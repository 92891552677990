<div class="tcs-dialog-outer-div">
  <!-- <mat-toolbar class="tcs-case-creation-dialog-toolbar">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <span class="tcs-case-creation-dialog-toolbar-text">{{ data.label }}</span>
      </div>
      <mat-icon fxLayout="column" fxLayoutAlign="start end" class="tcs-case-creation-dialog-icons"
                [svgIcon]="'tcs-close-icon'" (click)="closeDialog(false)"></mat-icon>
  </mat-toolbar> -->
  <div mat-dialog-content class="tcs-case-creation-dialog" fxLayout="column">
    <div id="formDiv"></div>
    <div *ngIf="data.caseReference">
      <tib-la-caseaction-form
        [sandbox]="formData[0]"
        [formDivId]="'formDiv'"
        [caseRef]="formData[1]"
        [chatBotEnabled]="'false'"
        [id]="formData[2]"
        [name]="formData[3]"
        [label]="formData[4]"
        [version]="formData[5]"
        [applicationId]="formData[6]"
        [applicationName]="formData[7]"
        [activityName]="formData[8]"
        [noData]="'false'"
        [useCustomForm]="'false'"
        (formCancel)="closeDialog($event)"
        (formSubmit)="closeDialog($event)"
      >
      </tib-la-caseaction-form>
    </div>
  </div>
</div>

