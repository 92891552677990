export async function fetchWithAuthentication(url, authToken) {
  const headers = new Headers();
  headers.set('Authorization', `Bearer ` + authToken);
  const file = await fetch(url, {headers});
  const blob = await file.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
}

export function convertBytesToUnits(x){
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0
  let n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l){
    n = n/1024;
  }
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export function containsObject(obj, list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings! 
  let int = parseInt(str); 
  return !isNaN(int) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}