import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { ConnectionTypeOption, ConnectionParameters } from '../../../models_ui/connectionDefinitions';
import { CatalogService,TCMConnection, DVConnection, Connection } from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  selector: 'connection-parameters-form',
  templateUrl: './connection-parameters-form.component.html',
  styleUrls: ['./connection-parameters-form.component.scss']
})
export class ConnectionParametersFormComponent implements OnChanges {

  constructor(private catalogService: CatalogService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,

  ) {}

  get isTestable() {
    if (this.isTCMConnection) {
      return (!this.testing &&
        this.connectionParams &&
        this.connectionParams.broker &&
        this.connectionParams.name &&
        this.connectionParams.userId &&
        this.connectionParams.password);
    };
    if (this.isTDVConnection) {
      return (!this.testing &&
        this.connectionParams &&
        this.connectionParams.name &&
        this.connectionParams.userId &&
        this.connectionParams.password &&
        this.connectionParams.hostname &&
        this.connectionParams.httpPort &&
        this.connectionParams.jdbcPort);
    }
  }

  get isTCMConnection() {
    return this.connectionType?.value === 'tcm';
  }

  get isTDVConnection() {
    return this.connectionType?.value === 'tdv';
  }

  @Input() connectionType: ConnectionTypeOption;
  @Input() connection: Connection;
  @Output() validConnectionParams: EventEmitter<ConnectionParameters> = new EventEmitter<ConnectionParameters>();
  @Output() triggerNotification: EventEmitter<any> = new EventEmitter();
  @Output() processing: EventEmitter<boolean> = new EventEmitter();

  connectionParams: ConnectionParameters;
  testing = false;
  notification: any;
  labelName: string;
  labeldDescription: string;
  labelUserId: string;
  labelPassword: string;
  labelBroker: string;
  labelTest: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.translationService.currentLang$.subscribe(() => {
    this.labelName= this.translationService.translate("connection.label.name")
    this.labeldDescription= this.translationService.translate("connection.label.description")
    this.labelUserId= this.translationService.translate("connection.label.userId")
    this.labelPassword= this.translationService.translate("connection.label.password")
    this.labelBroker= this.translationService.translate("connection.label.broker")
    this.labelTest= this.translationService.translate("connection.label.test")

    });
    if (changes.connection) {
      if (this.connection.type === Connection.TypeEnum.Tcm) {
        const tcmConfig: TCMConnection = this.connection.configuration as TCMConnection;
        this.connectionType = {
          value: 'tcm'
        }
        this.connectionParams = {
          name: this.connection.name,
          description: this.connection.description,
          userId: tcmConfig.username,
          password: tcmConfig.password,
          broker: tcmConfig.broker,
        }
      } else if (this.connection.type === Connection.TypeEnum.Dv) {
        const dvConfig: DVConnection = this.connection.configuration as DVConnection;
        this.connectionType = {
          value: 'tdv'
        }
        this.connectionParams = {
          name: this.connection.name,
          description: this.connection.description,
          userId: dvConfig.username,
          password: dvConfig.password,
          hostname: dvConfig.hostname,
          httpPort: dvConfig.http_port,
          jdbcPort: dvConfig.jdbc_port
        }
      }
    }
  }

  handleFieldUpdate(field: string, value: CustomEvent) {
    this.validConnectionParams.emit(undefined);
    this.connectionParams = { ...this.connectionParams };
    this.connectionParams[field] = value?.detail?.value;
  }

  test() {
    this.testing = true;
    this.processing.emit(true);
    let body: TCMConnection | DVConnection;
    switch (this.connectionType?.value) {
      case 'tcm': {
        body = {
          broker: this.connectionParams?.broker,
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
        } as TCMConnection;
        break;
      }
      case 'tdv': {
        body = {
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
          hostname: this.connectionParams?.hostname,
          jdbc_port: this.connectionParams?.jdbcPort,
          http_port: this.connectionParams?.httpPort
        } as DVConnection;
        break;
      }
      default: {
        // invalid test
        this.testing = false;
        this.processing.emit(false);
        return;
      }
    }

    this.catalogService.testConnection(body).subscribe(result => {
      if (result) {
        this.triggerNotification.emit({
          type: 'SUCCESS',
          message: 'Test connection to \'' + this.connectionParams.name + '\' was successful!'
        });
        this.validConnectionParams.emit(this.connectionParams);
      } else {
        //const part1 = this.translationService.translate("connection.parameter.snackbar.error.part1")
        //const part2 = this.translationService.translate("connection.parameter.snackbar.error.part2")

        // this.triggerNotification.emit({
        //   type: 'ERROR',
        //   message: 'Test connection to \'' + this.connectionParams.name + '\' failed with an unknown error!'
        // });
        this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error.part1')+"'\'"+this.translationService.translate('connection.parameter.snackbar.error.part2'),this.translationService.translate('connection.parameter.snackbar.close'),'error');

      }
      this.testing = false;
      this.processing.emit(false);
    }, error => {
      // this.triggerNotification.emit({
      //   type: 'ERROR',
      //   message: 'The credentials you entered are invalid. Please try again.'
      // });
      this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error2'), this.translationService.translate('connection.parameter.snackbar.erro'),'error');

      console.error(error);
      this.testing = false;
      this.processing.emit(false);
    })
  }

  handleCloseNotification() {
    this.notification = undefined;
  }

}
