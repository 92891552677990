import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConnectionParameters } from '../../../models_ui/connectionDefinitions';
import { CatalogService,Connection, TCMConnection, DVConnection, CreateConnection, UpdateConnection} from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  templateUrl: './edit-connection-wizard.component.html',
  styleUrls: ['./edit-connection-wizard.component.scss']
})
export class EditConnectionWizardComponent {

  @Output() connectionSaved: EventEmitter<{ action: any, id?: string }> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<EditConnectionWizardComponent>,
    private catalogService: CatalogService,
    @Inject(MAT_DIALOG_DATA) public data: Connection,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,

  ) {
    this.connection = data;
    dialogRef.disableClose = false;
  }

  connection: Connection;
  connectionParams: ConnectionParameters;
  form = 'connectionParams';
  notification: any;
  processing = false;

  handleParamsSelection(connectionParams: ConnectionParameters) {
    this.connectionParams = connectionParams;
  }

  back() {
    this.notification = undefined;
    this.connectionParams = undefined;
    this.form = 'typeSelection';
  }

  close() {
    this.dialogRef?.close();
  }

  async save() {
    this.processing = true;
    let config: TCMConnection | DVConnection;
    switch (this.connection.type) {
      case 'tcm': {
        config = {
          broker: this.connectionParams?.broker,
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
        } as TCMConnection;
        break;
      }
      case 'dv': {
        config = {
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
          hostname: this.connectionParams?.hostname,
          jdbc_port: this.connectionParams?.jdbcPort,
          http_port: this.connectionParams?.httpPort
        } as DVConnection;
        break;
      }
      default: {
        // invalid test
        console.error('Invalid update request for connection type: ', this.connection.type);
        this.processing = false;
        this.snackBarService.openSnackBar(this.translationService.translate('edit.connection.snackbar'), this.translationService.translate('connection.parameter.snackbar.close'),'error');

        return;
      }
    }
    const updates: UpdateConnection[] = [{
      id: this.connection.id,
      name: this.connectionParams.name,
      description: this.connectionParams.description,
      configuration: config,
      type: this.connection.type
    } as UpdateConnection]
    this.catalogService.updateConnection(updates).subscribe(() => {
      this.notification = undefined;
      this.processing = false;
      this.form = 'connectionSaved';
    }, error => {
      this.processing = false;
      this.notify({
        type: 'ERROR',
        message: 'Failed to create connection' + (error?.error?.message ? ' - ' + error.error.message : '')
      });
      console.error(error);
    })
  }

  handleProcessingChange(processing: boolean) {
    this.processing = processing;
  }

  handleConnectionsClick() {
    this.dialogRef.close('goConnections');
  }

  handleNewDatasetClick() {
    this.dialogRef.close('goDatasets');
  }

  handleCloseNotification() {
    this.notification = undefined;
  }

  notify(notification) {
    this.notification = notification;
    setTimeout(() => {
      this.notification = undefined;
    }, 5000)
  }

  get isConnectionParams() {
    return this.form === 'connectionParams';
  }

  get isConnectionSaved() {
    return this.form === 'connectionSaved';
  }

  get isCreatable() {
    return this.connectionParams;
  }

  get connectionTypeEnum(): CreateConnection.TypeEnum {
    return this.connection.type;
  }

}
