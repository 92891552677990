import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from 'src/app/service/translate.service';

export interface DictionaryRow {
  title: string
  description: string
  iconType: string
  icon: string
  iconClass: string
  loading: boolean
}

@Component({
  selector: 'dictionary-dialog',
  templateUrl: './dictionary-dialog.component.html',
  styleUrls: ['./dictionary-dialog.component.css']
})
export class DictionaryDialogComponent implements OnInit {

  title: string = '';
  rows: DictionaryRow[];

  panelOpenState = false;
  constructor(
    public dialogRef: MatDialogRef<DictionaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translationService: TranslationService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if(this.data){
      this.title = this.data.title ? this.data.title : this.translationService.translate('dictionary');
      this.rows = this.data.rows ? this.data.rows : [];
    }
  }

  public onCancel = (): void => {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
