<!-- 
  Remove label Additional info for problem with translation
  
  <uxpl-detail-pane class="dataset-details" top-section-label="Additional info" -->
<uxpl-detail-pane class="dataset-details"
                  [topSectionAttributes]="details"
                  (uxplAttributeCopy)="handleDetailAttributeCopy($event?.detail)"
                  (uxplAttributeAction)="goFileDetail($event)">
  <div *ngIf="datasetDetail?.metadata?.status === 'Error' || datasetDetail?.metadata?.dataCollectionStatus === 'Error'" class="error-container" slot="bottomSectionLabelContent">
    <img height=75 width=75 src="assets/svg/error-image-2.svg" onerror="this.style.display='none'">
    <div class="error-details-pane">
      <div class="error-header">
        <h3>{{ 'dataset.details.label.error' | translate }}</h3>
        <uxpl-icon class="error-icon" height="15px" width="15px" icon="pl-icon-copy"
                   matTooltip="Copy error to clipboard" matTooltipClass="tTip"
                   (click)="copyToClipBoard('Error', errorDetails,messageService)"></uxpl-icon>
      </div>
      <error-details [errorMessage]="errorDetails"></error-details>
    </div>
  </div>
  <!-- section to show PA's -->
  <div *ngIf="datasetDetail?.metadata.status !== 'Error'" slot="bottomSectionWidgets">
    <div *ngIf="dataset?.usedBy?.length > 0">
      <div class="analysis-link-header">{{ 'dataset.details.label.used' | translate }}</div>
      <div *ngFor="let paLink of dataset.usedBy" class="analysis-link-header new-link" matTooltip="Open process analysis"
           matTooltipClass="tTip" (click)="goAnalysisDetail(paLink.id)">{{paLink.name | replace: '||': ' > '}}</div>
      <div class="ps-detail-text">
        <span class="new-link" style="margin: 16px 0;" (click)="goCreateNewAnalysis()">{{ 'dataset.details.label.message1' | translate }}</span>
        {{ 'dataset.details.label.message2' | translate }}
      </div>
    </div>
    <div *ngIf="datasetDetail?.metadata.status === 'Ready' && (!dataset?.usedBy?.length > 0)" class="no-ps-container">
      <img width="171" src="assets/svg/analysis-empty-state.svg">
      <div class="ps-detail-text">{{ 'dataset.details.label.text' | translate }}</div>
      <span class="new-link text-center" (click)="goCreateNewAnalysis()">{{ 'dataset.details.label.goNewAnalysis' | translate }}</span>
    </div>
  </div>
</uxpl-detail-pane>
