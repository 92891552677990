import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'progress-screen',
  templateUrl: './progress-screen.component.html',
  styleUrls: ['./progress-screen.component.scss']
})
export class ProgressScreenComponent implements OnInit {

  @Input() message1: string;
  @Input() message2: string;
  @Input() percentage: number;
  @Input() status: string;

  @Input() showImageLeft = false;

  readonly robotAnimationGifLocation = 'assets/images/gif/robot-animation.gif';

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
