import {Component, EventEmitter, Output} from '@angular/core';
import { ConnectionTypeOption } from '../../../models_ui/connectionDefinitions';

@Component({
  selector: 'connection-type-selection-form',
  templateUrl: './connection-type-selection-form.component.html',
  styleUrls: ['./connection-type-selection-form.component.scss']
})
export class ConnectionTypeSelectionFormComponent {

  constructor() {}

  @Output() typeSelection: EventEmitter<ConnectionTypeOption> = new EventEmitter<ConnectionTypeOption>();

  connectionTypeOptions: ConnectionTypeOption[] = [
    {
      label: 'TDV view',
      value: 'tdv',
      icon: 'assets/svg/tdv-view.svg'
    },
    {
      label: 'TCM connection',
      value: 'tcm',
      icon: 'assets/svg/tcm-connection.svg'
    }
  ];

  selection(connectionType: ConnectionTypeOption) {
    this.typeSelection.emit(connectionType);
  }

}
