import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateDataset, Dataset, ElementReference} from '@tibco/discover-client-lib';
import {DatasetWizard} from '../../../models_ui/dataset';
import {NewAnalysisStepStatus} from '../../../models_ui/discover';

@Component({
  selector: 'dataset-datasource',
  templateUrl: './datasource.component.html',
  styleUrls: ['./datasource.component.scss', '../wizard/wizard.preview.table.scss']
})
export class NewDatasetDatasourceComponent implements OnInit {

  @Input() data: Dataset | CreateDataset;
  @Input() backupDataset: Dataset;
  @Input() wizard: DatasetWizard;
  @Input() previewColumns: any[];
  @Input() previewData: any[];
  @Input() file: File;

  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter();
  @Output() uploadedFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() stopEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectConnection: EventEmitter<ElementReference> = new EventEmitter<ElementReference>();
  @Output() highlightColumns: EventEmitter<any> = new EventEmitter<any>();

  connectionValid = false;
  csvValid = false;
  xesValid = false;
  xlsxValid = false;
  fileType: string;

  constructor() {
  }

  ngOnInit(): void {
    this.updateStatus();
  }

  public updateStatus() {
    const status =
      (this.wizard.dataSourceType.type === Dataset.TypeEnum.File && this.csvValid === true) ||
      (this.wizard.dataSourceType.type === Dataset.TypeEnum.Xes && this.xesValid === true) ||
      (this.wizard.dataSourceType.type === Dataset.TypeEnum.Xlsx && this.xlsxValid === true) ||
      ((this.wizard.dataSourceType.type === Dataset.TypeEnum.Tcm || this.wizard.dataSourceType.type === Dataset.TypeEnum.Dv) && this.connectionValid === true);
    const stepStatus = {
      step: 'dataset-datasource',
      completed: status
    } as NewAnalysisStepStatus;
    if(this.wizard.dataSourceType.type == 'file'){
      this.fileType = 'csv';
    }else{
      this.fileType = this.wizard.dataSourceType.type;
    }

    this.status.emit(stepStatus);
  }

  public uploadFile($event) {
    this.uploadedFile.emit($event);
  }

  onConnectionStatus($event) {
    this.connectionValid = $event.valid;
    this.updateStatus();
    if (this.connectionValid) {
      this.selectConnection.emit($event.connection);
    }
  }

  onCsvStatus($event) {
    this.csvValid = $event;
    this.updateStatus();
  }

  onXesStatus($event) {
    this.xesValid = $event;
    this.updateStatus();
  }
  
  onXlsxStatus($event) {
    this.xlsxValid = $event;
    this.updateStatus();
  }

  onHighlightColumns($event) {
    this.highlightColumns.emit($event);
  }

}
