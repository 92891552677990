<h2 mat-dialog-title>Confirm permissions</h2>
<mat-dialog-content>
  Are you sure you want to {{ superadmin ? "revoke" : "grant" }} superadmin
  privileges?

  <mat-form-field appearance="fill">
    <mat-label>Type "CONFIRM" to confirm</mat-label>
    <input
      matInput
      [(ngModel)]="confirmationInput"
      placeholder="Type 'CONFIRM'"
      #confirmationInputField
      (keydown)="onKeyDown($event)"
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="actions-container">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button
      mat-button
      color="warn"
      [disabled]="confirmationInput !== 'CONFIRM'"
      (click)="onSaveClick()"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
