<div class="tibco-table-ux">
  <p-table #investigationTable [columns]="cols" [value]="investigationTableData"
           dataKey="id" id="pTable" styleClass="custom-table-css"
           [rowHover]="false" [expandedRowKeys]="expandedRows"
           [loading]="investigationTableData == null" [filterDelay]="0">
    <ng-template pTemplate="header" let-columns>
      <tr style="background-color: #fff;">
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="tibco-top-row top-row">
          <div class="investigation-column-header">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </div>
        </th>
        <th style="width: 50px" class="top-row-empty"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-i="rowIndex">
      <tr class="rowhover">
        <td *ngFor="let col of columns let i = index" class="data-row hovereffect"
            (click)="investigationTable.toggleRow(rowData, $event)" (dblclick)="onRowClick(rowData)">
          <div *ngIf="col.field !== 'state' && i === 0" class="firstColumn"
               [ngClass]="i === 0 ? 'actionable-data' : ''"
               (click)="onRowClick(rowData)"
               [innerHTML]="rowData[col.field] | highlight: searchTerm : 'Multi-Match'"></div>
          <div
            *ngIf="col.field !== 'state' && i !== 0 && rowData[col.field + '_format'] === 'DATE' "
            [innerHTML]="rowData[col.field] | relativeTime"></div>
          <div
            *ngIf="col.field !== 'state' && i !== 0 && rowData[col.field + '_format'] !== 'DATE' "
            [innerHTML]="rowData[col.field] | highlight: searchTerm : 'Multi-Match'"></div>
          <div *ngIf="cConfig.states && col.field === 'state'" class="state-column">
            <uxpl-tag icon="{{getStateIcon(rowData[col.field])}}"
                      color="{{getStateColor(rowData[col.field])}}">{{rowData[col.field] | translate}}</uxpl-tag>
          </div>
        </td>
        <td class="no-padding hovereffect" style="width: 30px !important;">
          <div class="table-action-section">
            <div class="icon-holder">
              <uxpl-options-menu default-color='#2B94EB' x-position="before" y-position="below" icon-width="14px"
                                 icon-height="14px" [config]="actions[rowData.id]"
                                 (selected)="handleInvestigationActionSelect($event)"
                                 (click)="handleOptionClick(rowData.id)"></uxpl-options-menu>
            </div>
          </div>
        </td>
        <td class="no-padding hovereffect toggle-item" style="width: 30px !important;">
          <a class="tib-toggle-icons" href="#" [pRowToggler]="rowData">
            <uxpl-icon *ngIf="!expanded" url="assets/images/svg/ic_caretdown_clear.svg" class="hover-icon"></uxpl-icon>
            <uxpl-icon *ngIf="expanded" url="assets/images/svg/ic_caretup.svg" class="hover-icon"></uxpl-icon>
          </a>
        </td>
        <!--td class="options-cell">
          <uxpl-options-menu x-position="before" menu-height="180px" y-position="after"
                            icon-height="15px" icon-width="15px" [config]="actions[rowData.id]"
                            (selected)="handleInvestigationActionSelect($event)"
                            (click)="handleOptionClick(rowData.id)"></uxpl-options-menu>

        </td-->
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
      <tr style="background-color: #FFFFFF">
        <td [attr.colspan]="columns.length + 2" class="addl-info-section">
          <div class="ui-g ui-fluid" style="font-size:16px; ">
            <investigation-details [cConfig]="cConfig" [investigationDetail]="getInvestigationById(rowData.id)"
                                   (refreshEvent)="refreshEvent.emit($event)"></investigation-details>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
      <div *ngIf="showAdditionalSpinner" class="small-spinner">
        <div class="pl-spinner upload-spinner-placer">
          <div class="pl-spinner__icon"></div>
        </div>
      </div>
    </ng-template>
  </p-table>
</div>
