import { AbstractControl, ValidatorFn } from '@angular/forms';

export function noDashValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = /-/.test(control.value);
    return forbidden ? { 'noDash': { value: control.value } } : null;
  };
}

export function alphaNumericSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^[a-zA-Z0-9\s]*$/.test(control.value);
    return valid ? null : { 'alphanumspace': { value: control.value } };
  };
}
