export interface StartStop {
  startActivities?: string[],
  stopActivities?: string[]
}

export class MapDef {
  static PROP_NAMES: string[] = [
    'caseId',
    'activity',
    'requester',
    'resource',
    'resourceGroup',
  ];
  static PROP_NAMES_TIME: string[] = [
    'startTime',
    'endTime',
    'scheduledStart',
    'scheduledEnd'
  ];
}

// export interface Schedule {
//   Schedule: string;
//   isSchedule: string;
// }

export interface MappingDetails {
  fieldName: string;
  fieldDescription: string;
  lockFieldName: string;
  isLocked: boolean;
  isAutomapped: boolean;
  type: 'TIME' | 'STRING'
}

export interface MappingUI {
  mappings: MappingDetails[];
}

export interface ColumnUI {
  headerName: string;
  field: string;
  sortable: boolean;
  filter: boolean;
  resizable: boolean;
  isDate: boolean;
}

export interface PreviewUI {
  availableColumns: string[];
  columns: ColumnUI[];
  data: any;
}

export type FResult = { line: string, label: string, hoverText: string }
export type FStat = { name: string, value: number, total: number, percentage: number }
export type MStat = { label: string, values: string[] }

export enum AnalysisStatusReport {
  TOINITIALIZE = 'toInitialize',
  REPORTINITIALIZATION = 'reportInitialization',
  INITIALIZATIONFAILED = 'initializationFailed',
  INITIALIZATIONCOMPLETE = 'initializationComplete',
  REPORTGENERATION = 'reportGeneration',
  GENERATIONFAILED = 'generationFailed',
  GENERATIONCOMPLETE = 'generationComplete',
}

export enum AnalysisActionReport {
  NONE = 'none',
  INITIALIZEREPORT = 'initializeReport',
  GENERATEREPORT = 'generateReport',
  DOWNLOADREPORT = 'downloadReport'
}

export interface AnalysisActionsReport {
  action: AnalysisActionReport;
  label: string;
  icon: string;  
}

export enum AnalysisCacheStatus {
  NONE = 'none',
  CREATING = 'creating',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export enum AnalysisCacheAction {
  NONE = 'none',
  CACHE = 'cache',
  UNCACHE = 'uncache',
  REGENERATE = 'regenerate',
  ALERT_COMPLIANCE = 'alert-compliance'
}

export interface Report {
  language: string
  createdOn: string
  status: ReportStatusEnum
  s3PathPdf: string
  s3PathDocx: string
}

export enum ReportStatusEnum {
  CREATING = 'Creating',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  UNKNOWN = 'Unknown',
}

export enum ReportTypeEnum {
  PDF = 'pdf',
  DOCX = 'docx'
}