import { WelcomeComponent } from '../routes/welcome/welcome.component';
import { DiscoverComponent } from '../routes/discover/discover.component';
// import { AccessGuard } from '../guards/access.guard';
import {
  DISCOVER_ROUTE_CONFIG,
  DISCOVER_PROVIDERS,
} from './discover-route-config';
import { NoAccessComponent } from '../routes/no-access/no-access.component';
import { ActivationComponent } from '../routes/activation/activation.component';
import { MsalGuard } from '@azure/msal-angular';
import { SelectOrganizationComponent } from '../routes/select-organization/select-organization.component';
import { AdministrationComponent } from '../routes/administration/administration.component';
import { CreateOrgComponent } from '../routes/create-organization/create-org.component';
import { OrgStatisticsComponent } from '../routes/org-statistics/org-statistics.component';
import { OrgEditComponent } from '../routes/org-edit/org-edit.component';
import { OrgUsersComponent } from '../routes/org-users/org-users.component';
import { OrgAddUserComponent } from '../routes/org-add-user/org-add-user.component';
export const CORE_ROUTES = [
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'select-organization',
    component: SelectOrganizationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'administration/create-org',
    component: CreateOrgComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [MsalGuard],
    children: [{
      path: ':orgId',
      redirectTo: ':orgId/statistics',
      pathMatch: 'full',
    },{
      path: ':orgId/statistics',
      component: OrgStatisticsComponent,
      canActivate: [MsalGuard],
    },{
      path: ':orgId/users',
      component: OrgUsersComponent,
      canActivate: [MsalGuard],
    },{
      path: ':orgId/add-user',
      component: OrgAddUserComponent,
      canActivate: [MsalGuard],
    },{
      path: ':orgId/edit-org',
      component: OrgEditComponent,
      canActivate: [MsalGuard],
    }]
  },
  {
    path: 'discover',
    component: DiscoverComponent,
    canActivate: [MsalGuard],
    children: DISCOVER_ROUTE_CONFIG,
  },
  {
    path: 'activation',
    component: ActivationComponent,
  },

  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/welcome',
  },
];

export const CORE_PROVIDERS = [DISCOVER_PROVIDERS];
