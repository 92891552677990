import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { endpoints } from '../../environments/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class VisualisationService {

  constructor(
    private http: HttpClient,
  ) {
  }
  getConfiguration(): Observable<any> {
    return this.http.get<any>(
      endpoints.visualisation.configuration,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    ).pipe(
      map((res) => {
        return res.body;
      })
    );
  }
}
