import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TButton } from '../../../models_ui/buttons';
import { InvestigationAction } from '../common/InvestigationAction';
import { MatDialog } from '@angular/material/dialog';
import { Investigation, InvestigationApplication, InvestigationService } from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../../service/internal-message.service';

@Component({
  selector: 'investigation-action-tool-bar',
  templateUrl: './investigation-action-tool-bar.component.html',
  styleUrls: ['./investigation-action-tool-bar.component.css']
})
export class InvestigationActionToolBarComponent extends InvestigationAction implements OnChanges {

  @Input() cConfig: InvestigationApplication;
  @Input() investigationDetail: Investigation;
  @Output() refreshEvent: EventEmitter<string> = new EventEmitter<string>();

  actionButtons: TButton[];

  constructor(protected dialog: MatDialog,
              protected messageService: InternalMessageService,
              protected investigationMS: InvestigationService) {
    super(dialog, messageService, investigationMS)
  }

  // Function to get the action buttons
  async setActionButtons() {
    this.actionButtons = await this.getActionButtons(this.investigationDetail)
    this.actionButtons = [...this.actionButtons]
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.investigationDetail && this.investigationDetail && this.cConfig){
      this.setCConfig(this.cConfig)
      await this.setActionButtons()
    }
  }

  handleButtonClicked(tBut: TButton) {
    if (tBut.type === 'ACTION') {
      this.oneCommonAction(tBut.id, this.investigationDetail.id, tBut.formData).then( async (_result) => {
        // Refresh a little later till the case is updated
        setTimeout(async () => {
          // await this.setActionButtons();
          this.refreshEvent.emit(this.cConfig.id)
        }, 500)
      })
    }
  }

}
