<div class="flex-container">
	<div class="flex-row">
		<div class="flex-item">
			<h1 mat-dialog-title>{{analyisFolderTitle}}</h1>
		</div>
	</div>
</div>
<mat-stepper #stepper (selectionChange)="stepChanged($event)" *ngIf="creationStatus == 'ready' && creationLoading == false">
	<mat-step [hasError]="!validationSteps[0].valid" #step>
		<ng-template matStepLabel>{{ 'manage.process.analysis.title.modal.step1' | translate }}</ng-template>
		<ng-template matStepContent>
			<div class="flex-container">
				<div class="flex-row">
					<div class="flex-item p-0 flex-direction-column">
						<uxpl-form-field type="text" [label]="folderNamePlaceholder" [value]="folderName" [hint]="validationSteps[0].message" [valid]="validationSteps[0].valid" (uxplChanged)="updateFolderName($event.detail.value)"></uxpl-form-field>
                        <p></p>
					</div>
				</div>
				<div class="flex-row">
					<div class="flex-item width-xs-5"><button class="pl-button pl-button--secondary" (click)="closeDialog()">Cancel</button></div>
					<div class="flex-item width-xs-5"><button class="pl-button pl-button--primary" matStepperNext>Next</button></div>
				</div>
			</div>
		</ng-template>
	</mat-step>
	<mat-step [hasError]="!validationSteps[1].valid" #step>
		<ng-template matStepLabel>{{ 'manage.process.analysis.title.modal.step2' | translate }}</ng-template>
		<ng-template matStepContent>
			<div id="step-2" class="flex-container">
				<div class="flex-row">
					<div class="flex-item">
						<p>{{ 'manage.process.analysis.message.modal.step2' | translate }}</p>
						<p class="step-error-message" *ngIf="validationSteps[1].message.length > 0">{{validationSteps[1].message}}</p>
						<div class="process-analysis-breadcrumbs">
							<ul class="breadcrumbs">
								<li *ngFor="let folder of currentFolderArray2">
									<a [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path, '2')">{{folder.name}}</a>
								</li>
							</ul>
						</div>
						<div class="process-analysis-folder-wrapper">
							<div class="process-analysis-folder-container">
								<ul>
									<li class="open li-folder" *ngFor="let folder of hierarchicalFolders">
										<a href="javascript:void(0)" class="folder" [ngClass]="{'folder': true, 'checked': folder.path == currentFolder2}" [attr.folder-name]="folder.name" [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path, '2')">
										<span class="name">
										<span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
										<span class="material-symbols-rounded folder-icon folder-closed">folder</span>
										<span class="name-text">{{folder.name}}</span>
										</span>
										</a>
										<ul>
											<li class="li-folder" *ngFor="let folderParent of folder.children">
												<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderParent.path == currentFolder2}" [attr.folder-name]="folderParent.name" [attr.data-folder-path]="folderParent.path" (click)="selectFolder(folderParent.path, '2')">
												<span class="name">
												<span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
												<span class="material-symbols-rounded folder-icon folder-closed">folder</span>
												<span class="name-text">{{folderParent.name}}</span>
												</span>
												</a>
												<ul>
													<li class="li-folder" *ngFor="let folderChildren of folderParent.children">
														<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderChildren.path == currentFolder2}" [attr.folder-name]="folderChildren.name" [attr.data-folder-path]="folderChildren.path" (click)="selectFolder(folderChildren.path, '2')">
														<span class="name">
														<span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
														<span class="material-symbols-rounded folder-icon folder-closed">folder</span>
														<span class="name-text">{{folderChildren.name}}</span>
														</span>
														</a>
														<ul>
															<li class="li-folder" *ngFor="let folderGrandchild of folderChildren.children">
																<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderGrandchild.path == currentFolder2}" [attr.folder-name]="folderGrandchild.name" [attr.data-folder-path]="folderGrandchild.path" (click)="selectFolder(folderGrandchild.path, '2')">
																<span class="name">
																<span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
																<span class="material-symbols-rounded folder-icon folder-closed">folder</span>
																<span class="name-text">{{folderGrandchild.name}}</span>
																</span>
																</a>
																<ul>
																	<ng-container *ngFor="let analysis of analysisList">
																		<li *ngIf="analysis.folder == folderGrandchild.path" class="analysis">
																			<a href="javascript:void(0)">
																			<span class="name">
																			<span class="material-symbols-rounded folder-icon">insert_chart</span>
																			<span class="name-text">{{analysis.name}}</span>
																			</span>
																			</a>
																		</li>
																	</ng-container>
																	<li class="analysis no-analysis">
																		<a href="javascript:void(0)">
																		<span class="name">
																		<span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
																		<span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
																		</span>
																		</a>
																	</li>
																</ul>
															</li>
															<li class="folder no-folder">
																<a href="javascript:void(0)">
																<span class="name">
																<span class="material-symbols-rounded folder-icon">folder_off</span>
																<span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
																</span>
																</a>
															</li>
															<ng-container *ngFor="let analysis of analysisList">
																<li *ngIf="analysis.folder == folderChildren.path" class="analysis">
																	<a href="javascript:void(0)">
																	<span class="name">
																	<span class="material-symbols-rounded folder-icon">insert_chart</span>
																	<span class="name-text">{{analysis.name}}</span>
																	</span>
																	</a>
																</li>
															</ng-container>
															<li class="analysis no-analysis">
																<a href="javascript:void(0)">
																<span class="name">
																<span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
																<span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
																</span>
																</a>
															</li>
														</ul>
													</li>
													<li class="folder no-folder">
														<a href="javascript:void(0)">
														<span class="name">
														<span class="material-symbols-rounded folder-icon">folder_off</span>
														<span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
														</span>
														</a>
													</li>
													<ng-container *ngFor="let analysis of analysisList">
														<li *ngIf="analysis.folder == folderParent.path" class="analysis">
															<a href="javascript:void(0)">
															<span class="name">
															<span class="material-symbols-rounded folder-icon">insert_chart</span>
															<span class="name-text">{{analysis.name}}</span>
															</span>
															</a>
														</li>
													</ng-container>
													<li class="analysis no-analysis">
														<a href="javascript:void(0)">
														<span class="name">
														<span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
														<span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
														</span>
														</a>
													</li>
												</ul>
											</li>
											<li class="folder no-folder">
												<a href="javascript:void(0)">
												<span class="name">
												<span class="material-symbols-rounded folder-icon">folder_off</span>
												<span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
												</span>
												</a>
											</li>
											<ng-container *ngFor="let analysis of analysisList">
												<li *ngIf="analysis.folder == folder.path" class="analysis">
													<a href="javascript:void(0)">
													<span class="name">
													<span class="material-symbols-rounded folder-icon">insert_chart</span>
													<span class="name-text">{{analysis.name}}</span>
													</span>
													</a>
												</li>
											</ng-container>
											<li class="analysis no-analysis">
												<a href="javascript:void(0)">
												<span class="name">
												<span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
												<span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
												</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-row">
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--secondary" (click)="closeDialog()">Cancel</button></div>
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--primary" matStepperPrevious>Back</button></div>
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--primary" matStepperNext>Next</button></div>
				</div>
			</div>
		</ng-template>
	</mat-step>
	<mat-step [hasError]="!validationSteps[2].valid" #step>
		<ng-template matStepLabel>{{ 'manage.process.analysis.title.modal.step3' | translate }}</ng-template>
		<ng-template matStepContent>
			<div id="step-3" class="flex-container">
				<div class="flex-row">
					<div class="flex-item">
						<p>{{ 'manage.process.analysis.placeholder.analysis' | translate }}</p>
						<p class="step-error-message" *ngIf="validationSteps[2].message.length > 0">{{validationSteps[2].message}}</p>
						<div class="process-analysis-breadcrumbs">
							<ul class="breadcrumbs">
								<li *ngFor="let folder of currentFolderArray3">
									<a [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path, '3')">{{folder.name}}</a>
								</li>
							</ul>
						</div>
						<div class="process-analysis-folder-wrapper">
							<div class="process-analysis-folder-container">
								<ul>
									<li class="open li-folder" *ngFor="let folder of hierarchicalFolders">
										<a href="javascript:void(0)" class="folder" [ngClass]="{'folder': true, 'checked': folder.path == currentFolder3}" [attr.folder-name]="folder.name" [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path, '3')">
                                            <span class="name">
                                                <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                                                <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                                                <span class="name-text">{{folder.name}}</span>
                                            </span>
										</a>
										<ul>
											<li class="li-folder" *ngFor="let folderParent of folder.children">
												<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderParent.path == currentFolder3}" [attr.folder-name]="folderParent.name" [attr.data-folder-path]="folderParent.path" (click)="selectFolder(folderParent.path, '3')">
                                                    <span class="name">
                                                        <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                                                        <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                                                        <span class="name-text">{{folderParent.name}}</span>
												    </span>
												</a>
												<ul>
													<li class="li-folder" *ngFor="let folderChildren of folderParent.children">
														<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderChildren.path == currentFolder3}" [attr.folder-name]="folderChildren.name" [attr.data-folder-path]="folderChildren.path" (click)="selectFolder(folderChildren.path, '3')">
                                                            <span class="name">
                                                                <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                                                                <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                                                                <span class="name-text">{{folderChildren.name}}</span>
                                                            </span>
														</a>
														<ul>
															<li class="li-folder" *ngFor="let folderGrandchild of folderChildren.children">
																<a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderGrandchild.path == currentFolder3}" [attr.folder-name]="folderGrandchild.name" [attr.data-folder-path]="folderGrandchild.path" (click)="selectFolder(folderGrandchild.path, '3')">
                                                                    <span class="name">
                                                                        <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                                                                        <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                                                                        <span class="name-text">{{folderGrandchild.name}}</span>
                                                                    </span>
																</a>
																<ul>
																	<ng-container *ngFor="let analysis of analysisList">
																		<li *ngIf="analysis.folder == folderGrandchild.path" class="analysis">
																			<a href="javascript:void(0)">
                                                                                <span class="name">
                                                                                    <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                                                                    <span class="name-text">{{analysis.name}}</span>
                                                                                </span>
                                                                                <uxpl-button type="secondary" height="24px" *ngIf="!analysisToAdd.includes(analysis.id)" (click)="selectAnalysis(analysis.id)">{{ 'select' | translate }}</uxpl-button>
                                                                                <uxpl-button type="primary" height="24px" *ngIf="analysisToAdd.includes(analysis.id)" (click)="unselectAnalysis(analysis.id)">{{ 'selected.female' | translate }}</uxpl-button>
																			</a>
																		</li>
																	</ng-container>
																	<li class="analysis no-analysis">
																		<a href="javascript:void(0)">
                                                                            <span class="name">
                                                                                <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                                                                                <span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
																		    </span>
																		</a>
																	</li>
																</ul>
															</li>
															<li class="folder no-folder">
																<a href="javascript:void(0)">
                                                                    <span class="name">
                                                                        <span class="material-symbols-rounded folder-icon">folder_off</span>
                                                                        <span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
                                                                    </span>
																</a>
															</li>
															<ng-container *ngFor="let analysis of analysisList">
																<li *ngIf="analysis.folder == folderChildren.path" class="analysis">
																	<a href="javascript:void(0)">
                                                                        <span class="name">
                                                                            <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                                                            <span class="name-text">{{analysis.name}}</span>
																	    </span>
                                                                        <uxpl-button type="secondary" height="24px" *ngIf="!analysisToAdd.includes(analysis.id)" (click)="selectAnalysis(analysis.id)">{{ 'select' | translate }}</uxpl-button>
                                                                        <uxpl-button type="primary" height="24px" *ngIf="analysisToAdd.includes(analysis.id)" (click)="unselectAnalysis(analysis.id)">{{ 'selected.female' | translate }}</uxpl-button>
																	</a>
																</li>
															</ng-container>
															<li class="analysis no-analysis">
																<a href="javascript:void(0)">
                                                                    <span class="name">
                                                                        <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                                                                        <span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
                                                                    </span>
																</a>
															</li>
														</ul>
													</li>
													<li class="folder no-folder">
														<a href="javascript:void(0)">
                                                            <span class="name">
                                                                <span class="material-symbols-rounded folder-icon">folder_off</span>
                                                                <span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
                                                            </span>
														</a>
													</li>
													<ng-container *ngFor="let analysis of analysisList">
														<li *ngIf="analysis.folder == folderParent.path" class="analysis">
															<a href="javascript:void(0)">
                                                                <span class="name">
                                                                    <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                                                    <span class="name-text">{{analysis.name}}</span>
                                                                </span>
                                                                <uxpl-button type="secondary" height="24px" *ngIf="!analysisToAdd.includes(analysis.id)" (click)="selectAnalysis(analysis.id)">{{ 'select' | translate }}</uxpl-button>
                                                                <uxpl-button type="primary" height="24px" *ngIf="analysisToAdd.includes(analysis.id)" (click)="unselectAnalysis(analysis.id)">{{ 'selected.female' | translate }}</uxpl-button>
															</a>
														</li>
													</ng-container>
													<li class="analysis no-analysis">
														<a href="javascript:void(0)">
                                                            <span class="name">
                                                                <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                                                                <span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
                                                            </span>
														</a>
													</li>
												</ul>
											</li>
											<li class="folder no-folder">
												<a href="javascript:void(0)">
                                                    <span class="name">
                                                        <span class="material-symbols-rounded folder-icon">folder_off</span>
                                                        <span class="name-text">{{ 'manage.process.analysis.message2.modal.step3' | translate }}</span>
                                                    </span>
												</a>
											</li>
											<ng-container *ngFor="let analysis of analysisList">
												<li *ngIf="analysis.folder == folder.path" class="analysis">
													<a href="javascript:void(0)">
                                                        <span class="name">
                                                            <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                                            <span class="name-text">{{analysis.name}}</span>
                                                        </span>
                                                        <uxpl-button type="secondary" height="24px" *ngIf="!analysisToAdd.includes(analysis.id)" (click)="selectAnalysis(analysis.id)">{{ 'select' | translate }}</uxpl-button>
                                                        <uxpl-button type="primary" height="24px" *ngIf="analysisToAdd.includes(analysis.id)" (click)="unselectAnalysis(analysis.id)">{{ 'selected.female' | translate }}</uxpl-button>
                                                    </a>
												</li>
											</ng-container>
											<li class="analysis no-analysis">
												<a href="javascript:void(0)">
                                                    <span class="name">
                                                        <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                                                        <span class="name-text">{{ 'manage.process.analysis.message1.modal.step3' | translate }}</span>
                                                    </span>
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-row">
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--secondary" (click)="closeDialog()">{{ 'manage.process.analysis.button.cancel' | translate }}</button></div>
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--primary" matStepperPrevious>{{ 'manage.process.analysis.button.back' | translate }}</button></div>
					<div class="flex-item width-xs-333"><button class="pl-button pl-button--primary" (click)="createNewFolder()" [disabled]="!isValid()">{{ 'manage.process.analysis.button.save' | translate }}</button></div>
				</div>
			</div>
		</ng-template>
	</mat-step>
</mat-stepper>

<div class="flex-container creation-result" *ngIf="creationLoading == true">
	<div class="flex-row creation-result-body">
		<div class="flex-item justify-content-center flex-direction-column">
			<img width="300" src="assets/images/gif/robot-animation.gif">
			<p>{{ 'manage.process.analysis.message.wait' | translate }}</p>
		</div>
	</div>
	<div class="flex-row footer-buttons">
		<div class="flex-item"><button class="pl-button pl-button--secondary" (click)="closeDialog()">{{ 'close' | translate }}</button></div>
	</div>
</div>

<div class="flex-container creation-result" *ngIf="creationStatus == 'success' && creationLoading == false">
	<div class="flex-row creation-result-body">
		<div class="flex-item justify-content-center flex-direction-column">
			<img width="300" src="assets/images/svg/success-image.svg">
			<p>{{ 'manage.process.analysis.message.success' | translate }}</p>
		</div>
	</div>
	<div class="flex-row footer-buttons">
		<div class="flex-item"><button class="pl-button pl-button--secondary" mat-dialog-close="refresh">Close</button></div>
	</div>
</div>

<div class="flex-container creation-result" *ngIf="creationStatus == 'error' && creationLoading == false">
	<div class="flex-row creation-result-body">
		<div class="flex-item justify-content-center flex-direction-column">
			<img width="300" src="assets/images/svg/error-image.svg">
			<p>{{ 'manage.process.analysis.creation.folder.error' | translate }}</p>
		</div>
	</div>
	<div class="flex-row footer-buttons">
		<div class="flex-item"><button class="pl-button pl-button--secondary" mat-dialog-close="refresh">Close</button></div>
		<div class="flex-item"><button class="pl-button pl-button--secondary" (click)="retry()">Retry</button></div>
	</div>
</div>
