import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { CatalogService, Connection, CreateDataset, Dataset, ElementReference, TCMDatasetConfiguration, DVDatasetConfiguration } from '@tibco/discover-client-lib';
import { FilterMatchMode, TreeNode } from "primeng/api";
import { map } from 'rxjs/operators';
import { DatasetWizard } from '../../../models_ui/dataset';
import { insertTreeNode, pathItemToTreeNode } from '../../../functions/tree';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'dataset-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['../../common/tree-tibco-ux.css', './connections.component.scss']
})
export class NewDatasetConnectionsComponent implements OnInit {

  @Input() dataset: Dataset | CreateDataset;
  @Input() wizard: DatasetWizard;
  @Input() previewColumns: any[];
  @Input() previewData: any[];

  @Output() connectionValid: EventEmitter<{valid: boolean, connection: ElementReference}> = new EventEmitter();
  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();
  // @Output() fileDownload: EventEmitter<string> = new EventEmitter();
  // @Output() uploadFile: EventEmitter<File> = new EventEmitter();
  // @Output() updateFiles: EventEmitter<any> = new EventEmitter();

  @ViewChild('connectiontable', {static: false}) connectiontable;
  @ViewChild('viewTree', {static: false}) viewTree;

  constructor(private catalogService: CatalogService,    
    private translationService: TranslationService,
  ) {}

  searchTerm: string;
  filename: string = null;
  showDeleteConfirm = false;
  placeholderSearch: string;

  // scrollHeight = 'calc(90vh - 450px)';

  connections: Connection[];
  loading:boolean = false;
  selectedConnection: ElementReference;
  tcmTopics: string[] = [];
  tdvViews: string[] = [];
  tdvTree: TreeNode[];
  tcmTopicOptions: {label: string, value: string}[] = [];
  showViewSelection: boolean = false;

  cols = [
    {sortField: 'name', field: 'name', header: 'Connection name'},
    {sortField: 'metadata.createdOn', field: 'metadata.createdOn', header: this.translationService.translate('connection.created.on')},
    {sortField: 'metadata.modifiedOn', field: 'metadata.modifiedOn', header: this.translationService.translate('connection.modified.on')}
  ];

  loadingPreview = false;

  ngOnInit(): void {
    this.translationService.currentLang$.subscribe(() => {
      this.placeholderSearch = this.translationService.translate("connection.placeholder.search")
      this.refresh();
    });

    

    this.refresh();
  }

  handleSearch(event) {
    this.searchTerm = event?.detail?.value;
    this.connectiontable.filterGlobal(this.searchTerm, FilterMatchMode.CONTAINS);
    if (this.searchTerm === '' && this.connectiontable) {
      // A hack to reset the filter, since reset does not seem to do anything
      this.connectiontable.filterGlobal('***$$$', FilterMatchMode.NOT_EQUALS)
    }
  }

  handleSearchViews(event) {
    const searchTerm = event?.detail?.value;
    this.viewTree?._filter(searchTerm);
  }

  handleViewSelection(event) {
    if (event?.node.type === 'view') {
      const selectedView = event.node;
      this.wizard.connectionAddtionalFields['label'] = selectedView.label;
      this.selectAdditionalField(selectedView.data, 'view');
      this.showViewSelection = false;
    }
  }

  selectConnection(connection: Connection) {
    this.selectedConnection = connection;
    switch (this.wizard?.dataSourceType?.type) {
      case 'tcm': {
        this.populateConnectionTopics().subscribe();
        break;
      }
      case 'dv': {
        delete this.wizard.connectionAddtionalFields['view'];
        delete this.wizard.connectionAddtionalFields['label'];
        this.previewData = undefined;
        this.emitConnectionStatus();
        this.populateViews().subscribe();
        this.showViewSelection = true;
        break;
      }
      default: {
        console.warn('Tried to get unsupported connection type: ', this.wizard.dataSourceType.type);
      }
    }
  }

  private populateConnectionTopics() {
    return this.catalogService.getConnectionTopics(this.selectedConnection.id).pipe(
      map(
        resp => {
        this.tcmTopics = resp;
        this.tcmTopicOptions = this.tcmTopics.sort((a,b) => {
          if (a < b) {return -1}
          else if (a === b) {return 0}
          else {return 1}
        }).map(t => {
          return {label: t, value: t}
        })
      })
    );
  }

  private populateViews() {
    this.loading = true;
    return this.catalogService.getConnectionViews(this.selectedConnection.id).pipe(
      map(
        resp => {
          let nodes: TreeNode[] = [];
          resp.forEach(node => {
            const newNode = pathItemToTreeNode(node);
            nodes = insertTreeNode(newNode, nodes);
          })
          this.loading = false;
          this.tdvTree = nodes;
        /*this.tcmTopicOptions = this.tcmTopics.sort((a,b) => {
          if (a < b) {return -1}
          else if (a === b) {return 0}
          else {return 1}
        }).map(t => {
          return {label: t, value: t}
        })*/
      })
    );
  }

  handleSelectAdditionalField(event, fieldName) {
    const value = event.detail.value;
    this.selectAdditionalField(value, fieldName);
  }

  private selectAdditionalField(value: string, fieldName) {
    this.wizard.connectionAddtionalFields[fieldName] = value;
    this.refreshPreview();
  }

  refreshPreview() {
    this.loadingPreview = true;
    if (this.wizard.dataSourceType.type === Dataset.TypeEnum.Tcm) {
      this.catalogService.getTCMPreview(this.selectedConnection.id, this.wizard.connectionAddtionalFields['topic']).subscribe(resp => {
        this.loadingPreview = false;
        console.log('The preview data is', resp);
        this.handlePreviewData.emit({
          previewData: resp
        });
        setTimeout(() => {this.emitConnectionStatus()}, 0);
      });
    }
    if (this.wizard.dataSourceType.type === Dataset.TypeEnum.Dv) {
      this.catalogService.getDVPreview(this.selectedConnection.id, this.wizard.connectionAddtionalFields['view']).subscribe(resp => {
        this.loadingPreview = false;
        console.log('The preview data is', resp);
        this.handlePreviewData.emit({
          previewData: resp
        });
        setTimeout(() => {this.emitConnectionStatus()}, 0);
      });
    }
  }

  emitConnectionStatus() {
    const valid: boolean =
      this.selectedConnection != null &&
      // todo: add tdv condition here
      (
        (this.wizard.dataSourceType.type === Dataset.TypeEnum.Tcm && this.wizard.connectionAddtionalFields['topic'] != null)
      ||
        (this.wizard.dataSourceType.type === Dataset.TypeEnum.Dv && this.wizard.connectionAddtionalFields['view'] != null)
       ) &&
      this.previewData?.length > 0
    this.connectionValid.emit({
      valid,
      connection: this.selectedConnection
    });
  }

  refresh() {
    let type: 'tcm' | 'dv' = 'tcm';
    if (this.wizard.dataSourceType.type == 'dv') {
      type = 'dv';
    }
    this.catalogService.getConnections(type).subscribe(resp => {
      //this.connections = [...resp, ...resp, ...resp];
      this.connections = resp;

      if (this.wizard.editMode) {
        this.selectedConnection = (this.dataset as Dataset).connection;
      } else {
        const connectionId = (this.dataset as CreateDataset).connectionId;
        if (connectionId) {
          this.selectedConnection = {
            id: connectionId
          } as ElementReference
        }
      }

      if (this.selectedConnection && this.connections.find(con => con.id === this.selectedConnection.id)) {
        if (type === Dataset.TypeEnum.Tcm) {
          this.populateConnectionTopics().subscribe(() => {
            if (this.wizard.editMode) {
              const topic = ((this.dataset as Dataset).configuration as TCMDatasetConfiguration).topic;
              if (topic) {
                this.selectAdditionalField(topic, 'topic');
              }
            } else {
              this.emitConnectionStatus();
            }
          });
        } else if (type == Dataset.TypeEnum.Dv) {
          this.populateViews().subscribe(() => {
            if (this.wizard.editMode) {
              const view = ((this.dataset as Dataset).configuration as DVDatasetConfiguration).view;
              if (view) {
                this.selectAdditionalField(view, 'view');
              }
            } else {
              this.emitConnectionStatus();
            }
          });
        }
      } else {
        this.selectedConnection = null;
        this.tcmTopics = [];
        this.tcmTopicOptions = [];

        this.handlePreviewData.emit(null);
      }
    })
  }

  createNewConnection() {

  }
}
