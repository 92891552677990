import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {concatMap, debounceTime} from 'rxjs/operators';
import {NimbusDocument, NimbusLocationConfig} from 'src/app/models_ui/nimbus';
import {
  DocumentationService,
  FolderDetail,
  FolderBreadcrumbs,
  FolderMaps,
  FolderSubfolders
} from '@tibco/discover-client-lib';
import {notifyUser} from '../../functions/message';
import {InternalMessageService} from '../../service/internal-message.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { VisualisationService } from 'src/app/service/visualisation.service';

@Component({
  selector: 'nimbus-doc-location',
  templateUrl: './document-location.component.html',
  styleUrls: ['./document-location.component.scss']
})

export class ProcessDocumentLocationComponent implements OnInit, AfterViewInit {

  @Input() document: NimbusDocument;
  @Input() config: NimbusLocationConfig;
  @Input() listHeight: number;
  @Output() locationSelected: EventEmitter<any> = new EventEmitter();
  @Output() mapSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('newFolderInput') newFolderInput: ElementRef;
  @ViewChild('scrollableFolderContainer') scrollableFolderContainer: ElementRef;

  folders: FolderSubfolders[];
  maps: FolderMaps[];

  parentFolderId: number;
  breadcrumbs: FolderBreadcrumbs[];
  selectedFolder: FolderDetail;
  selectedMap: FolderMaps;
  nimbusRootFolder: number = 0;
  nimbusOrgFolder: number;

  //  newFolderName: string;
  // whether enter create new folder mode
  // createNewFolder: boolean = false;
  // whether the new folder name is
  editNewFolderMode = false;

  public folderScrolled: Subject<any> = new Subject<any>();

  public subFoldersCache: { [key: string]: FolderDetail };

  constructor(
    private documentationMS: DocumentationService,
    private messageService: InternalMessageService,
    private snackBarService: SnackbarService,
    private translationService: TranslationService,
    private visualisationService: VisualisationService,
  ) {
  }

  ngOnInit(): void {
    this.visualisationService.getConfiguration().subscribe({
      next: (data) => {
        if(data.nimbusFolderId){
          this.nimbusOrgFolder = parseInt(data.nimbusFolderId);

          this.subFoldersCache = {};
          if (this.document.folder) {
            this.selectedFolder = this.document.folder;
          }
          this.maps = [];
          this.getFolders(this.nimbusOrgFolder).subscribe(() => {
            setTimeout(() => {
              this.loadSubFolders()
            }, 100);
          });
    
          this.listHeight = this.listHeight || 190;
        }
      },
      error: (error) => {
        console.error('Get Configuration error: '+error);
        this.snackBarService.openSnackBar(this.translationService.translate('generic.error'), this.translationService.translate('close'),'error');
      }
    });
  }

  ngAfterViewInit(): void {
    this.folderScrolled
      .pipe(debounceTime(500))
      .subscribe(_re => {
        this.loadSubFolders();
      });

  }

  loadSubFolders() {
    const folderRows = this.scrollableFolderContainer.nativeElement.querySelectorAll('.folder-row');
    if (folderRows && folderRows.length > 0) {
      const containerRect = this.scrollableFolderContainer.nativeElement.getBoundingClientRect();
      const containerRectBottom = containerRect.top + containerRect.height;
      let findVisible = false;
      for (const row of folderRows) {
        const visible = this.isRowVisible(containerRectBottom, row.getBoundingClientRect());
        if (visible) {
          findVisible = true;
          const id = row.id;
          if (!this.subFoldersCache[id]) {
            this.documentationMS.getFolderDetail(id).subscribe(resp => {
              this.subFoldersCache[id] = resp;
            });
          }
        }
        if (!visible && findVisible) {
          // find an invisible row after finding a visible row, no need to continue, all the rows after it are invisible
          break;
        }
      }
    }
  }

  // if the row starts to show, even a little bit, return true
  isRowVisible(containerBottom, row) {
    return row.top < containerBottom;
  }

  getFolders(parentFolderId: number, newFolderId?: number): Observable<any> {
    return this.documentationMS.getFolderDetail(parentFolderId).pipe(
      concatMap(resp => {
        this.folders = resp.subFolders;
        if (this.config.select === 'process') {
          this.maps = resp.maps;
        }
        // Remove parent folder and parend bredcrumb
        let clearResp: FolderDetail;
        if(this.nimbusOrgFolder == parentFolderId){
          clearResp = this.removeParentFolder(resp);
        }else{
          clearResp = resp;
        }
        this.breadcrumbs = clearResp.breadcrumbs || [];
        if (newFolderId) {
          this.hoistNewFolder(newFolderId);
          this.documentationMS.getFolderDetail(newFolderId).subscribe(folderDet => {
            this.subFoldersCache[newFolderId] = folderDet;
          });
        }
        return of(true);
      })
    );
  }

  private removeParentFolder(folderDatail: FolderDetail){
    let clearFolderDetail: FolderDetail = {} as FolderDetail;
    if(folderDatail.breadcrumbs && folderDatail.breadcrumbs.length > 0){
      for(let [i, v] of folderDatail.breadcrumbs.entries()){
        if(v.mapFolderId == folderDatail.parentMapFolderId){
          folderDatail.breadcrumbs.splice(i, 1);
          break;
        }
      }
    }
    if(folderDatail.parentMapFolderId == this.nimbusRootFolder){
      delete folderDatail.parentMapFolderId;
    }
    clearFolderDetail = folderDatail;
    return clearFolderDetail;
  }

  private hoistNewFolder(newFolderId: number) {
    let index;
    for (let i = 0; i < this.folders.length; i++) {
      if (this.folders[i].mapFolderId === newFolderId) {
        index = i;
        break;
      }
    }

    if (index != null) {
      const deletedFolder = this.folders.splice(index, 1);
      this.folders.unshift(deletedFolder[0]);
    }
  }

  selectFolder(folder: FolderDetail) {
    if (this.config.select !== 'folder') {
      return;
    }
    this.selectedFolder = folder;
    this.document.folder = folder;

    this.updateSelectedFolder();
  }

  selectMap(map: FolderMaps) {
    if (this.config.select !== 'process') {
      return;
    }
    this.selectedMap = map;
    this.document.map = map;

    this.mapSelected.emit(map);
  }

  naviToFolder(index: number) {
    // breadcrumbs is slice(1) in html, so here need to add 1 to get the correct parent id
    // in the new API, root folder has id 0
    const parentFolderId = index >= 0 ? this.breadcrumbs[index + 1].mapFolderId : 0;
    if(parentFolderId == this.nimbusRootFolder){
      return;
    }
    this.getFolders(parentFolderId).subscribe();
    this.selectedFolder = null;
    this.updateSelectedFolder();
  }

  goIntoFolder(folder: FolderDetail, event: Event) {
    event.stopPropagation();
    this.scrollableFolderContainer.nativeElement.scrollTo(0, 0);
    this.getFolders(folder.mapFolderId).subscribe(() => {
      setTimeout(() => {
        this.loadSubFolders()
      }, 100);
    });
    this.selectedFolder = null;
    this.parentFolderId = folder.mapFolderId;
    this.updateSelectedFolder();
  }

  clickOnFolderName(folder: FolderDetail, event: Event) {
    if (this.canGoIntoFolder(folder)) {
      this.goIntoFolder(folder, event);
    }
  }

  // handleUpdate = (event, fieldName) => {
  //
  // }

  private updateSelectedFolder = (): void => {
    if (this.selectedFolder) {
      this.document.path = '/' + ['Processes', ...this.breadcrumbs.slice(1).map(folder => folder.name), this.selectedFolder.name].join('/');
      this.document.folder = this.selectedFolder;
      this.document.breadcrumbs = this.breadcrumbs;
    }
    this.locationSelected.emit();
  }

  public enterCreateNewFolderMode = () => {
    if (!this.editNewFolderMode) {
      this.editNewFolderMode = true;
      setTimeout(() => {
        this.newFolderInput.nativeElement.focus();
      }, 100);
    }

  }

  // public onFolderNameKeys = ($event) => {
  // }

  public createNewFolder(folderName: string) {
    if (!this.parentFolderId) {
      this.parentFolderId = this.nimbusOrgFolder;
    }
    this.documentationMS.createFolder({
      parentFolderId: this.parentFolderId,
      name: folderName
    }).subscribe((response) => {
      // notifyUser('INFO', error.message, this.messageService)
      this.editNewFolderMode = false;
      this.getFolders(this.parentFolderId, response.mapFolderId).subscribe();
    }, error => {
      console.log(error);
      notifyUser('ERROR', error.message, this.messageService)
    });
  }

  public onNewFolderNameEnter(folderName: string) {
    if (folderName && folderName.replace(/\s/g, '') !== '') {
      this.createNewFolder(folderName.trim());
    } else {
      this.editNewFolderMode = false;
    }
  }

  public onNewFolderNameBlur(folderName: string) {
    this.onNewFolderNameEnter(folderName);
  }

  onFoldersScroll() {
    this.folderScrolled.next({});
  }

  public canGoIntoFolder(folder: FolderDetail) {
    return folder && this.subFoldersCache[folder.mapFolderId] && (
      (this.subFoldersCache[folder.mapFolderId].subFolders && this.subFoldersCache[folder.mapFolderId].subFolders.length && this.config.select === 'folder') ||
      (this.subFoldersCache[folder.mapFolderId].maps && this.subFoldersCache[folder.mapFolderId].maps.length && this.config.select === 'process')
    )
  }

}
