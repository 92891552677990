import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  concatMap,
  delay,
  filter,
  map,
  repeatWhen,
  take,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProcessAnalysisTableComponent } from '../../components/process-analysis/process-analysis-table/process-analysis-table.component';
import {
  Analysis,
  CreateAnalysis,
  RepositoryService,
} from '@tibco/discover-client-lib';
import { InternalMessageService } from '../../service/internal-message.service';
import {
  convertAnalysisToCreateAnalysis,
  convertCreateAnalysisToUpdate,
} from 'src/app/functions/analysis';
import { hierarchicalFolders } from 'src/app/components/new-analysis/basic-info/basic-info.component';
import { openStopCreationDialog } from '../new-analysis/new-analysis.component';
import { NewAnalysisStopCreationComponent } from 'src/app/components/new-analysis/stop-creation/stop-creation.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalysisFolderComponent } from 'src/app/components/analytics/analysis-folder/analysis-folder.component';
import { CommonService } from 'src/app/service/common.service';
import { isNumeric } from 'src/app/functions/common';
import { TranslationService } from 'src/app/service/translate.service';

export interface FolderEntity {
  name: string;
  path: string;
  selected?: boolean;
  children?: ChildrenEntity[];
}
export interface ChildrenEntity {
  name: string;
  path: string;
  selected?: boolean;
  children?: ChildrenEntity[];
}

@Component({
  selector: 'app-process-analysis',
  templateUrl: './manage-process-analysis.component.html',
  styleUrls: ['./manage-process-analysis.component.scss'],
})
export class ManageProcessAnalysisComponent implements OnInit, AfterViewInit {
  @ViewChild('paTable', { static: false })
  paTable: ProcessAnalysisTableComponent;

  cases: Analysis[] = [];
  newAnalysis: CreateAnalysis;
  analysisId: string = '';
  casesByFolder: any = {};
  casesByFolderArray: any[] = [];
  folders: any[] = [];
  activeFolder: string = localStorage.getItem('folderFocus');
  search = '';
  isLoading = true;
  statusMap: { [key: string]: any } = {};
  folderFocus: string = 'General';
  hierarchicalFolders: FolderEntity[] = [
    {
      name: 'General',
      path: 'General',
      children: [],
    },
  ];
  openFolders: any[] = [];

  paFocus: string[];
  dialogRef: MatDialogRef<NewAnalysisStopCreationComponent, any>;
  dialogRefAnalysisFolder: MatDialogRef<AnalysisFolderComponent, any>;

  sidebarWidth: number = 300;
  sidebarWidthMax: number = 500;
  sidebarWidthMin: number = 200;
  placeholderSearch: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: InternalMessageService,
    private repositoryMS: RepositoryService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.placeholderSearch = this.translationService.translate("manage.process.analysis.placeholder.search")

    this.translationService.currentLang$.subscribe(() => {
      this.placeholderSearch = this.translationService.translate("manage.process.analysis.placeholder.search")
      this.route.queryParams.pipe(take(1)).subscribe((params) => {
        if (params.focus) {
          this.paFocus = params.focus.split(',');
        }
      });
      let analysisSidebarWidth = localStorage.getItem('analysisSidebarWidth');
      if (isNumeric(analysisSidebarWidth)) {
        this.sidebarWidth = parseInt(analysisSidebarWidth);
      }
    });

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.focus) {
        this.paFocus = params.focus.split(',');
      }
    });
    let analysisSidebarWidth = localStorage.getItem('analysisSidebarWidth');
    if (isNumeric(analysisSidebarWidth)) {
      this.sidebarWidth = parseInt(analysisSidebarWidth);
    }
  }

  ngAfterViewInit(): void {
    this.folderFocus = localStorage.getItem('folderFocus');
    this.placeholderSearch = this.translationService.translate("manage.process.analysis.placeholder.search")
    this.refresh();
  }

  public refresh(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.repositoryMS
        .getAnalysises()
        .pipe(
          map((analysisList) => {
            this.cases = analysisList
              .filter((el) => el.metadata.status !== 'Completed')
              .sort((a, b) =>
                a.metadata?.modifiedOn > b.metadata?.modifiedOn
                  ? -1
                  : b.metadata?.modifiedOn > a.metadata?.modifiedOn
                  ? 1
                  : 0
              );
            // this.cases = []
            if (this.cases.length > 0) {
              // start progress query for those who doesn't have status yet
              this.startPollingStatus();
              // Assign folder to cases
              for (let value of this.cases) {
                let aName = value.name.split('||');
                let sName = aName.pop();
                if (aName.length > 0) {
                  value.folder = aName.join('||');
                  value.name = sName;
                } else {
                  value.folder = 'General';
                }
              }
              // Define folders array
              this.folders = [];
              for (let item of this.cases) {
                if (!this.folders.includes(item.folder)) {
                  this.folders.push(item.folder);
                }
              }
              this.folders.sort();
              // Define hierarchical folder array
              this.hierarchicalFolders = hierarchicalFolders(
                this.folders,
                'General'
              );
              // this.hierarchicalFolders = [{
              //   "name": "General",
              //   "path": "General",
              //   "children": []
              // }];
              // for(let folder of this.folders){
              //   let aFolder = folder.split("||");
              //   let sParent = '';
              //   let sChild = '';
              //   for(let [i, v] of aFolder.entries()){
              //     if(i == 0 && v != 'General'){
              //       if(this.getArrayIndex(this.hierarchicalFolders[0].children, v) == -1){
              //         this.hierarchicalFolders[0].children.push({
              //           "name": v,
              //           "path": aFolder[0],
              //           "children": []
              //         });
              //       }
              //     }else if(i == 1){
              //       sParent = aFolder[i - 1];
              //       let iParent = this.getArrayIndex(this.hierarchicalFolders[0].children, sParent);
              //       let iCurrent = this.getArrayIndex(this.hierarchicalFolders[0].children[iParent].children, v);
              //       if(iCurrent == -1){
              //         this.hierarchicalFolders[0].children[iParent].children.push({
              //           "name": v,
              //           "path": aFolder[0]+'||'+aFolder[1],
              //           "children": []
              //         });
              //       }
              //     }else if(i == 2){
              //       sParent = aFolder[i - 2];
              //       sChild = aFolder[i - 1];
              //       let iParent = this.getArrayIndex(this.hierarchicalFolders[0].children, sParent);
              //       let iChild = this.getArrayIndex(this.hierarchicalFolders[0].children[iParent].children, sChild);
              //       let iCurrent = this.getArrayIndex(this.hierarchicalFolders[0].children[iParent].children[iChild].children, v);
              //       if(iCurrent == -1){
              //           this.hierarchicalFolders[0].children[iParent].children[iChild].children.push({
              //             "name": v,
              //             "path": folder
              //           })
              //         // }
              //       }
              //     }
              //   }
              // }
              let groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                  (rv[x[key]] = rv[x[key]] || []).push(x);
                  return rv;
                }, {});
              };
              this.casesByFolder = groupBy(this.cases, 'folder');
              for (let key in this.casesByFolder) {
                this.casesByFolderArray.push(this.casesByFolder[key]);
              }
              // Assign folders array to cases
              for (let value of this.cases) {
                value.folders = [];
                for (let folder of this.folders) {
                  value.folders.push({
                    label: folder,
                    value: folder,
                  });
                }
              }
            }
          })
        )
        .subscribe(
          () => {
            this.isLoading = false;
            resolve(true);
            setTimeout(this.selectFolder, 5);
          },
          () => {
            this.isLoading = false;
            reject(false);
          }
        );
    });
  }

  private startPollingStatus() {
    this.stopPollingStatus();
    for (const analysis of this.cases) {
      const analysisId = analysis.id;
      if (analysis.metadata && analysis.metadata.status === 'Process mining') {
        const progress = {
          message: 'Process mining',
          stop: false,
        };
        this.pollAnalysisStatus(analysisId, progress).subscribe((resp) => {
          if (resp.metadata && resp.metadata.state) {
            analysis.metadata = Object.assign({}, resp.metadata);
            // analysis.actions = resp.actions;
            progress.stop = true;
            // this.statusMap[analysis.data.name] = null;
          } else {
            // stopped by setting progress.stop = true
            progress.message = 'Stopped';
          }
        });
        this.statusMap[analysis.name] = progress;
      }
    }
  }

  private pollAnalysisStatus(
    analysisId: string,
    progress: any
  ): Observable<any> {
    return this.repositoryMS
      .getAnalysisStatus(analysisId)
      .pipe(
        repeatWhen((obs) => obs.pipe(delay(2000))),
        filter((data) => {
          if (data.progress !== 0) {
            progress.percentage = data.progress;
            progress.status = data.description;
          }
          // stop polling status once the progression is 100
          // if(data.progress === 100) {
          if (
            data.status.toLowerCase() === 'ready' ||
            data.status.toLowerCase() === 'error'
          ) {
            this.refresh();
          }
          return progress.stop === true || data.progress === 100;
        }),
        take(1)
      )
      .pipe(
        concatMap(() => {
          return this.repositoryMS.getAnalysisDetails(analysisId).pipe(
            filter(
              (data) =>
                progress.stop === true ||
                data.metadata.status !== 'Process mining'
            ),
            take(1)
          );
        })
      );
  }

  private stopPollingStatus() {
    for (const analysisId in this.statusMap) {
      if (this.statusMap[analysisId]) {
        this.statusMap[analysisId].stop = true;
      }
    }
    this.statusMap = {};
  }

  public openAnalytics = (analysis: any): void => {
    // catch double click event and ignore if in process mining
    if (analysis?.state.toLowerCase() !== 'process mining') {
      if (analysis?.templateId) {
        this.router.navigate(['/discover/analytics', analysis.id]);
      } else {
        this.router.navigate(['/discover/select-template', analysis?.id]);
      }
    }
  };

  public showNewAnalysis = (): void => {
    this.repositoryMS.getAnalysises().subscribe((analysisList: Analysis[]) => {
      let analysises = analysisList;
      let analysisOnProcessMining = false;
      let analysisOnProcessMiningId;
      for (let an of analysises) {
        if (an.metadata.status.toLowerCase() === 'process mining') {
          analysisOnProcessMining = true;
          analysisOnProcessMiningId = an.id;
          break;
        }
      }
      if (analysisOnProcessMining) {
        console.log('There is another analysis on process mining state');
        openStopCreationDialog(
          analysisOnProcessMiningId,
          this.dialog,
          this.dialogRef
        );
      } else {
        this.router.navigate(['/discover/new-analysis']);
      }
    });
  };

  public showTemplates = (): void => {
    this.router.navigate(['/discover/templates']);
  };

  public handleSearch = ($event): void => {
    this.search = $event.detail.value;
  };

  public selectFolder = (event?): void => {
    if (event == undefined) {
      this.folderFocus = localStorage.getItem('folderFocus')
      if (!this.folderFocus) {
        this.folderFocus = 'General';
      }
      event = document.querySelector(
        '[folder-name="' + this.folderFocus + '"]'
      );
      const tabs = document.querySelectorAll(
        '.process-analysis-folder-wrapper ul li a'
      );
      tabs.forEach((element) => {
        element.classList.remove('active');
      });
      if(event){
        event.classList.add('active');
      }
      if (!this.openFolders.includes(this.folderFocus)) {
        this.openFolders.push(this.folderFocus);
      }
      this.openTree();

      const trToDeactivate = document.querySelectorAll('#toHightlight');
      trToDeactivate.forEach((element) => {
        element.classList.remove('active');
      });
      const trToActivate = document.querySelectorAll(
        '#toHightlight[data-folder="' + this.folderFocus + '"]'
      );
      trToActivate.forEach((element) => {
        element.classList.add('active');
      });
    }
    if (event && event.target.localName == 'a') {
      this.folderFocus = event.target.getAttribute('folder-name');
      localStorage.setItem("folderFocus",this.folderFocus)
      const tabs = document.querySelectorAll(
        '.process-analysis-folder-wrapper ul li a'
      );
      tabs.forEach((element) => {
        element.classList.remove('active');
      });
      event.target.classList.add('active');

      const trToDeactivate = document.querySelectorAll('#toHightlight');
      trToDeactivate.forEach((element) => {
        element.classList.remove('active');
      });
      const trToActivate = document.querySelectorAll(
        '#toHightlight[data-folder="' + this.folderFocus + '"]'
      );
      trToActivate.forEach((element) => {
        element.classList.add('active');
      });
    }
    if (event && event.target.localName == 'span') {
      let folderPath = event.target.parentElement.getAttribute('folder-name');
      let open = false;
      for (let classV of event.target.parentElement.parentElement.classList) {
        if (classV == 'open') {
          open = true;
          break;
        }
      }
      if (open == true) {
        event.target.parentElement.parentElement.classList.remove('open');
        if (this.openFolders.includes(folderPath)) {
          this.openFolders.splice(this.openFolders.indexOf(folderPath), 1);
        }
      } else {
        event.target.parentElement.parentElement.classList.add('open');
        if (!this.openFolders.includes(folderPath)) {
          this.openFolders.push(folderPath);
        }
      }
    }
    
  };

  public async handleActionSelected(event) {
    if (event.name.toLowerCase() === 'rerun') {
      this.isLoading = true;
      // Send message to clear SF report
      this.messageService.sendMessage('clear-analytic.topic.message', 'OK');
      await this.repositoryMS
        .rerunAnalysis(event.analysis?.id, event.analysis?.version)
        .toPromise();
      this.refresh();
      return;
    }
    if (event.name.toLowerCase() === 'archive') {
      this.isLoading = true;
      try {
        await this.repositoryMS
          .archiveAnalysis(event.analysis?.id, event.analysis?.version)
          .toPromise();
        this.refresh();
        return;
      } catch (error) {
        this.isLoading = false;
        console.error('Error occurred while archiving analysis:', error);
        throw error;
      }
    }
    if (event.name.toLowerCase() === 'delete') {
      this.isLoading = true;
      await this.repositoryMS
        .deleteAnalysis(event.analysis?.id, event.analysis?.version)
        .toPromise();
      this.refresh();
      return;
    }
    if (event.name.toLowerCase() === 'abort') {
      this.isLoading = true;
      await this.repositoryMS
        .abortAnalysis(event.analysis?.id, event.analysis?.version)
        .toPromise();
      this.refresh();
      return;
    }
    if (event.name.toLowerCase() === 'edit') {
      this.router.navigate(['/discover/edit-analysis', event.analysis?.id]);
    } else if (event.name === 'change-template') {
      this.router.navigate(['/discover/select-template', event.analysis?.id]);
    }
  }

  public open(id: any) {
    localStorage.setItem("folderFocus",this.folderFocus)
    this.router.navigate(['/discover/analytics', id]);
  }

  dragStartAnalysis(analysisId: string) {
    localStorage.setItem('dragStartAnalysisId', '');
    this.analysisId = analysisId;
  }
  allowDrop(event) {
    event.preventDefault();
  }
  dropAnalysis(event, folder) {
    event.preventDefault();
    event.target.classList.remove('dropEnter');
    this.folderFocus = folder;
    let analysisIdFromTable = localStorage.getItem('dragStartAnalysisId');
    if (analysisIdFromTable != null && analysisIdFromTable != '') {
      this.analysisId = analysisIdFromTable;
      localStorage.setItem('dragStartAnalysisId', '');
    }
    this.repositoryMS
      .getAnalysisDetails(this.analysisId)
      .subscribe((analysis) => {
        this.newAnalysis = convertAnalysisToCreateAnalysis(analysis);
        let aName = this.newAnalysis.name.split('||');
        let sName = '';
        sName = aName.pop();
        this.newAnalysis.name = folder + '||' + sName;
        if (this.existsAnalysis(this.cases, this.newAnalysis.name) == false) {
          this.repositoryMS
            .updateAnalysis([
              convertCreateAnalysisToUpdate(this.newAnalysis, this.analysisId),
            ])
            .subscribe(
              () => {
                const message = `Analysis ${sName} has been moved successfully.`;
                this.messageService.sendMessage(
                  'news-banner.topic.message',
                  message
                );
                this.refresh();
              },
              (error) => {
                console.error('Error updating the analysis.', error);
              }
            );
        } else {
          const mes = {
            type: 'ERROR',
            message:
              'A file with the same name exists in the destination folder.',
          };
          this.messageService.sendMessage(
            'news-banner.topic.message',
            'MESSAGE:' + JSON.stringify(mes)
          );
        }
      });
  }
  dragEnterAnalysis(event) {
    event.target.classList.add('dropEnter');
  }
  dragLeaveAnalysis(event) {
    event.target.classList.remove('dropEnter');
  }
  public getArrayIndex(arr, name, fieldName?) {
    if (!fieldName) {
      fieldName = 'name';
    }
    for (let [i, el] of arr.entries()) {
      if (el[fieldName] == name) {
        return i;
      }
    }
    return -1;
  }

  existsAnalysis(arr, name) {
    for (let el of arr) {
      let path = el.folder + '||' + el.name;
      if (path == name) {
        return true;
      }
    }
    return false;
  }

  openTree() {
    if (this.openFolders.length > 0) {
      for (let folderPath of this.openFolders) {
        let folder = document.querySelector(
          '.process-analysis-folder-wrapper ul li a[folder-name="' +
            folderPath +
            '"]'
        );
        if (folder) {
          folder.parentElement.classList.add('open');
          this.openTreeParent(folder.parentElement);
        }
      }
    }
  }

  openTreeParent(li) {
    let parent = li.parentElement.parentElement;
    if (parent.tagName == 'LI' && !parent.classList.contains('open')) {
      parent.classList.add('open');
      this.openTreeParent(parent);
    }
  }

  newAnalysisFolder() {
    const title = this.translationService.translate('manage.process.analysis.title.modal');
    if (this.dialogRefAnalysisFolder) {
      this.dialogRefAnalysisFolder.close();
    } else {
      this.dialogRefAnalysisFolder = this.dialog.open(AnalysisFolderComponent, {
        width: '720px',
        height: 'auto',
        data: {
          title: title,
          hierarchicalFolders: this.hierarchicalFolders,
          foldersList: this.folders,
          currentFolder: 'General',
          analysisList: this.cases,
        },
        panelClass: 'new-analysis-folder',
      });
      this.dialogRefAnalysisFolder.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'refresh') {
            this.refresh();
          }
        }
        this.dialogRefAnalysisFolder = undefined;
      });
    }
  }

  handleCompareClick() {
    let analysis: Analysis[] = [];
    this.commonService.doCompare.emit(analysis);
  }
}
