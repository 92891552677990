<div class="fixActionRow">
  <h1 mat-dialog-title>{{ "process.analysis.model.upload" | translate }}</h1>
  <div mat-dialog-content class="dialog-content" [style.visibility]="noNimbusSubscription || showResult ? 'hidden' : 'visible'">
    <mat-progress-bar *ngIf="isLoadoading" mode="indeterminate"></mat-progress-bar>
    <uxpl-stepper [config]="config" (stepClickedIdx)="handleStepClick($event)" [hideNumbers]="!isNewDataSet">
      <div *ngIf="display('upload-doc')" style="display: flex; flex-direction: column; height: 100%;" slot="upload-doc">
        <upload-document style="height: 100%;"
          step="upload-doc"
          (status)="handleStatus($event)"
          (handleGraphJson)="handleGraphJson($event)"
          [loadedGraphJson]="graphJson"
          (handlePreview)="handlePreview($event)"
          [loadedPreview]="preview"
          (handlePreviewData)="handlePreviewData($event)"
          (handleFilename)="handleFilename($event)"
          [loadedFilename]="filename"
          (handleSuccess)="handleSuccess($event)"
          [loadedSuccess]="successUpload"
          filetypes="bpmn"
          filesize="1000"
          [analysisId]="dialogData.analysisId"
          size="small"></upload-document>
      </div>
      <div *ngIf="display('preview-doc')" style="display: flex; flex-direction: column" slot="preview-doc">
        <figure id="preview" [document]="document" step="preview-doc" (status)="handleStatus(previewStatus)" (handlePreviewData)="handlePreviewData($event)">
          <img [src]="'data:image/png;base64,'+preview"/>
        </figure>
      </div>
      <div *ngIf="display('map-diagram')" style="display: flex; flex-direction: column" slot="map-diagram">
        <upload-document-map id="map"
          step="map-diagram"
          (status)="handleStatus($event)"
          (handleNewGraphJson)="handleNewGraphJson($event)"
          [newGraphJson]="newGraphJson"
          (handleLoadedDataSource)="handleLoadedDataSource($event)"
          [loadedDataSource]="loadedDataSource"
          [activities]="activities"
          [loadedGraphJson]="graphJson"
          (handlePreviewData)="handlePreviewData($event)">
        </upload-document-map>
      </div>
      <div *ngIf="display('nimbus-doc-basic-info')" style="display: flex; flex-direction: column" slot="nimbus-doc-basic-info">
        <nimbus-doc-basic-info [document]="document" step="nimbus-doc-basic-info" (status)="handleStatus($event)" (handlePreviewData)="handlePreviewData($event)"></nimbus-doc-basic-info>
      </div>
      <div *ngIf="display('nimbus-doc-confirmation')" style="display: flex; flex-direction: column" slot="nimbus-doc-confirmation">
        <nimbus-doc-confirmation [document]="document" step="nimbus-doc-confirmation" (status)="handleStatus($event)" (handlePreviewData)="handlePreviewData($event)"></nimbus-doc-confirmation>
      </div>
    </uxpl-stepper>

  </div>
  <div>
    <div mat-dialog-actions *ngIf="!noNimbusSubscription && !showResult">
      <uxpl-button type="secondary" (clicked)="onCancel()">Cancel</uxpl-button>
      <uxpl-button [disabled]="handleDisablePrevButton()" [hidden]="hide('prev')" type="secondary" (clicked)="changeTab(-1)">{{ "previous" | translate }}</uxpl-button>
      <uxpl-button [disabled]="handleDisableNextButton()" [hidden]="hide('next')" (clicked)="changeTab(1)">{{ "next" | translate }}</uxpl-button>
      <uxpl-button [hidden]="hide('finish')" (clicked)="createProcessDocument()">{{ "create" | translate }}</uxpl-button>
    </div>
    <div mat-dialog-actions *ngIf="!showResult && noNimbusSubscription">
      <uxpl-button type="secondary" (clicked)="goToSubscriptions()">{{ "process.analysis.model.go.to.subscriptions" | translate }}</uxpl-button>
      <uxpl-button (clicked)="closeDialog()">{{ "close" | translate }}</uxpl-button>
    </div>
    <div mat-dialog-actions *ngIf="showResult && success">
      <!-- <uxpl-button type="secondary" (clicked)="viewProcessDocument()">View process document</uxpl-button> -->
      <uxpl-button (clicked)="closeDialog()">{{ "close" | translate }}</uxpl-button>
    </div>
    <div mat-dialog-actions *ngIf="showResult && !success">
      <uxpl-button (clicked)="closeDialog()">{{ "close" | translate }}</uxpl-button>
    </div>
  </div>

  <div *ngIf="progress.enabled" class="modal-backdrop">
    <progress-screen class="overlay-container" [message1]="progress.message1" [message2]="progress.message2" [percentage]="progress.percentage" [status]="progress.status"></progress-screen>
  </div>

  <div *ngIf="noNimbusSubscription" class="modal-backdrop">
    <div class="overlay-container">
      <div>
        <img width="208" height="160" src="assets/images/svg/missing-subscription.svg">
      </div>
      <div >
        <div class="result-text">{{ "process.analysis.model.nimbus.access" | translate }}</div>
        <div class="result-msg">{{ "process.analysis.model.nimbus.subscription.1" | translate }}<span><a (click)="goToSubscriptions()">{{ "process.analysis.model.nimbus.subscription.2" | translate }}</a></span>{{ "process.analysis.model.nimbus.subscription.3" | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="showResult && success" class="modal-backdrop">
    <div class="overlay-container">
      <div>
        <img width="208" height="160" src="assets/images/svg/success-process-document.svg">
      </div>
      <div >
        <div class="result-text">{{ "process.analysis.model.nimbus.success" | translate }}</div>
        <div class="result-msg">{{ "process.analysis.model.nimbus.success.message" | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="showResult && !success" class="modal-backdrop">
    <div class="overlay-container">
      <div>
        <img width="208" height="160" src="assets/images/svg/error-process-document.svg">
      </div>
      <div >
        <div class="result-text">{{ "process.analysis.model.nimbus.error" | translate }}</div>
        <div class="result-msg">{{ "process.analysis.model.nimbus.error.message" | translate }}<span *ngIf="errorMsg">{{errorMsg}}</span></div>
      </div>
    </div>
  </div>
</div>
