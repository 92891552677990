import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { NimbusDocument, NimbusLocationConfig } from 'src/app/models_ui/nimbus';
import { NewAnalysisStepStatus } from '../../../models_ui/discover';
import {DocumentationService} from '@tibco/discover-client-lib';

@Component({
  selector: 'nimbus-doc-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class NewProcessDocumentBasicInfoComponent implements OnInit {

  @Input() document: NimbusDocument;
  @Input() step: string;
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter<NewAnalysisStepStatus>();

  nameError = false;

  public nameChanged: Subject<any> = new Subject<any>();

  readonly documentLocationConfig: NimbusLocationConfig = {
    newFolder: true,
    select: 'folder'
  }

  constructor(
    private documentationMS: DocumentationService

  ) { }

  ngOnInit(): void {
    this.updateStatus();
  }

  locationSelected = (_event) => {
    this.checkName();
  }

  private checkName() {
    let sameName = false;
    if (this.document.folder && this.document.name) {
      this.documentationMS.getFolderDetail(this.document.folder.mapFolderId).subscribe(resp => {
        const maps = resp.maps;
        if (maps) {
          const mapsOfSameName = maps.filter(map => map.name === this.document.name.trim());
          if (mapsOfSameName.length > 0) {
            sameName = true;
          }
        }
        this.nameError = sameName;
        this.updateStatus();
      });
    } else {
      this.updateStatus();
    }
  }

  handleUpdate = (event, fieldName) => {
    const value = event.detail.value;
    if (fieldName === 'name') {
      this.document.name = value;
      this.checkName();
    } else {
      this.updateStatus();
    }
  }

  private updateStatus = (): void => {
    const status = !!(this.document.folder && this.document.name) && !this.nameError;
    const stepStatus = {
      step: this.step,
      completed: status
    } as NewAnalysisStepStatus;

    this.status.emit(stepStatus);
  }

}
