<div class='data-preview-container'>
  <div class="side-section">
    <div class="section-top" *ngIf="showTopSection">
      <div *ngIf="!showMapping" class="left-header"> {{ 'data.preview.field.additional' | translate }}</div>
      <uxpl-icon *ngIf="!showMapping" class='icon' height="24px" width="24px" icon="icon-switch-mappings"
                 matTooltip="Switch to Mappings"
                 matTooltipClass="tTip"
                 (click)="showMapping = !showMapping;"></uxpl-icon>
      <div *ngIf="showMapping" class="left-header"> {{ 'data.preview.field.mappings' | translate }}</div>
      <uxpl-icon *ngIf="showMapping" class='icon' height="24px" width="24px" icon="icon-switch-options"
                 matTooltip="Switch to Options"
                 matTooltipClass="tTip"
                 (click)="showMapping = !showMapping;"></uxpl-icon>
    </div>
    <map-panel *ngIf="showMapping" class='map-panel' [mapping]="mapping" [datasetId]="selectedDataset"
               [availableColumns]="availableColumns" advanceMode="false"
               (status)="updateStatus($event)" [doAutoMap]="false"
    ></map-panel>
    <uxpl-left-nav-multi *ngIf='showMenu' [hidden]="showMapping" #leftNav [tabs]="leftNavTabs"
                         (selected)="handleClick($event)" (collapsed)="showTopSection = !$event.detail"
                         collapse></uxpl-left-nav-multi>
  </div>
  <div class='spotfire-viewer-container' *ngIf="spotfireServer && previewDXPLocation">
    <spotfire-viewer #analysis
                     style="height: 100%;"
                     [url]="spotfireServer"
                     [path]="previewDXPLocation"
                     version="10.10"
                     [parameters]="analysisParameters"
                     [customization]="customization"
                     [page]="page"
                     (document)="setDocument($event)">
    </spotfire-viewer>
  </div>
</div>

