<div class="activation-overlay" *ngIf="processing">
  <!-- <uxpl-spinner message="Processing ..."></uxpl-spinner> -->
  <pulse-loading></pulse-loading>
</div>
<div class="fixActionRow">
  <div class="dialog-header-content mat-dialog-title">
    <div class="connection-dialog-title">{{ 'new.connection.wizard.title' | translate }}</div>
    <uxpl-icon height="36px" icon="pl-icon-close" size="20px" (click)="close()"></uxpl-icon>
  </div>
  <div mat-dialog-content class="dialog-content dialog-content-borders">
      <uxpl-notification *ngIf="notification" [type]="notification?.type" [message]="notification?.message" (closed)="handleCloseNotification()"></uxpl-notification>
      <connection-type-selection-form *ngIf="isTypeSelection" (typeSelection)="handleTypeSelection($event)" (processing)="handleProcessingChange($event)"></connection-type-selection-form>
      <connection-parameters-form *ngIf="isConnectionParams" [connectionType]="connectionType" (triggerNotification)="notify($event)" (validConnectionParams)="handleParamsSelection($event)" (processing)="handleProcessingChange($event)"></connection-parameters-form>
      <connection-created-form *ngIf="isConnectionCreated"></connection-created-form>
  </div>
  <div>
    <div mat-dialog-actions>
      <uxpl-button *ngIf="!isConnectionCreated" type="no-border" (clicked)="close()"><div class="dialog-action-text">{{ 'new.connection.wizard.button.cancel' | translate }}</div></uxpl-button>
      <uxpl-button *ngIf="!isConnectionCreated && !isTypeSelection" type="secondary" (clicked)="back()"><div class="dialog-action-text">{{ 'new.connection.wizard.button.back' | translate }}</div></uxpl-button>
      <uxpl-button *ngIf="!isConnectionCreated" id="connection-create-button" class="create-button" [ngClass]="{'disabled': !isCreatable}" [disabled]="!isCreatable" type="primary" (clicked)="create()"><div class="dialog-action-text">{{ 'new.connection.wizard.button.create' | translate }}</div></uxpl-button>
      <uxpl-tooltip *ngIf="isConnectionParams" position="Left" right="104px" bottom="-15px" class="create-disabled-tooltip" ><div>{{ 'new.connection.wizard.button.test' | translate }}</div></uxpl-tooltip>
      <uxpl-button *ngIf="isConnectionCreated" type="secondary" (clicked)="handleConnectionsClick()"><div class="dialog-action-text">{{ 'new.connection.wizard.button.goConnection' | translate }}</div></uxpl-button>
      <uxpl-button *ngIf="isConnectionCreated" type="primary" (clicked)="handleNewDatasetClick()"><div class="dialog-action-text">{{ 'new.connection.wizard.button.dataset' | translate }}</div></uxpl-button>
    </div>
  </div>
</div>
