<div>
  <div class="info-row">
    <div class="label">{{ 'confirmation.label.name' | translate }}</div>
    <div class="value">{{data.name}}</div>
  </div>
  <div class="info-row">
    <div class="label">{{ 'confirmation.label.description' | translate }}</div>
    <div class="value">{{data.description}}</div>
  </div>
  <!-- <div class="info-row">
    <div class="label">Data source type</div>
    <div *ngIf="data.type=='tdv'" class="value">TDV view</div>
    <div *ngIf="data.type=='csv' && data.csvMethod=='file'" class="value">Existing CSV file</div>
    <div *ngIf="data.type=='csv' && data.csvMethod=='upload'" class="value">New CSV file</div>
  </div>
  <div class="info-row">
    <div class="label">Data source</div>
    <div *ngIf="data.type=='tdv'" class="value">{{data.TdvView.DatasetName}}</div>
    <div *ngIf="data.type=='csv' && data.csvMethod=='file'" class="value">{{data.CsvFile.OriginalFilename}}</div>
    <div *ngIf="data.type=='csv' && data.csvMethod=='upload'" class="value">{{data.Dataset_Source.FileName}}</div>
  </div> -->
</div>
