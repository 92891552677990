<div class="settings-main">
  <div class="settings-title">
    <h1>Auto map settings</h1>
    <div class="settings-buttons">
      <uxpl-button type="secondary" (clicked)="handleReset()">Reset</uxpl-button>
      <uxpl-button (clicked)="handleSave()">Save</uxpl-button>
    </div>
  </div>
  <div class="settings-container">
    <div>
      <h2>Auto Mapping of Process Mining columns</h2>
      <div *ngIf="autoMapStrings">
        <div class="settings-container ss-settings">
          <div class="ss-inner">
            <uxpl-button class="right-button" height="60px" style='top: 40px' (clicked)="getWords();">Get Words from<br> Previous Mappings
            </uxpl-button>
            <uxpl-button class="right-button" height="30px" (clicked)="clearAll();">Clear All
            </uxpl-button>
            <div *ngFor="let entry of autoMapStrings | keyvalue: originalOrder">
              <div><h5><b>{{entry.key}}</b></h5></div>
              <uxpl-form-field style="width: 80%" label="Words to find the {{entry.key}} (occurred mappings in brackets)"
                               type="text" valid required="true"
                               [value]="entry.value"
                               (uxplChanged)="handleUpdate($event, entry.key)">
              </uxpl-form-field>
              <br>
            </div>
            <br>
            <uxpl-form-field style="width: 80%" label="Threshold (all found mappings under this value will be ignored)"
                             type="text" valid
                             required="true" [value]="threshold"
                             (uxplChanged)="handleUpdateThreshold($event)">
            </uxpl-form-field>
          </div>
        </div>
        <br>
        <h2>Test Auto Mapping</h2>
        <div class="settings-container ss-settings">
          <div class="ss-inner">
            Use a comma separated list of headers (you can start typing to test): <br>
            <input style="width: 80%" [(ngModel)]="testValue" (input)="testChanged()">
            <uxpl-button class="right-button" (clicked)="testChanged();">Test Auto Mapping</uxpl-button>
            <div *ngIf="showTestResult">
              <br>
              <div *ngFor="let entry of autoMapConfig">
                <div *ngIf="autoMapResults[entry.fieldName]">
                  {{entry.fieldName}}] Column: <b>{{autoMapResults[entry.fieldName].columnName}}</b>
                  ({{autoMapResults[entry.fieldName].likelihood | number : '1.0-2'}}) or
                  <b>{{(autoMapResults[entry.fieldName].likelihood | number : '1.0-2') * 100}}%</b> Occurrences:
                  <b>{{autoMapResults[entry.fieldName].occurrences}}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height:50px"></div>
        <!--<pre>{{autoMapStrings | json}}</pre>
        <pre>{{autoMapConfig | json}}</pre>
        <pre>{{autoMapResults | json}}</pre> -->
      </div>
    </div>
  </div>
</div>


