<div class="dataset-connections">
  <div *ngIf="connections?.length>0" class="dataset-connections-inner">
    <div class="upper-section">
      <div>
        <div>{{ 'connection.label.message' | translate }}</div>
      </div>
      <div class="title-bar">
        <div class="title-left">
          <div class="table-title">{{ 'connection.label.title.connections' | translate }}</div>
          <div class="search-container">
            <uxpl-form-field style="margin-left: 25px;" type="text" valid concise [label]="placeholderSearch"
                             (uxplChanged)="handleSearch($event);"></uxpl-form-field>
            <uxpl-icon class="search-icon" icon="pl-icon-search" width="24px" height="24px"></uxpl-icon>
          </div>
        </div>
        <div class="title-right">
          <uxpl-button type="no-border" icon="assets/images/svg/pl-icon-refresh.svg" (clicked)="refresh()" width="100">
            {{ 'connection.button.refresh' | translate }}
          </uxpl-button>
          <uxpl-button type="no-border" icon="assets/images/svg/pl-icon-add-circle-primary.svg" (clicked)="createNewConnection()" width="100">
            {{ 'connection.button.new' | translate }}
          </uxpl-button>
        </div>
      </div>
      <div class="data-wrapper">
        <div class="data-flex-wrapper">
          <p-table #connectiontable [columns]="cols" [value]="connections"
                  dataKey="name" id="pTable" styleClass="connection-table" [rowHover]="true" [scrollable]="true"
                  [autoLayout]="true"
                  [showCurrentPageReport]="true" [loading]="connections == null"
                  [filterDelay]="0"
                  [scrollable]="true" scrollHeight="flex">>
            <ng-template pTemplate="header" let-columns>
              <tr style="background-color: #fff;">
                <th *ngFor="let col of columns; let i = index;" [pSortableColumn]="col.sortField" class="top-row"
                    [ngClass]="{'firstCol': i==0}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                              ariaLabelDesc="Activate to sort in descending order"
                              ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th class="top-row-empty" style="max-width: 200px;"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-connection>
              <tr class="rowhover" id="toHightlight"
                  [ngClass]="{selected: connection == selectedConnection}">
                <td class="no-padding data-row hovereffect firstCol">
                  <div [innerHTML]="connection.name | highlight: searchTerm : 'Multi-Match'"></div>
                </td>
                <td class="no-padding data-row hovereffect">
                  <div>{{connection.metadata.createdOn | relativeTime}}</div>
                </td>
                <td class="no-padding data-row hovereffect">
                  <div>{{connection.metadata.modifiedOn | relativeTime}}</div>
                </td>
                <td class="no-padding lastCol connection-selector" style="max-width: 200px;">
                  <div
                    *ngIf="selectedConnection?.id == connection.id; else selectButtonBlock"
                    class="selected-col">
                    <div *ngIf="wizard.connectionAddtionalFields.label else connectionSel" class="selected-marker actionable-data" (click)="selectConnection(connection)">
                      <div class="selected inline-ele view-label">{{wizard.connectionAddtionalFields.label}}</div>
                    </div>
                    <ng-template #connectionSel>
                      <div class="selected-marker">
                        <uxpl-icon class="inline-ele" icon="pl-icon-checkmark" size="14px"></uxpl-icon>
                        <div class="selected inline-ele">{{ selectedConnection?.type === 'dv' ? 'Now select a view' : 'selected'}}</div>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #selectButtonBlock>
                    <uxpl-button type="secondary" height="24px" width="68px" (clicked)="selectConnection(connection)">select
                    </uxpl-button>
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

    </div>
      <div *ngIf="wizard.dataSourceType.type === 'tcm'">
        <div>{{ 'connection.label.message2' | translate }}</div>
        <uxpl-select-input class="flex-field flex-margin" select-caption="" no-clear-on-reselect="true" label="Topic name" [value]="wizard.connectionAddtionalFields.topic" [options]="tcmTopicOptions" (uxplSelection)="handleSelectAdditionalField($event, 'topic')"></uxpl-select-input>
        <!-- <uxpl-form-field id="#tcmTopicName" fieldId="tcmTopicName" style="margin-top: 5px; margin-bottom: 5px;" label="Topic name" type="text" valid [value]="wizard.connectionAddtionalFields.topic" (uxplChanged)="handleUpdateAdditionalField($event, 'topic')"></uxpl-form-field> -->
      </div>
    <div *ngIf="showViewSelection && wizard.dataSourceType.type === 'dv' && tdvTree">
      <div class="title-bar">
        <div class="title-left">
          <div class="table-title">{{ 'connection.label.title.views' | translate }}</div>
          <div class="search-container">
            <uxpl-form-field style="margin-left: 25px;" type="text" valid [label]="placeholderSearch" concise
                             (uxplChanged)="handleSearchViews($event);"></uxpl-form-field>
            <uxpl-icon class="search-icon" icon="pl-icon-search" width="24px" height="24px"></uxpl-icon>
          </div>
        </div>
        <div class="title-right">
          <uxpl-button type="no-border" icon="assets/images/svg/pl-icon-refresh.svg" (clicked)="refresh()" width="100">
            {{ 'connection.button.refresh' | translate }}
          </uxpl-button>
        </div>
      </div>
      <p-tree #viewTree *ngIf="tdvTree" [value]="tdvTree" selectionMode="single" (onNodeSelect)="handleViewSelection($event)"></p-tree>
    </div>

    <!-- JS: We shouldnt show a preview until data connection selected and configured -->
    <dataset-data-preview *ngIf="previewData" [previewColumns]="previewColumns" [previewData]="previewData" [wizard]="wizard" [loading]="loadingPreview"></dataset-data-preview>

  </div>
  <!--div class="no-connection-container" *ngIf="connections?.length == 0">
      <img src="assets/images/svg/connections-empty-state.svg"/>
      <div class="desc">You don't have any {{wizard.dataSourceType.name}}s yet.</div>
  </div-->
  <div class="loading-indicator" *ngIf="!connections || loading">
    <pulse-loading></pulse-loading>
    <!-- <uxpl-spinner style="height: 150px; min-width: 250px; max-width: 250px;" appearance="light"
                  message="Loading connections..."></uxpl-spinner> -->
  </div>
</div>
