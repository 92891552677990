import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { TranslationService } from 'src/app/service/translate.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { GetAdminStatisticsResponse } from 'src/app/models_ui/users';
import { UsersService } from 'src/app/service/users.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { Subscription } from 'rxjs';
import { SuperadminService } from 'src/app/service/superadmin.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'org-statistics',
  templateUrl: './org-statistics.component.html',
  styleUrls: ['./org-statistics.component.css'],
  host: {'class': 'flex-item'}
})
export class OrgStatisticsComponent implements OnInit {

  currentOrgId: string = '';
  private currentOrgIdSubscription: Subscription;
  private refreshSubscription: Subscription;
  data: any;
  pulseMindsColors: string[] = [
    "#2B94EB",
    "#4A6DE0",
    "#6C49D1",
    "#D240A7",
    "#FB5443",
    "#FD7742"
  ]

  isLoading: boolean = false;
  adminStatistics: GetAdminStatisticsResponse;

  // Database bar chart
  public databaseBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        display: false
      }
    },
    hover: {
      mode: null,
    },
    layout: {
      padding: {
        top: 40
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value} MB`;
        },
        font: {
          size: 12,
          family: "Montserrat"
        }
      }
    }
  };
  public databaseBarChartLabels: string[] = [];
  public databaseBarChartType: ChartType = 'bar';
  public databaseBarChartLegend = false;
  public databaseBarChartPlugins = [DataLabelsPlugin];
  public databaseBarChartData: ChartDataset[] = [{
      data: [],
      backgroundColor: "#2B94EB",
      borderRadius: 4
    }
  ];
  dbTotalSize: number = 0;

  // Users bar chart
  public usersBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        display: false
      }
    },
    hover: {
      mode: null,
    },
    layout: {
      padding: {
        top: 40
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value}`;
        },
        font: {
          size: 12,
          family: "Montserrat"
        }
      }
    }
  };
  public usersBarChartLabels: string[] = [""];
  public usersBarChartType: ChartType = 'bar';
  public usersBarChartLegend = false;
  public usersBarChartPlugins = [DataLabelsPlugin];
  public usersBarChartData: ChartDataset[] = [];
  usersTotal: number = 0;
  userRolesColors: string[] = [];

  // Token bar chart
  public tokenBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        display: false
      }
    },
    hover: {
      mode: null,
    },
    layout: {
      padding: {
        top: 40
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value}`;
        },
        font: {
          size: 12,
          family: "Montserrat"
        }
      }
    }
  };
  public tokenBarChartLabels: string[] = [""];
  public tokenBarChartType: ChartType = 'bar';
  public tokenBarChartLegend = false;
  public tokenBarChartPlugins = [DataLabelsPlugin];
  public tokenBarChartData: ChartDataset[] = [];
  tokenTotal: number = 0;
  tokenRolesColors: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private usersService: UsersService,
    private snackBarService: SnackbarService,
    private superadminService: SuperadminService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.refreshSubscription = this.commonService.refreshObservable.subscribe(() => {
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgIdSubscription) {
      this.currentOrgIdSubscription.unsubscribe();
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  refresh() {
    if (this.currentOrgIdSubscription) {
      this.currentOrgIdSubscription.unsubscribe();
    }
    this.loadData();
  }

  loadData() {
    this.currentOrgIdSubscription = this.route.paramMap.subscribe(
      params => {
        this.currentOrgId = params.get('orgId');
        this.superadminService.changeId(this.currentOrgId);
        this.getAdminStatistics();
    });
  }

  getAdminStatistics(){
    this.isLoading = true;
    this.usersService.getAdminStatistics(this.currentOrgId).subscribe(
      (response: GetAdminStatisticsResponse) => {
        this.adminStatistics = response;
        this.populateStatistics();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.openSnackBar(this.translationService.translate('generic.message.api.error'), this.translationService.translate('create.org.snackBar.close'),'error');
        console.error("getAdminsStatics error: ", error);
      } 
    );
  }

  populateStatistics(){
    this.databaseBarChartLabels = [];
    this.databaseBarChartData[0].data = [];
    if(this.adminStatistics && this.adminStatistics.database && this.adminStatistics.database.tables && this.adminStatistics.database.tables.length > 0){
      for(let table of this.adminStatistics.database.tables){
        this.databaseBarChartLabels.push(table.table);
        this.databaseBarChartData[0].data.push(Math.round(table.value / 1048576 ));
      }
    }
    this.dbTotalSize = 0;
    if(this.adminStatistics && this.adminStatistics.database && this.adminStatistics.database.total_size){
      let totalInGb = this.adminStatistics.database.total_size.value / 1073741824;
      this.dbTotalSize = parseFloat(totalInGb.toFixed(2));
    }

    this.usersTotal = 0;
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.total){
      this.usersTotal = this.adminStatistics.users.total;
    }
    this.userRolesColors = [];
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.roles && this.adminStatistics.users.roles.length > 0){
      this.userRolesColors = this.adminStatistics.users.roles.map((_, index) => 'color-'+((index % 6) + 1)+'-text');
    }
    this.usersBarChartData = [];
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.roles && this.adminStatistics.users.roles.length > 0){
      for(let [index, user] of this.adminStatistics.users.roles.entries()){
        this.usersBarChartData.push({
          data: [user.length],
          backgroundColor: this.pulseMindsColors[index],
          borderRadius: 4
        });
      }
    }

    this.tokenTotal = 0;
    if(this.adminStatistics && this.adminStatistics.summary && this.adminStatistics.summary.total_tokens){
      this.tokenTotal = this.adminStatistics.summary.total_tokens;
    }
    this.tokenBarChartData = [];
    if(this.adminStatistics && this.adminStatistics.summary && this.adminStatistics.summary.prompt_tokens && this.adminStatistics.summary.completion_tokens){
      this.tokenBarChartData.push({
        data: [this.adminStatistics.summary.prompt_tokens],
        backgroundColor: this.pulseMindsColors[2],
        borderRadius: 4
      });
      this.tokenBarChartData.push({
        data: [this.adminStatistics.summary.completion_tokens],
        backgroundColor: this.pulseMindsColors[3],
        borderRadius: 4
      });
    }
  }

}
