import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Investigation, InvestigationApplication, InvestigationService} from '@tibco/discover-client-lib';
import {getRelativeTime} from '../../functions/message';
import _ from 'lodash';
import {InvestigationContextService} from '../../service/investigation-context.service';
import {InvestigationContext} from '../../models_ui/investigationContext';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import {StepperConfigUI} from '../../models_ui/uxpl-models';
import {RecursivePartial} from '../../models_ui/discover';

@Component({
  selector: 'investigations-details',
  templateUrl: './investigations-details.component.html',
  styleUrls: ['./investigations-details.component.css']
})
export class InvestigationsDetailsComponent implements OnInit {

  objHeaderConfig: RecursivePartial<ObjectHeaderConfig>;
  applicationId: string;
  investigationId: string;
  investigation: Investigation;
  investigationContext: InvestigationContext;
  cConfig: InvestigationApplication;
  investigationFound = true;
  htmlErrorMessage = '';
  stepperConfig: StepperConfigUI = {
    steps: [
      {available: true, completed: true, label: 'Summary', slot: 'summary'}
    ],
    currentStepIdx: 0
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private investigationMS: InvestigationService,
              private investigationContextService: InvestigationContextService) {
  }

  async ngOnInit() {
    this.setStepperConfig()
    this.applicationId = this.route.snapshot.paramMap.get('applicationId')
    this.investigationId = this.route.snapshot.paramMap.get('investigationId')
    this.loadInvestigation()
    const config = await this.investigationMS.getInvestigationApplications().toPromise();
    this.cConfig = config.find(f => f.id === this.applicationId)
    if (!this.cConfig) {
      this.investigationFound = false
      this.htmlErrorMessage += `<br>Investigation Application: <b>${this.applicationId}</b> not found !<br>`
    }


  }

  loadInvestigation() {
    // TODO: Use a service that sends back one investigation
    this.investigationMS.getInvestigations(this.applicationId).subscribe(investigations => {
      this.investigation = investigations.find(inv => inv.id === this.investigationId)
      // console.log('investigation: ', this.investigation)
      if (!this.investigation) {
        this.investigationFound = false
        this.htmlErrorMessage += `<br>Investigation with id <b>${this.investigationId}</b> not found.<br>`
      } else {
        // Found the investigation
        // TODO: Use a proper ID (which makes it more generic)
        let investigationContextId = '';
        if (this.cConfig.customTitle === 'Improvements') {
          // TODO: Replace by return model, which manuel will create (any was Investigation but contains 'object')
          // @ts-ignore
          investigationContextId = this.investigation.data.ImprovementID
        }
        if (this.cConfig.customTitle === 'Conformance Checking') {
          // TODO: Replace by return model, which manuel will create (any was Investigation but contains 'object')
          // @ts-ignore
          investigationContextId = this.investigation.data.ComplianceID
        }

        this.investigationContextService.getInvestigationContext(investigationContextId).then(ic => {
          this.investigationContext = ic;
          console.log('this.investigationContext: ', this.investigationContext)
          this.setStepperConfig();
        })


        const modBy = this.investigation.metadata.find(md => md.name === 'modifiedBy').value
        // modificationTimestamp
        const modOn = this.investigation.metadata.find(md => md.name === 'modificationTimestamp').value
        const modOnRel = getRelativeTime(new Date(modOn).getTime())
        // Load the title based on the config
        const title = _.get(this.investigation.data, this.cConfig.detailTitle.field)
        this.objHeaderConfig = {
          title: {
            value: title,
          },
          details: [
            {
              value: 'Type: ' + this.cConfig.customTitle
            },
            {
              value: 'Modified by: ' + modBy,
            },
            {
              value: 'Modified on: ' + modOnRel,
            }
          ]
        };
      }
    })
  }

  goInvestigations() {
    // Go directly to the selected Investigation:
    this.router.navigate(['/discover/investigations/'], {
      queryParams: {
        focusApp: this.applicationId,
        focus: this.investigationId
      }
    });
  }

  handleStepClick(step) {
    console.log('step clicked ', step)
    // this.previousStep = step.detail;
    // this.config.currentStepIdx = step.detail;
  }

  display(step: string) {
    return this.stepperConfig.steps[this.stepperConfig.currentStepIdx].slot === step;
  }

  private setStepperConfig() {
    this.stepperConfig = {
      steps: [
        {available: true, completed: true, label: 'Summary', slot: 'summary'}
      ],
      currentStepIdx: 0
    };
    if (this.investigationContext) {
      this.stepperConfig.steps.push({available: true, completed: true, label: 'Cases/Variants', slot: 'cases'})
      this.stepperConfig.steps.push({available: true, completed: true, label: 'Analytics', slot: 'analytics'})
    }
  }
}
