import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {NavMenu} from '@tibco/tc-web-components/dist/types/models/leftNav';
import {ResizableDraggableComponent} from '../resizable-draggable/resizable-draggable.component';
import {Dataset, FilterTemplate} from '@tibco/discover-client-lib';
import {Components} from '@tibco/tc-web-components';
import UxplLeftNavMulti = Components.UxplLeftNavMulti;
import { InternalMessageService } from 'src/app/service/internal-message.service';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'sf-filter-panel',
  templateUrl: './sf-filter-panel.component.html',
  styleUrls: ['./sf-filter-panel.component.scss']
})
export class SfFilterPanelComponent implements OnInit, AfterViewInit, OnChanges {
  _spotfireAPI: any;

  @Input() width: number;
  @Input() height: number;
  @Input() left: number;
  @Input() top: number;
  @Input() filterIds: string[];
  @Input() filterConfig: FilterTemplate[];
  @Input() filterByTab: any;
  @Input() datasetId: string;
  @Input() availableTimeRange: DateRange<Date> | undefined;
  @Input() dateTimeFormat: string;
  @Input() attributes: any[];
  @Input() isAttributeReady: boolean;
  @Input() filtersEnabledOrg: string[];
  @Input() currentOrg: string;

  @Output() enableSFPointerEvents: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openFilterPage: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeFPEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() panelResize: EventEmitter<any> = new EventEmitter<any>();
  @Output() setFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() setFilterByTime: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() setFilterAttributes: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetFilterAttr: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('sfFilterContainer', {static: false}) sfFilterContainer: ResizableDraggableComponent;
  @ViewChild('sfView', {static: false}) sfView: ElementRef;
  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNavMulti>;

  activeTab: string;
  leftNavTabs: NavMenu[] = [{
    "id": "Filter By Time",
    "label": "By Time"
  },{
    "id": "Filter By Variants",
    "label": "By Variants"
  },{
    "id": "Filters Activities",
    "label": "By Activities"
  },{
    "id": "Filter Attributes",
    "label": "By Attributes"
  }];
  selectedRangeValue: DateRange<Date> | undefined;
  filterTimeObj: any = {
    lowValue: "1900/01/01",
    highValue: "2100/12/31",
    type: "Fit In"
  };
  filterLowDate: any;
  filterHighDate: any;
  filterType: any;
  dataset: Dataset;
  startAt: Date;
  minDate: Date;
  maxDate: Date;
  isAutoCheck: boolean = false;

  private firstShow = false;

  constructor(
    private messageService: InternalMessageService
  ) {}

  contentExpanded = true;
  contentMaximized = true;
  oriHeight: number;
  minFilterPanelSize = {x: 228, y: 68};

  // the top when the filter panel is show at first time. The position is right underneath the filter button
  // so when the filter panel is maximized we can know what the top should be
  maxTop: number;

  ngOnInit() {
    this.processConfig();
  }

  ngAfterViewInit() {
    this.setMenu();
  }

  private setMenu() {
    const firstTab = this.filterConfig?.filter(fc => fc.isDefault === true)[0];
    if (firstTab) {
      this.activeTab = firstTab.view;
      this.openFilterPage.emit(firstTab.view);
      // console.log('this.leftNav 1 :  ', this.leftNav)
      // setTimeout(() => {
      //   console.log('this.leftNav 2:  ', this.leftNav)
      const menuTab: NavMenu = {
        id: firstTab.view,
        label: firstTab.label
      }

      this.leftNav?.nativeElement?.setTab(menuTab, true);
      // }, 500);
    }
  }

  handleMenuClick(event) {
    if (event.detail && event.detail.id) {
      const filterId = event.detail.id;
      this.activeTab = this.filterConfig.find(fc => fc.view === filterId).view;
      // this.activeTab = event.detail.view;
      this.openFilterPage.emit(filterId);
    }
  }

  handleMenuClick2(filterId) {
    if(filterId !== 'Filter Attributes' && filterId != 'Filter By Time'){
      this.activeTab = this.filterConfig.find(fc => fc.view === filterId).view;
    }else{
      this.activeTab = filterId;
    }
    this.openFilterPage.emit(filterId);
  }

  public setContainerScope(scope) {
    this.sfFilterContainer.setContainerScope(scope);
  }

  public startDrag(event) {
    this.sfFilterContainer.startDrag(event);
  }

  public enableSpotfirePointerEvents(event) {
    this.enableSFPointerEvents.emit(event);
    this.enableInnerSFPointerEvents(event);
  }

  public enableInnerSFPointerEvents(enabled) {
    const iframe = this.sfView.nativeElement.querySelector('div#' + this.activeTab + ' > iframe');
    // console.log('iframe', iframe);
    if (iframe) {
      const style = 'border: 0px; margin: 0px; padding: 0px; width: 100%; height: 100%;';
      if (enabled) {
        iframe.setAttribute('style', style);
      } else {
        iframe.setAttribute('style', style + 'pointer-events: none;');
      }
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if(_changes.filterByTab){
      this.setFilterTimeRange();
    }
    if(_changes.isAttributeReady){
      this.defineFilterAttributes();
    }
    if(_changes.attributes){
      this.defineFilterAttributes();
    }
    // if(_changes.attributes){
    //   for(let attribute of Object.entries(this.attributes)){
    //     for(let value of attribute.values)
    //   }
    // }
    this.processConfig();
  }

  ngDoCheck() {
    // console.log("FIXFIX this.isFilterByTimeChanged", this.isFilterByTimeChanged);
  }

  private processConfig() {
    if(this.currentOrg && this.filtersEnabledOrg && !this.filtersEnabledOrg.includes(this.currentOrg)){
      this.leftNavTabs = [];
      this.filterConfig?.forEach(fc => {
        if (fc.enabled) {
          this.leftNavTabs.push({
            id: fc.view,
            label: fc.label
          });
          if (fc.isDefault) {
            if(!this.activeTab){
              this.activeTab = fc.view;
            }
          }
        }
      })
    }
  }

  public toggleShow(isShown: boolean, maxTop: number) {
    if (isShown && !this.firstShow) {
      this.firstShow = true;
      // save it here, and don't loose it
      this.maxTop = maxTop;
      this.setMenu();
    }
    this.setFilterTimeRange();
  }

  public toggleContentExpanded() {
    if (this.contentExpanded) {
      this.oriHeight = this.sfFilterContainer.verticalCollapse(68);
    } else {
      this.sfFilterContainer.verticalExpand(this.oriHeight);
    }
    this.contentExpanded = !this.contentExpanded;
  }

  public toggleContentMaximize() {
    if (this.contentMaximized) {
      this.sfFilterContainer.restore();
    } else {
      this.sfFilterContainer.maximize(this.maxTop);
    }
    const rect = this.sfFilterContainer.getBindingClientRect();
    this.top = rect.top || this.top;
    this.left = rect.left || this.left;
    this.height = rect.height || this.height;
    this.width = rect.width || this.width;
    this.contentMaximized = !this.contentMaximized;
  }

  public resizeToSmallSize() {
    this.contentMaximized = false;
  }

  public resize(event) {
    this.panelResize.emit(event);
  }

  closeFilterPanel() {
    this.closeFPEvent.emit(true)
  }

  resetTabFilter(tabId?){
    this.resetFilter.emit(tabId);
    if(tabId == "Filter By Time"){
      this.selectedRangeValue = new DateRange<Date>(null, null);
    }else if(tabId == "Filter Attributes"){
      this.resetFilterAttributes();
    }
    console.log('tabId', tabId);
  }

  resetFilterAttributes(){
    let filterObj = [];
    for(let attribute of this.attributes){
      if(attribute.filterType != 'RangeFilter'){
        filterObj.push({
          name: attribute.name,
          filterType: attribute.filterType,
          values: null
        })
      }else{
        if(attribute.currentLowValue && attribute.currentHighValue){
          filterObj.push({
            name: attribute.name,
            filterType: attribute.filterType,
            values: null
          })
        }
        attribute.currentLowValue = attribute.lowValue;
        attribute.currentHighValue = attribute.highValue;
      }
    }
    this.resetFilterAttr.emit(filterObj);
  }

  applyFilter(tabId?){
    console.log('tabId', tabId);
    if(tabId == "Filter By Time"){
      if(this.selectedRangeValue.start && this.selectedRangeValue.end){
        this.filterTimeObj.lowValue = moment(this.selectedRangeValue.start).format(this.dateTimeFormat);
        this.filterTimeObj.highValue = moment(this.selectedRangeValue.end).format(this.dateTimeFormat);
        this.startAt = this.selectedRangeValue.start; 
        this.minDate = this.selectedRangeValue.start;
        this.maxDate = this.selectedRangeValue.end;
        this.setFilterByTime.emit(this.filterTimeObj);
      }else{
        const mes = {
          type: 'WARNING',
          message: 'Select a valid date range'
        };
        this.messageService.sendMessage('news-banner.topic.message', 'MESSAGE:' + JSON.stringify(mes));
        return;
      }
    }else if(tabId == "Filter Attributes"){
      let filterObj = [];
      for(let attribute of this.attributes){
        if(attribute.filterType != 'RangeFilter'){
          let values = [];
          for(let value of attribute.values){
            if(value.checked){
              values.push(value.value);
            }
          }
          if(values.length > 0){
            filterObj.push({
              name: attribute.name,
              filterType: attribute.filterType,
              values: values
            })
          }
        }else{
          if(attribute.currentLowValue && attribute.currentHighValue){
            filterObj.push({
              name: attribute.name,
              filterType: attribute.filterType,
              lowValue: attribute.currentLowValue,
              highValue: attribute.currentHighValue
            })
          }
        }
      }
      if(filterObj.length == 0){
        const mes = {
          type: 'WARNING',
          message: 'Select at least an value'
        };
        this.messageService.sendMessage('news-banner.topic.message', 'MESSAGE:' + JSON.stringify(mes));
        return;
      }else{
        this.setFilterAttributes.emit(filterObj);
      }
    }else{
      this.setFilter.emit(tabId);
    }
  }
  
  resetAllFilters(){
    this.selectedRangeValue = new DateRange<Date>(null, null);
    this.resetFilterAttributes();
    this.resetAll.emit();
  }

  enableFilter(tabId, event){
    let isValuesPresent = true;
    if(tabId == "Filter By Time"){
      for(let column of this.filterByTab[tabId].columns){
        if(
            !column.filterSettings.lowValue || 
            column.filterSettings.lowValue == null  || 
            column.filterSettings.lowValue == undefined ||
            !column.filterSettings.highValue || 
            column.filterSettings.highValue == null  || 
            column.filterSettings.highValue == undefined
          ){
          isValuesPresent = false;
          break;
        }
      }
    }else if(tabId == "Filter Attributes"){
      if(this.filterByTab[tabId].columns && this.filterByTab[tabId].columns.length > 0){
        isValuesPresent = false;
        for(let column of this.filterByTab[tabId].columns){
          // if not exists at least the values array or range value
          if((column.filterSettings.values && column.filterSettings.values.length > 0) || (column.filterSettings.lowValue && column.filterSettings.highValue)){
            isValuesPresent = true;
            break;
          }
        }
      }else{
        isValuesPresent = false;
      }
    }else{
      for(let column of this.filterByTab[tabId].columns){
        if(!column.filterSettings.values || column.filterSettings.values == null  || column.filterSettings.values == undefined || column.filterSettings.values.length == 0 ){
          isValuesPresent = false;
          break;
        }
      }
    }
    if(!isValuesPresent){
      // Reset checkbox
      event.source.checked = false;
      const mes = {
        type: 'WARNING',
        message: 'There are no saved filters to activate'
      };
      this.messageService.sendMessage('news-banner.topic.message', 'MESSAGE:' + JSON.stringify(mes));
      return;
    }
    let toggleConfig = {
      tabId: tabId,
      enabled: event.checked
    }
    this.toggleFilter.emit(toggleConfig);
  }

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
        this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
        const start = this.selectedRangeValue.start;
        const end = m;
        if (end < start) {
            this.selectedRangeValue = new DateRange<Date>(end, start);
        } else {
            this.selectedRangeValue = new DateRange<Date>(start, end);
        }
    }
  }

  setFilterTimeRange(){
    let filterByTimeId = "Filter By Time";
    if(this.filterByTab[filterByTimeId].enabled){
      let indexStartDate = this.filterByTab[filterByTimeId].columns.findIndex(object => {
        return object.dataColumnName === "case_start_timestamp";
      });
      this.startAt = new Date(moment(this.filterByTab[filterByTimeId].columns[indexStartDate].filterSettings.lowValue, this.dateTimeFormat).format('L'));
      this.minDate = new Date(moment(this.filterByTab[filterByTimeId].columns[indexStartDate].filterSettings.lowValue, this.dateTimeFormat).format('L'));
      let indexEndDate = this.filterByTab[filterByTimeId].columns.findIndex(object => {
        return object.dataColumnName === "case_end_timestamp";
      });
      this.maxDate = new Date(moment(this.filterByTab[filterByTimeId].columns[indexEndDate].filterSettings.highValue, this.dateTimeFormat).format('L'));
      this.selectedRangeValue = new DateRange<Date>(this.minDate, this.maxDate);
    }else{
      if(this.availableTimeRange && this.availableTimeRange.start){
        this.startAt = this.availableTimeRange.start;
        this.minDate = this.availableTimeRange.start;
      }
      if(this.availableTimeRange && this.availableTimeRange.end){
        this.maxDate = this.availableTimeRange.end;
      }
      this.selectedRangeValue = new DateRange<Date>(null, null);
    }
  }

  checkAttribute(attribute, event){
    let indexAttribute = this.attributes.findIndex(object => {return object.name === attribute;});
    let indexValue = this.attributes[indexAttribute].values.findIndex(object => {
      return object.value === event.detail.value;
    });
    this.attributes[indexAttribute].values[indexValue].checked = event.detail.checked;
    let checkedCount = this.attributes[indexAttribute].values.filter(n => n.checked).length;
    if(checkedCount == this.attributes[indexAttribute].values.length){
      this.isAutoCheck = true;
      document.getElementById(attribute).setAttribute("checked", "true");
    }else{
      this.isAutoCheck = true;
      document.getElementById(attribute).setAttribute("checked", "false");
    }
  }

  checkboxClicked(){
    this.isAutoCheck = false;
  }

  checkAllAttribute(event){
    if(!this.isAutoCheck){
      let indexAttribute = this.attributes.findIndex(object => {
        return object.name === event.detail.value;
      });
      for(let value of this.attributes[indexAttribute].values){
        value.checked = event.detail.checked;
      }
    }
  }

  defineFilterAttributes(){
    if(
      this.attributes 
      && this.filterByTab 
      && this.filterByTab['Filter Attributes']
    ){
      for(let attribute of this.attributes){
        if(attribute.filterType == "RangeFilter"){
          let indexColumn = this.filterByTab['Filter Attributes'].columns.findIndex(object => {
            return object.dataColumnName === attribute.name;
          });
          if(indexColumn > -1){
            if(this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.lowValue && this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.highValue){
              attribute.currentLowValue = this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.lowValue
              attribute.currentHighValue = this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.highValue
            }else{
              attribute.currentLowValue = attribute.lowValue
              attribute.currentHighValue = attribute.highValue
            }
          }else{
            attribute.currentLowValue = attribute.lowValue
            attribute.currentHighValue = attribute.highValue
          }
        }else{
          if(this.filterByTab['Filter Attributes'].columns.length > 0){
            let indexColumn = this.filterByTab['Filter Attributes'].columns.findIndex(object => {
              return object.dataColumnName === attribute.name;
            });
            if(indexColumn > -1){
              if(this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.values && this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.values.length > 0){
                for(let value of this.filterByTab['Filter Attributes'].columns[indexColumn].filterSettings.values){
                  let indexValue = attribute.values.findIndex(object => {
                    return object.value === value;
                  });
                  attribute.values[indexValue].checked = true;
                }
              }
            }
          }
        }
      }
    }
  }

  updateAttributeLowValue(event){
    let indexAttribute = this.attributes.findIndex(object => {
      return object.name === event.target.name;
    });
    this.attributes[indexAttribute].currentLowValue = event.detail.value;
  }
  updateAttributeHighValue(event){
    let indexAttribute = this.attributes.findIndex(object => {
      return object.name === event.target.name;
    });
    this.attributes[indexAttribute].currentHighValue = event.detail.value;
  }
}
