<div class="page-container container-flex-fluid">
  <div class="flex">
    <div class="flex-item width-xs-10">
      <div class="data-title-container">
        <div class="data-title">
          <div class="page-title">{{ 'manage.process.analysis.title' | translate }}</div>
            <uxpl-form-field class="dataset-search-widget" type="text" consise valid [label]="placeholderSearch" (uxplChanged)="handleSearch($event);">
              <uxpl-icon class="dataset-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
            </uxpl-form-field>
          </div>
          <div class="data-title">
            <div class="data-title">
              <button mat-stroked-button color="primary" (click)="refresh()">
                <mat-icon class="material-symbols-rounded">refresh</mat-icon>
                {{ 'manage.process.analysis.button.refresh' | translate }}
              </button>
              <button mat-stroked-button color="primary" (click)="handleCompareClick()">
                <mat-icon class="material-symbols-rounded">compare</mat-icon>
                {{ 'manage.process.analysis.button.compare' | translate }}
              </button>
              <!-- <uxpl-button class="secondary" type="secondary" (clicked)="showTemplates()">
                <div class="icon-button secondary-icon-button">Templates</div>
              </uxpl-button> -->
              <!-- <uxpl-button class="secondary add-folder-button" type="secondary" (click)="newAnalysisFolder()">
                <div class="icon-button secondary-icon-button">
                  <span class="material-symbols-rounded folder-icon folder-open">create_new_folder</span>
                  Folder
                </div>
              </uxpl-button> -->
              <button mat-flat-button color="primary" (click)="showNewAnalysis()">
                <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                {{ 'manage.process.analysis.button.analysis' | translate }}
              </button>
            </div>
          </div>
      </div>
    </div>
    <div id="process-analysis-tabs" class="flex-item width-xs-10">
      <div class="flex" style="height: calc(100vh - 142px);">
          <div class="process-analysis-folder-wrapper resizable-panel flex-item" 
          [style.width.px]="sidebarWidth" 
          appResizable 
          resizableMinWidth="200" 
          resizableMaxWidth="500"
          >
            <div class="process-analysis-folder-container">
              <ul>
                <li class="open" *ngFor="let folder of hierarchicalFolders">
                  <a href="javascript:void(0)" class="folder active" [attr.folder-name]="folder.path" draggable="false" (click)="selectFolder($event)" (drop)="dropAnalysis($event, folder.path)" (dragover)="allowDrop($event)" (dragenter)="dragEnterAnalysis($event)" (dragleave)="dragLeaveAnalysis($event)">
                    <span class="name">
                      <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                      <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                      <span class="name-text">{{folder.name}}</span>
                    </span>
                    <span class="material-symbols-rounded drop-down-icon">arrow_drop_down</span>
                  </a>
                  <ul>
                    <li *ngFor="let folderParent of folder.children">
                      <a href="javascript:void(0)" class="folder" [attr.folder-name]="folderParent.path" draggable="false" (drop)="dropAnalysis($event, folderParent.path)" (click)="selectFolder($event)" (dragover)="allowDrop($event)" (dragenter)="dragEnterAnalysis($event)" (dragleave)="dragLeaveAnalysis($event)">
                        <span class="name">
                          <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                          <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                          <span class="name-text">{{folderParent.name}}</span>
                        </span>
                        <span class="material-symbols-rounded drop-down-icon">arrow_drop_down</span>
                      </a>
                      <ul>
                        <li *ngFor="let folderChildren of folderParent.children">
                          <a href="javascript:void(0)" class="folder" [attr.folder-name]="folderChildren.path" draggable="false" (drop)="dropAnalysis($event, folderChildren.path)" (click)="selectFolder($event)" (dragover)="allowDrop($event)" (dragenter)="dragEnterAnalysis($event)" (dragleave)="dragLeaveAnalysis($event)">
                            <span class="name">
                              <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                              <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                              <span class="name-text">{{folderChildren.name}}</span>
                            </span>
                            <span class="material-symbols-rounded drop-down-icon">arrow_drop_down</span>
                          </a>
                          <ul>
                            <li *ngFor="let folderGrandchild of folderChildren.children">
                              <a href="javascript:void(0)" class="folder" [attr.folder-name]="folderGrandchild.path" draggable="false" (drop)="dropAnalysis($event, folderGrandchild.path)" (click)="selectFolder($event)" (dragover)="allowDrop($event)" (dragenter)="dragEnterAnalysis($event)" (dragleave)="dragLeaveAnalysis($event)">
                                <span class="name">
                                  <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                                  <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                                  <span class="name-text">{{folderGrandchild.name}}</span>
                                </span>
                                <span class="material-symbols-rounded drop-down-icon">arrow_drop_down</span>
                              </a>
                              <ul>
                                <ng-container *ngFor="let case of cases">
                                  <li *ngIf="case.folder == folderGrandchild.path">
                                    <a href="javascript:void(0)" (click)="open(case.id)" draggable="true" (dragstart)="dragStartAnalysis(case.id)">
                                      <span class="name">
                                        <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                        <span class="name-text">{{case.name}}</span>
                                      </span>
                                    </a>
                                  </li>
                                </ng-container>    
                              </ul>
                            </li>
                            <ng-container *ngFor="let case of cases">
                              <li *ngIf="case.folder == folderChildren.path">
                                <a href="javascript:void(0)" (click)="open(case.id)" draggable="true" (dragstart)="dragStartAnalysis(case.id)">
                                  <span class="name">
                                    <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                    <span class="name-text">{{case.name}}</span>
                                  </span>
                                </a>
                              </li>
                            </ng-container>    
                          </ul>
                        </li>
                        <ng-container *ngFor="let case of cases">
                          <li *ngIf="case.folder == folderParent.path">
                            <a href="javascript:void(0)" (click)="open(case.id)" draggable="true" (dragstart)="dragStartAnalysis(case.id)">
                              <span class="name">
                                <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                <span class="name-text">{{case.name}}</span>
                              </span>
                            </a>
                          </li>
                        </ng-container>    
                      </ul>
                    </li>
                    <ng-container *ngFor="let case of cases">
                      <li *ngIf="case.folder == folder.path">
                        <a href="javascript:void(0)" (click)="open(case.id)" draggable="true" (dragstart)="dragStartAnalysis(case.id)">
                          <span class="name">
                            <span class="material-symbols-rounded folder-icon">insert_chart</span>
                            <span class="name-text">{{case.name}}</span>
                          </span>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="process-analysis-add-folder-container">
              <button mat-flat-button class="add-folder-button width-xs-10" color="primary" (click)="newAnalysisFolder()">
                <mat-icon class="material-symbols-rounded">create_new_folder</mat-icon>
                {{ 'manage.process.analysis.button.folder' | translate }}
              </button>
            </div>
          </div>
        <div class="process-analysis-wrapper flex-item" *ngIf="isLoading || cases.length>0" [attr.active-folder]="activeFolder">
          <div class="process-analysis-container">
            <mat-card>
              <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
              <mat-card-content>
                <process-analysis-table #paTable [processAnalyses]="cases" [searchTerm]="search" [statusMap]="statusMap"
                  [paFocus]="paFocus"
                  (openAnalysis)="openAnalytics($event)"
                  (caseActionSelect)="handleActionSelected($event)"
                  (forceRefresh)="refresh()"></process-analysis-table>
                  <!-- <process-analysis-table #paTable [processAnalyses]="casesByFolder.Test" [searchTerm]="search" [statusMap]="statusMap"
                  [paFocus]="paFocus"
                  [isLoading]="isLoading" (openAnalysis)="openAnalytics($event)"
                  (caseActionSelect)="handleActionSelected($event)"
                  (forceRefresh)="refresh()"></process-analysis-table> -->
                <!-- <process-analysis-table #paTable [processAnalyses]="casesByFolder.General" [searchTerm]="search" [statusMap]="statusMap"
                                        [paFocus]="paFocus"
                                        [isLoading]="isLoading" (openAnalysis)="openAnalytics($event)"
                                        (caseActionSelect)="handleActionSelected($event)"
                                        (forceRefresh)="refresh()"></process-analysis-table> -->
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div *ngIf="!isLoading && cases.length==0" class="process-analysis-wrapper process-analysis-empty flex-item">
          <div class="process-analysis-container">
            <mat-card>
              <mat-card-content>
                <div class="center">
                  <div class="img-center">
                    <img width="204" src="assets/images/png/no-data.png">
                  </div>
                  <div class="nodata-msg">{{ 'manage.process.analysis.message.noData' | translate }}</div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<process-analysis-compare [analysisOptions]="cases"></process-analysis-compare>