import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
// import {Title} from '@angular/platform-browser';
// import {NavigationEnd, Router} from '@angular/router';
// import {ConfigurationService} from './service/configuration.service';
// import {OauthService} from './service/oauth.service';
// import {GeneralInformationConfiguration, ConfigurationService as ConfigurationServiceMS} from '@tibco/discover-client-lib';
// import {NavBarConfig} from './models_ui/tc-navbar';
// import {WhatsNewService} from './service/whats-new.service';
// import {NgxPendoService} from 'ngx-pendo';

// declare var GlobalNavbar: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  // @ViewChild('TCNavbar', {static: false}) TCNavbar: TibcoCloudNavbarComponent;

  // @ViewChild('helpContainer', {read: ElementRef}) helpContainer: ElementRef;

  // disableTimeout = false;
  // isLogin = false;
  // afterInit = false;
  // navBarConfg: NavBarConfig;
  // showHelp = false;
  // helpSource: string;
  // private navbar;
  // hasNoAccess = false;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService) { }

  // constructor(private titleService: Title,
  //             public router: Router,
  //             private configService: ConfigurationService,
  //             public oauthService: OauthService,
  //             private whatsNewService: WhatsNewService,
  //             private ngxPendoService: NgxPendoService,
  //             private configurationMS: ConfigurationServiceMS) {
  //   router.events.subscribe(evt => {
  //     if (evt instanceof NavigationEnd) {
  //       if (evt?.url.startsWith('/login') || evt?.url.startsWith('/login-oauth')) {
  //         this.isLogin = true;
  //       } else {
  //         this.isLogin = false;
  //       }
  //       // We need urlAfterRedirects, because of the initial landing (which redirects to welcome)
  //       const urlTree = router.parseUrl(evt.urlAfterRedirects);
  //       let helpS = 'discover'
  //       const segments = urlTree.root?.children?.primary?.segments
  //       if (segments && segments.length > 0) {
  //         for (let i = 0; i < segments.length; i++) {
  //           const path = segments[i].path;
  //           if (path === 'discover' && segments[i + 1]?.path) {
  //             const secPath = segments[i + 1].path
  //             if (secPath === 'admin' && segments[i + 2]?.path) {
  //               helpS = 'discover/admin/' + segments[i + 2].path
  //             } else {
  //               helpS = 'discover/' + segments[i + 1].path
  //             }
  //           }
  //           if (path === 'welcome') {
  //             helpS = 'welcome'
  //           }
  //         }
  //       }
  //       this.helpSource = 'assets/help/' + helpS + '/config.json'
  //     }
  //   })
  // }

  ngOnInit() {
    // this.isIframe = window !== window.parent && !window.opener;

    // this.broadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe(() => {
    //   this.setLoginDisplay();
    // })
    // if(this.loginDisplay){
    //   this.login();
    // }

    // this.broadcastService.msalSubject$
    // .pipe(
    //   filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe((result: EventMessage) => {
    //   console.log("Get token", result);
    //   // Do something with event payload here
    // });

    // this.afterInit = false;
    // if (this.oauthService.isCloud()) {
    //   this.disableTimeout = false;
    // } else {
    //   this.disableTimeout = true;
    // }
    // let title = 'Discover'
    // if (this.config) {
    //   title = this.config.applicationTitle
    // }
    // this.navBarConfg = {
    //   container: '#navbar',
    //   textAfterLogo: title,
    //   /*restApiDomain: 'https://eu.account.cloud.tibco.com',*/
    //   xhrHeaders: {
    //     Authorization: () => {
    //       return this.oauthService?.token ? 'Bearer ' + this.oauthService?.token : undefined
    //     },
    //     'x-tenant': 'LABS'
    //   },
    //   idle: {
    //     disabled: this.disableTimeout
    //   },
    //   iconMenus: {
    //     search: {
    //       visible: false
    //     },
    //     download: {
    //       visible: false
    //     },
    //     help: {
    //       visible: true,
    //       publishEvent: false
    //     },
    //     notifications: {
    //       visible: false
    //     },
    //     products: {
    //       visible: true
    //     },
    //     region: {
    //       visible: false
    //     },
    //     accountswitcher: {
    //       visible: true
    //     }
    //   },
    //   customProfilePanel: {
    //     account: {
    //       visible: true,
    //       disabled: false
    //     },
    //     subscriptions: {
    //       visible: true,
    //       disabled: false
    //     },
    //     organization: {
    //       visible: true
    //     },
    //     tenants: {
    //       visible: true
    //     }
    //   },
    //   customizedComponents: [],
    //   accountChange: {
    //     disabled: false,                    // JS:  JIRA CPS-1693) disabled until gallery released and navbar org switch enabled
    //     secureHomeURL: '/index.html?accountChange=true'    // optional. if provided, the web UI will lands on this URL of product domain
    //   }
    // };
    // this.navbar = new GlobalNavbar(this.navBarConfg);
    // this.navbar.load();
    // this.titleService.setTitle(this.config.applicationTitle);
    // // fix for notifications
    // if (this.configService?.config?.discover?.messages && this.configService?.config?.discover?.messages.length > 0) {
    //   for (const not of this.configService?.config?.discover?.messages) {
    //     const mes = not.message
    //     if (!mes.startsWith('NEW:[')) {
    //       this.navbar.publishEvent('REQUEST_TOP_NOTIFICATION', not);
    //     } else {
    //       // What's new page
    //       const news = mes.substring(5, mes.length - 1).split(']|[')
    //       this.whatsNewService.setNewItems(not.id, news)
    //     }

    //   }
    // }
    // /*
    // navbar.subscribeEvent('ORG_CHANGE', (data) => {
    //   console.log('*****', data);
    //   exit();
    // })*/
    // this.configurationMS.getWhoAmI().subscribe(async (me) => {
    //   let orgName = ''
    //   try {
    //     const accountJSONString = sessionStorage.getItem('myAccountsInfo');
    //     const accObj = JSON.parse(accountJSONString)
    //     orgName = accObj?.loggedInAccountInfo?.accountDisplayName
    //   } catch (e) {
    //     console.error('Parse error on account info: ', e)
    //   }
    //   console.log('Orgname: ', orgName)
    //   let accountIdToUse = me.subscriptionId
    //   // this.orgId = resp?.subscriptionId;

    //   // this.oauthService.getUserInfo().subscribe(_s =>
    //   //   console.log
    //   // )


    //   const subD = await this.oauthService.getSubscriptionDetails().toPromise()
    //   if (subD.subscriptionDetails?.length > 0) {
    //     accountIdToUse = subD.subscriptionDetails[0].tscAccountId
    //   }
    //   this.ngxPendoService.initialize({
    //     id: me.email,
    //     userId: me.id,
    //     firstname: me.firstName,
    //     lastname: me.lastName
    //   }, {
    //     id: accountIdToUse,
    //     subscriptionId: me.subscriptionId,
    //     name: orgName,
    //     project: 'Project Discover'
    //   });
    // })

  }

  // get config(): GeneralInformationConfiguration {
  //   return this.configService?.config?.discover?.general;
  // }

  ngAfterViewInit(): void {
    // window.setTimeout(() => {
    //   this.afterInit = true;
    // }, 100)
    // setTimeout(() => {
    //   this.navbar.customizePanel('help', this.helpContainer.nativeElement);
    //   this.showHelp = true;
    // }, 100)
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() { // Add log out function here
    this.authService.logoutRedirect();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
