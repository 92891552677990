import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from '@tibco/discover-client-lib';

// Pendo
import { NgxPendoModule } from 'ngx-pendo';

// PrimeNG
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';

// Material components
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// AppComponents
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './routes/welcome/welcome.component';
import { DiscoverComponent } from './routes/discover/discover.component';
import { ManageProcessAnalysisComponent } from './routes/manage-process-analysis/manage-process-analysis.component';
import { AnalyticsComponent } from './routes/analytics/analytics.component';
import { ManageInvestigationsComponent } from './routes/manage-investigations/manage-investigations.component';
import { ManageDatasetsComponent } from './routes/manage-datasets/manage-datasets.component';
import { APP_BASE_HREF, DatePipe, PlatformLocation } from '@angular/common';
import { AnalyticsDashboardComponent } from './components/analytics/analytics-dashboard/analytics-dashboard.component';
import { AnalyticsDictionaryComponent } from './components/analytics/dictionary/dictionary.component';
import { AnalysisFolderComponent } from './components/analytics/analysis-folder/analysis-folder.component';
import { BasicInfoComponent } from './components/new-analysis/basic-info/basic-info.component';
import { NewAnalysisSelectFolderComponent } from './components/new-analysis/select-folder/select-folder.component';
import { NewAnalysisStopCreationComponent } from './components/new-analysis/stop-creation/stop-creation.component';
import { MapComponent } from './components/new-analysis/map/map.component';
import { ConfirmationComponent } from './components/new-analysis/confirmation/confirmation.component';
import { ProcessAnalysisTableComponent } from './components/process-analysis/process-analysis-table/process-analysis-table.component';
import { InvestigationActionToolBarComponent } from './components/investigations/investigation-action-tool-bar/investigation-action-tool-bar.component';
import { IconButtonComponent } from './components/common/icon-button/icon-button.component';
import { NewsBannerComponent } from './components/common/news-banner/news-banner.component';
import { ActionButtonBarComponent } from './components/investigations/action-button-bar/action-button-bar.component';
import { ActionDialogComponent } from './components/investigations/action-dialog/action-dialog.component';
import { ProcessAnalysisDetailsComponent } from './components/process-analysis/process-analysis-details/process-analysis-details.component';
import { InvestigationTableComponent } from './components/investigations/investigation-table/investigation-table.component';
import { InvestigationListComponent } from './components/investigations/investigation-list/investigation-list.component';
import { InvestigationDetailsComponent } from './components/investigations/investigation-details/investigation-details.component';
import { ConfigurationService } from './service/configuration.service';
import { DocumentationService } from './service/documentation.service';
import { AuthService } from './service/auth.service';
import { UsersService } from './service/users.service';
import { CatalogMsService } from './service/catalog-ms.service';
import { VisualisationService } from './service/visualisation.service';
import { SettingsBrandingComponent } from './routes/settings-branding/settings-branding.component';
import { SettingsInvestigationsComponent } from './routes/settings-investigations/settings-investigations.component';
import { CreateInvestigationMenuComponent } from './components/analytics/create-investigation-menu/create-investigation-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WelcomePreviewComponent } from './components/admin/welcome-preview/welcome-preview.component';
import { HightlighEditComponent } from './components/common/hightligh-edit/hightligh-edit.component';
import { SplashScreenComponent } from './components/common/splash-screen/splash-screen.component';
import { AccessGuard } from './guards/access.guard';
import { OfflineGuard } from './guards/offline.guard';
import { Router } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NewDatasetWizardComponent } from './components/new-dataset/wizard/wizard.component';
import { NewDatasetBasicInfoComponent } from './components/new-dataset/basic-info/basic-info.component';
import { NewDatasetDatasourceComponent } from './components/new-dataset/datasource/datasource.component';
import { NewDatasetParseComponent } from './components/new-dataset/parse/parse.component';
import { CsvUploadComponent } from './components/new-dataset/csv-upload/csv-upload.component';
import { DatasetParseCsvComponent } from './components/new-dataset/parse-csv/parse-csv.component';
import { NewDatasetAttributesComponent } from './components/new-dataset/attributes/attributes.component';
import { NewDatesetDateParserComponent } from './components/new-dataset/date-parser/date-parser.component';
import { NewDatasetConfirmationComponent } from './components/new-dataset/confirmation/confirmation.component';
import { NewDatasetConnectionsComponent } from './components/new-dataset/connections/connections.component';
import { NewDatasetDataPreviewComponent } from './components/new-dataset/data-preview/data-preview.component';
import { CaseStateEditComponent } from './components/admin/case-state-edit/case-state-edit.component';
import { YesnoConfirmationComponent } from './components/common/yesno-confirmation/yesno-confirmation.component';

import { AutoMappingService } from './service/auto-mapping.service';
import { SettingsAutomapComponent } from './routes/settings-automap/settings-automap.component';
import { AnalyticCardComponent } from './components/analytics/analytic-card/analytic-card.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth-interceptor';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalGuard,
  MsalInterceptor,
} from '@azure/msal-angular'; // Import MsalInterceptor
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { SelectDatasetComponent } from './components/new-analysis/select-dataset/select-dataset.component';
import { DataPreviewComponent } from './components/new-analysis/data-preview/data-preview.component';
import { MapPanelComponent } from './components/new-analysis/map-panel/map-panel.component';
import { NewAnalysisComponent } from './routes/new-analysis/new-analysis.component';
import { TemplateCardComponent } from './components/templates/template-card/template-card.component';
import { ManageTemplatesComponent } from './routes/manage-templates/manage-templates.component';
import { ListTemplatesComponent } from './components/templates/list-templates/list-templates.component';
import { TemplateSelectComponent } from './routes/template-select/template-select.component';
import { AdminComponent } from './routes/admin/admin.component';
import { TemplateEditorComponent } from './routes/template-editor/template-editor.component';
import { TemplateBasicInfoComponent } from './components/templates/template-basic-info/template-basic-info.component';
import { TemplateAnalyticsMenuConfigurationComponent } from './components/templates/template-analytics-menu-configuration/template-analytics-menu-configuration.component';
import { SpotfireViewerModule } from '@tibcosoftware/spotfire-wrapper';
import { TemplateAnalyticsMenuDragdropComponent } from './components/templates/template-analytics-menu-dragdrop/template-analytics-menu-dragdrop.component';
import { TemplateEditMenuPopupComponent } from './components/templates/template-edit-menu-popup/template-edit-menu-popup.component';
import { TemplateConfirmationComponent } from './components/templates/template-confirmation/template-confirmation.component';
import { IconGridComponent } from './components/common/icon-grid/icon-grid.component';
import { TemplateAdvancedComponent } from './components/templates/template-advanced/template-advanced.component';
import { TemplateAnalyticsComponent } from './components/templates/template-analytics/template-analytics.component';
import { TemplateAnalyticPreviewComponent } from './components/templates/template-analytic-preview/template-analytic-preview.component';
import { TemplateMarkingSelectorComponent } from './components/templates/template-marking-selector/template-marking-selector.component';
import { ActivitiesPipe } from './pipes/activities.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { LastSplitPipe } from './pipes/last-split.pipe';
import { environment, LANGUAGES } from 'src/environments/environment';
import { ProgressScreenComponent } from './components/common/progress-screen/progress-screen.component';
import { PulseLoadingComponent } from './components/common/pulse-loading/pulse-loading.component';
import { MiniProgressBarComponent } from './components/common/mini-progress-bar/mini-progress-bar.component';

import { DragDropFieldsListComponent } from './routes/settings-investigations/drag-drop-fields-list/drag-drop-fields-list.component';
import { ResizableDraggableComponent } from './components/analytics/resizable-draggable/resizable-draggable.component';
import { SfFilterPanelComponent } from './components/analytics/sf-filter-panel/sf-filter-panel.component';
import { TemplateAnalyticsFiltersComponent } from './components/templates/template-analytics-filters/template-analytics-filters.component';
import { ErrorDetailsComponent } from './components/common/error-details/error-details.component';
import { DatasetDetailsComponent } from './components/dataset/dataset-details/dataset-details.component';
import { HelpContainerComponent } from './components/common/help-container/help-container.component';
import { ProcessAnalysisCompareComponent } from './components/process-analysis/process-analysis-compare/process-analysis-compare.component';
import { MetricComparisonComponent } from './components/process-analysis/metric-comparison/metric-comparison.component';

import { DialogModule } from 'primeng/dialog';
import { ProcessAnalysisMoreinfoComponent } from './components/process-analysis/process-analysis-moreinfo/process-analysis-moreinfo.component';
import { DataPreviewTableComponent } from './components/common/data-preview-table/data-preview-table.component';

import { NewProcessDocumentWizardComponent } from './components/new-process-document/wizard/wizard.component';
import { UploadProcessDocumentWizardComponent } from './components/new-process-document/upload-document-wizard/upload-document-wizard.component';
import { UploadProcessModelDocumentComponent } from './components/new-process-document/upload-document/upload-document.component'
import { NewProcessDocumentBasicInfoComponent } from './components/new-process-document/basic-info/basic-info.component';
import { ProcessDocumentLocationComponent } from './components/document-location/document-location.component';
import { NewProcessDocumentConfirmationComponent } from './components/new-process-document/confirmation/confirmation.component';
import { SelectNimbusProcessComponent } from './components/select-nimbus-process/select-nimbus-process.component';
import { InvestigationDetailsTableComponent } from './components/investigations/investigation-details-table/investigation-details-table.component';
import { InvestigationsDetailsComponent } from './routes/investigations-details/investigations-details.component';
import { InvestigationAnalyticComponent } from './components/investigations/investigation-analytic/investigation-analytic.component';
import { NoAccessComponent } from './routes/no-access/no-access.component';
import { AnalyticFilterSidePanelComponent } from './components/analytics/analytic-filter-side-panel/analytic-filter-side-panel.component';
import { SelectConnectionComponent } from './components/new-dataset/select-connection/select-connection.component';

import { NewConnectionWizardComponent } from './components/connections/new-connection-wizard/new-connection-wizard.component';
import { ConnectionTypeSelectionFormComponent } from './components/connections/connection-type-selection-form/connection-type-selection-form.component';
import { ConnectionParametersFormComponent } from './components/connections/connection-parameters-form/connection-parameters-form.component';
import { ConnectionCreatedFormComponent } from './components/connections/connection-created-form/connection-created-form.component';
import { EditConnectionWizardComponent } from './components/connections/edit-connection-wizard/edit-connection-wizard.component';

import { ApiModule as DiscoverClientApi } from '@tibco/discover-client-lib';
import { BASE_PATH } from '@tibco/discover-client-lib';
import { ManageConnectionsComponent } from './routes/manage-connections/manage-connections.component';
import { ConnectionAddlInfoComponent } from './components/connections/connection-addl-info/connection-addl-info.component';
import { ActivationComponent } from './routes/activation/activation.component';
import { TemplatePreviewDataSelectorComponent } from './components/templates/template-preview-data-selector/template-preview-data-selector.component';
import { InternalMessageService } from './service/internal-message.service';
// import { OAUTH_LOCAL_STORAGE_KEY } from './app.settings';
import { NgChartsModule } from 'ng2-charts';
import { WhatsNewComponent } from './components/common/whats-new/whats-new.component';
import { WhatsNewService } from './service/whats-new.service';
import { CommonService } from './service/common.service';
import { SpotfirewpService } from './service/spotfirewp.service';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { SelectOrganizationComponent } from './routes/select-organization/select-organization.component';
import { CookieService } from 'ngx-cookie-service';
import { SnackbarService } from './service/snackbar.service';
import { RepositoryService } from './service/repository.service';
import { SuperadminService } from './service/superadmin.service';

// Directives
import { ProcessAnalysisResizableDirective } from './routes/manage-process-analysis/manage-process-analysis-resize.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangeRoleDialogComponent } from './components/change-role-dialog/change-role-dialog.component';
import { RemoveUserDialogComponent } from './components/remove-user-dialog/remove-user-dialog.component';
import { RemoveDatasetDialogComponent } from './components/remove-dataset-dialog/remove-dataset-dialog.component';
import { RemoveAnalysisDialogComponent } from './components/remove-analysis-dialog/remove-analysis-dialog.component'
import { AdministrationComponent } from './routes/administration/administration.component';
import { CreateOrgComponent } from './routes/create-organization/create-org.component';
import { ToggleSuperadminDialogComponent } from './components/toggle-superadmin-dialog/toggle-superadmin-dialog.component';
import { SnackBarContentComponent } from './components/snack-bar-content/snack-bar-content.components'
import{ GenericPopupComponent } from './components/generic-popup/generic-popup.component'
import { TranslatePipe  } from '../app/pipes/translate.pipe';
import { RelativeDatePipe } from '../app/pipes/date-relative.pipe';
import { APP_INITIALIZER } from '@angular/core';
import { TranslationService } from './service/translate.service';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ReleaseNotesComponent } from './components/common/release-notes/release-notes.component';

// Locale
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { ProcessAnalysisReportComponent } from './components/process-analysis/process-analysis-report/process-analysis-report.component';
import { UploadDocumentMapComponent } from './components/new-process-document/upload-document-map/upload-document-map.component';
import { AdminNavBarComponent } from './components/admin-nav-bar/admin-nav-bar.component';
import { ProcessAnalysisDialogCacheAlertComplianceComponent } from './components/process-analysis/process-analysis-dialog-cache-alert-compliance/process-analysis-dialog-cache-alert-compliance.component';
import { DictionaryDialogComponent } from './components/common/dictionary-dialog/dictionary-dialog.component';
import { ControlPanelComponent } from './routes/control-panel/control-panel.component';
import { IconSidebarComponent } from './components/common/icon-sidebar/icon-sidebar.component';
import { ControlPanelUsersComponent } from './routes/control-panel-users/control-panel-users.component';
import { ControlPanelAddUserComponent } from './routes/control-panel-add-user/control-panel-add-user.component';
import { OrgStatisticsComponent } from './routes/org-statistics/org-statistics.component';
import { OrgEditComponent } from './routes/org-edit/org-edit.component';
import { OrgUsersComponent } from './routes/org-users/org-users.component';
import { OrgAddUserComponent } from './routes/org-add-user/org-add-user.component';
import { OrgInfoComponent } from './components/org-info/org-info.component';
import { ExportXesDialogComponent } from './components/export-xes-dialog/export-xes-dialog.component';

registerLocaleData(localeIt, 'it_IT');
registerLocaleData(localeEs, 'es_ES');
registerLocaleData(localeEn, 'en_US');

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function servicesOnRun() {
  // oauthService: OauthService, config: ConfigurationService, formsService: FormsService
  // return () => oauthService.initialize().then(
  //   () => config.refresh().then(
  //     // () => appDefinitionService.loadFormResources()
  //     () => formsService.getCookie(OAUTH_LOCAL_STORAGE_KEY).then(
  //       () => {
  //         formsService.loadFormResources();
  //       }
  //     )
  //     , (e) => {
  //       console.error('Error getting Config: ', e)
  //     })
  // );
}
export function initApp(translationService: TranslationService) {
  return () => {
    const defaultLang = LANGUAGES.find((lang) => lang.default == true).code;
    const savedLang = localStorage.getItem('language') || defaultLang; 
    translationService.loadTranslations(savedLang); 
  }
}

const configurationProvider = () => {
  const configurationParams: ConfigurationParameters = {
    withCredentials: true,
    basePath: environment.apiURL,
  };
  return new Configuration(configurationParams);
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    DiscoverComponent,
    ManageProcessAnalysisComponent,
    AnalyticsComponent,
    ManageInvestigationsComponent,
    ManageDatasetsComponent,
    NewDatasetWizardComponent,
    NewDatasetBasicInfoComponent,
    NewDatasetDatasourceComponent,
    NewDatasetParseComponent,
    NewDatesetDateParserComponent,
    CsvUploadComponent,
    DatasetParseCsvComponent,
    NewDatasetAttributesComponent,
    NewDatasetConfirmationComponent,
    NewDatasetConnectionsComponent,
    NewDatasetDataPreviewComponent,
    AnalyticsDashboardComponent,
    AnalyticsDictionaryComponent,
    AnalysisFolderComponent,
    BasicInfoComponent,
    NewAnalysisSelectFolderComponent,
    NewAnalysisStopCreationComponent,
    MapComponent,
    ConfirmationComponent,
    ProcessAnalysisTableComponent,
    InvestigationActionToolBarComponent,
    IconButtonComponent,
    NewsBannerComponent,
    ActionButtonBarComponent,
    ActionDialogComponent,
    ProcessAnalysisDetailsComponent,
    InvestigationTableComponent,
    InvestigationListComponent,
    InvestigationDetailsComponent,
    SettingsBrandingComponent,
    SettingsInvestigationsComponent,
    CreateInvestigationMenuComponent,
    WelcomePreviewComponent,
    HightlighEditComponent,
    SplashScreenComponent,
    SettingsAutomapComponent,
    AnalyticCardComponent,
    SelectDatasetComponent,
    DataPreviewComponent,
    MapPanelComponent,
    NewAnalysisComponent,
    TemplateCardComponent,
    ManageTemplatesComponent,
    ListTemplatesComponent,
    TemplateSelectComponent,
    AdminComponent,
    TemplateEditorComponent,
    TemplateBasicInfoComponent,
    TemplateAnalyticsMenuConfigurationComponent,
    TemplateAnalyticsMenuDragdropComponent,
    TemplateEditMenuPopupComponent,
    TemplateConfirmationComponent,
    CaseStateEditComponent,
    YesnoConfirmationComponent,
    IconGridComponent,
    TemplateAdvancedComponent,
    TemplateAnalyticsComponent,
    TemplateAnalyticPreviewComponent,
    TemplateMarkingSelectorComponent,
    ActivitiesPipe,
    RelativeTimePipe,
    HighlightPipe,
    ReplacePipe,
    LastSplitPipe,
    ProgressScreenComponent,
    PulseLoadingComponent,
    MiniProgressBarComponent,
    DragDropFieldsListComponent,
    ResizableDraggableComponent,
    SfFilterPanelComponent,
    TemplateAnalyticsFiltersComponent,
    DragDropFieldsListComponent,
    ErrorDetailsComponent,
    DatasetDetailsComponent,
    HelpContainerComponent,
    NewProcessDocumentWizardComponent,
    UploadProcessDocumentWizardComponent,
    UploadProcessModelDocumentComponent,
    NewProcessDocumentBasicInfoComponent,
    ProcessDocumentLocationComponent,
    NewProcessDocumentConfirmationComponent,
    SelectNimbusProcessComponent,
    ProcessAnalysisCompareComponent,
    MetricComparisonComponent,
    ProcessAnalysisMoreinfoComponent,
    DataPreviewTableComponent,
    InvestigationDetailsTableComponent,
    InvestigationsDetailsComponent,
    InvestigationAnalyticComponent,
    NoAccessComponent,
    AnalyticFilterSidePanelComponent,
    SelectConnectionComponent,
    NewConnectionWizardComponent,
    ConnectionTypeSelectionFormComponent,
    ConnectionParametersFormComponent,
    ConnectionCreatedFormComponent,
    ManageConnectionsComponent,
    ConnectionAddlInfoComponent,
    EditConnectionWizardComponent,
    ActivationComponent,
    TemplatePreviewDataSelectorComponent,
    WhatsNewComponent,
    SelectOrganizationComponent,
    ProcessAnalysisResizableDirective,
    ChangeRoleDialogComponent,
    RemoveUserDialogComponent,
    RemoveDatasetDialogComponent,
    RemoveAnalysisDialogComponent,
    AdministrationComponent,
    CreateOrgComponent,
    ToggleSuperadminDialogComponent,
    SnackBarContentComponent,
    GenericPopupComponent,
    TranslatePipe,
    LanguageSelectorComponent,
    ReleaseNotesComponent,
    ProcessAnalysisReportComponent,
    UploadDocumentMapComponent,
    AdminNavBarComponent,
    UploadDocumentMapComponent,
    ProcessAnalysisDialogCacheAlertComplianceComponent,
    DictionaryDialogComponent,
    ControlPanelComponent,
    IconSidebarComponent,
    ControlPanelUsersComponent,
    ControlPanelAddUserComponent,
    OrgStatisticsComponent,
    OrgEditComponent,
    OrgUsersComponent,
    OrgAddUserComponent,
    OrgInfoComponent,
    RelativeDatePipe,
    ExportXesDialogComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ApiModule,
    // TcCoreLibModule.forRoot(tcCoreConfig),
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    FlexModule,
    MatCardModule,
    MatTooltipModule,
    MatExpansionModule,
    DragDropModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    OverlayModule,
    MatStepperModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    TableModule,
    TreeModule,
    DropdownModule,
    ProgressBarModule,
    SpotfireViewerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    ScrollingModule,
    DialogModule,
    NgChartsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatSnackBarModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.authConfig.clientId, // Application (client) ID from the app registration
          authority: environment.authConfig.authority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.authConfig.redirect_url, // This is your redirect URI
          postLogoutRedirectUri:
            environment.authConfig.end_session_redirect_url,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.authConfig.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          [environment.apiURL, environment.authConfig.scopes],
        ]),
      }
    ),
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendoApiKey,
      pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
    }),
  ],
  entryComponents: [ActionDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [TranslationService],
      multi: true
    },
    MsalGuard,
    DatePipe,
    RelativeTimePipe,
    // for using oAuth
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    InternalMessageService,
    ConfigurationService,
    DocumentationService,
    AuthService,
    UsersService,
    CatalogMsService,
    DecimalPipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: servicesOnRun,
    //   multi: true,
    //   deps: [OauthService, ConfigurationService, FormsService]
    // },
    AccessGuard,
    OfflineGuard,
    WhatsNewService,
    CommonService,
    SpotfirewpService,
    AutoMappingService,
    SnackbarService,
    RepositoryService,
    SuperadminService,
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: Configuration, useFactory: configurationProvider, deps: [] },
    { provide: BASE_PATH, useValue: environment.discoverClientBasePath },
    DiscoverClientApi,
    CookieService,
    VisualisationService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // register icons
    this.matIconRegistry.addSvgIconLiteral(
      'tcs-plus-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<?xml version="1.0" encoding="UTF-8"?>\n' +
          '<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
          '    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
          '        <g id="Foundations/system-icon/ic_add" transform="translate(-2.000000, -2.000000)" fill="#727272">\n' +
          '            <path class="tcs-plus-icon" d="M20.6845201,13.3154056 L13.3156532,13.3154056 L13.3156532,20.6842724 C13.3156532,21.0211304 13.1872509,21.3579884 12.9304463,21.614793 C12.4168369,22.1284023 11.5830144,22.1284023 11.0694051,21.614793 C10.8129968,21.3583847 10.6841981,21.0211304 10.6845944,20.6838761 L10.6845944,13.3158019 L3.31612385,13.3154056 C2.97886959,13.3158019 2.64201164,13.1873995 2.38520698,12.9305949 C1.87159767,12.4169856 1.87159767,11.5831631 2.38520698,11.0695537 C2.64201164,10.8127491 2.97886959,10.6843468 3.31572755,10.6843468 L10.6845944,10.6843468 L10.6845944,3.31547987 C10.6845944,2.97862192 10.8126005,2.64216026 11.0694051,2.38535561 C11.5834107,1.87135 12.4168369,1.8717463 12.9304463,2.38535561 C13.1872509,2.64216026 13.3156532,2.97901822 13.3156532,3.31587618 L13.3160495,10.6843468 L20.6841238,10.6843468 C21.0209818,10.6843468 21.358236,10.8131454 21.6146444,11.0695537 C22.1282537,11.5831631 22.12865,12.4165893 21.6146444,12.9305949 C21.3578397,13.1873995 21.0213781,13.3154056 20.6845201,13.3154056 Z" ></path>\n' +
          '        </g>\n' +
          '    </g>\n' +
          '</svg>'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `cache-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/cache.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `report-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/report.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `archive-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/archive.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `unarchive-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/unarchive.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `edit-process-model`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/edit-process-model.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `upload-process-model`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/upload-process-model.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `it_IT-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/flags/it_IT.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `en_US-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/flags/en_US.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `es_ES-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/flags/es_ES.svg")
    );
  }
}
