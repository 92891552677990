<div class="container-flex-fluid">
  <div class="flex">
    <div class="flex-item"> <!-- [hidden]="!fullSpotfireConfig.hasAnalytics" -->
      <uxpl-object-header [config]="objHeaderConfig" (back)="goProcessAnalysis()" (editDetail)="editTemplate()">
        <!--<uxpl-button [disabled]="!markedVariants || markedVariants.length==0" slot="navigation-content"
                    id="#createProcessDocument" type="secondary" (click)="createProcessDocument()"></uxpl-button> -->
        <!-- <uxpl-object-header [config]="objHeaderConfig" (back)="goProcessAnalysis()" (editDetail)="editTemplate()"> -->
        <button slot="navigation-content" mat-flat-button>
          <mat-spinner diameter="20" *ngIf="!isfFullyLoaded" ></mat-spinner>
        </button>
        <button slot="navigation-content"
          id="dictionary"
          mat-flat-button
          *ngIf="buttonDictionaryVisible"
          [disabled]="!buttonDictionaryActive"
          (click)="openDictionary()"
          width="auto"
          [disabled]="!isfFullyLoaded">
          <uxpl-icon class="edit-icon"
            icon="pl-icon-document"
            width="20px"></uxpl-icon>
        </button>
        <button
          slot="navigation-content"
          mat-stroked-button
          color="primary"
          [matTooltip]="editTemplateTooltip"
          matTooltipClass="custom-tooltip"
          (click)="editTemplate()">
          <mat-icon>lan</mat-icon>
        </button>
        <button *ngIf="templateToUse && templateToUse.name == 'Conformance Checking' && !loadingReferenceModel && !analysisReference"
          slot="navigation-content"
          mat-stroked-button
          color="primary"
          [disabled]="!(isfFullyLoaded && !isAnalysisCached)"
          id="#assignProcessDocument"
          [matTooltip]="analysisReferenceTooltip"
          matTooltipClass="custom-tooltip"
          (click)="assignProcessDocument()">
          <mat-icon>layers</mat-icon>
        </button>
        <button *ngIf="templateToUse && templateToUse.name == 'Conformance Checking' && !loadingReferenceModel && analysisReference"
          id="reference-assigned"
          slot="navigation-content"
          [disabled]="!(isfFullyLoaded && !isAnalysisCached)"
          mat-stroked-button
          color="primary"
          [matTooltip]="analysisReferenceTooltip"
          matTooltipClass="custom-tooltip"
          (click)="editProcessDocument()">
          <mat-icon id="reference-assigned-check">library_add_check</mat-icon>
          <mat-icon id="reference-assigned-edit" svgIcon="edit-process-model"></mat-icon>
        </button>
        <!-- <div *ngIf="templateToUse && templateToUse.name == 'Conformance Checking' && !loadingReferenceModel && analysisReference" class="reference-model" slot="navigation-content">
          <span class="state-icon">
            <img src="assets/svg/ic-process.svg"/>
          </span>
          <span>{{"process.analysis.model.reference" | translate}}</span>
          <span class="model-name">{{analysisReference.referenceName}}</span>
          <uxpl-icon class="edit-icon" icon="pl-icon-edit" width="20px" height="20px" color="#727272"
                    *ngIf="isfFullyLoaded"
                    (click)="editProcessDocument()"></uxpl-icon>
        </div> -->
        <button *ngIf="templateToUse && templateToUse.name == 'Conformance Checking'"
          [disabled]="!(isfFullyLoaded && isActivityValuesReady)"
          slot="navigation-content"
          id="uploadProcessDocument"
          mat-stroked-button
          color="primary"
          [matTooltip]="'process.analysis.model.upload' | translate"
          matTooltipClass="custom-tooltip"
          (click)="uploadProcessDocument()">
          <mat-icon svgIcon="upload-process-model"></mat-icon>
        </button>
        <button *ngIf="templateToUse && templateToUse.name == 'Conformance Checking'"
          [disabled]="!markedVariants || markedVariants.length==0"
          slot="navigation-content"
          id="#createProcessDocument"
          mat-stroked-button
          color="primary"
          [matTooltip]="'process.analysis.model.create' | translate"
          matTooltipClass="custom-tooltip"
          (click)="createProcessDocument()">
          <mat-icon>library_add</mat-icon>
        </button>
        <!-- <uxpl-button slot="navigation-content" style="width: 160px" icon="assets/images/svg/plus.svg"
                    (clicked)="showCreateCaseMenu()" [disabled]="!isfFullyLoaded">
          Investigation
          <uxpl-popup *ngIf="investigationConfig" #popup id="popup" slot="popup" x-position="before"
                      content-id="my-popup-content" max-width="400px"
                      max-height="450px">
            <create-investigation-menu #createinvestigation id="my-popup-content" [caseMarking]="markedCases"
                                      [variantMarking]="markedVariants" [investigationConfig]="investigationConfig"
                                      (createInvestigationEvent)="createInv($event)"></create-investigation-menu>
          </uxpl-popup>
        </uxpl-button> -->
        <button slot="navigation-content"
          id="#refresh"
          mat-stroked-button
          color="primary"
          (referenceModelUpdated)="loadAnalytic()"
          (click)="loadAnalytic()"
          icon="assets/images/svg/pl-icon-refresh.svg">
          <mat-icon>refresh</mat-icon>
          {{"refresh" | translate}}
        </button>
      </uxpl-object-header>
    </div>
    <div class="flex-item">
      <div class="analytics-dashboard-wrapper flex" *ngIf="spotfireDXP && hideSelectProcess">
        <div class="analytics-dashboard-left-nav flex-item">
          <uxpl-left-nav-multi #leftNav [tabs]="leftNavTabs" (selected)="handleClick($event)" class="sidebar-left-nav"></uxpl-left-nav-multi>
        </div>
        <div id="sidebar-content" class="analytics-dashboard-analytics flex-item" #analysisContainer>
          <mat-card class="width-xs-10">
            <mat-card-content>
              <spotfire-viewer [sid]="MAIN_DIV" #analysis
                              [url]="spotfireServer"
                              [path]="spotfireDXP"
                              [parameters]="analysisParameters"
                              [markingOn]="markingOn"
                              version="10.10"
                              (document)="setDocument($event)"
                              (markingEvent)="marking($event)"
                              [maxRows]="10000"
                              [customization]="setSpotfireConfiguration()"
                              [linkedReportIds]="[FILTER_DIV]">
              </spotfire-viewer>
            </mat-card-content>
          </mat-card>
        </div>
        <div id="filter-container" class="flex-item">
          <div class="filter-side" *ngIf="hideSelectProcess">
            <analytic-filter-side-panel [filterStats]="filterStats" [filterCount]="filterCount"
                                        [markingStats]="markingStats" [filterPanelOpen]="showFilterPanel"
                                        (filterClicked)="toggleFilterPanel()"
                                        (newTabClicked)="newFilterTab()"
                                        (resetMarkingClicked)="marking('CLEAR')"
                                        [filterPage]="filterPage"
                                        (filterIds)="filterIds"
                                        ></analytic-filter-side-panel>
            <!-- button for toggle back -->
            <!-- <div class="toggle-filter-side-button" (click)="toggleDrawer()">
              <div class="collapse-icon-holder">
                <uxpl-icon class="collapse-icon" icon="pl-icon-collapse-horizontal" width="24px"
                            height="24px"></uxpl-icon>
              </div>
            </div> -->
          </div>
        </div>
        <div class="flex-item" *ngIf="!analysis">
          <mat-card>
            <mat-card-content>
              <div class="img-center">
                <img width="204" src="assets/images/png/no-data.png">
              </div>
              <div class="no-data-available"><br></div>
              <div class="no-data-available" style="font-weight: bold">{{"process.analysis.noprocess.selected" | translate}}<br></div>
              <div class="no-data-available"><br></div>
              <div class="no-data-available">
                {{"process.analysis.start.1" | translate}}
                <span class="hyperlink" (click)="selectAnalytic()">{{"process.analysis.start.2" | translate}}</span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <sf-filter-panel #spotfireFilterPanel class="filter-panel" *ngIf="spotfireDXP"
        [hidden]="!showFilterPanel" [filterIds]="filterIds" [filterConfig]="filterConfig"
        (enableSFPointerEvents)="enableSFPointerEvents($event)" (openFilterPage)="openFPage($event)"
        (closeFPEvent)="showFilterPanel = false" (panelResize)="filterPanelResize($event)"
        [width]="filterPanelSize.x" [height]="filterPanelSize.y" [top]="filterPanelTop"
        [left]="filterPanelLeft"
        (setFilter)="setFilter($event)" (resetFilter)="resetFilter($event)" (resetAll)="resetAllFilters($event)"
        (setFilterByTime)="setFilterByTime($event)"
        [filterByTab]="filterByTab" [datasetId]="analysisObject.dataset.id" [availableTimeRange]="availableTimeRange" [dateTimeFormat]="dateTimeFormat"
        [attributes]="attributes" [isAttributeReady]="isAttributeReady" (setFilterAttributes)="setFilterAttributes($event)" (resetFilterAttr)="resetFilterAttr($event)"
        (toggleFilter)="toggleFilter($event)"
        [filtersEnabledOrg]="filtersEnabledOrg" [currentOrg]="currentOrg"
      ></sf-filter-panel>
    </div>

    <div class="floating-filter-button" (click)="toggleDrawer()" *ngIf="!filterPanelVisible">
      <uxpl-icon icon="pl-icon-collapse-horizontal" width="24px"
                height="24px"></uxpl-icon>
    </div>

    <!-- <div class="spinner-holder" *ngIf="!isfFullyLoaded && hideSelectProcess">
      <div class="spinner-wrapper">
        <pulse-loading></pulse-loading>
      </div>
    </div> -->

  </div>
</div>
