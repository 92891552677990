<div class="nimbusLocation">
    <div class="root-folder">
        <div class="icon">
            <uxpl-icon class="chevron-icon" icon="pl-icon-chevron-left" width="24px" height="24px"></uxpl-icon>
        </div>
        <div class="location">
            <span class="clickable blue-txt" (click)="naviToFolder(-1)">Processes</span><span *ngFor="let folder of breadcrumbs | slice:1; index as i; last as isLast"  [ngClass]="{'clickable': !isLast, 'blue-txt': !isLast}" (click)="naviToFolder(i)"> / {{folder.name}}</span>
        </div>
        <div class="create-folder" *ngIf="config.newFolder">
            <span (click)="enterCreateNewFolderMode()">{{ "process.analysis.model.new.folder" | translate }}</span>
        </div>
    </div>


    <div #scrollableFolderContainer class="sub-folders" [style.maxHeight.px]="listHeight" [style.minHeight.px]="listHeight" (scroll)="onFoldersScroll()">
        <div class="folder-row" *ngIf="editNewFolderMode">
            <div class="icon">
                <span class="state-icon">
                    <img src="assets/svg/ic-folder.svg"/>
                </span>
            </div>
            <div class="name-col">
                <input #newFolderInput type="text"
                    (keyup.enter)="onNewFolderNameEnter(newFolderInput.value)"
                    (blur)="onNewFolderNameBlur(newFolderInput.value)">
            </div>
            <div class="selected">
            </div>
        </div>
        <folder class="folder-row" id="{{folder.mapFolderId}}" [ngClass]="{onlyFolderName: config.select!='folder', 'pointer-cursor': config.select=='folder'}" *ngFor="let folder of folders">
            <div class="icon">
                <span class="state-icon">
                    <img src="assets/svg/ic-folder.svg"/>
                </span>
            </div>
            <div class="name-col">
                <div class="name" [ngClass]="{'blue-txt': canGoIntoFolder(folder)}">
                    <span (click)="clickOnFolderName(folder, $event)">{{folder.name}}</span>
                    <span *ngIf="config.select == 'process' && !subFoldersCache[folder.mapFolderId]"> ...</span>
                </div>
                <div class="subfolder-num" *ngIf="config.select == 'folder' && subFoldersCache[folder.mapFolderId]">{{subFoldersCache[folder.mapFolderId].subFolders? subFoldersCache[folder.mapFolderId].subFolders.length : 0}} folders</div>
                <div class="subfolder-num" *ngIf="config.select == 'folder' && !subFoldersCache[folder.mapFolderId]"><span>loading ...</span></div>
            </div>
            <div class="selected">
                <div class="selected-col" *ngIf="selectedFolder?.mapFolderId === folder.mapFolderId">
                    <uxpl-icon class="inline-ele" icon="pl-icon-checkmark" width="14px" height="14px"></uxpl-icon>
                    <div class="inline-ele">{{ "selected.female" | translate }}</div>
                </div>
                <uxpl-button class="selectBtn" type="secondary" *ngIf="config.select == 'folder' && selectedFolder?.mapFolderId !== folder.mapFolderId" height="24px" width="48px" (click)="selectFolder(folder)">{{ 'select' | translate }}</uxpl-button>
            </div>
        </folder>
        <div class="process-row" [ngClass]="{onlyFolderName: config.select!='folder', 'pointer-cursor': config.select=='process'}" *ngFor="let map of maps">
            <div class="icon">
                <span class="state-icon">
                    <img src="assets/svg/ic-process.svg"/>
                </span>
            </div>
            <div class="name-col">
                <div class="name">{{map.name}}</div>
            </div>
            <div class="selected">
                <div class="selected-col" *ngIf="selectedMap?.processId === map.processId">
                    <uxpl-icon class="inline-ele" icon="pl-icon-checkmark" width="14px" height="14px"></uxpl-icon>
                    <div class="inline-ele">{{ "selected.female" | translate }}</div>
                </div>
                <uxpl-button class="selectBtn" type="secondary" *ngIf="config.select !== 'folder' && selectedMap?.processId !== map.processId" height="24px" width="48px" (click)="selectMap(map)">{{ 'select' | translate }}</uxpl-button>
            </div>
        </div>
    </div>

</div>






