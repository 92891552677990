import {Component, Input} from '@angular/core';

@Component({
  selector: 'connection-created-form',
  templateUrl: './connection-created-form.component.html',
  styleUrls: ['./connection-created-form.component.scss']
})
export class ConnectionCreatedFormComponent {

  constructor() {}

  @Input() mode: string;

  get completionMessage() {
    if (this.mode === 'update') {
      return 'Your connection has successfully been updated. You can now create a new dataset using this connection or manage it from the Datasets tab.'
    } else {
      return 'Your connection has successfully been created. You can now create a new dataset using this connection or manage it from the Datasets tab.'
    }
  }

}
