import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import { Dataset, Template } from '@tibco/discover-client-lib';

import { User } from 'src/app/models_ui/users';
import { Roles, UsersService } from 'src/app/service/users.service';
import { ChangeRoleDialogComponent } from '../../components/change-role-dialog/change-role-dialog.component';
// import { RemoveUserDialogComponent } from 'src/app/components/remove-user-dialog/remove-user-dialog.component';
import { TranslationService } from 'src/app/service/translate.service';
import { IconSidebar } from 'src/app/components/common/icon-sidebar/icon-sidebar.component';
import { RemoveUserDialogComponent } from 'src/app/components/remove-user-dialog/remove-user-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoResponse } from 'src/app/models_ui/auth';

@Component({
  selector: 'control-panel-users',
  templateUrl: './control-panel-users.component.html',
  styleUrls: ['./control-panel-users.component.css']
})
export class ControlPanelUsersComponent implements AfterViewInit, OnInit {

  iconSidebarData: IconSidebar = {
    "main": [{
      "label": "Control panel",
      "icon": "dashboard",
      "link": "/discover/control-panel",
      "routerLinkActiveExact": true 
    },{
      "label": "Users",
      "icon": "group",
      "link": "/discover/control-panel/users",
      "routerLinkActiveExact": true 
    },{
      "label": "Add user",
      "icon": "person_add",
      "link": "/discover/control-panel/add-user",
      "routerLinkActiveExact": true 
    }]
  };
  userinfo: UserInfoResponse;
  isLoading: boolean = false;
  templates: Template[];
  search: string;
  datasets: Dataset[];
  roles: Roles;
  displayedColumns: string[] = ['name', 'email', 'role', 'actions'];
  dataSource = new MatTableDataSource<any>();
  placeholderSearch: string;
  filteredUsers: Partial<User>[] = [];
  constructor(
    private usersService: UsersService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private cookieService: CookieService,
  ) {}
  ngOnInit(): void {
    this.isLoading = true;
    this.placeholderSearch = this.translationService.translate("user.manager.placeholder");

    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
    }

    this.translationService.currentLang$.subscribe(() => { 
      this.placeholderSearch = this.translationService.translate("user.manager.placeholder");
    });

    this.usersService.getRoles().subscribe((res: Roles) => (this.roles = res));
    this.usersService.getUsers().subscribe((res: User[]) => {
      this.dataSource.data = res;
      this.isLoading = false;
    });
  }
  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh() {
    this.isLoading = true;
    this.usersService.getUsers().subscribe((res: User[]) => {
      this.dataSource.data = res;
      this.isLoading = false;
    });
  }

  handleSearch(event: CustomEvent) {
    this.search = event?.detail?.value?.toLowerCase();
    this.usersService.getUsers().subscribe((users: any[]) => {
      this.filteredUsers = users.filter(
        (user) =>
          user.displayName.toLowerCase().includes(this.search) ||
          user.firstName.toLowerCase().includes(this.search) ||
          user.lastName.toLowerCase().includes(this.search) ||
          user.email.toLowerCase().includes(this.search) ||
          user.role.toLowerCase().includes(this.search)
      );
      console.log(this.filteredUsers);
      this.dataSource.data = this.filteredUsers;
    });
  }

  openChangeRoleDialog(userId: string): void {
    const dialogRef = this.dialog.open(ChangeRoleDialogComponent, {
      width: '250px',
      data: {
        currentRole: this.dataSource.data.find((user) => user.id === userId)
          .role,
        roles: this.roles,
        userId: userId,
      },
    });
    // Subscribe to emitted event from dialog
    dialogRef.componentInstance.roleUpdated.subscribe((newRole: string) => {
      // Update the role in the data source
      const userToUpdate = this.dataSource.data.find(
        (user) => user.id === userId
      );
      if (userToUpdate) {
        userToUpdate.role = newRole;
      }
    });
  }
  openRemoveUserDialog(userId: string) {
    const dialogRef = this.dialog.open(RemoveUserDialogComponent, {
      width: '250px',
      data: {
        userId: userId,
      },
    });
    dialogRef.componentInstance.userDeleted.subscribe(
      (deletedUserId: string) => {
        // Remove the deleted user from the data source
        this.dataSource.data = this.dataSource.data.filter(
          (user) => user.id !== deletedUserId
        );
      }
    );
  }

}
