import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LANGUAGES } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
    
    private currentLang = new BehaviorSubject<string>(localStorage.getItem('language'));
    currentLang$ = this.currentLang.asObservable();
    private translations: any = {};

  constructor(private http: HttpClient) {
    let savedLanguage = localStorage.getItem('language');
    if(!savedLanguage){
      savedLanguage = LANGUAGES.find((lang) => lang.default == true).code;
    }
    this.loadTranslations(savedLanguage); 
  }

  loadTranslations(lang: string): void {
    localStorage.setItem('language', lang);
    this.http.get(`/assets/translations/${lang}.json`).subscribe(
      translations => {
        this.translations[lang] = translations;
        this.currentLang.next(lang); 
      },
      error => {
        console.error(`Could not load translations for language: ${lang}`, error);
      }
    );
  }

  changeLanguage(lang: string): void {
    localStorage.setItem('language', lang);

    if (!this.translations[lang]) {
      this.loadTranslations(lang); 
    } else {
      this.currentLang.next(lang);
    }
  }

  translate(key: string, variables?: string[]): string {
    const languageTranslations = this.translations[this.currentLang.getValue()];
    let result = languageTranslations && languageTranslations[key] ? languageTranslations[key] : key;
    if(variables && variables.length > 0){
      variables.forEach(variable => {
        result.replace("%s", variable);
      });
    }
    return result;
  }

  getCurrentLanguage(): string {
    return this.currentLang.getValue();
  }
}
