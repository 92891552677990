import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'process-analysis-dialog-cache-alert-compliance',
  templateUrl: './process-analysis-dialog-cache-alert-compliance.component.html',
  styleUrls: ['./process-analysis-dialog-cache-alert-compliance.component.css']
})
export class ProcessAnalysisDialogCacheAlertComplianceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
