import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {NewAnalysisStepStatus} from 'src/app/models_ui/discover';
import {Analysis, RepositoryService} from '@tibco/discover-client-lib';
import { NewAnalysisSelectFolderComponent } from '../select-folder/select-folder.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FolderEntity } from 'src/app/routes/manage-process-analysis/manage-process-analysis.component';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit, OnChanges {

  @Input() name: string;
  @Input() description: string;
  @Input() folder: string;
  @Input() analysisId: string;
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter<NewAnalysisStepStatus>();
  @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(
    private repositoryMS: RepositoryService,
    private dialog: MatDialog,
    private translationService: TranslationService,

  ) {
  }

  nameChanged: Subject<any> = new Subject<any>();
  sameName = false;
  nameHint = '';
  isValidN = true;
  folders: any[] = [{
    "label": 'General',
    "value": 'General'
  }];
  newFolder: boolean;
  folderReady:boolean = false;
  initialFolder: string = '';
  hierarchicalFolders: FolderEntity[] = [{
    "name": "General",
    "path": "General",
    "children": []
  }];
  analysisList: any[] = [];
  dialogRef: MatDialogRef<NewAnalysisSelectFolderComponent, any>;
  breadcrumbs: string[];
placeholderName: string;
placeholderDescription: string;
  private analysisNames: string[] = [];

  ngOnInit(): void {



    this.translationService.currentLang$.subscribe(() => {   
    this.placeholderName = this.translationService.translate('basic.info.placeholder.name')
    this.placeholderDescription = this.translationService.translate('basic.info.placeholder.description')

    });
    this.nameChanged
      .pipe(debounceTime(0), distinctUntilChanged())
      .pipe(
        map((newValue: any) => {
          return this.analysisNames.includes(newValue.value);
        })
      ).subscribe((re: any) => {
      if (re) {
        this.sameName = true;
      } else {
        this.sameName = false;
      }
      this.updateStatus();
    });
    this.getAnalysies();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.analysisId) {
      this.getAnalysisNames();
    }
  }

  private getAnalysisNames() {
    this.repositoryMS.getAnalysises().subscribe((analysisList: Analysis[]) => {
      this.analysisNames = analysisList.filter(
        (el: Analysis) => el.id !== this.analysisId
      ).map(
        (el: Analysis) => el.name
      );
      this.updateStatus();
    })
  }

  private getAnalysies() {
    this.repositoryMS.getAnalysises().subscribe((analysisList: Analysis[]) => {
      this.analysisList = analysisList;
      if (this.analysisList.length > 0) {
        // Assign folder to cases
        for(let value of this.analysisList){
          let aName = value.name.split('||');
          let sName = aName.pop();
          if(aName.length > 0){
            value.folder = aName.join('||');
            value.name = sName;
          }else{
            value.folder = 'General';
          }
        }
        // Define folders array
        this.folders = [];
        for(let item of this.analysisList){
          if(!this.folders.includes(item.folder)){
            this.folders.push(item.folder);
          }
        }
        // Define hierarchical folder array
        this.hierarchicalFolders = hierarchicalFolders(this.folders, this.folder);
      }
      if(this.folder != undefined){
        this.initialFolder = this.folder;
      }else{
        this.folder = 'General';
      }
      this.breadcrumbs = this.getBreadcrumbs();
      
      this.folderReady = true;
    })
  }

  handleUpdate = (event, fieldName) => {
    const value = event.detail.value;
    // this.initialFolder = this.folder;
    this.changed.emit([fieldName, event.detail.value]);
    if (fieldName === 'name' && value && value.trim() !== '') {
      this.nameChanged.next({
        value
      });
      this.updateFolder(this.folder);
    } else {
      // Use a setTimeout here to fix the issue that when a description get's pasted the Next button does not get enabled
      // (this gives the input to this class the change to be set)
      setTimeout(() => {
        this.updateStatus();
      })
    }
  }

  updateFolder = (value) => {
    this.changed.emit(['folder', value]);
    // Use a setTimeout here to fix the issue that when a description get's pasted the Next button does not get enabled
    // (this gives the input to this class the change to be set)
    setTimeout(() => {
      this.updateStatus();
    })
  }

  private updateStatus() {
    this.isValidN = false
    if (this.sameName) {
      this.nameHint = 'A Process Analysis with this name already exists...';
    } else {
      if (this.name) {
        this.nameHint = '';
        this.isValidN = true
      } else {
        this.nameHint = 'Please provide a name...'
      }

    }
    const status = !this.sameName && !(this.name === undefined || this.name === '');
    const stepStatus = {
      step: 'basic-info',
      completed: status
    } as NewAnalysisStepStatus;
    // FIX for "Expression has changed after it was checked" (https://blog.angular-university.io/angular-debugging/)
    // Only send status update in the next JavaScript Cycle
    window.setTimeout(() => {
      this.status.emit(stepStatus);
    })
  }

  changeFolder(event){
    this.newFolder = event.detail.checked;
    if(event.detail.checked == false){
      this.folder = this.initialFolder;
    }else{
      this.folder = '';
    }
    let eventFolder = {
      detail: {
        value: this.folder
      }
    }
    this.handleUpdate(eventFolder, "folder");
  }

  openSelectFolderDialog() {
    this.hierarchicalFolders = hierarchicalFolders(this.folders, this.folder);
    this.dialogRef = this.dialog.open(NewAnalysisSelectFolderComponent, {
      width: '720px',
      height: 'auto',
      data: {
        hierarchicalFolders: this.hierarchicalFolders,
        currentFolder: this.folder,
        foldersList: this.folders,
        analysisList: this.analysisList
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.folder = result;
        this.breadcrumbs = this.getBreadcrumbs();
        if(!this.folders.includes(this.folder)){
          this.folders.push(this.folder);
        }
        this.updateFolder(this.folder);
      }
    });
  }

  getBreadcrumbs(){
    let aFolder:string[] = this.folder.split('||');
    if(aFolder[0] != 'General'){
      aFolder.unshift('General');
    }
    return aFolder;
  }

}
export function getArrayIndex(arr, name){
  for(let [i, el] of arr.entries()){
    if(el.name == name){
      return i;
    }
  }
  return -1;
}
export function hierarchicalFolders(folderArray, currentFolder){
  folderArray.sort();
  let hierarchicalFolders: FolderEntity[] = [{
    "name": "General",
    "path": "General",
    "children": []
  }];
  for(let folder of folderArray){
    let aFolder = folder.split("||");
    let sParent = '';
    let sChild = '';
    for(let [i, v] of aFolder.entries()){
      if(i == 0 && v != 'General'){
        if(getArrayIndex(hierarchicalFolders[0].children, v) == -1){
          hierarchicalFolders[0].children.push({
            "name": v,
            "path": aFolder[0],
            "selected": folder == currentFolder ? true: false,
            "children": []
          });
        }
      }else if(i == 1){
        sParent = aFolder[i - 1];
        let iCurrent = 0;
        let iParent = getArrayIndex(hierarchicalFolders[0].children, sParent);
        if(iParent != -1){
          iCurrent = getArrayIndex(hierarchicalFolders[0].children[iParent].children, v);
        }
        if(iCurrent == -1 && v != 'General'){
          hierarchicalFolders[0].children[iParent].children.push({
            "name": v,
            "path": aFolder[0]+'||'+aFolder[1],
            "children": []
          });
        }
        if(iParent == -1){
          hierarchicalFolders[0].children.push({
            "name": v,
            "path": aFolder[0]+'||'+aFolder[1],
            "children": []
          });
        }
      }else if(i == 2){
        sParent = aFolder[i - 2];
        sChild = aFolder[i - 1];
        let iCurrent = 0;
        let iParent = getArrayIndex(hierarchicalFolders[0].children, sParent);
        let iChild = getArrayIndex(hierarchicalFolders[0].children[iParent].children, sChild);
        if(iChild != -1){
          iCurrent = getArrayIndex(hierarchicalFolders[0].children[iParent].children[iChild].children, v);
        }
        if(iCurrent == -1){
          hierarchicalFolders[0].children[iParent].children[iChild].children.push({
            "name": v,
            "path": folder
          })
        }
        if(iChild == -1){
          hierarchicalFolders[0].children[iParent].children.push({
            "name": v,
            "path": folder
          })
        }
      }
    }
  }
  hierarchicalFolders[0].selected = "General" == currentFolder ? true: false;
  return hierarchicalFolders;
}
