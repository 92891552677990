<div id="icon-sidebar-wrap" *ngIf="iconSidebarData">
    <main>
        <ul *ngIf="iconSidebarData.main">
            <li *ngFor="let row of iconSidebarData.main">
                <button mat-button
                    [matTooltip]="row.label"
                    matTooltipClass="custom-tooltip"
                    [routerLink]="row.link"
                    routerLinkActive="mat-primary"
                    matTooltipPosition="right"
                    [routerLinkActiveOptions]="{exact: row.routerLinkActiveExact}">
                    <mat-icon class="material-symbols-rounded">{{row.icon}}</mat-icon>
                </button>
            </li>
        </ul>
    </main>
    <footer>
        <ul *ngIf="iconSidebarData.footer">
            <li *ngFor="let row of iconSidebarData.footer">
                <button mat-button
                    [matTooltip]="row.label"
                    matTooltipClass="custom-tooltip"
                    [routerLink]="row.link"
                    routerLinkActive="mat-primary"
                    matTooltipPosition="right"
                    [routerLinkActiveOptions]="{exact: row.routerLinkActiveExact}">
                    <mat-icon class="material-symbols-rounded">{{row.icon}}</mat-icon>
                </button>
            </li>
        </ul>
    </footer>
</div>