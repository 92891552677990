<div>
  <div class="info-row">
    <div class="label">{{"process.analysis.model.name"|translate}}</div>
    <div class="value">{{document.name}}</div>
  </div>
  <div class="info-row">
    <div class="label">{{"process.analysis.model.location"|translate}}</div>
    <div class="value">{{document.path}}</div>
  </div>
  <!-- <div class="info-row">
    <div class="label">No. of variants</div>
    <div class="value">4</div>
  </div>
  <div class="info-row">
    <div class="label">Attribute</div>
    <div class="value">Value</div>
  </div>
  <div class="info-row">
    <div class="label">Attribute</div>
    <div class="value">Value</div>
  </div> -->
</div>
