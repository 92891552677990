import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Report, ReportStatusEnum, ReportTypeEnum } from 'src/app/models_ui/analysis';
import { Languages } from 'src/app/models_ui/languages';
import { RepositoryService } from 'src/app/service/repository.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { LANGUAGES } from 'src/environments/environment';
import * as moment from 'moment';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Analysis } from '@tibco/discover-client-lib';
import { GetStatusReportResponse } from 'src/app/models_ui/repository';

export interface ReportElement {
  type: ReportTypeEnum;
  creation: string;
  status: ReportStatusEnum;
  download: boolean;
}

@Component({
  selector: 'process-analysis-report',
  templateUrl: './process-analysis-report.component.html',
  styleUrls: ['./process-analysis-report.component.scss']
})
export class ProcessAnalysisReportComponent implements OnInit {
  isReportReady: boolean = false;
  expansionReady: boolean = false;
  language: string;
  reports: Report[];
  languages: Languages[] = LANGUAGES;
  displayedColumns: string[] = ['type', 'creation', 'status', 'download'];
  dataSource: [ReportElement[]];
  private statusSubscription: { [key: string]: Subscription } = {};
  processName: string;
  activeReportLanguage = ["en_US", "it_IT", "es_ES"];

  constructor(
    public dialogRef: MatDialogRef<ProcessAnalysisReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private repositoryService: RepositoryService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    if(this.data){
      if(this.data.language){
        this.language = this.data.language;
      }
      if(this.data.analysis && this.data.analysis.id){
        this.processName = this.data.analysis.name.split('||').pop();
        this.refreshAnalysis();
      }
    }
    setTimeout(()=>{
      this.expansionReady = true;
    }, 0)
  }

  updateTable(){
    // Empty array
    this.dataSource = [[]];
    this.dataSource.splice(0, 1);
    // Popolate table
    for(let lang of this.languages){
      const langReport = this.reports.find(obj => obj.language == lang.code);
      if(langReport){
        this.dataSource.push([{
          type: ReportTypeEnum.PDF,
          creation: langReport.createdOn,
          status: langReport.status,
          download: langReport.s3PathPdf ? true : false
        },{
          type: ReportTypeEnum.DOCX,
          creation: langReport.createdOn,
          status: langReport.status,
          download: langReport.s3PathDocx ? true : false
        }]);
        if(langReport.status == ReportStatusEnum.CREATING){
          this.startGetStatus(this.data.analysis.id, lang.code);
        }
      }else{
        this.dataSource.push([{
          type: ReportTypeEnum.PDF,
          creation: null,
          status: ReportStatusEnum.UNKNOWN,
          download: false
        },{
          type: ReportTypeEnum.DOCX,
          creation: null,
          status: ReportStatusEnum.UNKNOWN,
          download: false
        }]);
      }
    }
    this.isReportReady = true;
  }

  popolateDatasource() {
    // Empty array
    this.dataSource = [[]];
    this.dataSource.splice(0, 1);
    // Popolate table
    for (let index = 0; index < this.languages.length; index++) {
      this.dataSource.push([{
        type: ReportTypeEnum.PDF,
        creation: null,
        status: ReportStatusEnum.UNKNOWN,
        download: false
      },{
        type: ReportTypeEnum.DOCX,
        creation: null,
        status: ReportStatusEnum.UNKNOWN,
        download: false
      }]);
    }
    this.isReportReady = true;
  }

  downloadReport(language: string, type: ReportTypeEnum){
    this.repositoryService.downloadReport(this.data.analysis.id, language, type).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.translationService.translate("report")+' '+moment().format('YYYY-MM-DD HH-mm-ss')+' - '+language+' - '+this.processName+'.'+type;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }, (error) => {
      console.error(error);
      this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.report.download.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
    });
  }

  generateReport(language: string){
    this.repositoryService.createReport(this.data.analysis.id, language).subscribe(
      () => {
        const langIndex = this.languages.findIndex(obj => obj.code == language);
        for(let doc of this.dataSource[langIndex]){
          doc.status = ReportStatusEnum.CREATING;
          doc.download = false;
        }
        // this.refreshAnalysis();
        this.startGetStatus(this.data.analysis.id, language);
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.report.generate.message.success"), this.translationService.translate("process.analysis.snackBar.close"),'success');
      }, (error) => {
        console.error(error);
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.report.generate.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
      }
    );
  }

  refreshAnalysis(){
    this.repositoryService.getAnalysis(this.data.analysis.id).subscribe((response: Analysis) => {
      if(Array.isArray(response.report) && response.report.length > 0){
        this.processName = this.data.analysis.name.split('||').pop();
        this.reports = response.report;
        this.updateTable();
      }else{
        this.popolateDatasource();
      }
    }, (error) => {
      console.error(error);
    });
  }

  startGetStatus(analysisId: string, language: string): void {
    if (this.statusSubscription[analysisId+language]) {
      return;
    }
    this.statusSubscription[analysisId+language] = interval(5000).pipe(
      switchMap(() => this.repositoryService.getReportStatus(analysisId, language))
    ).subscribe(
      (response: GetStatusReportResponse) => {
        if (response.status === 'Completed' || response.status === 'Failed') {
          this.stopGetStatus(analysisId, language);
        }
      }, (error) => {
        this.stopGetStatus(analysisId, language);
        console.error('getReportStatus error: ', error);
      }
    );
  }

  stopGetStatus(analysisId: string, language: string): void {
    if (this.statusSubscription[analysisId+language]) {
      this.statusSubscription[analysisId+language].unsubscribe();
      delete this.statusSubscription[analysisId+language];
      this.refreshAnalysis();
    }
  }

  close(){
    this.unsubescribeAll();
    this.dialogRef.close();
  }

  unsubescribeAll(){
    for(let key in this.statusSubscription){
      this.statusSubscription[key].unsubscribe();
    }
  }

}
