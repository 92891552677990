import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FolderEntity } from 'src/app/routes/manage-process-analysis/manage-process-analysis.component';
import { hierarchicalFolders } from '../basic-info/basic-info.component';

@Component({
  selector: 'select-folder',
  templateUrl: './select-folder.component.html',
  styleUrls: ['./select-folder.component.scss']
})

export class NewAnalysisSelectFolderComponent implements OnInit {
  // public onClose: 
  @Output() folderSelected: EventEmitter<any> = new EventEmitter();

  currentFolder: string;
  currentFolderArray: any[] = [{
    "name": "General",
    "path": "General"
  }];
  folders: any[] = [];
  hierarchicalFolders: FolderEntity[];
  analysisList: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewAnalysisSelectFolderComponent>,
    // private messageService: InternalMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // don't close dialog when the outside is clicked
    dialogRef.disableClose = true;
    console.log('data in new document dialog', data);
    this.hierarchicalFolders = data?.hierarchicalFolders;
    this.folders = data?.foldersList;
    this.currentFolder = data?.currentFolder;
    this.analysisList = data?.analysisList;
    setTimeout(()=>{ this.selectFolder(this.currentFolder); },100);
  }

  ngOnInit(): void {
  }

  public onCancel = (): void => {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public selectFolder = (folderPath): void => {
    let openFolder = document.querySelector('.process-analysis-folder-wrapper ul li.open');
    if(openFolder){
      openFolder.classList.remove('open');
    }
    let newFolder = document.querySelector('.process-analysis-folder-wrapper ul li.add-folder');
    if(newFolder){
      newFolder.classList.remove('add-folder');
    }
    let selectedFolder = document.querySelector('.process-analysis-folder-wrapper ul li a[data-folder-path="'+folderPath+'"]').closest('li');
    if(selectedFolder){
      selectedFolder.classList.add('open');
    }
    this.currentFolder = folderPath;
    localStorage.setItem('folderFocus',folderPath);
    this.updateCurrentFolderArray();
  }

  addFolder(){
    document.querySelector('.process-analysis-folder-wrapper ul li.li-folder.open').classList.add('add-folder');
    let newFolder = document.getElementById('new-folder-'+this.currentFolder);
    newFolder.scrollIntoView();
    newFolder.querySelector('input').focus();
  }

  createFolder(id){
    let newfolderName = document.getElementById(id).querySelector('input').value;
    if(newfolderName != ''){
      let folderPath = document.getElementById(id).querySelector('input').getAttribute('data-folder-path');
      this.currentFolder = folderPath == 'General'? newfolderName: folderPath+'||'+newfolderName;
      if(!this.folders.includes(this.currentFolder)){
        this.folders.push(this.currentFolder);
        localStorage.setItem('folderFocus',this.currentFolder)
      }else{
        document.getElementById(id).querySelector('input').classList.add('invalid');
        return;
      }
      this.hierarchicalFolders = hierarchicalFolders(this.folders, this.currentFolder);
      setTimeout(()=>{ this.selectFolder(this.currentFolder); },100);
    }else{
      document.getElementById(id).querySelector('input').classList.add('invalid');
    }
  }

  resetValidation(id){
    let target = document.getElementById(id).querySelector('input');
    if(target.classList.contains('invalid')){
      document.getElementById(id).querySelector('input').classList.add('invalid');
    }
  }

  updateCurrentFolderArray(){
    let aCurrentFolder = this.currentFolder.split('||');
    this.currentFolderArray = [{
      "name": "General",
      "path": "General"
    }]
    for(let [i, folder] of aCurrentFolder.entries()){
      let itemToAdd = undefined;
      if(i == 0 && folder !== 'General'){
        itemToAdd = {
          "name": folder,
          "path": folder
        };
      }else if(i == 1){
        itemToAdd = {
          "name": folder,
          "path": aCurrentFolder[i - 1]+'||'+folder
        };
      }else if(i == 2){
        itemToAdd = {
          "name": folder,
          "path": aCurrentFolder[i - 2]+'||'+aCurrentFolder[i - 1]+'||'+folder
        };
      }
      if(itemToAdd != undefined){
        this.currentFolderArray.push(itemToAdd);
      }
    }
  }

}
