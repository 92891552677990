import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import {CatalogService, Dataset } from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'app-remove-dataset-dialog',
  templateUrl: './remove-dataset-dialog.component.html',
  styleUrls: ['./remove-dataset-dialog.component.scss'],
})
export class RemoveDatasetDialogComponent {
  
  @Output() datasetDeleted = new EventEmitter<string>(); // Define event emitter
  roles: string[];
  selectedRole: string;
  datasetId: string;
  name: string;
  metadataVersion: any;
  confirmationInput: string = ''; // Variable to store user input
  datasetOnAction: Dataset = null;
  statusMap: { [key: string]: any } = {};
  
  @ViewChild('confirmationInputField') confirmationInputField: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<RemoveDatasetDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { datasetId: string; name:string;metadataVersion:any; },
    private catalogService: CatalogService,
    private translationService: TranslationService,

    private snackBarService: SnackbarService
  ) {
    this.datasetId = data.datasetId;
    this.name = data.name;
    this.metadataVersion = data.metadataVersion;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    if (this.datasetId) {
      const datasetId = this.datasetId;
      if (this.statusMap[datasetId]) {
        this.statusMap[datasetId].stop = true;
        this.statusMap[datasetId] = null;
      }
      this.catalogService.deleteDataset(datasetId, this.metadataVersion).subscribe(() => {
          this.snackBarService.openSnackBar(this.translationService.translate('remove.dataset.snackBar.deleted'), this.translationService.translate('remove.dataset.snackBar.close'),'success');
            this.datasetDeleted.emit(this.datasetId)
      }, error => {
        if (error.status === 409) {
          const messagePart1 = this.translationService.translate('remove.dataset.snackBar.mess1');
          const messagePart2 = this.translationService.translate('remove.dataset.snackBar.mess2');
          
          // Costruisci il messaggio completo
          const fullMessage = `${messagePart1}${this.name}${messagePart2}`;
          this.snackBarService.openSnackBar(
            fullMessage,
            this.translationService.translate('remove.dataset.snackBar.close'),'warn')
        } else {
          this.snackBarService.openSnackBar(
            this.translationService.translate('remove.dataset.snackBar.error'),
            this.translationService.translate('remove.dataset.snackBar.close'),
            'error'
          );
        }
      });
      this.dialogRef.close();
    }

  }



  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.confirmationInput === 'DELETE') {
        this.onDeleteClick();
      }
    }
  }
}
