<div class="dataset-data-preview">
  <div *ngIf="previewData && previewData.length > 0 ; else loadingData" class="dataset-data-preview-wrapper">
    <div class="parse-preview-container">
        <div>Data Preview</div>
        <div *ngIf="previewColumns?.length > 0" class="dataset-preview-table tibco-table-ux">
          <p-table #dataPreviewTab [columns]="previewColumns" [value]="previewData" [scrollable]="true" scrollHeight="flex"
            dataKey="id" id="pTable" styleClass="custom-table-css">
            <ng-template pTemplate="header" let-columns>
              <tr style="background-color: #fff;">
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="tibco-top-row top-row">
                  <div class="preview-column-header">
                    <span [ngClass]="{'invalid-col': col.highlight}">{{col.headerName}}</span>
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-expanded="expanded" let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" class="data-row">
                  <div>{{rowData[col.field]}}</div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
    </div>
    <div style="margin-top: 12px;">Previewing the first  {{wizard.numberRowsForPreview}} rows</div>
  </div>

  <ng-template #loadingData>
    <div *ngIf="loading" class="loading-indicator">
      <pulse-loading></pulse-loading>
      <!-- <uxpl-spinner style="height: 150px; min-width: 150px; max-width: 150px;" appearance="light"
                    message="Loading data..."></uxpl-spinner> -->
    </div>
    <div *ngIf="!loading && previewData?.length <= 0" class="no-preview">
      <div>
        <img src="assets/images/svg/image-no-preview.svg" style="max-width: 300px; padding: 50px 50px 30px;"/>
        <div class="desc">No preview data available</div>
      </div>
    </div>
  </ng-template>


</div>
