import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateUser, EditOrgRequest, ReleaseNote, User } from '../models_ui/users';
import { endpoints } from '../../environments/endpoints';
import { map } from 'rxjs/operators';
import { Organization } from '../models_ui/auth';
import { CreateOrg } from '../routes/create-organization/create-org.component';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.http
      .get<User[]>(endpoints.users.users, {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res.body;
        })
      );
  }

  getUser(id: string) {
    return this.http.get<User>(endpoints.users.users + '/' + id, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  createUser(userData: CreateUser): Observable<any> {
    return this.http.post<CreateUser>(endpoints.users.users, userData, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    });
  }

  editUser(userId: string, role: string): Observable<any> {
    return this.http.patch<Role>(
      `${endpoints.users.users}/${userId}`,
      { role },
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }
    
  editOrg(orgId:string, body: Organization): Observable<any> {
    return this.http.patch<EditOrgRequest>(
      `${endpoints.users.organizations}/${orgId}`,
      body,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  deleteUser(userId: string): Observable<any> {
    return this.http.delete<User>(`${endpoints.users.users}/${userId}`, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    });
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(endpoints.users.roles, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    });
  }

  getOrg(orgId: string): Observable<Organization> {
    return this.http.get<Organization>(endpoints.users.organizations+'/'+orgId, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getOrgs(): Observable<Organization[]> {
    return this.http.get<Organization[]>(endpoints.users.organizations, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getUsersByOrgId(orgID: string): Observable<User[]> {
    const orgIdFormatted = orgID.replace(/-/g, '');
    return this.http.get<User[]>(endpoints.users.organizations + `/${orgIdFormatted}/users`, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  createUserByOrgId(orgID: string, usersData: CreateUser): Observable<User[]> {
    const orgIdFormatted = orgID.replace(/-/g, '');
    return this.http.post<User[]>(
      endpoints.users.organizations + `/${orgIdFormatted}/users`,
      usersData,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    ).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  createOrg(orgData: CreateOrg): Observable<any> {
    return this.http.post<CreateUser>(endpoints.users.organizations, orgData, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    });
  }

  editUserByOrgId(
    userId: string,
    orgId: string,
    role: string | Role
  ): Observable<any> {
    return this.http.patch<Role>(
      `${endpoints.users.organizations}/${orgId}/users/${userId}`,
      { role },
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  deleteUserByOrgId(userId: string, orgId: string): Observable<any> {
    return this.http.delete<User>(
      `${endpoints.users.organizations}/${orgId}/users/${userId}`,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  toggleSuperadmin(userId: string): Observable<any> {
    return this.http.patch<Role>(
      `${endpoints.users.users}/superadmin/${userId}`,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  getDXPDocument(orgId: string): Observable<any> {
    const url = `${endpoints.users.dxp}/${orgId}`;
    return this.http.get(url, {
      responseType: 'blob', // Set the response type to blob
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      withCredentials: true,
    });
  }
  
  getOrgsStatics(orgId: string): Observable<any>{
    const url = `${endpoints.users.organizations}/statistics/${orgId}`;
    return this.http.get<any>(url, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getStatistics(): Observable<any>{
    const url = `${endpoints.users.statistics}`;
    return this.http.get<any>(url, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getAdminStatistics(orgId: string): Observable<any>{
    const url = `${endpoints.users.adminStatistics}/${orgId}`;
    return this.http.get<any>(url, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getLastReleaseNote(): Observable<ReleaseNote>{
    const url = `${endpoints.users.releaseNotes}/last`;
    return this.http.get<ReleaseNote>(url, {
      observe: 'response',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      withCredentials: true,
    }).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

}

export interface Roles {
  roles: string[];
}
export interface Role {
  role: string;
}
