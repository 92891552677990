import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  transform(value: Date | moment.Moment, locale: string = 'en_US'): string {
    moment.locale(locale);
    return moment(value).fromNow();
  }
}
