<h2 mat-dialog-title>
  <span>{{'release.notes.title' | translate}}</span>
  <span id="version" class="badge secondary-bg" *ngIf="!isLoading && ready">v {{releaseNotes.version}}</span>
</h2>
<i class="date">{{releaseDate | date: 'longDate': '':language}}</i>
<mat-dialog-content>
  <div id="release-notes">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    <div id="description" *ngIf="!isLoading && ready" [innerHTML]="safeHtmlContent"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'close' | translate}}</button>
</mat-dialog-actions>
