import {Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {VisualisationService} from '@tibco/discover-client-lib';
import {copyToClipBoard} from '../../../functions/details';
import {DatePipe} from '@angular/common';
import {AttributeDef} from '@tibco/tc-web-components/dist/types/models/attributeDef';
import {ChartService, SummaryConfig} from '../../../service/chart.service';
import {DateTime} from 'luxon';
import {RelationshipService} from '../../../service/relationship.service';
import {Router} from '@angular/router';
import { Analysis, Dataset } from '@tibco/discover-client-lib'
import {InternalMessageService} from '../../../service/internal-message.service';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'process-analysis-details',
  templateUrl: './process-analysis-details.component.html',
  styleUrls: ['./process-analysis-details.component.css']
})
export class ProcessAnalysisDetailsComponent implements OnInit, OnChanges {

  constructor(
    private visualisationMS: VisualisationService,
    public msService: InternalMessageService,
    private datePipe: DatePipe,
    private chartService: ChartService,
    private relationshipS: RelationshipService,
    private router: Router,
    private translationService: TranslationService,
  ) {
  }
  @Input() processAnalysis: Analysis;

  @Input() progress: any;

  @Output() doCompare: EventEmitter<Analysis> = new EventEmitter<Analysis>();

  currentLanguage: string;
  templateName: string;
  showError = false;
  moreInfo = false;

  activitiesChartConfig: any;
  durationChartConfig: any;
  summaryConfig: SummaryConfig;

  paId: string;
  chartData: any;
  display = false;

  dataset: Dataset;

  keymetricsLabel:string;
  quickLabel:string;
  eventsLabel:string;
  casesLabel:string;
  variantsLabel:string;
  activitiesLabel:string;
  resourcesLabel:string;
  timespanLabel:string;
  activitiesCaseLabel:string;
  caseDurationLabel:string;


  ngOnInit() {
    this.translationService.currentLang$.subscribe(() => {
      this.currentLanguage = this.translationService.getCurrentLanguage();
      // this.setDetails()
    });
    this.updateLabel()
    // If template label is not part of the response
    if (this.processAnalysis.template?.id && !this.processAnalysis.template?.name ) {
      this.visualisationMS.getTemplate(this.processAnalysis.template.id).subscribe(
        result => {
          this.processAnalysis.template.name = result.name;
        }
      )
    }
    if (!this.processAnalysis.template?.id) {
      this.processAnalysis.template = {
        id: '',
        name: 'Not assigned'
      }
    }
  }

  updateLabel(){
    this.keymetricsLabel = this.translationService.translate('process.analysis.details.widget.label.keyMetrics');
    this.quickLabel = this.translationService.translate('process.analysis.details.widget.quick');
    this.eventsLabel = this.translationService.translate('process.analysis.details.widget.events');
    this.casesLabel = this.translationService.translate('process.analysis.details.widget.cases');
    this.variantsLabel = this.translationService.translate('process.analysis.details.widget.variants');
    this.activitiesLabel = this.translationService.translate('process.analysis.details.widget.activities');
    this.resourcesLabel = this.translationService.translate('process.analysis.details.widget.resources');
    this.timespanLabel = this.translationService.translate('process.analysis.details.widget.timespan');
    this.activitiesCaseLabel = this.translationService.translate('process.analysis.details.widget.activitiesCase');
    this.caseDurationLabel = this.translationService.translate('process.analysis.details.widget.caseDuration');

  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.processAnalysis && this.processAnalysis.id) {
      const idT = this.processAnalysis.id;
      this.paId = idT;
      // this.paVersion = getVersionFromProcessAnalysisID(idT);
      this.relationshipS.getDatasetForProcessAnalysisId(idT).then( ds => {
        this.dataset = ds;
      })
      if (!this.processAnalysis.template?.id) {
        this.processAnalysis.template = {
          id: '',
          name: 'Not assigned'
        }
      }
      if (this.processAnalysis?.metrics) {
        this.chartData = this.chartService.buildChartConfig(this.processAnalysis.metrics);
        this.summaryConfig = this.chartData.summaryConfig;
        this.activitiesChartConfig = this.chartData.activitiesChartConfig;
        this.durationChartConfig = this.chartData.durationChartConfig;
      }
    }
  }


  get attributeDefs(): AttributeDef[] {
    if (this.processAnalysis) {
      let dsN = this.dataset ? this.dataset.name : '';
  
      const defs: AttributeDef[] = [
        { label: this.translationService.translate('process.analysis.details.widget.label.name'), value: this.processAnalysis.name },
        { label: this.translationService.translate('process.analysis.details.widget.label.description'), value: this.processAnalysis.description },
        { label: this.translationService.translate('process.analysis.details.widget.label.createdBy'), value: this.processAnalysis.metadata.createdBy },
        { label: this.translationService.translate('process.analysis.details.widget.label.createdOn'), value: this.datePipe.transform(this.processAnalysis.metadata.createdOn, 'short', '', this.currentLanguage) },
        { label: this.translationService.translate('process.analysis.details.widget.label.template'), value: this.processAnalysis.template?.name, actionable: true },
        { label: this.translationService.translate('process.analysis.details.widget.label.id'), value: this.paId, copyable: true },
        { label: this.translationService.translate('process.analysis.details.widget.label.dataset'), value: dsN, actionable: true },
      ];
  
      if (this.processAnalysis.metadata.archivedOn) {
        defs.push({ label: this.translationService.translate('process.analysis.details.widget.label.archived.on'), value: this.datePipe.transform(this.processAnalysis.metadata.archivedOn, 'short', '', this.currentLanguage) });
      }
  
      if (this.processAnalysis.metadata.deletionOn) {
        defs.push({ label: this.translationService.translate('process.analysis.details.widget.label.deletion.on'), value: this.datePipe.transform(this.processAnalysis.metadata.deletionOn, 'short', '', this.currentLanguage) });
      }
  
      return defs;
    } else {
      return [];
    }
  }
  

  get label(): string {
    return this.translationService.translate('process.analysis.details.widget.label.additionalInfo');
  }

  get timespan(): string {
    const start: DateTime = DateTime.fromISO(this.processAnalysis.metrics.minTimestamp);
    const end: DateTime = DateTime.fromISO(this.processAnalysis.metrics.maxTimestamp);
    if (start.isValid && end.isValid) {
      return start.toLocaleString() + ' -> ' + end.toLocaleString();
    } else {
      return 'invalid start/end timestamps';
    }
  }

  handleMoreInfoToggle() {
    this.moreInfo = !this.moreInfo;
  }

  handleCompareClick() {
    if (this.processAnalysis?.metrics) {
      console.log(this.processAnalysis);
      this.doCompare.emit(this.processAnalysis);
    }
  }

  handleDetailAttributeCopy(attribute: AttributeDef) {
    copyToClipBoard(attribute.label, attribute.value, this.msService)
  }

  handleDetailAction(ev: any) {
    if(ev?.detail && ev.detail.label === 'Dataset') {
      this.router.navigate(['/discover/dataset'], {queryParams: {focus: this.dataset.id}});
    }
    if(ev?.detail && ev.detail.label === 'Template') {
      // this.router.navigate(['/discover/dataset'], {queryParams: {focus: this.dataset.Dataset_Id}});
      this.router.navigate(['/discover/select-template', this.processAnalysis.id]);
    }
  }
}
