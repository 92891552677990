import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { BrowserUtils } from "@azure/msal-browser";
import { CORE_PROVIDERS, CORE_ROUTES } from './route-config/core-route-config';

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(CORE_ROUTES, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule],
  providers: CORE_PROVIDERS
})

export class AppRoutingModule { }
