import { ManageProcessAnalysisComponent } from '../routes/manage-process-analysis/manage-process-analysis.component';
import { AnalyticsComponent } from '../routes/analytics/analytics.component';
import { ManageInvestigationsComponent } from '../routes/manage-investigations/manage-investigations.component';
import { ADMIN_ROUTE_CONFIG, ADMIN_PROVIDERS } from './admin-route-config';
import { AccessGuard } from '../guards/access.guard';
import { ManageDatasetsComponent } from '../routes/manage-datasets/manage-datasets.component';
import { NewAnalysisComponent } from '../routes/new-analysis/new-analysis.component';
import { ManageTemplatesComponent } from '../routes/manage-templates/manage-templates.component';
import { TemplateSelectComponent } from '../routes/template-select/template-select.component';
import { AdminComponent } from '../routes/admin/admin.component';
import { TemplateEditorComponent } from '../routes/template-editor/template-editor.component';
import { InvestigationsDetailsComponent } from '../routes/investigations-details/investigations-details.component';
import { ManageConnectionsComponent } from '../routes/manage-connections/manage-connections.component';
import { MsalGuard } from '@azure/msal-angular';
import { ControlPanelComponent } from '../routes/control-panel/control-panel.component';
import { ControlPanelUsersComponent } from '../routes/control-panel-users/control-panel-users.component';
import { ControlPanelAddUserComponent } from '../routes/control-panel-add-user/control-panel-add-user.component';
export const DISCOVER_ROUTE_CONFIG = [
  {
    path: 'process-analysis',
    component: ManageProcessAnalysisComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'new-analysis',
    component: NewAnalysisComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'edit-analysis/:id',
    component: NewAnalysisComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'templates',
    component: ManageTemplatesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'select-template/:name',
    component: TemplateSelectComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'new-template',
    component: TemplateEditorComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'edit-template/:name',
    component: TemplateEditorComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AccessGuard],
  },
  {
    path: 'analytics/:id',
    component: AnalyticsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'investigations',
    component: ManageInvestigationsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'investigation-details/:applicationId/:investigationId',
    component: InvestigationsDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'dataset',
    component: ManageDatasetsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'connections',
    component: ManageConnectionsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [MsalGuard],
    children: ADMIN_ROUTE_CONFIG,
  },
  {
    path: 'control-panel',
    component: ControlPanelComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'control-panel/users',
    component: ControlPanelUsersComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'control-panel/add-user',
    component: ControlPanelAddUserComponent,
    canActivate: [MsalGuard],
  }
];

export const DISCOVER_PROVIDERS = [ADMIN_PROVIDERS];
