<div class="page-container container-flex-fluid">
    <div class="flex">
        <div class="flex-item">
            <div class="data-title-container">
                <div class="data-title">
                    <div class="page-title">{{ 'control.panel.add.user.title' | translate }}</div>
                </div>
                <div class="data-title">
                    <div class="data-title">
                        <button mat-stroked-button color="primary" (click)="goBack()">
                            <mat-icon class="material-symbols-rounded">chevron_left</mat-icon>
                            {{ 'go.back' | translate }}
                        </button>
                        <button mat-flat-button color="primary" [disabled]="userForm.invalid" type="submit" (click)="onSubmit()">
                            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                            {{ 'user.manager.button.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-item">
            <div id="icon-sidebar-container" class="flex">
                <div id="icon-sidebar-side" class="flex-item">
                    <icon-sidebar [iconSidebarData]="iconSidebarData"></icon-sidebar>
                </div>
                <div id="icon-sidebar-content" class="flex-item">
                    <div class="flex">
                        <div class="flex-item width-xs-10 width-sm-7 width-md-5 width-lg-4 width-xl-3">
                            <mat-card id="add-user-card">
                                <mat-progress-bar *ngIf="creatingUser" mode="indeterminate"></mat-progress-bar>
                                <mat-card-title>
                                    {{'create.user.superadmin.title' | translate}}
                                </mat-card-title>
                                <mat-card-content>
                                    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                                        <div class="container-flex-fluid">
                                            <div class="flex">
                                                <div class="flex-item width-xs-10">
                                                    <mat-form-field [style.width.%]="100" appearance="fill">
                                                        <mat-label>{{"form.field.first.name" | translate}}</mat-label>
                                                        <input matInput formControlName="firstName"/>
                                                        <mat-error *ngIf="userForm.get('firstName').invalid && userForm.get('firstName').touched">{{'generic.message.form.field.is.required' | translate}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="flex-item width-xs-10">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>{{"form.field.last.name" | translate}}</mat-label>
                                                        <input matInput formControlName="lastName"/>
                                                        <mat-error *ngIf="userForm.get('lastName').invalid && userForm.get('lastName').touched">{{'generic.message.form.field.is.required' | translate}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="flex-item width-xs-10">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>{{"form.field.email" | translate}}</mat-label>
                                                        <input matInput formControlName="email"/>
                                                        <mat-error *ngIf="userForm.get('email').invalid && userForm.get('email').touched">{{'generic.message.form.field.email.validation.error' | translate}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="flex-item width-xs-10">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>{{"form.field.role" | translate}}</mat-label>
                                                        <mat-select formControlName="role">
                                                        <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="userForm.get('role').invalid && userForm.get('role').touched">{{'generic.message.form.field.is.required' | translate}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="flex-item width-xs-10">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>{{"form.field.language" | translate}}</mat-label>
                                                        <mat-select formControlName="language">
                                                        <mat-option *ngFor="let lang of languages" [value]="lang.code">
                                                            {{ lang.name }}
                                                        </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="userForm.get('language').invalid && userForm.get('language').touched">{{'generic.message.form.field.is.required' | translate}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>