<div>
    <div class="basic-info-container">
        <uxpl-form-field id="#nimbusDocName" fieldId="nimbusDocName" style="margin-top: 5px; margin-bottom: 5px;" [label]="'process.analysis.model.name' | translate" type="text" required="true"
            [value]="document.name" (uxplChanged)="handleUpdate($event, 'name')" [valid]="!nameError"></uxpl-form-field>
        <div *ngIf="nameError" class="error">{{ "process.analysis.model.name.same" | translate}}</div>
        <div class='pl-form-field-container doc-location'>
            <div class="title">{{ "process.analysis.model.location" | translate}}</div>
            <nimbus-doc-location [document]="document" [config]="documentLocationConfig" [listHeight]="190" (locationSelected)="locationSelected($event)"></nimbus-doc-location>
        </div>
    </div>
</div>
