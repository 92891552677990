import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@tibco/discover-client-lib';
import { OauthService } from 'src/app/service/oauth.service';
import { environment } from 'src/environments/environment';
import {fetchWithAuthentication} from '../../../functions/common';

@Component({
  templateUrl: './hightligh-edit.component.html',
  styleUrls: ['./hightligh-edit.component.css']
})
export class HightlighEditComponent implements OnInit {

  public highlight;
  public iconURL: string;

  constructor(
    private configurationMS: ConfigurationService,
    private oauthService: OauthService,
    public dialogRef: MatDialogRef<HightlighEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  async ngOnInit(): Promise<void> {
    this.highlight = this.data;
    this.iconURL = await fetchWithAuthentication(environment.apiURL + '/configuration/resource/' + this.highlight.iconURL, this.oauthService.token);
  }

  public onNoClick = (): void => {
    this.dialogRef.close();
  }

  public handleUpdate = ($event, field: string): void => {
    this.highlight[field] = $event.detail.value;
  }

  public onCloseClick = (): void => {
    this.dialogRef.close(this.highlight);
  }

  public handleUpload = (file: File): void => {
    this.configurationMS.uploadImage(file).subscribe(
      (response: any) => {
        console.log('************ response: ', response);
        this.highlight.iconURL = file.name;
        this.iconURL = file.name;
      },
      error => {
        console.log('error', error);
      }
    );
  }
}
