import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import guessFormat from 'moment-guess';
import { DateParseRecord, DateParsingResult } from '../models_ui/parsing';
import { ConfigurationService } from './configuration.service';



@Injectable({
  providedIn: 'root'
})
export class ParsingService {

  constructor(protected config: ConfigurationService) {
  }

  public readonly supportedEncoding = [
    'ISO-8859-1',
    'ISO-8859-2',
    'ISO-8859-3',
    'ISO-8859-5',
    'ISO-8859-15',
    'US-ASCII',
    'UTF-8',
    'UTF-16',
    'windows-1252'
  ];

  public readonly encodingNameForJavaIO = [
    'ISO8859_1',
    'ISO8859_2',
    'ISO8859_3',
    'ISO8859_5',
    'ISO8859_15',
    'ASCII',
    'UTF8',
    'UTF-16',
    'Cp1252'
  ];

  public validateSingleDate = (value: string, pattern: string): boolean => {
      return DateTime.fromFormat(value, pattern).isValid;
  }

  /**
   * The moment-guess might give multiple possibilities.
   * @param dateString Return null the string cannot be parsed as any date format
   */
  public guessDateFormat(dateString: string): string[] {
    try {
      const f = guessFormat(dateString);
      if (Array.isArray(f)) {
        return f;
      } else {
        return [f];
      }
    } catch (error) {
      // moment-guess throw error if the string can not be parsed as date
    }
    return null;
  }

  private getGuessDates(dateStrings: string[]): string[] {
    const formatMap: {[key: string]: boolean} = {};
    const addMap = (f) => {
      if (!formatMap[f]) {
        formatMap[f] = true;
      }
    }
    for (const dateString of dateStrings) {
      const f = this.guessDateFormat(dateString);
      if (f) {
        f.forEach(ele => {
          addMap(ele);
        })
      }
    }
    let formats = Object.keys(formatMap);
    formats = formats.map(f => this.convertToLuxonDateFormat(f));
    return formats;
  }

  /**
   * Now the convert the following tokens
   * DD -> dd
   * YYYY -> yyyy
   * A -> a
   */
  private convertToLuxonDateFormat(dateFormat) {
    return dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y').replace(/A/g, 'a');
  }

  public predictDateFormat(dateStrings: string[], previousResult?: DateParsingResult): DateParsingResult {
    if (!previousResult) {
      previousResult = new DateParsingResult();
      previousResult.formats = [];
    }

    const dateFormats = this.getGuessDates(dateStrings);
    dateFormats.forEach((formatStr: string) => {
      let formatRec: DateParseRecord;
      if (previousResult) {
        // use existing
        formatRec = previousResult.formats.find(res => { return res.format === formatStr})
      }
      if (!formatRec) {
        // initialize
        formatRec = { format: formatStr, matches: 0, badRows: [], badColumns: [], rowMatches: []};
      }
      // let idx: number = formatRec.badRows.length + 1;
      dateStrings.forEach((dateStr: string, idx) => {
        let badRow = false;
        let colIdx = 0;
        // row.forEach(dateStr => {
          let isValid: boolean;
          if (dateStr && dateStr.length > 0) {
            const aDate = DateTime.fromFormat(dateStr, formatStr);
            isValid = aDate.isValid;
          } else {
            // empty date field
            isValid = false;
          }
          if (!isValid) {
            badRow = true;
            if (!formatRec.badColumns.includes(colIdx)) {
              formatRec.badColumns.push(colIdx);
            }
          }
          colIdx++;
        // })
        if (!badRow) {
          formatRec.matches++;
          formatRec.rowMatches[idx] = true;
        } else {
          formatRec.badRows.push(idx);
          formatRec.rowMatches[idx] = false;
        }
        // idx++;
      })
      // before adding the parse result, need to do some calculation
      let formatNeeded = false;

      if (formatRec.matches === dateStrings.length) {
        // if the all the rows matches the format, no doubt, the format need to return
        // practically now the parsing and row matching can abort, but a rare case is
        // multiple formats match all the rows.
        formatNeeded = true;
      } else {
        // now we need to check if a adding format covers this format.
        if (previousResult.formats.length === 0) {
          formatNeeded = true;
        } else {
          const cr = formatRec.rowMatches;
          let covered = false;
          for (let i = 0; i < previousResult.formats.length; i++) {
            const pr = previousResult.formats[i].rowMatches;
            let unic = false;
            let unip = false;
            for (let j = 0; j < pr.length; j++) {
              if (!pr[j] && cr[j]) {
                unic = true;
              } else if (pr[j] && !cr[j]) {
                unip = true;
              }
              if (unic && unip) {
                break;
              }
            }
            if (!unic && unip) {
              covered = true;
              break;
            }

            if (unic && !unip) {
              // the added one is covered by the new one
              previousResult.formats.splice(i, 1);
              i--;
            }
          }

          if (!covered) {
            formatNeeded = true;
          }
        }
      }

      if (formatNeeded) {
        previousResult.formats.push(formatRec);
      }

    })
    return previousResult;
  }

  public getSupportEncoding(): string[] {
    return this.supportedEncoding;
  }

  public getJavaIOEncoding(encoding: string) {
    const index = this.supportedEncoding.indexOf(encoding);
    if (index != -1) {
      return this.encodingNameForJavaIO[index];
    }
    return encoding;
  }


}
