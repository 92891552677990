<h2 mat-dialog-title>
    <span>{{'process.analysis.report.dialog.title' | translate}} - {{processName}}</span>
</h2>
<mat-dialog-content>
    <mat-accordion *ngIf="expansionReady && isReportReady">
        <ng-container *ngFor="let lang of languages; let i = index">
            <mat-expansion-panel expanded="false" [disabled]="!activeReportLanguage.includes(lang.code)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="report-lang-title">
                            <div class="icon-wrap">
                                <img [src]="'../../../../assets/png/flags/'+lang.code+'.png'"/>
                            </div>
                            {{lang.name}}
                        </div>
                        <div class="report-unavailable-msg">
                            <span class="badge" *ngIf="!activeReportLanguage.includes(lang.code)">{{'process.analysis.report.dialog.unavailable.language' | translate}}</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="report-table-wrap">
                    <table mat-table [dataSource]="dataSource[i]">
                        <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>{{'type' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                        </ng-container>
                        <ng-container matColumnDef="creation">
                            <th mat-header-cell *matHeaderCellDef>{{'process.analysis.report.dialog.creationOn' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.creation">{{element.creation | date: 'longDate': '':language}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>{{'status' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon *ngIf="element.status == 'Completed'" class="success-color" matTooltipClass="custom-tooltip" [matTooltip]="'process.analysis.report.tooltip.generation.completed' | translate">check_circle</mat-icon>
                                <mat-spinner *ngIf="element.status == 'Creating'" color="primary" diameter="20"></mat-spinner>
                                <mat-icon *ngIf="element.status == 'Unknown'" style="color: lightgray;">circle</mat-icon>
                                <mat-icon *ngIf="element.status == 'Failed'" class="error-color" matTooltipClass="custom-tooltip" [matTooltip]="'process.analysis.report.tooltip.generation.unknow' | translate">cancel</mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="download">
                            <th mat-header-cell *matHeaderCellDef>{{'download' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-flat-button color="primary" class="small-button" [disabled]="!element.download" (click)="downloadReport(lang.code, element.type)">
                                    <mat-icon>download</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <div class="report-action-buttons">
                    <button mat-flat-button color="accent" *ngIf="dataSource[i][0].status == 'Completed' || dataSource[i][0].status == 'Failed' || dataSource[i][0].status == 'Creating'" (click)="generateReport(lang.code)">{{'process.analysis.report.tooltip.regenerate' | translate}}</button>
                    <button mat-flat-button color="primary" *ngIf="!dataSource[i][0] || !dataSource[i][0].status || dataSource[i][0].status == 'Unknown'" (click)="generateReport(lang.code)">{{'process.analysis.report.tooltip.generate' | translate}}</button>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">{{'close' | translate}}</button>
</mat-dialog-actions>