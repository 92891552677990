<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Dictionary</h1>
<div mat-dialog-content class="dialog-content">
  <mat-accordion *ngIf="data != ''">
    <mat-expansion-panel *ngFor="let d of data" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>{{d.title}}</mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{d.description}}</p>
    </mat-expansion-panel>
  </mat-accordion>
  <p *ngIf="data == ''">There are not data to display</p>
</div>
<div>
  <div mat-dialog-actions>
    <uxpl-button type="secondary" (clicked)="closeDialog()">Close</uxpl-button>
  </div>
</div>