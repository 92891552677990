import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StepStatus, TemmplateAnalyticDetails, TemplateAnalyticChoice} from 'src/app/models_ui/analyticTemplate';
import {stripOrgFolder} from '../../../functions/analysis';
import {ConfigurationService, LibraryItem, VisualisationServer, VisualisationService} from '@tibco/discover-client-lib';
import {TreeNode} from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'template-analytics',
  templateUrl: './template-analytics.component.html',
  styleUrls: ['./template-analytics.component.scss']
})
export class TemplateAnalyticsComponent implements OnInit {

  constructor(
    private visualisationMS: VisualisationService,
    private configurationMS: ConfigurationService
  ) {
  }

  // The initial location of the DXP (To be able to reset making a copy from)
  @Input() initialLocation: string;
  // Location of the DXP of the Template (that exists), so we can link to it
  @Input() existingDXPLocation: string;
  // Location of the (new) DXP in the Template that we need to copy to (so does not exist yet)
  @Input() newDXPLocation: string;
  // Indicator if this is a new or an existing template (update or create)
  @Input() isNewTemplate: boolean;
  // What mode are we using for the new DXP (Copy, Using Same, or Select Existing one)
  @Input() analyticsChoice: TemplateAnalyticChoice;
  // Indicator if the advanced tab should be shown
  @Input() doAdvancedTab: boolean;

  // Throw event if the advanced button is toggled
  @Output() doAdvancedE: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Throw event when analytics choice is changed
  @Output() updateAnalytics: EventEmitter<TemmplateAnalyticDetails> = new EventEmitter<TemmplateAnalyticDetails>();
  // Throw event if we can move to the next step
  @Output() status: EventEmitter<StepStatus> = new EventEmitter<StepStatus>();

  // Function to remvove the Org Folder for a string
  stripOrgF = stripOrgFolder;
  // Error message on new name of DXP
  nameHint: string;
  // Indicator if we need to link to an (existing dxp)
  showSFLink = false;
  // Configuration (with the User Defined path for the new dxp's)
  vConfiguration: VisualisationServer;
  // Indiator if the new name for a dxp is valid
  copyDxpNameValid = true
  // The tree with exisiting dxp's
  dxpTree: TreeNode[] = [];

  private currentCheckedTreeNode: TreeNode;

  // Function called when opening this page; get's the latest config and fills the initial tree
  async ngOnInit() {
    if (!this.newDXPLocation && this.analyticsChoice === 'CUSTOM') {
      this.existingDXPLocation = this.initialLocation;
    }
    this.visualisationMS.getVisualisationConfiguration().subscribe(async (vConf) => {
      this.vConfiguration = vConf
      this.processChoice()
    });
    this.configurationMS.getWhoAmI().subscribe(async (whoAmI) => {
      const mainFConent = await this.getSFFolderContent('/Teams/' + whoAmI.subscriptionId)
      mainFConent.forEach(node => {
        this.addLibraryItemToTree(node, true)
      })
      if (!this.isNewTemplate) {
        // When we are in edit mode, expand the tree to the currently selected dxp
        let existingPath = this.existingDXPLocation
        if (existingPath.startsWith('/')) {
          existingPath = existingPath.substring(1)
        }
        const existingPathElements = existingPath.split('/');
        let belowTeams = false
        let currentPath = '/'
        for (let paI = 0; paI < existingPathElements.length; paI++) {
          const lastElement = paI === existingPathElements.length - 1
          if (lastElement) {
            currentPath += existingPathElements[paI]
          } else {
            currentPath += existingPathElements[paI] + '/'
          }
          if (belowTeams) {
            if (!lastElement) {
              const content = await this.getSFFolderContent(currentPath)
              content.forEach(node => {
                this.addLibraryItemToTree(node, true)
              })
            } else {
              // check the path element
              const nodeToCheck  = this.getNodeByDataPath(currentPath, this.dxpTree)
              nodeToCheck.icon = 'pi pi-check'
              this.currentCheckedTreeNode = nodeToCheck
            }
          }
          if (existingPathElements[paI] === whoAmI.subscriptionId) {
            belowTeams = true
          }
        }
      }
    })
  }

  // Function that determines what to show based on the radio selection
  public showForm(field: string): boolean {
    switch (this.analyticsChoice) {
      case 'COPY':
        return this.isNewTemplate && this.analyticsChoice === field
      default:
        return this.analyticsChoice === field
    }
  }

  // Function called from the UI to get just the folder from the dxp location (either existing or new)
  public getFolder(): string {
    let re = ''
    const location = this.showSFLink ? this.existingDXPLocation : this.newDXPLocation
    if (location) {
      const partialPath = stripOrgFolder(location);
      if (partialPath) {
        re = partialPath?.slice(0, partialPath.lastIndexOf('/'));
      }
    }
    return re
  }

  // Function called from the UI to get just the name from the dxp location (either existing or new)
  public getName(): string {
    const location = this.showSFLink ? this.existingDXPLocation : this.newDXPLocation
    if (location) {
      return location?.slice(location.lastIndexOf('/') + 1);
    }
    return ''
  }

  // Function called when the user selects an element in the tree (and we either expand it or set the dxp location)
  async handleDxpTreeSelection(event: any) {
    if (event.node) {
      const node = event.node as TreeNode;
      if (node.type === 'folder') {
        if (!node.children || node?.children?.length < 1) {
          // pi pi-cog
          node.expandedIcon = 'pi pi-spin pi-spinner'
          node.collapsedIcon = 'pi pi-spin pi-spinner'
          const subFolderContent = await this.getSFFolderContent(node.data)
          subFolderContent.forEach(snode => {
            this.addLibraryItemToTree(snode)
          })
          if (subFolderContent && subFolderContent.length < 1) {
            // node.expandedIcon
            node.expandedIcon = 'pi pi-ban'
            node.collapsedIcon = 'pi pi-ban'
            // node.type = 'folder'
            //
          }
        }
      } else {
        if (this.currentCheckedTreeNode) {
          this.currentCheckedTreeNode.icon = 'pi pi-file'
        }
        node.icon = 'pi pi-check'
        this.updateExistingDxpLocation(node.data)
        this.currentCheckedTreeNode = node;
      }
    }
  }

  // Function called when the radio selection changes
  public dxpRadioChange(event) {
    event.stopPropagation();
    if (event?.detail?.value) {
      const ev = event?.detail?.value;
      if (ev === 'EXISTING' || ev === 'CUSTOM' || ev === 'COPY' || ev === 'CURRENT') {
        this.analyticsChoice = ev;
        this.processChoice()
      }
    }
  }

  // Function that does all the updates based on the analytics choice selection
  private processChoice() {
    this.showSFLink = true;
    if (this.analyticsChoice === 'CURRENT') {
      this.updateAnalytics.emit({option: this.analyticsChoice, existingDXPLocation: this.existingDXPLocation});
    }
    if (this.analyticsChoice === 'EXISTING' || this.analyticsChoice === 'CUSTOM') {
      this.updateAnalytics.emit({option: this.analyticsChoice, existingDXPLocation: this.existingDXPLocation});
    }
    if (this.analyticsChoice === 'COPY') {
      // Add the target folder to the newLocation
      if (this.vConfiguration) {
        if (!this.newDXPLocation) {
          this.newDXPLocation = this.initialLocation;
        }
        this.setCopyTargetFolderFromConfig()
      }
      this.isCopyDxpNameValid(this.newDXPLocation?.substr(this.newDXPLocation?.lastIndexOf('/') + 1))
      this.emitAnalytics();
      this.showSFLink = false;
    }
    this.updateStatus();

  }

  // Helper function that takes the custom folder from config and adds that to the newDXPLocation (to copy to) configutation
  private setCopyTargetFolderFromConfig() {
    const folder = this.vConfiguration.customUserFolder;
    if (this.newDXPLocation.lastIndexOf('/') > -1) {
      this.newDXPLocation = folder + this.newDXPLocation.substring(this.newDXPLocation.lastIndexOf('/'), this.newDXPLocation.length);
    } else {
      this.newDXPLocation = folder + '/' + this.newDXPLocation;
    }
  }

  // Function that is called when the user changes the new dxp name
  public async setNewDXPName(event) {
    if (event.detail?.value || event.detail?.value === '') {
      const newName = event.detail.value;
      this.setNewLocation(newName)
      await this.isCopyDxpNameValid(newName)
      this.emitAnalytics();
      this.updateStatus();
    }
  }

  // Function called from the UI to open the DXP in a new window (in Spotfire)
  public openSFReport() {
    window.open(environment.spotfireURL + '/spotfire/wp/analysis?file=' + this.existingDXPLocation);
  }

  // Function called from the UI when the User toggles the advanced button
  public toggleAdvanced(event) {
    this.doAdvancedTab = event.detail.checked;
    this.doAdvancedE.emit(this.doAdvancedTab);
    this.updateStatus();
  }

  // Helper Function that get's the content (Array of Library items) of a specific folder in the Spotfire Library
  private async getSFFolderContent(folder: string): Promise<LibraryItem[]> {
    // Get the first list of folders/ dxp's
    return this.visualisationMS.getItems([], folder).toPromise()
  }

  // Function that takes a library item and based on the display path puts it into the tree
  private addLibraryItemToTree(libItem: Partial<LibraryItem>, expanded?: boolean) {
    const toExpand = expanded || false;
    // Get the display path
    const displayPath = libItem.displayPath
    // Convert the library item to a tree node
    const node: TreeNode = {
      label: libItem.title,
      data: libItem.path,
      type: libItem.itemType
    }
    if (libItem.itemType === 'folder') {
      node.expandedIcon = 'pi pi-folder-open'
      node.collapsedIcon = 'pi pi-folder'
      node.type = 'folder'
      node.expanded = toExpand
      // pi pi-ban

    } else {
      node.type = 'file'
      node.icon = 'pi pi-file'
    }
    this.addNodeByPath(displayPath, this.dxpTree, node)
  }

  private getNodeByDataPath(dataPath: string,  tree: TreeNode[]): TreeNode {
    let re;
    for (const node of tree) {
      if(node.data === dataPath) {
        re = node
      }  else {
        if(node.children) {
          re = this.getNodeByDataPath(dataPath, node.children)
        }
      }
    }
    return re
  }


  private addNodeByPath(path: string, tree: TreeNode[], newNode: TreeNode) {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    const nodePathElement = path.split('/')[0];
    let nodeFound = false
    for (const node of tree) {
      if (node.children && path.indexOf('/') > -1) {
        if (node.label === nodePathElement) {
          // add the node one level deeper
          nodeFound = true
          node.expanded = true
          this.addNodeByPath(path.substring(path.indexOf('/') + 1), node.children, newNode)
        }
      }
    }
    if (!nodeFound) {
      if (path.indexOf('/') > -1) {
        // There are more path elements, add a new document node
        // check if we need to add or replace the element
        this.addOrReplaceInTree({
          label: nodePathElement,
          children: [],
          expandedIcon: 'pi pi-folder-open',
          collapsedIcon: 'pi pi-folder',
          type: 'folder'
        }, tree)
        // Rerun to dive in
        this.addNodeByPath(path, tree, newNode)
      } else {
        // Add the new node
        this.addOrReplaceInTree(newNode, tree)
      }
    }
  }

  // helper function to add or replace an element (on one level) in a tree
  private addOrReplaceInTree(newNode: TreeNode, tree: TreeNode[]) {
    let replaced = false
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].label === newNode.label) {
        tree[i] = newNode
        replaced = true
      }
    }
    if (!replaced) {
      tree.push(newNode)
    }
  }

  // Helper Function to emit the analytics config
  private emitAnalytics() {
    this.updateAnalytics.emit({
      option: this.analyticsChoice,
      existingDXPLocation: this.existingDXPLocation,
      newDXPLocation: this.newDXPLocation,
    });
  }

  // Helper function to check if the new name for the DXP is a valid one
  private isCopyDxpNameValid(newName: string) {
    if (!newName) {
      this.nameHint = 'Please provide a new name for the Analysis File...'
      this.copyDxpNameValid = false
    } else {
      this.visualisationMS.checkAnalysisFile({path: this.newDXPLocation}).toPromise().then(result => {
        this.copyDxpNameValid = !result.exist
        if (!this.copyDxpNameValid) {
          this.nameHint = 'This DXP exists already...';
        } else {
          this.nameHint = ''
        }
        this.updateStatus()
      });
    }
  }

  // Helper function to only change the name in the new location of the dxp
  private setNewLocation(newName) {
    const prefixPath = this.newDXPLocation.substring(0, this.newDXPLocation.lastIndexOf('/') + 1);
    this.newDXPLocation = prefixPath + newName;
  }

  // helper function to update the existing DXP location and send and update
  private updateExistingDxpLocation(eDxpLocation: string) {
    if (eDxpLocation) {
      this.existingDXPLocation = eDxpLocation
      this.updateAnalytics.emit({option: 'EXISTING', existingDXPLocation: this.existingDXPLocation});
      this.updateStatus();
    }
  }

  // Helper function to update the status
  private updateStatus() {
    let valid = false;
    if (this.analyticsChoice === 'CURRENT') {
      valid = true
    }
    if (this.analyticsChoice === 'COPY') {
      valid = this.existingDXPLocation && this.newDXPLocation && this.copyDxpNameValid
    }
    if (this.analyticsChoice === 'EXISTING' || this.analyticsChoice === 'CUSTOM') {
      valid = this.existingDXPLocation !== '';
    }
    const stepStatus = {
      step: 'analytics',
      completed: valid
    };
    this.status.emit(stepStatus);
  }

}
