<!--<pre>{{preview | json}}</pre>-->
<div *ngIf="preview" class="map-container">
  <div *ngIf="hasAutoMapped" style="display: flex; flex-direction: row; margin: 10px 0px 32px 0px; justify-content: space-between; align-items: center; ">
    <div id="map-notice" class="notice">
      <mat-icon class="material-symbols-rounded primary-color">lightbulb</mat-icon>
      {{ 'map.label.info' | translate }}
    </div>
  </div>
  <div class="mapping-section">
    <div class="map-left-panel">
      <map-panel [mapping]="mapping" [availableColumns]="preview?.availableColumns" [datasetId]="selectedDataset"
                 (status)="updateStatus($event)" [doAutoMap]="doAutoMap"></map-panel>
      <!--
      <div class="map-section advanced-margin" *ngIf="!hideAdvanced">
        Advanced options
      </div>
      <div class="map-column" *ngIf="!hideAdvanced">
        <p>Select additional tab to customize your analysis</p>
        <uxpl-switch-input [checked]="advancedMode" (uxplChange)="toggleAdvanced($event)">Additional preparation tab
        </uxpl-switch-input>
      </div> -->
    </div>
    <div class="map-preview-table" *ngIf="previewColumns">
      <div style="display: flex; flex-direction: row; align-items: baseline; justify-content: space-between;">
        <div>
          {{ 'map.label.prev' | translate }}
        </div>
        <div style="display: flex; flex-direction: row; align-items: baseline;">
          <uxpl-button type="no-border" (clicked)="clearMap()">{{ 'map.label.clear' | translate }}</uxpl-button>
          <uxpl-toggle-text [selected]="previewValue" [options]="previewOptions"
                            (uxplSelectionChanged)="handlePreviewColumns($event)"></uxpl-toggle-text>
        </div>
      </div>
      <data-preview-table [columnDefs]="previewColumns" [rowData]="preview?.data.slice(0,20)"
                          [autoLayout]="false" [showSortIcon]="false"
                          [showExpandButton]="false" [paginator]="false"
                          [showCurrentPageReport]="false" [showFilterRow]="false">
      </data-preview-table>
    </div>
    <!-- <div *ngIf="isError" class="datasource-error">
      <uxpl-empty-state-system type="system error" [message]="errorMessage" [description]="errorDescription"></uxpl-empty-state-system>
    </div> -->
    <div *ngIf="previewColumns === undefined" class="center">
      <!-- *ngIf="!isError && (previewColumns === undefined || previewColumns?.length === 0)" class="center"> -->
      <div class="img-center">
        <img width="204" [src]="'assets/images/png/no-data.png'">
      </div>
      <div class="no-data-available"><br></div>
      <div class="no-data-available" style="font-weight: bold">{{ 'map.label.noPrev' | translate }}<br></div>
      <div class="no-data-available"><br></div>
    </div>
  </div>
</div>
