import { Component, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { IconSidebar } from 'src/app/components/common/icon-sidebar/icon-sidebar.component';
import { GetStatisticsResponse } from 'src/app/models_ui/users';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {

  iconSidebarData: IconSidebar = {
    "main": [{
      "label": "Control panel",
      "icon": "dashboard",
      "link": "/discover/control-panel",
      "routerLinkActiveExact": true 
    },{
      "label": "Users",
      "icon": "group",
      "link": "/discover/control-panel/users",
      "routerLinkActiveExact": true 
    },{
      "label": "Add user",
      "icon": "person_add",
      "link": "/discover/control-panel/add-user",
      "routerLinkActiveExact": true 
    }]
  };

  pulseMindsColors: string[] = [
    "#2B94EB",
    "#4A6DE0",
    "#6C49D1",
    "#D240A7",
    "#FB5443",
    "#FD7742"
  ]

  isLoading: boolean = false;
  adminStatistics: GetStatisticsResponse;

  // Users bar chart
  public usersBarChartOptions: ChartOptions = {
    responsive: true,
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        // beginAtZero: true,
        // grid: {
        //   display: false
        // },
        display: false,
        beginAtZero: true
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        display: false
      }
    },
    hover: {
      mode: null,
    },
    layout: {
      padding: {
        right: 40
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value}`;
        },
        font: {
          size: 16,
          weight: 800,
          family: "Montserrat"
        }
      }
    }
  };
  public usersBarChartLabels: string[] = [""];
  public usersBarChartType: ChartType = 'bar';
  public usersBarChartLegend = false;
  public usersBarChartPlugins = [DataLabelsPlugin];
  public usersBarChartData: ChartDataset[] = [];
  usersTotal: number = 0;
  userRolesColors: string[] = [];
  
  constructor(
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
    private usersService: UsersService,
  ) {}
  ngOnInit(): void {
    this.getAdminStatistics();
  }
  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh() {
    this.getAdminStatistics();
  }

  getAdminStatistics(){
    this.isLoading = true;
    this.usersService.getStatistics().subscribe(
      (response: GetStatisticsResponse) => {
        this.adminStatistics = response;
        this.populateStatistics();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.openSnackBar(this.translationService.translate('generic.message.api.error'), this.translationService.translate('create.org.snackBar.close'),'error');
        console.error("getAdminsStatics error: ", error);
      } 
    );
  }

  populateStatistics(){
    this.usersTotal = 0;
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.total){
      this.usersTotal = this.adminStatistics.users.total;
    }
    this.userRolesColors = [];
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.roles && this.adminStatistics.users.roles.length > 0){
      this.userRolesColors = this.adminStatistics.users.roles.map((_, index) => 'color-'+((index % 6) + 3)+'-text');
    }
    this.usersBarChartData = [];
    if(this.adminStatistics && this.adminStatistics.users && this.adminStatistics.users.roles && this.adminStatistics.users.roles.length > 0){
      for(let [index, user] of this.adminStatistics.users.roles.entries()){
        this.usersBarChartData.push({
          data: [user.length],
          backgroundColor: this.pulseMindsColors[index+2],
          borderRadius: 4
        });
      }
    }
  }

}
