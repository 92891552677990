import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CreateTemplate,
  Template,
  VisualisationService,
  MenuConfigTemplate,
  FilterTemplate
} from '@tibco/discover-client-lib';
import {ConfigurationService} from '../../service/configuration.service';
import {notifyUser} from '../../functions/message';
import {
  BaseTemplateInfo,
  StepStatus,
  TemmplateAnalyticDetails,
  TemplateAnalyticChoice
} from '../../models_ui/analyticTemplate';
import {convertCreateTemplateToUpdate, convertTemplateToCreateTemplate} from '../../functions/templates';
import {InternalMessageService} from '../../service/internal-message.service';
import {StepperConfigUI} from '../../models_ui/uxpl-models';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css']
})
export class TemplateEditorComponent implements OnInit {

  constructor(
    private messageService: InternalMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private visualisationMS: VisualisationService,
    protected configService: ConfigurationService,
    private translationService: TranslationService,

  ) {
  }

  private mode: 'edit' | 'create';

  objHeaderConfig:ObjectHeaderConfig;
  config: StepperConfigUI;
  request: CreateTemplate;
  doAdvanced: boolean;
  pageOptions: string[];
  markingOptions: string[];
  dataOptions: any;
  previewMenu: MenuConfigTemplate[];
  previewFilterMenu: FilterTemplate[];
  baseTemplateId: string;
  editTemplateId: string;
  analyticsOption: TemplateAnalyticChoice;
  existingDXPLocation: string;
  newDXPLocation: string;

  initialTemplateDXPLocation: string;

  callingUpdateCreate = false
  langChangeSubscription: Subscription;

  step1Label: string;
  step2Label: string;
  step3Label: string;
  step4Label: string;

  ngOnInit(): void {
    this.doAdvanced = false;
    this.messageService.sendMessage('integratedHelp', 'discover/new-template');

    if (this.route.snapshot.paramMap.get('name') !== null) {
      this.mode = 'edit';
      this.analyticsOption = 'EXISTING';
      
    } else {
      this.mode = 'create';
      this.analyticsOption = 'CURRENT';
    }
    this.initializeForm();
    this.getStepperConfiguration(false);
    this.updateStepLabels(false);
    // this.visualisationMS.getItems('folder')
    //   .subscribe((folders) => {
    //     this.folders = folders
    //   })
    this.langChangeSubscription = this.translationService.currentLang$.subscribe(() => {
      

      this.updateStepLabels(this.doAdvanced);
      this.initializeForm();
      this.getStepperConfiguration(this.doAdvanced);


    });
  }

  private initializeForm() {
    if (this.mode === 'create') {
      this.route.queryParams.subscribe(
        params => {
          // Converted to string to fix comparison
          if (params.templateToCreateFrom) {
            this.baseTemplateId = params.templateToCreateFrom + '';
          } else {
            this.visualisationMS.getTemplates().subscribe(templates => {
              if (templates.length > 0) {
                // By default copy discovery
                this.baseTemplateId = templates.find(v => v.name === 'Discovery').id
                if (!this.baseTemplateId) {
                  // Otherwise use the first one
                  this.baseTemplateId = templates[0].id
                }
              }
            })
          }
          this.request = {} as CreateTemplate;
          this.createObjectHeader();
        }
      )
    }

    if (this.mode === 'edit') {
      // Trick to update the screen
      this.request = null;
      // window.setTimeout(() => {
      this.visualisationMS.getTemplate(this.route.snapshot.paramMap.get('name')).subscribe(
        (template: Template) => {
          this.request = convertTemplateToCreateTemplate(template);
          this.editTemplateId = template.id;
          if (!this.request.filters) {
            this.request.filters = [];
          }
          this.existingDXPLocation = this.request.analysisFileLocation;
          this.createObjectHeader();
        }
      );
    }
  }

  private createObjectHeader() {
    let tempS = '';
    if (this.request.name) {
      tempS = ': ' + this.request.name
    }
    this.objHeaderConfig = {
      title: {
        value: this.mode === 'create' ? this.translationService.translate("template.editor.title.create") + tempS : this.translationService.translate("template.editor.title.edit") + tempS,
        isEdit: false,
        editing: false
      }
    };
  }

  public isMode(mode: string): boolean {
    return this.mode === mode;
  }

  public display(element: string) {
    return this.config.steps[this.config.currentStepIdx].slot === element;
  }
  private updateStepLabels(advancedAvailable: boolean) {
    let confirmLabel =  this.translationService.translate('template.editor.label.confirm');

    // Traduci e aggiorna le etichette dei passi qui
    this.step1Label = this.translationService.translate("template.editor.label.step1");
    this.step2Label = this.translationService.translate("template.editor.label.step2");
    this.step3Label = this.translationService.translate("template.editor.label.step3");
    this.step4Label = this.translationService.translate("template.editor.label.step4");


    if (this.mode === 'edit') {
      this.doAdvanced = true;
      advancedAvailable = true;
      confirmLabel =  this.translationService.translate('template.editor.label.summary');

    }
    this.config = {
      steps: [
        {available: true, completed: false, label: this.step1Label, slot: 'basic-info'},
        {available: this.mode === 'edit', completed: false, label: this.step2Label, slot: 'analytics'},
        {
          available: this.mode === 'edit',
          completed: false,
          label: this.step3Label,
          slot: 'analytics-menu-configuration'
        },
        {
          available: this.mode === 'edit',
          completed: false,
          label: this.step4Label,
          slot: 'analytics-filter-panel'
        },
        {available: this.mode === 'edit', completed: false, label: confirmLabel, slot: 'confirmation'}
      ],
      currentStepIdx: this.config ? this.config.currentStepIdx : 0
    };

    if (this.doAdvanced) {
      const advancedStep = {
        available: advancedAvailable,
        completed: false,
        label: this.translationService.translate('template.editor.label.advanced'),
        slot: 'additional-options'
      };
      this.config.steps.splice(4, 0, advancedStep);
    }
    for (let i = 1; i <= this.config.steps.length; i++) {
      this.config.steps[i - 1].name = i + '';
    }
}
  private getStepperConfiguration(advancedAvailable: boolean) {
    

    // Traduci e aggiorna le etichette dei passi qui
    this.step1Label = this.translationService.translate("template.editor.label.step1");
    this.step2Label = this.translationService.translate("template.editor.label.step2");
    this.step3Label = this.translationService.translate("template.editor.label.step3");
    this.step4Label = this.translationService.translate("template.editor.label.step4");

    //let confirmLabel = 'Confirmation';
    let confirmLabel =  this.translationService.translate('template.editor.label.confirm');

    if (this.mode === 'edit') {
      this.doAdvanced = true;
      advancedAvailable = true;
      confirmLabel = 'Summary';
      confirmLabel =  this.translationService.translate('template.editor.label.summary');

    }
    this.config = {
      steps: [
        {available: true, completed: false, label: this.step1Label, slot: 'basic-info'},
        {available: this.mode === 'edit', completed: false, label: this.step2Label, slot: 'analytics'},
        {
          available: this.mode === 'edit',
          completed: false,
          label: this.step3Label,
          slot: 'analytics-menu-configuration'
        },
        {
          available: this.mode === 'edit',
          completed: false,
          label: this.step4Label,
          slot: 'analytics-filter-panel'
        },
        {available: this.mode === 'edit', completed: false, label: confirmLabel, slot: 'confirmation'}
      ],
      currentStepIdx: this.config ? this.config.currentStepIdx : 0
    };

    if (this.doAdvanced) {
      const advancedStep = {
        available: advancedAvailable,
        completed: false,
        label: this.translationService.translate('template.editor.label.advanced'),
        slot: 'additional-options'
      };
      this.config.steps.splice(4, 0, advancedStep);
    }
    for (let i = 1; i <= this.config.steps.length; i++) {
      this.config.steps[i - 1].name = i + '';
    }
  }

  public goTemplates() {
    this.router.navigate(['/discover/templates']);
  }

  public handleAdvanced($event) {
    this.doAdvanced = $event;
    this.getStepperConfiguration(this.mode === 'edit');
  }

  public updateTemplate(data: CreateTemplate) {
    /* Implemented a fix here. When going back and forth these values are set wrong,
       but when the user selects another template this needs to be set;
       added an additional separate event to set the base template info */
    // console.log('updateTemplate: ', data)
    this.request = data;
  }

  public setBaseTemplateInfo(btInfo: BaseTemplateInfo) {
    // console.log('setBaseTemplateInfo: ', btInfo)
    this.initialTemplateDXPLocation = btInfo.dxpLocation
    this.existingDXPLocation = btInfo.dxpLocation
    this.baseTemplateId = btInfo.id
    this.newDXPLocation = btInfo.newDxpLocation
  }

  public async updateAnalytics(event: TemmplateAnalyticDetails) {
    this.analyticsOption = event.option;
    // if (this.request?.sourceId || this.request?.destinationFolderId) {
    //   delete this.request.sourceId;
    //   delete this.request.destinationFolderId;
    // }
    if (this.request?.analysisFilesourcePath) {
      delete this.request.analysisFilesourcePath;
    }
    switch (this.analyticsOption) {
      case 'CUSTOM':
        this.existingDXPLocation = event.existingDXPLocation;
        break;
      case 'EXISTING':
        this.existingDXPLocation = event.existingDXPLocation;
        break;
      case 'CURRENT':
        this.visualisationMS.getTemplates().subscribe(
          (templates: Template[]) => {
            this.existingDXPLocation = templates.find(t => t.id + '' === this.baseTemplateId + '').analysisFileLocation;
          })
        break;
      case 'COPY':
        // console.log('Event: ', event)
        this.newDXPLocation = event.newDXPLocation;
        this.existingDXPLocation = event.existingDXPLocation;
        // When destination can't be found use the current folder // TODO: Double check if the folders are coming in
        this.request.analysisFilesourcePath = event.existingDXPLocation
        this.request.analysisFileLocation = event.newDXPLocation
        break;
      default:
        break;
    }
    // Fix so it shows correctly on the summary page (not for Copy, since then the preview needs to show the old DXP)
    if (this.request && this.analyticsOption === 'CUSTOM' || this.analyticsOption === 'EXISTING' || this.analyticsOption === 'CURRENT') {
      this.request.analysisFileLocation = this.existingDXPLocation;
    }
  }

  // private async setFDestinationId(sourceId) {
  //   const customUserFolder = (await this.visualisationMS.getVisualisationConfiguration().toPromise()).customUserFolder;
  //   if (sourceId) {
  //     for (const fol of this.folders) {
  //       if (fol.path === customUserFolder) {
  //         this.request.sourceId = sourceId;
  //         this.request.destinationFolderId = fol.id;
  //       }
  //     }
  //   }
  // }

  public handleStatus(sStatus: StepStatus) {
    const stepStatus = this.config.steps.filter(step => step.slot === sStatus.step)[0];
    stepStatus.completed = sStatus.completed;
  }

  public changeTab(delta: number) {
    const newSteptStep = this.config.currentStepIdx + delta;
    this.config.steps[newSteptStep].available = true;
    this.config = {...this.config, currentStepIdx: newSteptStep};
  }

  public handleStepClick(step) {
    // console.log('Step click: ', step)
    this.config.currentStepIdx = step.detail;
  }

  public handlePageOptions(event) {
    this.pageOptions = event;
  }

  public handleMarkingOptions(event) {
    this.markingOptions = event;
  }

  public handleDataOptions(event) {
    this.dataOptions = event;
  }

  public hide(element: string): boolean {
    const lastIdx = this.config.steps.length - 1;
    if (element === 'prev') {
      return this.config.currentStepIdx === 0 || this.mode === 'edit';
    }
    if (element === 'next') {
      return this.config.currentStepIdx === lastIdx || this.mode === 'edit';
    }
    if (element === 'create') {
      return this.config.currentStepIdx !== lastIdx || this.mode !== 'create';
    }
    if (element === 'save') {
      return this.mode !== 'edit';
    }
  }

  public handleDisableNextButton(): boolean {
    return !this.config.steps[this.config.currentStepIdx].completed; //  !this.config.steps.filter(status => status.step === this.config.steps[this.config.currentStepIdx].slot)[0].completed;
  }

  public handleCreateUpdateClicked() {
    // TODO: Temporary fix to handle the fact that the spotfireLocation is set in the general template (and therefore need to be overwritten with the target location)
    // When the word 'Spotfire' is removed from the API AND(!) the 'spotfireLocation' is removed from the general template data this code can be removed.
    if(this.request.spotfireLocation){
      this.request.spotfireLocation = this.request.analysisFileLocation
    }
    let method = this.visualisationMS.postTemplate(this.request);
    if (this.mode === 'edit') {
      method = this.visualisationMS.updateTemplates([convertCreateTemplateToUpdate(this.request, this.editTemplateId)]);
    }
    // this.request.spotfireLocation = this.existingDXPLocation;
    if (!this.callingUpdateCreate) {
      this.callingUpdateCreate = true;
      method.subscribe(
        () => {
          this.callingUpdateCreate = false
          const action = this.mode !== 'edit' ? 'created' : 'updated'
          const message = 'Template ' + this.request.name + ' has been ' + action + ' successfully.'
          this.messageService.sendMessage('news-banner.topic.message', message);
          this.router.navigate(['/discover/templates']);
        },
        error => {
          this.callingUpdateCreate = false
          let message = 'Error creating the template...';
          if (this.mode === 'edit') {
            message = 'Error updating the template...';
          }
          console.error(message, error);
          notifyUser('ERROR', message, this.messageService);
        }
      );
    }
  }
}
