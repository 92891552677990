<h2 mat-dialog-title>Confirm Delete</h2>
<mat-dialog-content>
  Are you sure you want to delete this analysis?
  <mat-form-field appearance="fill">
    <mat-label>Type "DELETE" to confirm</mat-label>
    <input
      matInput
      [(ngModel)]="confirmationInput"
      placeholder="Type 'DELETE'"
      #confirmationInputField
      (keydown)="onKeyDown($event)"
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="actions-container">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button
      mat-button
      color="warn"
      [disabled]="confirmationInput !== 'DELETE'"
      (click)="onDeleteClick()"
    >
      Delete
    </button>
  </div>
</mat-dialog-actions>
