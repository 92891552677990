import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {InvestigationContext} from '../../../models_ui/investigationContext';
import {Router} from '@angular/router';
import {stripDisabledMenuItems} from '../../../functions/templates';
import {Investigation, Template, VisualisationService} from '@tibco/discover-client-lib';
import {SpotfireDocument, SpotfireViewerComponent} from '@tibcosoftware/spotfire-wrapper';
import {NavMenu} from '@tibco/tc-web-components/dist/types/models/leftNav';
import {OauthService} from '../../../service/oauth.service';
import {UxplLeftNav} from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'investigation-analytic',
  templateUrl: './investigation-analytic.component.html',
  styleUrls: ['./investigation-analytic.component.css']
})
export class InvestigationAnalyticComponent implements OnInit, OnChanges {

  constructor(private router: Router,
              private oService: OauthService,
              private visualisationMS: VisualisationService) {
  }

  @Input() investigationDetail: Investigation;
  @Input() investigationContext: InvestigationContext;

  @ViewChild('analysis', {static: false}) analysisRef: SpotfireViewerComponent;
  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNav>;

  spotfireServer;
  spotfireDXP: string;
  anTemplate: Template;
  analysisParameters: any;
  leftNavTabs: NavMenu[];
  document: SpotfireDocument;

  readonly mode = false
  SFConfg = {
    showAbout: this.mode,
    showAnalysisInformationTool: this.mode,
    showAuthor: this.mode,
    showClose: this.mode,
    showCustomizableHeader: false,
    showDodPanel: this.mode,
    showExportFile: this.mode,
    showFilterPanel: true,
    showHelp: false,
    showLogout: false,
    showPageNavigation: false,
    showStatusBar: false,
    showToolBar: this.mode,
    showUndoRedo: this.mode,
  };
  enableMenu = false

  ngOnInit() {
    this.spotfireServer = environment.spotfireURL;
  }

  goAnalytic() {
    if (this.investigationContext?.processAnalysis?.id) {
      this.router.navigate(['/discover/analytics/' + this.investigationContext.processAnalysis.id]);
    }
  }

  async ngOnChanges(_changes: SimpleChanges) {
    if (this.investigationContext) {
      if (this.investigationContext.template?.id) {
        this.anTemplate = await this.visualisationMS.getTemplate(this.investigationContext.template?.id).toPromise();
        // console.log('this.anTemplate: ', this.anTemplate)
        this.leftNavTabs = stripDisabledMenuItems(this.anTemplate.menuConfig);
        this.spotfireDXP = this.anTemplate.analysisFileLocation;
        this.analysisParameters = 'AnalysisId="' + this.investigationContext?.processAnalysis?.idNoVersion + '";&Token="' + this.oService.token + '";&inKeepRow="' + this.investigationContext?.analytic?.filter + '";' // + 'SetPage(pageTitle="' + this.defaultTab.id + '");';
      }
    }
  }

  setDocument(ev: any) {
    if (!this.document) {
      this.document = ev;
      const sfApi = ev._doc
      this.document.onDocumentReady$().subscribe(_val => {
        if (this.investigationContext?.analytic?.page) {
          this.openPage(this.investigationContext.analytic.page)
          this.selectMenuItem(this.investigationContext.analytic.page)
        }
        // TODO: Apply case marking
        if (this.investigationContext?.analytic?.markedVariants) {
          const mv = this.investigationContext.analytic.markedVariants
          const whereClause = '[variant_id] IN ' + mv.replace('[', '(').replace(']', ')').replace(/"/g, '')
          // console.log('whereClause: ', whereClause)
          // Marking name: Variant
          // Table name: variants
          sfApi.marking.setMarking('Variant', 'variants', whereClause, 'REPLACE')
        }
        this.enableMenu = true;
        /* TODO: Apply filters
        if (this.filterConfig) {

        }*/
      })
    }
  }

  selectMenuItem(id: string){
    let tab:NavMenu
    this.leftNavTabs.forEach( lnT => {
      if(lnT.id === id) {
        tab = lnT
      }
      if(lnT.child && lnT.child.length > 0) {
        lnT.child.forEach( ch => {
          if(ch.id === id){
            tab = ch
          }
        })
      }
    })
    setTimeout(() => {
      this.leftNav?.nativeElement?.setTab(tab, true);
    }, 0);
    // Sometime the left tab is not selected, this code ensures it is
    setTimeout(() => {
      this.leftNav.nativeElement.setTab(tab, true);
    }, 100);
  }

  handleClick(ev: any) {
    if (this.enableMenu && ev?.detail.id) {
      this.openPage(ev.detail.id)
    }
  }

  private openPage(page: string) {
    this.analysisRef.page = page;
    this.analysisRef.openPage(page);
  }

}
