// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  local: false,
  apiURL: 'https://test.api.pulseminds.com',
  discoverHost: 'discover.labs.cloud.tibco.com',
  discoverClientBasePath: 'https://test.api.pulseminds.com',
  tcHost: 'cloud.tibco.com',
  region: 'eu',
  authConfig: {
    clientId: '71e37749-abf2-4808-b74a-5f1b44217d50', // Application (client) ID from the app registration
    authority: 'https://login.microsoftonline.com/4a028571-5ac8-493d-98c1-0b23ec7d5004', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    redirect_url: 'https://test.app.pulseminds.com/discover',
    end_session_redirect_url: 'https://test.app.pulseminds.com/welcome',
    scopes: ['user.read', 'team.readbasic.all']
  },
  pendoApiKey: '30b53883-4f50-46a5-45d9-2f87691fd3f1',
  spotfireURL: 'https://test.spotfire.pulseminds.com',
  spotfireClientId: 'ee2914745b747cbed6181ae362b1ace8.oauth-clients.spotfire.tibco.com',
  spotfireRedirectUri: 'https://test.app.pulseminds.com/discover/process-analysis',
  supportUrl: "https://dev.support.pulseminds.com",
  docsUrl: "https://dev.docs.pulseminds.com",
  supportEmail: "support@pulseminds.com",
  version: "1.0.0"
};

export const LANGUAGES = [{
  "code": "en_US",
  "shortName": "EN",
  "name": "English",
  "default": true,
  "active": true
},{
  "code": "it_IT",
  "shortName": "IT",
  "name": "Italiano",
  "default": false,
  "active": true
},{
  "code": "es_ES",
  "shortName": "ES",
  "name": "Español",
  "default": false,
  "active": true
}]

export const RELEASE_NOTES = {
  "date": "2024-07-04T00:00:00Z",
  "it_IT": {
    "description": "Siamo entusiasti di annunciare il lancio della versione 1.0 di PulseMinds, Intelligent Process Platform (IPP) che rappresenta un passo significativo ed innovativo nell’ambito della discovery e l'ottimizzazione dei processi aziendali.",
    "notes": [{
      "title": "Caratteristiche Principali",
      "list": [{
          "title": "Analisi Predittiva",
          "description": "Utilizzando algoritmi avanzati di machine learning, la piattaforma è in grado di prevedere tendenze e comportamenti, consentendo migliori decisioni."
        },{
          "title": "Interfaccia Utente Intuitiva",
          "description": "Un design user-friendly che permette agli utenti di tutte le competenze tecniche di navigare e utilizzare la piattaforma con facilità, anche multilingua."
        },{
          "title": "Sicurezza Avanzata",
          "description": "Accesso alla piattaforma solo per utenti registrati."
      }]
      },{
      "title": "Vantaggi per gli Utenti",
      "list": [{
          "title": "Esperienza utente",
          "description": "È sufficiente un browser e si è subito operativi."
        },{
          "title": "Efficienza Migliorata",
          "description": "Identificazione dei bottleneck dei processi e aumento della produttività."
        },{
          "title": "Costi Operativi Ridotti",
          "description": "Minimizzazione degli errori e ottimizzazione delle risorse aziendali."
        },{
          "title": "Compliance",
          "description": " La piattaforma permette di verificare la compliance rispetto a processi di riferimento e SLA."
      }]
    }]
  },
  "en_US": {
    "description": "We are excited to announce the launch of version 1.0 of PulseMinds, an Intelligent Process Platform (IPP) that represents a significant and innovative step in business process discovery and optimization.",
    "notes": [
      {
        "title": "Key Features",
        "list": [
          {
            "title": "Predictive Analytics",
            "description": "Using advanced machine learning algorithms, the platform can predict trends and behaviors, enabling better decisions."
          },{
            "title": "Intuitive User Interface",
            "description": "A user-friendly design that allows users of all technical skills to navigate and use the platform with ease, including multilingual."
          },{
            "title": "Advanced Security",
            "description": "Access to the platform for registered users only."
          }
        ]
      },
      {
        "title": "Benefits for Users",
        "list": [
          {
            "title": "User Experience",
            "description": "All you need is a browser and you are up and running immediately."
          },{
            "title": "Improved Efficiency",
            "description": "Identification of process bottlenecks and increased productivity."
          },{
            "title": "Reduced Operating Costs",
            "description": "Minimization of errors and optimization of business resources."
          },{
            "title": "Compliance",
            "description": " The platform allows verification of compliance against benchmark processes and SLAs."
          }
        ]
      }
    ]
  },
  "es_ES": {
    "description": "Nos complace anunciar el lanzamiento de la versión 1.0 de PulseMinds, Intelligent Process Platform (IPP) que representa un paso significativo e innovador en el descubrimiento y optimización de procesos de negocios.",
    "notes": [{
      "title": "Características principales",
      "list": [{
        "title": "Análisis predictivo",
        "description": "Utilizando algoritmos avanzados de aprendizaje automático, la plataforma es capaz de predecir tendencias y comportamientos, lo que permite tomar mejores decisiones."
      }, {
        "title": "Interfaz de usuario intuitiva",
        "description": "Un diseño fácil de usar que permite a los usuarios con todos los conocimientos técnicos navegar y utilizar la plataforma con facilidad, incluso en varios idiomas."
      }, {
        "title": "Seguridad Avanzada",
        "description": "Acceso a la plataforma sólo para usuarios registrados."
      }]
    }, {
      "title": "Beneficios para los usuarios",
      "list": [{
        "title": "Experiencia de usuario",
        "description": "Todo lo que necesitas es un navegador y estarás listo y funcionando."
      }, {
        "title": "Eficiencia mejorada",
        "description": "Identificar cuellos de botella en los procesos y aumentar la productividad."
      }, {
        "title": "Costos operativos reducidos",
        "description": "Minimización de errores y optimización de recursos de la empresa."
      }, {
        "title": "Cumplimiento",
        "description": "La plataforma permite verificar el cumplimiento de procesos de referencia y SLA."
      }]
    }]
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

