<div>
  <div id="map-notice" class="notice">
    <mat-icon class="material-symbols-rounded primary-color">lightbulb</mat-icon>
    <div>
      {{ 'attribute.component.text' | translate }}
      <br>
      {{ 'attribute.component.text.2' | translate }}
    </div>
  </div>
  <div class="preview-table" *ngIf="previewData ; else loading">
    <p-table [value]="schema" dataKey="label">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="label">{{ 'attribute.component.text.col' | translate }}
            <p-sortIcon field="label"></p-sortIcon>
          </th>
          <th>{{ 'attribute.component.text.value' | translate }}</th>
          <th>{{ 'attribute.component.text.attribute' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-column let-i="rowIndex" >
        <tr >
          <td>
            {{column.label}}
          </td>
          <td>
            {{previewData[0][column.label]}}
          </td>
          <td>
            <uxpl-select-input class="dropdown" [options]="datatypeOptions" [value]="column.type" max-height="165px" [yPosition]="getPosition(i)"
                               (uxplSelection)="onSelectDatatype($event, column)"></uxpl-select-input>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #loading>
    <div class="loading-indicator">
      <pulse-loading></pulse-loading>
      <!-- <uxpl-spinner style="height: 150px; min-width: 150px; max-width: 150px;" appearance="light"
                    message="preview file..."></uxpl-spinner> -->
    </div>
  </ng-template>
</div>
