import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/service/translate.service';
import { User } from 'src/app/models_ui/users';
import { Roles, UsersService } from 'src/app/service/users.service';
import { SnackbarService } from 'src/app/service/snackbar.service';import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ToggleSuperadminDialogComponent } from 'src/app/components/toggle-superadmin-dialog/toggle-superadmin-dialog.component';
import { RemoveUserDialogComponent } from 'src/app/components/remove-user-dialog/remove-user-dialog.component';
import { ChangeRoleDialogComponent } from 'src/app/components/change-role-dialog/change-role-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoResponse } from 'src/app/models_ui/auth';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
;

@Component({
  selector: 'org-users',
  templateUrl: './org-users.component.html',
  styleUrls: ['./org-users.component.css'],
  host: {'class': 'flex-item'}
})
export class OrgUsersComponent implements OnInit {

  private refreshSubscription: Subscription;
  currentOrgId: string = '';
  isLoading: boolean = false;
  users: User[];
  usersColumns: string[] = ['firstName', 'lastName', 'email', 'role', 'superadmin', 'actions'];
  dataSource: MatTableDataSource<User>;
  roles: Roles;
  userinfo: UserInfoResponse;
  userDisplayName: string;

  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private usersService: UsersService,
    private snackBarService: SnackbarService,
    private toggleSuperadminDialog: MatDialog,
    private removeUserDialog: MatDialog,
    private changeRoleDialog: MatDialog,
    private cookieService: CookieService,
    private commonService: CommonService
  ) {
    this.dataSource = new MatTableDataSource();
    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
      if (this.userinfo.firstName && this.userinfo.lastName) {
        this.userDisplayName =
          this.userinfo.firstName +
          ' ' +
          this.userinfo.lastName.substring(0, 1);
      }
    }
  }

  ngOnInit(): void {
    this.currentOrgId = this.route.snapshot.paramMap.get('orgId');
    if(this.currentOrgId){
      this.refresh();
      this.refreshSubscription = this.commonService.refreshObservable.subscribe(() => {
        this.refresh();
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  refresh() {
    this.getOrgUsers();
    this.usersService.getRoles().subscribe((res: Roles) => (this.roles = res));
  }

  getOrgUsers(){
    this.isLoading = true;
    this.usersService.getUsersByOrgId(this.currentOrgId).subscribe(
      (response) => {
        this.users = response;
        this.dataSource.data = this.users;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.openSnackBar(this.translationService.translate('generic.message.api.error'), this.translationService.translate('create.org.snackBar.close'),'error');
        console.error("getAdminsStatics error: ", error);
      } 
    );
  }

  toggleSuperadmin($event, id: string, superadmin: boolean) {
    this.openConfirmationDialog($event, id, superadmin);
  }

  openConfirmationDialog($event, id: string, superadmin: boolean): void {
    const dialogRef = this.toggleSuperadminDialog.open(
      ToggleSuperadminDialogComponent,
      {
        width: '300px',
        data: {
          superadmin: superadmin,
          userId: id,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        $event.source.checked = !$event.source.checked;
      }
    });
  }

  changeRole(userId: string): void {
    const dialogRef = this.changeRoleDialog.open(ChangeRoleDialogComponent, {
      width: '250px',
      data: {
        currentRole: this.dataSource.data.find((user) => user.id === userId).role,
        roles: this.roles,
        userId: userId,
        orgId: this.currentOrgId,
        superadmin: this.userinfo.superadmin,
      },
    });
    // Subscribe to emitted event from dialog
    dialogRef.componentInstance.roleUpdated.subscribe((newRole: string) => {
      // Update the role in the data source
      const userToUpdate = this.dataSource.data.find(
        (user) => user.id === userId
      );
      if (userToUpdate) {
        userToUpdate.role = newRole;
      }
    });
  }
  removeUser(userId: string) {
    const dialogRef = this.removeUserDialog.open(RemoveUserDialogComponent, {
      width: '250px',
      data: {
        userId: userId,
        orgId: this.currentOrgId,
        superadmin: this.userinfo.superadmin,
      },
    });
    dialogRef.componentInstance.userDeleted.subscribe(
      (deletedUserId: string) => {
        this.dataSource.data = this.dataSource.data.filter(
          (user) => user.id !== deletedUserId
        );
      }
    );
  }
}
