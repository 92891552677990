import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CatalogService, CheckExistDataset, CreateDataset, Dataset } from '@tibco/discover-client-lib';
import { Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatasetWizard } from 'src/app/models_ui/dataset';
import { NewAnalysisStepStatus } from '../../../models_ui/discover';
import { TranslationService } from 'src/app/service/translate.service';
@Component({
  selector: 'dataset-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class NewDatasetBasicInfoComponent implements OnInit, OnChanges {

  @Input() data: Dataset | CreateDataset;
  @Input() wizard: DatasetWizard;
  @Input() checkRequired: boolean;
  // TODO: replace the NewAnalysisStepStatus with a general wizard step status
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter<NewAnalysisStepStatus>();
  @Output() enableSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() type: EventEmitter<string> = new EventEmitter<string>();
  namePlaceholder: string;
  descriptionPlaceholder: string;
  nameRequired: boolean = false;
  typeRequired: boolean = false;
  datatypes: any[] = [
    {
      name: 'CSV',
      type: Dataset.TypeEnum.File,
      description: this.translationService.translate("basic.file.description"),
      disabled: false
    },
    {
      name: 'XES',
      type: Dataset.TypeEnum.Xes,
      description: this.translationService.translate("basic.xes.description"),
      disabled: false
    },
    {
      name: 'XLSX',
      type: Dataset.TypeEnum.Xlsx,
      description: this.translationService.translate("basic.xlsx.description"),
      disabled: false
    },
    //This code is only hidden temporanenly
    /*
    {
      name: 'TCM connection',
      type: Dataset.TypeEnum.Tcm,
      description: 'Connect to TCM',
      imageName: 'ic-tcm-connections.svg'
    },
    {
      name: 'TDV view',
      type: Dataset.TypeEnum.Dv,
      description: 'Connect to a TDV view',
      imageName: 'ic-tdv-view.svg'
    }
 */   // end for This code is only hidden temporanenly
    /*,
    {
      name: 'Demo dataset',
      type: 'demo',
      description: 'Use a sample dataset',
      imageName: 'ic-demo-data.svg'
    }*/
  ]

  nameError = false;

  public nameChanged: Subject<any> = new Subject<any>();

  constructor(
    private catalogService: CatalogService,
    private translationService: TranslationService,

  ) { }

  ngOnInit(): void {
    this.translationService.currentLang$.subscribe(() => {
      this.namePlaceholder= this.translationService.translate("basic.placeholder.name")
      this.descriptionPlaceholder = this.translationService.translate("basic.placeholder.description")

    });
    if (this.wizard.editMode) {
      const type = (this.data as Dataset).type;
      this.wizard.dataSourceType = this.datatypes.find(dt => dt.type === type);

      // this.datatypes.forEach(dt => {
      //   if (dt.type !== type) {
      //     dt.disabled = true;
      //     dt.hint = 'You can\'t change your dataset type';
      //   }
      // })
    } else {
      this.catalogService.getConnections().subscribe(connections => {
        const typeMap: {[key: string]: boolean} = {};
        for (const connection of connections) {
          const type = connection.type;
          if (!typeMap[type]) {
            typeMap[type] = true;
          }
        }

        // for (let i = 1; i < this.datatypes.length - 1; i++) {
        //   if (!typeMap[this.datatypes[i].type]) {
        //     this.datatypes[i].disabled = true;
        //     this.datatypes[i].hint = 'You don\'t have any connections yet';
        //   }
        // }
      })
    }

    this.updateStatus();

    this.nameChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(
        concatMap(model => {
          const requestBody = {
            id: model.datasetId,
            name: model.value
          } as CheckExistDataset;
          return this.catalogService.checkDatasetName(requestBody);
        })
      ).subscribe(re => {
        if (re && re.exist === true) {
          this.nameError = true;
        } else {
          this.nameError = false;
        }
        this.updateStatus();
        // this.updateSaveStatus(!this.nameError);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['checkRequired']) {
      console.log('changes', changes);
      if(changes['checkRequired'].previousValue != undefined){
        this.checkFields();
      }
    }
  }

  checkFields() {
    let valid: number = 0;
    if(!this.nameError){
      valid++;
    }
    if(this.data.name){
      this.nameRequired = false;
      valid++;
    }else{
      this.nameRequired = true;
    }
    if(this.wizard?.dataSourceType?.type){
      valid++;
      this.typeRequired = false;
    }else{
      this.typeRequired = true;
    }
    if(valid == 3){
      this.checkRequired = false;
    }
  }

  handleUpdate = (event, fieldName: 'name' | 'description') => {
    const value = event.detail.value;
    this.data[fieldName] = value;
    this.updateStatus();

    if (fieldName === 'name' && value && value.trim() !== '') {
      this.nameChanged.next({
        value,
        datasetId: this.wizard.editMode ? (this.data as Dataset).id : null
      });
      this.nameRequired = false;
    }
  }

  // private updateSaveStatus(enabled: boolean) {
  //   this.enableSave.emit(enabled);
  // }

  public selectDatatype(type) {
    if (!this.wizard.editMode && !type.disabled) {
      this.wizard.dataSourceType = type;
      this.typeRequired = false;
      this.type.emit(type);
      this.updateStatus();
    }
  }

  private updateStatus() {
    const status = !!(this.data && this.data.name && !this.nameError && this.wizard.dataSourceType);
    const stepStatus = {
      step: 'dataset-basic-info',
      completed: status
    } as NewAnalysisStepStatus;

    this.status.emit(stepStatus);
  }

}
