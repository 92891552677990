import {cloneDeep} from 'lodash-es';
import {MConfig} from '../models_ui/analyticTemplate';
import {
  CreateTemplate,
  MenuConfigTemplate,
  VisualisationService,
  Template,
  UpdateTemplate,
} from '@tibco/discover-client-lib';
import { environment } from 'src/environments/environment';


export const DXP_EXISTS_MESSAGE = 'A DXP with this name already exists...';
export const TEMPLATE_EXISTS_MESSAGE = 'A TEMPLATE with this name already exists...';

// This function removes all disable menu-items from a menu configuration
export function stripDisabledMenuItems2(menu: MenuConfigTemplate[]): MenuConfigTemplate[] {
  const re = [];
  const tempMenu = cloneDeep(menu);
  if (tempMenu) {
    if (isIterable(tempMenu)) {
      tempMenu.forEach((mItem) => {
        if (mItem.child && mItem.child.length > 0) {
          mItem.child = stripDisabledMenuItems(mItem.child);
        }
        if (mItem.enabled) re.push(mItem);
      })
    }
  }
  return re;
}

export function stripDisabledMenuItems(menu: any[]): any[] {
  const re = [];
  const tempMenu = cloneDeep(menu);
  if (tempMenu) {
    if (isIterable(tempMenu)) {
      tempMenu.forEach((mItem) => {
        if (mItem.child && mItem.child.length > 0) {
          mItem.child = stripDisabledMenuItems(mItem.child);
        }
        if (mItem.enabled) re.push(mItem);
      })
    }
  }
  return re;
}

export function createReadableArrayString(array: string[], maxLength: number) {
  let moreChars = true;
  let textToDisplay = '';
  if (array) {
    for (let cMI = 0; cMI < array.length && moreChars; cMI++) {
      if (textToDisplay.length < maxLength) {
        textToDisplay += array[cMI] + ', ';
      } else {
        textToDisplay += 'and ' + (array.length - cMI) + ' more...';
        moreChars = false;
      }
    }
    if (textToDisplay.endsWith(', ')) {
      textToDisplay = textToDisplay.substring(0, textToDisplay.length - 2);
    }
  }
  return textToDisplay;
}

export function escapeCharsForJSON(str) {
  return str
    .replace(/[\\]/g, '\\\\')
    .replace(/[\"]/g, '\\\"')
    .replace(/[\/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t');
};


// Get the configured spotfire link
export function getSFLink(): string {
  // if (conf && conf.server && conf.server !== '') {
  //   return conf.server;
  // }
  // const urlLoc = window.location.href;
  // let region = 'eu.';
  // if (!(urlLoc.indexOf('eu.') > 0 || (urlLoc.indexOf('localhost') > 0))) {
  //   // Don't insert anything for US region.
  //   region = '';
  // }
  // if (window.location.host === 'discover.labs.cloud.tibco.com') {
  //   return 'https://eu.spotfire-next.cloud.tibco.com';
  // } else {
  //   return 'https://' + region + 'spotfire-next.cloud.tibco.com';
  // }
  return environment.spotfireURL;
}

export function createMConfig(inputMConfig: string): MConfig {
  let re: MConfig = null;
  if (inputMConfig && inputMConfig !== '') {
    const icArr = inputMConfig.split('.');
    if (icArr.length > 2) {
      re = {
        markingName: icArr[0],
        dataTable: icArr[1],
        columnName: icArr[2],
      }
    }
  }
  return re;
}

export function stringifyMConfig(mConfig: MConfig): string {
  let re = null;
  if (mConfig) {
    re = mConfig.markingName + '.' + mConfig.dataTable + '.' + mConfig.columnName;
  }
  return re;
}

export async function checkIfTemplateNameExists(name: string, currentId: string, visService: VisualisationService): Promise<boolean> {
  return new Promise((resolve) => {
    visService.getTemplates()
      .subscribe(aTemplates =>
        resolve(aTemplates.filter(temp => temp.id !== currentId && temp.name === name).length === 1));
  })
}
//
// export async function checkIfDXPExists(dxpName: string, visService: VisualisationService): Promise<boolean> {
//   return new Promise((resolve) => {
//     visService.getItems(['dxp']).pipe(
//       map((items: LibraryItem[]) => {
//           let DXPExist = false;
//           if (items) {
//             for (const sfObj of items) {
//               if (sfObj.itemType === 'spotfire.dxp') {
//                 if (sfObj.path === dxpName) {
//                   DXPExist = true;
//                 }
//               }
//             }
//           } else {
//             console.log('No existing DXPs...');
//           }
//           resolve(DXPExist);
//         }
//       )).subscribe();
//   });
// }

export function clearAllNodeFromDefault(data) {
  if (data) {
    if (isIterable(data)) {
      for (const node of data) {
        node.isDefault = false;
        if (node.child && node.child.length && typeof node.child === 'object') {
          clearAllNodeFromDefault(node.child);
        }
      }
    }
  }
}

function isIterable(value) {
  return Symbol.iterator in Object(value);
}

export function compareTemplates(tempA: Template, tempB: Template) {
  if (tempA.type === 'User defined' && tempB.type === 'User defined') {
    if (tempA.name < tempB.name) {
      return -1
    }
    if (tempA.name > tempB.name) {
      return 1
    }
  }
  if (tempA.type === 'User defined') {
    return 1
  }
  if (tempB.type === 'User defined') {
    return -1
  }
  if (tempA.type < tempB.type) {
    return -1
  }
  if (tempA.type > tempB.type) {
    return 1
  }
  // types are the same
  if (tempA.type === 'General') {
    if (tempA.name === 'Discovery') {
      return -1
    }
  }
  if (tempB.type === 'General') {
    if (tempB.name === 'Discovery') {
      return 1
    }
  }
  if (tempA.name < tempB.name) {
    return -1
  }
  if (tempA.name > tempB.name) {
    return 1
  }
  return 0
}

export function convertTemplateToCreateTemplate(template: Template): CreateTemplate {
  // id is stripped
  return {
    name: template?.name,
    description: template?.description,
    type: template?.type,
    splash: template?.splash,
    icon: template?.icon,
    analysisFileLocation: template?.analysisFileLocation,
    menuConfig: template?.menuConfig,
    filters: template?.filters,
    enabled: template?.enabled,
    marking: template?.marking,
    previewParameters: template?.previewParameters
  }
}

export function convertCreateTemplateToUpdate(createTemplate: CreateTemplate, id: string): UpdateTemplate {
  return {
    id,
    name: createTemplate?.name,
    description: createTemplate?.description,
    type: createTemplate?.type,
    splash: createTemplate?.splash,
    icon: createTemplate?.icon,
    analysisFileLocation: createTemplate?.analysisFileLocation,
    menuConfig: createTemplate?.menuConfig,
    filters: createTemplate?.filters,
    enabled: createTemplate?.enabled,
    marking: createTemplate?.marking,
    previewParameters: createTemplate?.previewParameters,
    //  TODO:  Add source path?
    // sourceId: createTemplate?.sourceId,
    // destinationFolderId: createTemplate?.destinationFolderId,
  }
}
