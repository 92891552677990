import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-content',
  templateUrl: './snack-bar-content.component.html',
  styleUrls: ['./snack-bar-content.component.scss']
})
export class SnackBarContentComponent {
  icon: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<SnackBarContentComponent>
  ) {
    switch (data.type) {
      case 'success':
        this.icon = 'check_circle'; // Usa icone di Material Design
        break;
      case 'warn':
        this.icon = 'warning';
        break;
      case 'error':
        this.icon = 'error_outline';
        break;
      default:
        this.icon = '';
    }
  }

  // Metodo per chiudere la snackbar
  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  get typeClass(): string {
    return `snack-bar-${this.data.type}`; // Restituisce una stringa come 'snack-bar-success'
  }
}
