import {
  Component, Input, OnInit
} from '@angular/core';
import { DatasetWizard } from 'src/app/models_ui/dataset';

@Component({
  selector: 'dataset-data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['../../common/tibco-table.ux.css', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss', './data-preview.component.scss']
})
export class NewDatasetDataPreviewComponent implements OnInit {

  @Input() previewColumns: any[];
  @Input() previewData: any[];
  @Input() wizard: DatasetWizard;
  // dataSource can be file, connection, etc. which will be use to show the loading indicator after the
  // the data source is assigned before the preview data is fully loaded.
  @Input() loading: boolean;

  constructor() {}


  ngOnInit(): void {
  }

}
