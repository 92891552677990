import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../environments/endpoints';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  constructor(private http: HttpClient) {}

  uploadDiagram(analysisId: string, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(endpoints.documentation.diagramUpload+analysisId, formData, {
      observe: 'response',
      headers: new HttpHeaders({
        'Accept': 'application/json, text/plain'
      }),
      withCredentials: true,
      reportProgress: true,
    });
  }
}
