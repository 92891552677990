import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UxplPrimaryNav } from '@tibco/tc-web-components/dist/types/components/uxpl-primary-nav/uxpl-primary-nav';
// import {unionBy} from 'lodash-es';
// import {ConfigurationService} from 'src/app/service/configuration.service';
import { InternalMessageService } from '../../service/internal-message.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WhatsNewComponent } from '../../components/common/whats-new/whats-new.component';
import { WhatsNewService } from '../../service/whats-new.service';
import { MsalService } from '@azure/msal-angular';
// import { containsObject } from 'src/app/functions/common';
import { CookieService } from 'ngx-cookie-service';
import { Organization, UserInfoResponse } from 'src/app/models_ui/auth';
import { containsObject } from 'src/app/functions/common';
import { TranslationService } from 'src/app/service/translate.service';
import { environment } from 'src/environments/environment';
import { ReleaseNotesComponent } from 'src/app/components/common/release-notes/release-notes.component';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.css'],
})
export class DiscoverComponent implements OnInit, AfterViewInit {

  releaseVersion: string;
  processAnalysisTabObj = { id: 'process-analysis', label: 'Process Analysis' };
  analyticsTabObj = { id: 'analytics', label: 'Visualizations' };
  templatesTabObj = { id: 'templates', label: 'Templates' };
  investigationsTabObj = { id: 'investigations', label: 'Investigations' };
  datasetTabObj = { id: 'dataset', label: 'Datasets' };
  settingsTabObj = { id: 'settings', label: 'Settings' };
  adminTabObj = { id: 'admin', label: 'Admin' };

  userinfo: UserInfoResponse;
  userRole: string;
  userDisplayName: string = '';
  orgInfo: Organization = {} as Organization;

  @ViewChild('primaryNav', { static: false }) nav: ElementRef<UxplPrimaryNav>;
  public tabs;

  public hideAnalytics: boolean;
  public analysisID: string;
  superadmin: boolean = false;
  dialogRef: MatDialogRef<WhatsNewComponent, any>;
  supportUrl: string = '';
  docsUrl: string = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    // private configService: ConfigurationService,
    private messageService: InternalMessageService,
    private whatsNewService: WhatsNewService,
    private authService: MsalService,
    private cookieService: CookieService,
    private translationService: TranslationService,

  ) {
    this.releaseVersion = environment.version;

    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
      if (this.userinfo.firstName && this.userinfo.lastName) {
        this.superadmin = this.userinfo.superadmin;
        this.userDisplayName =
          this.userinfo.firstName +
          ' ' +
          this.userinfo.lastName.substring(0, 1);
      }
    }
    // Get OrgInfo
    this.orgInfo = JSON.parse(localStorage.getItem('org'));
    if (this.cookieService.check('userRole')) {
      this.userRole = this.cookieService.get('userRole');
    } else {
      this.router.navigate(['/select-organization']);
    }
    this.tabs = this.generateMenu();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // Set the right tab
        if (this.nav && this.nav.nativeElement) {
          for (const tab of this.nav.nativeElement.tabs) {
            if (val.url.includes(tab.id)) {
              this.nav.nativeElement.setTab(tab, true);
            }
          }
        }
        if (val.url.startsWith('/discover/analytics')) {
          this.hideAnalytics = false;
          this.analysisID = undefined;
          window.setTimeout(() => {
            // Check if there are no parameters specified
            if (val.url.indexOf('?') > 0) {
              this.analysisID = val.url.slice(
                val.url.lastIndexOf('/') + 1,
                val.url.lastIndexOf('?')
              );
            } else {
              this.analysisID = val.url.slice(val.url.lastIndexOf('/') + 1);
            }
            if (this.analysisID === 'analytics') {
              this.analysisID = '';
            }
          });
        } else {
          this.hideAnalytics = true;
        }
      }
    });
    if (this.router.url === '/discover') {
      this.router.navigate(['/discover/' + this.tabs[0].id]);
    }

    // console.log(this.configService.config.discover.navBarMessages);
    /* TODO: remove this to fix notifications
    if (this.configService?.config?.discover?.messages && this.configService?.config?.discover?.messages.length > 0) {
      for (const not of this.configService?.config?.discover?.messages) {
        console.log('Sending notification: ', not)
        this.messageService.sendMessage('navbar.message', JSON.stringify(not));
      }
    }
    */
    this.messageService
      .getMessage('set-main-tab.topic.message')
      .subscribe((message) => {
        // console.log('Got tab message: ', message)
        if (message.text && message.text !== '' && message.text !== 'init') {
          if (this.nav?.nativeElement) {
            const tab = this.getTab(message.text);
            // console.log('setting tab: ', tab)
            this.nav.nativeElement.setTab(tab, true);
            // this.messageService.sendMessage('set-main-tab.topic.message', 'process-analysis');
            // this.messageService.sendMessage('set-main-tab.topic.message', 'dataset');
          }
        }
      });

    if(environment.supportUrl){
      this.supportUrl = environment.supportUrl;
    }
    if(environment.docsUrl){
      this.docsUrl = environment.docsUrl;
    }
  }

  updateMenu(){
    this.processAnalysisTabObj.label = this.translationService.translate('discover.menu.processAnalysis');
    this.analyticsTabObj.label = this.translationService.translate('discover.menu.visualizations');
    this.templatesTabObj.label = this.translationService.translate('discover.menu.templates');
    this.investigationsTabObj.label = this.translationService.translate('discover.menu.investigation')
    this.datasetTabObj.label = this.translationService.translate('discover.menu.datasets')
    this.settingsTabObj.label = this.translationService.translate('discover.menu.settings')
    this.adminTabObj.label = this.translationService.translate('discover.menu.admin')
  }

  public generateMenu = (): any[] => {
    let tabs = [];
    if (
      this.userRole == 'Analyst' ||
      this.userRole == 'Admin' ||
      this.userRole == 'Superadmin'
    ) {
      if (!containsObject(this.processAnalysisTabObj, tabs))
        tabs.push(this.processAnalysisTabObj);
      if (!containsObject(this.analyticsTabObj, tabs))
        tabs.push(this.analyticsTabObj);
      if (!containsObject(this.templatesTabObj, tabs))
        tabs.push(this.templatesTabObj);
      if (!containsObject(this.datasetTabObj, tabs))
        tabs.push(this.datasetTabObj);
      // if(!containsObject(this.investigationsTabObj, tabs)) tabs.push(this.investigationsTabObj);
    }
    if (this.userRole == 'Admin' || this.userRole == 'Superadmin') {
      // if(!containsObject(this.settingsTabObj, tabs)) tabs.push(this.settingsTabObj);
      // if(!containsObject(this.adminTabObj, tabs)) tabs.push(this.adminTabObj);
    }
    if (this.userRole == 'Superadmin') {
    }
    return tabs;
  };

  ngOnInit(): void {
    const checkReleaseNotes = this.cookieService.get('checkReleaseNotes');
    if(!checkReleaseNotes || checkReleaseNotes != "true"){
      this.handleReleaseNotes();
    }
    this.translationService.currentLang$.subscribe(()=> {
      this.updateMenu();
    });
  }

  ngAfterViewInit() {
    const entryId = this.router.url.split('/')[2];
    // Added this to prevent no ID found from /discover/new-template
    if (entryId) {
      let tab = this.getTab(entryId);
      // This is done so on refreshing the page the right tab on the top get's selected
      if (!tab) {
        if (
          entryId === 'new-analysis' ||
          entryId === 'edit-analysis' ||
          entryId === 'templates' ||
          entryId === 'edit-template' ||
          entryId === 'new-template'
        )
          tab = this.getTab('process-analysis');
        if (entryId === 'connections') tab = this.getTab('dataset');
        if (entryId === 'investigation-details')
          tab = this.getTab('investigations');
        if (entryId === 'select-template') tab = this.getTab('analytics');
      }
      if (tab) {
        this.nav.nativeElement.setTab(tab, true);
      }
    }
    this.openWhatsNew();
  }

  private openWhatsNew() {
    // console.log('Show items:  ', this.whatsNewService.getShowItems())
    // console.log('   Do Show:  ', this.whatsNewService.doShow())
    if (
      this.whatsNewService.getShowItems().length > 0 &&
      this.whatsNewService.doShow()
    ) {
      this.dialogRef = this.dialog.open(WhatsNewComponent, {
        width: '100%',
        height: '80%',
        data: {
          newItems: this.whatsNewService.getShowItems(),
        },
      });
    }
  }

  private getTab(id: string) {
    return this.nav.nativeElement.tabs.find((e) => e.id === id);
  }

  handleClick = (event): void => {
    if (event?.detail?.id) {
      let endpoint = event.detail.id;
      if (
        endpoint === 'analytics' &&
        this.analysisID &&
        this.analysisID !== ''
      ) {
        endpoint += '/' + this.analysisID;
      }
      this.router.navigate(['/discover/' + endpoint]);
    }
  };

  logout() {
    this.authService.logoutRedirect();
  }

  handleReleaseNotes() {
    const language = localStorage.getItem('language');
    const dialogRef = this.dialog.open(ReleaseNotesComponent, {
      width: '420px',
      height: 'auto',
      data: language,
      panelClass: 'release-notes-panel'
  });

    dialogRef.afterClosed().subscribe(() => {
      this.cookieService.set('checkReleaseNotes', 'true', {
        path: '/',
      });
    });
  }
}
