import {Injectable} from '@angular/core';
import {Configuration} from '../models_ui/configuration';
import {
  ConfigurationService as ConfigurationServiceMS,
  DiscoverConfiguration
} from '@tibco/discover-client-lib';
import {HttpClient} from '@angular/common/http';
import {AdminMenuEntry, LocalAppConfig} from '../models_ui/admin';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configuration: Configuration;
  private _adminMenu: AdminMenuEntry[];

  constructor(
    private configurationMS: ConfigurationServiceMS,
    private http: HttpClient
  ) {
  }

  get config() {
    return this.configuration;
  }

  get adminMenu() {
    return this._adminMenu;
  }

  public async readConfig(): Promise<Configuration> {
    if (!this.configuration) {
      this.configuration = {} as Configuration;
      let whoAmI;
      try {
        whoAmI = await this.configurationMS.getWhoAmI().toPromise();
      } catch (e) {
        console.error('Error getting Identity: ', e)
      }
      // JS: this will cause this code to fail if user has no config
      /*if (whoAmI && !whoAmI.isUser) {
        return Promise.resolve(this.configuration);
      }*/

      this.configuration.user = whoAmI;
      this.configuration.uiAppId = 'discoverapp';
      const discoverConfig = await this.configurationMS.getConfiguration().toPromise();
      if (discoverConfig) {
        await this.getAdminMenu();
        this.configuration.discover = discoverConfig as DiscoverConfiguration;
        return Promise.resolve(this.configuration);
      }
      return Promise.resolve(this.configuration);
    } else {
      return Promise.resolve(this.configuration);
    }
  }

  public refresh = (): Promise<Configuration> => {
    this.configuration = undefined;
    return this.readConfig();
  }

  private async getAdminMenu(): Promise<void> {
    const fileContent = (await this.http.get('assets/config/appConfig.json').toPromise()) as LocalAppConfig;
    this._adminMenu = fileContent.config.adminMenu;

  }
}
