<table class="investigation-detail-table">
  <tr class="cell-row" *ngFor="let row of transpose">
    <td *ngFor="let field of row" class="case-details-cell">
      <div class="case-details-cell-wrappr" *ngIf="field">
        <div class="cell-header-main">{{field.label}}</div>
        <div class="cont-detail"
             matTooltip="{{getToolTip(field.field, field.format)}}" matTooltipClass="tTip"
             [innerHTML]="getFieldValue(field.field, field.format)"></div>
      </div>
      <div *ngIf="!field">
        <div></div>
      </div>
    </td>
  </tr>
  <tr class="cell-row" *ngIf="investigationContext?.processAnalysis">
    <td class="case-details-cell">
      <div class="case-details-cell-wrappr">
        <div class="cell-header-main">Process Analysis Name</div>
        <div matTooltip="View process analysis details" matTooltipClass="tTip" class="cont-detail-link cont-detail"
             (click)="openAnalysis()"
        >{{investigationContext.processAnalysis.name}}</div>
      </div>
    </td>
    <td class="case-details-cell">
      <div class="case-details-cell-wrappr">
        <div class="cell-header-main">Process Analysis Id</div>
        <div class="inline-icon">{{investigationContext.processAnalysis.id}}
          <uxpl-icon class="copy-icon" height="15px" width="15px" icon="pl-icon-copy" matTooltip="Copy process analysis id to clipboard"
                     matTooltipClass="tTip"
                     (click)="copyToClipBoard('Id', investigationContext.processAnalysis.id,msService)"></uxpl-icon>
        </div>
      </div>
    </td>
  </tr>
  <tr class="cell-row" *ngIf="investigationContext?.dataset">
    <td class="case-details-cell">
      <div class="case-details-cell-wrappr">
        <div class="cell-header-main">Dataset Name</div>
        <div matTooltip="View dataset details" matTooltipClass="tTip" class="cont-detail-link cont-detail"
             (click)="openDataset()"
        >{{investigationContext.dataset.name}}</div>
      </div>
    </td>
    <td class="case-details-cell">
      <div class="case-details-cell-wrappr">
        <div class="cell-header-main">Dataset Id</div>
        <div class="inline-icon">{{investigationContext.dataset.id}}
          <uxpl-icon class="copy-icon" height="15px" width="15px" icon="pl-icon-copy" matTooltip="Copy dataset id to clipboard"
                     matTooltipClass="tTip"
                     (click)="copyToClipBoard('Id', investigationContext.dataset.id,msService)"></uxpl-icon>
        </div>
      </div>
    </td>
  </tr>
</table>
