import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ObjectHeaderConfig } from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import { CookieService } from 'ngx-cookie-service';
import { alphaNumericSpaceValidator } from '../../functions/form';
import {
  CreateOrgResponse,
  Organization,
  UserInfoResponse,
} from 'src/app/models_ui/auth';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-org',
  templateUrl: './create-org.component.html',
  styleUrls: ['./create-org.component.scss'],
})
export class CreateOrgComponent implements OnInit {
  isLoading: boolean = false;
  createOrgBody: Partial<CreateOrg>;
  mode: string = 'create';
  newOrgForm: FormGroup;
  objHeaderConfig: ObjectHeaderConfig;
  userDisplayName: string = '';
  tabs;
  userinfo: UserInfoResponse;
  orgInfo: Organization = {} as Organization;
  orgResponse: CreateOrgResponse;
  currentLanguage: string;

  constructor(
    private authService: MsalService,
    private route: Router,
    private fb: FormBuilder,
    private userService: UsersService,
    private snackBarService: SnackbarService,
    private cookieService: CookieService,
    private translationService: TranslationService,
    private location: Location
  ) {
    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
      if (this.userinfo.firstName && this.userinfo.lastName) {
        this.userDisplayName =
          this.userinfo.firstName +
          ' ' +
          this.userinfo.lastName.substring(0, 1);
      }
      this.newOrgForm = this.fb.group({
        name: ['', [Validators.required, alphaNumericSpaceValidator()]],
        description: ['', [Validators.required, alphaNumericSpaceValidator()]],
      });
    }
    this.tabs = this.generateMenu();
  }
  public generateMenu = (): any[] => {
    let tabs = [];

    return tabs;
  };

  ngOnInit(): void {
    this.createOrgBody = {};
    this.createObjectHeader();
    this.currentLanguage = this.translationService.getCurrentLanguage();
    this.translationService.currentLang$.subscribe(lang => {
      this.currentLanguage = getLanguageCode(lang);
      this.createObjectHeader();
    });
  }

  goBack(): void {
    this.location.back();
  }
  private createObjectHeader = (): void => {
    const createTitle = this.translationService.translate('createOrg.createNewOrganization');
    const editTitle = this.translationService.translate('createOrg.editUser');
    const editTitle2 = this.translationService.translate('createOrg.updateUser');
  
    this.objHeaderConfig = {
      title: {
        value:
          this.mode === 'create'
            ? createTitle
            : editTitle + editTitle2,
        isEdit: false,
        editing: false,
      },
    };
  };
  onSubmit(): void {
    if (this.newOrgForm.valid) {
      const orgData = this.newOrgForm.value;
      this.isLoading = true;
      this.userService.createOrg(orgData).subscribe(
        (res) => {
          this.orgResponse = res;
          this.isLoading = false;
          this.snackBarService.openSnackBar(this.translationService.translate('create.org.snackBar.created'), this.translationService.translate('create.org.snackBar.close'),'success');
          const ordIdSanitize = res.body.organizationId.replace(/-/g, '');
          this.route.navigate(['/administration/'+ordIdSanitize+'/statistics']);
        },
        (error) => {
          this.isLoading = false;
          let message = this.translationService.translate('create.org.snackBar.error');
          if(error.status == 409){
            message = this.translationService.translate('create.org.snackBar.error.same.name');
          }
          this.snackBarService.openSnackBar(
            message,
            this.translationService.translate('create.org.snackBar.close'),
            'error',
            6000
          );
          console.error('Error occurred while sending user data:', error);
        }
      );
    } else {
      this.newOrgForm.markAllAsTouched();
    }
  }

  handleClick(event: any) {
    console.log(event);
  }

  logout() {
    this.authService.logoutRedirect();
  }

  changeLanguage(lang: string): void {
    this.translationService.changeLanguage(lang);
  }
}

function getLanguageCode(lang) {
  const languagePart = lang.split('-')[0];
  return languagePart.toUpperCase();
}

export interface CreateOrg {
  name: string;
  description: string;
}
