<!--<pre>{{statusMap | json}}</pre>-->
<div class="page-container container-flex-fluid">
  <div class="flex">
    <div class="flex-item">
      <div class="data-title-container">
        <div class="data-title">
          <div class="page-title">{{ 'manage.datasets.title' | translate }}</div>
          <uxpl-form-field class="dataset-search-widget" type="text" consise valid [label]="placeholderSearch"
                          (uxplChanged)="handleSearch($event);">
            <uxpl-icon class="dataset-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
          </uxpl-form-field>
        </div>
        <div class="data-title">
          <div class="data-title">
            <button mat-stroked-button color="primary" (click)="refresh()">
              <mat-icon class="material-symbols-rounded">refresh</mat-icon>
              {{ 'manage.datasets.button.refresh' | translate }}
            </button>
            <!-- <button mat-stroked-button color="primary" (click)="showConnections()">
              <mat-icon class="material-symbols-rounded">hub</mat-icon>
              {{ 'manage.datasets.button.connections' | translate }}
            </button> -->
            <button mat-flat-button color="primary" (click)="newDatasetDialog()">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
              {{ 'manage.datasets.button.dataset' | translate }}
            </button>
            <!-- <uxpl-button class="secondary" type="no-border" icon="assets/images/svg/pl-icon-refresh.svg"
                        (clicked)="refresh()" width="100">
              <div class="icon-button secondary-icon-button">{{ 'manage.datasets.button.refresh' | translate }}</div>
            </uxpl-button> -->
            <!-- <uxpl-button class="secondary" type="secondary" (clicked)="showConnections()">
              <div class="icon-button secondary-icon-button">{{ 'manage.datasets.button.connections' | translate }}</div>
            </uxpl-button> -->
            <!-- <uxpl-button class="primary" (clicked)="newDatasetDialog()" icon="assets/images/svg/pl-icon-add-circle.svg">
              <div class="icon-button">{{ 'manage.datasets.button.dataset' | translate }}</div>
            </uxpl-button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex-item dataset-container">
      <!-- <div class="quick-filter">
        hidden temp
          
          
          <div class="filter-title">Filter by type:</div>
        <div *ngFor="let filterOption of filterOptions"
            [ngClass]="{'filter-option': true, 'option-selected': filterOption?.active }"
            (click)="setFilter(filterOption.type)">{{filterOption?.label}}</div>
      </div> -->
      <mat-card>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        <mat-card-content>
          <div class="data-wrapper datasets" *ngIf="isLoading || (dsTableData && dsTableData.length>0); else nodata">
            <p-table #datasetsTable [columns]="cols" [value]="dsTableData" [globalFilterFields]="['name', 'description']"
                    dataKey="name" id="pTable" styleClass="custom-table-css" [rowHover]="false"
                    [rows]="15" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                    loadingIcon="tibco-spinner-icon" [filterDelay]="0" [expandedRowKeys]="expandedRows">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width: 40px" class="top-row-empty"></th>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="top-row tibco-top-row"
                      [ngClass]="col.class">
                    <div class="header-container">
                      {{col.header}}
                      <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </div>
                  </th>
                  <th 
                    style="width: 50px; text-align: center;" 
                    class="tibco-top-row top-row"
                    matTooltipClass="custom-tooltip" 
                  >
                    <div class="table-th-wrap" style="justify-content: center; align-items: center;">
                      {{ "actions" | translate }}<mat-icon id="actions-info-btn" class="material-symbols-rounded" style="font-size: 14px;line-height: 14px;height: 14px;" (click)="openDatasetDictionary()">info</mat-icon>
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dataset let-expanded="expanded">
                <tr class="rowhover" id="toHightlight" (dblclick)="edit(dataset, $event)">
                  <td class="no-padding hovereffect toggle-item" style="width: 40px !important;">
                    <a class="tib-toggle-icons" href="#" [pRowToggler]="dataset">
                      <mat-icon *ngIf="!expanded" class="material-symbols-rounded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="expanded" class="material-symbols-rounded">arrow_drop_up</mat-icon>
                    </a>
                  </td>
                  <td class="no-padding data-row hovereffect" (click)="dt.toggleRow(dataset, $event)">
                    <div class="dataset-type-block" *ngIf="dataset.type != 'file'">
                      {{dataset.type | uppercase}}
                    </div>
                    <div class="dataset-type-block" *ngIf="dataset.type == 'file'">CSV</div>
                  </td>
                  <td class="no-padding data-row hovereffect actionable-data name-data" (click)="dt.toggleRow(dataset, $event)">
                    <div [innerHTML]="dataset.name | highlight: searchTerm : 'Multi-Match'" (click)="edit(dataset, $event)"></div>
                  </td>
                  <td class="no-padding data-row hovereffect" (click)="dt.toggleRow(dataset, $event)">
                    <div>{{dataset.metadata.lastGenerated | relativeDate: currentLanguage}}</div>
                  </td>
                  <!-- <td class="no-padding data-row hovereffect" (click)="dt.toggleRow(dataset, $event)">
                    <div>{{dataset.size}}</div>
                  </td> -->
                  <td class="no-padding data-row hovereffect data-status" (click)="dt.toggleRow(dataset, $event)">
                    <div class="action-holder">
                      <uxpl-tag *ngIf="!statusMap[dataset.id] || !statusMap[dataset.id].inProcess"
                                [icon]="dataset._statusIcon?.name" class="uxpl-tag"
                                [color]="dataset._statusIcon?.color">{{dataset.metadata?.dataCollectionStatus ? dataset.metadata?.dataCollectionStatus : dataset.metadata.status | translate}}</uxpl-tag>
                      <mini-progress-bar *ngIf="statusMap[dataset.id] && statusMap[dataset.id].inProcess"
                                        [percentage]="statusMap[dataset.id].percentage"
                                        [message]="statusMap[dataset.id].message"></mini-progress-bar>
                      <uxpl-button *ngIf="dataset?._rules.canPause" type="no-border" class="action-button" height="25px" width="70px" (click)="pause(dataset, $event)"><div class="action-button-content"><uxpl-icon icon-content='<svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Artboard" transform="translate(-123.000000, -111.000000)" fill="currentColor"><path d="M132.215967,111 C137.301414,111 141.433735,115.0239 141.433735,120 C141.433735,124.9761 137.301414,129 132.215967,129 C127.13142,129 123,124.9761 123,120 C123,115.0239 127.13142,111 132.215967,111 Z M132.432835,113.024096 C128.336874,113.024096 125.024096,116.249155 125.024096,120.216867 C125.024096,124.18458 128.336874,127.409639 132.432835,127.409639 C136.530596,127.409639 139.843373,124.18458 139.843373,120.216867 C139.843373,116.249155 136.530596,113.024096 132.433735,113.024096 L132.432835,113.024096 Z M130.987952,116.566265 C131.207535,116.566265 131.385542,116.970827 131.385542,117.46988 L131.385542,122.891566 C131.385542,123.390619 131.207535,123.795181 130.987952,123.795181 L128.60241,123.795181 C128.382827,123.795181 128.204819,123.390619 128.204819,122.891566 L128.204819,117.46988 C128.204819,116.970827 128.382827,116.566265 128.60241,116.566265 L130.987952,116.566265 Z M135.759036,116.566265 C135.978619,116.566265 136.156627,116.970827 136.156627,117.46988 L136.156627,122.891566 C136.156627,123.390619 135.978619,123.795181 135.759036,123.795181 L133.373494,123.795181 C133.153911,123.795181 132.975904,123.390619 132.975904,122.891566 L132.975904,117.46988 C132.975904,116.970827 133.153911,116.566265 133.373494,116.566265 L135.759036,116.566265 Z" id="Shape"></path></g></g></svg>' color="#727272" size="18px"
                        ></uxpl-icon>{{ 'manage.datasets.button.pause' | translate }}</div></uxpl-button>
                      <uxpl-button *ngIf="dataset?._rules.canStart" type="no-border" class="action-button" height="25px" width="70px" (click)="start(dataset, $event)"><div class="action-button-content"><uxpl-icon icon-content='<svg width="21px" height="20px" id="pl-icon-start" fill="currentColor" viewBox="0 0 21 20" version="1.1"><path d="M10.23,0 C15.875,0 20.462,4.471 20.462,10 C20.462,15.529 15.875,20 10.23,20 C4.586,20 0,15.529 0,10 C0,4.471 4.586,0 10.23,0 Z M10.23,2 C5.68,2 2,5.587 2,10 C2,14.413 5.68,18 10.23,18 C14.782,18 18.462,14.413 18.462,10 C18.462,5.587 14.782,2 10.231,2 L10.23,2 Z M9.088,5.466 L14,9.8 L9.088,14.134 C8.89397022,14.3049716 8.61750716,14.3457732 8.38235987,14.2381415 C8.14721259,14.1305097 7.99740764,13.8945964 7.99996689,13.636 L7.99996689,5.963 C7.99996689,5.396 8.664,5.09 9.088,5.466 Z" id="Shape"/></svg>' color="#727272" size="18px"></uxpl-icon>Start</div></uxpl-button>
                    </div>
                  </td>
                  
                  <td class="no-padding hovereffect" style="width: 30px !important">
                    <div class="table-action-section">
                      <button mat-stroked-button
                        color="primary"
                        class="small-button"
                        [matTooltip]="'manage.datasets.button.edit' | translate"
                        matTooltipClass="custom-tooltip"
                        [disabled]="!dataset?._rules.canEdit"
                        (click)="edit(dataset, $event)">
                        <mat-icon class="material-symbols-rounded">edit</mat-icon>
                      </button>
                      <button mat-stroked-button
                        color="primary"
                        class="small-button"
                        *ngIf="dataset.metadata.status != 'Archived'"
                        [disabled]="dataset.usedBy.length > 0"
                        [matTooltip]="archiveTooltip"
                        matTooltipClass="custom-tooltip"
                        (click)="archiveDataset(dataset, $event)">
                        <mat-icon class="material-symbols-rounded">archive</mat-icon>
                      </button>
                      <button mat-stroked-button
                        color="primary"
                        class="small-button"
                        *ngIf="dataset.metadata.status == 'Archived'"
                        [matTooltip]="unarchiveTooltip"
                        matTooltipClass="custom-tooltip"
                        (click)="unarchive(dataset, $event)">
                        <mat-icon class="material-symbols-rounded">unarchive</mat-icon>
                      </button>
                      <button mat-stroked-button color="error" class="small-button" [disabled]="!(dataset._rules?.canDelete && dataset.metadata.status=== 'Archived')" [matTooltip]="deleteTooltip" matTooltipClass="custom-tooltip" (click)="openRemoveDatasetDialog(dataset, $event)">
                        <mat-icon class="material-symbols-rounded">delete</mat-icon>
                      </button>
                      
                      <button mat-stroked-button class="small-button" [matMenuTriggerFor]="datasetTableMenu"> 
                        <mat-icon>more_vert</mat-icon>
                        <mat-menu #datasetTableMenu="matMenu" xPosition="before">
                          <!-- <button mat-menu-item *ngIf="dataset?.metadata?.status === 'Processing'" (click)="abortProcessingDataset(dataset)">
                            <span>{{'manage.datasets.label.abort' | translate}}</span>
                          </button> -->
                          <button mat-menu-item *ngIf="dataset?.metadata?.status !== 'Processing'" (click)="reprocessDataset(dataset)">
                            <span>{{'manage.datasets.label.regenerate' | translate}}</span>
                          </button>
                          <button mat-menu-item *ngIf="dataset?.metadata?.status === 'Ready'" (click)="exportDataset(dataset)">
                            <span>{{'manage.datasets.label.export.csv' | translate}}</span>
                          </button>
                          <button mat-menu-item *ngIf="dataset?.metadata?.status === 'Ready' && dataset.type != 'xes'" (click)="openExportDatasetXesDialog(dataset)">
                            <span>{{'manage.datasets.label.export.xes' | translate}}</span>
                          </button>
                          <button mat-menu-item *ngIf="dataset?.metadata?.status === 'Ready' && dataset.type == 'xes'" (click)="downloadXes(dataset)">
                            <span>{{'manage.datasets.label.export.xes' | translate}}</span>
                          </button>
                          <button mat-menu-item *ngIf="dataset?.metadata?.status === 'Ready'" (click)="exportXlsxDataset(dataset)">
                            <span>{{'manage.datasets.label.export.xlsx' | translate}}</span>
                          </button>
                        </mat-menu>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-dataset let-columns="columns">
                <tr style="background-color: #FFFFFF">
                  <td class="addl-info-section" [attr.colspan]="columns.length + 3">
                    <div class="ui-g ui-fluid" style="font-size:16px">
                      <dataset-details [dataset]="dataset" [messageLength]="messageLength"></dataset-details>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-card-content>
      </mat-card>

      <div id="popup-host" [ngStyle]="{ 'top': popupY , 'left': popupX}" class="popup-wrapper" style="position: absolute;">
        <uxpl-popup 
        #deletePopup 
        id="deletepopup" 
        slot="popup" 
        x-position="after"
        y-position="above"
                    content-id="delete-action-confirm" max-width="328px" [maxHeight]="'190px'">
          <!-- section to show if manage-datasets is used -->
          <div *ngIf="datasetOnAction && datasetOnAction.usedBy?.length > 0" class="list-used-pa">
            <div class="popup-text">{{ 'manage.datasets.message.used' | translate }}</div>
            <div *ngFor="let ds of datasetOnAction.usedBy" class="popup-text">
              <strong>{{ds.name}}</strong>
            </div>
            <div class="popup-text">{{ 'manage.datasets.message.before' | translate }}</div>
            <uxpl-button (clicked)="cancelDelete()">Ok</uxpl-button>
          </div>
          <!-- section to confirm if a manage-datasets really needs to be deleted -->
          <action-confirm *ngIf="datasetOnAction" id="delete-action-confirm"
                          confirmQuestion="Are you sure you want to archive dataset '{{datasetOnAction.name}}'? It can't be undone."
                          doable="true" type="delete" rejectMessage="You can not archive dataset"
                          yesBtnLabel="Yes, archive it" noBtnLabel="No" (actionConfirmed)="handleConfirmation($event)"
                          ></action-confirm>
        </uxpl-popup>
      </div>
      <ng-template #nodata>
        <div class="center" style="padding: 30px 0;">
          <div class="img-center">
            <img width="204" src="assets/images/png/no-data.png">
          </div>
          <div *ngIf="dsTableData?.length==0">
            <div class="nodata-msg" style="margin-top: 15px">
              {{ 'manage.datasets.message.part1' | translate }}<br> {{ 'manage.datasets.message.part2' | translate }} <span class="hyperlink" (click)="newDatasetDialog()">{{ 'manage.datasets.message.part3' | translate }}</span>{{ 'manage.datasets.message.part4' | translate }}
            </div>
          </div>
          <div *ngIf="!datasets" class="nodata-msg">{{ 'manage.datasets.message.noData' | translate }}</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
