import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ObjectHeaderConfig } from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import { IconSidebar } from 'src/app/components/common/icon-sidebar/icon-sidebar.component';
import { Languages } from 'src/app/models_ui/languages';
import { CreateUser } from 'src/app/models_ui/users';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';
import { LANGUAGES } from 'src/environments/environment';

@Component({
  selector: 'control-panel-add-user',
  templateUrl: './control-panel-add-user.component.html',
  styleUrls: ['./control-panel-add-user.component.css']
})
export class ControlPanelAddUserComponent implements OnInit {

  iconSidebarData: IconSidebar = {
    "main": [{
      "label": "Control panel",
      "icon": "dashboard",
      "link": "/discover/control-panel",
      "routerLinkActiveExact": true 
    },{
      "label": "Users",
      "icon": "group",
      "link": "/discover/control-panel/users",
      "routerLinkActiveExact": true 
    },{
      "label": "Add user",
      "icon": "person_add",
      "link": "/discover/control-panel/add-user",
      "routerLinkActiveExact": true 
    }]
  };

  creatingUser: boolean = false;
  createUserBody: CreateUser;
  mode: string = 'create';
  userForm: FormGroup;
  objHeaderConfig: ObjectHeaderConfig;
  roles: string[] = [];
  languages: Languages[] = LANGUAGES;
  constructor(
    private route: Router,
    private fb: FormBuilder,
    private userService: UsersService,
    private snackBarService: SnackbarService,
    private translationService: TranslationService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userService
      .getRoles()
      .subscribe((res: string[]) => (this.roles = res));
    this.createUserBody = {} as CreateUser;
    this.createObjectHeader();

    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      language: ['', Validators.required],
    });
  }

  goUserManager(): void {
    this.route.navigate(['/discover/control-panel/users']);
  }

  goBack() {
    this.location.back();
  }

  private createObjectHeader = (): void => {
    this.objHeaderConfig = {
      title: {
        value:
          this.mode === 'create'
            ? this.translationService.translate('create.user.label.create')
            : this.translationService.translate('create.user.label.edit') + this.translationService.translate('create.user.label.update'),
        isEdit: false,
        editing: false,
      },
    };
  };
  onSubmit(): void {
    if (this.userForm.valid) {
      const userData = this.userForm.value;
      this.creatingUser = true;
      this.userService.createUser(userData).subscribe(
        (response) => {
          this.creatingUser = false;
          this.snackBarService.openSnackBar(this.translationService.translate('create.user.snackBar.created'), this.translationService.translate('create.user.snackBar.close'),'success');
          console.log('User data sent successfully:', response);
          this.route.navigate(['/discover/control-panel/users']);
        },
        (error) => {
          this.creatingUser = false;
          this.snackBarService.openSnackBar(
            this.translationService.translate('create.user.snackBar.error'),
            this.translationService.translate('create.user.snackBar.close'),'error'
          );
          console.error('Error occurred while sending user data:', error);
          // Handle error
        }
      );
    } else {
      // Mark all fields as touched to display validation messages
      this.userForm.markAllAsTouched();
    }
  }

}
