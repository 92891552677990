<div id="pulse-loading">
    <!-- Heart -->
    <svg id="heart" xmlns="http://www.w3.org/2000/svg" class="mask02 scrollfire scrollfire-active" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 377 204" xml:space="preserve" style="transition-delay: 0ms;">
        <style type="text/css">
            rect{fill:url(#gradient)}
        </style>
        <defs>
            <linearGradient id="gradient" x1="100%" x2="0%">
                <stop offset="0%" stop-color="#2B94EB">
                    <animate attributeName="stop-color" values="#2B94EB;#4A6DE0;#6C49D1;#D240A7;#FB5443;#FD7742;#2B94EB;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
                <stop offset="19%" stop-color="#4A6DE0">
                    <animate attributeName="stop-color" values="#4A6DE0;#6C49D1;#D240A7;#FB5443;#FD7742;#2B94EB;#4A6DE0;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
                <stop offset="42%" stop-color="#6C49D1">
                    <animate attributeName="stop-color" values="#6C49D1;#D240A7;#FB5443;#FD7742;#2B94EB;#4A6DE0;#6C49D1;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
                <stop offset="60%" stop-color="#D240A7">
                    <animate attributeName="stop-color" values="#D240A7;#FB5443;#FD7742;#2B94EB;#4A6DE0;#6C49D1;#D240A7;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
                <stop offset="78%" stop-color="#FB5443">
                    <animate attributeName="stop-color" values="#FB5443;#FD7742;#2B94EB;#4A6DE0;#6C49D1;#D240A7;#FB5443;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
                <stop offset="100%" stop-color="#FD7742">
                    <animate attributeName="stop-color" values="#FD7742;#2B94EB;#4A6DE0;#6C49D1;#D240A7;#FB5443;#FD7742;" dur="2.5s" repeatCount="indefinite"></animate>
                </stop>
            </linearGradient>
        </defs>
        <path id="symbol" d="M9.9,86.4c42.4,0.4,57.6,16.4,70.3,49.7c2,5.2,6.2,23.3,11.9,23.6c5.9,0.3,10.5-18.6,12.4-23.7
        c34.9-94.1,50.2-23.4,64.9,22.3c13,40.3,23.5,42.7,37,1c14.8-45.6,30-117.7,64.9-24.1c2,5.2,6.7,24.4,12.5,24.6
        c6,0.1,10.7-20,12.9-25.4c12.6-32,28-47.5,69.6-47.9c7.1-0.1,9.9-1,9.9-2.2c0-3.5-25.7-3.4-37.1-18c-12.2-15.7-11.6-37.6-19-28.7
        c-9.2,11-15.9,42.5-32.1,43.1c-22.3,0.9-38-67.9-45.5-77.8c-3.9-5.2-7.9-2.8-10.9,2.3c-4.9,8.4-9.6,25.4-16.4,40.1
        c-16.4,35.4-38.3,35-54.3-0.5c-6.4-14.3-10.9-30.6-16.3-39.7c-3-5.1-7-7.5-10.9-2.3c-7.5,9.9-23.2,78.7-45.5,77.8
        C72,80.1,65.3,48.6,56.1,37.6c-7.4-8.9-6.8,13-19,28.7C25.7,80.9,0,80.8,0,84.3C0,85.4,2.9,86.4,9.9,86.4"></path>
        <path id="symbol-shadow" d="M214.4,47c-16,33.1-36.9,32.9-52.6,0C176.7,86.3,194.4,93.5,214.4,47 M311,54.3c-5.8,12.4-12.3,26-22.9,26.4
        c-9.4,0.4-17.6-11.6-24.6-26.4C275.6,102,304.6,91.1,311,54.3z M116,47c-7.6,17.9-16.9,34.2-27.8,33.7c-12.7-0.5-19.6-20-26.4-33.7
        C71.7,97.8,102.6,103.4,116,47z"></path>
    </svg>
    <!-- Logo name -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 393 83" style="margin-top: 15px;max-height: 40%;" xml:space="preserve" *ngIf="name">
        <style type="text/css">
            .st0{fill-rule:evenodd;clip-rule:evenodd;}
        </style>
        <defs>
        </defs>
        <path class="st0" d="M20.5,27.6c2.6,0,4.5-0.6,5.9-1.9c1.4-1.3,2.1-3.1,2.1-5.6c0-2.3-0.7-4.1-2.1-5.4c-1.4-1.3-3.4-1.9-5.9-1.9h-8
            v14.8H20.5z M83,13.8V51H70.7v-6c-2.5,4.3-6.5,6.5-11.9,6.6c-4,0-7.2-1.3-9.7-3.9c-2.4-2.6-3.6-6-3.6-10.3V13.8h12.2v20
            c0,2.1,0.5,3.8,1.6,4.9c1.1,1.2,2.5,1.8,4.4,1.8c2.2,0,3.8-0.8,5.1-2.4c1.2-1.5,1.9-3.5,1.9-6V13.8H83z M92.7,0h12.2v51H92.7V0z
            M139.4,25.6c-4.8-2.5-8.7-3.8-11.8-3.8c-1.1,0-1.9,0.2-2.5,0.6c-0.6,0.4-1,1-1,1.8c0,0.8,0.5,1.5,1.5,2c1,0.5,2.6,1.1,4.8,1.6
            c2.7,0.8,4.9,1.5,6.6,2.3c1.8,0.8,3.3,1.9,4.6,3.4c1.3,1.5,1.9,3.5,1.9,6c0,3.8-1.5,6.7-4.4,8.8c-2.9,2.1-6.7,3.2-11.4,3.2
            c-3.2,0-6.2-0.4-9.1-1.3c-2.9-0.9-5.4-2.2-7.7-3.9l3.8-7.9c2.1,1.5,4.3,2.6,6.6,3.4c2.3,0.8,4.5,1.2,6.6,1.2c1.2,0,2.1-0.2,2.9-0.6
            c0.7-0.4,1.1-1,1.1-1.7c0-0.9-0.5-1.6-1.5-2.1c-1-0.5-2.6-1.1-4.8-1.6c-2.6-0.8-4.8-1.5-6.5-2.3c-1.7-0.8-3.2-1.9-4.4-3.4
            c-1.3-1.5-1.9-3.5-1.9-5.9c0-3.8,1.4-6.7,4.3-8.8c2.8-2.1,6.5-3.2,11-3.2c2.7,0,5.4,0.4,8,1.1c2.6,0.7,5.1,1.8,7.5,3.2L139.4,25.6z
            M173.4,29.1c0-2.2-0.6-3.9-1.9-5.2c-1.3-1.3-2.9-2-5-2c-1.9,0-3.5,0.6-4.8,1.9c-1.3,1.3-2.2,3-2.6,5.2H173.4z M166.4,13.4
            c6,0,10.7,1.8,13.9,5.4c3.3,3.6,4.9,8.5,4.9,14.7c0,1,0,1.7-0.1,2.2h-25.8c0.6,2.1,1.6,3.7,3.1,4.8c1.5,1.1,3.3,1.7,5.4,1.7
            c1.6,0,3.1-0.3,4.6-1c1.5-0.6,2.9-1.6,4.1-2.8l6.4,6.4c-1.9,2.2-4.2,3.8-7,4.9c-2.7,1.1-5.8,1.7-9.2,1.7c-4,0-7.5-0.8-10.4-2.3
            c-3-1.6-5.3-3.8-6.8-6.6c-1.6-2.8-2.4-6.1-2.4-9.9c0-3.8,0.8-7.2,2.4-10.1c1.6-2.9,3.9-5.2,6.8-6.7
            C159.2,14.2,162.6,13.4,166.4,13.4z M194.4,2.9h5.9l18.8,36.1l18.6-36.1h5.9V51h-4.4l-0.1-41.5l-18.8,36.3h-2.6L198.8,9.5V51h-4.5
            V2.9z M260.3,1.4c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,1-2.2,1c-0.9,0-1.7-0.3-2.3-1
            c-0.6-0.6-0.9-1.4-0.9-2.3c0-0.9,0.3-1.7,0.9-2.3C258.6,1.7,259.3,1.4,260.3,1.4z M257.9,15h4.6v36h-4.6V15z M294.7,14.8
            c4.3,0,7.7,1.2,10.1,3.7c2.4,2.5,3.7,5.9,3.7,10.3V51h-4.7v-21c0-3.4-0.9-6-2.8-8c-1.9-1.9-4.5-2.9-7.8-2.9c-3.7,0-6.7,1.2-8.9,3.4
            c-2.2,2.2-3.5,5.3-3.8,9.1V51h-4.6V15h4.6v8.4c1.2-2.8,3.1-4.9,5.5-6.3C288.4,15.6,291.3,14.9,294.7,14.8z M335.4,47.3
            c2.6,0,4.9-0.6,7-1.8c2.1-1.2,3.7-2.9,4.8-5c1.2-2.2,1.8-4.6,1.8-7.3s-0.6-5.2-1.8-7.4c-1.2-2.2-2.8-3.9-4.8-5.1
            c-2.1-1.2-4.4-1.9-7-1.9c-2.6,0-4.9,0.6-7,1.9c-2.1,1.2-3.7,2.9-4.9,5.1c-1.2,2.2-1.8,4.6-1.8,7.4s0.6,5.2,1.8,7.3
            c1.2,2.2,2.8,3.8,4.9,5C330.4,46.6,332.8,47.3,335.4,47.3z M353.6,0v51H349v-8.2c-1.4,2.7-3.3,4.8-5.8,6.3c-2.5,1.5-5.4,2.2-8.7,2.2
            c-3.4,0-6.4-0.8-9.1-2.3c-2.7-1.6-4.7-3.7-6.2-6.5c-1.5-2.8-2.2-6-2.2-9.5c0-3.5,0.7-6.7,2.2-9.4c1.5-2.7,3.5-4.9,6.2-6.5
            c2.6-1.6,5.6-2.3,9-2.3c3.3,0,6.2,0.8,8.7,2.3c2.5,1.5,4.4,3.6,5.8,6.3V0H353.6z M386,21.4c-1.4-0.9-2.9-1.5-4.6-2
            c-1.7-0.5-3.4-0.7-5-0.7c-2.3,0-4.1,0.4-5.5,1.3c-1.4,0.9-2.1,2.2-2.1,4c0,1.2,0.4,2.2,1.1,3c0.8,0.8,1.7,1.4,2.8,1.9
            c1.1,0.5,2.7,1,4.7,1.6c2.5,0.7,4.5,1.5,6,2.2c1.6,0.7,2.9,1.7,4,3.1c1.1,1.4,1.6,3.1,1.6,5.3c0,3.3-1.2,5.7-3.7,7.5
            c-2.5,1.7-5.6,2.6-9.3,2.6c-2.6,0-5.1-0.4-7.6-1.3c-2.4-0.9-4.5-2.1-6.1-3.7l1.9-3.3c1.6,1.4,3.4,2.5,5.5,3.4
            c2.1,0.8,4.3,1.2,6.5,1.2c2.4,0,4.4-0.5,5.9-1.5c1.5-1,2.3-2.5,2.3-4.4c0-1.3-0.4-2.4-1.2-3.3c-0.8-0.8-1.8-1.5-2.9-2
            c-1.2-0.5-2.8-1-4.8-1.7c-2.4-0.7-4.4-1.4-5.9-2.1c-1.5-0.7-2.8-1.7-3.8-3c-1.1-1.3-1.6-3-1.6-5.1c0-3.2,1.2-5.5,3.5-7.1
            c2.4-1.6,5.3-2.4,8.8-2.4c2.1,0,4.1,0.3,6,0.8c1.9,0.5,3.6,1.3,5.2,2.3L386,21.4z M21.2,2.8c6.1,0,10.9,1.5,14.3,4.4
            c3.4,3,5.1,7.1,5.1,12.5c0,5.7-1.7,10.1-5.1,13.2c-3.4,3.1-8.2,4.7-14.3,4.7h-8.6V51H0V2.8H21.2z"/>
        <!-- Tag line -->
        <path class="st0" *ngIf="tagLine" d="M16.9,68.8h1.6v11.7h-1.6l-6.7-9v9H8.6V68.8h1.6l6.7,9.1V68.8z M23.6,68.8h9.1v1.5h-3.8v10.2h-1.6V70.3h-3.8
            V68.8z M37.7,68.8h8.1v1.5h-6.5v3.6h5.8v1.5h-5.8V79H46v1.5h-8.3V68.8z M51.8,68.8h1.6V79h5.1v1.5h-6.7V68.8z M63.7,68.8h1.6V79h5.1
            v1.5h-6.7V68.8z M75.6,68.8h1.6v11.7h-1.6V68.8z M91.8,74.8h1.4v4.3c-0.6,0.5-1.3,0.8-2.1,1.1c-0.8,0.3-1.6,0.4-2.4,0.4
            c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.3-2.2-2.2c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3c0.5-0.9,1.3-1.6,2.2-2.1
            c0.9-0.5,2-0.8,3.1-0.8c0.8,0,1.6,0.1,2.4,0.4c0.8,0.3,1.4,0.7,2,1.2l-0.9,1.2c-0.5-0.4-1-0.8-1.6-1c-0.6-0.2-1.2-0.4-1.9-0.4
            c-0.8,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.3,0.9-1.7,1.6c-0.4,0.7-0.6,1.4-0.6,2.3c0,0.8,0.2,1.6,0.6,2.3c0.4,0.7,1,1.2,1.7,1.7
            c0.7,0.4,1.5,0.6,2.3,0.6c0.5,0,1-0.1,1.5-0.2c0.5-0.2,1-0.4,1.4-0.7V74.8z M99.5,68.8h8.1v1.5h-6.5v3.6h5.8v1.5h-5.8V79h6.7v1.5
            h-8.3V68.8z M121.8,68.8h1.6v11.7h-1.6l-6.7-9v9h-1.6V68.8h1.6l6.7,9.1V68.8z M128.5,68.8h9.1v1.5h-3.8v10.2h-1.6V70.3h-3.8V68.8z
            M163.5,75.4c1,0,1.8-0.2,2.3-0.7c0.5-0.4,0.8-1.1,0.8-1.9c0-0.8-0.3-1.5-0.8-1.9c-0.5-0.4-1.3-0.6-2.3-0.6l-2.8,0v5.1H163.5z
            M163.6,68.8c1.5,0,2.6,0.3,3.4,1c0.8,0.7,1.2,1.7,1.2,2.9c0,1.3-0.4,2.3-1.2,3c-0.8,0.7-1.9,1.1-3.4,1.1h-2.9v3.6h-1.6V68.8H163.6z
            M178,75.4c1,0,1.8-0.2,2.3-0.7c0.5-0.4,0.8-1.1,0.8-1.9c0-0.8-0.3-1.5-0.8-1.9c-0.5-0.4-1.3-0.6-2.3-0.6h-3v5.1H178z M181,80.5
            l-2.3-3.7c-0.3,0-0.5,0-0.7,0h-3v3.6h-1.6V68.8h4.6c1.5,0,2.6,0.3,3.4,1c0.8,0.7,1.2,1.7,1.2,2.9c0,0.9-0.2,1.7-0.7,2.4
            c-0.4,0.6-1.1,1.1-1.9,1.4l2.7,4H181z M193.8,70.1c-0.8,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.2,0.9-1.7,1.6c-0.4,0.7-0.6,1.4-0.6,2.3
            c0,0.8,0.2,1.6,0.6,2.3c0.4,0.7,1,1.2,1.7,1.6c0.7,0.4,1.4,0.6,2.3,0.6c0.8,0,1.6-0.2,2.2-0.6c0.7-0.4,1.2-1,1.6-1.6
            c0.4-0.7,0.6-1.4,0.6-2.3c0-0.8-0.2-1.6-0.6-2.3c-0.4-0.7-1-1.2-1.6-1.6C195.4,70.3,194.7,70.1,193.8,70.1z M193.8,68.7
            c1.1,0,2.2,0.3,3.1,0.8c0.9,0.5,1.7,1.2,2.2,2.1c0.5,0.9,0.8,1.9,0.8,3c0,1.1-0.3,2.1-0.8,3c-0.5,0.9-1.3,1.6-2.2,2.2
            c-0.9,0.5-2,0.8-3.1,0.8c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.3-2.2-2.2c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3
            c0.5-0.9,1.3-1.6,2.2-2.1C191.7,69,192.7,68.7,193.8,68.7z M213.5,71.6c-0.4-0.5-1-0.8-1.6-1.1c-0.6-0.3-1.2-0.4-1.8-0.4
            c-0.8,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.3c0,0.8,0.2,1.6,0.6,2.3c0.4,0.7,0.9,1.2,1.6,1.6
            c0.7,0.4,1.4,0.6,2.3,0.6c0.6,0,1.2-0.1,1.8-0.4c0.6-0.3,1.1-0.6,1.6-1l1,1.1c-0.6,0.6-1.3,1-2.1,1.4c-0.8,0.3-1.6,0.5-2.4,0.5
            c-1.1,0-2.1-0.3-3.1-0.8c-0.9-0.5-1.7-1.2-2.2-2.2c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3c0.5-0.9,1.3-1.6,2.2-2.1
            c0.9-0.5,2-0.8,3.1-0.8c0.8,0,1.6,0.2,2.4,0.5c0.8,0.3,1.4,0.7,2,1.3L213.5,71.6z M219.9,68.8h8.1v1.5h-6.5v3.6h5.8v1.5h-5.8V79h6.7
            v1.5h-8.3V68.8z M240.8,71.2c-0.5-0.3-1.1-0.6-1.7-0.8c-0.6-0.2-1.1-0.3-1.6-0.3c-0.7,0-1.2,0.1-1.6,0.4c-0.4,0.3-0.6,0.6-0.6,1.1
            c0,0.4,0.1,0.7,0.4,1c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.2,0.9,0.3,1.5,0.5c0.8,0.2,1.4,0.5,1.9,0.7c0.5,0.2,0.9,0.6,1.3,1
            c0.3,0.4,0.5,1,0.5,1.7c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.9-1.5,1.2c-0.7,0.3-1.4,0.4-2.2,0.4c-0.9,0-1.7-0.2-2.5-0.5
            c-0.8-0.3-1.5-0.8-2.1-1.3l0.7-1.4c0.6,0.5,1.2,1,1.9,1.3c0.7,0.3,1.4,0.4,2,0.4c0.8,0,1.4-0.2,1.8-0.5c0.5-0.3,0.7-0.7,0.7-1.2
            c0-0.4-0.1-0.8-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.6c-0.4-0.2-0.9-0.3-1.5-0.5c-0.8-0.2-1.4-0.5-1.9-0.7c-0.5-0.2-0.9-0.6-1.2-1
            c-0.3-0.4-0.5-1-0.5-1.7c0-0.6,0.2-1.2,0.5-1.7c0.3-0.5,0.8-0.8,1.5-1.1c0.6-0.3,1.3-0.4,2.2-0.4c0.7,0,1.4,0.1,2.1,0.3
            c0.7,0.2,1.3,0.5,1.8,0.8L240.8,71.2z M254.3,71.2c-0.5-0.3-1.1-0.6-1.7-0.8c-0.6-0.2-1.1-0.3-1.6-0.3c-0.7,0-1.2,0.1-1.6,0.4
            c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.7,0.4,1c0.2,0.3,0.5,0.5,0.9,0.6c0.4,0.2,0.9,0.3,1.5,0.5c0.8,0.2,1.4,0.5,1.9,0.7
            c0.5,0.2,0.9,0.6,1.3,1c0.3,0.4,0.5,1,0.5,1.7c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.9-1.5,1.2c-0.7,0.3-1.4,0.4-2.2,0.4
            c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.3-1.5-0.8-2.1-1.3l0.7-1.4c0.6,0.5,1.2,1,1.9,1.3c0.7,0.3,1.4,0.4,2,0.4c0.8,0,1.4-0.2,1.8-0.5
            c0.5-0.3,0.7-0.7,0.7-1.2c0-0.4-0.1-0.8-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.6c-0.4-0.2-0.9-0.3-1.5-0.5c-0.8-0.2-1.4-0.5-1.9-0.7
            c-0.5-0.2-0.9-0.6-1.2-1c-0.3-0.4-0.5-1-0.5-1.7c0-0.6,0.2-1.2,0.5-1.7c0.3-0.5,0.8-0.8,1.5-1.1c0.6-0.3,1.3-0.4,2.2-0.4
            c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.3,0.5,1.8,0.8L254.3,71.2z M281.9,75.4c1,0,1.8-0.2,2.3-0.7c0.5-0.4,0.8-1.1,0.8-1.9
            c0-0.8-0.3-1.5-0.8-1.9c-0.5-0.4-1.3-0.6-2.3-0.6l-2.8,0v5.1H281.9z M281.9,68.8c1.5,0,2.6,0.3,3.4,1c0.8,0.7,1.2,1.7,1.2,2.9
            c0,1.3-0.4,2.3-1.2,3c-0.8,0.7-1.9,1.1-3.4,1.1H279v3.6h-1.6V68.8H281.9z M291.8,68.8h1.6V79h5.1v1.5h-6.7V68.8z M305.8,76.2h4.9
            l-2.5-5.7L305.8,76.2z M312.5,80.5l-1.2-2.8h-6.2l-1.2,2.8h-1.7l5.2-11.7h1.7l5.2,11.7H312.5z M316.5,68.8h9.1v1.5h-3.8v10.2h-1.6
            V70.3h-3.8V68.8z M330.7,68.8h7.6l0,1.5h-6v3.8h5.4v1.5h-5.4v4.9h-1.6V68.8z M348.4,70.1c-0.8,0-1.6,0.2-2.3,0.6
            c-0.7,0.4-1.2,0.9-1.7,1.6c-0.4,0.7-0.6,1.4-0.6,2.3c0,0.8,0.2,1.6,0.6,2.3c0.4,0.7,1,1.2,1.7,1.6c0.7,0.4,1.4,0.6,2.3,0.6
            c0.8,0,1.6-0.2,2.2-0.6c0.7-0.4,1.2-1,1.6-1.6c0.4-0.7,0.6-1.4,0.6-2.3c0-0.8-0.2-1.6-0.6-2.3c-0.4-0.7-1-1.2-1.6-1.6
            C350,70.3,349.3,70.1,348.4,70.1z M348.4,68.7c1.1,0,2.2,0.3,3.1,0.8c0.9,0.5,1.7,1.2,2.2,2.1c0.5,0.9,0.8,1.9,0.8,3
            c0,1.1-0.3,2.1-0.8,3c-0.5,0.9-1.3,1.6-2.2,2.2c-0.9,0.5-2,0.8-3.1,0.8c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.3-2.2-2.2
            c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3c0.5-0.9,1.3-1.6,2.2-2.1C346.3,69,347.3,68.7,348.4,68.7z M364.6,75.4
            c1,0,1.8-0.2,2.3-0.7c0.5-0.4,0.8-1.1,0.8-1.9c0-0.8-0.3-1.5-0.8-1.9c-0.5-0.4-1.3-0.6-2.3-0.6h-3v5.1H364.6z M367.6,80.5l-2.3-3.7
            c-0.3,0-0.5,0-0.7,0h-3v3.6H360V68.8h4.6c1.5,0,2.6,0.3,3.4,1c0.8,0.7,1.2,1.7,1.2,2.9c0,0.9-0.2,1.7-0.7,2.4
            c-0.4,0.6-1.1,1.1-1.9,1.4l2.7,4H367.6z M375.3,68.8h1.9l4.3,8.2l4.2-8.2h1.9v11.7h-1.5l0-9.3l-4.2,8.1h-1l-4.2-8.1v9.3h-1.5V68.8z
            M0.5,68.8h1.6v11.7H0.5V68.8z"/>
    </svg>
</div>