<div class="importNimbusGraph fixActionRow">
  <h1 *ngIf="mode=='create'" mat-dialog-title>Assign a reference model</h1>
  <h1 *ngIf="mode=='edit'" mat-dialog-title>Edit reference model</h1>
  <div mat-dialog-content class="dialog-content">
    <div>{{ "process.analysis.model.select.reference" | translate }}</div>
    <div class='pl-form-field-container doc-location'>
        <div class="title">{{ "process.analysis.model.map.location" | translate }}</div>
        <nimbus-doc-location [document]="document" [config]="documentLocationConfig" [listHeight]="290" (mapSelected)="mapSelected($event)"></nimbus-doc-location>
    </div>
  </div>
  <div class="action-bar" [ngClass]="{'flex-end': mode=='create'}">
    <div mat-dialog-actions *ngIf="mode=='edit'">
      <uxpl-button type="secondary" (clicked)="onRemove()">{{ "process.analysis.model.remove" | translate }}</uxpl-button>
    </div>
    <div mat-dialog-actions>
      <uxpl-button type="secondary" (clicked)="onCancel()">{{ "cancel" | translate }}</uxpl-button>
      <uxpl-button *ngIf="mode=='create'" (clicked)="onConfirm()">{{ "assign" | translate }}</uxpl-button>
      <uxpl-button *ngIf="mode=='edit'" (clicked)="onConfirm()">{{ "save" | translate }}</uxpl-button>
    </div>
  </div>
</div>
