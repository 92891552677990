<div class="template-editor-wrapper">
  <uxpl-object-header [config]="objHeaderConfig" (back)="goTemplates()">
    <uxpl-button *ngIf="isMode('edit')" slot="navigation-content" type="no-border" (clicked)="goTemplates()">
      {{ 'template.editor.button.discard' | translate }}
    </uxpl-button>
    <uxpl-button *ngIf="isMode('create')" slot="navigation-content" type="no-border" (clicked)="goTemplates()">
      {{ 'template.editor.button.cancel' | translate }}
    </uxpl-button>
    <uxpl-button slot="navigation-content" type="secondary" [hidden]="hide('prev')" (clicked)="changeTab(-1)">
      {{ 'template.editor.button.back' | translate }}
    </uxpl-button>
    <uxpl-button slot="navigation-content" type="primary" [hidden]="hide('next')" [disabled]="handleDisableNextButton()"
                 (clicked)="changeTab(1)">
                 {{ 'template.editor.button.next' | translate }}
    </uxpl-button>
    <uxpl-button slot="navigation-content" type="primary" [hidden]="hide('create')" [disabled]="callingUpdateCreate"
                 (clicked)="handleCreateUpdateClicked()">
                 {{ 'template.editor.button.create' | translate }}
    </uxpl-button>
    <uxpl-button slot="navigation-content" type="primary" [hidden]="hide('save')" [disabled]="callingUpdateCreate"
                 (clicked)="handleCreateUpdateClicked()">
                 {{ 'template.editor.button.save' | translate }}
    </uxpl-button>
  </uxpl-object-header>


  <div *ngIf="request" class="stepper">
    <uxpl-stepper [config]="config" (stepClickedIdx)="handleStepClick($event)" [hideNumbers]="mode !== 'create'">
      <div *ngIf="display('basic-info')" slot="basic-info" class="stepper-item">
        <template-basic-info [template]="request"
                             [isNewTemplate]="isMode('create')"
                             [baseTemplateId]="baseTemplateId"
                             (status)="handleStatus($event)"
                             (updateTemplate)="updateTemplate($event)"
                             (setBaseTemplate)="setBaseTemplateInfo($event)">
        </template-basic-info>
      </div>
      <div *ngIf="display('analytics')" slot="analytics" class="stepper-item">
        <template-analytics [initialLocation]="initialTemplateDXPLocation"
                            [existingDXPLocation]="existingDXPLocation"
                            [newDXPLocation]="newDXPLocation"
                            [analyticsChoice]="analyticsOption"
                            [isNewTemplate]="isMode('create')"
                            [doAdvancedTab]="doAdvanced"
                            (doAdvancedE)="handleAdvanced($event)"
                            (status)="handleStatus($event)"
                            (updateAnalytics)="updateAnalytics($event)">
        </template-analytics>
      </div>
      <div *ngIf="display('analytics-menu-configuration')" slot="analytics-menu-configuration" class="stepper-item">
        <template-analytics-menu-configuration [menuConfig]="request.menuConfig"
                                               [pageOptions]="pageOptions"
                                               (status)="handleStatus($event)"
                                               (previewMenuEE)="previewMenu = $event"
                                               (updateConfig)="request.menuConfig = $event">
        </template-analytics-menu-configuration>
      </div>
      <div *ngIf="display('analytics-filter-panel')" slot="analytics-filter-panel" class="stepper-item">
        <template-analytics-filters [filterConfig]="request.filters"
                                    [pageOptions]="pageOptions"
                                    (status)="handleStatus($event)"
                                    (previewFilterMenuEE)="previewFilterMenu = $event"
                                    (updateConfig)="request.filters = $event"></template-analytics-filters>
      </div>
      <div *ngIf="display('additional-options')" slot="additional-options" class="stepper-item">
        <template-advanced [template]="request"
                           [markingOptions]="markingOptions"
                           [dataOptions]="dataOptions"
                           (status)="handleStatus($event)">
        </template-advanced>
      </div>
      <div *ngIf="display('confirmation')" slot="confirmation" class="stepper-item">
        <template-confirmation [template]="request"
                               [analyticsChoice]="analyticsOption"
                               [selectedAnalytics]="existingDXPLocation"
                               [newDXPLocation]="newDXPLocation"
                               [isNewTemplate]="isMode('create')">
        </template-confirmation>
      </div>
    </uxpl-stepper>
   <template-analytic-preview *ngIf="request"
                               [isFilterPreview]="false"
                               class="analytic-preview"
                               [hidden]="!display('analytics-menu-configuration')"
                               [location]="existingDXPLocation"
                               [previewParameters]="request.previewParameters"
                               [previewMenu]="previewMenu"
                               (pageOptions)="handlePageOptions($event)"
                               (markingOptions)="handleMarkingOptions($event)"
                               (dataOptions)="handleDataOptions($event)">
    </template-analytic-preview>
    <template-analytic-preview *ngIf="request"
                               [isFilterPreview]="true"
                               class="analytic-preview"
                               [hidden]="!display('analytics-filter-panel')"
                               [location]="existingDXPLocation"
                               [previewParameters]="request.previewParameters"
                               [previewMenu]="previewFilterMenu"
                               (pageOptions)="handlePageOptions($event)"
                               (markingOptions)="handleMarkingOptions($event)"
                               (dataOptions)="handleDataOptions($event)">
    </template-analytic-preview>
<!--    <pre>initialTemplateDXPLocation:{{initialTemplateDXPLocation |  json}}</pre>-->
<!--    <pre>existingDXPLocation:{{existingDXPLocation |  json}}</pre>-->
<!--    <pre>request:{{request |  json}}</pre>-->
  </div>
</div>
