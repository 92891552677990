<!-- make case screen full height-->
<div class="box">
  <div id="navbar"></div>
  <!-- <div #helpContainer>
    <help-container [showHelp]="showHelp" [helpSource]="helpSource"></help-container>
  </div> -->
<!--  <div *ngIf="!hasNoAccess && (!config || !afterInit)" class="rowC header">-->
<!--    <div class="headerBack"></div>-->
<!--  </div>-->
  <div class="rowC content tcs-starter-app-router-pane">
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
</div>

