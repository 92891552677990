<div class="case-table-wrapper">
  <div class="title-bar">
    <div class="data-title">
      <div class="page-title">{{cConfig.customTitle}}</div>
      <uxpl-form-field class="inv-search-widget" type="text" consise valid [label]="placeholderSearch"
                       (uxplChanged)="handleSearch($event);">
        <uxpl-icon class="inv-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
      </uxpl-form-field>
    </div>
    <div class="data-title">
      <uxpl-button class="secondary" type="no-border" icon="assets/images/svg/pl-icon-refresh.svg"
          (clicked)="refreshEvent.emit(cConfig.id)" width="100">
        <div class="icon-button secondary-icon-button">{{ 'investigation.button.refresh' | translate }}</div>
      </uxpl-button>
    </div>
  </div>
  <!--div class="case-list-toolbar-container">
    <div class="case-list-search">
      <div class="investigation-title">{{cConfig.customTitle}}</div>
      <uxpl-form-field style="margin-bottom:15px;" type="text" valid label="Search" float
                       (uxplChanged)="handleSearch($event);"></uxpl-form-field>
      <uxpl-icon class="search-icon" icon="pl-icon-search" width="20px" height="20px"></uxpl-icon>
    </div>
    <uxpl-button id="#refresh" type="secondary" icon="assets/images/svg/pl-icon-refresh.svg"
                 (clicked)="refreshEvent.emit(cConfig.id)" type="no-border">
      Refresh
    </uxpl-button>
  </div-->
  <div class="case-list-table-container">
    <div [hidden]="tableLoading">
      <investigation-table style="background-color: #f4f4f4;" *ngIf="investigations && investigations.length > 0"
                           [cConfig]="cConfig"
                           [investigations]="investigations"
                           [focusIds]="focusIds"
                           [loading]="tableLoading"
                           [searchTerm]="searchTerm"
                           (refreshEvent)="refreshEvent.emit($event)">
      </investigation-table>
      <div *ngIf="investigations && investigations.length === 0" class="center">
        <div class="img-center">
          <img width="204" src="assets/images/png/no-data.png">
        </div>
        <div class="no-data-available"><br></div>
        <div class="no-data-available" style="font-weight: bold">{{ 'investigation.label.messagge' | translate }}<br></div>
        <div class="no-data-available"><br></div>
      </div>

    </div>
    <div *ngIf="tableLoading" class="loading-indicator">
      <!-- <uxpl-spinner style="height: 150px; min-width: 150px; max-width: 150px;" appearance="light"
                    message="Loading investigations..."></uxpl-spinner> -->
      <pulse-loading></pulse-loading>
    </div>
  </div>
</div>
