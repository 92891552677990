    <div class="field-row">
    <uxpl-form-field type="text" fieldId="connectionName" [label]="labelName" valid required="true" [value]="connectionParams?.name" (uxplChanged)="handleFieldUpdate('name', $event)"></uxpl-form-field>
    <uxpl-form-field type="text" maxlength="256" fieldId="connectionDescription" [label]="labeldDescription" valid required="false" [value]="connectionParams?.description" (uxplChanged)="handleFieldUpdate('description', $event)"></uxpl-form-field>
  </div>

  <div class="field-row">
    <uxpl-form-field type="text" fieldId="userId" [label]="labelUserId" [hint]="connectionType?.value === 'tcm' ? 'abcdef/channel' : ''" valid required="true" [value]="connectionParams?.userId" (uxplChanged)="handleFieldUpdate('userId', $event)"></uxpl-form-field>
    <uxpl-form-field type="password" fieldId="password" [label]="labelPassword" valid required="false" [value]="connectionParams?.password" (uxplChanged)="handleFieldUpdate('password', $event)"></uxpl-form-field>
  </div>

  <div class="field-row" *ngIf="isTCMConnection">
    <uxpl-form-field type="text" fieldId="broker" [label]="labelBroker" hint="abc-def.messaging.cloud.tibco.com:10165" valid required="false" tooltip=true tooltip-top="-8px" [value]="connectionParams?.broker" (uxplChanged)="handleFieldUpdate('broker', $event)"><div slot="tooltip-content">A list of host/port pairs to use for establishing the initial connection to the Kafka cluster</div></uxpl-form-field>
  </div>

  <div class="field-row" *ngIf="isTDVConnection">
    <uxpl-form-field class="half-width-field" type="text" fieldId="hostname" label="TDV URL" hint="https://my.tdv.tibco.com" valid required="false" [value]="connectionParams?.hostname" (uxplChanged)="handleFieldUpdate('hostname', $event)"></uxpl-form-field>
    <uxpl-form-field type="text" fieldId="httpPort" label="Http port" valid required="false" hint="9400" [value]="connectionParams?.httpPort" (uxplChanged)="handleFieldUpdate('httpPort', $event)"></uxpl-form-field>
    <uxpl-form-field type="text" fieldId="jdbcPort" label="Jdbc port" valid required="false" hint="9401" [value]="connectionParams?.jdbcPort" (uxplChanged)="handleFieldUpdate('jdbcPort', $event)"></uxpl-form-field>
  </div>

  <uxpl-button class="test-button" [disabled]="!isTestable" type="secondary" width="50px" (clicked)="test()"><div class="dialog-action-text">{{ 'connection.label.test' | translate }}</div></uxpl-button>

