import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UxplLeftNav} from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import {ActivatedRoute, Router} from '@angular/router';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import {CatalogService, Dataset} from '@tibco/discover-client-lib';
import {NavMenu} from '@tibco/tc-web-components/dist/types/models/leftNav';
import {SpotfireDocument, SpotfireViewerComponent} from '@tibcosoftware/spotfire-wrapper';
import {stripDisabledMenuItems} from '../../../functions/templates';
import {OauthService} from '../../../service/oauth.service';
import {debounceTime, take} from 'rxjs/operators';
import {UxplPopup} from '@tibco/tc-web-components/dist/types/components/uxpl-popup/uxpl-popup';
import _ from 'lodash';
import {CreateInvestigationMenuComponent} from '../create-investigation-menu/create-investigation-menu.component';
import {notifyUser} from '../../../functions/message';
import {SfFilterPanelComponent} from '../sf-filter-panel/sf-filter-panel.component';
import {Subject} from 'rxjs';
import {InvestigationConfig} from '../../../models_ui/investigations';
import {NewProcessDocumentWizardComponent} from '../../new-process-document/wizard/wizard.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InvestigationContextService} from '../../../service/investigation-context.service';
import {RelationshipService} from '../../../service/relationship.service';
import {
  Analysis,
  CreateInvestigation,
  InvestigationService,
  InvestigationApplication,
  RepositoryService,
  Template,
  VisualisationService,
  FilterTemplate,
  MenuConfigTemplate,
  AnalysisReference
} from '@tibco/discover-client-lib';
import {MatDrawer} from '@angular/material/sidenav';

import {SelectNimbusProcessComponent} from '../../select-nimbus-process/select-nimbus-process.component';
import {FStat, MStat} from '../../../models_ui/analysis';
import {CreateInvestigationResponse} from '@tibco/discover-client-lib/model/createInvestigationResponse';
import {InternalMessageService} from '../../../service/internal-message.service';
import { AnalyticsDictionaryComponent } from '../dictionary/dictionary.component';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { environment } from 'src/environments/environment';
import { UploadProcessDocumentWizardComponent } from '../../new-process-document/upload-document-wizard/upload-document-wizard.component';

declare var spotfire: any;

@Component({
  selector: 'analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: InternalMessageService,
    private oService: OauthService,
    private repositoryMS: RepositoryService,
    private visualisationMS: VisualisationService,
    private investigationMS: InvestigationService,
    private investigationContextService: InvestigationContextService,
    private relationshipService: RelationshipService,
    private catalogService: CatalogService,
    private autService: AuthService,
    private snackBarService: SnackbarService,
    private translationService: TranslationService,

  ) {
    this.messageService.getMessage('clear-analytic.topic.message').subscribe(
      _D => {
        this.spotfireDXP = null;
      });
    window.addEventListener('message', this.receiveSFMessage);
  }

  @Input() analysis: string;
  @Input() resetMarking: boolean;

  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNav>;
  // @ViewChild('objectHeaderConfig', {static: false}) objectHeaderConfig: ElementRef<any>;
  @ViewChild('analysis', {static: false}) analysisRef: SpotfireViewerComponent;
  @ViewChild('popup', {static: false}) popup: ElementRef<UxplPopup>;
  @ViewChild('createinvestigation', {static: false}) createinvestigationMenu: CreateInvestigationMenuComponent;
  @ViewChild('analysisContainer', {static: false}) analysisContainer: ElementRef;
  @ViewChild('spotfireFilterPanel', {static: false}) spotfireFilterPanel: SfFilterPanelComponent;
  @ViewChild('drawer', {static: false}) drawer: MatDrawer;

  objHeaderConfig: ObjectHeaderConfig;
  leftNavTabs: NavMenu[];
  spotfireDXP: string;
  analysisParameters: string;
  spotfireServer;
  markingOn: string;
  casesSelector: string;
  variantSelector: string;
  markedVariants: string[];
  markedCases: string[];
  hideSelectProcess = false;
  investigationConfig: InvestigationApplication[];
  document: SpotfireDocument;
  filterCount: number;
  filterStats: FStat[];
  markingStats: MStat[];
  defaultTab: MenuConfigTemplate;
  templateToUse: Template;
  filterIds = [];
  filterConfig: FilterTemplate[] = [];
  showFilterButton = false;
  showFilterPanel = false;
  filterPanelSize = {x: 578, y: 629};
  filterPanelLeft: number;
  filterPanelTop: number;
  windowResized: Subject<any> = new Subject<any>();
  hoverOnFilterButton = false;
  dialogRef: MatDialogRef<NewProcessDocumentWizardComponent, any>;
  dialogRefUploadProcessDocument: MatDialogRef<UploadProcessDocumentWizardComponent, any>;
  graphJson;
  selectProcessDialogRef: MatDialogRef<SelectNimbusProcessComponent, any>;
  analysisObject: Analysis;
  isAnalysisCached: boolean = false;
  buttonDictionaryVisible = false;
  buttonDictionaryActive = false;
  dictionaryData;
  currentOrg = '';
  dialogRefDictionary: MatDialogRef<AnalyticsDictionaryComponent, any>;
  filterPage: string = '';
  markingByTab: any = [{
      "filterId": "Filter By Time",
      "markingName": "FilterModInput",
      "dataTableName": "events",
      "dataColumnNames": ["case_start_timestamp"]
    }, {
      "filterId": "Filter By Variants",
      "markingName": "FilterModInput",
      "dataTableName": "cases",
      "dataColumnNames": ["variant_id"]
    }, {
      "filterId": "Filters Activities",
      "markingName": "FilterModInput",
      "dataTableName": "events",
      "dataColumnNames": ["activity"]
    }, {
      "filterId": "Filter Attributes",
      "markingName": "FilterModInput",
      "dataTableName": "events",
      "dataColumnNames": ["resource_group", "activity"]
    }];
  filterByTab: any;
  filterByTabDefault: any = {
    "Filter By Time": {
      "columns": [{
        "filteringSchemeName": "Filtering scheme",
        "dataTableName": "events",
        "dataColumnName": "case_start_timestamp",
        "filterSettings": {
          "includeEmpty": true
        }
      },{
        "filteringSchemeName": "Filtering scheme",
        "dataTableName": "events",
        "dataColumnName": "case_end_timestamp",
        "filterSettings": {
          "includeEmpty": true
        }
      }],
      "enabled": false
    },
    "Filter By Variants": {
      "columns": [{
        "filteringSchemeName": "Filtering scheme",
        "dataTableName": "cases",
        "dataColumnName": "variant_id",
        "filterSettings": {
          "includeEmpty": true
        }
      }],
      "enabled": false
    },
    "Filters Activities": {
      "columns": [{
        "filteringSchemeName": "Filtering scheme",
        "dataTableName": "events",
        "dataColumnName": "activity",
        "filterSettings": {
          "includeEmpty": true
        }
      }],
      "enabled": false
    },
    "Filter Attributes": {
      "columns": [],
      "enabled": false
    }
  }
  availableTimeRange: DateRange<Date> | undefined;
  dateTimeFormat: string;
  dataset: Dataset;
  attributes: any;
  isAttributeReady: boolean = false;
  isActivityValuesReady: boolean = false;
  distinctActivities: any;

  readonly MAIN_DIV = 'SFVIEWERDIV'
  readonly FILTER_DIV = 'FILTERDIV'

  private filterForInvestigation: string;
  isfFullyLoaded: boolean;
  public spotfireAPI: any;
  private templateIdToUse: string;
  allTemplate: Template[];
  templateFilter: Template;
  private analysisIdPrevious: string;
  private analysisIdToUse: string;
  private analysisNameToUse: string;

  editTemplateTooltip: string = '';
  filterPanelVisible = true;

  public analysisReference: AnalysisReference = null;
  analysisReferenceTooltip: string = null;
  public loadingReferenceModel = true;
  showFilterSide = false;

  private receiveSFMessage(message: any) {
    try {
      const mesObj = JSON.parse(message.data)
      if (mesObj.spotfireKPIStats) {
        this.filterStats = mesObj.spotfireKPIStats
        this.filterStats = [...this.filterStats]
      }
    } catch (e) {
      // console.log('SF Message error', e)
    }
  }

  ngAfterViewInit(): void {
    spotfire.webPlayer.setApiVersion('14.0');
    spotfire.webPlayer
      .authenticate({ server: environment.spotfireURL, clientId: environment.spotfireClientId, redirectUri: environment.spotfireRedirectUri, silent: true, anonymous: true })
      .then(this.connect)
      .catch(this.onError);
    spotfire.webPlayer.oauthEnd();
    this.showRightSidePanel()
  }

  async ngOnInit(): Promise<void> {
    this.setFilterPanelScope();
    this.investigationConfig = await this.investigationMS.getInvestigationApplications().toPromise();
    this.initFilters();
    this.spotfireServer = environment.spotfireURL;
    this.autService.getOrgId().then((orgId) => {
      if(orgId){
        this.currentOrg = orgId;
      }
    });
    this.windowResized
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.showFilterPanel = false
        this.setFilterPanelScope();
        if(this.spotfireFilterPanel) {
          this.spotfireFilterPanel.toggleShow(this.showFilterPanel, this.filterPanelTop);
        }
      });
  }

  connect(token) {
    return spotfire.webPlayer.connect({ token, path: "/authentication", reconnect: false, customization: { showToolBar: false }, openParameters: "" });
  }
  onError(err) {
    console.error("Spotfire JavaScript API Error:", err);
  }

  showRightSidePanel() {
    if (this.drawer) {
      this.drawer.toggle(true)
      this.filterPanelVisible = true;
    }
  }

  private resetFilterValues() {
    this.markingStats = [];
    this.filterCount = 0;
    this.filterStats = []
  }

  public async ngOnChanges(changes: SimpleChanges) {
    // if(!this.currentOrg){
    //   let whoAmI = await this.configurationMS.getWhoAmI().toPromise();
    //   if(whoAmI && whoAmI.subscriptionId){
    //     this.currentOrg = whoAmI.subscriptionId;
    //   }
    // }
    if(!this.currentOrg){
      this.autService.getOrgId().then((orgId) => {
        if(orgId){
          this.currentOrg = orgId;
        }
      });
    }
    if(changes.analysis.previousValue){
      this.analysisIdPrevious = changes.analysis.previousValue;
    }
    if(changes.analysis.currentValue && this.analysisIdPrevious !== changes.analysis.currentValue) {
      this.analysisIdToUse = changes.analysis.currentValue;
      this.initFilters();
      this.initFilterByTime();
      this.initFilterAttributes();
    }
    if (changes.analysis.previousValue !== changes.analysis.currentValue) {
      this.loadingReferenceModel = true;
      this.isActivityValuesReady = false;
      if (changes.analysis.currentValue !== '') {

        this.templateIdToUse = null;
        this.route.queryParams.pipe(take(1)).subscribe(params => {
          this.templateIdToUse = params.forceTemplate;
          if (this.analysis) {
            this.repositoryMS.getAnalysisDetails(this.analysis).subscribe(
              async (analysis) => {
                // const analysis = this.dummyAnalysis
                if (analysis) {
                  this.analysisObject = analysis;
                  if(this.analysisObject.cache && this.analysisObject.cache.cacheStatus == 'Completed'){
                    this.isAnalysisCached = true;
                  }else{
                    this.isAnalysisCached = false;
                  }
                  this.editTemplateTooltip = this.translationService.translate('analysis.visualization.template.edit')+this.analysisObject.template?.name;
                  this.getReferenceModel();
                  if (this.templateIdToUse == null && analysis.template?.id) {
                    this.templateIdToUse = analysis.template.id;
                  }
                  if (!this.templateIdToUse) {
                    // Set a template
                    this.editTemplate()
                  }
                  // If the template was forced
                  // if (analysis.template) {
                  //   analysis.template = {
                  //     name: '',
                  //     id: this.templateIdToUse
                  //   }
                  // }
                  this.analysisNameToUse = analysis.name;
                  this.createObjectHeader(analysis);
                  this.showRightSidePanel();
                  try {
                    this.templateToUse = await this.visualisationMS.getTemplate(this.templateIdToUse).toPromise();
                    this.allTemplate = await this.visualisationMS.getTemplates().toPromise();
                    let indexDiscoveryTemplate = this.allTemplate.findIndex(object => {return object.name === 'Discovery';});
                    this.templateFilter = this.allTemplate[indexDiscoveryTemplate];
                  } catch (e) {
                    // TODO: Specifically check for a 404 when the backend is updated
                    console.error('TEMPLATE ERROR ', e);
                    this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error.template'), this.translationService.translate('connection.parameter.snackbar.close'),'error');

                  }

                  if (this.templateToUse) {
                    let oldFilter;
                    if (this.filterConfig) {
                      oldFilter = [...this.filterConfig];
                    }
                    this.filterConfig = null;
                    if (this.templateFilter?.filters) {
                      this.filterConfig = [...this.templateFilter.filters];
                      this.filterIds = this.filterConfig.map(config => config.view);
                    }
                    this.hideSelectProcess = true;
                    this.leftNavTabs = stripDisabledMenuItems(this.templateToUse.menuConfig);
                    this.updateLeftNavTabs();
                    for (const tab of this.leftNavTabs) {
                      const myTab = tab as MenuConfigTemplate;
                      if (myTab.enabled && myTab.isDefault) {
                        this.defaultTab = myTab;
                      }
                    }
                    let defTabParameter = '';
                    if (this.defaultTab && this.defaultTab.view) {
                      defTabParameter = 'SetPage(pageTitle="' + this.defaultTab.view + '");';
                    }
                    const newAnalyisParameters = 'AnalysisId="' + this.analysisIdToUse + '";&Token="' + this.oService.token + '";' + defTabParameter;
                    let doAnalysisParametersChange = false;
                    if (newAnalyisParameters !== this.analysisParameters) {
                      this.buttonDictionaryVisible = false;
                      this.analysisParameters = newAnalyisParameters;
                      doAnalysisParametersChange = true;
                    }
                    if (this.templateToUse.marking) {
                      this.markingOn = '*'
                      try {
                        this.markingOn = this.templateToUse.marking.listenOnMarking.replace(/\\/g, '');
                      } catch (e) {
                        console.error('Marking parse error: ', e)
                        this.snackBarService.openSnackBar(this.translationService.translate('analytics.dashboard.snackbar.error3')+ e, this.translationService.translate('connection.parameter.snackbar.close'),'error');

                      }
                      this.casesSelector = this.templateToUse.marking.casesSelector;
                      this.variantSelector = this.templateToUse.marking.variantSelector;
                    } else {
                      this.markingOn = '*';
                    }
                    let filterChanged = false;
                    if (oldFilter) {
                      filterChanged = true;
                      if (this.filterConfig) {
                        filterChanged = !(this.filterConfig.length === oldFilter.length && this.filterConfig.every((value, index) => value.id === oldFilter[index].id))
                      }
                    }
                    // TODO: Check if we need the filter changed behaviour
                    // Only re-load the DXP if it is different or the analysis parameters change or if there is a filter panel
                    console.log('filterChanged: ', filterChanged)
                    if (doAnalysisParametersChange || (this.spotfireDXP !== this.templateToUse.analysisFileLocation)) {
                      console.log('Reloading analytic')
                      // Reset filter values
                      this.resetFilterValues()
                      this.isfFullyLoaded = false;
                      this.loadAnalytic();
                    }
                    this.initFilterAttributes();
                  } else {
                    this.hideSelectProcess = false;
                    if (this.templateToUse) {
                      // Only show error if ID not zero
                      notifyUser('ERROR', 'Can\'t find template with id: ' + this.templateIdToUse, this.messageService);
                    }
                    // We get an error getting the template, so we display the select template screen
                    this.editTemplate()
                  }
                }
              }
            );
          }
        });
      }
    }
  }

  private createObjectHeader = async (analysis: Analysis): Promise<void> => {
    if (analysis) {
      // const templateDetails = await this.visualisationMS.getTemplate(analysis.template?.id).toPromise();
      // const formatDate = new Date(analysis.metadata.createdOn).toLocaleDateString('en-US', {
      //   weekday: 'long',
      //   year: 'numeric',
      //   month: 'long',
      //   day: 'numeric'
      // });
      let aName = analysis.name.split('||');
      if(aName.length > 1){
        analysis.name = aName.pop();
        analysis.folder = aName.join('||');
      }
      this.objHeaderConfig = {
        title: {
          value: analysis.name,
          isEdit: false,
          editing: false
        },
        details: [
          // {isEdit: false, editing: false, value: this.translationService.translate('analysis.visualization.created.by') + analysis.metadata.createdBy},
          // {isEdit: false, editing: false, value: this.translationService.translate('analysis.visualization.created.on') + formatDate},
          // {isEdit: false, editing: false, value: getShortMessage(analysis.description, 50)},
          // {isEdit: true, editing: false, value: this.translationService.translate('analysis.visualization.template') + templateDetails.name, eventOnEdit: true, id: 'template'}
        ],
        externalOptions: false,
        options: []
      };
    }
  }

  public setSpotfireConfiguration = (): any => {
    const mode = false;
    return {
      showAbout: mode,
      showAnalysisInformationTool: mode,
      showAuthor: mode,
      showClose: mode,
      showCustomizableHeader: false,
      showDodPanel: mode,
      showExportFile: mode,
      showFilterPanel: true,
      showHelp: false,
      showLogout: false,
      showPageNavigation: false,
      showStatusBar: false,
      showToolBar: mode,
      showUndoRedo: mode
    };
  }

  public goProcessAnalysis = (): void => {
    this.router.navigate(['/discover/process-analysis']);
  }

  createInv(event: InvestigationConfig) {
    // const createInvestigation = true;
    if (event == null) {
      this.popup.nativeElement.show = true;
    } else {
      const investigation = event.data as CreateInvestigation;
      investigation.analysisId = this.analysisIdToUse;
      investigation.analysisName = this.analysisNameToUse;
      investigation.templateId = this.templateToUse.id;
      investigation.templateName = this.templateToUse.name;
      const appId = event.investigationId;
      const appConfig = this.investigationConfig.find((el: InvestigationApplication) => el.applicationId === appId)
      if (appConfig) {
        this.investigationMS.postInvestigation(appConfig.id, investigation).subscribe(
          async (response: CreateInvestigationResponse) => {
            if (response) {
              // Create the investigation context
              const dataset = await this.relationshipService.getDatasetForProcessAnalysisId(this.analysis)
              // For filters we are getting the document property; outKeepRow (and set; inKeepRow)
              await this.investigationContextService.createInvestigationContext({
                id: response.id,
                dataset: {
                  id: dataset.id,
                  name: dataset.name,
                },
                analytic: {
                  page: this.analysisRef.page,
                  // Add the applied filter (got from document property: outKeepRow)
                  filter: this.filterForInvestigation,
                  markedCases: JSON.stringify(this.markedCases),
                  markedVariants: JSON.stringify(this.markedVariants)
                },
                processAnalysis: {
                  id: this.analysis,
                  idNoVersion: this.analysisIdToUse,
                  name: this.analysisNameToUse
                },
                processDocumentation: {
                  // TODO: Provide option to create process documentation
                  name: '',
                  id: ''
                },
                template: {
                  name: this.templateToUse.name,
                  id: this.templateToUse.id
                }
              })
              //this.messageService.sendMessage('news-banner.topic.message', event.investigationType + ' investigation created successfully (' + response.id + ')...');
              this.popup.nativeElement.show = false;
              this.snackBarService.openSnackBar(event.investigationType + this.translationService.translate('change.role.snackbar.success')+'(' + response.id + ')...' , this.translationService.translate('connection.parameter.snackbar.close'),'success');

            }
          }, error => {
            console.error('Unable to run the action');
            console.error(error);
            this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error'), this.translationService.translate('connection.parameter.snackbar.close'),'error');

          }
        );
      } else {
        this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error2'), this.translationService.translate('connection.parameter.snackbar.close'),'error');

        console.error('Could not find the Configuration to create the investigation')
      }
    }
  }

  handleClick = (event: any): void => {
    if (this.isfFullyLoaded && event?.detail.view) {
      this.analysisRef.page = event.detail.view;
      this.analysisRef.openPage(event.detail.view, this.MAIN_DIV);
    }
    if(event.detail.label == "Events"){
      this.buttonDictionaryVisible = true;
    }else{
      this.buttonDictionaryVisible = false;
      if(this.dialogRefDictionary){
        this.dialogRefDictionary.close();
      }
    }
  };

  public marking(data) {
    this.markedCases = _.get(data, this.casesSelector);
    this.markedVariants = _.get(data, this.variantSelector);
    this.markingStats = []
    if (this.markedCases?.length > 0) {
      this.markingStats.push({label: 'cases', values: this.markedCases})
    }else
    if (this.markedVariants?.length > 0) {
      this.markingStats.push({label: 'variants', values: this.markedVariants})
    }
    if(data == 'CLEAR'){
      this.spotfireAPI.marking.setMarking("Cases", "cases", "variant_id = 1", "CLEAR");
      this.spotfireAPI.marking.setMarking("Variant", "variants", "variant_id = 1", "CLEAR");
    }
  }

  public editTemplate() {
    this.router.navigate(['/discover/select-template', this.analysis]);
  }

  public setDocument = (event): void => {
    if (!this.document && event.mainId) {
      this.document = event;
      this.spotfireAPI = undefined;
      this.spotfireAPI = event._doc;
      this.initFilterByTime();
      this.initFilterAttributes();
      this.watchDocProp('GraphJson', n => this.graphJson = n)
      this.watchDocProp('outKeepRow', n => this.filterForInvestigation = n)
      this.watchDocProp('FilterCount', n => this.filterCount = Number(n))
      this.watchDocProp('FilterStats', n => this.filterStats = n)

      this.document.onDocumentReady$().subscribe(_val => {
        this.isfFullyLoaded = true;
        this.loadDictionary();
        this.showRightSidePanel()
        if (this.filterConfig) {
          this.showFilterButton = true;
          // const {width, height, left, top} = this.setFilterPanelScope();
          this.setFilterPanelScope();
        }
      })
    }
  }

  private watchDocProp(propertyName: string, updateProp: (newValue: any) => void) {
    this.spotfireAPI.onDocumentPropertyChanged(propertyName, change => {
      try {
        for (const key in change) {
          if (key === 'value') {
            const newValue = JSON.parse(change.value as string)
            updateProp(newValue)
          }
        }
      } catch (error) {
        // ignore the error, the prop can be empty string or null
      }
    })
  }

  private setFilterPanelScope() {
    if (this.analysisContainer && this.analysisContainer.nativeElement) {
      const {width, height, left, top} = this.analysisContainer?.nativeElement?.getBoundingClientRect();
      this.spotfireFilterPanel?.setContainerScope({width, height, left, top});
      // set the height of filter panel the same height of the scope
      // 40 is the height of the expand/collapse vertical panel toggle

      this.filterPanelSize.x = this.spotfireFilterPanel.width || this.filterPanelSize.x;
      if (this.spotfireFilterPanel.contentMaximized || width - 40 <= this.filterPanelSize.x) {
        this.filterPanelLeft = left + 40;
        this.filterPanelSize.x = width - 40;
      } else {
        this.filterPanelLeft = left + (width - this.filterPanelSize.x);
      }
      this.filterPanelSize.y = height - 40;
      this.filterPanelTop = top;
      return {width, height, left, top};
    }
    return {width: null, height: null, left: null, top: null}
  }

  public filterPanelResize(event) {
    this.filterPanelSize.x = event.width;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.windowResized.next({});
  }

  selectAnalytic() {
    this.router.navigate(['/discover/process-analysis']);
  }

  showCreateCaseMenu() {
    this.popup.nativeElement.show = !this.popup.nativeElement.show;
    this.createinvestigationMenu.setupAdditionalInfo();
  }

  loadAnalytic() {
    this.isfFullyLoaded = false;
    this.spotfireServer = environment.spotfireURL;
    this.showFilterButton = false;
    this.showFilterPanel = false;
    this.document = null;
    // Trick to force reloading of dxp
    this.spotfireDXP = null;

    setTimeout(() => {
      this.leftNav?.nativeElement?.setTab(this.defaultTab, true);
      if(this.analysisObject.cache && this.analysisObject.cache.cacheStatus && this.analysisObject.cache.cacheStatus == 'Completed' && this.analysisObject.cache.spotfireLocation && this.analysisObject.cache.spotfireLocation != ''){
        this.spotfireDXP = this.analysisObject.cache.spotfireLocation;
      }else{
        this.spotfireDXP = this.templateToUse.analysisFileLocation;
      }
    }, 0);
    // Sometime the left tab is not selected, this code ensures it is
    setTimeout(() => {
      this.leftNav.nativeElement.setTab(this.defaultTab, true);
    }, 100);
  }

  public toggleFilterPanel() {
    // only allow to show the filter panel when spotfire is fully loaded
    if (this.isfFullyLoaded) {
      this.showFilterPanel = !this.showFilterPanel;
      this.spotfireFilterPanel.toggleShow(this.showFilterPanel, this.filterPanelTop);
    }
  }

  public mouseUp() {
    console.log('mouse up in container');
  }

  public enableSFPointerEvents(enabled) {
    if (this.analysisContainer) {
      const iframe = this.analysisContainer.nativeElement.querySelector('iframe');
      if (iframe) {
        const style = 'border: 0px; margin: 0px; padding: 0px; width: 100%; height: 100%;';
        if (enabled) {
          iframe.setAttribute('style', style);
        } else {
          iframe.setAttribute('style', style + 'pointer-events: none;');
        }
      }
    }
  }

  openFPage(page: string) {
    if(page == "Filter By Time"){
      document.getElementById('filter-by-time').classList.remove('hidden');
    }else{
      document.getElementById('filter-by-time').classList.add('hidden');
    }
    if(page == "Filter Attributes"){
      document.getElementById('filter-attributes').classList.remove('hidden');
    }else{
      document.getElementById('filter-attributes').classList.add('hidden');
    }
    if (this.analysisRef) {
      this.analysisRef.openPage(page, this.FILTER_DIV);
    }
    this.filterPage = page;
  }

  createProcessDocument() {
    // Pulsem Minds - Bypass subscription control
    // const config = this.configService.config;
    let hasValidSubscription = true;
    // if (config && config.user && config.user.tenants) {
    //   config.user.tenants.forEach(tenant => {
    //     if (tenant.id.toLowerCase() === 'nimbus' && tenant.roles && tenant.roles.length > 0) {
    //       hasValidSubscription = true;
    //     }
    //   });
    // }
    this.dialogRef = this.dialog.open(NewProcessDocumentWizardComponent, {
      width: '720px',
      height: '566px',
      data: {
        graphJson: this.graphJson,
        data: {
          ProcessAnalysisId: this.analysisObject.id,
          ProcessAnalysisName: this.analysisObject.name,
          DatasetId: this.analysisObject.dataset.id
        },
        hasValidSubscription
      }
    });
  }

  uploadProcessDocument() {
    // Pulsem Minds - Bypass subscription control
    // const config = this.configService.config;
    let hasValidSubscription = true;
    // if (config && config.user && config.user.tenants) {
    //   config.user.tenants.forEach(tenant => {
    //     if (tenant.id.toLowerCase() === 'nimbus' && tenant.roles && tenant.roles.length > 0) {
    //       hasValidSubscription = true;
    //     }
    //   });
    // }
    this.dialogRefUploadProcessDocument = this.dialog.open(UploadProcessDocumentWizardComponent, {
      width: '1080px',
      height: '566px',
      data: {
        activities: this.distinctActivities.values,
        data: {
          analysisId: this.analysisObject.id,
        },
        hasValidSubscription
      }
    });
  }

  assignProcessDocument() {
    this.openProcessDocumentDialog('create');
  }

  editProcessDocument() {
    if (this.isfFullyLoaded) {
      this.openProcessDocumentDialog('edit');
    }
  }

  getReferenceModel() {
    this.repositoryMS.getReferenceModelsForAnalysis(this.analysisObject.id).toPromise().then(analysisReferences => {
      const assignDisabled = this.translationService.translate('process.analysis.model.assign.disabled');
      this.loadingReferenceModel = false;
      if (analysisReferences && analysisReferences.length > 0) {
        this.analysisReference = analysisReferences[analysisReferences.length - 1];
        this.analysisReferenceTooltip = this.translationService.translate('process.analysis.model.reference')+this.analysisReference.referenceName;
        if(this.isAnalysisCached){
          this.analysisReferenceTooltip += ' - '+assignDisabled;
        }
      } else {
        this.analysisReference = null;
        this.analysisReferenceTooltip = this.translationService.translate('process.analysis.model.title.create');
        if(this.isAnalysisCached){
          this.analysisReferenceTooltip = assignDisabled;
        }
      }
    })
  }

  private openProcessDocumentDialog(mode: 'create' | 'edit' ) {
    this.selectProcessDialogRef = this.dialog.open(SelectNimbusProcessComponent, {
      width: '720px',
      height: '566px',
      data: {
        analysis: this.analysisObject,
        mode
      }
    });
    this.selectProcessDialogRef.componentInstance.referenceModelUpdated.subscribe(() => {
      this.loadAnalytic();
      this.getReferenceModel();
    });
  }

  newFilterTab() {
    const sfViewer = document.getElementById('mySFViewer');
    console.log('sfViewer: ', sfViewer)
    console.log('sfViewer: ', sfViewer.firstChild.firstChild)
    const iFrame = sfViewer.firstChild.firstChild as HTMLElement;
    console.log('attributes: ', iFrame.attributes)
    console.log('attributes: ', iFrame.getAttribute('src'))
    const link = iFrame.getAttribute('src').replace('wavid=0', 'wavid=1')
    window.open(link)
    console.log('Childs: ', iFrame.childNodes)
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.filterPanelVisible = !this.filterPanelVisible;
    if (!this.filterPanelVisible && this.showFilterPanel) {
      this.showFilterPanel = false;
    }
  }

  openDictionary(){
    if (this.dialogRefDictionary){
      this.buttonDictionaryActive = false;
      this.dialogRefDictionary.close();
    }else{
      this.buttonDictionaryActive = true;
      let dictionaryData = '';
      if(this.dictionaryData){
        dictionaryData = JSON.parse(this.dictionaryData);
      }
      this.dialogRefDictionary = this.dialog.open(AnalyticsDictionaryComponent, {
        width: '360px',
        height: 'auto',
        data: dictionaryData,
        hasBackdrop: true,
        backdropClass: 'dictionary-backdrop'
      });
      this.dialogRefDictionary.afterClosed().subscribe(() => {
        this.buttonDictionaryActive = false;
        this.dialogRefDictionary = undefined;
      });
    }
  }

  loadDictionary(){
    this.document.getDocumentProperty$('dictionary').subscribe(
      data => this.dictionaryData = data.value
    );
  }

  updateLeftNavTabs(){
    for(let [i, tab] of this.leftNavTabs.entries()){
      if(
        // tab.label == "Variants" || 
        tab.label == "Cases"
      ){
        if(tab.child && tab.child.length > 0){
          for(let [i, child] of tab.child.entries()){
            if(child.label == "Data"){
              tab.child.splice(i, 1);
              break;
            }
          }
        }
      }else if(tab.label == "Process Map"){
        this.leftNavTabs.splice(i, 1);
      }
    }
  }

  initFilters(){
    if(!this.analysisIdToUse){
      return;
    }
    let filterByTab: any = localStorage.getItem('filterByTab');
    if(!this.isJsonString(filterByTab) || filterByTab == null || filterByTab == undefined){
      filterByTab = {};
    }else{
      filterByTab = JSON.parse(filterByTab);
    }
    if(filterByTab[this.analysisIdToUse]){
      this.filterByTab = filterByTab[this.analysisIdToUse];
      for(let [filter] of Object.entries(this.filterByTab)){
        this.filterByTab[filter].enabled = false;
      }
    }else{
      filterByTab[this.analysisIdToUse] = {};
      this.filterByTab = JSON.parse(JSON.stringify(this.filterByTabDefault));
    }
    filterByTab[this.analysisIdToUse] = JSON.parse(JSON.stringify(this.filterByTab));
    localStorage.setItem('filterByTab', JSON.stringify(filterByTab));
  }

  async setFilter(filterId){
    let markingObj = await this.getMarkingByFilterId(filterId);
    if(this.isEmptyObject(markingObj)){
      return;
    }
    // Update filterByTab
    for(let [key, column] of Object.entries(markingObj)){
      let index = this.filterByTab[filterId].columns.findIndex(object => {
        return object.dataColumnName === key;
      });
      this.filterByTab[filterId].columns[index].filterSettings.values = column;
    }
    this.filterByTab[filterId].enabled = true;
    this.setSpotfireFilter(this.filterByTab[filterId]);
    this.initFilterByTime();
    this.initFilterAttributes();
  }

  async setFilterByTime(filterObj){
    let filterId = "Filter By Time"
    // Update filterByTab
    let indexStart = this.filterByTab[filterId].columns.findIndex(object => {
      return object.dataColumnName === "case_start_timestamp";
    });
    this.filterByTab[filterId].columns[indexStart].filterSettings.lowValue = filterObj.lowValue;
    this.filterByTab[filterId].columns[indexStart].filterSettings.highValue = filterObj.highValue;
    
    let indexEnd = this.filterByTab[filterId].columns.findIndex(object => {
      return object.dataColumnName === "case_end_timestamp";
    });
    this.filterByTab[filterId].columns[indexEnd].filterSettings.lowValue = filterObj.lowValue;
    this.filterByTab[filterId].columns[indexEnd].filterSettings.highValue = filterObj.highValue;

    this.filterByTab[filterId].enabled = true;
    this.setSpotfireFilter(this.filterByTab[filterId]);
  }

  async setFilterAttributes(filterObj){
    let filterId = "Filter Attributes"
    // Update filterAttributes
    this.filterByTab[filterId].columns = [];
    for(let attribute of filterObj){
      if(attribute.filterType != 'RangeFilter'){
        this.filterByTab[filterId].columns.push({
          "filteringSchemeName": "Filtering scheme",
          "dataTableName": "events",
          "dataColumnName": attribute.name,
          "filterSettings": {
            "includeEmpty": true,
            "values": attribute.values
          }
        });
      }else{
        this.filterByTab[filterId].columns.push({
          "filteringSchemeName": "Filtering scheme",
          "dataTableName": "events",
          "dataColumnName": attribute.name,
          "filterSettings": {
            "includeEmpty": true,
            "lowValue": attribute.lowValue,
            "highValue": attribute.highValue
          }
        });
      }
    }

    this.filterByTab[filterId].enabled = true;
    this.setSpotfireFilter(this.filterByTab[filterId]);
    this.initFilterByTime();
    this.initFilterAttributes();
    this.saveFiltersInLocalStorage();
  }

  resetFilterAttr(filterObj){
    let filterId = "Filter Attributes"
    // Update filterAttributes
    this.filterByTab[filterId].columns = [];
    for(let attribute of filterObj){
      this.filterByTab[filterId].columns.push({
        "filteringSchemeName": "Filtering scheme",
        "dataTableName": "events",
        "dataColumnName": attribute.name,
        "filterSettings": {
          "includeEmpty": true,
          "values": null
        }
      });
    }

    this.filterByTab[filterId].enabled = false;
    this.setSpotfireFilter(this.filterByTab[filterId]);
    this.initFilterAttributes();
    this.saveFiltersInLocalStorage();
  }

  resetFilter(filterId){
    // Save filter in localstorage
    this.filterByTab[filterId] = JSON.parse(JSON.stringify(this.filterByTabDefault[filterId]));
    this.setSpotfireFilter(this.filterByTab[filterId]);
    this.saveFiltersInLocalStorage();
    if(filterId == "Filter By Time"){
      this.initFilterByTime();
    }else if(filterId == "Filter Attributes"){
      this.initFilterAttributes();
    }
  }

  resetAllFilters(){
    this.filterByTab = JSON.parse(JSON.stringify(this.filterByTabDefault));
    for(let filter of Object.entries(this.filterByTab)){
      this.setSpotfireFilter(filter[1]);
    }
    this.saveFiltersInLocalStorage();
    // Update FilterCount Document Property
    this.updateFilterCount();
  }

  getMarkingByFilterId(filterId) {
    let index = this.markingByTab.findIndex(object => {
      return object.filterId === filterId;
    });
    return new Promise(resolve => {
      this.spotfireAPI.marking.getMarking(
        this.markingByTab[index].markingName,
        this.markingByTab[index].dataTableName,
        this.markingByTab[index].dataColumnNames,
        9999,
        response => resolve(response)
      )
    });
  }

  getActiveFilteringScheme() {
    if(!this.spotfireAPI){
      return false;
    }
    return new Promise(resolve => {
      this.spotfireAPI.filtering.getActiveFilteringScheme(
        response => resolve(response)
      )
    });
  }

  getFilterColumn(filteringScheme, filterObj) {
    return new Promise(resolve => {
      filteringScheme.getFilterColumn(
        filterObj.dataTableName,
        filterObj.dataColumnName,
        filterObj.includedFilterSettings,
        response => resolve(response)
      )
    });
  }

  toggleFilter(toggleConfig){
    this.filterByTab[toggleConfig.tabId].enabled = toggleConfig.enabled;
    this.setSpotfireFilter(this.filterByTab[toggleConfig.tabId]);
    this.saveFiltersInLocalStorage();
  }

  async setSpotfireFilter(filterObject){
    let operation:number = 2; //replace
    let filterColumns = JSON.parse(JSON.stringify(filterObject.columns));
    // let direction = 'in'; // in/out
    if(!filterObject.enabled){
      operation = 5; //reset
      // direction = 'reset';
      for(let column of filterColumns){
        column.filterSettings.values = null;
      }
    }else{
      // remove column without values
      for (var i = filterColumns.length - 1; i >= 0; i--) {
        if((!filterColumns[i].filterSettings.values || filterColumns[i].filterSettings.values && filterColumns[i].filterSettings.values.length == 0) && (!filterColumns[i].filterSettings.lowValue && !filterColumns[i].filterSettings.highValue)){
          filterColumns.splice(i, 1);
        }
      }
    }

    this.spotfireAPI.filtering.setFilters(filterColumns, operation);
    // Update FilterCount Document Property
    this.updateFilterCount();
    // Get filter from localstorage
    this.saveFiltersInLocalStorage();
  }

  async updateFilterStats(direction){
    this.document.getDocumentProperty$('FilterStats.No2').subscribe(
      filterStats => {
        if(typeof filterStats.value == "string"){
        }
      }
    );
    let outKeepRow = await this.document.getDocumentProperty$('outKeepRow').toPromise();
    this.document.setDocumentProperty('inKeepRow', outKeepRow.value);
    this.document.setDocumentProperty('FilterSettings', '{"origin": "panel", "filterOn": "events", "direction": "'+direction+'"}');
    this.document.getDocumentProperty$('FilterStats.No2').subscribe(
      filterStats => {
        if(typeof filterStats.value == "string"){
        }
      }
    );
  }

  updateFilterCount(){
    let count = 0;
    for(let [filter] of Object.entries(this.filterByTab)){
      if(this.filterByTab[filter].enabled){
        count++;
      }
    }
    this.document.setDocumentProperty('FilterCount', count.toString());
  }

  isEmptyObject(value) {
    if (value == null) {
      // null or undefined
      return false;
    }
  
    if (typeof value !== 'object') {
      // boolean, number, string, function, etc.
      return false;
    }
  
    const proto = Object.getPrototypeOf(value);
  
    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto !== null && proto !== Object.prototype) {
      return false;
    }
  
    return this.isEmpty(value);
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  saveFiltersInLocalStorage(){
    // redefine filtertab form ngOnChangs
    this.filterByTab = JSON.parse(JSON.stringify(this.filterByTab));

    let filterByTabStart: any = JSON.parse(localStorage.getItem('filterByTab'));
    if(!filterByTabStart){
      filterByTabStart = {};
    }
    filterByTabStart[this.analysisIdToUse] = JSON.parse(JSON.stringify(this.filterByTab));
    localStorage.setItem('filterByTab', JSON.stringify(filterByTabStart));
  }

  async initFilterByTime(){
    // Define time format
    if(this.analysisObject){
      this.dataset = await this.catalogService.getDataset(this.analysisObject.dataset.id).toPromise();
      for(let schema of this.dataset.schema){
        if(schema.type == "Timestamp"){
          this.dateTimeFormat = schema.format.replaceAll('d', 'D');;
          break;
        }
      }
    }
    // Get filteringScheme
    let filteringScheme = await this.getActiveFilteringScheme();

    if(!filteringScheme){
      return;
    }

    // Get case_start_timestamp
    let filterCaseStart = {
      dataTableName: "events",
      dataColumnName: "case_start_timestamp",
      includedFilterSettings: 1
    }
    let case_start_timestamp: any = await this.getFilterColumn(filteringScheme, filterCaseStart);

    let indexStart = this.filterByTab["Filter By Time"].columns.findIndex(object => {
      return object.dataColumnName === "case_start_timestamp";
    });
    // Define range value if not present
    if(!this.filterByTab["Filter By Time"].columns[indexStart].filterSettings.lowValue && !this.filterByTab["Filter By Time"].columns[indexStart].filterSettings.highValue){
      this.filterByTab["Filter By Time"].columns[indexStart].filterSettings.lowValue = case_start_timestamp.filterSettings.lowValue;
      this.filterByTab["Filter By Time"].columns[indexStart].filterSettings.highValue = case_start_timestamp.filterSettings.highValue;
    }

    // Get case_end_timestamp
    let filterCaseEnd = {
      dataTableName: "events",
      dataColumnName: "case_end_timestamp",
      includedFilterSettings: 1
    }
    let case_end_timestamp: any = await this.getFilterColumn(filteringScheme, filterCaseEnd);
    let indexEnd = this.filterByTab["Filter By Time"].columns.findIndex(object => {
      return object.dataColumnName === "case_end_timestamp";
    });
    // Define range value if not present
    if(!this.filterByTab["Filter By Time"].columns[indexEnd].filterSettings.lowValue && !this.filterByTab["Filter By Time"].columns[indexEnd].filterSettings.highValue){
      this.filterByTab["Filter By Time"].columns[indexEnd].filterSettings.lowValue = case_end_timestamp.filterSettings.lowValue;
      this.filterByTab["Filter By Time"].columns[indexEnd].filterSettings.highValue = case_end_timestamp.filterSettings.highValue;
    }

    // Define available time range
    this.availableTimeRange = new DateRange<Date>(new Date(moment(case_start_timestamp.filterSettings.lowValue, this.dateTimeFormat).format('L')), new Date(moment(case_end_timestamp.filterSettings.highValue, this.dateTimeFormat).format('L')));

    // Save in localstorage
    this.saveFiltersInLocalStorage();
  }

  async initFilterAttributes(){
    // Get filteringScheme
    let filteringScheme = await this.getActiveFilteringScheme();

    if(!filteringScheme){
      return;
    }

    let events = await this.getDataTable("events");
    let columns = await this.getDataColumns(events);
    let columnsInput: any = {};
    for(let column of Object.entries(columns)){
      if(column[1].dataColumnName.startsWith("input_")){
        if(column[1].dataType != "DateTime" && column[1].dataType != "Real"){
          let values = await this.getDistinctValues(column[1]);
          columnsInput[column[1].dataColumnName] = values;
        }
      }
    }
    let attributes = [];
    for(let [key, attribute] of Object.entries(columnsInput)){
      let columnFilter: any = await this.getFilterColumnType(key);
      let column = {
        name: key,
        filterType: columnFilter.filterType,
        lowValue: null,
        highValue: null,
        values: []
      }
      for(let [key2, attr] of Object.entries(attribute)){
        if(key2 == "values"){
          if(columnFilter.filterType != "RangeFilter"){
            for(let value of attr){
              column.values.push({
                value: value,
                checked: false
              });
            }
          }else{
            column.lowValue = parseInt(attr[0]);
            column.highValue = parseInt(attr[attr.length - 1]);
          }
        }
      }
      attributes.push(column);
    }
    this.attributes = attributes;
    this.isAttributeReady = true;
    let activities = await this.getDataTable("activities");
    let activityColumns: any[] = await this.getDataColumns(activities);
    let activityColumn = activityColumns.find(obj => obj.dataColumnName == "activity_name");
    this.distinctActivities = await this.getDistinctValues(activityColumn);
    this.isActivityValuesReady = true;
  }

  getDataTable(tableName) {
    return new Promise(resolve => {
      this.spotfireAPI.data.getDataTable(tableName,
        response => resolve(response)
      )
    });
  }
  getDataColumns(table) {
    return new Promise<any[]>(resolve => {
      table.getDataColumns(
        response => resolve(response)
      )
    });
  }
  getDistinctValues(column) {
    return new Promise(resolve => {
      column.getDistinctValues(0, 99999,
        response => resolve(response)
      )
    });
  }
  getFilterColumnType(columnName){
    return new Promise(resolve => {
      this.spotfireAPI.filtering.getFilterColumn(
        'Filtering scheme',
        'events',
        columnName,
        1,
        response => resolve(response)
      )
    });
  }

  isNumeric(str) {
    if (typeof str != "string") return false;
    if (str.includes(' ') || str.includes('/') || str.includes('-') || str.includes(':')) return false;
    return !isNaN(parseFloat(str))
  }
}