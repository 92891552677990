import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-toggle-superadmin-dialog',
  templateUrl: './toggle-superadmin-dialog.component.html',
  styleUrls: ['./toggle-superadmin-dialog.component.scss'],
})
export class ToggleSuperadminDialogComponent {
  @Output() superadminUpdated = new EventEmitter<boolean>(); // Define event emitter
  roles: string[];
  selectedRole: string;
  userId: string;
  superadmin: boolean;
  confirmationInput: string = ''; // Variable to store user input
  @ViewChild('confirmationInputField') confirmationInputField: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ToggleSuperadminDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { superadmin: boolean; userId: string },
    private usersService: UsersService,
    private snackBarService: SnackbarService,
    private translationService: TranslationService,

  ) {
    this.userId = data.userId;
    this.superadmin = data.superadmin;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.usersService.toggleSuperadmin(this.userId).subscribe(
      () => {
        if (this.superadmin) {
          this.snackBarService.openSnackBar(
            this.translationService.translate('toggle.superadmin.snackBar.revoked'),
            this.translationService.translate('toggle.superadmin.snackBar.close'),'success'
          );
        } else {
          this.snackBarService.openSnackBar(
            this.translationService.translate('toggle.superadmin.snackBar.granted'),
            this.translationService.translate('toggle.superadmin.snackBar.close'),'success'
            
          );
        }
        this.superadminUpdated.emit(/* Updated superadmin value */);
      },
      (error) => {
        this.snackBarService.openSnackBar(this.translationService.translate('toggle.superadmin.snackBar.error'), this.translationService.translate('toggle.superadmin.snackBar.close'),'error');
        console.error('Error changing superadmin role:', error);
        // Handle error
      }
    );
    this.dialogRef.close(true);
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.confirmationInput === 'CONFIRM') {
        this.onSaveClick();
      }
    }
  }
}
