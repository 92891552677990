<div id="admin-statistics">
    <div class="flex">
        <div class="flex-item width-xs-10">
            <mat-card>
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'filter' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [placeholder]="'administration.label.search' | translate">
                    </mat-form-field>
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="firstName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'first.name' | translate }}</th>
                          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'last.name' | translate }}</th>
                          <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'email' | translate }}</th>
                          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'role' | translate }}</th>
                          <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                        </ng-container>
                        <ng-container matColumnDef="superadmin">
                          <th mat-header-cell *matHeaderCellDef>{{ 'administration.label.suparadmin' | translate }}</th>
                          <td mat-cell *matCellDef="let element">
                            <mat-slide-toggle color="primary" [checked]="element.superadmin" (change)="toggleSuperadmin($event, element.id, element.superadmin)"></mat-slide-toggle>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef>{{ 'administration.label.actions' | translate }}</th>
                          <td mat-cell *matCellDef="let element">
                            <div class="actions-column-cell">
                                <button mat-stroked-button (click)="changeRole(element.id)" color="primary" matTooltipClass="custom-tooltip" [matTooltip]="'administration.button.changeRole' | translate">
                                    <mat-icon class="material-symbols-rounded">change_circle</mat-icon>
                                </button>
                                <button mat-stroked-button (click)="removeUser(element.id)" matTooltipClass="custom-tooltip" [matTooltip]="'administration.button.remove.user' | translate" color="error">
                                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
                                </button>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
                      </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>