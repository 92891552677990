import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryService } from '@tibco/discover-client-lib';
import { Observable } from 'rxjs';
import { delay, filter, repeatWhen, take } from 'rxjs/operators';
  
@Component({
    selector: 'stop-creation',
    templateUrl: './stop-creation.component.html',
    styleUrls: ['./stop-creation.component.scss']
})
  
  export class NewAnalysisStopCreationComponent implements OnInit {
    // @Output() folderSelected: EventEmitter<any> = new EventEmitter();
    progress: any = {};

    constructor(
        public dialogRef: MatDialogRef<NewAnalysisStopCreationComponent>,
        private repositoryMS: RepositoryService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // don't close dialog when the outside is clicked
        dialogRef.disableClose = true;
        console.log('data in new document dialog', data);
    }
  
    ngOnInit(): void {
        this.progress = {
            message1: 'There is another analysis in process mining.',
            message2: 'Wait for the run to complete before creating the new analysis.',
            percentage: 0,
            status: '',
            ready: false
        };
        this.startPollingStatus();
    }
  
    public onCancel = (): void => {
      this.dialogRef.close();
    }
  
    public closeDialog() {
      this.dialogRef.close();
    }

    private startPollingStatus() {
        const progress = {
            message: 'Process mining',
            stop: false
        };
        this.pollAnalysisStatus(this.data.analysisId, this.progress).subscribe(
            resp => {
            if (resp.metadata && resp.metadata.state) {
                // analysis.metadata = Object.assign({}, resp.metadata);
                // analysis.actions = resp.actions;
                progress.stop = true;
                // this.statusMap[analysis.data.name] = null;
            } else {
                // stopped by setting progress.stop = true
                progress.message = 'Stopped';
            }
            }
        );
    }
    pollAnalysisStatus(analysisId: string, progress: any): Observable<any> {
        return this.repositoryMS.getAnalysisStatus(analysisId).pipe(
          repeatWhen(obs => obs.pipe(delay(2000))),
          filter(data => {
            if (data.progress !== 0) {
              progress.percentage = data.progress;
              progress.status = data.description;
            }
            // stop polling status once the progression is 100
            // if(data.progress === 100) {
            if(data.status.toLowerCase() === 'ready' || data.status.toLowerCase() === 'error') {
                console.log('Status', data.status);
                progress.stop = true;
                this.progress = {
                    message1: 'Now you can create the new analysis.',
                    message2: 'Close the dialog and click the Finish button to start creation.',
                    percentage: 100,
                    status: 'Ready',
                    ready: true
                };
            }
            return progress.stop === true || data.progress === 100
          }),
          take(1)
        )
        // .pipe(
        //   concatMap(() => {
        //     return this.repositoryMS.getAnalysisDetails(analysisId).pipe(
        //       filter(data => (progress.stop === true || data.metadata.status !== 'Process mining')),
        //       take(1)
        //     );
        //   })
        // );
    }
  
  }
  