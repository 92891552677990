<div class="page-container container-flex-fluid">
  <div class="flex">
    <div class="flex-item">
      <div class="data-title-container">
        <div class="data-title">
          <uxpl-icon class="back-icon" icon="pl-icon-chevron-left-unpadded" size="20px" (click)="handleBack()"></uxpl-icon>
          <div class="page-title">{{ 'manage.connections.title' | translate }}</div>
          <uxpl-form-field class="connections-search-widget" type="text" consise valid [label]="searchPlaceholder"
                          (uxplChanged)="handleSearch($event);">
                            <uxpl-icon class="connections-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
                          </uxpl-form-field>
          <!--uxpl-icon class="search-icon" icon="pl-icon-search" width="24px" height="24px"></uxpl-icon-->
        </div>
        <div class="data-title">
          <button color="primary" mat-stroked-button (click)="refresh()">
            <mat-icon class="material-symbols-rounded">refresh</mat-icon>
            {{ 'manage.connections.button.refresh' | translate }}
          </button>
          <button color="primary" mat-flat-button (click)="newConnectionDialog()">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            {{ 'manage.connections.button.connection' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-item">
      <div class="quick-filter">
        <div class="filter-title">{{ 'manage.connections.button.filterBy' | translate }}</div>
        <div *ngFor="let filterOption of filterOptions" [ngClass]="{'filter-option': true, 'option-selected': filterOption?.active }" (click)="setFilter(filterOption.id)">{{filterOption?.label}}</div>
      </div>
      <div class="data-wrapper" *ngIf="loading || (csTableData && csTableData.length>0); else nodata">
        <p-table #connectionsTable [columns]="cols" [value]="csTableData" [globalFilterFields]="['name', 'description']"
                dataKey="name" id="pTable" styleClass="custom-table-css" [rowHover]="false"
                [rows]="15" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                loadingIcon="tibco-spinner-icon" [filterDelay]="0" [expandedRowKeys]="expandedRows">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field"  [class]="'tibco-top-row top-row ' + ('col-' + col.field) " [ngClass]="col.class">
                <div class="header-container">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                              ariaLabelDesc="Activate to sort in descending order"
                              ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </div>
              </th>
              <th style="width: 50px" class="top-row-empty"></th>
              <th style="width: 42px" class="top-row-empty"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-connection let-expanded="expanded">
            <tr class="rowhover" id="toHightlight" [ngClass]="{'row-highlight': connection.id === currentConId}" (dblclick)="edit(connection)">
              <td class="no-padding data-row hovereffect" (click)="ct.toggleRow(connection, $event)">
                <div class="connection-type-block">
                  <uxpl-icon [iconContent]="connection._typeIcon" size="20px" color="#727272"></uxpl-icon>
                  <div [innerHTML]="connection._type"></div>
                </div>
              </td>
              <td class="no-padding data-row hovereffect actionable-data name-data" (click)="ct.toggleRow(connection, $event)" (click)="edit(connection)">
                <div [innerHTML]="connection.name | highlight: searchTerm : 'Multi-Match'"></div>
              </td>
              <td class="no-padding data-row hovereffect" (click)="ct.toggleRow(connection, $event)">
                <div>{{connection.metadata.createdOn | relativeTime}}</div>
              </td>
              <td class="no-padding data-row hovereffect" (click)="ct.toggleRow(connection, $event)">
                <div>{{connection.metadata.modifiedOn | relativeTime}}</div>
              </td>
              <td class="no-padding data-row hovereffect data-status" (click)="ct.toggleRow(connection, $event)">
                <uxpl-tag [icon]="getStateIcon(connection?.metadata?.valid)" class="uxpl-tag"
                          color="{{getStateColor(connection?.metadata?.valid)}}">{{displayStatus(connection?.metadata?.valid)}}</uxpl-tag>
              </td>
              <!--td class="no-padding">
                <div class="button-container">
                  <uxpl-options-menu *ngIf="connectionOptionsMenus[dataset.datasetid]" menu-height="180px" [xPosition]="'before'" [yPosition]="'after'" [iconHeight]="'15px'"
                                    [iconWidth]="'15px'" [config]="datasetOptionsMenus[dataset.datasetid]"
                                    (selected)="optionSelect($event, dataset)"
                                    (click)="handleOptionClick(dataset.datasetid, $event)"></uxpl-options-menu>
                </div>
              </td-->
              <!--td class="hovereffect"></td-->
              <td class="no-padding hovereffect" style="width: 30px !important;">
                <div class="table-action-section">
                  <uxpl-button *ngIf="connection?._rules.canEdit else editPlaceholder" class="edit-button" height="25px" width="70px" type="secondary" (click)="edit(connection)">Edit</uxpl-button>
                  <ng-template #editPlaceholder>
                    <div class="edit-placeholder"></div>
                  </ng-template>
                  <!--div class="icon-holder">
                    <uxpl-icon *ngIf="connection._rules?.canEdit" icon-content='<svg fill="currentColor" viewBox="0 0 24 24" id="pl-icon-edit" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"d="M4.056 16.433L14.604 5.88 18.12 9.4 7.572 19.95l-3.516-3.517zM18.12 2.364a1.242 1.242 0 0 1 1.758 0l1.758 1.759a1.243 1.243 0 0 1 0 1.758L18.999 8.52l-3.516-3.517 2.637-2.638zM2 22l1.17-4.689 3.517 3.518L2 22z"/></svg>' size="24px" (click)="edit(connection)"></uxpl-icon>
                  </div-->
                  <div class="icon-holder">
                    <uxpl-icon *ngIf="connection._rules?.canDelete" icon-content='<svg fill="currentColor" viewBox="0 0 24 24" id="pl-icon-delete" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd"d="M17.188 19.158c0 .54-.42.978-.938.978s-.938-.438-.938-.978v-8.8c0-.54.42-.977.938-.977.517 0 .938.438.938.978v8.799zm-3.75 0c0 .54-.42.978-.938.978s-.938-.438-.938-.978v-8.8c0-.54.42-.977.938-.977s.938.438.938.978v8.799zm-3.75 0c0 .54-.42.978-.938.978s-.938-.438-.938-.978v-8.8c0-.54.42-.977.938-.977s.938.438.938.978v8.799zM5.938 7.335v12.743c0 1.08.839 1.922 1.875 1.922h9.375c1.035 0 1.875-.842 1.875-1.922V7.335H5.938zm10.312-3.38h-7.5C8.75 2.875 9.59 2 10.625 2h3.75c1.036 0 1.875.875 1.875 1.955zm2.813 1.956H5.938C5.42 5.91 5 5.473 5 4.933s.42-.978.938-.978h13.125c.517 0 .937.438.937.978s-.42.978-.938.978"/></svg>' size="24px" (click)="deleteConnection(connection, $event)"></uxpl-icon>
                  </div>
                </div>
              </td>
              <td class="no-padding hovereffect toggle-item" style="width: 30px !important;">
                <a class="tib-toggle-icons" href="#" [pRowToggler]="connection">
                  <uxpl-icon *ngIf="!expanded" icon-content='<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Foundations/system-icon/ic_caretdown" fill="currentColor"><path d="M6.03130366,9 C5.14680436,9 4.674179,9.99617193 5.25698064,10.6320682 L5.25698064,10.6320682 L11.9993572,18 L18.7427634,10.6320682 C19.3265947,9.99617193 18.85191,9 17.9684403,9 L17.9684403,9 L6.03130366,9 Z" id="🎨-Color"></path></g></svg>'
                    class="hover-icon"></uxpl-icon>
                  <uxpl-icon *ngIf="expanded" icon-content='<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M6.03130366,18 C5.18526085,18 4.71605545,17.0885686 5.18689555,16.4528064 L5.25698064,16.3679318 L11.9993572,9 L18.7427634,16.3679318 C19.3012107,16.9761804 18.8911859,17.9140574 18.0813039,17.9944621 L17.9684403,18 L6.03130366,18 Z" id="🎨-Color"></path></svg>' class="hover-icon"></uxpl-icon>
                </a>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-connection let-columns="columns">
            <tr style="background-color: #FFFFFF">
              <td class="addl-info-section" [attr.colspan]="columns.length + 2">
                <div class="ui-g ui-fluid">
                  <connection-addl-info [connection]="connection" (datasetSelection)="handleDatasetSelection($event)"></connection-addl-info>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <ng-template #nodata>
        <div id="nodata-connection" class="center">
          <div class="img-center">
            <img width="204" src="assets/images/png/no-data.png">
          </div>
          <div *ngIf="csTableData?.length==0" class="nodata-msg" style="margin-top: 15px">
            {{ 'manage.connections.message1' | translate }}<br> {{ 'manage.connections.message2' | translate }} <span class="hyperlink" (click)="newConnectionDialog()">{{ 'manage.connections.message3' | translate }}</span>{{ 'manage.connections.message4' | translate }}
          </div>
          <div *ngIf="!csTableData" class="nodata-msg">Failed to get connections. Please refresh page to try it again.</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div id="popup-host" [ngStyle]="{ 'top': popupY , 'left': popupX}" class="popup-wrapper" style="position: absolute;">
  <uxpl-popup #deletePopup id="deletepopup" slot="popup" x-position="after" y-position="above"
              content-id="delete-action-confirm" max-width="328px" [maxHeight]="maxDeletePopupHeight">
    <!-- section to show if connection is used -->
    <!-- section to confirm if a connection really needs to be deleted -->
    <action-confirm id="delete-action-confirm"
                    confirmQuestion="Are you sure you want to delete connection '{{connectionToDelete?.name}}'? It can't be undone."
                    doable="true" type="delete" rejectMessage="You can not delete connection"
                    yesBtnLabel="Yes, delete it" noBtnLabel="No" (actionConfirmed)=handleDeleteConfirmation($event)
                    [shown]="showDeleteConfirm"></action-confirm>
  </uxpl-popup>
</div>
