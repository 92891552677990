import { HttpEvent, HttpEventType } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NewAnalysisStepStatus } from 'src/app/models_ui/discover';
import { DocumentationService } from 'src/app/service/documentation.service';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadProcessModelDocumentComponent implements OnInit {

  @Input() loadedFilename: string;
  @Input() filetypes: string;
  @Input() filesize: number;
  @Input() analysisId: string;
  @Input() size: string;
  @Input() error: string;
  @Input() loadedGraphJson: any;
  @Input() loadedPreview: string;
  @Input() loadedSuccess: string;
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter();
  @Output() handleGraphJson: EventEmitter<any> = new EventEmitter();
  @Output() handlePreview: EventEmitter<any> = new EventEmitter();
  @Output() handleFilename: EventEmitter<any> = new EventEmitter();
  @Output() handleSuccess: EventEmitter<string> = new EventEmitter();


  allowedTypes: string[];
  maxFileSize;
  success: string;
  loading: boolean = false;
  graphJson: any;
  preview: string;
  filename: string;

  fileover: boolean;

  constructor(
    private translationService: TranslationService,
    private documentationService: DocumentationService
  ) {
  }

  ngOnInit(): void {
    if (this.filetypes) {
      this.allowedTypes = this.filetypes.split(',').map(t => t.toLowerCase());
    }
    if (this.filesize) {
      this.maxFileSize = this.filesize * 1024 * 1024;
    }
    if(this.loadedGraphJson){
      this.graphJson = this.loadedGraphJson;
    }
    if(this.loadedPreview){
      this.preview = this.loadedPreview;
    }
    if(this.loadedFilename){
      this.filename = this.loadedFilename;
    }
    if(this.loadedSuccess){
      this.success = this.loadedSuccess;
    }
  }

  fileBrowseHandler(files) {
    this.selectFile(files);
  }

  // onFileDropped($event) {
  //   console.log();
  // }

  onFileDrop($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = false;
    // @ts-ignore
    this.selectFile($event.dataTransfer.files);
  }

  private selectFile(files) {
    if (files && files.length > 0) {
      this.loading = true;
      const file = files[0];
      // if (this.allowedTypes && this.allowedTypes.indexOf(this.getSubFileType(file.type))) {
      // if (this.allowedTypes && this.allowedTypes.indexOf(file.name)) {
      const fType = file?.name?.substring(file?.name?.lastIndexOf('.') + 1, file?.name?.length);
      let fileOk = false;
      this.allowedTypes.forEach(fileType => {
        if (fType.indexOf(fileType) > -1) {
          fileOk = true;
        }
      })
      if (!fileOk) {
        this.loading = false;
        this.error = this.translationService.translate('process.analysis.model.upload.step.upload.drop.error.type');
        return;
      }

      if (this.maxFileSize && file.size >= this.maxFileSize) {
        this.loading = false;
        this.error = this.translationService.translate('process.analysis.model.upload.step.upload.drop.error.type')+`${this.filesize}MB.`;
        return;
      }

      this.error = undefined;

      this.filename = files[0].name;

      this.documentationService.uploadDiagram(this.analysisId, file).subscribe({
        next: (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (event.total) {
                this.loading = true;
              }
              break;
            case HttpEventType.Response:
              this.loading = false;
              this.error = null;
              this.success = this.translationService.translate('process.analysis.model.upload.step.upload.drop.success.message');
              this.graphJson = event.body.nimbus_json;
              this.preview = event.body.image;
              this.updateStatus();
              break;
          }
        },
        error: (e: any) => {
          this.loading = false;
          this.graphJson = null;
          this.preview = null;
          console.error(e);
          this.error = this.translationService.translate('process.analysis.model.upload.step.upload.drop.error.prefix')+this.translationService.translate('generic.error.message.undefined');
          this.success = null;
          this.updateStatus();
        }
      });
    }
  }

  onDragover($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = true;
  }

  onDragleave($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = false;
  }

  public updateStatus() {
    let status = false;
    if(this.error == null && this.filename){
      status = true
    }
    const stepStatus: NewAnalysisStepStatus = {
      step: 'upload-doc',
      completed: status
    };

    this.status.emit(stepStatus);
    this.handleGraphJson.emit(this.graphJson);
    this.handlePreview.emit(this.preview);
    this.handleFilename.emit(this.filename);
    this.handleSuccess.emit(this.success);
  }
}
