<div class="no-access-container">
  <div class="center-container">
    <div class="img-center">
      <img width="386" src="assets/images/svg/no-plan.svg">
    </div>
    <div class="no-data-available"><br></div>
    <div class="top-text">It looks like you don’t have access to<br> Project Discover yet</div>
    <div class="bottom-text"><br><span style="color: red" *ngIf="message">{{message}}<br></span><br>
      Check your organization’s <span class="link-text" (click)="goSubscription()">subscription</span> plans or <span
        class="link-text" (click)="goContact()">contact</span> your<br> account admin to request access.
    </div>
  </div>
</div>
