import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Template } from '@tibco/discover-client-lib';
import { OptionsMenuConfig } from '@tibco/tc-web-components/dist/types/models/optionsMenuConfig';
import { CardMode } from '../../../models_ui/configuration';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.css']
})
export class TemplateCardComponent implements OnInit, OnChanges {

  @Input() cardConfig: Template;
  @Input() mode: CardMode // mode can be 'copy' or 'select'
  @Output() selectedCard: EventEmitter<{ mode: CardMode, template: Template, location: DOMRect}> = new EventEmitter<{ mode: CardMode, template: Template, location: DOMRect}>();

  @Input() highLight: string;
  @Input() isSelected: boolean;

  public typeCSS = 'top_general';
  public glowCSS = ''

  constructor(    private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.translationService.currentLang$.subscribe(() => {
    });

    this.setCSS();
  }
  updateLabels() {


  }

  public optionClicked(mode: CardMode, location: DOMRect) {
    this.selectedCard.emit({mode, template: this.cardConfig, location});
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setCSS()
  }

  public showSelect = (): boolean => {
    return this.mode === 'select' && !this.isSelected;
  }

  public showCopy = (): boolean => {
    return this.mode === 'copy' || this.mode === 'edit' || this.mode === 'delete';
  }

  public canEditOrDelete = (): boolean => {
    return this.showCopy() && this.cardConfig?.type !== 'General' && this.cardConfig.type !== 'Vertical';
  }

  get isSelectionMode(): boolean {
    return this.mode?.toLowerCase() === 'select';
  }

  private setCSS() {
    this.typeCSS = 'top_' + this.cardConfig?.type.toLowerCase().replace(' ', '_');
    if (this.isSelected) {
      this.glowCSS = 'glow_' + this.cardConfig?.type.toLowerCase().replace(' ', '_');
    }
  }

  handleOptionSelected(event) {
    const location: DOMRect = event.target?.getBoundingClientRect();
    // To stop the event being called multiple times
    event.stopPropagation();
    this.optionClicked(event?.detail?.id, location);
  }

  get templateOptions(): OptionsMenuConfig {
    const templateConfig: OptionsMenuConfig = {
      options: [
        { id: 'copy', label: this.translationService.translate("template.card.label"), iconName: 'pl-icon-copy'}
      ]
    }
    if (this.cardConfig?.type === Template.TypeEnum.UserDefined) {
      templateConfig.options.push(
        { id: 'edit', label: this.translationService.translate("template.card.edit"), iconName: 'pl-icon-edit'},
        { id: 'delete', label: this.translationService.translate("template.card.delete"), iconName: 'pl-icon-delete'}
      )
    }
    return templateConfig;
  }
}

