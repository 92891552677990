import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { GetCacheStatusResponse, GetStatusReportResponse } from '../models_ui/repository';
import { ReportTypeEnum } from '../models_ui/analysis';
import { Analysis } from '@tibco/discover-client-lib';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RepositoryService {
  constructor(private http: HttpClient) {}

  getAnalysis(analysisId: Analysis): Observable<any> {
    return this.http.get<any>(
      endpoints.repository.analysis + '/' + analysisId,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    )
    .pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  unarchive(orgData: any): Observable<any> {
    const analysisId = orgData.id;
    const version = orgData.version;
    return this.http.post<any>(
      endpoints.repository.analysis +
        `/${analysisId}/${version}/action/unarchive`,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  archive(orgData: any): Observable<any> {
    const analysisId = orgData.id;
    const version = orgData.version;
    return this.http.post<any>(
      endpoints.repository.analysis +
        `/${analysisId}/${version}/action/archive`,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  createReport(analysisId: string, language: string): Observable<any> {
    return this.http.post<any>(
      endpoints.repository.report + analysisId + '/' + language,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  getReportStatus(analysisId: string, language: string): Observable<GetStatusReportResponse> {
    return this.http.get<GetStatusReportResponse>(
      endpoints.repository.reportStatus + analysisId + '/' + language,
      {
        withCredentials: true,
      }
    );
  }

  downloadReport(analysisId: string, language: string, type: ReportTypeEnum): Observable<any> {
    return this.http.get(
      endpoints.repository.downloadReport + analysisId + '/' + language + '/' + type,
      {
        responseType: 'blob',
        withCredentials: true,
    });
  }

  createCache(analysisId: string): Observable<any> {
    return this.http.post<any>(
      endpoints.repository.cache + analysisId,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  getCacheStatus(analysisId: string): Observable<GetCacheStatusResponse> {
    return this.http.get<GetCacheStatusResponse>(
      endpoints.repository.cacheStatus + analysisId,
      {
        withCredentials: true,
      }
    );
  }

  deleteCache(analysisId: string): Observable<any> {
    return this.http.put<any>(
      endpoints.repository.cache + analysisId,
      null,
      {
        withCredentials: true,
      }
    );
  }
}
