import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TButton} from '../../../models_ui/buttons';
import {Investigation, InvestigationApplication} from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'investigation-list',
  templateUrl: './investigation-list.component.html',
  styleUrls: ['./investigation-list.component.css']
})
export class InvestigationListComponent implements OnInit {

  @Input() cConfig: InvestigationApplication;
  @Input() investigations: Investigation[];
  @Input() focusIds: string[];
  @Output() refreshEvent: EventEmitter<string> = new EventEmitter<string>();

  public caseRefs: string[];
  public tableLoading = false;
  public actionButtons: TButton[];
  public searchTerm: string;
 placeholderSearch: string;
  constructor(    private translationService: TranslationService,
  ) {
  }

  public firstLoad = false;

  ngOnInit(): void {
    this.translationService.currentLang$.subscribe(() => {
      this.placeholderSearch = this.translationService.translate("investigation.placeholder")
    });




    (async () => {
      if (!this.firstLoad) {
        this.tableLoading = true;
        this.tableLoading = false;
        this.firstLoad = true;
      }
    })();
  }

  public handleSearch = ($event): void => {
    this.searchTerm = $event.detail.value;
  }
}
