<div class="activation-content" *ngIf="userinfo">
  <div class="activation-title">Hello {{userinfo?.given_name}}, welcome to</div>
  <div class="split-panel">
      <div class="left-split">
        <div>
          <div class="project-title">
            <!-- todo: replace with discover logo) -->
            <svg width="64px" height="65px" viewBox="0 0 64 65" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                  <polygon id="path-s3srg_ru6v-1" points="3.33066907e-16 -2.18466738e-16 7.40825651 -2.18466738e-16 7.40825651 7.45164248 3.33066907e-16 7.45164248"></polygon>
                  <polygon id="path-s3srg_ru6v-3" points="0 0 6.31062117 0 6.31062117 20.6734469 0 20.6734469"></polygon>
              </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Logo_v1_app-logo" transform="translate(-0.000000, 0.511529)">
                      <path d="M13.75,0 C6.156125,0 0,6.1560625 0,13.75 L0,13.75 L0,50.25 C0,57.8439375 6.156125,64 13.75,64 L13.75,64 L50.25,64 C57.843875,64 63.9999375,57.8439375 63.9999375,50.25 L63.9999375,50.25 L63.9999375,13.75 C63.9999375,6.1560625 57.843875,0 50.25,0 L50.25,0 L13.75,0 Z" id="Fill-1" fill="#2B94EB" fill-rule="nonzero"></path>
                      <g id="Logo-v1" transform="translate(9.739270, 10.434783)">
                          <g id="Group-15" transform="translate(-0.000000, 0.000000)">
                              <g id="Fill-1-Clipped" transform="translate(39.107923, 14.210910)">
                                  <mask id="mask-s3srg_ru6v-2" fill="white">
                                      <use xlink:href="#path-s3srg_ru6v-1"></use>
                                  </mask>
                                  <g id="path-1"></g>
                                  <path d="M3.70438505,-2.18466738e-16 C1.6621642,-2.18466738e-16 3.33066907e-16,1.67152373 3.33066907e-16,3.72582124 C3.33066907e-16,5.78011874 1.6621642,7.45164248 3.70438505,7.45164248 C5.7466059,7.45164248 7.4083032,5.78011874 7.4083032,3.72582124 C7.4083032,1.67152373 5.7466059,-2.18466738e-16 3.70438505,-2.18466738e-16" id="Fill-1" fill="#FEFEFE" fill-rule="nonzero" mask="url(#mask-s3srg_ru6v-2)"></path>
                              </g>
                              <path d="M32.7124186,24.7032797 C30.6701978,24.7032797 29.0085005,26.3748035 29.0085005,28.429101 C29.0085005,30.4833985 30.6701978,32.1549222 32.7124186,32.1549222 C34.7546395,32.1549222 36.4163368,30.4833985 36.4163368,28.429101 C36.4163368,26.3748035 34.7546395,24.7032797 32.7124186,24.7032797" id="Fill-4" fill="#FEFEFE" fill-rule="nonzero"></path>
                              <path d="M32.7124186,33.7892018 C31.5222904,33.7892018 30.4283435,33.3759001 29.557108,32.688786 L29.557108,42.8592964 L35.8677292,42.8592964 L35.8677292,32.688786 C34.9964937,33.3759001 33.9025469,33.7892018 32.7124186,33.7892018" id="Fill-6" fill="#FEFEFE" fill-rule="nonzero"></path>
                              <path d="M32.7124186,22.9374481 C33.6284765,22.9374481 34.4871057,23.1830808 35.2322782,23.6085937 C33.1541061,15.9587543 26.1879572,10.3176552 17.9336311,10.3176552 C14.8142719,10.3176552 11.879805,11.1245329 9.32165963,12.5391521 C12.094579,8.79078708 16.5170564,6.34761021 21.508218,6.34761021 L24.301681,6.34761021 C30.5455355,6.34761021 35.9172206,10.1575008 38.2531216,15.5849041 C39.1047473,13.9208949 40.8266747,12.7777399 42.8124006,12.7777399 C43.2144016,12.7777399 43.6042631,12.8294026 43.9805846,12.9181685 C40.6399147,5.31858282 33.0789352,0.00014089832 24.301681,0.00014089832 L21.508218,0.00014089832 C9.64895657,0.00014089832 -2.26706674e-16,9.70568684 -2.26706674e-16,21.6355476 L-2.26706674e-16,28.3573368 L-2.26706674e-16,39.2638061 L-2.26706674e-16,42.8590615 L6.31015426,42.8590615 L6.31015426,39.2638061 L6.31015426,28.3573368 C6.31015426,21.9102993 11.5240272,16.6655942 17.9336311,16.6655942 C23.0415178,16.6655942 27.3808869,20.0011271 28.9365979,24.6174257 C29.8746001,23.5879286 31.2178715,22.9374481 32.7124186,22.9374481" id="Fill-8" fill="#FEFEFE" fill-rule="nonzero"></path>
                              <g id="Fill-10-Clipped" transform="translate(39.499652, 22.185896)">
                                  <mask id="mask-s3srg_ru6v-4" fill="white">
                                      <use xlink:href="#path-s3srg_ru6v-3"></use>
                                  </mask>
                                  <g id="path-3"></g>
                                  <path d="M3.3126559,1.22675471 C2.05015815,1.22675471 0.894580506,0.76366889 0,0 L0,20.6735408 L6.31062117,20.6735408 L6.31062117,0.244693415 C5.46599896,0.85994941 4.43228224,1.22675471 3.3126559,1.22675471" id="Fill-10" fill="#FEFEFE" fill-rule="nonzero" mask="url(#mask-s3srg_ru6v-4)"></path>
                              </g>
                              <path d="M12.3945156,4.31142178e-16 L2.41639455,4.31142178e-16 C1.11094199,4.31142178e-16 0.0529464664,1.06425198 0.0529464664,2.37742432 L0.0529464664,12.4154903 C2.31881244,6.78096648 6.79358258,2.27926516 12.3945156,4.31142178e-16" id="Fill-13" fill="#FEFEFE" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
            </svg>
            <div class="activation-project">Project Discover</div>
          </div>
        </div>
        <div class="project-description">Understanding the variety of business processes within an organization can be very challenging. How do you know if processes are being executed as expected? Do resource bottlenecks exist? And just how many variations of the same process do you have? Project Discover solution is a process-mining solution built on the TIBCO Connected Intelligence Cloud that allows customers to discover, learn, improve and predict.</div>
        <div class="project-activation-inst">You can request access for your Tibco Cloud Organization {{userinfo?.organization_name}} by clicking below.</div>
        <uxpl-button width="133px" height="40px" type="cta"><div class="button-action-text">Request access</div></uxpl-button>
      </div>
      <div class="image-container">
        <img class="project-image" src="../../../assets/images/discover.png">
      </div>
  </div>

</div>
