<div class="fixActionRow">
  <h1 mat-dialog-title>Welcome to Discover - New Features</h1>
  <div mat-dialog-content class="dialog-content">
    <img src="assets/images/whats-new-banner.png" style="width: 100%">
    <h2>What's new:</h2>
    <ul>
      <li *ngFor="let newItem of newItems">
        <div>{{newItem}}<br></div>
      </li>
    </ul>
  </div>
  <div>
    <div mat-dialog-actions>
      <uxpl-checkbox class='default-checkbox' [checked]="dontShowAgain" (clicked)="dontShowAgain = $event.detail.checked">Don't show again</uxpl-checkbox>
      <uxpl-button (clicked)="okToClose()">Ok</uxpl-button>
    </div>
  </div>
</div>
