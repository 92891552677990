<div class="analysis-confirmation-container">
  <div class="analysis-left">
    <div class="spec-title">{{ 'confirmation.label.analysis' | translate }}</div>
    <table class="detail-table">
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.name' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.name}}</div>
        </td>
      </tr>
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.description' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.description}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="(dataSet$ | async) as dataset">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.dataset' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{dataset.name}}</div>
        </td>
      </tr>
    </table>
    <!-- TODO: Add Scheduling
    <div class="spec-title">Scheduling</div>
    <div class="item-detail">???</div>   -->
    <div class="spec-title" *ngIf="hasStart || hasStop">{{ 'confirmation.label.startStop' | translate }}</div>
    <table class="detail-table">
      <tr class="detail-row" *ngIf="hasStart">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.startActivities' | translate }}</div>
        </td>
        <td>
          <ul>
            <li class="menu-item" *ngFor="let act of startActivities">{{act}}
            </li>
          </ul>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="hasStop">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.stopActivities' | translate }}</div>
        </td>
        <td>
          <ul>
            <li class="menu-item" *ngFor="let act of stopActivities">{{act}}
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
  <div class="middle-divider"></div>
  <div class="analysis-right">
    <div class="spec-title" *ngIf="data.mappings">{{ 'confirmation.label.mappings' | translate }}</div>
    <table class="detail-table" *ngIf="data.mappings">
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.caseId' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.caseId}}</div>
        </td>
      </tr>
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.startTime' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.startTime}}</div>
        </td>
      </tr>
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.activity' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.activity}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.endTime">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.activityEnd' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.endTime}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.requester">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.requester' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.requester}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.resource">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.resource' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.resource}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.resourceGroup">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.resourceGroup' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.resourceGroup}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.scheduledStart">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.scheduledStart' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.scheduledStart}}</div>
        </td>
      </tr>
      <tr class="detail-row" *ngIf="data.mappings.scheduledEnd">
        <td class="header-location">
          <div class="item-header">{{ 'confirmation.label.scheduledEnd' | translate }}</div>
        </td>
        <td>
          <div class="item-detail">{{data.mappings.scheduledEnd}}</div>
        </td>
      </tr>
      <tr class="detail-row" >
        <td class="header-location">
          <!--<pre>{{newAnalysis|json}}</pre> -->
          <div class="item-header">{{ 'confirmation.label.others' | translate }}</div>
        </td>
        <td>
          <!--<div class="item-detail" *ngIf="data.Mapping.Otherattributes">All other attributes are also taken into account...</div>-->
          <div class="item-detail" *ngIf="!data.mappings.otherAttributes">{{ 'confirmation.label.none' | translate }}</div>
          <ul *ngIf="data.mappings.otherAttributes">
            <li class="menu-item" *ngFor="let oField of otherFields">{{oField}}
            </li>
          </ul>
        </td>
      </tr>
    </table>
    <div class="spec-title" *ngIf="hasFilter">{{ 'confirmation.label.filters' | translate }}</div>
    <table class="detail-table">
      <tr class="detail-row" *ngFor="let filter of data.filters | activities:':'">
        <td class="header-location">
          <div class="item-header">{{filter.name}}</div>
        </td>
        <td>
          <div class="item-detail">{{
            createReadableArrayString(filter.values, 50)}}</div>
        </td>
      </tr>
    </table>
  </div>
</div>
