import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewAnalysisStepStatus } from 'src/app/models_ui/discover';
import {ActivityMap, GraphEdge, GraphNode} from '../../../models_ui/processModel';

@Component({
  selector: 'upload-document-map',
  templateUrl: './upload-document-map.component.html',
  styleUrls: ['./upload-document-map.component.scss']
})
export class UploadDocumentMapComponent implements OnInit {

  @Input() loadedGraphJson: GraphNode[] | GraphEdge[];
  @Input() newGraphJson: GraphNode[] | GraphEdge[];
  @Input() loadedDataSource: ActivityMap[];
  @Input() activities: string[];
  @Output() status: EventEmitter<any> = new EventEmitter();
  @Output() handleNewGraphJson: EventEmitter<any> = new EventEmitter();
  @Output() handleLoadedDataSource: EventEmitter<any> = new EventEmitter();
  displayedColumns = ['activities', 'mapping'];
  dataSource: ActivityMap[] = [] as ActivityMap[];

  constructor() { }

  ngOnInit(): void {
    if(!this.newGraphJson){
      this.dataSource = this.preMapping();
      this.newGraphJson = JSON.parse(JSON.stringify(this.loadedGraphJson))
    }
    if(this.loadedDataSource){
      this.dataSource = this.loadedDataSource;
    }
    this.updateJsonGraph();
    setTimeout(() => {
      this.updateStatus();
    }, 0);
  }

  ngChanges(): void {}

  preMapping() {
    let output: ActivityMap[] = [];
    for(let [index, act] of this.loadedGraphJson.entries()){
      if(act.group == "nodes" && act.data.id.toLowerCase() != 'start' && act.data.id.toLowerCase() != 'stop'){
        let match = this.activities.find(value => value.toLowerCase() == act.data.id.toLowerCase());
        if(!match){
          match = '';
        }
        output.push({
          index: index,
          activity: act.data.id,
          activityLower: act.data.id.toLowerCase(),
          map: match,
          mapLower: match.toLowerCase()
        });
      }
    }
    return output;
  }

  onSelectionChange(index: number, event: any) {
    this.updateDataSource(index, event.value);
    this.updateStatus();
  }

  updateDataSource(index, newValue){
    let data = this.dataSource.find(value => value.index == index);
    const newLabel = this.activities.find(value => value.toLowerCase() == newValue);
    data.map = '';
    if(newLabel){
      data.map = newLabel;
    }
    this.updateJsonGraph();
  }

  updateJsonGraph() {
    for(let data of this.dataSource){
      let activity = data.map;
      if(!data.mapLower){
        activity = data.activity;
      }
      const graphJsonIndex = this.loadedGraphJson.findIndex(value => value.data.id.toLowerCase() == data.activityLower);
      this.newGraphJson[graphJsonIndex].data.id = activity;

      let edgeSoruceIndex: number[] = [];
      for(let [index, obj] of this.loadedGraphJson.entries()){
        if(obj.group == 'edges'){
          if(obj.data.source.toLowerCase() == data.activityLower){
            edgeSoruceIndex.push(index);
          }
        }
      }
      if(edgeSoruceIndex.length > 0){
        for(let index of edgeSoruceIndex){
          this.newGraphJson[index].data.source = activity;
        }
      }

      let edgeTargetIndex: number[] = [];
      for(let [index, obj] of this.loadedGraphJson.entries()){
        if(obj.group == 'edges'){
          if(obj.data.target.toLowerCase() == data.activityLower){
            edgeTargetIndex.push(index);
          }
        }
      }
      if(edgeTargetIndex.length > 0){
        for(let index of edgeTargetIndex){
          this.newGraphJson[index].data.target = activity;
        }
      }
    }
  }

  public updateStatus() {
    const stepStatus: NewAnalysisStepStatus = {
      step: 'map-diagram',
      completed: true
    };

    this.status.emit(stepStatus);
    this.handleNewGraphJson.emit(this.newGraphJson);
    this.handleLoadedDataSource.emit(this.dataSource);
  }
}
