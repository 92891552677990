import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from '../../environments/endpoints';
import { DownloadXesRequest } from '../models_ui/catalog';
@Injectable({
  providedIn: 'root',
})
export class CatalogMsService {
  constructor(private http: HttpClient) {}

  uploadXes(file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(endpoints.catalog.xes+'/upload', formData, {
      observe: 'response',
      headers: new HttpHeaders({
        'Accept': 'application/json, text/plain'
      }),
      withCredentials: true,
      reportProgress: true,
    })
  }

  generateXes(datasetID: string, downloadXesRequest: DownloadXesRequest): Observable<any> {
    return this.http.post(endpoints.catalog.xes+'/generate/'+datasetID, downloadXesRequest, {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      observe: 'response',
      responseType: "blob",
      withCredentials: true,
    })
  }

  downloadXes(datasetID: string): Observable<any> {
    return this.http.post(endpoints.catalog.xes+'/download/'+datasetID, null, {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      observe: 'response',
      responseType: "blob",
      withCredentials: true,
    })
  }

  uploadXlsx(file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(endpoints.catalog.xlsx+'/upload', formData, {
      observe: 'response',
      headers: new HttpHeaders({
        'Accept': 'application/json, text/plain'
      }),
      withCredentials: true,
      reportProgress: true,
    })
  }

  downloadXlsx(datasetID: string): Observable<any> {
    return this.http.post(endpoints.catalog.xlsx+'/download/'+datasetID, null, {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      observe: 'response',
      responseType: "blob",
      withCredentials: true,
    })
  }
}
