<div class="page-container container-flex-fluid">
  <div class="flex">
    <div class="flex-item">
      <uxpl-object-header
        [config]="objHeaderConfig"
        (back)="goProcessAnalysis()"
        (editDetail)="goTemplate()"
      >
      </uxpl-object-header>
    </div>
    <!--  {{ this.analysis | json }} -->
    <div class="flex-item">
      <list-templates
        *ngIf="showListTemplates"
        [templates]="templates"
        [selectedTemplate]="analysis.template?.id"
        mode="select"
        (selected)="handleSelectTemplate($event)"
      ></list-templates>
    </div>
  </div>
</div>
