export class DatasetWizard {
    numberRowsForPreview?: number;
    dataSourceChanged?: boolean;
    attributesUnpredicted?: boolean;
    skipComments?: boolean;
    skipEmptyLines?: boolean;
    dataSourceType?: {[key: string]: string};
    // the topic of tcm, the view name of tdv etc.
    connectionAddtionalFields?: {[key: string]: string};
    editMode = false;
}

export enum DatasetSchemaType {
  STRING = 'String',
  INTEGER = 'Integer',
  NUMERIC = 'Numeric',
  TIMESTAMP = 'Timestamp',
}
