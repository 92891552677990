import {Component, Input} from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.css']
})
export class IconButtonComponent  {

  @Input() text: string;

  @Input() iconLocation: string;

  constructor() {
  }

}
