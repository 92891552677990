import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {abbreviateNumber} from '../../../functions/analysis';
import {FResult, FStat, MStat} from '../../../models_ui/analysis';
import {createReadableArrayString} from '../../../functions/templates';

@Component({
  selector: 'analytic-filter-side-panel',
  templateUrl: './analytic-filter-side-panel.component.html',
  styleUrls: ['./analytic-filter-side-panel.component.css']
})
export class AnalyticFilterSidePanelComponent implements OnInit, OnChanges {

  @Input() filterCount: number;
  @Input() filterStats: FStat[];
  @Input() markingStats: MStat[];
  @Input() filterPanelOpen: boolean;
  @Input() filterPage: string;

  @Output() filterClicked: EventEmitter<any> = new EventEmitter();
  @Output() newTabClicked: EventEmitter<any> = new EventEmitter();
  @Output() resetMarkingClicked: EventEmitter<any> = new EventEmitter();

  buttons: { label: string, icon: string, class: string , hoverText: string}[] = [];
  results: FResult[] = [];
  markings: FResult[] = [];

  constructor() {
    this.buttons = [
      {label: 'Filters', icon: 'pl-icon-filter', class: '', hoverText: 'Filter options'} // ,
      /*{label: 'New tab', icon: 'pl-icon-new-tab-window'},*/
      /*{label: 'Filter results', icon: 'pl-icon-filter'},*/
      // TODO: Add Documents created and investigations started
    ]
  }

  ngOnInit(): void {
  }

  buttonClicked(button: { label: string; icon: string }, event) {
    if (button.label === 'Filters') {
      this.filterClicked.emit(event)
    }
    if (button.label === 'New tab') {
      this.newTabClicked.emit(event)
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if(this.filterPanelOpen) {
      this.buttons.find(b => b.label === 'Filters').class = 'filter-open'
    } else {
      this.buttons.find(b => b.label === 'Filters').class = ''
    }
    this.markings = []
    if (this.markingStats && this.markingStats.length > 0) {
      this.markingStats.forEach(mStat => {
        this.markings.push({
          label: mStat.label,
          line: abbreviateNumber(mStat.values?.length), // createReadableArrayString(mStat.values, 8),
          hoverText: createReadableArrayString(mStat.values, 180)
        })
      })
    }
    this.results = [];
    if (this.filterStats && this.filterStats.length > 0) {
      // console.log('Changed stats ', this.filterStats)
      for (const stat of this.filterStats) {
        this.resultHelper('events', stat)
        this.resultHelper('cases', stat)
        this.resultHelper('variants', stat)
      }
    }
  }

  private resultHelper(name: string, stat: FStat) {
    if (stat.name === name) {
      let line = abbreviateNumber(stat.value) + ' of ' + abbreviateNumber(stat.total);
      if (stat.total === stat.value) {
        line = abbreviateNumber(stat.value) + ''
      }
      const result: FResult = {
        label: name,
        line,
        hoverText: Math.ceil(stat.percentage) + '%'
      }
      this.results.push(result)
    }
  }

  clearMarking(){
    this.resetMarkingClicked.emit(true);
  }

}
