import { Injectable } from '@angular/core';
import { parse } from 'papaparse';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(
    protected configService: ConfigurationService,
  ) { }

  public refreshPreview = (file: File, lines: number, config?: any): Observable<{preview: any[], columns: any[], columnSeparator: string}> => {
    let columns = [];
    const preview = [];
    let columnSeparator: string;
    const subject = new Subject<any>();
    this.previewFile(file, lines, config).subscribe({
      next: element => {
        if (columns.length === 0) {
          columns = element.data;
          columnSeparator = element.meta.delimiter;
        } else {
          preview.push(element.data);
        }
      },
      complete: () => {
        subject.next({
          preview,
          columns,
          columnSeparator
        });
        subject.complete();
      }
    });
    return subject;
  }

  private previewFile = (file: File, lines, config?: any): Observable<any> => {
    if ( typeof(lines) === 'string'){
      lines = Number(lines)
    }
    let localConfig = {
      preview: lines + 1,
      download: true,
      step: null,
      complete: null
    }

    if (config) {
      localConfig =  config;
      localConfig.preview = lines + 1;
    }

    let lineCount = 0;
    return new Observable(
      observable =>  {
        localConfig.step =  (result, parser) => {
          lineCount++;
          observable.next(result);
          if (lineCount === localConfig.preview){
            parser.abort();
            observable.complete();
          }
        }
        // if the rows of csv file is less than the preview count, need to complete once no more lines.
        localConfig.complete = () => {
          observable.complete();
        }
        parse(file, localConfig);
      }
    );
  }

  public previewData = (data: string): any => {
    return parse(data, { header: true });
  }
}
