import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileDatasetConfiguration } from '@tibco/discover-client-lib';
import { CsvService } from 'src/app/service/csv.service';
import { DatasetService } from 'src/app/service/dataset.service';
import { ParsingService } from 'src/app/service/parsing.service';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'parse-csv',
  templateUrl: './parse-csv.component.html',
  styleUrls: ['./parse-csv.component.scss']
})

export class DatasetParseCsvComponent implements OnInit, OnChanges {

  @Input() datasetConfig: FileDatasetConfiguration;
  @Input() numberRowsForPreview: number;
  @Input() file: File;
  
  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateNumberRowsForPreview: EventEmitter<any> = new EventEmitter<any>();
  public encodingOptions;

  public columnSeparator: string;
  public customColumnSeparator = '';
  radioOther: string;
  labelEncoding: string;
  labelQuote: string;
  labelEscape:string;
  labelNumberRow:string;
  labelRefresh:string;
  placeholderEncoding: string;
  constructor(
    protected datasetService: DatasetService,
    protected parsingService: ParsingService,
    protected csvService: CsvService,
    private translationService: TranslationService,
  ) { }

  ngOnInit(): void {

    this.translationService.currentLang$.subscribe(() => {
      this.radioOther= this.translationService.translate("parse.csv.radio.other")
      this.labelEncoding= this.translationService.translate("parse.csv.label.encoding")
      this.labelQuote= this.translationService.translate("parse.csv.label.quote")
      this.labelEscape= this.translationService.translate("parse.csv.label.escape")
      this.labelNumberRow= this.translationService.translate("parse.csv.label.numberRow")
      this.placeholderEncoding = this.translationService.translate("parse.csv.placeholder.encoding")
    });


    const supportedEncoding = this.parsingService.getSupportEncoding();
    this.encodingOptions = supportedEncoding.map(ec => {return {label: ec, value: ec}});
    this.initColumnSeparator();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasetConfig && 
      changes.datasetConfig.previousValue && 
      changes.datasetConfig.currentValue && 
      changes.datasetConfig.previousValue.delimiter != changes.datasetConfig.currentValue.delimiter) {
        this.initColumnSeparator();
      }
  }

  handleUpdate = (event, fieldName) => {
    this.datasetConfig[fieldName] = event.detail.value;
    this.clickedRefresh()
  }

  updateNumForRowsPreview = (event) => {
    const value = event.detail.value;
    this.updateNumberRowsForPreview.emit(value);
  }

  public clickedRefresh = (): void => {
    /**
     * Confirmed with Florent. The form of editing parsing option only shows if a csv file is uploaded when create/update dataset.
     * Even for a dataset created by uploading a csv file, when edit it, since in web UI that file handler is not there, so I cannot
     * let the user to change csv parsing options and refresh
     */
    if (this.file) {
      const lines = this.numberRowsForPreview;
      const config = {
        quoteChar: this.datasetConfig.quoteChar,
        escapeChar: this.datasetConfig.escapeChar,
        delimiter: this.datasetConfig.delimiter,
        preview: lines,
        encoding: this.datasetConfig.encoding,
        comments: this.datasetConfig.commentsChar,
        skipEmptyLines: true,
        download: true
      };

      this.csvService.refreshPreview(this.file, lines, config).subscribe(parseResult => {
        this.handlePreviewData.emit(parseResult);
      });
    }
  }

  public initColumnSeparator = (): void => {
    this.columnSeparator = this.datasetConfig.delimiter === '\\t' || this.datasetConfig.delimiter === ',' || this.datasetConfig.delimiter === ';' ? this.datasetConfig.delimiter : 'other';
    if (this.columnSeparator === 'other') {
      this.customColumnSeparator = this.datasetConfig.delimiter;
    }
  }

  onSetColumnSeparator(event) {
    const value = event.detail.value;
    this.setColumnSeparator(value);
  }

  private setColumnSeparator(value) {
    if (value === ';' || value === ',' || value === '\\t'){
      this.columnSeparator = value
      this.datasetConfig.delimiter = value;
    } else {
      this.columnSeparator = 'other';
      this.customColumnSeparator = value === 'other' ? this.customColumnSeparator: value;
      this.datasetConfig.delimiter = this.customColumnSeparator;
    }
    this.clickedRefresh();
  }

  public handleEncodingSelection = ($event): void => {
    this.datasetConfig.encoding = $event.detail.value;
  }

  
}
