<div>
    <div class="basic-info-container" *ngIf="data">
        <uxpl-form-field id="#datasetLabel" fieldId="datasetLabel" style="margin-top: 5px; margin-bottom: 5px;" [label]="namePlaceholder" type="text" [valid]="!nameError" required="true" [value]="data.name" float (uxplChanged)="handleUpdate($event, 'name')"></uxpl-form-field>
        <div *ngIf="nameError" class="error">{{ 'basic.error.name' | translate }}</div>
        <div *ngIf="nameRequired" class="error">{{ 'generic.message.form.field.is.required' | translate }}</div>
        <uxpl-form-field id="#datasetDescription" maxlength="256" fieldId="datasetDescription" style="margin-top: 5px; margin-bottom: 5px;" [label]="descriptionPlaceholder" type="text" valid [value]="data.description" float  (uxplChanged)="handleUpdate($event, 'description')"></uxpl-form-field>
    </div>

    <div class="select-types">
        <div class="title">{{'dataset.basic.info.select.title' | translate}}</div>
        <div class="container-flex-fluid">
            <div class="types-row flex">
                <div *ngFor="let type of datatypes; let first = first;" class="flex-item width-xs-10 width-sm-5 width-md-333 width-lg-25">
                    <mat-card class="type-cell" [ngClass]="{selected: wizard?.dataSourceType?.type == type?.type, required: typeRequired}" (click)="selectDatatype(type)"> 
                        <mat-card-content>
                            <!-- <div *ngFor="let type of datatypes; let first = first;" class="type-cell" [ngClass]="{selected:true}" (click)="selectDatatype(type)"> -->
                            <div class="hint" *ngIf="type.disabled">
                                <uxpl-icon height="24px" width="24px" icon="pl-icon-info" style="color: #727272"></uxpl-icon>
                                <uxpl-tooltip position="Left" class="tooltip" top="-10px"><div style="max-width: 175px">{{type.hint}}</div></uxpl-tooltip>
                            </div>
                            <img [src]="'assets/png/dataset-types/'+type.type+'.png'" class="type-image"/>
                            <div>
                                <div class="type-name">{{type.name}}</div>
                                <div class="type-desc">{{type.description}}</div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>






