import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';


export interface AnalysisAction {
  analysisId: string;
  analysisOnAction: string; 
}


@Component({
  selector: 'app-remove-analysis-dialog',
  templateUrl: './remove-analysis-dialog.component.html',
  styleUrls: ['./remove-analysis-dialog.component.scss'],
})
export class RemoveAnalysisDialogComponent {
  
  @Output() analysisDeleted = new EventEmitter<AnalysisAction>(); // Define event emitter
  roles: string[];
  selectedRole: string;
  analysisId: string;
  name: string;
  metadataVersion: any;
  confirmationInput: string = ''; // Variable to store user input
  analysisOnAction: any = null;
  
  @ViewChild('confirmationInputField') confirmationInputField: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<RemoveAnalysisDialogComponent>,
    private translationService: TranslationService,

    @Inject(MAT_DIALOG_DATA)
    public data: { rowData:any,paToArchive: string; paAction:string;  },

    private snackBarService: SnackbarService
  ) {
    this.analysisId = data.paToArchive;
    this.analysisOnAction = data.paAction

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    if (this.analysisId) {
      const rowId = this.analysisId;
      const action = this.analysisOnAction
      this.snackBarService.openSnackBar(this.translationService.translate('remove.analysis.deleted'), this.translationService.translate('remove.analysis.close'),'success');
      this.analysisDeleted.emit({ analysisId: rowId, analysisOnAction: action });
    }
    this.dialogRef.close();
  }
  
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.confirmationInput === 'DELETE') {
        this.onDeleteClick();
      }
    }
  }
}
