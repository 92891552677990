import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CompanyAndRoles,
  MembershipResponse,
  Organization,
  SetOrganizationRequest,
  UserInfoResponse,
} from '../models_ui/auth';
import { endpoints } from '../../environments/endpoints';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  getMembership(): Observable<CompanyAndRoles[]> {
    return this.http
      .get<CompanyAndRoles[]>(endpoints.authorization.membership, {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .pipe(
        map((res) => {
          return res.body;
        })
      );
  }

  async getMembershipPromise() {
    return this.http
      .get<MembershipResponse>(endpoints.authorization.membership, {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .toPromise();
  }

  async getOrgId() {
    let orgString: string = localStorage.getItem('org');
    let org: Organization = JSON.parse(orgString);
    if (org && org.companyId) {
      return org.companyId;
    } else {
      this.router.navigate(['/select-organization']);
      return;
    }
  }

  getUserinfo(): Observable<UserInfoResponse> {
    return this.http
      .get<UserInfoResponse>(endpoints.authorization.userinfo, {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      })
      .pipe(
        map((res) => {
          return res.body;
        })
      );
  }

  setOrganization(organization: SetOrganizationRequest): Observable<any> {
    return this.http
      .post<any>(endpoints.authorization.setorganization, organization, {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
