import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LandingPageConfiguration} from '@tibco/discover-client-lib';
import {cloneDeep} from 'lodash-es';
import {ConfigurationService} from 'src/app/service/configuration.service';
import {OauthService} from 'src/app/service/oauth.service';
import {environment} from 'src/environments/environment';
import {fetchWithAuthentication} from '../../../functions/common';

@Component({
  templateUrl: './welcome-preview.component.html',
  styleUrls: ['./welcome-preview.component.css']
})
export class WelcomePreviewComponent implements OnInit {

  public landingPage: LandingPageConfiguration;

  constructor(
    public dialogRef: MatDialogRef<WelcomePreviewComponent>,
    protected configService: ConfigurationService,
    protected oauthService: OauthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.landingPage = cloneDeep(this.data);

    const imageUrl = environment.apiURL + '/configuration/resource/' + this.landingPage.backgroundURL;
    this.landingPage.backgroundURL = await fetchWithAuthentication(imageUrl, this.oauthService.token);
    this.landingPage.highlights = await Promise.all(this.landingPage.highlights.map(async highlight => {
      highlight.iconURL = await fetchWithAuthentication(environment.apiURL + '/configuration/resource/' + highlight.iconURL, this.oauthService.token);
      return highlight;
    }));
    this.landingPage.actionButtons = [
      {
        text: 'Get started',
        route: ''
      }
    ];
  }

}
