<div class="tibco-table-ux">
  <!--<pre>{{callCounter}}</pre>-->
  <process-analysis-compare
    *ngIf="selectedAnalysis[0] !== undefined && selectedAnalysis[0].metrics"
    [display]="showCompare"
    (onHide)="toggleCompare()"
    [analysisOptions]="processAnalyses"
    [selectedAnalysis]="selectedAnalysis"
  ></process-analysis-compare>
  <div id="analysis-data-wrapper" class="data-wrapper">
    <p-table
      #processAnalysisTab
      [columns]="cols"
      [value]="processAnalysesTableData"
      *ngIf="processAnalysesTableData && processAnalysesTableData.length > 0"
      dataKey="id"
      id="pTable"
      styleClass="custom-table-css"
      [rowHover]="false"
      [loading]="loading"
      loadingIcon="tibco-spinner-icon"
      [filterDelay]="0"
      [expandedRowKeys]="expandedRows"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="background-color: #fff">
          <th style="width:40px;" class="top-row-empty"></th>
          <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          class="tibco-top-row top-row {{ col.class }}"
          >
            <div class="table-th-wrap">
              {{ col.header }}
              <p-sortIcon
                [field]="col.field"
                ariaLabel="Activate to sort"
                class="sort-icon"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"
              ></p-sortIcon>
            </div>
          </th>
          <th 
            style="width: 50px; text-align: center;" 
            class="tibco-top-row top-row"
            matTooltipClass="custom-tooltip" 
          >
            <div class="table-th-wrap" style="justify-content: center; align-items: center;">
              {{ "actions" | translate }}<mat-icon id="actions-info-btn" class="material-symbols-rounded" style="font-size: 14px;line-height: 14px;height: 14px;" (click)="openActionsInfo()">info</mat-icon>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-i="rowIndex"
        let-expanded="expanded"
        let-columns="columns"
      >
        <tr
          [ngClass]="
            rowData.folder == 'General' ? 'rowhover active' : 'rowhover'
          "
          id="toHightlight"
          [attr.data-folder]="rowData.folder"
          style="background: transparent"
          draggable="true"
          (dragstart)="dragStartAnalysis(rowData.id)"
        >
          <td style="width:40px;" class="no-padding hovereffect toggle-item">
            <a class="tib-toggle-icons" href="#" style="width: 40px !important; display: block; text-align: center;" [pRowToggler]="rowData" [matTooltip]="'manage.process.analysis.additional.info.tooltip' | translate" matTooltipClass="custom-tooltip" matTooltipPosition="above">
              <mat-icon *ngIf="!expanded" class="material-symbols-rounded">arrow_drop_down</mat-icon>
              <mat-icon *ngIf="expanded" class="material-symbols-rounded">arrow_drop_up</mat-icon>
            </a>
          </td>
          <td
            *ngFor="let col of columns"
            class="no-padding data-row hovereffect {{ col.class }}"
            (dblclick)="open(rowData)"
            (click)="processAnalysisTab.toggleRow(rowData, $event)"
            [data-folder]="rowData.folder"
          >
            <!--div *ngIf="col.field != 'state'" [innerHTML]="obtainData(rowData, col.field) | highlight: searchTerm : 'Multi-Match'"></div-->
            <div *ngIf="col.field === 'modifiedOn'">
              {{ rowData?.modifiedOn | relativeDate: currentLanguage }}
            </div>
            <div *ngIf="col.field === 'state'" class="status-cell data-status">
              <uxpl-tag
                *ngIf="rowData[col.field] !== 'Process mining'"
                matTooltip="{{ rowData.toolTip }}"
                matTooltipClass="tTip"
                icon="{{
                  'assets/images/states/' + rowData[col.field] + '.svg'
                }}"
                class="uxpl-tag"
                color="{{ rowData.ngClass }}"
                >{{ rowData[col.field] | translate }}</uxpl-tag
              >
              <div class="progress-bar-holder">
                <mini-progress-bar
                  *ngIf="rowData[col.field] === 'Process mining'"
                  [percentage]="statusMap[rowData?.name]?.percentage"
                  [message]="rowData[col.field]"
                ></mini-progress-bar>
              </div>
            </div>
            <div
              *ngIf="
                col.field !== 'state' &&
                col.field !== 'modifiedOn' &&
                col.field !== 'description'
              "
              [innerHTML]="
                rowData[col.field] | highlight : searchTerm : 'Multi-Match'
              "
              [ngClass]="col?.dataClass"
              (click)="dataAction(col.field, rowData)"
            ></div>
            <div
              *ngIf="col.field === 'description'"
              innerHTML="<div class='description-overflow-text'>{{
                rowData[col.field] | highlight : searchTerm : 'Multi-Match'
              }}</div>"
              [ngClass]="col?.dataClass"
              (click)="dataAction(col.field, rowData)"
            ></div>
          </td>
          <td class="no-padding hovereffect" style="width: 30px !important">
            <div class="table-action-section">
              <button mat-stroked-button color="primary" class="small-button" [matTooltip]="'manage.process.analysis.button.open.fast' | translate" matTooltipClass="custom-tooltip" *ngIf="rowData.canArchive && rowData.state == 'Ready' && rowData.cacheStatus == 'completed';else standardOpen" (click)="open(rowData)">
                <mat-icon class="success-color">offline_bolt</mat-icon>
                <ng-template #standardOpen><mat-icon>play_circle</mat-icon></ng-template>
              </button>
              <ng-template #standardOpen>
                <button mat-stroked-button color="primary" class="small-button" [matTooltip]="'manage.process.analysis.button.open' | translate" matTooltipClass="custom-tooltip" (click)="open(rowData)">
                  <mat-icon class="material-symbols-rounded">play_circle</mat-icon>
                </button>
              </ng-template>
              <button mat-stroked-button [color]="rowData.cacheStatus == 'none' ? 'primary' : (rowData.cacheStatus == 'completed' ? 'success' : (rowData.cacheStatus == 'failed' ? 'error' : ''))" class="small-button cache-button" [disabled]="!(rowData.canArchive && rowData.state == 'Ready')" [matMenuTriggerFor]="cacheMenu"> 
                <mat-icon 
                  matTooltipClass="custom-tooltip" 
                  [matTooltip]="rowData.cacheTooltip" 
                >speed</mat-icon>
                <!-- {'primary': rowData.cacheStatus == 'none', 'primary': rowData.cacheStatus == 'completed', 'warn': rowData.cacheStatus == 'failed'} -->
                <span class="material-symbols-rounded cache-waiting" *ngIf="rowData.cacheStatus == 'creating'">cached</span>
                <mat-menu #cacheMenu="matMenu" xPosition="before">
                  <button *ngIf="rowData.cacheAction != 'none'" mat-menu-item (click)="handleCache(rowData.id, rowData.cacheAction)">
                    <span>{{rowData.cacheLabel}}</span>
                  </button>
                  <button *ngIf="rowData.cacheAction == 'none'" mat-menu-item>
                    <span>{{rowData.cacheLabel}}</span>
                  </button>
                </mat-menu>
              </button>
              <button mat-stroked-button [color]="rowData.reportFound ? 'success' : 'primary'" class="small-button" [matTooltip]="'process.analysis.report.tooltip' | translate" matTooltipClass="custom-tooltip" (click)="openReport(rowData.id)" *ngIf="!rowData.canDelete" [disabled]="!(rowData.canArchive && rowData.state == 'Ready')">
                <mat-icon svgIcon="report-icon"></mat-icon>
              </button>
              <button mat-stroked-button color="primary" class="small-button" *ngIf="rowData.canArchive" [matTooltip]="archiveTooltip" matTooltipClass="custom-tooltip" (click)="action(rowData, 'archive', $event)">
                <mat-icon class="material-symbols-rounded">archive</mat-icon>
              </button>
              <button mat-stroked-button color="primary" class="small-button" *ngIf="rowData.canDelete" [matTooltip]="unarchiveTooltip" matTooltipClass="custom-tooltip" (click)="unarchive(rowData, $event)">
                <mat-icon class="material-symbols-rounded">unarchive</mat-icon>
              </button>
              <button mat-stroked-button color="error" class="small-button" *ngIf="rowData.canDelete" [matTooltip]="deleteTooltip" matTooltipClass="custom-tooltip" (click)="openRemoveAnalysisDialog(rowData, 'delete', $event)">
                <mat-icon class="material-symbols-rounded">delete</mat-icon>
              </button>
              <div class="icon-holder">
                <uxpl-options-menu
                  *ngIf="rowData?._analysisOptions?.options?.length > 0"
                  default-color="#2B94EB"
                  x-position="before"
                  y-position="below"
                  icon-width="14px"
                  icon-height="14px"
                  [config]="rowData._analysisOptions"
                  (selected)="handleOptionSelected(rowData, $event)"
                ></uxpl-options-menu>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <!-- <tr style="background-color: #FFFFFF" [ngClass]="rowData.folder == 'General' ? 'rowexpansion active' : 'rowexpansion'" [attr.data-folder]="rowData.folder"> -->
        <tr style="background-color: #ffffff" class="rowexpansion">
          <td [attr.colspan]="columns.length + 2" class="addl-info-section">
            <div class="ui-g ui-fluid" style="font-size: 16px">
              <process-analysis-details
                [processAnalysis]="getProcessAnalysisById(rowData.id)"
                [progress]="statusMap[rowData?.name]"
                (doCompare)="handleDoCompare($event)"
              ></process-analysis-details>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
