import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../service/translate.service';

@Pipe({
    name: 'translate',
    pure: false
  })
export class TranslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService) {}

  transform(value: any): any {
    return this.translationService.translate(value);
  }
}
