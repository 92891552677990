<div class="template-analytics-container">
  <div class="analytics-info" *ngIf="vConfiguration">
    <div class="spec-title">Analysis file selection</div>
    <div class="spec-sub-title">Select an analysis file (DXP) to use with this template</div>
    <uxpl-radio-group class="dxp-radio" [value]="analyticsChoice" (uxplChanged)="dxpRadioChange($event)">
      <uxpl-radio *ngIf='isNewTemplate' class='radio-item' value="CURRENT">Use the current analysis file <span
        class="recommended">(Default)</span></uxpl-radio>
      <uxpl-radio *ngIf='isNewTemplate' class='radio-item' value="COPY">Create a copy from the current analysis
        file<span
          class="recommended">(Recommended)</span></uxpl-radio>

      <uxpl-radio class='radio-item' value="EXISTING">Use an existing analysis file from your Teams folder</uxpl-radio>
    </uxpl-radio-group>
    <div class="input-section">
      <div class="inputs">
        <uxpl-form-field *ngIf='showForm("COPY")' class="template-dxp-input" label="New analysis file name"
                         type="text" [valid]="copyDxpNameValid" [hint]="nameHint"
                         required [value]="newDXPLocation?.substr(newDXPLocation?.lastIndexOf('/')+1)"
                         (uxplChanged)="setNewDXPName($event)"></uxpl-form-field>
        <table class="detail-table" *ngIf='showForm("COPY")'>
          <tr class="detail-row">
            <td class="header-location">
              <div class="item-header">Original file name</div>
            </td>
            <td>
              <div class="item-detail">{{stripOrgF(initialLocation)}}</div>
            </td>
          </tr>
        </table>
        <p-tree *ngIf='dxpTree && showForm("EXISTING")' [value]="dxpTree" selectionMode="single"
                (onNodeSelect)="handleDxpTreeSelection($event)" styleClass="custom-tree-css" emptyMessage="Loading... "></p-tree>
<!--        <pre>{{dxpTree | json}}</pre>-->
      </div>
    </div>
  </div>
  <div class="middle-divider"></div>
  <div class="right-specs">
    <div class="spec-title">Analysis file location</div>
    <table class="detail-table">
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">Location</div>
        </td>
        <td>
          <div *ngIf='showSFLink' class="item-detail-dxp">
            <a target="_blank" href="">{{existingDXPLocation}}</a>
            <uxpl-icon class='open-sf-icon' height="18px" width="18px" icon="pl-icon-new-tab-window"
                       (click)="openSFReport()"></uxpl-icon>
          </div>
          <div *ngIf='!showSFLink' class="item-detail-dxp">{{newDXPLocation}}</div>
        </td>
      </tr>
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">Folder</div>
        </td>
        <td>
          <div class="item-detail">{{ getFolder() }}</div>
        </td>
      </tr>
      <tr class="detail-row">
        <td class="header-location">
          <div class="item-header">Name</div>
        </td>
        <td>
          <div class="item-detail">{{ getName() }}</div>
        </td>
      </tr>
    </table>
    <ng-container *ngIf="isNewTemplate">
      <div class="section-title advanced">Advanced options</div>
      <div class="basic-text">Additional options determine how to create an investigation from the analytics results.
      </div>
      <div class="advanced-switch">
        <div class="advanced-label">Advanced</div>
        <uxpl-switch-input [checked]="doAdvancedTab" (uxplChange)="toggleAdvanced($event);" id="#switch" value=true
                           style="margin-left: 10px;"></uxpl-switch-input>
      </div>
    </ng-container>
  </div>
</div>
