import {Component, Input, Output} from '@angular/core';
import { Connection, DVConnection, TCMConnection } from '@tibco/discover-client-lib';
import { EventEmitter } from '@angular/core';
import {copyToClipBoard} from '../../../functions/details';
import {InternalMessageService} from '../../../service/internal-message.service';

@Component({
  selector: 'connection-addl-info',
  templateUrl: './connection-addl-info.component.html',
  styleUrls: ['./connection-addl-info.component.scss']
})
export class ConnectionAddlInfoComponent {

  constructor(private msService: InternalMessageService) {}

  @Input() connection: Connection;
  @Output() datasetSelection: EventEmitter<string> = new EventEmitter<string>();

  get configurationDV(): DVConnection {
    return this.connection.configuration as DVConnection;
  }

  get configurationTcm(): TCMConnection {
    return this.connection.configuration as TCMConnection;
  }

  get isTcm(): boolean {
    return this.connection.type === Connection.TypeEnum.Tcm;
  }

  get isDv(): boolean {
    return this.connection.type === Connection.TypeEnum.Dv;
  }

  copy(title: string, data: string) {
    copyToClipBoard(title, data, this.msService);
  }

  selectDataset(datasetId: string) {
    this.datasetSelection.emit(datasetId);
  }

}
