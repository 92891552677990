<div class="investigation-details-top">
  <div *ngIf="investigationFound">
    <uxpl-object-header [config]="objHeaderConfig" (back)="goInvestigations()">
      <investigation-action-tool-bar slot="navigation-content" [investigationDetail]="investigation" [cConfig]="cConfig" (refreshEvent)="loadInvestigation()"></investigation-action-tool-bar>
    </uxpl-object-header>
    <div class="investigation-details">
      <uxpl-stepper [config]="stepperConfig" (stepClickedIdx)="handleStepClick($event)" hide-numbers span-width>
        <div *ngIf="display('summary')" class="investigation-details-tab" slot="summary">
          <investigation-details-table [investigationDetail]="investigation" [investigationContext]="investigationContext" [cConfig]="cConfig" ></investigation-details-table>
        </div>
        <div *ngIf="display('cases')" class="investigation-details-tab" slot="cases">
          <div class="placeholder">Cases</div>
        </div>
        <div *ngIf="display('analytics')" class="investigation-details-tab" slot="analytics">
          <!-- <div class="placeholder">Analytics</div> -->
          <investigation-analytic [investigationDetail]="investigation" [investigationContext]="investigationContext" ></investigation-analytic>
        </div>
      </uxpl-stepper>
      <!--
      Application:
      <pre>{{applicationId}}</pre>
      Investigation Id:
      <pre> {{investigationId}}</pre>
      Investigation:
      <pre> {{investigation | json}}</pre>

      Investigation Configuration:
      <pre> {{cConfig | json}}</pre> -->
    </div>
  </div>
  <div *ngIf="!investigationFound" class="center">
    <div class="img-center">
      <img width="204" src="assets/images/png/no-data.png">
    </div>
    <div class="no-data-available"><br></div>
    <div class="no-data-available" [innerHTML]="htmlErrorMessage"></div>
    <div class="no-data-available"><br></div>
    <div class="no-data-available">Go to <span class="hyperlink"
                                               (click)="goInvestigations()">investigations</span> and select an
      investigation
    </div>
  </div>
</div>


