import {DateTime} from 'luxon';
import {Analysis, CreateAnalysis, Dataset, UpdateAnalysis, Mapping} from '@tibco/discover-client-lib';
import {PreviewUI} from '../models_ui/analysis';

export const START_NAME = 'Starting Activities';
export const STOP_NAME = 'Stopping Activities';


export function normalizeColumnName(columnName: string): string {
  return columnName.replace('_', ' ');
}

export function encodeColumnName(columnName: string): string {
  return columnName.replace(' ', '_');
}

export function transformMapping(mapping: Mapping) {
  const colMappObj = {};
  for (const key of Object.keys(mapping)) {
    if (mapping[key]) {
      if (typeof (mapping[key]) === 'string') {
        colMappObj[key] = encodeColumnName(mapping[key]);
        // this.mapping[key] = encodeColumnName(this.mapping[key]);
      }
    }
  }
  return colMappObj;
}

export function getColumnLabel(normalizedColumnName: string, datasetId: string, datasets: Dataset[]) {
  let re = ''
  const dataset = datasets.find(ds => ds.id === datasetId)
  if (dataset?.schema) {
    re = dataset.schema.find(sv => sv.field === normalizedColumnName)?.label
  }
  return re
}

export function convertColumnNameToLabel(name: string, cDefinition: PreviewUI): string {
  let re = '';
  if(cDefinition.columns?.length > 0){
    re = cDefinition.columns.find( c  => c.field === name)?.headerName
  }
  return re;
}

export function calculateColumns(columns: any[], mappedColumns: string[]): any[] {
  // console.log('Calculate columns: ', columns, 'MC: ', mappedColumns)
  return columns.map(column => {
    let name = column
    if (column.columnName) {
      name = column.columnName
    }
    let header = name;
    if(column.headerName){
      header = column.headerName
    }
    let mapped = false
    if (mappedColumns.indexOf(name) > -1) {
      mapped = true
    }
    const newColumn = {
      headerName: header,
      field: name,
      sortable: false,
      filter: false,
      resizable: false,
      isMapped: mapped
    };
    return newColumn;
  })
}

export function stripOrgFolder(dxpLocation): string {
  let re = '';
  if (dxpLocation) {
    const fpArr = dxpLocation.split('/');
    if (fpArr.length > 2) {
      let folder = '';
      for (let i = 3; i < fpArr.length; i++) {
        folder += '/' + fpArr[i];
      }
      re = folder;
    } else {
      re = dxpLocation;
    }
  }
  return re;
}


export function convertDateFromLocale(date: string): string {
  const locale = window.navigator.language;
  const newDateLocale = Intl.DateTimeFormat(locale).formatToParts(new Date());
  let format = '';
  newDateLocale.forEach((element: Intl.DateTimeFormatPart) => {
    switch (element.type) {
      case 'day':
        format = format + 'd';
        break;
      case 'month':
        format = format + 'M';
        break;
      case 'year':
        format = format + (element.value.length === 2 ? 'yy' : 'yyyy');
        break;
      case 'literal':
        // TODO: This does not work for dutch keyboard ('-' signs are separators)
        format = format + element.value;
        // format = format + '/';
        break;
      default:
        break;
    }
  })
  if (date.indexOf('PM') > -1 || date.indexOf('AM') > -1) {
    format = format + ' tt';
  } else {
    format = format + ' hh:mm:ss';
  }
  // format = format + ' hh:mm:ss';

  const newDate = DateTime.fromFormat(date, format, {locale}).toISO();
  // console.log('OLD DATE: ' + date + ' NEW DATE: ' + newDate);
  return newDate;
}

export function convertDateToLocale(date: string): string {
  const currentDate = new Date(date);
  const locale = window.navigator.language;
  return currentDate.toLocaleDateString(locale) + ' ' + currentDate.toLocaleTimeString(locale)
}

/*
export function stripVersionFromProcessAnalysisID(id: string) {
  let re = id;
  if(id && id.indexOf('-') > 0){
    re = id.slice(0, id.lastIndexOf('-'));
  }
  return re;
}

export function getVersionFromProcessAnalysisID(id: string) {
  let re = id;
  if(id && id.indexOf('-') > 0){
    re = id.substring(id.lastIndexOf('-') + 1)
  }
  return re;
}*/


export function abbreviateNumber(value) {
  // console.log('Abbreviate: ', value)
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 't'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export function convertAnalysisToCreateAnalysis(analysis: Analysis): CreateAnalysis {
  return {
    name: analysis?.name,
    description: analysis?.description,
    folder: analysis?.folder,
    datasetId: analysis?.dataset?.id,
    filters: analysis?.filters,
    mappings: analysis?.mappings,
    groups: analysis?.groups
  }
}
export function convertCreateAnalysisToUpdate(createAnalysis: CreateAnalysis, id: string): UpdateAnalysis {
  return {
    id,
    name: createAnalysis?.name,
    description: createAnalysis?.description,
    folder: createAnalysis?.folder,
    datasetId: createAnalysis?.datasetId,
    filters: createAnalysis?.filters,
    mappings: createAnalysis?.mappings,
    groups: createAnalysis?.groups
  }
}
