<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>{{ title }}</span>
    <mat-icon>drag_handle</mat-icon>
</h1>
<div mat-dialog-content class="dialog-content">
  <mat-accordion *ngIf="data != ''">
    <mat-expansion-panel *ngFor="let row of rows">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="icon-wrap">
                <mat-icon *ngIf="row.iconType == 'material'" [class]="row.iconClass">
                    {{row.icon}}
                </mat-icon>
                <mat-icon *ngIf="row.iconType == 'svg'" [svgIcon]="row.icon" [class]="row.iconClass"></mat-icon>
                <span class="material-symbols-rounded icon-loading" *ngIf="row.loading == true">cached</span>
            </div>
            {{row.title}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{row.description}}</p>
    </mat-expansion-panel>
  </mat-accordion>
  <p *ngIf="!data">{{ "process.analysis.action.info.dialog.nodata" | translate }}</p>
</div>
<div>
  <div mat-dialog-actions>
    <uxpl-button type="secondary" (clicked)="closeDialog()">{{ "close" | translate }}</uxpl-button>
  </div>
</div>