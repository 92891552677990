<!-- Removed: [virtualScroll]="true" -->
<div class="tibco-table-ux scrollable-table">
<!--<pre>{{columnDefs | json}}</pre>-->
  <p-table #dt [columns]="columnDefs" [value]="rowData" styleClass="custom-table-css"
           [autoLayout]="autoLayout" dataKey="name" [resizableColumns]="false" scrollHeight="flex"
           [virtualRowHeight]="34" [scrollable]="tableScrollable" [style]="tableStyle"
           [rows]="defaultRows" [showCurrentPageReport]="showCurrentPageReport" [rowsPerPageOptions]="rowsPerPageOptions"
           [paginator]="paginator" [currentPageReportTemplate]="currentPageReportTemplate">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="top-row tibco-top-row min-width-header">
          <div class="header-container">
            {{col.headerName}}
          </div>
          <p-sortIcon *ngIf="showSortIcon" [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
        <th *ngIf="showExpandButton" style="width: 3em" class="top-row"></th>
      </tr>
      <tr *ngIf="showFilterRow">
        <th *ngFor="let col of columns" class="filter-row">
          <input pInputText
                 type="text" (input)="dt.filter($event.target.value, col.field, 'contains');"
                 class="ui-column-filter">
        </th>
        <th *ngIf="showExpandButton" class="filter-row"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-rowIndex="rowIndex">
      <tr>
        <td *ngFor="let col of columns"  class="no-padding data-row" [ngClass]="{'col-mapped': col.isMapped}">
          <div *ngIf="isDate(rowData, col)" class="description-overflow-text">
            {{(rowData[col.field] | date :'medium')}}
          </div>
          <div *ngIf='!isDate(rowData, col)' class='description-overflow-text'>
            {{rowData[col.field]}}
          </div>
        </td>
        <td *ngIf="showExpandButton">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
          </a>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-data>
      <ng-container *ngTemplateOutlet="rowExpansionTemplate; context:{$implicit: data}">
      </ng-container>
    </ng-template>
  </p-table>
</div>
