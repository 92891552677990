
<div class="data-title">
  <div class="dataset-title">{{ 'select.dataset.title.availableDatasets' | translate }}</div>
  <uxpl-form-field class="dataset-search-widget" type="text" consise valid [label]="placeholderSearch"
                   (uxplChanged)="handleSearch($event);">
    <uxpl-icon class="dataset-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
  </uxpl-form-field>
  <div class="new-button">
    <uxpl-button class="secondary" type="no-border" icon="assets/images/svg/pl-icon-add-circle-primary.svg"
      (clicked)="goDataSet()" width="100">
    <div class="icon-button secondary-icon-button">{{ 'select.dataset.button.new' | translate }}</div>
  </uxpl-button>

  </div>

</div>

<div class="data-wrapper datasets tibco-table-ux ds-table" *ngIf="(availableDatasets?.length > 0); else nodata">
  <p-table #datasetsTable [columns]="cols" [value]="availableDatasets" [globalFilterFields]="['name']"
           dataKey="id" id="pTable" styleClass="custom-table-css" [rowHover]="false" [filterDelay]="0" [expandedRowKeys]="expandedRows">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="top-row tibco-top-row"
            [ngClass]="col.class">
          <div class="header-container">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" class="sort-icon"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </div>
        </th>
        <th style="width: 150px" class="top-row-empty"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataset>
      <tr #datasetRow [id]="'dsr-' + dataset.id" class="rowhover" id="toHightlight" [ngClass]="{'row-highlight': dataset.id === currentDSId, 'selected': isSelected(dataset)}" (click)="handleSelection(dataset)">
        <td class="no-padding data-row hovereffect">
          <div [innerHTML]="dataset.name | highlight: searchTerm : 'Multi-Match'"></div>
        </td>
        <td class="no-padding data-row hovereffect">
          <div>{{dataset.metadata.createdOn | relativeDate: currentLanguage}}</div>
        </td>
        <td class="no-padding data-row hovereffect">
          <div>{{dataset.metadata.modifiedOn | relativeDate: currentLanguage}}</div>
        </td>
        <td class="no-padding data-row hovereffect selection-data">
          <uxpl-button class="selection-button"  height="24px" type="secondary">
            {{ isSelected(dataset) ? (translationService.translate('select.dataset.button.selected')) : (translationService.translate('select.dataset.button.select')) }}          </uxpl-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #nodata>
  <div class="center">
    <div class="img-center">
      <img width="204" [src]="noDataIconLocation">
    </div>
    <div *ngIf="datasets && datasets.length==0">
      <div class="nodata-msg" style="margin-top: 15px">
        {{ 'select.dataset.msg.noData' | translate }}<br> {{ 'select.dataset.msg.please' | translate }} <span class="hyperlink" (click)="newDatasetDialog()">{{ 'select.dataset.msg.click' | translate }}</span> {{ 'select.dataset.msg.create' | translate }}
      </div>
    </div>
    <div *ngIf="!datasets" class="nodata-msg">{{ 'select.dataset.msg.failed' | translate }}</div>
  </div>
</ng-template>


<!--div>
  <div class="datasource-preview">
    <uxpl-select-input label="Available datasets" [value]="dataset" [options]="availableDatasets"
                       (uxplSelection)="handleSelection($event)"></uxpl-select-input>
    Don’t see the dataset you’re looking for? To create a new one, <span class="go-dataset-click"
                                                                         (click)="goDataSet()">go to Datasets.</span>

    <div class="datasource-preview-table" *ngIf="preview && !isDataPreviewError">
      <data-preview-table [columnDefs]="preview.columns" [rowData]="preview?.data.slice(0,5)"
                           [autoLayout]="false" [showSortIcon]="false"
                           [showExpandButton]="false" [paginator]="false"
                           [showCurrentPageReport]="false" [showFilterRow]="false">
      </data-preview-table>
    </div>
    <div *ngIf="!preview && !isDataPreviewError" class="center">
      <!-- *ngIf=" (previewColumns === undefined || previewColumns?.length === 0)" class="center"> -->
      <!--div class="img-center">
        <img width="204" [src]="'assets/images/png/no-data.png'">
      </div>
      <div class="no-data-available"><br></div>
      <div class="no-data-available" style="font-weight: bold">No preview data available<br></div>
      <div class="no-data-available"><br></div>
    </div>
    <div *ngIf="isDataPreviewError" class="center">
      <div class="img-center">
        <img width="204" [src]="'assets/images/png/no-data.png'">
      </div>
      <div class="no-data-available"><br></div>
      <div class="no-data-available" style="font-weight: bold">The preview data service is not online.<br></div>
      <div class="no-data-available" style="font-weight: bold">Please, contact your administrator.<br></div>
      <div class="no-data-available"><br></div>
    </div>
  </div>
</div-->
