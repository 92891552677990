import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UxplPopup } from '@tibco/tc-web-components/dist/types/components/uxpl-popup/uxpl-popup';
import { TemplateAnalyticsMenuDragdropComponent } from '../template-analytics-menu-dragdrop/template-analytics-menu-dragdrop.component';
import { cloneDeep } from 'lodash-es';
import { clearAllNodeFromDefault, stripDisabledMenuItems } from '../../../functions/templates';
import { MenuConfigTemplate } from '@tibco/discover-client-lib';
import { StepStatus } from '../../../models_ui/analyticTemplate';
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'template-analytics-menu-configuration',
  templateUrl: './template-analytics-menu-configuration.component.html',
  styleUrls: ['./template-analytics-menu-configuration.component.css']
})
export class TemplateAnalyticsMenuConfigurationComponent implements OnInit {

  @Input() menuConfig: MenuConfigTemplate[];
  @Input() pageOptions: string[];
  @Output() previewMenuEE: EventEmitter<MenuConfigTemplate[]> = new EventEmitter<MenuConfigTemplate[]>();
  @Output() status: EventEmitter<StepStatus> = new EventEmitter<StepStatus>();
  @Output() updateConfig: EventEmitter<MenuConfigTemplate[]> = new EventEmitter<MenuConfigTemplate[]>();

  @ViewChild('popup', {static: true}) popup: ElementRef<UxplPopup>;
  @ViewChild('dragdrop', {static: true}) dragdrop: TemplateAnalyticsMenuDragdropComponent;

  originalMenuNodes: MenuConfigTemplate[];
  previewMenu: MenuConfigTemplate[];
  newNode: MenuConfigTemplate;

  popupX = '0';
  popupY = '0';

  constructor() {
  }

  ngOnInit(): void {
    this.originalMenuNodes = cloneDeep(this.menuConfig);
    this.updateMenu();
  }

  reset(): void {
    this.menuConfig = cloneDeep(this.originalMenuNodes);
    this.updateStatus();
  }

  addMenuItem(newMenuItem: MenuConfigTemplate): void {
    if (!(newMenuItem.label === '')) {
      if(newMenuItem.isDefault){
        clearAllNodeFromDefault(this.menuConfig)
      }
      if (this.menuConfig) {
        this.menuConfig.push(newMenuItem);
      } else {
        this.menuConfig = [newMenuItem];
      }
    }
    window.setTimeout(() => {
      this.popup.nativeElement.show = false;
    })
    this.menuConfig = cloneDeep(this.menuConfig);
    this.updateMenu();
  }

  updateMenu(): void {
    this.dragdrop.reloadMenu();
    this.previewMenu = cloneDeep(stripDisabledMenuItems(this.menuConfig));
    this.previewMenuEE.emit(this.previewMenu);
    this.updateConfig.emit(this.menuConfig);
    this.updateStatus();
  }

  receiveMenuUpdate(menu: MenuConfigTemplate[]) {
    this.menuConfig = menu;
    this.previewMenu = cloneDeep(stripDisabledMenuItems(this.menuConfig));
    this.previewMenuEE.emit(this.previewMenu);
    this.updateConfig.emit(this.menuConfig);
    this.updateStatus();
  }

  add(ev: MouseEvent): void {
    this.popupX = (ev.pageX + 15) + 'px';
    this.popupY = (ev.pageY + 15) + 'px';
    this.newNode = {
      id: uuidv4(),
      label: '',
      view: '',
      icon: 'pl-icon-home',
      enabled: true,
      child: []
    }
    this.dragdrop.closePopup();
    this.popup.nativeElement.show = true;
  }

  closeAddPopup(): void {
    this.popup.nativeElement.show = false;
  }

  addAll() {
    this.closeAddPopup();
    for (const option of this.pageOptions) {
      const newNode = {
        id: uuidv4(),
        label: option,
        view: option,
        icon: 'pl-icon-document',
        enabled: true,
        child: []
      }
      let doAdd = true;
      this.menuConfig.forEach(item => {
        if (item.view === option) {
          doAdd = false;
        }
        item.child.forEach(childItem => {
          if (childItem.view === option) {
            doAdd = false;
          }
        })
      })
      if (doAdd) {
        if (this.menuConfig) {
          this.menuConfig.push(newNode);
        } else {
          this.menuConfig = [newNode];
        }
      }
    }
    this.updateMenu();
    this.receiveMenuUpdate(this.menuConfig);
  }

  clearAll() {
    this.menuConfig = [];
    this.receiveMenuUpdate(this.menuConfig);
  }

  private updateStatus = (): void => {
    const status = this.menuConfig.length > 0;
    const stepStatus = {
      step: 'analytics-menu-configuration',
      completed: status
    };
    this.status.emit(stepStatus);
  }
}
