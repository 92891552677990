import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, DoCheck, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  CandidateInvestigationApplication,
  CandidateInvestigationDefinition,
  CreateInvestigationApplication,
  Investigation,
  InvestigationApplication,
  InvestigationField,
  InvestigationMetadata,
  InvestigationService,
  UpdateInvestigationApplication
} from '@tibco/discover-client-lib';
import {UxplPopup} from '@tibco/tc-web-components/dist/types/components/uxpl-popup/uxpl-popup';
import {cloneDeep, isEqual} from 'lodash-es';
import {Observable, of} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {CaseStateEditComponent} from 'src/app/components/admin/case-state-edit/case-state-edit.component';
import {InternalMessageService} from '../../service/internal-message.service';


@Component({
  selector: 'settings-investigations',
  templateUrl: './settings-investigations.component.html',
  styleUrls: ['./settings-investigations.component.scss']
})
export class SettingsInvestigationsComponent implements OnInit, DoCheck {

  public maxInvestigations: number;
  public availableApps = [];
  public availableCreators = [];

  // the config of case type store
  public caseConfig: InvestigationApplication[] = [];
  public originalData: InvestigationApplication[];

  // the index of the current selected menu item
  public activeCaseConfigIndex: number;

  public customTitle: string;

  public tableAvailableFields: InvestigationField[] = [];
  // the uxpl-select-options for the available field of the table list
  public tableAvailableOptions = [];
  // the field name of the selected option
  public inputTableField: string;
  // the label of the selected option
  public inputTableLabel: string;

  public detailTitleField: string;
  public detailTitle: InvestigationField;
  public detailFields: InvestigationField[][] = [];

  public detailAvailableFields: InvestigationField[] = [];
  public detailAvailableOptions = [];
  public detailField: string;
  public detailFieldLabel: string;
  public readonly DETAIL_COLS = 3;

  public creatorAppProperties: any;
  public creatorSelections: { [key: string]: string } = {};
  public creatorSelectOptions: any = {};
  public creatorSelectOptions2: any[] = [];

  public showResetConfirm = false;
  public showMenuConfirm = false;
  public showDeleteConfirm = false;
  public newMenuIndex: number;

  public showEditTableField = false;
  public fieldInEdit;
  public fieldsArrayInEdit: InvestigationField[];
  @ViewChild('editPopup', {static: true}) editPopup: ElementRef<UxplPopup>;
  @ViewChild('settingsMain', {static: true}) settingsMain: ElementRef;
  public editPopupX;
  public editPopupY;

  public stateDialogRef: MatDialogRef<CaseStateEditComponent, any>;

  public allFieldsArray: InvestigationField[] = [];
  public allFieldsOptions = [];
  public allAppsOptions = [];

  public loading = false;

  public unsavedChange = false;

  // validation error
  liveappsError = false;
  fieldMappingError = false;
  tableColError = false;
  stateError = false;
  detailsError = false;
  haveError = false;

  constructor(
    private dialog: MatDialog,
    private investigationMS: InvestigationService,
    private messageService: InternalMessageService) {
  }

  ngDoCheck(): void {
    if (!this.loading && this.caseConfig.length > 0) {
      this.unsavedChange = this.checkUnsavedChange();

      if (this.haveError) {
        this.validateForm();
      }
    }
  }

  private checkUnsavedChange() {
    // console.log('this.checkNewChanges() = ' + this.checkNewChanges() + '; this.checkCaseMenu() = ' + this.checkCaseMenu());
    return this.checkNewChanges() || !this.checkCaseMenu();
  }

  private updateActiveCaseConfigWithSettings() {
    this.caseConfig[this.activeCaseConfigIndex].customTitle = this.customTitle;
  }

  private checkNewChanges() {
    this.updateActiveCaseConfigWithSettings();
    return !isEqual(this.originalData[this.activeCaseConfigIndex], this.activeCaseConfig);
  }

  private checkCaseMenu() {
    return (this.originalData.length === this.caseConfig.length);
  }

  ngOnInit(): void {
    // this.activeCaseConfigIndex = 0;
    this.reset();
  }

  public getCreatorField = (key: string): string => {
    const field = this.activeCaseConfig.creatorData.filter((element: InvestigationField) => element.label === key)[0];
    if (field) {
      return field.field;
    } else {
      return '';
    }
  }

  private initAvailableApps() {
    this.availableApps = [];
    for (const i in this.allAppsOptions) {
      if (!this.caseConfig.find((c, index) => {
        return c.applicationId === this.allAppsOptions[i].id && index !== this.activeCaseConfigIndex
      })) {
        this.availableApps.push({label: this.allAppsOptions[i].label, value: this.allAppsOptions[i].id});
      }
    }

    this.availableApps = [...this.availableApps];
  }

  private initTableFields = (): void => {
    this.detailTitleField = this.activeCaseConfig.detailTitle?.field;
    this.activeCaseConfig.headerFields = this.activeCaseConfig.headerFields ? this.activeCaseConfig.headerFields : [];
  }

  /**
   * Get the case type app info by appId and initialize configuration. If the configuration in caseConfig
   * is existing, use the existing config to init UI, otherwise use the case type app info.
   */
  private getCaseAppInfoToInit(appId: string): Observable<any> {
    return this.investigationMS.getCandidateApplicationDetails(appId).pipe(
      map((appDefinition: CandidateInvestigationDefinition) => {
        this.availableCreators = appDefinition.creators;
        this.initCreatorSelectOptions();
        this.activeCaseConfig.states = this.activeCaseConfig?.states?.length > 0 ? [...this.activeCaseConfig.states] : [...appDefinition.states];
        this.setDefaultStateIconColor();
        this.allFieldsArray = appDefinition.fields;
        this.allFieldsOptions = this.convertFieldsToSelectOptions(this.allFieldsArray);
        this.assembleTableAvailableOption();
        this.assembleDetailAvailableOption();
      })
    );
  }

  private setDefaultStateIconColor() {
    this.activeCaseConfig.states.forEach(state => {
      if (!state.icon) {
        state.icon = 'assets/images/states/Added.svg';
      }
      if (!state.color) {
        state.color = '#F4F4F4';
      }
    })
  }

  /**
   * Empty the current configuration
   */
  private emptyCurrentConfig() {
    this.tableAvailableFields = [];
    this.tableAvailableOptions = [];
    this.allFieldsArray = [];
    this.detailFields = [];
    this.detailAvailableFields = [];
    this.detailAvailableOptions = [];
    this.creatorAppProperties = {};
    // this.creatorSelections = {};
  }

  public setCustomTitle(event) {
    if (!this.loading && event.detail) {
      // this.activeCaseConfig.customTitle = event.detail;
      this.customTitle = event.detail;
    }
  }

  public handleAdd = (): void => {
    this.loading = true;
    const caseConfig = {
      customTitle: 'menu item',
      headerFields: [],
      detailFields: [[], [], []],
      creatorData: [],
      states: [],
      detailTitle: {
        field: '',
        label: ''
      },
      showMilestones: false
    } as InvestigationApplication;
    this.customTitle = 'menu item';
    this.caseConfig.push(caseConfig);
    this.activeCaseConfigIndex = this.caseConfig.length - 1;
    this.emptyCurrentConfig();
    this.initAvailableApps();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  public handleSave = (): void => {
    this.updateActiveCaseConfigWithSettings();
    const iaToSave = this.caseConfig[this.activeCaseConfigIndex];
    if (this.validateForm()) {
      return;
    }

    let saveActiveConfigService: Observable<any> = null;
    iaToSave.ranking = this.activeCaseConfigIndex + 1;
    if (iaToSave.id) {
      // update investigation application
      saveActiveConfigService = this.investigationMS.updateInvestigationApplications([iaToSave as UpdateInvestigationApplication]);
    } else {
      // create new investigations
      saveActiveConfigService = this.investigationMS.createInvestigationApplication(iaToSave as CreateInvestigationApplication);
    }

    saveActiveConfigService.pipe(
      concatMap(() => {
        return of({});
      })
    ).pipe(
      concatMap(() => {
        const updateRankingApps: UpdateInvestigationApplication[] = [];
        for (let i = 0; i < this.caseConfig.length; i++) {
          if (i !== this.activeCaseConfigIndex) {
            updateRankingApps.push({
              id: this.caseConfig[i].id,
              ranking: i + 1
            });
          }
        }
        if (updateRankingApps.length > 0) {
          return this.investigationMS.updateInvestigationApplications(updateRankingApps)
        } else {
          return of({});
        }
      })
    ).subscribe(() => {
      this.notifySuccessAndReset();
    }, () => {
      // todo: notify error
    });
  }

  private notifySuccessAndReset() {
    this.messageService.sendMessage('news-banner.topic.message', 'Settings saved ...');
    this.reset();
  }

  /**
   * Validate the form to make sure all the required fields are complete
   */
  private validateForm() {
    if (this.activeCaseConfig) {
      this.liveappsError = (!this.activeCaseConfig.applicationId || !this.activeCaseConfig.creatorId);
      this.fieldMappingError = this.activeCaseConfig.creatorId && this.activeCaseConfig.creatorData.filter(d => d.label && d.field).length !== 4;
      this.tableColError = this.activeCaseConfig.applicationId && this.activeCaseConfig.headerFields.length <= 0;
      this.stateError = this.activeCaseConfig.applicationId && this.activeCaseConfig.states.filter(state => !state.icon || !state.color).length > 0
      this.detailsError = this.activeCaseConfig.applicationId && (
        !this.activeCaseConfig.detailTitle ||
        !this.activeCaseConfig.detailTitle.field ||
        this.activeCaseConfig.detailFields.filter(col => col.length > 0).length <= 0);
    }
    this.haveError = this.liveappsError || this.fieldMappingError || this.tableColError || this.stateError || this.detailsError;
    return this.haveError;
  }

  private clearError() {
    this.liveappsError = false;
    this.fieldMappingError = false;
    this.tableColError = false;
    this.stateError = false;
    this.detailsError = false;
  }

  public onClickReset() {
    if (this.checkNewChanges()) {
      this.showResetConfirm = true;
    }
  }

  public handleReset = (event): void => {
    if (event.action === true) {
      if (!this.activeCaseConfig.id) {
        this.activeCaseConfigIndex--;
      }
      this.reset();
    }
    this.showResetConfirm = false;
  }

  private reset() {
    this.loading = true;
    this.clearError();
    this.investigationMS.getCandidateApplications().pipe(
      concatMap((apps: CandidateInvestigationApplication[]) => {
        this.allAppsOptions = apps;

        return this.investigationMS.getInvestigationApplications().pipe(
          map((result: InvestigationApplication[]) => {
            result = result.sort((a, b) => a.ranking - b.ranking);
            this.caseConfig = result;
            this.originalData = cloneDeep(this.caseConfig);
            this.maxInvestigations = 3;
            this.activeCaseConfigIndex = this.activeCaseConfigIndex || 0;
            this.switchMenu(this.activeCaseConfigIndex);
          })
        );
      })
    ).subscribe();
  }

  public getCreator = (index: number): string => {
    return this.caseConfig[index].creatorId;
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.caseConfig, event.previousIndex, event.currentIndex);
    // MC moveItemInArray(this.discover.investigations.caseConfig, event.previousIndex, event.currentIndex);
    if (event.previousIndex === this.activeCaseConfigIndex) {
      this.activeCaseConfigIndex = event.currentIndex;
    } else if (event.previousIndex < this.activeCaseConfigIndex && event.currentIndex >= this.activeCaseConfigIndex) {
      this.activeCaseConfigIndex--;
    } else if (event.previousIndex > this.activeCaseConfigIndex && event.currentIndex <= this.activeCaseConfigIndex) {
      this.activeCaseConfigIndex++;
    }
  }

  public handleApplicationSelection = ($event): void => {
    if ($event.detail) {
      const appId = $event.detail.value;
      this.activeCaseConfig.applicationId = appId;
      this.getCaseAppInfoToInit(appId).subscribe(() => {
      });
    }
  }

  public handleCreatorSelection = ($event): void => {
    if ($event.detail) {
      const creatorId = $event.detail.value
      this.activeCaseConfig.creatorId = creatorId;
      this.initCreatorSelectOptions();
    }
  }

  private initCreatorSelectOptions() {
    const creator = this.availableCreators.filter(el => el.value === this.activeCaseConfig.creatorId);
    this.creatorSelectOptions2 = creator.length > 0 ? creator[0].fields : [];
  }

  public handleCreatorConfigSelection = ($event, key): void => {
    if ($event.detail) {
      const field = $event.detail.value;
      const entry = this.activeCaseConfig.creatorData.find((element: InvestigationField) => element.label === key);
      if (entry) {
        entry.field = field;
      } else {
        this.activeCaseConfig.creatorData.push({label: key, field});
      }
    }
  }

  public get activeCaseConfig(): InvestigationApplication {
    if (this.caseConfig) {
      if (this.activeCaseConfigIndex < this.caseConfig.length) {
        return this.caseConfig[this.activeCaseConfigIndex];
      }
    }
    return null;
  }

  public selectMenu(index: number) {
    if (!isEqual(this.activeCaseConfig, this.originalData[this.activeCaseConfigIndex])) {
      this.showMenuConfirm = true;
      this.newMenuIndex = index;
    } else {
      this.switchMenu(index);
    }
  }

  public handleSwitchMenu(event) {
    if (event.action === true) {
      if (this.originalData[this.activeCaseConfigIndex]) {
        this.caseConfig[this.activeCaseConfigIndex] = cloneDeep(this.originalData[this.activeCaseConfigIndex]);
      } else {
        this.caseConfig.splice(this.activeCaseConfigIndex, 1);
      }
      this.switchMenu(this.newMenuIndex);
    }
    this.newMenuIndex = null;
    this.showMenuConfirm = false;
  }

  private switchMenu(index: number) {
    this.loading = true;
    if (this.caseConfig.length > 0) {
      this.activeCaseConfigIndex = index;
      this.customTitle = this.activeCaseConfig.customTitle;
      this.initAvailableApps();
      this.initTableFields();
      this.getCaseAppInfoToInit(this.activeCaseConfig.applicationId).subscribe(() => {
        this.loading = false;
      });
    } else {
      // no investigation application, stop loading
      this.loading = false;
    }
  }

  public clickDeleteMenu(index: number) {
    this.showDeleteConfirm = true;
    this.newMenuIndex = index;
  }

  public handleDeleteMenu(event) {
    if (event.action === true) {
      this.deleteMenu(this.newMenuIndex);
    }
    this.newMenuIndex = null;
    this.showDeleteConfirm = false;
  }

  private deleteMenu(index: number) {
    const caseConfig = this.caseConfig[index];
    this.investigationMS.deleteInvestigationApplication(caseConfig.id).subscribe(() => {
      this.activeCaseConfigIndex = 0;
      this.reset();
    });
  }

  public tableFieldsDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.activeCaseConfig.headerFields, event.previousIndex, event.currentIndex);
  }

  public handleSelectFieldForTable(event) {
    const value = event.detail.value;
    this.inputTableField = value;
    this.inputTableLabel = this.allFieldsArray.filter((el: InvestigationField) => el.field === value)[0].label;
  }

  public handleEditLabelForTable(event) {
    const value = event.detail;
    if (value) {
      this.inputTableLabel = value;
    }
  }

  public addTableField() {
    const fieldToAdd = this.allFieldsArray.filter((el: InvestigationField) => el.field === this.inputTableField)[0];
    if (fieldToAdd) {
      this.activeCaseConfig.headerFields.push(
        {
          label: this.inputTableLabel,
          field: this.inputTableField,
          format: fieldToAdd.format
        }
      );
      this.assembleTableAvailableOption();
    }
  }

  public addAllTableColumns() {
    this.tableAvailableOptions.forEach(el => {
      this.activeCaseConfig.headerFields.push(
        {
          label: el.label,
          field: el.value,
          format: el.format
        }
      );
    });
    this.assembleTableAvailableOption();
  }

  public deleteAllTableFields() {
    this.activeCaseConfig.headerFields = [];
    // this.tableAvailableFields = [];
    // for(let i = 0; i < this.allFieldsArray.length; i++) {
    //   const f = this.allFieldsArray[i];
    //   this.tableAvailableFields.push(f);
    // }
    // Replaced by coping an array:
    this.tableAvailableFields = [...this.allFieldsArray];
    this.assembleTableAvailableOption();
  }

  private convertFieldsToSelectOptions(caseFields: InvestigationField[]) {
    return caseFields.map(field => {
      return {label: field.label, value: field.field}
    }).sort((a, b) => {
      if (a.label < b.label) {
        return -1
      } else if (a.label === b.label) {
        return 0
      } else {
        return 1
      }
    });
  }

  /**
   * Assemble the {label, value} object array for the application select
   * and make the first option the selected by default
   */
  private assembleTableAvailableOption() {
    const possibleFields = this.allFieldsArray.filter(ar => !this.activeCaseConfig.headerFields.find(rm => (rm.field === ar.field)));
    this.tableAvailableOptions = this.convertFieldsToSelectOptions(possibleFields);

    if (possibleFields.length > 0) {
      const firstField = possibleFields[0];
      this.inputTableField = firstField.field;
      this.inputTableLabel = firstField.label;
    }
  }

  private assembleDetailAvailableOption() {
    const possibleFields = this.allFieldsArray.filter(ar => !this.activeCaseConfig.detailFields[0].find(rm => (rm.field === ar.field))).filter(ar => !this.activeCaseConfig.detailFields[1].find(rm => (rm.field === ar.field))).filter(ar => !this.activeCaseConfig.detailFields[2].find(rm => (rm.field === ar.field)));
    this.detailAvailableOptions = this.convertFieldsToSelectOptions(possibleFields);

    if (this.detailAvailableFields.length > 0) {
      const firstField = this.detailAvailableFields[0];
      this.detailField = firstField.field;
      this.detailFieldLabel = firstField.label;
    }
  }

  public deleteTableField(event) {
    const deletedField = this.activeCaseConfig.headerFields.splice(event, 1)[0];
    this.tableAvailableFields.push(deletedField);
    this.assembleTableAvailableOption();
  }

  public editTableField(event, fieldsArray) {
    if (this.showEditTableField) {
      this.cancelEditTableField();
    }

    setTimeout(() => {
      const index = event.index;
      const selectedField = fieldsArray[index];

      this.fieldInEdit = selectedField;
      this.fieldsArrayInEdit = fieldsArray;

      const clickEvent = event.event;

      const target = clickEvent.target;
      const button = target.parentNode;
      const domRect = button.getBoundingClientRect();

      const settingsRect = this.settingsMain.nativeElement.getBoundingClientRect();

      this.editPopupY = domRect.y - settingsRect.y;
      this.editPopupX = domRect.x - settingsRect.x;
      this.showEditTableField = true;
      this.editPopup.nativeElement.show = true;
    }, 0);
  }

  public cancelEditTableField() {
    this.showEditTableField = false;
    this.editPopup.nativeElement.show = false;
  }

  /**
   * Save the field label edit
   */
  public saveEditTableField(event) {
    const field = this.fieldsArrayInEdit.find(ele => ele.field === event.field);
    if (field) {
      field.label = event.label;
    }
    this.cancelEditTableField();
  }

  public tableFieldDropped(_event) {
  }

  public editStateColorAndIcon(index: number) {
    const stateConfig = this.activeCaseConfig.states[index];
    this.stateDialogRef = this.dialog.open(CaseStateEditComponent, {
      width: '400px',
      height: '460px',
      data: {
        caseStateConfig: {...stateConfig, index}
      }
    });

    this.stateDialogRef.componentInstance.stateEditSaved.subscribe(data => {
      this.activeCaseConfig.states[index].color = data.color;
      this.activeCaseConfig.states[index].icon = data.icon;
    });
  }

  public selectDetailTitle(event) {
    if (event.detail) {
      const detail = event.detail;
      if (detail && detail.value) {
        this.activeCaseConfig.detailTitle.field = detail.value;
        this.activeCaseConfig.detailTitle.label = detail.label;
      }
    }
  }

  public checkStatesMilestone(event) {
    this.activeCaseConfig.showMilestones = event.detail.checked;
  }

  public deleteDetailField(event, col) {
    const deletedField = this.activeCaseConfig.detailFields[col].splice(event, 1)[0];
    if (deletedField) {
      // the config maybe is wrong, or the Live Apps app is modified after the configuration
      this.detailAvailableFields.push(deletedField);
    }
    this.assembleDetailAvailableOption();
  }

  public handleSelectFieldForDetail(event) {
    const value = event.detail.value;
    this.detailField = value;
    this.detailFieldLabel = this.allFieldsArray.filter((el: InvestigationField) => el.field === value)[0].label;
  }

  public handleEditLabelForDetail(event) {
    const value = event.detail;
    if (value) {
      this.detailFieldLabel = value;
    }
  }

  public addDetailField() {
    const fieldToAdd = this.allFieldsArray.filter((el: InvestigationField) => el.field === this.detailField)[0];
    if (fieldToAdd) {
      const newField = (
        {
          label: this.detailFieldLabel,
          field: this.detailField
        }
      );
      this.assignDetailFieldToColumn(newField);

      this.assembleDetailAvailableOption();
    }
  }

  private assignDetailFieldToColumn(addedField: InvestigationField) {
    // special process
    if (addedField.field === 'DataSourceName') {
      addedField.format = 'EVENT-LINK';
    }

    // add field to the shortest column. If all columns are the same, add it to the first one
    let col = 0;
    let length = 10000;
    for (let i = 0; i < this.activeCaseConfig.detailFields.length; i++) {
      if (this.activeCaseConfig.detailFields[i].length < length) {
        col = i;
        length = this.activeCaseConfig.detailFields[i].length;
      }
    }

    for (let i = 0; i < this.DETAIL_COLS; i++) {
      if (!this.activeCaseConfig.detailFields[i]) {
        this.activeCaseConfig.detailFields[i] = [];
      }
    }

    this.activeCaseConfig.detailFields[col].push(addedField);
  }

  public addAllDetailFields() {
    for (const field of this.detailAvailableOptions) {
      // const field = this.detailAvailableOptions[i];
      this.assignDetailFieldToColumn(this.getInvestigationFieldFromOption(field));
    }
    // this.detailAvailableFields.length = 0;
    this.assembleDetailAvailableOption();
  }

  private getInvestigationFieldFromOption(option: { label: string, value: string }): InvestigationField {
    return {
      label: option.label,
      field: option.value
    } as InvestigationField;
  }

  public deleteAllDetailFields() {
    this.detailFields = [];
    for (let i = 0; i < this.activeCaseConfig.detailFields.length; i++) {
      this.activeCaseConfig.detailFields[i].length = 0;
    }
    // for(let i = 0; i < this.allFieldsArray.length; i++) {
    //   this.detailAvailableFields.push(this.allFieldsArray[i]);
    // }
    this.assembleDetailAvailableOption();
  }

  // The cConfig that detail component needs is exactly the same json that
  public getDetailConfig() {
    const configCopy: InvestigationApplication = cloneDeep(this.activeCaseConfig);
    if (!configCopy.detailTitle) {
      configCopy.detailTitle = {
        field: '',
        label: ''
      }
    }
    return configCopy;
  }

  public getDummyDetailData(): any {
    const metadata: InvestigationMetadata[] = [];
    const untaggedCasedataObj: any = {};
    // for (let i = 0; i < this.activeCaseConfig.detailFields.length; i++) {
    //   const col = this.activeCaseConfig.detailFields[i];
    for (const col of this.activeCaseConfig.detailFields) {
      // for (let j = 0; j < col.length; j++) {
      for (const row of col) {
        if (row) {
          let field = row.field;
          if (field === '') {
            untaggedCasedataObj[field] = '';
          } else {
            if (field.indexOf('.') !== -1) {
              const names = field.split('.');
              let obj = untaggedCasedataObj;
              while (names.length > 1) {
                const n = names.shift();
                if (!obj[n]) {
                  obj[n] = {};
                }
                obj = obj[n];
              }
              if (row.format === 'ARRAY') {
                obj[names[0]] = ['Sample ' + row.label];
              } else if (row.format === 'DATE') {
                obj[names[0]] = new Date().toISOString();
              } else {
                obj[names[0]] = 'Sample ' + row.label;
              }
            } else {
              if (field.indexOf('CUSTOM:') === 0) {
                field = field.substr(7);
              } else if (field.indexOf('META:') === 0) {
                field = field.substr(5);
                if (row.format === 'DATE') {
                  metadata.push({name: field, value: new Date().toISOString()} as InvestigationMetadata);
                } else {
                  metadata.push({name: field, value: 'Sample ' + row.label} as InvestigationMetadata);
                }
              }
              if (row.format === 'ARRAY') {
                untaggedCasedataObj[field] = ['Sample ' + row.label];
              } else if (row.format === 'DATE') {
                untaggedCasedataObj[field] = new Date().toISOString();
              } else {
                untaggedCasedataObj[field] = 'Sample ' + row.label;
              }
            }
          }
        }
      }
    }
    // Set state to first state
    untaggedCasedataObj.state = this.activeCaseConfig.states[0].name;

    const dummyData: Investigation = {
      id: '1234567',
      data: untaggedCasedataObj,
      metadata
    }
    return dummyData;
  }

  public clickOverlayOutside(_event) {
    this.showMenuConfirm = false;
    this.showResetConfirm = false;
  }

  public isEmptyDetailGroups = (): boolean => {
    let elements: number;
    elements = 0;
    this.activeCaseConfig.detailFields.forEach((el: InvestigationField[]) => {
      elements = elements + el.length
    });

    return elements > 0;
  }

  public showCreateNewInvestigation = (): boolean => {
    return this.caseConfig?.length > 0 && this.caseConfig?.length < this.maxInvestigations && !this.unsavedChange;
  }
}
