import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateTemplate, Template, VisualisationService} from '@tibco/discover-client-lib';
import {TEMPLATE_EXISTS_MESSAGE} from '../../../functions/templates';
import {cloneDeep} from 'lodash-es';
import {BaseTemplateInfo, StepStatus} from '../../../models_ui/analyticTemplate';
import {SelectOption} from '@tibco/tc-web-components/dist/types/models/selectInputConfig';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'template-basic-info',
  templateUrl: './template-basic-info.component.html',
  styleUrls: ['./template-basic-info.component.css']
})
export class TemplateBasicInfoComponent implements OnInit {

  @Input() template: CreateTemplate;
  @Input() isNewTemplate: boolean;
  @Input() baseTemplateId: string;

  @Output() updateTemplate: EventEmitter<CreateTemplate> = new EventEmitter<CreateTemplate>();
  @Output() status: EventEmitter<StepStatus> = new EventEmitter<StepStatus>();
  @Output() setBaseTemplate: EventEmitter<BaseTemplateInfo> = new EventEmitter<BaseTemplateInfo>();


  templateLabels: SelectOption[];

  iconCode: string;
  nameHint: string;
  title: string;
  ICON_GRID = [
    ['default.svg', 'webflow.svg', 'business.svg', 'construction.svg', 'customers.svg', 'healthcare.svg', 'manufacturing.svg', 'retail.svg'],
    ['science.svg', 'security.svg', 'shield-insurance.svg', 'telecommunications.svg', 'transportation.svg', 'warehouse.svg', 'banking.svg', 'building.svg']
  ];

  templates: Template[];

  constructor(
    protected visualisationMS: VisualisationService,
    private translationService: TranslationService,
  ) {
    this.ICON_GRID.map((row, i) => {
      this.ICON_GRID[i] = row.map(icon => {
        if (icon.indexOf('.svg') > 0) {
          return 'assets/svg/' + icon;
        } else {
          return icon;
        }
      })
    })
  }

  ngOnInit(): void {

    this.title = this.translationService.translate("template.basic.label.create")

    this.translationService.currentLang$.subscribe(() => {
      this.title = this.translationService.translate("template.basic.label.create")
    });

    if (!this.isNewTemplate) {
      this.iconCode = this.template.icon;
    }
    this.visualisationMS.getTemplates().subscribe(
      (templates: Template[]) => {
        this.templates = templates;
        this.templateLabels = templates.map(template => ({
          label: template.name,
          value: String(template.id)
        })).sort((a, b) => (a.label > b.label) ? 1 : -1);
        if (this.template.name === undefined) {
          this.handleTemplateSelection({detail: {value: this.baseTemplateId}});
        }
        this.updateStatus()
      }
    );
  }

  async handleUpdate(event, fieldName) {
    // console.log('handleUpdate event: ', event, ' fieldName: ', fieldName)
    if (this.template) {
      this.template[fieldName] = event.detail.value;
      this.updateTemplate.emit(this.template);
      await this.updateStatus();
    }
  }

  handleTemplateSelection(event: any) {
    console.log('handleTemplateSelection: ', event)
    if (event?.detail?.value) {
      const selectedTemplateId = event.detail.value
      const template = this.templates.filter(temp => temp.id + '' === selectedTemplateId + '')[0];
      if (template?.id && template) {
        const myTemplate: Template = cloneDeep(template);
        if (myTemplate.metadata) {
          delete myTemplate.metadata
        }
        if (myTemplate.id) {
          delete myTemplate.id
        }
        this.template = myTemplate as CreateTemplate;
        this.template.type = 'User defined';
        this.template.icon = 'assets/svg/default.svg';
        this.template.splash = '';
        this.template.name = 'Copy of ' + this.template.name;
        this.updateTemplate.emit(this.template);
        this.setBaseTemplate.emit({dxpLocation: template.analysisFileLocation, id: selectedTemplateId,  newDxpLocation: template.analysisFileLocation +  '_user_defined'});
      }
    }
  }

  selectCardIcon(icon) {
    this.template.icon = icon;
  }

  isValid(): boolean {
    return this.nameHint && this.nameHint !== '';
  }

  private async updateStatus() {
    if (this.templates) {
      this.nameHint = '';
      let valid = true;
      if (!this.template.name) {
        this.nameHint = 'Template name can\'t be empty.'
        valid = false
      } else {
        if (this.isNewTemplate) {
          // only check if the name exist if this is a new template
          const templateNameExists = (await this.visualisationMS.checkTemplateName({name: this.template.name}).toPromise()).exist
          if (templateNameExists) {
            this.nameHint = TEMPLATE_EXISTS_MESSAGE
            valid = false
          }
        }
      }
      const stepStatus: StepStatus = {
        step: 'basic-info',
        completed: valid
      };
      this.status.emit(stepStatus);
    }
  }
}
