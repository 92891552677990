import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateDataset, Dataset, FileDatasetConfiguration } from '@tibco/discover-client-lib';
import { CsvService } from 'src/app/service/csv.service';
import { DatasetService } from 'src/app/service/dataset.service';
import { DatasetWizard } from '../../../models_ui/dataset';
import { cloneDeep } from 'lodash-es';
import {validateColumnHeader} from '../../../functions/dataset';

@Component({
  selector: 'dataset-parse',
  templateUrl: './parse.component.html',
  styleUrls: ['./parse.component.scss', '../wizard/wizard.preview.table.scss']
})
export class NewDatasetParseComponent implements OnInit {
  @Input() data: Dataset | CreateDataset;
  @Input() backupDataset: Dataset;
  @Input() wizard: DatasetWizard;
  @Input() previewColumns: any[];
  @Input() previewData: any[];
  @Input() file: File;
  @Input() fileType: string;

  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();
  @Output() highlightColumns: EventEmitter<any> = new EventEmitter<any>();
  @Output() csvValid: EventEmitter<boolean> = new EventEmitter();
  @Output() uploadedFile: EventEmitter<File> = new EventEmitter();
  @Output() stopEdit: EventEmitter<any> = new EventEmitter<any>();


  csvError: string = null;
  filename: string = null;
  isError = false;

  constructor(
    protected csvService: CsvService,
    protected datasetService: DatasetService
  ) { }

  ngOnInit(): void {
    if (this.wizard.editMode &&  (!this.previewData || this.previewData.length === 0)) {
      this.datasetService.pullPreviewData(this.data as Dataset, this.wizard).subscribe(
        data => {
          this.handlePreviewData.emit({
            previewData: data
          });

          this.filename = ((this.data as Dataset).configuration as FileDatasetConfiguration).filename;
          this.filename = this.filename || '(filename not returned).csv'

          this.updateStatus();
        }
      );

    } else if (!this.wizard.editMode && this.file) {
      this.filename = this.file.name;
      this.updateStatus();
    } else {
      this.updateStatus();
    }
  }

  public refreshCSVPreview(event) {
    this.handlePreviewData.emit(event);
  }

  public updateNumberRowsForPreview = (event): void => {
    this.wizard.numberRowsForPreview = event;
  }

  onUploadFile(file: File) {
    this.file = file;
    this.filename = file.name;
    this.uploadFile(file);
  }

  public uploadFile(file: File){
    this.isError = false;
    this.stopStepperNaviIfNeeded();
    this.preview(file);
    this.uploadedFile.emit(file);
  }

  private stopStepperNaviIfNeeded() {
    if (this.wizard.editMode) {
      // select data source when edit
      this.wizard.dataSourceChanged = true;
      this.wizard.attributesUnpredicted = true;
      this.stopEdit.emit('dataset-datasource');
    }
  }

  public preview = (file: File): void => {
    this.refreshPreview(file);
  }

  public refreshPreview = (file: File): void => {
    // todo:
    // this.loadingStatus = 'preview'
    this.csvService.refreshPreview(file, this.wizard.numberRowsForPreview).subscribe(
      element => {
        // this.loadingStatus = undefined;
        if (element.columnSeparator) {
          // clone the configuration object and change value in order that the onChange cycle in parse-csv component will be triggered.
          const config = cloneDeep(this.data.configuration);
          config.delimiter = element.columnSeparator;
          this.data.configuration = config;
        }
        this.refreshCSVPreview(element);
        const columns = validateColumnHeader(element.columns);
        if (columns.length > 0) {
          this.highlightColumns.emit(columns);
          this.isError = true;
          // this.csvError = "The column header of " + (columns.length == 1 ? '' : 's') + " " + columns.join(' , ') + " can't contain the following characters: ,;{}()=.+";
          this.csvError = 'Column headers can’t be blank or contain the following characters: ,;{}()=.+';
        }
        this.updateStatus();
      }
    );
  }

  private updateStatus() {
    const status = this.filename && !this.isError;
    this.csvValid.emit(status);
  }
}
