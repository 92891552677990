<div class="fixActionRow">
  <h1 *ngIf="!datasetWizard.editMode" mat-dialog-title>{{ 'wizard.label.title' | translate }}</h1>
  <h1 *ngIf="datasetWizard.editMode" mat-dialog-title>{{ 'wizard.label.edit' | translate }} {{dataset?.name}}</h1>
  <div mat-dialog-content class="dialog-content">
    <uxpl-stepper [config]="config" (stepClickedIdx)="handleStepClick($event)" [hideNumbers]="datasetWizard.editMode">
      <div *ngIf="display('dataset-basic-info')" class="step-wrapper" slot="dataset-basic-info">
        <dataset-basic-info [data]="dataset"
          [wizard]="datasetWizard"
          [checkRequired]="checkRequired"
          (status)="handleStatus($event)"
          (type)="handleType($event)"
          (enableSave)="handleSaveStatus($event)"></dataset-basic-info>
      </div>
      <div *ngIf="display('dataset-datasource')" class="step-wrapper step-wrapper-ds" slot="dataset-datasource">
        <dataset-datasource [data]="dataset"
          [backupDataset]="backupDataset"
          [wizard]="datasetWizard"
          [previewColumns]="previewColumns"
          [previewData]="previewData"
          [file]="file"
          (status)="handleStatus($event)"
          (handlePreviewData)="handlePreviewData($event)"
          (uploadedFile)="uploadFile($event)"
          (selectConnection)="selectConnection($event)"
          (stopEdit)="stopEdit($event)"
          (highlightColumns)="onHighlightColumns($event)"></dataset-datasource>
        <!-- <select-connection (createConnection)="goCreateConnection($event)" (connectionSelected)="connectionSelected($event)"></select-connection> -->
      </div>
      <!-- <div *ngIf="display('dataset-parse')" class="step-wrapper"  slot="dataset-parse">
          <dataset-parse [data]="dataset" [backupDataset]="backupDataset" [wizard]="datasetWizard" [previewColumns]="previewColumns" [previewData]="previewData" [file]="file" (status)="handleStatus($event)" (handlePreviewData)="handlePreviewData($event)" (stopEdit)="stopEdit($event)" ></dataset-parse>
        </div> -->
      <div *ngIf="display('dataset-attributes')" class="step-wrapper" slot="dataset-attributes">
        <dataset-attributes [data]="dataset"
          [backupDataset]="backupDataset"
          [wizard]="datasetWizard"
          [previewData]="previewData"
          (status)="handleStatus($event)"
          (handlePreviewData)="handlePreviewData($event)"></dataset-attributes>
      </div>
      <div *ngIf="display('dataset-dates')" class="step-wrapper" slot="dataset-dates">
        <dataset-date-parser [data]="dataset"
          [wizard]="datasetWizard"
          [previewData]="previewData"
          [csvFile]="file"
          (status)="handleStatus($event)"
          (handlePreviewData)="handlePreviewData($event)"></dataset-date-parser>
      </div>
      <div *ngIf="display('dataset-confirmation')" class="step-wrapper" slot="dataset-confirmation">
        <dataset-confirmation [data]="dataset"
          (status)="handleStatus($event)"></dataset-confirmation>
      </div>
    </uxpl-stepper>
  
  </div>
  <div>
    <div mat-dialog-actions *ngIf="!showResult">
      <button mat-stroked-button color="primary" (click)="onCancel()">{{cancelText}}</button>
      <button mat-flat-button color="primary" [disabled]="handleDisablePrevButton() || progress.enabled" *ngIf="!hide('prev')" mat-stroked-button color="primary" (click)="changeTab(-1);">{{ 'wizard.label.previous' | translate }}</button>
      <button mat-flat-button color="primary" *ngIf="this.config.currentStepIdx == 0" (click)="checkInfoToChange();">{{ 'wizard.label.next' | translate }}</button>
      <button mat-flat-button color="primary" [disabled]="handleDisableNextButton() || progress.enabled" *ngIf="!hide('next') && this.config.currentStepIdx != 0" (click)="changeTab(1);">{{ 'wizard.label.next' | translate }}</button>
      <button mat-flat-button color="primary" *ngIf="!data.dataset && !hide('finish')" (click)="createUpdateDataset();" [disabled]="!saveEnabled || progress.enabled">{{ 'wizard.label.finish' | translate }}</button>
      <button mat-flat-button color="primary" *ngIf="data.dataset && !hide('save')" [disabled]="!allStepCompleted() || !saveEnabled || progress.enabled" (click)="createUpdateDataset();">{{ 'wizard.label.save' | translate }}</button>
    </div>
    <div mat-dialog-actions *ngIf="showResult">
      <button mat-stroked-button color="primary" (click)="goToDatasets()">{{ 'wizard.button.goToDatasets' | translate }}</button>
      <button mat-flat-button color="primary" (click)="goToAnalysis()">{{ 'wizard.button.goToAnalysis' | translate }}</button>
    </div>
  </div>

  <div *ngIf="progress.enabled" class="modal-backdrop">
    <progress-screen class="overlay-container" [message1]="progress.message1" [message2]="progress.message2" [percentage]="progress.percentage" [status]="progress.description"></progress-screen>
  </div>

  <div *ngIf="showResult" class="modal-backdrop">
    <div class="overlay-container">
      <div>
        <img *ngIf="success" width="208" height="160" src="assets/images/svg/success-image.svg">
        <img *ngIf="!success" width="211" height="203" src="assets/images/svg/error-image.svg">
      </div>
      <div *ngIf="success">
        <div class="result-text">{{ 'wizard.message.success' | translate }}</div>
        <div class="result-msg" *ngIf="!dataset.Dataset_Id">{{ 'wizard.message.added' | translate }}</div>
        <div class="result-msg" *ngIf="dataset.Dataset_Id">{{ 'wizard.message.updated' | translate }}</div>
      </div>
      <div *ngIf="!success">
        <div class="result-text error">{{ 'wizard.message.error' | translate }}</div>
        <div class="result-msg">{{errorMsg}}</div>
      </div>
    </div>
  </div>
</div>
