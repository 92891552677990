import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { copyToClipBoard } from 'src/app/functions/details';
import { Analysis } from '@tibco/discover-client-lib'
import {InternalMessageService} from '../../../service/internal-message.service';
import { TranslationService } from 'src/app/service/translate.service';

export class AdditionalAttr {
  label: string;
  value: string;
  type: string;
  copy?: boolean;
}

@Component({
  selector: 'process-analysis-moreinfo',
  templateUrl: './process-analysis-moreinfo.component.html',
  styleUrls: ['./process-analysis-moreinfo.component.css']
})
export class ProcessAnalysisMoreinfoComponent implements OnChanges {

  @Input() display: boolean;
  @Input() selectedAnalysis: Analysis;
  @Output() onHide: EventEmitter<null> = new EventEmitter();

  constructor(public msService: InternalMessageService,     
    private translationService: TranslationService,
  ) { }

  displayAttributes: AdditionalAttr[];

  copyToClipBoard = copyToClipBoard;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedAnalysis) {
      this.displayAttributes = [
        { label: this.translationService.translate('process.analysis.moreInfo.label.id'), value: this.selectedAnalysis.id?.substring(0, this.selectedAnalysis.id.lastIndexOf('-')), type: 'string', copy: true },
        { label: this.translationService.translate('process.analysis.moreInfo.label.version'), value: this.selectedAnalysis.id?.substring(this.selectedAnalysis.id.lastIndexOf('-') + 1), type: 'string', copy: true },
        { label: this.translationService.translate('process.analysis.moreInfo.label.caseId'), value: this.selectedAnalysis.mappings.caseId, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.startTime'), value: this.selectedAnalysis.mappings.startTime, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.activity'), value: this.selectedAnalysis.mappings.activity, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.endTime'), value: this.selectedAnalysis.mappings.endTime, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.resource'), value: this.selectedAnalysis.mappings.resource, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.scheduledStartTime'), value: this.selectedAnalysis.mappings.scheduledStart, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.department'), value: this.selectedAnalysis.mappings.resourceGroup, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.scheduledEndTime'), value: this.selectedAnalysis.mappings.scheduledEnd, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.requestor'), value: this.selectedAnalysis.mappings.requester, type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.otherAttributes'), value: this.selectedAnalysis.mappings.otherAttributes?.toString(), type: 'string' },
        { label: this.translationService.translate('process.analysis.moreInfo.label.description'), value: this.selectedAnalysis.description, type: 'string' }
      ];
    }
  }

  toggleDisplay() {
    this.onHide.emit();
   }

   handleDialogClose() {
     this.display = false;
   }


}
