import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from 'src/app/models_ui/auth';
import { Languages } from 'src/app/models_ui/languages';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { Roles, UsersService } from 'src/app/service/users.service';
import { LANGUAGES } from 'src/environments/environment';

@Component({
  selector: 'org-add-user',
  templateUrl: './org-add-user.component.html',
  styleUrls: ['./org-add-user.component.css'],
  host: {'class': 'flex-item'}
})
export class OrgAddUserComponent implements OnInit {

  isLoading: boolean = false;
  addUserFormGroup: FormGroup;
  currentOrgId: string;
  currentOrg: Organization;
  roles: Roles;
  
  languages: Languages[] = LANGUAGES;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentOrgId = this.route.snapshot.paramMap.get('orgId');
    this.usersService.getRoles().subscribe((res: Roles) => (this.roles = res));
    this.addUserFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      language: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    if (this.addUserFormGroup.valid) {
      this.usersService.createUserByOrgId(this.currentOrgId, this.addUserFormGroup.value).subscribe(
        () => {
          this.snackBarService.openSnackBar(this.translationService.translate('create.user.superadmin.snackBar.created'), this.translationService.translate('close'),'success');
          this.isLoading = false;
          this.router.navigate(['/administration/'+this.currentOrgId+'/users']);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.openSnackBar(
            this.translationService.translate('create.user.superadmin.snackBar.error'),
            this.translationService.translate('close'),
            'error'
          );
          console.error('AddUser error: ', error);
        }
      );
    }
  }

}
