import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {InvestigationContext} from '../models_ui/investigationContext';

@Injectable({
  providedIn: 'root'
})
export class InvestigationContextService {

  constructor(private http: HttpClient) {
  }

  investigationContextCache: { [k: string]: InvestigationContext } = {}


  async createInvestigationContext(invCont: InvestigationContext) {
    this.investigationContextCache[invCont.id] = invCont;
    await this.callApi('', 'POST', invCont).toPromise().catch(
      _ => {
        console.log('Error Calling investigation context, but its only available in Dev :-) ')
        return Promise.resolve('Ok for now')
      }
    )
  }

  async getInvestigationContext(id: string): Promise<InvestigationContext> {
    if(id) {
      if (this.investigationContextCache[id]) {
        return new Promise(resolve => {
          resolve(this.investigationContextCache[id])
        })
      } else {
        return await this.callApi(id, 'GET').toPromise().catch(
          _ => {
            console.log('Error Calling investigation context, but its only available in Dev :-) ')
            return Promise.resolve(null)
          }
        )
      }
    } else {
      return Promise.resolve(null)
    }
  }


  private callApi(url: string, method: string = 'GET', body?: any, customOptions: any = {}): Observable<any> {
    const urlToCall = '/investigation-context/' + url;
    console.log('Calling: ', urlToCall, ' method: ', method)
    const options = {...this.generateOptions(), ...customOptions};
    if (method.toUpperCase() === 'POST') {
      return this.http.post(urlToCall, body, options);
    } else if (method.toUpperCase() === 'PUT') {
      return this.http.put(urlToCall, body, options);
    } else if (method.toUpperCase() === 'DELETE') {
      return this.http.delete(urlToCall, options);
    } else {
      return this.http.get(urlToCall, options);
    }
  }

  private generateOptions = () => {
    return {
      withCredentials: true
    };
  }
}
