<div class="addl-info-title">Additional info</div>

<div *ngIf="isTcm" class="addl-info-attributes">
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">NAME</div>
    <div class="addl-info-attributes-item-value">{{connection.name}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">DESCRIPTION</div>
    <div class="addl-info-attributes-item-value">{{connection.description}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label-section">
      <div class="addl-info-attributes-item-label">USER ID</div>
      <uxpl-icon size="15px" icon="pl-icon-copy" color="#2B94EB" (click)="copy('User ID', configurationTcm.username)"></uxpl-icon>
    </div>
    <div class="addl-info-attributes-item-value">{{configurationTcm.username}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">PASSWORD</div>
    <div class="addl-info-attributes-item-value">*****</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label-section">
      <div class="addl-info-attributes-item-label">BROKER</div>
      <uxpl-icon size="15px" icon="pl-icon-copy" color="#2B94EB" (click)="copy('Broker', configurationTcm.broker)"></uxpl-icon>
    </div>
    <div class="addl-info-attributes-item-value">{{configurationTcm.broker}}</div>
  </div>
</div>
<div *ngIf="isDv" class="addl-info-attributes">
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">NAME</div>
    <div class="addl-info-attributes-item-value">{{connection.name}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">DESCRIPTION</div>
    <div class="addl-info-attributes-item-value">{{connection.description}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">USER ID</div>
    <div class="addl-info-attributes-item-value">{{configurationDV.username}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">PASSWORD</div>
    <div class="addl-info-attributes-item-value">*****</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">TDV URL</div>
    <div class="addl-info-attributes-item-value">{{configurationDV.hostname}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">HTTP PORT</div>
    <div class="addl-info-attributes-item-value">{{configurationDV.http_port}}</div>
  </div>
  <div class="addl-info-attributes-item">
    <div class="addl-info-attributes-item-label">JDBC PORT</div>
    <div class="addl-info-attributes-item-value">{{configurationDV.jdbc_port}}</div>
  </div>
</div>
<div class="addl-info-break"></div>
<div class="addl-info-datasets">
  <div *ngIf="connection.usedBy?.length > 0 else notInUse" class="addl-info-datasets-label">USED BY DATASET</div>
  <ng-template #notInUse>
    <div class="addl-info-datasets-label">NOT USED BY ANY DATASETS</div>
  </ng-template>
  <div *ngFor="let usedBy of connection.usedBy" class="addl-info-datasets-link" (click)="selectDataset(usedBy.id)">{{usedBy.name}}</div>
</div>
