import {TreeNode} from 'primeng/api';

export function pathItemToTreeNode(pathItems: string, fullPath?: string): TreeNode {
  // '/item1/item2/item3
  if (!fullPath) {
    fullPath = pathItems;
  }
  const items: string[] = pathItems.split('/');
  // if undefined drop item
  if (items[0] === '') {
    items.shift();
  }
  const thisItem: string = items.shift();
  const otherItems = items.join('/');
  // [ 'item1', 'item2', 'item3' ]
  const node = {
    label: thisItem,
    type: otherItems?.length > 0 ? 'folder' : 'view',
    data: fullPath,
    children: otherItems?.length > 0 ? [ pathItemToTreeNode(otherItems, fullPath) ]: []
  }
  return node as TreeNode;
}

export function insertNode(newNode: TreeNode, existingTree: TreeNode[]): TreeNode[] {
  // takes this newNode and inserts them into the existing tree children
  existingTree.push(newNode);
  return existingTree;
}

export function insertTreeNode(newNode: TreeNode, existingNodes: TreeNode[]): TreeNode[] {
  // does this label exist?
  const existingIdx = existingNodes.findIndex(node => { return node.label === newNode.label});
  if (existingIdx !== undefined && existingIdx > -1) {
    // insert it one level down
    insertTreeNode(newNode.children[0], existingNodes[existingIdx].children);
  } else {
    insertNode(newNode, existingNodes);
  }
  return existingNodes;
}
