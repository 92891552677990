import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { ConfigurationService } from '../service/configuration.service';

@Injectable({
  providedIn: 'root'
})

export class AccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private configurationService: ConfigurationService) {
  }

  async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,): Promise<boolean> {

    if(!this.configurationService?.config?.user || !this.configurationService.config.user) {
      /* this will only happen if the user has no token AND we cant refresh it.
      * In that scenario the interceptor will redirect UI to Tibco auth.
      * Therefore we can just return false
      */
      return false
    }
    const whoAmI = this.configurationService?.config?.user;
    if (whoAmI.isUser) {
      const isAnalyst = whoAmI.isAnalyst;
      const isResolver = whoAmI.isResolver;
      const isAdmin = whoAmI.isAdmin;
      let requiredRole: string;
      let output: boolean;
      switch (state.url.split('/')[2]) {
        case 'process-analysis':
        case 'analytics':
          requiredRole = 'Discover Analysts';
          output = isAnalyst;
          break;
        case 'cases':
          requiredRole = 'Discover Analyists or Discover Case Resolvers';
          output = isAnalyst || isResolver;
          break;
        case 'data':
          requiredRole = 'Discover Analysts';
          output = isAnalyst
          break;
        case 'dataset':
          requiredRole = 'Discover Analysts';
          output = isAnalyst
          break;
        case 'admin':
          requiredRole = 'Discover Admins';
          output = isAdmin;
          break;
        default:
          output = true;
      }

      if (!output) {
        console.error('Logged in user is NOT a member of the required Live Apps group ', requiredRole);
        const errMessage = 'You must be a member of ' + requiredRole + ' to access this application';
        this.router.navigate(['/no-access'], {queryParams: {message: errMessage}});
      }

      return output;
    } else {
      console.error('Logged in user does NOT have the required role Discover User');
      const errMessage = 'You must have the Discover User role to access this application';
      this.router.navigate(['/no-access'], {queryParams: {message: errMessage}});
      return false;
    }
  }

}
