<div class="investigation-analytics-container" *ngIf="investigationContext">
  <div class="analysis-header"><b>Analysis: </b><span (click)="goAnalytic()"
                                                      class="analysis-link">{{investigationContext.processAnalysis?.name}}</span>
    <uxpl-icon class='link-icon' height="18px" width="18px" icon="pl-icon-new-tab-window"
    ></uxpl-icon>
  </div>
  <div class="analytics-dashboard-wrapper" *ngIf="spotfireDXP">
    <div class="analytics-dashboard-left-nav">
      <uxpl-left-nav-multi #leftNav [tabs]="leftNavTabs" (selected)="handleClick($event)"
                           collapse></uxpl-left-nav-multi>
    </div>
    <div class="analytics-dashboard-analytics" #analysisContainer>
      <div style="width: 100%; height: 100%; position: relative;">
        <!-- <pre>{{filterIds | json}}</pre> -->
        <spotfire-viewer id='mySFViewer' #analysis
                         [url]="spotfireServer"
                         [path]="spotfireDXP"
                         [parameters]="analysisParameters"
                         version="10.10"
                         (document)="setDocument($event)"
                         [customization]="SFConfg">
        </spotfire-viewer>
      </div>
    </div>
  </div>

</div>

<!--
<pre> {{ investigationContext | json}}</pre>
<pre> {{ investigationDetail | json}}</pre>
-->
