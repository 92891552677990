<div class="fixActionRow">
  <h1 mat-dialog-title>{{ 'select.folder.title.selectFolder' | translate }}</h1>
  <div mat-dialog-content class="dialog-content">
    <div class="process-analysis-breadcrumbs">
      <ul class="breadcrumbs">
        <li *ngFor="let folder of currentFolderArray">
          <a [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path)">{{folder.name}}</a>
        </li>
      </ul>
      <ul class="new-folder">
        <li>
          <a (click)="addFolder()">{{ 'select.folder.button.newFolder' | translate }}</a>
        </li>
      </ul>
    </div>
    <div class="process-analysis-folder-wrapper">
      <div class="process-analysis-folder-container">
        <ul>
          <li class="open li-folder" *ngFor="let folder of hierarchicalFolders">
            <a href="javascript:void(0)" class="folder" [ngClass]="{'folder': true, 'checked': folder.path == currentFolder}" [attr.folder-name]="folder.name" [attr.data-folder-path]="folder.path" (click)="selectFolder(folder.path)">
              <span class="name">
                <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                <span class="name-text">{{folder.name}}</span>
              </span>
            </a>
            <ul>
              <li class="li-folder" *ngFor="let folderParent of folder.children">
                <a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderParent.path == currentFolder}" [attr.folder-name]="folderParent.name" [attr.data-folder-path]="folderParent.path" (click)="selectFolder(folderParent.path)">
                  <span class="name">
                    <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                    <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                    <span class="name-text">{{folderParent.name}}</span>
                  </span>
                </a>
                <ul>
                  <li class="li-folder" *ngFor="let folderChildren of folderParent.children">
                    <a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderChildren.path == currentFolder}" [attr.folder-name]="folderChildren.name" [attr.data-folder-path]="folderChildren.path" (click)="selectFolder(folderChildren.path)">
                      <span class="name">
                        <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                        <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                        <span class="name-text">{{folderChildren.name}}</span>
                      </span>
                    </a>
                    <ul>
                      <li class="li-folder" *ngFor="let folderGrandchild of folderChildren.children">
                        <a href="javascript:void(0)" [ngClass]="{'folder': true, 'checked': folderGrandchild.path == currentFolder}" [attr.folder-name]="folderGrandchild.name" [attr.data-folder-path]="folderGrandchild.path" (click)="selectFolder(folderGrandchild.path)">
                          <span class="name">
                            <span class="material-symbols-rounded folder-icon folder-open">folder_open</span>
                            <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
                            <span class="name-text">{{folderGrandchild.name}}</span>
                          </span>
                        </a>
                        <ul>
                          <ng-container *ngFor="let analysis of analysisList">
                            <li *ngIf="analysis.folder == folderGrandchild.path" class="analysis">
                              <a href="javascript:void(0)">
                                <span class="name">
                                  <span class="material-symbols-rounded folder-icon">insert_chart</span>
                                  <span class="name-text">{{analysis.name}}</span>
                                </span>
                              </a>
                            </li>
                          </ng-container>
                          <li class="analysis no-analysis">
                            <a href="javascript:void(0)">
                              <span class="name">
                                <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                                <span class="name-text">There are no analysis in this folder</span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li class="folder no-folder">
                        <a href="javascript:void(0)">
                          <span class="name">
                            <span class="material-symbols-rounded folder-icon">folder_off</span>
                            <span class="name-text">There are no folders</span>
                          </span>
                        </a>
                      </li>
                      <li class="li-new-folder" [attr.id]="'new-folder-'+folderChildren.path">
                        <a href="javascript:void(0)" class="folder new-folder">
                          <span class="name">
                            <span class="material-symbols-rounded folder-icon">create_new_folder</span>
                            <span class="name-text">
                              <input type="text" name="new-folder" (keydown)="resetValidation('new-folder-'+folderChildren.path)" (keyup.enter)="createFolder('new-folder-'+folderChildren.path)" [attr.data-folder-path]="folderChildren.path"/>
                            </span>
                          </span>
                          <uxpl-button type="secondary" height="24px" (click)="createFolder('new-folder-'+folderChildren.path)">Create</uxpl-button>
                        </a>
                      </li>
                      <ng-container *ngFor="let analysis of analysisList">
                        <li *ngIf="analysis.folder == folderChildren.path" class="analysis">
                          <a href="javascript:void(0)">
                            <span class="name">
                              <span class="material-symbols-rounded folder-icon">insert_chart</span>
                              <span class="name-text">{{analysis.name}}</span>
                            </span>
                          </a>
                        </li>
                      </ng-container>
                      <li class="analysis no-analysis">
                        <a href="javascript:void(0)">
                          <span class="name">
                            <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                            <span class="name-text">There are no analysis in this folder</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="li-new-folder" [attr.id]="'new-folder-'+folderParent.path">
                    <a href="javascript:void(0)" class="folder new-folder">
                      <span class="name">
                        <span class="material-symbols-rounded folder-icon">create_new_folder</span>
                        <span class="name-text">
                          <input type="text" name="new-folder" (keyup.enter)="createFolder('new-folder-'+folderParent.path)" [attr.data-folder-path]="folderParent.path"/>
                        </span>
                      </span>
                      <uxpl-button type="secondary" height="24px" (click)="createFolder('new-folder-'+folderParent.path)">Create</uxpl-button>
                    </a>
                  </li>
                  <li class="folder no-folder">
                    <a href="javascript:void(0)">
                      <span class="name">
                        <span class="material-symbols-rounded folder-icon">folder_off</span>
                        <span class="name-text">There are no folders</span>
                      </span>
                    </a>
                  </li>
                  <ng-container *ngFor="let analysis of analysisList">
                    <li *ngIf="analysis.folder == folderParent.path" class="analysis">
                      <a href="javascript:void(0)">
                        <span class="name">
                          <span class="material-symbols-rounded folder-icon">insert_chart</span>
                          <span class="name-text">{{analysis.name}}</span>
                        </span>
                      </a>
                    </li>
                  </ng-container>
                  <li class="analysis no-analysis">
                    <a href="javascript:void(0)">
                      <span class="name">
                        <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                        <span class="name-text">There are no analysis in this folder</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="li-new-folder" [attr.id]="'new-folder-'+folder.path">
                <a href="javascript:void(0)" class="folder new-folder">
                  <span class="name">
                    <span class="material-symbols-rounded folder-icon">create_new_folder</span>
                    <span class="name-text">
                      <input type="text" name="new-folder" (keyup.enter)="createFolder('new-folder-'+folder.path)" [attr.data-folder-path]="folder.path"/>
                    </span>
                  </span>
                  <uxpl-button type="secondary" height="24px" (click)="createFolder('new-folder-'+folder.path)">Create</uxpl-button>
                </a>
              </li>
              <li class="folder no-folder">
                <a href="javascript:void(0)">
                  <span class="name">
                    <span class="material-symbols-rounded folder-icon">folder_off</span>
                    <span class="name-text">There are no folders</span>
                  </span>
                </a>
              </li>
              <ng-container *ngFor="let analysis of analysisList">
                <li *ngIf="analysis.folder == folder.path" class="analysis">
                  <a href="javascript:void(0)">
                    <span class="name">
                      <span class="material-symbols-rounded folder-icon">insert_chart</span>
                      <span class="name-text">{{analysis.name}}</span>
                    </span>
                  </a>
                </li>
              </ng-container>
              <li class="analysis no-analysis">
                <a href="javascript:void(0)">
                  <span class="name">
                    <span class="material-symbols-rounded folder-icon">bid_landscape_disabled</span>
                    <span class="name-text">There are no analysis in this folder</span>
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <div mat-dialog-actions>
      <uxpl-button type="secondary" (clicked)="closeDialog()">Close</uxpl-button>
      <uxpl-button type="primary" [mat-dialog-close]="currentFolder">{{ 'select' | translate }}</uxpl-button>
    </div>
  </div>
</div>
