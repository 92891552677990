<div id="select-organization" class="start-page">
  <div
    id="start-page-wrap"
    class="width-xs-9 width-sm-5 width-md-3 width-lg-3 width-xl-2"
  >
    <div id="top-block">
      <h2><small>{{ 'select.organization.title1' | translate }}</small> {{ 'select.organization.title2' | translate }}</h2>
    </div>
    <mat-card>
      <mat-card-content>
        <pulse-loading name="true" tagLine="true"></pulse-loading>
        <div class="button-wrap">
          <div id="list-buttons">
            <button id="administration-btn" class="mat-button-icon" *ngIf="superadmin" mat-flat-button color="primary" routerLink="/administration">
              <strong><mat-icon>account_circle</mat-icon>{{'administration' | translate}}</strong>
            </button>
            <ng-container *ngIf="orgs.length > 0 && orgIsLoading">
              <button mat-stroked-button *ngFor="let org of orgs" (click)="selectOrg(org)">
                <strong>{{ org.displayName }}</strong>
                <i>{{ org.description }}</i>
              </button>
            </ng-container>
            <ng-container *ngIf="orgs.length == 0 && orgIsLoading">
              <div class="no-org">
                <strong>{{ 'select.organization.message1' | translate }}</strong>
                <i>{{ 'select.organization.message2' | translate }}</i>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div id="bottom-block">
      <p>{{ 'select.organization.subtitle1' | translate }}
      </p>
      <p>
        {{ 'select.organization.subtitle2' | translate }}
        <a [href]="'mailto:'+supportEmail" target="_blank"
          >{{supportEmail}}</a
        >
      </p>
    </div>
  </div>
  <button
    id="logout-button"
    mat-icon-button
    (click)="logout()"
    matTooltip="Logout"
    matTooltipPosition="left"
  >
    <mat-icon>logout</mat-icon>
  </button>
</div>
