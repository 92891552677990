import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {CardMode} from '../../models_ui/configuration';
import {MatDialogRef} from '@angular/material/dialog';
import {YesnoConfirmationComponent} from 'src/app/components/common/yesno-confirmation/yesno-confirmation.component';
import {UxplPopup} from '@tibco/tc-web-components/dist/types/components/uxpl-popup/uxpl-popup';
import {compareTemplates} from '../../functions/templates';
import {RepositoryService, Template, VisualisationService} from '@tibco/discover-client-lib';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'template-manage',
  templateUrl: './manage-templates.component.html',
  styleUrls: ['./manage-templates.component.css']
})
export class ManageTemplatesComponent implements AfterViewInit {

  @ViewChild('deletePopup', {static: true}) deletePopup: ElementRef<UxplPopup>;

  templates: Template[];
  confirmDialogRef: MatDialogRef<YesnoConfirmationComponent, any>;
  objHeaderConfig: ObjectHeaderConfig = {};
  popupX: string;
  popupY: string;
  templateToDelete: string;
  templateToDeleteName: string;
  usedByAnalysis: string[] = [];
  maxDeletePopupHeight = '162px';
  search: string;
  placeholderSearch: string;

  constructor(
    private router: Router,
    private visualisationMS: VisualisationService,
    private repositoryMS: RepositoryService,
    private translationService: TranslationService
  ) {
  }

  ngAfterViewInit(): void {
    this.translationService.currentLang$.subscribe(() => {
      this.placeholderSearch = this.translationService.translate("manage.templates.placeholder.search")
    });

    this.refresh();
  }

  public goProcessAnalysis = (): void => {
    this.router.navigate(['/discover/process-analysis']);
  }

  public refresh() {
    this.visualisationMS.getTemplates().pipe(
      map(temp => {
        this.templates = temp.sort(compareTemplates);
      })
    ).subscribe();
  }


  public goTemplate = (): void => {
    this.router.navigate(['/discover/new-template']);
  }

  public templateAction(action) {
    const location: DOMRect = action.location;
    const template: Template = action.template;
    const mode: CardMode = action.mode;
    if (mode === 'copy') {
      this.router.navigate(['/discover/new-template'], {queryParams: {templateToCreateFrom: template.id}});
    }
    if (mode === 'edit') {
      this.router.navigate(['/discover/edit-template/' + template.id]);
    }
    if (mode === 'delete') {
      // See if the template is used
      const subscription = this.repositoryMS.getAnalysises().pipe(
        map(analysisList => {
          this.usedByAnalysis = [];
          let pHeight = 162;
          this.usedByAnalysis = analysisList.filter(analysis => analysis.template?.id === String(template.id)).map(analysis => analysis.name);
          pHeight = pHeight + (20 * this.usedByAnalysis.length);
          this.templateToDelete = template.id;
          this.templateToDeleteName = template.name;
          this.maxDeletePopupHeight = pHeight + 'px';
          this.deletePopup.nativeElement.show = true;
          this.popupX = (location.left - 300) + 'px';
          this.popupY = (location.top + 250) + 'px';
          subscription.unsubscribe();
        })
      ).subscribe();
    }
  }

  public reallyDeleteTemplate() {
    this.visualisationMS.deleteTemplate(this.templateToDelete).toPromise().then(() => {
      this.deletePopup.nativeElement.show = false;
      this.refresh();
    });
  }

  public cancelDelete() {
    this.deletePopup.nativeElement.show = false;
  }

  public handleSearch(event: CustomEvent) {
    this.search = event?.detail?.value;
    console.log(this.search);
  }
}
