<div class="page-container container-flex-fluid">
    <div class="flex">
        <div class="flex-item">
            <div class="data-title-container">
                <div class="data-title">
                    <div class="page-title">{{ 'control.panel.title' | translate }}</div>
                </div>
                <div class="data-title">
                    <div class="data-title">
                        <button mat-stroked-button color="primary"
                            (click)="refresh()"
                            width="100">
                            <mat-icon class="material-symbols-rounded">refresh</mat-icon>
                            {{ 'refresh' | translate }}
                        </button>
                        <button mat-flat-button color="primary"
                            routerLink="/discover/control-panel/add-user">
                            <mat-icon class="material-symbols-rounded">person_add</mat-icon>
                            {{ 'control.panel.add.user.button' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-item">
            <div id="icon-sidebar-container" class="flex">
                <div id="icon-sidebar-side" class="flex-item">
                    <icon-sidebar [iconSidebarData]="iconSidebarData"></icon-sidebar>
                </div>
                <div id="icon-sidebar-content">
                    <div id="admin-statistics" class="flex-item">
                        <div class="flex">
                            <div class="flex-item width-xs-10">
                                <mat-card id="users-bar-chart" class="card-2-3 chart-card">
                                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                                    <mat-card-content>
                                        <header>
                                            <label>
                                                <mat-icon class="material-symbols-rounded">database</mat-icon>
                                                {{'statistics.users' | translate}}
                                            </label>
                                            <label>
                                                <span>{{"statistics.users.total" | translate}}</span>
                                                <span class="badge color-1-bg">{{usersTotal}}</span>
                                            </label>
                                            <div class="chart-legend"  *ngIf="adminStatistics && adminStatistics.users && adminStatistics.users.roles && adminStatistics.users.roles.length > 0">
                                                <ul>
                                                    <li *ngFor="let role of adminStatistics.users.roles; let i = index;">
                                                        <mat-icon [class]="userRolesColors[i]">circle</mat-icon>
                                                        <span>{{role.role}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </header>
                                        <main>
                                            <canvas baseChart
                                                [datasets]="usersBarChartData"
                                                [labels]="usersBarChartLabels"
                                                [options]="usersBarChartOptions"
                                                [plugins]="usersBarChartPlugins"
                                                [legend]="usersBarChartLegend"
                                                chartType="bar">
                                            </canvas>
                                        </main>
                                    </mat-card-content>
                                </mat-card>    
                            </div>
                            <div class="flex-item width-xs-10 width-sm-5 width-md-25">
                                <mat-card class="card-1-3 color-1-bg white-color">
                                    <mat-card-content>
                                        <main>
                                            <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.analysis_count; else analysisCountNd">{{adminStatistics.summary.analysis_count}}</span>
                                            <ng-template #analysisCountNd><span class="kpi-value">{{"statistics.not.available" | translate}}</span></ng-template>
                                        </main>
                                        <footer>
                                            <mat-icon class="material-symbols-rounded">insert_chart</mat-icon>
                                            <label>{{"statistics.summary.analysis.count" | translate}}</label>
                                        </footer>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="flex-item width-xs-10 width-sm-5 width-md-25">
                                <mat-card class="card-1-3 color-2-bg white-color">
                                    <mat-card-content>
                                        <main>
                                            <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.cached_analysis_count; else analysisCacheCountNd">{{adminStatistics.summary.cached_analysis_count}}</span>
                                            <ng-template #analysisCacheCountNd><span class="kpi-value">{{"statistics.not.available" | translate}}</span></ng-template>
                                        </main>
                                        <footer>
                                            <mat-icon class="material-symbols-rounded">speed</mat-icon>
                                            <label>{{"statistics.summary.analysis.cached.count" | translate}}</label>
                                        </footer>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="flex-item width-xs-10 width-sm-5 width-md-25">
                                <mat-card class="card-1-3 color-3-bg white-color">
                                    <mat-card-content>
                                        <main>
                                            <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.dataset_count; else analysisCountNd">{{adminStatistics.summary.dataset_count}}</span>
                                            <ng-template #datasetCountNd><span class="kpi-value">{{"statistics.not.available" | translate}}</span></ng-template>
                                        </main>
                                        <footer>
                                            <mat-icon class="material-symbols-rounded">description</mat-icon>
                                            <label>{{"statistics.summary.dataset.count" | translate}}</label>
                                        </footer>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="flex-item width-xs-10 width-sm-5 width-md-25">
                                <mat-card class="card-1-3 color-4-bg white-color">
                                    <mat-card-content>
                                        <main>
                                            <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.report_count; else analysisCountNd">{{adminStatistics.summary.report_count}}</span>
                                            <ng-template #reportCountNd><span class="kpi-value">{{"statistics.not.available" | translate}}</span></ng-template>
                                        </main>
                                        <footer>
                                            <mat-icon svgIcon="report-icon"></mat-icon>
                                            <label>{{"statistics.summary.report.count" | translate}}</label>
                                        </footer>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>