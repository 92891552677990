import { environment } from './environment';

export const endpoints = {
  authorization: {
    membership: environment.apiURL + '/authz/authorization/membership',
    setorganization: environment.apiURL + '/authz/authorization/set-organization',
    userinfo: environment.apiURL + '/authz/authorization/userinfo',
  },
  users: {
    users: environment.apiURL + '/users/users',
    roles: environment.apiURL + '/users/users/roles',
    organizations: environment.apiURL + '/users/organizations',
    dxp: environment.apiURL + '/users/organizations/dxp',
    statistics: environment.apiURL + '/users/statistics',
    adminStatistics: environment.apiURL + '/users/organizations/statistics',
    releaseNotes: environment.apiURL + '/users/release-notes',
  },
  repository: {
    analysis: environment.apiURL + '/repository/analysis',
    report: environment.apiURL + '/repository/analysis/report/',
    reportStatus: environment.apiURL + '/repository/analysis/report/status/',
    downloadReport: environment.apiURL + '/repository/analysis/report/download/',
    cache: environment.apiURL + '/repository/analysis/cache/',
    cacheStatus: environment.apiURL + '/repository/analysis/cache/status/',
  },
  catalog:{
    dataset : environment.apiURL + '/catalog/dataset',
    xes : environment.apiURL + '/catalog/xes',
    xlsx : environment.apiURL + '/catalog/xlsx',
  },
  visualisation:{
    configuration : environment.apiURL + '/visualisation/configuration',
  },
  documentation:{
    diagramUpload : environment.apiURL + '/documentation/diagram-file/upload/',
  }
};
