import {Component, OnInit} from '@angular/core';
import {SelectOption} from '@tibco/tc-web-components/dist/types/models/selectInputConfig';
import {RepositoryService} from '@tibco/discover-client-lib';
import {map} from 'rxjs/operators';
import {InternalMessageService} from '../../../service/internal-message.service';

@Component({
  selector: 'template-preview-data-selector',
  templateUrl: './template-preview-data-selector.component.html',
  styleUrls: ['./template-preview-data-selector.component.css']
})
export class TemplatePreviewDataSelectorComponent implements OnInit {
  previewPAOptions: SelectOption[] = [];
  previewPA: string;

  constructor(private repositoryMS: RepositoryService,
              private messageService: InternalMessageService) {
  }

  ngOnInit(): void {
    // this.previewPAOptions = [{value: '1', label: 'one'}, {value: '2', label: 'two'}]
    this.repositoryMS.getAnalysises().pipe(map(val => val.map(v => {
      return {value: v.id, label: v.name}
    }))).subscribe(pop => this.previewPAOptions = pop)
  }

  handlePreviewSelection(selection: SelectOption) {
    if(selection?.value) {
      this.messageService.sendMessage('preview-dxp.process-analysis-id.message', selection.value);
    }
  }
}
