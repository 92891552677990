<div id="add-user" class="card-bg">
    <div class="flex">
        <div class="flex-item width-xs-10 width-sm-8 width-md-6 width-lg-5 width-xl-4">
            <form [formGroup]="addUserFormGroup" (ngSubmit)="onSubmit()">
                <mat-card>
                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                    <mat-card-title>
                        {{'create.user.superadmin.title' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="container-flex-fluid">
                            <div class="flex">
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'create.user.superadmin.label.firstName' | translate }}</mat-label>
                                        <input matInput formControlName="firstName">
                                        <mat-error *ngIf="addUserFormGroup.get('firstName').hasError('required')">
                                            {{ 'create.user.superadmin.error.firstNameRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'create.user.superadmin.label.lastName' | translate }}</mat-label>
                                        <input matInput formControlName="lastName">
                                        <mat-error *ngIf="addUserFormGroup.get('lastName').hasError('required')">
                                            {{ 'create.user.superadmin.error.lastNameRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'create.user.superadmin.label.email' | translate }}</mat-label>
                                        <input matInput formControlName="email" type="email">
                                        <mat-error *ngIf="addUserFormGroup.get('email').hasError('required') || addUserFormGroup.get('email').hasError('touched') || addUserFormGroup.get('email').hasError('dirty')">
                                            {{ 'create.user.superadmin.error.emailRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'create.user.superadmin.label.role' | translate }}</mat-label>
                                        <mat-select formControlName="role">
                                            <mat-option *ngFor="let role of roles" [value]="role">
                                                {{role}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="addUserFormGroup.get('role').hasError('required')">
                                            {{ 'create.user.superadmin.error.roleRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'create.user.superadmin.label.language' | translate }}</mat-label>
                                        <mat-select formControlName="language">
                                            <mat-option *ngFor="let lang of languages" [value]="lang.code">
                                                {{lang.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="addUserFormGroup.get('language').hasError('required')">
                                            {{ 'create.user.superadmin.error.languageRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-flat-button color="primary" type="submit" [disabled]="addUserFormGroup.invalid">{{"administration.button.add" | translate}}</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
    </div>
</div>
