import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TButton} from '../../../models_ui/buttons';
import {Investigation, InvestigationApplication, InvestigationService} from '@tibco/discover-client-lib';
import {MatDialog} from '@angular/material/dialog';
import {InvestigationDetailsTableComponent} from '../investigation-details-table/investigation-details-table.component';
import {InvestigationAction} from '../common/InvestigationAction';
import {InternalMessageService} from '../../../service/internal-message.service';

@Component({
  selector: 'investigation-details',
  templateUrl: './investigation-details.component.html',
  styleUrls: ['./investigation-details.component.css']
})
export class InvestigationDetailsComponent extends InvestigationAction implements OnInit, AfterViewInit {

  constructor(protected dialog: MatDialog,
              protected messageService: InternalMessageService,
              protected investigationMS: InvestigationService) {
    super(dialog, messageService, investigationMS)
  }

  @Input() cConfig: InvestigationApplication;
  @Input() investigationDetail: Investigation;
  @Input() hideActionBtn: boolean;
  @Output() refreshEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('invDetails') invDetails: InvestigationDetailsTableComponent

  milestone: any;
  actionButtons: TButton[];
  detailTitle = ''

  ngOnInit(): void {
    this.setCConfig(this.cConfig)
    this.milestone = {
      milestones: this.cConfig.states.map(state => {
        return {name: state.name, label: state.name, status: 'Pending'}
      })
    };
    this.milestone.milestones[this.milestone.milestones.length - 1].isTerminal = true;
    const stateToFilter = (this.investigationDetail.data as any).state
    this.milestone.milestones.filter(state => state.name === stateToFilter)[0].status = 'InProgress';
    (async () => {
      await this.setActionButtons()
    })();
  }

  // Use after view init to make sure the getFieldValue method exists
  ngAfterViewInit(): void {
    // Use timeout to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      const state = (this.investigationDetail.data as any).state
      this.detailTitle = this.cConfig.customTitle + ' - ' + this.invDetails.getFieldValue(this.cConfig.detailTitle.field) + ' - ' + state;
    });
  }

  // Function to get the action buttons
  async setActionButtons() {
    this.actionButtons = await this.getActionButtons(this.investigationDetail)
  }

  // Function to handle a button clicked
  public handleTBut(tBut: any) {
    if (tBut.type === 'ACTION') {
      this.oneCommonAction(tBut.id, this.investigationDetail.id, tBut.formData).then(_result => {
        // Refresh a little later till the case is updated
        setTimeout(async () => {
          await this.setActionButtons();
          this.refreshEvent.emit(this.cConfig.id)
        }, 500)
      })
    }
  }

}
