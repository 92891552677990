import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {concatMap, delay, filter, map, repeatWhen, take} from 'rxjs/operators';
import {NewAnalysisStepStatus} from 'src/app/models_ui/discover';
import {DataPreviewComponent} from '../../components/new-analysis/data-preview/data-preview.component';
import {AutoMappingService} from '../../service/auto-mapping.service';
import {MapDef, PreviewUI} from '../../models_ui/analysis';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  calculateColumns,
  convertAnalysisToCreateAnalysis, convertCreateAnalysisToUpdate,
  getColumnLabel
} from '../../functions/analysis';

import {
  CatalogService, CreateAnalysis, Dataset, DatasetMetadata, RepositoryService, AnalysisStatus,
  Mapping,
  Analysis
} from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../service/internal-message.service';
import {StepperConfigUI} from '../../models_ui/uxpl-models';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import {notifyUser} from "../../functions/message";
import { NewAnalysisStopCreationComponent } from 'src/app/components/new-analysis/stop-creation/stop-creation.component';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'new-analysis',
  templateUrl: './new-analysis.component.html',
  styleUrls: ['./new-analysis.component.scss']
})
export class NewAnalysisComponent implements OnInit {
  private allDatasets: Dataset[];

  constructor(
    private messageService: InternalMessageService,
    private catalogMS: CatalogService,
    private router: Router,
    private route: ActivatedRoute,
    private repositoryMS: RepositoryService,
    private autoMapService: AutoMappingService,
    private dialog: MatDialog,
    private translationService: TranslationService,

  ) {
  }

  @ViewChild('dataPreview', {static: false}) dataPreview: DataPreviewComponent;

  objHeaderConfig: ObjectHeaderConfig;
  config: StepperConfigUI;
  advancedMode: boolean;
  newAnalysis: CreateAnalysis;
  mode: 'edit' | 'create'
  disableSave = false;
  datasetName: string;
  progress: any = {};
  analysisId = '';
  cancelText = this.translationService.translate('new.analysis.button.cancel');
  analysisNames: string[];
  analysisList: Analysis[];
  dialogRef: MatDialogRef<NewAnalysisStopCreationComponent, any>;
  step1Label: string;
  step2Label: string;
  step3Label: string;


  private previousStep = 0;
  readonly ADVANCED_STEP_NUMBER = 3;

  showResult = false;
  newAnalysisId: string;
  private analysisVersion: number;
  success = true;
  errorMsg;
  doAutoMap = true;
  initialDatasetId: string;

  availableDatasets$: Observable<Dataset[]>

  previewD: PreviewUI;
  isDataPreviewError = false;
  langChangeSubscription: Subscription;

  ngOnInit(): void {
    this.messageService.sendMessage('integratedHelp', 'discover/new-analysis');
    this.cancelText = this.translationService.translate('new.analysis.button.cancel');
    this.advancedMode = true;
    if (this.route.snapshot.paramMap.get('id') !== null) {
      this.mode = 'edit';
      this.advancedMode = true;
      this.doAutoMap = false;
    } else {
      this.mode = 'create';
    }
    this.langChangeSubscription = this.translationService.currentLang$.subscribe(() => {
      this.cancelText = this.translationService.translate('new.analysis.button.cancel');
      this.updateStepLabels();
      this.initializeForm();
      this.getStepperConfiguration(this.advancedMode);
    });
    
    this.updateStepLabels();
    this.initializeForm();
    this.getStepperConfiguration(this.advancedMode);
    this.disableSave = false;

    this.availableDatasets$ = this.catalogMS.getDatasets().pipe(
      map((datasets: Dataset[]) => {
        this.allDatasets = datasets;
        return datasets
          // TODO: When dataset if of type TDV or File, just check for ready. When dataset is of type TCM Check for Created and collection status should be Paused and the  (To be reverted when fixed in the microservice)
          .filter(dataset => dataset.metadata.status === DatasetMetadata.StatusEnum.Ready || dataset.metadata.status === DatasetMetadata.StatusEnum.Created || dataset.metadata.dataCollectionStatus === DatasetMetadata.DataCollectionStatusEnum.Paused)
          .sort((a, b) => (a.name > b.name) ? 1 : -1);
      })
    )
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.initialDatasetId) {
        this.initialDatasetId = params.initialDatasetId;
        this.initializeForm();
      }
    });
  }
 
  private initializeForm = (): void => {
    this.newAnalysis = {
      mappings: {},
      filters: [],
      groups: []
    } as unknown as CreateAnalysis;
    if (this.mode === 'create' && this.initialDatasetId) {
      this.newAnalysis.datasetId = this.initialDatasetId;
      this.getDatasetDetails(this.newAnalysis.datasetId)
    }
    this.repositoryMS.getAnalysises().subscribe()
    if (this.mode === 'edit') {
      this.repositoryMS.getAnalysisDetails(this.route.snapshot.paramMap.get('id')).subscribe(
        analysis => {
          this.newAnalysis = convertAnalysisToCreateAnalysis(analysis);
          this.analysisId = this.route.snapshot.paramMap.get('id');
          this.analysisVersion = analysis.metadata?.version
          this.createObjectHeader();
          this.getDatasetDetails(this.newAnalysis.datasetId);
        }
      )
    } else {
      this.createObjectHeader();
    }
  }

  private getDatasetDetails(datasetId: string) {
    this.availableDatasets$.pipe(
      map((datasets: Dataset[]) => {
        const dsT = datasets.filter(element => element.id === datasetId)[0]
        this.datasetName = dsT.name;
        this.getPreviewData(dsT.id)
      })
    ).subscribe()
  }

  private createObjectHeader = (): void => {
    const createTitle = this.translationService.translate('new.analysis.button.title.create');
    const edit =this.translationService.translate('new.analysis.button.title.edit');

    if(this.mode == 'edit'){
      let aName = this.newAnalysis.name.split('||');
      if(aName.length > 1){
        this.newAnalysis.name = aName.pop();
        this.newAnalysis.folder = aName.join('||');
      }else{
        this.newAnalysis.folder = 'General';
      }
    }
    this.objHeaderConfig = {
      title: {
        value: 
        this.mode === 'create'
         ? createTitle : edit + ' ' + this.newAnalysis.name,
        isEdit: false,
        editing: false
      }
    };
  }

  private getPreviewData(datasetId: string) {
    this.catalogMS.getPreview(datasetId).pipe(
      map((data) => {
        if (data.length > 0) {
          const firstRow = data[0];
          // Get the available column labels
          const availableColumns = Object.keys(firstRow).map(c => {
            return {columnName: c, headerName: getColumnLabel(c, datasetId, this.allDatasets)}
          })
          const col = calculateColumns(availableColumns, []);
          this.previewD = {
            availableColumns: availableColumns.map(v => v.columnName),
            columns: col,
            data
          }
          this.isDataPreviewError = false;
          this.handleStatus({
            step: 'dataset',
            completed: true
          })
        } else {
          notifyUser('ERROR', 'No preview data for ' + datasetId, this.messageService)
        }
      })
    ).subscribe();
  }


  public display = (element: string): boolean => {
    return this.config.steps[this.config.currentStepIdx].slot === element;
  }
  
  private updateStepLabels() {
    let confirmLabel =  this.translationService.translate('new.analysis.label.confirm');

    this.step1Label = this.translationService.translate("new.analysis.label.step1");
    this.step2Label = this.translationService.translate("new.analysis.label.step2");
    this.step3Label = this.translationService.translate("new.analysis.label.step3");
    if (this.mode === 'edit') {
      const isEditAvailable = true;
      confirmLabel =  this.translationService.translate('new.analysis.label.summary');

      this.config = {
        steps: [
          { name: '1', available: true, completed: false, label: this.step1Label, slot: 'basic-info' },
          { name: '2', available: isEditAvailable, completed: false, label: this.step2Label, slot: 'dataset' },
          { name: '3', available: isEditAvailable, completed: false, label: this.step3Label, slot: 'map' },
          { name: '4', available: isEditAvailable, completed: false, label: confirmLabel, slot: 'confirmation' }
        ],
        currentStepIdx: 0  
      };
    }else{
      this.config = {
        steps: [
          { name: '1', available: true, completed: false, label: this.step1Label, slot: 'basic-info' },
          { name: '2', available: false, completed: false, label: this.step2Label, slot: 'dataset' },
          { name: '3', available: false, completed: false, label: this.step3Label, slot: 'map' },
          { name: '4', available: false, completed: false, label: confirmLabel, slot: 'confirmation' }
        ],
        currentStepIdx: 0 
      };
    }
    this.getStepperConfiguration(this.advancedMode);
  }

  private getStepperConfiguration = (advancedMode: boolean): void => {
    let isAvailable = false;
    let confirmLabel =  this.translationService.translate('new.analysis.label.confirm');
    // let advLabel = this.translationService.translate('new.analysis.label.adv');

    if (this.mode === 'edit') {
      isAvailable = true;
      confirmLabel = this.translationService.translate('new.analysis.label.summary');
      // advLabel = 'Advanced';
      this.advancedMode = true;
    }
    const basicSteps = [
      {name: '1', available: true, completed: false, label: this.step1Label, slot: 'basic-info'},
      {name: '2', available: isAvailable, completed: false, label: this.step2Label, slot: 'dataset'},
      {name: '3', available: isAvailable, completed: false, label: this.step3Label, slot: 'map'},
      /*{name: '4', available: isAvailable, completed: true, label: 'Scheduling', slot: 'scheduling'},*/
      {name: '4', available: isAvailable, completed: false, label: confirmLabel, slot: 'confirmation'}
    ];
    // const advancedSteps = [
    //   {name: '5', available: isAvailable, completed: true, label: advLabel, slot: 'dataPreview'},
    // ];
    const steps = this.config ? this.config.steps.filter(obj => obj.slot !== 'dataPreview') : basicSteps;
    
    // For testdata
    // if (advancedMode || true) {
    if (advancedMode) {
      // steps.splice(this.ADVANCED_STEP_NUMBER, 0, ...advancedSteps);
    }
    steps[steps.length - 2].name = (steps.length - 1) + '';
    steps[steps.length - 1].name = steps.length + '';
    const stepConfig = {
      steps,
      currentStepIdx: this.config ? this.config.currentStepIdx : 0
    };
    this.config = {...stepConfig};
  }

  public goProcessAnalsysis = (): void => {
    this.router.navigate(['/discover/process-analysis']);
  }

  public handleData = (event: string[]): void => {
    this.newAnalysis[event[0]] = event[1];
    if (event[0] === 'name') {
      this.createObjectHeader();
    }
  }

  public handleStatus = (newStatus: NewAnalysisStepStatus): void => {
    const stepStatus = this.config.steps.filter(step => step.slot === newStatus.step)[0];
    stepStatus.completed = newStatus.completed;
    this.disableSave = !stepStatus.completed
    if (newStatus.step === 'map' && stepStatus.completed) {
      this.doAutoMap = false
      // Enable the last two tabs
      this.config.steps.forEach(step => step.available = true)
      this.config = {...this.config};
    }
  }

  public async changeTab(delta: number) {
    await this.checkClickedAwayFromAdvanced(this.config.currentStepIdx);
    const newSteptStep = this.config.currentStepIdx + delta;
    this.checkClickedIntoAdvanced(newSteptStep);
    this.config.steps[newSteptStep].available = true;
    this.config = {...this.config, currentStepIdx: newSteptStep};
  }

  public async handleStepClick(step) {
    await this.checkClickedAwayFromAdvanced(this.previousStep);
    this.checkClickedIntoAdvanced(step.detail);
    this.previousStep = step.detail;
    // this.config.currentStepIdx = step.detail;
  }

  // Check if the user clicked into advanced so we can send data to SF
  private checkClickedIntoAdvanced(stepNumber) {
    if (stepNumber === this.ADVANCED_STEP_NUMBER && this.advancedMode) {
      this.dataPreview?.ngOnInit();
      this.dataPreview?.updateStatus();
    }
  }

  // Check if the user clicked away from advanced so we need to read the spotfire data.
  private async checkClickedAwayFromAdvanced(oldStepNumber) {
    if (oldStepNumber === this.ADVANCED_STEP_NUMBER && this.advancedMode) {
      // console.log('Clicked away from advanced...');
      if (this.dataPreview) {
        await this.dataPreview.extractSpotfireFilterData();
        // Trigger a change on the analysis
        this.newAnalysis = {...this.newAnalysis};
      }
    }
  }

  public handleSelectedDataset = (event): void => {
    if (this.newAnalysis.datasetId !== event) {
      this.doAutoMap = true;
      if (this.newAnalysis.mappings) {
        this.newAnalysis.mappings = {} as unknown as Mapping;
      }
    }
    this.newAnalysis.datasetId = event;
    this.datasetName = event;
    this.getPreviewData(this.newAnalysis.datasetId)
  }

  public hide = (element: string): boolean => {
    if (element === 'prev') {
      // When disable save is true the analysis is running, so don't show the back button.
      return this.config.currentStepIdx === 0 || this.mode === 'edit' || this.disableSave;
    }
    if (element === 'next') {
      return this.config.currentStepIdx === this.config.steps.length - 1 || this.mode === 'edit'; // this.config.currentStepIdx === 4 && !this.advancedMode;
    }
    if (element === 'finish') {
      return this.config.currentStepIdx !== this.config.steps.length - 1 || this.mode === 'edit';
    }
    if (element === 'save') {
      return this.mode !== 'edit';
    }
  }

  public toggleAdvanced = (): void => {
    this.advancedMode = !this.advancedMode;
    this.getStepperConfiguration(this.advancedMode);
  }


  public handleDisableNextButton() {
    return !this.config.steps[this.config.currentStepIdx].completed;
  }

  public handleSaveButton() {
    // Removed last part to allow save and rerun on last page during edit
    return this.disableSave; // || !this.config.steps[this.config.currentStepIdx].completed;
  }

  public goToAnalysis() {
    this.router.navigate(['/discover/process-analysis'], {queryParams: {focus: this.newAnalysisId || this.analysisId}});
  }

  public goToTemplate() {
    if (this.newAnalysisId) {
      this.router.navigate([`/discover/select-template/${this.newAnalysisId}`]);
    }
  }

  createAnalysis() {
    this.repositoryMS.getAnalysises().subscribe((analysisList: Analysis[]) => {
      let analysises = analysisList;
      let analysisOnProcessMining = false;
      let analysisOnProcessMiningId;
      for(let an of analysises){
        if(an.metadata.status.toLowerCase() === 'process mining'){
          analysisOnProcessMining = true;
          analysisOnProcessMiningId = an.id;
          break;
        }
      }
      if(analysisOnProcessMining){
        openStopCreationDialog(analysisOnProcessMiningId, this.dialog, this.dialogRef);
      }else{
        this.cancelText = this.translationService.translate('new.analysis.button.close');
        // Disable the save button
        this.disableSave = true;
        this.progress = {
          message1: this.translationService.translate('new.analysis.message1.adv'),
          message2: this.translationService.translate('new.analysis.message2.adv'),
          percentage: 0,
          status: '',
          enabled: false
        };
        if(this.newAnalysis.folder){
          this.newAnalysis.name = this.newAnalysis.folder+'||'+this.newAnalysis.name
        }
        delete this.newAnalysis.folder;
      // Store the mappings
        this.autoMapService.storeMappings(this.newAnalysis.mappings).then(() => {
          this.messageService.sendMessage('news-banner.topic.message', 'Mappings saved...');
        })
        // Create the analysis
        this.repositoryMS.postAnalysis(this.newAnalysis).pipe(
          concatMap(resp => {
            const id = resp.id;
            this.newAnalysisId = id;
            this.progress.enabled = true;
            return this.repositoryMS.getAnalysisStatus(id).pipe(
              repeatWhen(obs => obs.pipe(delay(2000))),
              filter(data => {
                const dataS: AnalysisStatus = data;
                this.progress.percentage = dataS.progress;
                if (dataS.status === 'Ready') {
                  this.progress.percentage = 100;
                }
                this.progress.status = dataS.description;
                return (dataS.progress === 100 || dataS.status === 'Ready') // || (data.progress === 0 && data.description !== 'Starting ...'))
              }),
              take(1)
            );
          })
        ).pipe(
          // purposely delay 2 secs to show the final result before redirect to the list
          delay(2000)
        ).subscribe(
          analysisStatus => {
            this.progress.enabled = false;
            this.showResult = true;
            this.success = analysisStatus.status === 'Ready';
            if (!this.success && analysisStatus.description) {
              this.errorMsg = analysisStatus.description;
            }
            const action = this.mode !== 'edit' ? 'created' : 'updated';
            const message = `Analysis ${this.newAnalysis.name} has been ${action} successfully.`
            window.setTimeout(() => {
              this.messageService.sendMessage('news-banner.topic.message', message);
            }, 3000);

          },
          error => {
            // todo: show the error result page
            console.error('Error creating the analysis.', error);
            this.showResult = true;
            this.success = false;
            this.errorMsg = 'Failed to create the analysis';
          }
        );
      }
    })
  }

  async updateAnalysis(doRerun: boolean) {
    // Check exists analysis name
    // Get all analysis names
    let nameExits = false;
    let sName = this.newAnalysis.name;
    this.analysisList = await this.repositoryMS.getAnalysises().toPromise();
    this.analysisNames = this.analysisList.filter(
      (el: Analysis) => el.id !== this.analysisId
    ).map(
      (el: Analysis) => el.name
    );
    if(this.analysisNames.includes(this.newAnalysis.name)){
      nameExits = true;
    }
    if(this.newAnalysis.folder !== ''){
      this.newAnalysis.name = this.newAnalysis.folder+'||'+this.newAnalysis.name;
    }else{
      this.newAnalysis.name = 'General||'+this.newAnalysis.name;
    }
    if(this.analysisNames.includes(this.newAnalysis.name)){
      nameExits = true;
    }
    if(nameExits == true){
      this.newAnalysis.name = sName;
      const mes = {
        type: 'ERROR',
        message: 'A file with the same name exists in the destination folder.'
      };
      this.messageService.sendMessage('news-banner.topic.message', 'MESSAGE:' + JSON.stringify(mes));
      return;
    }

    this.cancelText = this.translationService.translate('new.analysis.button.close');
    // Get the latest spotfire filters
    await this.dataPreview?.extractSpotfireFilterData();
    // Save analysis id in local storage
    localStorage.setItem('folderFocus', this.newAnalysis.folder);
    // Check if mappings have changed
    this.repositoryMS.getAnalysisDetails(this.route.snapshot.paramMap.get('id')).subscribe(() => {
      // analysis.data.mappings.
      const mappingChanged = false;
      const FIELD_NAMES = MapDef.PROP_NAMES.concat(MapDef.PROP_NAMES_TIME);
      FIELD_NAMES.forEach(_field => {
        // if (anal.data.mappings[field] !== this.newAnalysis.mappings[field]) {
        //   mappingChanged = true;
        // }
      });
      if (mappingChanged) {
        // Store the mappings
        this.autoMapService.storeMappings(this.newAnalysis.mappings);
        this.messageService.sendMessage('news-banner.topic.message', 'Mappings saved...');
      }
      this.repositoryMS.updateAnalysis([convertCreateAnalysisToUpdate(this.newAnalysis, this.route.snapshot.paramMap.get('id'))]).subscribe(
        () => {
          const message = `Analysis ${sName} has been updated successfully.`
          this.messageService.sendMessage('news-banner.topic.message', message);
          window.setTimeout(() => {
            if (doRerun) {
              // Clear the current analytic
              this.messageService.sendMessage('clear-analytic.topic.message', 'OK');
              this.repositoryMS.rerunAnalysis(this.route.snapshot.paramMap.get('id'), this.analysisVersion).subscribe(_response => {
                this.goToAnalysis();
              })
            } else {
              this.goToAnalysis();
            }
          }, 1000);
        },
        error => {
          console.error('Error updating the analysis.', error);
          this.showResult = true;
          this.success = false;
          this.errorMsg = 'Failed to update the analysis';
        });
    });
  }

}

export function openStopCreationDialog(analysisId, dialog, dialogRef) {
  dialogRef = dialog.open(NewAnalysisStopCreationComponent, {
    width: '720px',
    height: 'auto',
    data: {
      analysisId: analysisId
    }
  });
  dialogRef.afterClosed().subscribe(() => {
  });
}
