import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {copyToClipBoard} from '../../../functions/details';
import {DatePipe} from '@angular/common';
import {AttributeDef} from '@tibco/tc-web-components/dist/types/models/attributeDef'
import {Router} from '@angular/router';
import { CatalogService, Dataset, FileDatasetConfiguration } from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../../service/internal-message.service';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'dataset-details',
  templateUrl: './dataset-details.component.html',
  styleUrls: ['./dataset-details.component.css']
})
export class DatasetDetailsComponent implements OnInit, OnChanges {

  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private datePipe: DatePipe,
    public messageService: InternalMessageService,
    private translationService: TranslationService,
  ) {
  }

  @Input() dataset: Dataset;
  @Input() messageLength: number;

  datasetDetail: Dataset;
  currentLanguage: string;

  copyToClipBoard = copyToClipBoard;

  showError = false;

  details: AttributeDef[];

  ngOnInit(): void {

    this.translationService.currentLang$.subscribe(() => {
      this.currentLanguage = this.translationService.getCurrentLanguage();
      this.setDetails()
    });


    this.catalogService.getDataset(this.dataset.id).subscribe(dataset => {
      this.datasetDetail = dataset;
      this.setDetails()
    })
  }

  handleDetailAttributeCopy(detail: any) {
    if(detail && detail.label && detail.value) {
      copyToClipBoard(detail.label, detail.value, this.messageService)
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setDetails()
  }
  private setDetails() {
    if (this.dataset && this.datasetDetail) {
      const details = [
        {label: this.translationService.translate("dataset.details.label.name"), value: this.dataset.name},
        {label: this.translationService.translate("dataset.details.label.description"), value: this.dataset.description},
        {label: this.translationService.translate("dataset.details.label.createdOn"), value: this.datePipe.transform(this.datasetDetail.metadata.createdOn, 'short', '', this.currentLanguage)},
        {label: this.translationService.translate("dataset.details.label.modifiedOn"), value: this.datePipe.transform(this.datasetDetail.metadata.modifiedOn, 'short', '', this.currentLanguage)},
        {label: this.translationService.translate("dataset.details.label.id"), value: this.dataset.id, copyable: true},
        {label: this.translationService.translate("dataset.details.label.type"), value: this.dataset.type},
        {label: this.translationService.translate("dataset.details.label.fileName"), value: this.datasetDetail.type === Dataset.TypeEnum.File ? (this.datasetDetail.configuration as FileDatasetConfiguration).filename : '', actionable: false},
      ];
  
      if (this.datasetDetail.metadata.archivedOn) {
        details.push({label: this.translationService.translate("dataset.details.label.archived.on"), value: this.datePipe.transform(this.datasetDetail.metadata.archivedOn, 'short', '', this.currentLanguage)});
      }
  
      if (this.datasetDetail.metadata.deletionOn) {
        details.push({label: this.translationService.translate("dataset.details.label.deletion.on"), value: this.datePipe.transform(this.datasetDetail.metadata.deletionOn, 'short', '', this.currentLanguage)});
      }
  
      this.details = details;
    }
  }
  

  public goCreateNewAnalysis() {
    this.messageService.sendMessage('set-main-tab.topic.message', 'process-analysis');
    this.router.navigate(['/discover/new-analysis'], {queryParams: {initialDatasetId: this.dataset.id}});
  }

  goAnalysisDetail(id: string) {
    this.router.navigate(['/discover/process-analysis'], {queryParams: {focus: id}});
  }

  goFileDetail(ev: any){
    console.log('ev: ', ev)
    if(ev?.detail?.value) {
      if(ev.detail.label === 'File Name') {
        this.router.navigate(['/discover/connections'], {queryParams: {focus: ev.detail.value}});
      }
    }
  }
  get additionalInfoLabel(): string {

    return  this.translationService.translate("dataset.details.label.info")
  }
  get errorDetails(): string {
    // if both errors - return them on different lines
    if (this.dataset?.metadata?.error && this.dataset.metadata.errorCaptureData) {
      return this.dataset.metadata.error + '\n\n' + this.dataset.metadata.errorCaptureData;
    } else {
      // if only one of the errors return the one populated
      return this.dataset?.metadata?.error ? this.dataset?.metadata?.error : this.dataset.metadata.errorCaptureData;
    }
  }

}
