import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2
}

@Component({
  selector: 'resizable-draggable',
  templateUrl: './resizable-draggable.component.html',
  styleUrls: ['./resizable-draggable.component.scss']
})
export class ResizableDraggableComponent implements OnInit, AfterViewInit {
  @Input() width: number;
  @Input() height: number;
  @Input() left: number;
  @Input() top: number;

  @Input() minHeight: number;
  @Input() minWidth: number;
  @ViewChild('box') public box: ElementRef;

  @Output() enableSFPointerEvents: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resizeToSmallSize: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resize: EventEmitter<any> = new EventEmitter<any>();
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: { x: number, y: number }
  public status: Status = Status.OFF;
  private mouseClick: { x: number, y: number, left: number, top: number };
  private posBeforeMax: { top: number, left: number, height: number, width: number };

  showResizeHandler = true;

  maximized = false;
  private maxDimension: { width: number, height: number };

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadBox();
    // this.loadContainer();

    this.initMinSize();
  }

  private loadBox() {
    setTimeout(() => {
      const {left, top} = this.box.nativeElement.getBoundingClientRect();
      // console.log(`loadBox [${this.left}, ${this.top}]`);
      this.boxPosition = {left, top};
    }, 100);
  }

  // private loadContainer(){
  //   // const left = this.boxPosition.left - this.left;
  //   // const top = this.boxPosition.top - this.top;
  //   const left = this.containerLeft;
  //   const top = this.containerTop;
  //   const right = this.containerWidth + this.containerLeft;
  //   const bottom = this.containerHeight + this.containerTop;
  //   this.containerPos = { left, top, right, bottom };
  //   // console.log('[loadContainer] this.containerPos: ', this.containerPos);
  // }

  private initMinSize() {
    if (!this.minHeight) {
      this.minHeight = 0;
    }
    if (!this.minWidth) {
      this.minWidth = 0;
    }
  }

  public setContainerScope(scope) {
    const left = scope.left;
    const top = scope.top;
    const right = scope.width + left;
    const bottom = scope.height + top;
    this.containerPos = {left, top, right, bottom};
    // console.log('[setContainerScope] this.containerPos: ', this.containerPos);
  }

  public startDrag(event) {
    this.loadBox();
    this.setStatus(event, Status.MOVE);
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    // console.log('onMouseUp');
    this.setStatus(event, 0);
    this.enableSFPointerEvents.emit(true);
  }

  setStatus(event: MouseEvent, status: number) {
    // console.log('set status to ' + status);
    if (status === 0) {
      // console.log('set status = 0, the mouse up');
    }
    if (status === Status.RESIZE || status === Status.MOVE) {
      // console.log('disable the pointer event for spotfire iframe');
      this.enableSFPointerEvents.emit(false);
    }
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = {x: event.clientX, y: event.clientY, left: this.left, top: this.top};
    } else {
      this.loadBox();
    }
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    // console.log('onMouseMove', event);
    // console.log('this.status = ' + this.status);
    this.mouse = {x: event.clientX, y: event.clientY};

    if (this.status === Status.RESIZE) this.leftExpand();
    else if (this.status === Status.MOVE) this.move();
  }

  // private mouseInBox() {
  //   if (this.mouse.x >= this.boxPosition.left && this.mouse.x <= this.boxPosition.left + this.width && this.mouse.y >= this.boxPosition.top && this.mouse.y <= this.boxPosition.top + this.height) {
  //     return true;
  //   }
  //   return false;
  // }

  /**
   * Resize the component by drag the right-bottom corner;
   * @deprecated
   */
  // private resize() {
  //   // console.log('resize')
  //   if (this.resizeCondMeet()) {
  //     // console.log(`meet resize condition, resize. mouse[${this.mouse.x}, ${this.mouse.y}] boxPosition[${this.boxPosition.left}, ${this.boxPosition.top}]`);
  //     this.width = Math.max(Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0, this.minWidth);
  //     this.height = Math.max(Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0, this.minHeight);
  //
  //     if (this.maxDimension && (this.width < this.maxDimension.width || this.height < this.maxDimension.height)) {
  //       this.maximized = false;
  //       this.resizeToSmallSize.emit(true);
  //     }
  //   }
  // }

  private expandCondMeet() {
    return (this.mouse.x < this.containerPos.right - this.minWidth && this.mouse.x > this.containerPos.left + 40);
  }

  private leftExpand() {
    if (this.expandCondMeet()) {
      this.left = this.mouse.x;
      this.width = Math.max(this.minWidth, this.containerPos.right - this.left);
      // console.log(`leftExpand. this.width = ${this.width}  this.left=${this.left}`)
      this.resize.emit({
        left: this.left,
        width: this.width
      });

      if (this.maxDimension && this.width < this.maxDimension.width) {
        this.maximized = false;
        this.resizeToSmallSize.emit(true);
      }
    }
  }

  public verticalCollapse(newHeight: number) {
    this.showResizeHandler = false;
    return this.changeHeight(newHeight);
  }

  public verticalExpand(newHeight: number) {
    this.showResizeHandler = true;
    return this.changeHeight(newHeight);
  }

  public maximize(maxTop: number) {
    this.posBeforeMax = {top: this.top, left: this.left, height: this.height, width: this.width};
    const margin = 0;
    this.left = this.containerPos.left + margin;
    this.top = maxTop;
    this.width = this.containerPos.right - this.containerPos.left - margin;
    this.height = this.containerPos.bottom - maxTop - margin;

    this.maximized = true;
    this.maxDimension = {width: this.width, height: this.height};

    return this.getBindingClientRect();
  }

  public restore() {
    this.left = this.posBeforeMax.left;
    this.top = this.posBeforeMax.top;
    this.width = this.posBeforeMax.width;
    this.height = this.posBeforeMax.height;

    // there is resize before restore
    if (this.left + this.width !== this.containerPos.right) {
      this.left = this.left + (this.containerPos.right - (this.left + this.width));
    }

    this.maximized = false;

    return this.getBindingClientRect();
  }

  public getBindingClientRect() {
    return {
      x: this.left,
      y: this.top,
      left: this.left,
      top: this.top,
      width: this.width,
      height: this.height
    }
  }

  private changeHeight(newHeight: number) {
    const height = this.height;
    this.height = newHeight;

    if (newHeight > height) {
      // the component becomes bigger
      if (newHeight + this.top > this.containerPos.bottom) {
        this.top = this.containerPos.bottom - newHeight;
      }
    }
    return height;
  }

  // private resizeCondMeet() {
  //   return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
  // }

  private move() {
    if (this.moveCondMeet()) {
      this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
      this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
    }
  }

  private moveCondMeet() {
    const offsetLeft = this.mouseClick.x - this.boxPosition.left;
    const offsetRight = this.width - offsetLeft;
    const offsetTop = this.mouseClick.y - this.boxPosition.top;
    const offsetBottom = this.height - offsetTop;
    // const meetMoveCond = (this.mouse.x > this.containerPos.left + offsetLeft &&
    //   this.mouse.x < this.containerPos.right - offsetRight &&
    //   this.mouse.y > this.containerPos.top + offsetTop &&
    //   this.mouse.y < this.containerPos.bottom - offsetBottom);
    // console.log('meet move condition : ', meetMoveCond);
    // console.log('this.mouseClick(x, y) = (' + [this.mouseClick.x, this.mouseClick.y].join(',') + ')');
    // console.log('this.boxPosition(left, top) = (' + [this.boxPosition.left, this.boxPosition.top].join(',') + ')');
    // console.log('this.containerPos(left, right, top, bottom) = (' + [this.containerPos.left, this.containerPos.right, this.containerPos.top, this.containerPos.bottom].join(',') + '); offset(left, right, top, bottom) = (', [offsetLeft, offsetRight, offsetTop, offsetBottom].join(',') + ')');
    return (
      this.mouse.x > this.containerPos.left + offsetLeft &&
      this.mouse.x < this.containerPos.right - offsetRight &&
      this.mouse.y > this.containerPos.top + offsetTop &&
      this.mouse.y < this.containerPos.bottom - offsetBottom
    );
  }
}
