<!-- <div class="filter-header">
  <div class="filter-sub-header">
    <div class="filter-type">
      <div class="filter-type-label">{{ 'list.templates.button.filter' | translate }}</div>
      <div *ngFor="let fil of filterTypes; let i = index" [ngClass]="{'filter-enabled': filterEnabled[i]}"
            class="filter-item" (click)="filterSelected(i)">{{fil.replace(' ', '-').replace('User-defined','Custom')}}
      </div>
    </div>
  </div>
</div> -->
<div class="flex">
  <div *ngFor="let template of filteredTemplates" class="flex-item width-xs-10 width-md-333">
    <template-card [cardConfig]="template" [mode]="mode" [isSelected]="isSelected(template.id)"
                    (selectedCard)="handleSelected($event)" [highLight]="searchValue"></template-card>
    <!--  [highLight]="highLight" [isSelected]="temp.name == selectedTemplate?.name" -->
  </div>
  <!-- <div *ngIf="!isSelectMode" class="new-template" (click)="handleNewTemplate()">
    <div class="new-template-content">
      <uxpl-icon icon="pl-icon-add" size="20px"></uxpl-icon>
      <span>{{ 'list.templates.button.create' | translate }}</span>
    </div>
  </div> -->
</div>
