import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateDataset, Dataset, DatasetSchema } from '@tibco/discover-client-lib';
import { SelectItem } from 'primeng/api';
import { CsvService } from 'src/app/service/csv.service';
import { DatasetService } from 'src/app/service/dataset.service';
import { ParsingService } from 'src/app/service/parsing.service';
import { DatasetSchemaType, DatasetWizard } from '../../../models_ui/dataset';
import { NewAnalysisStepStatus } from '../../../models_ui/discover';

@Component({
  selector: 'dataset-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss']
})
export class NewDatasetAttributesComponent implements OnInit {

  valid = false;

  readonly datatypeValue: string[] = [DatasetSchemaType.STRING, DatasetSchemaType.INTEGER, DatasetSchemaType.NUMERIC, DatasetSchemaType.TIMESTAMP];
  datatypeOptions: SelectItem[];
  schema: DatasetSchema[];

  @Input() data: Dataset | CreateDataset;
  @Input() backupDataset: Dataset;
  @Input() previewData: any[];
  @Input() wizard: DatasetWizard;
  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter();

  constructor(
    protected csvService: CsvService,
    protected parsingService: ParsingService,
    protected datasetService: DatasetService
  ) { }

  ngOnInit(): void {
    this.schema = this.data.schema;
    this.datatypeOptions = this.datatypeValue.map(v => {
      return {
        label: v.charAt(0).toUpperCase() + v.substring(1),
        value: v
      } as SelectItem;
    });

    if (this.previewData) {
      if ((!this.wizard.editMode && !this.isTimestampSelected()) || this.wizard.attributesUnpredicted) {
        this.predictSchemaType();
      }
    } else {
      this.datasetService.pullPreviewData(this.data as Dataset, this.wizard).subscribe(data => {
        this.handlePreviewData.emit({
          previewData: data
        });
        this.predictSchemaType();
        this.updateStatus();
      });
    }

    this.updateStatus();
  }

  private isTimestampSelected() {
    return this.schema.filter(schema => schema.type == DatasetSchemaType.TIMESTAMP).length > 0;
  }

  private predictSchemaType() {
    const firstRow = this.previewData[0];
    if (firstRow) {
      this.schema.forEach((schema) => {
        const value = firstRow[schema.label];
        if (value != null) {
          let type;
          if (/^\s*[\d]*\.{0,1}\d*\s*$/.test(value)) {
            // could be numeric or integer
            const f = parseFloat(value);
            if (!isNaN(f)) {
              if (f.toString().indexOf('.') !== -1) {
                type = DatasetSchemaType.NUMERIC;
              } else {
                type = DatasetSchemaType.INTEGER;
              }
            }
          }
          if (!type) {
            // new Date('Case 1') will get a valid date, so now only the predefined dates format to guess date
            if (this.parsingService.guessDateFormat(value)) {
              type = DatasetSchemaType.TIMESTAMP;
            } else {
              type = DatasetSchemaType.STRING;
            }
          }
          if (type) {
            schema.type = type;
          }
        }
      });
    }
  }

  public onSelectDatatype(event: any, column: DatasetSchema) {
    if (event) {
      column.type = event.detail.value;
      this.updateStatus();
    }
  }

  private updateStatus = (): void => {
    this.valid = this.validateDatatype();
    const stepStatus = {
      step: 'dataset-attributes',
      completed: this.valid
    } as NewAnalysisStepStatus;

    this.status.emit(stepStatus);

    if (this.valid) {
      this.wizard.attributesUnpredicted = false;
    }
  }

  private validateDatatype(): boolean {
    let findDatetime = false;
    for (const {type: datatype} of this.schema) {
      if (datatype) {
        if (DatasetSchemaType.TIMESTAMP === datatype) {
          findDatetime = true;
        }
      } else {
        return false;
      }
    }
    return findDatetime;
  }

  getPosition(location: number) {
    if(location > 5){
      return 'above';
    } else {
      return 'below';
    }
  }

}
