import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import {
  CompanyAndRoles,
  Organization,
  UserInfoResponse,
} from 'src/app/models_ui/auth';
import { AuthService } from 'src/app/service/auth.service';
import { UsersService } from 'src/app/service/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.css'],
})
export class SelectOrganizationComponent implements OnInit {
  orgIsLoading: boolean = false;
  orgs: CompanyAndRoles[] = [];
  userInfo: UserInfoResponse;
  userRole: string;
  superadmin: boolean = false;
  supportEmail: string = environment.supportEmail;

  constructor(
    private cookieService: CookieService,
    private authService: MsalService,
    private authorizationService: AuthService,
    private usersService: UsersService,
    private router: Router
  ) {
    this.cookieService.delete('org');
  }

  ngOnInit(): void {
    this.userRole = this.cookieService.get('userRole');
    this.authorizationService.getUserinfo().subscribe({
      next: (response) => {
        this.userInfo = response;
        this.superadmin = this.userInfo.superadmin;
        this.cookieService.set('userinfo', JSON.stringify(response), {
          path: '/',
        });
      },
      error: (response) => {
        console.error('Response error: ', response);
      },
    });
    this.authorizationService.getMembership().subscribe({
      next: (response) => {
        this.orgs = response;
        this.orgIsLoading = true;
      },
      error: (response) => {
        console.error('Response error: ', response);
        this.orgIsLoading = true;
      },
    });
  }

  selectOrg(org: Organization) {
    localStorage.setItem('org', JSON.stringify(org));
    this.authorizationService
      .setOrganization({
        companyId: org.companyId,
      })
      .subscribe({
        next: () => {
          this.usersService.getUser(this.userInfo.id).subscribe({
            next: (response) => {
              this.cookieService.set('userRole', response.role, { path: '/' });
              this.router.navigate(['/discover/process-analysis']);
            },
            error: (error) => {
              console.error('Response error: ', error);
              this.orgIsLoading = true;
            },
          });
        },
        error: (response) => {
          console.error('Response error: ', response);
          this.orgIsLoading = true;
        },
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }
}
