<div class="settings-main">
  <div class="settings-title">
    <h1>Branding</h1>
    <div class="settings-buttons">
      <uxpl-button type="secondary" (clicked)="handleReset()">Reset</uxpl-button>
      <uxpl-button type="secondary" (clicked)="handlePreview()">Preview</uxpl-button>
      <uxpl-button (clicked)="handleSave()">Save</uxpl-button>
    </div>
  </div>
  <div class="settings-container">
    <div *ngIf="generalInformation">
      <h2>General</h2>
      <div class="settings-subcategory">
        <h3>Application</h3>
        <uxpl-form-field id="#appTitle" fieldId="appTitle" style="margin-top: 5px; margin-bottom: 5px;" label="Application title" type="text" valid required="true" [value]="generalInformation.applicationTitle" (uxplChanged)="handleUpdate($event, 'general')"></uxpl-form-field>
      </div>
    </div>
    <div *ngIf="landingPage">
      <h2>Welcome screen</h2>
      <div>
        <div class="settings-subcategory">
            <h3>Introduction</h3>
            <uxpl-form-field id="#welcomeScreenTitle" fieldId="welcomeScreenTitle" style="padding-top: 5px; padding-bottom: 5px;" label="Title" type="text" valid required="true" [value]="landingPage.title" (uxplChanged)="handleUpdate($event, 'landingPage', 'title')"></uxpl-form-field>
            <uxpl-form-field id="#appTitle" fieldId="appTitle" style="padding-top: 5px; padding-bottom: 5px;" label="Subtitle" type="text" valid required="true" [value]="landingPage.subtitle" (uxplChanged)="handleUpdate($event, 'landingPage', 'subtitle')"></uxpl-form-field>
        </div>
        <div class="settings-subcategory">
          <h3>Capabilities</h3>
          <div>
            <p-table [value]="landingPage.highlights" styleClass="custom-table-css">
              <ng-template pTemplate="header">
                <tr>
                  <th class="top-row" style="width: 15%">Icon</th>
                  <th class="top-row" style="width: 25%">Title</th>
                  <th class="top-row" style="width: 60%">Description</th>
                  <th class="top-row-empty" style="width: 50px">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-highlight let-rowIndex="rowIndex">
                <tr class="rowhover" >
                <td class="no-padding data-row hovereffect">
                  {{ highlight.iconURL.slice(highlight.iconURL.lastIndexOf('/') + 1) }}
                </td>
                <td class="no-padding data-row hovereffect">{{ highlight.title }}</td>
                <td class="no-padding data-row hovereffect">{{ highlight.content }}</td>
                <td class="no-padding">
                  <uxpl-icon icon="pl-icon-edit" width="24px" height="24px" (click)="handleHighlighEdit(rowIndex)"></uxpl-icon>
                </td>
              </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="settings-subcategory">
          <h3>Background image</h3>
          <div class="settings-line">
            <input type="file" #file style="display:none" (change)="handleUpload($event.target.files[0])">
            <uxpl-form-field class="setting-background-image" label="Background image" type="text" valid required="true" readonly="true" [value]="landingPage.backgroundURL.slice(landingPage.backgroundURL.lastIndexOf('/') + 1)"></uxpl-form-field>
            <uxpl-icon style="margin-bottom: -5px;" icon="pl-icon-upload" width="24px" height="24px" (click)="file.click()"></uxpl-icon>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2>Advanced <span class="advanced" (click)="showAdvanced = true">>></span></h2>
      <div *ngIf="showAdvanced" class="settings-subcategory">
        <h3 (dblclick)="showServer = true">Spotfire</h3>
        <uxpl-form-field *ngIf="showServer" id="#sfServer" fieldId="sfServer" style="margin-top: 5px; margin-bottom: 5px;" label="Alternative Spotfire Server (leave blank to use TIBCO Cloud Spotfire)" type="text" valid [value]="analytics.server" (uxplChanged)="handleUpdate($event, 'advanced', 'server')"></uxpl-form-field>
        <uxpl-form-field id="#previewDXP" fieldId="previewDXP" style="margin-top: 25px; margin-bottom: 5px;" label="Spotfire DXP used for preview" type="text" valid [value]="analytics.previewLocation" (uxplChanged)="handleUpdate($event, 'advanced', 'previewLocation')"></uxpl-form-field>
        <uxpl-form-field id="#dataTablePreviewDXP" fieldId="dataTablePreviewDXP" style="margin-top: 25px; margin-bottom: 5px;" label="Data table used in preview DXP" type="text" valid [value]="analytics.previewTableName" (uxplChanged)="handleUpdate($event, 'advanced', 'previewTableName')"></uxpl-form-field>
        <uxpl-form-field id="#userFolder" fieldId="userFolder" style="margin-top: 25px; margin-bottom: 5px;" label="Spotfire folder for user DXP's (used to store the template DXPs)" type="text" valid [value]="analytics.customUserFolder" (uxplChanged)="handleUpdate($event, 'advanced', 'customUserFolder')"></uxpl-form-field>
      </div>
    </div>
  </div>
</div>
