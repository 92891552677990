import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryService, Analysis, FolderMaps, DocumentationService } from '@tibco/discover-client-lib';
import { NimbusDocument, NimbusLocationConfig } from 'src/app/models_ui/nimbus';
import { notifyUser } from '../../functions/message';
import {InternalMessageService} from '../../service/internal-message.service';

@Component({
  selector: 'select-nimbus-process',
  templateUrl: './select-nimbus-process.component.html',
  styleUrls: ['./select-nimbus-process.component.scss']
})

export class SelectNimbusProcessComponent implements OnInit {

  nameError = false;

  document: NimbusDocument;
  analysis: Analysis;
  processMap: FolderMaps;

  mode: 'edit' | 'create';

  readonly documentLocationConfig: NimbusLocationConfig = {
    newFolder: false,
    select: 'process'
  }

  @Output() referenceModelUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<SelectNimbusProcessComponent>,
    private repositoryService: RepositoryService,
    private documentationMS: DocumentationService,
    private messageService: InternalMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.analysis = data.analysis;
    this.mode = data.mode;
  }

  ngOnInit(): void {
    this.document = {
      name: ''
    }
  }

  mapSelected = (event) => {
    if (event) {
      this.processMap = event;
    }
  }

  onCancel = () => {
    this.dialogRef.close();
  }

  onConfirm = () => {
    this.documentationMS.createReferenceModel(this.processMap.processId, this.analysis.id, this.analysis.name, this.analysis.dataset.id).subscribe(() => {
      notifyUser('INFO', 'Successfully assigned a reference model.', this.messageService);
    }, () => {
      notifyUser('ERROR', 'Failed to assign a reference model.', this.messageService);
    }, () => {
      this.dialogRef.close();
      this.referenceModelUpdated.emit();
    });
  }

  onRemove = () => {
    this.repositoryService.deleteReferenceModelsForAnalysis(this.analysis.id, this.analysis.metadata.version).subscribe(() => {
      notifyUser('INFO', 'Successfully removed reference model.', this.messageService);
    }, () => {
      notifyUser('ERROR', 'Failed to remove reference model.', this.messageService);
    }, () => {
      this.dialogRef.close();
      this.referenceModelUpdated.emit();
    })
  }
}
