import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectOption } from '../../models_ui/common';
import { TranslationService } from 'src/app/service/translate.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { CatalogMsService } from 'src/app/service/catalog-ms.service';
import { DownloadXesRequest } from 'src/app/models_ui/catalog';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'export-xes-dialog',
  templateUrl: './export-xes-dialog.component.html',
  styleUrls: ['./export-xes-dialog.component.css']
})
export class ExportXesDialogComponent implements OnInit {

  loading: boolean = false;
  mapping: FormGroup;
  mappingOptions: SelectOption[] = [];
  mappingFieldsRequired: any[];
  mappingFields: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExportXesDialogComponent>,
    public translationService: TranslationService,
    private snackBarService: SnackbarService,
    private catalogMsService: CatalogMsService
  ) {
    this.mappingFieldsRequired = [
      {fieldName: 'caseId', fieldDescription: this.translationService.translate('map.panel.field.caseId'), lockFieldName: 'input_case_id', type: ['String', 'Integer']},
      {fieldName: 'activity', fieldDescription: this.translationService.translate('map.panel.field.activity'), lockFieldName: 'input_activity_id', type: ['String', 'Integer']},
      {fieldName: 'startTime', fieldDescription: this.translationService.translate('map.panel.field.startTime'), lockFieldName: 'input_activity_start_timestamp', type: ['Timestamp']},
    ]
    this.mappingFields = [
      {fieldName: 'endTime', fieldDescription: this.translationService.translate('map.panel.field.endTime'), lockFieldName: 'input_activity_end_timestamp', type: ['Timestamp']},
      {fieldName: 'scheduledStart', fieldDescription: this.translationService.translate('map.panel.field.scheduledStart'), lockFieldName: 'input_scheduled_start', type: ['Timestamp']},
      {fieldName: 'scheduledEnd', fieldDescription: this.translationService.translate('map.panel.field.scheduledEnd'), lockFieldName: 'input_scheduled_end', type: ['Timestamp']},
      {fieldName: 'requester', fieldDescription: this.translationService.translate('map.panel.field.requester'), lockFieldName: 'input_requester', type: ['String', 'Integer']},
      {fieldName: 'resourceGroup', fieldDescription: this.translationService.translate('map.panel.field.resourceGroup'), lockFieldName: 'input_resource_group', type: ['String', 'Integer']},
      {fieldName: 'resource', fieldDescription: this.translationService.translate('map.panel.field.resource'), lockFieldName: 'input_resource_id', type: ['String', 'Integer']}
    ]
    this.mapping = this.fb.group({
      activity: [null, Validators.required],
      caseId: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null],
      otherAttributes: [null],
      requester: [null],
      resource: [null],
      resourceGroup: [null],
      scheduledEnd: [null],
      scheduledStart: [null],
    });
  }

  ngOnInit(): void {
    if(!(this.data && this.data.dataset && this.data.dataset.schema && Array.isArray(this.data.dataset.schema) && this.data.dataset.schema.length > 0)){
      this.snackBarService.openSnackBar(this.translationService.translate('generic.error'), this.translationService.translate('close'), 'error');
      this.dialogRef.close();
    }
  }

  getSelectedValues() {
    return Object.values(this.mapping.value).filter(value => value !== null);
  }

  isOptionDisabled(option: string): boolean {
    return this.getSelectedValues().includes(option);
  }

  onSubmit() {
    this.loading = true;
    if (this.mapping.valid) {
      let downloadXesRequest: DownloadXesRequest = {} as DownloadXesRequest;
      for(let [key, value] of Object.entries(this.mapping.value)){
        if(value){
          downloadXesRequest[key] = value;
        }else{
          downloadXesRequest[key] = 'none';
        }
      }
      this.catalogMsService.generateXes(this.data.dataset.id, downloadXesRequest).subscribe(
        (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], { type: response.body.type });
          const downloadLink = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `${this.data.dataset.id}.xes`;
          document.body.appendChild(downloadLink);
  
          downloadLink.click();
  
          window.URL.revokeObjectURL(url);
          document.body.removeChild(downloadLink);
          this.loading = false;
          this.dialogRef.close(this.mapping.value);
        },
        (error) => {
          this.loading = false;
          console.error('Download XES error: '+error);
          this.snackBarService.openSnackBar(this.translationService.translate('generic.error'), this.translationService.translate('close'), 'error');
        }
      )
    } else {
      this.loading = false;
      console.error('Form not valid');
    }
  }

}
