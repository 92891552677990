import { Component } from '@angular/core';
import { TranslationService } from 'src/app/service/translate.service';
import { LANGUAGES } from 'src/environments/environment';
import { Languages } from 'src/app/models_ui/languages';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  currentLangDisplay: string;
  currentLangCode: string;
  languages: Languages[] = LANGUAGES;

  constructor(private translationService: TranslationService) {
    this.updateLanguageDisplay(this.translationService.getCurrentLanguage());
    this.translationService.currentLang$.subscribe(lang => {
      this.updateLanguageDisplay(lang);
    });
  }

  changeLanguage(lang: string): void {
    this.translationService.changeLanguage(lang);
  }

  private updateLanguageDisplay(language: string): void {
    this.currentLangDisplay = this.languages.find((lang) => lang.code == language).shortName;
    this.currentLangCode = language;
  }
}
