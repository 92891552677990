<div *ngIf="investigationDetail && cConfig" class="cont-card">
  <div class="card-top">
    <div >{{detailTitle}} </div>
    <div *ngIf="cConfig.showMilestones">
      <div class="milestone-container">
        <uxpl-milestone-trailer small="true" [config]="milestone"></uxpl-milestone-trailer>
      </div>
      <div class="horizontal-divider"></div>
    </div>
    <investigation-details-table #invDetails [investigationDetail]="investigationDetail" [cConfig]="cConfig"></investigation-details-table>
    <div style="height: 16px"></div>
    <action-button-bar *ngIf="!hideActionBtn" [buttons]="actionButtons" (actionButtonClicked)="handleTBut($event)"></action-button-bar>
  </div>
</div>
