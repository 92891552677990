  <!--div class="field-row">
    <uxpl-form-field type="text" fieldId="connectionName" style="margin-top: 5px; margin-bottom: 5px;" label="Name" valid required="true"></uxpl-form-field>
    <uxpl-form-field type="text" fieldId="connectionDescription" style="margin-top: 5px; margin-bottom: 5px;" label="Description" valid required="false"></uxpl-form-field>
  </div>
  <div class="field-row">
    <uxpl-select-input label="Connection type" [options]="connectionTypeOptions"></uxpl-select-input>
    <div class="field-spacer"></div>
  </div-->
  <div class="hint-row">
    <div class="hint-content">
      <img src="assets/svg/connections-empty-state.svg">
      <div>{{ 'connection.type.selection' | translate }}</div>
    </div>
  </div>
  <div class="connection-options">
    <div class="connection-option" *ngFor="let connectionType of connectionTypeOptions" (click)="selection(connectionType)">
      <div>
        <img [src]="connectionType.icon">
        <div class="connection-type-label">{{connectionType.label}}</div>
      </div>
    </div>
  </div>
