<h2 mat-dialog-title>
    {{'administration.org.info.dialog.title' | translate}}
</h2>
<mat-dialog-content>
    <div id="org-info">
        <ul>
            <li>
                <h3>{{'administration.org.info.dialog.name' | translate}}</h3>
                <p>{{data.displayName}}</p>
            </li>
            <li>
                <h3>{{'administration.org.info.dialog.description' | translate}}</h3>
                <p>{{data.description}}</p>
            </li>
            <li>
                <h3>{{'administration.org.info.dialog.id' | translate}}</h3>
                <p>{{data.id}}</p>
            </li>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close' | translate}}</button>
</mat-dialog-actions>  