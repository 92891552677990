<div id="admin-statistics">
    <div class="flex">
        <div class="flex-item width-xs-10 width-md-25">
            <mat-card id="users-bar-chart" class="card-3-4 chart-card chart-card-small">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <header>
                        <label>
                            <mat-icon class="material-symbols-rounded">group</mat-icon>
                            {{'statistics.users' | translate}}
                        </label>
                        <label>
                            <span style="order: 2;">{{"statistics.users.total" | translate}}</span>
                            <span class="badge color-1-bg">{{usersTotal}}</span>
                        </label>
                        <div class="chart-legend"  *ngIf="adminStatistics && adminStatistics.users && adminStatistics.users.roles && adminStatistics.users.roles.length > 0">
                            <ul>
                                <li *ngFor="let role of adminStatistics.users.roles; let i = index;">
                                    <mat-icon [class]="userRolesColors[i]">circle</mat-icon>
                                    <span>{{role.role}}</span>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <main>
                        <canvas baseChart
                            [datasets]="usersBarChartData"
                            [labels]="usersBarChartLabels"
                            [options]="usersBarChartOptions"
                            [plugins]="usersBarChartPlugins"
                            [legend]="usersBarChartLegend"
                            chartType="bar">
                        </canvas>
                    </main>
                </mat-card-content>
            </mat-card>    
        </div>
        <div class="flex-item width-xs-10 width-md-5">
            <mat-card class="card-3-4 chart-card" id="database-bar-chart">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <header>
                        <label>
                            <mat-icon class="material-symbols-rounded">database</mat-icon>
                            {{'statistics.database.table.size' | translate}}
                        </label>
                        <label>
                            {{"statistics.summary.database.total.size" | translate}}
                            <span class="badge color-1-bg">{{dbTotalSize}}GB</span>
                        </label>
                        <!-- <div class="chart-legend">
                            <ul>
                                <li>
                                    <mat-icon class="primary-color">circle</mat-icon>
                                    <span>Seria A</span>
                                </li>
                            </ul>
                        </div> -->
                    </header>
                    <main>
                        <canvas baseChart
                            [datasets]="databaseBarChartData"
                            [labels]="databaseBarChartLabels"
                            [options]="databaseBarChartOptions"
                            [plugins]="databaseBarChartPlugins"
                            [legend]="databaseBarChartLegend"
                            chartType="bar">
                        </canvas>
                    </main>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item width-xs-10 width-md-25">
            <mat-card id="tokens-bar-chart" class="card-3-4 chart-card chart-card-small">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <header>
                        <label>
                            <mat-icon class="material-symbols-rounded">contextual_token</mat-icon>
                            {{'statistics.tokens' | translate}}
                        </label>
                        <label>
                            <span style="order: 2;">{{"statistics.tokens.total" | translate}}</span>
                            <span class="badge color-1-bg">{{tokenTotal}}</span>
                        </label>
                        <div class="chart-legend"  *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.completion_tokens && adminStatistics.summary.prompt_tokens">
                            <ul>
                                <li>
                                    <mat-icon class="color-3-text">circle</mat-icon>
                                    <span>{{"statistics.tokens.prompt" | translate}}</span>
                                </li>
                                <li>
                                    <mat-icon class="color-4-text">circle</mat-icon>
                                    <span>{{"statistics.tokens.completion" | translate}}</span>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <main>
                        <canvas baseChart
                            [datasets]="tokenBarChartData"
                            [labels]="tokenBarChartLabels"
                            [options]="tokenBarChartOptions"
                            [plugins]="tokenBarChartPlugins"
                            [legend]="tokenBarChartLegend"
                            chartType="bar">
                        </canvas>
                    </main>
                </mat-card-content>
            </mat-card>    
        </div>
        <div class="flex-item width-xs-10 width-sm-5 width-md-25">
            <mat-card class="card-1-4 color-1-bg white-color">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <main>
                        <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.analysis_count; else analysisCountNd">{{adminStatistics.summary.analysis_count}}</span>
                        <ng-template #analysisCountNd><span class="kpi-value">0</span></ng-template>
                    </main>
                    <footer>
                        <mat-icon class="material-symbols-rounded">insert_chart</mat-icon>
                        <label>{{"statistics.summary.analysis.count" | translate}}</label>
                    </footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item width-xs-10 width-sm-5 width-md-25">
            <mat-card class="card-1-4 color-2-bg white-color">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <main>
                        <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.cached_analysis_count; else analysisCacheCountNd">{{adminStatistics.summary.cached_analysis_count}}</span>
                        <ng-template #analysisCacheCountNd><span class="kpi-value">0</span></ng-template>
                    </main>
                    <footer>
                        <mat-icon class="material-symbols-rounded">speed</mat-icon>
                        <label>{{"statistics.summary.analysis.cached.count" | translate}}</label>
                    </footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item width-xs-10 width-sm-5 width-md-25">
            <mat-card class="card-1-4 color-3-bg white-color">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <main>
                        <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.dataset_count; else analysisCountNd">{{adminStatistics.summary.dataset_count}}</span>
                        <ng-template #datasetCountNd><span class="kpi-value">0</span></ng-template>
                    </main>
                    <footer>
                        <mat-icon class="material-symbols-rounded">description</mat-icon>
                        <label>{{"statistics.summary.dataset.count" | translate}}</label>
                    </footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-item width-xs-10 width-sm-5 width-md-25">
            <mat-card class="card-1-4 color-4-bg white-color">
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                <mat-card-content>
                    <main>
                        <span class="kpi-value" *ngIf="adminStatistics && adminStatistics.summary && adminStatistics.summary.report_count; else analysisCountNd">{{adminStatistics.summary.report_count}}</span>
                        <ng-template #reportCountNd><span class="kpi-value">0</span></ng-template>
                    </main>
                    <footer>
                        <mat-icon svgIcon="report-icon"></mat-icon>
                        <label>{{"statistics.summary.report.count" | translate}}</label>
                    </footer>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
