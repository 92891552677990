import {Component, Input} from '@angular/core';
import {stripOrgFolder} from '../../../functions/analysis';
import {CreateTemplate} from '@tibco/discover-client-lib';
import {TemplateAnalyticChoice} from '../../../models_ui/analyticTemplate';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'template-confirmation',
  templateUrl: './template-confirmation.component.html',
  styleUrls: ['./template-confirmation.component.css']
})
export class TemplateConfirmationComponent {

  @Input() template: CreateTemplate;
  @Input() isNewTemplate: boolean;
  @Input() selectedAnalytics: string;
  @Input() newDXPLocation: string;
  @Input() analyticsChoice: TemplateAnalyticChoice;

  stripOrgF = stripOrgFolder;

  constructor(
  ) {
  }

  public openSFReport = (): void => {
    window.open(environment.spotfireURL + '/spotfire/wp/analysis?file=' + this.template.analysisFileLocation);
  }

}
