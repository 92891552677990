import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConnectionParameters, ConnectionTypeOption } from '../../../models_ui/connectionDefinitions';
import { CatalogService,TCMConnection, DVConnection, CreateConnection} from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  templateUrl: './new-connection-wizard.component.html',
  styleUrls: ['./new-connection-wizard.component.scss']
})
export class NewConnectionWizardComponent {

  @Output() connectionSaved: EventEmitter<{ action: any, id?: string }> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<NewConnectionWizardComponent>,
    private catalogService: CatalogService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    dialogRef.disableClose = false;
  }

  connectionType: ConnectionTypeOption;
  connectionParams: ConnectionParameters;
  form = 'typeSelection';
  notification: any;
  processing = false;

  handleTypeSelection(selectedType: ConnectionTypeOption) {
    this.connectionType = selectedType;
    this.form = 'connectionParams';
  }

  handleParamsSelection(connectionParams: ConnectionParameters) {
    this.connectionParams = connectionParams;
  }

  back() {
    this.notification = undefined;
    this.connectionType = undefined;
    this.connectionParams = undefined;
    this.form = 'typeSelection';
  }

  close() {
    this.dialogRef?.close();
  }

  create() {
    this.processing = true;
    this.notification = undefined;
    let config: TCMConnection | DVConnection;
    switch (this.connectionType?.value) {
      case 'tcm': {
        config = {
          broker: this.connectionParams?.broker,
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
        } as TCMConnection;
        break;
      }
      case 'tdv': {
        config = {
          username: this.connectionParams?.userId,
          password: this.connectionParams?.password,
          hostname: this.connectionParams?.hostname,
          jdbc_port: this.connectionParams?.jdbcPort,
          http_port: this.connectionParams?.httpPort
        } as DVConnection;
        break;
      }
      default: {
        // invalid test
        console.error('Invalid creation request for connection type: ', this.connectionType);
        this.snackBarService.openSnackBar(this.translationService.translate('new.connection.snackbar') + this.connectionType, this.translationService.translate('connection.parameter.snackbar.close'),'error');

        this.processing = false;
        return;
      }
    }
    const connectionConfig: CreateConnection = {
      name: this.connectionParams?.name,
      description: this.connectionParams?.description,
      type: this.connectionTypeEnum,
      configuration: config
    } as CreateConnection;
    this.catalogService.createConnection(connectionConfig).subscribe(() => {
      this.form = 'connectionCreated';
      this.notification = undefined;
      this.processing = false;
    }, error => {
      this.processing = false;
      this.notify({
        type: 'ERROR',
        message: 'Failed to create connection' + (error?.error?.message ? ' - ' + error.error.message : '')
      });
      console.error(error);
    })
  }

  handleProcessingChange(processing: boolean) {
    this.processing = processing;
  }

  handleConnectionsClick() {
    this.dialogRef.close('goConnections');
  }

  handleNewDatasetClick() {
    this.dialogRef.close('goDatasets');
  }

  handleCloseNotification() {
    this.notification = undefined;
  }

  notify(notification) {
    this.notification = notification;
    setTimeout(() => {
      this.notification = undefined;
    }, 5000)
  }

  get isTypeSelection() {
    return this.form === 'typeSelection';
  }

  get isConnectionParams() {
    return this.form === 'connectionParams';
  }

  get isConnectionCreated() {
    return this.form === 'connectionCreated';
  }

  get isCreatable() {
    return this.connectionParams;
  }

  get connectionTypeEnum(): CreateConnection.TypeEnum {
    if (this.connectionType.value === 'tcm') {
      return CreateConnection.TypeEnum.Tcm;
    }
    if (this.connectionType.value === 'tdv') {
      return CreateConnection.TypeEnum.Dv;
    }
  }

}
