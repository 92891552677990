import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarContentComponent } from '../components/snack-bar-content/snack-bar-content.components';
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, type: 'success' | 'warn' | 'error', duration: number = 3000) {
    const config = new MatSnackBarConfig();
    config.data = { message,action, type };
    config.duration = duration;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'right';
    config.panelClass = [`snack-bar-${type}`];

    this.snackBar.openFromComponent(SnackBarContentComponent, config);
  }
}
