import {Component, Input} from '@angular/core';

@Component({
  selector: 'mini-progress-bar',
  templateUrl: './mini-progress-bar.component.html',
  styleUrls: ['./mini-progress-bar.component.scss']
})
export class MiniProgressBarComponent {

  @Input() percentage: number;
  @Input() message: string;

  constructor() {
  }

}
