<!--div> {{dates.columns}}</div-->
<div style="display: inline-block; width: 100%;">
  <div *ngIf="loadedData ; else loadingData" class="hint">
    <span>{{ 'data.parser.text' | translate }}</span>
  </div>
  <ng-template #loadingData>
    <div class="loading-indicator">
      <pulse-loading></pulse-loading>
      <!-- <uxpl-spinner style="height: 150px; min-width: 150px; max-width: 150px;" appearance="light"
                    message="Analyzing data..."></uxpl-spinner> -->
    </div>
  </ng-template>
</div>

<br>

<div class="datasource-csv-preview">
  <p-table #dt *ngIf="loadedData" [columns]="columns" autoLayout="true" [resizableColumns]="false" showCurrentPageReport="false" 
    [value]="loadedData" [scrollable]="true" [rows]="rows" [scrollHeight]="tableScrollHeight" styleClass="custom-table-css"
      [virtualScroll]="true" [virtualRowHeight]="30" [lazy]="true" (onLazyLoad)="dataSource?.loadMore($event)">
      <ng-template pTemplate="header" let-columns>
        <tr style="background-color: #fff;">
            <th class="top-row index-col" style="display: table-cell;">
                <div>{{ 'data.parser.row' | translate }}</div>
            </th>
            <th *ngFor="let df of dateFields" class="top-row date-col" style="display: table-cell;">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <uxpl-icon *ngIf="isBadColumn(col)" class="upload-icon warning-triangle" icon="pl-icon-warning-triangle" width="20px" height="20px" style="max-height: 20px;" color="#FAB632"></uxpl-icon>
                <div style="overflow: hidden; flex-grow: 1">{{df.name}}</div>
              </div>
            </th>
        </tr>
        <tr>
          <th style="padding-right: 24px;" class="index-col">
            <div>
              <uxpl-button type="icon-only" class="refresh-btn" icon="assets/images/svg/pl-icon-refresh.svg" (clicked)="refresh()"></uxpl-button>
            </div>
          </th>
          <th style="padding-right: 24px;" *ngFor="let df of dateFields; index as i" class="date-col">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <uxpl-select-input class="parse-options-csv-date-element-format" label="Date time format"  type="text" allow-input case-sensitive [value]="schema[df.index].format" [options]="getOptions(i)" 
                (uxplChanged)="handleDateFormatSelect($event, i)" (uxplInputChanged)="handleDateFormatEdit($event, i)" (click)="clickDateFormat($event)"></uxpl-select-input>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-idx="rowIndex">
          <tr >
            <td class="notdate index-col">
              <div>{{idx + 1}}</div>
            </td>
            <td *ngFor="let df of dateFields; index as i" [ngClass]="dateClass(rowData[df.name], df.name, i)" class="date-col">
              <div style="overflow: hidden">{{rowData[df.name]}}</div>
            </td>
          </tr>
      </ng-template>
  </p-table>
  <div class="table-footer-row">
    <div *ngIf="validRowsCount !== -1 ; else nocheck">
      <span class="valid-rows-check"></span>
      <span>{{ 'data.parser.desc' | translate }}{{validRowsCount}} {{ 'data.parser.rows' | translate }}</span>
      <span *ngIf="this.loadedData[loadedData?.length - 1] == undefined">{{ 'data.parser.scroll' | translate }}</span>
    </div>
    <ng-template #nocheck>
      <div>&nbsp;</div>
    </ng-template>
    <div class="footer-end" *ngIf="this.loadedData && this.loadedData.length > 0">
      <div *ngIf="this.loadedData[loadedData?.length - 1] !== undefined ; else loadingRowsCounter">{{ 'data.parser.all' | translate }}</div>
      <ng-template #loadingRowsCounter>
        <div>{{loadedData?.length - 1}} {{ 'data.parser.loaded' | translate }}</div>
      </ng-template>
    </div>
  </div>
</div>
