import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LandingPageButtonConfiguration, LandingPageHighlightConfiguration} from '@tibco/discover-client-lib';

@Component({
  selector: 'splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent  {

  /**
   * Splash Screen main Title
   */
  @Input() title: string;
  /**
   * Splash Sub-Title
   */
  @Input() subTitle: string;
  /**
   * Splash Configured Background Image URL to a SVG, PNG or Jpeg
   */
  @Input() backGroundImage: string;
  /**
   * Space above the highlight panel. Default is 0px
   */
  public topMargin = '0px';
  /**
   * Bullet Point Item Configuration with Icon's and Text
   */
  @Input() highlights: LandingPageHighlightConfiguration[];
  /**
   * Buttons configuration with text and route to go.
   */
  @Input() actionButtons: LandingPageButtonConfiguration[];

  /**
   * ~event getStartedEvent : Get Started Clicked
   * ~payload string : emits that user clicked get started button on cloud splash screen
   */
  @Output() getStartedEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Shows Application Overview like Configured
   */
  constructor() {
  }

  /**
   * Get Started Click to open the Application
   */
  getStartedClick() {
    this.getStartedEvent.emit();
  }

}
