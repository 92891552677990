import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {cloneDeep, isEqual} from 'lodash-es';
import {CatalogService, CreateDataset, Dataset, DatasetMetadata, DatasetSchema, DatasetStatus, DVDatasetConfiguration, ElementReference, FileDatasetConfiguration, TCMDatasetConfiguration, UpdateDataset} from '@tibco/discover-client-lib';
import {DatasetWizard} from 'src/app/models_ui/dataset';
import {DatasetService} from 'src/app/service/dataset.service';
import {notifyUser} from '../../../functions/message';
import {NewAnalysisStepStatus} from '../../../models_ui/discover';
import {calculateDatasetColumns, calculateDatasetData} from '../../../functions/dataset';
import {UIConnection} from '../../../models_ui/connections';
import {InternalMessageService} from '../../../service/internal-message.service';
import {StepperConfig} from '@tibco/tc-web-components/dist/types/models/stepperConfig';
import { TranslationService } from 'src/app/service/translate.service';

export type DatasetAction = 'GO_DATASET' | 'CANCEL' | 'CREATE_ANALYSIS' | 'SAVED' | 'ERROR'

@Component({
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class NewDatasetWizardComponent implements OnInit {

  @Output() datasetSaved: EventEmitter<{ action: DatasetAction, id?: string }> = new EventEmitter();

  dataset: Dataset | CreateDataset;
  createdDataSetId: string
  // the backup dataset before editing. In datasource it's needed to get the preview data.
  backupDataset: Dataset;
  datasetWizard: DatasetWizard;
  previewData: any[];
  previewColumns: any[];
  file: File;
  checkRequired: boolean = false;

  config: StepperConfig;
  saveEnabled = false;

  progress: any = {};
  showResult = false;

  success = true;
  errorMsg;

  cancelText = 'Cancel';

  private statuses: NewAnalysisStepStatus[];

  constructor(
    public dialogRef: MatDialogRef<NewDatasetWizardComponent>,
    private catalogService: CatalogService,
    protected datasetService: DatasetService,
    private messageService: InternalMessageService,
    private router: Router,
    private translationService: TranslationService,

    @Inject(MAT_DIALOG_DATA) public data: {dataset: Dataset}
  ) {
    // don't close dialog when the outside is clicked
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {



    this.cancelText = this.translationService.translate("wizard.label.cancel");
    this.messageService.sendMessage('integratedHelp', 'discover/process-analysis/wizard');

    if (this.data.dataset) {
      this.initDataset(this.data.dataset);
    } else {
      this.initializeForm();
    }

    // this.progress = {
    //   message1: "Your dataset will take a few minutes to be created. ",
    //   message2: "You can stick around and wait or come back later to see it in the Datasets table.",
    //   percentage: 50,
    //   enabled: true
    // }

    // this.showResult = true;

  }

  private initDataset(dataset: Dataset) {
    this.backupDataset = cloneDeep(dataset);
    this.initializeForm(dataset);
  }

  private getStepperConfiguration = (): void => {
    let last = this.translationService.translate("wizard.label.confirmation")
    if (this.datasetWizard.editMode) {
      last = this.translationService.translate("wizard.label.summary")
    }
    const stepConfig = {
      steps: [
        {
          slot: 'dataset-basic-info',
          name: '1',
          label: this.translationService.translate("wizard.label.step1"),
          available: true
        },
        {
          slot: 'dataset-datasource',
          name: '2',
          label: this.translationService.translate("wizard.label.step2"),
        },
        {
          slot: 'dataset-attributes',
          name: '3',
          label: this.translationService.translate("wizard.label.step3"),
        },
        {
          slot: 'dataset-dates',
          name: '4',
          label: this.translationService.translate("wizard.label.step4"),
        },
        {
          slot: 'dataset-confirmation',
          name: '5',
          label: last
        }
      ],
      currentStepIdx: 0
    };
    if (this.data.dataset?.id) {
      // make all steps available
      stepConfig.steps.forEach(step => step.available = true);
    }
    this.config = {...stepConfig};
    this.initStatuses();
    if (this.data.dataset?.id) {
      // make all steps complete
      this.statuses.forEach(status => status.completed = true);
    }
  }

  public stopEdit($event) {
    const name = $event;
    let start = false;
    let i = 0;
    while (i < this.config.steps.length) {
      if (start) {
        this.config.steps[i].available = false;
      } else {
        start = this.config.steps[i].slot === name;
      }
      i++;
    }

    start = false;
    i = 0;
    while (i < this.statuses.length) {
      if (start) {
        this.statuses[i].completed = false;
      } else {
        start = this.statuses[i].step === name;
      }
      i++;
    }

    // disable the "Save" button and force the user to go through all steps
    // this.saveEnabled = false;
  }

  private initStatuses = (): void => {
    this.statuses = [
      {step: 'dataset-basic-info', completed: false},
      {step: 'dataset-datasource', completed: false},
      // {step: 'dataset-parse', completed: false},
      {step: 'dataset-attributes', completed: false},
      {step: 'dataset-dates', completed: false},
      {step: 'dataset-confirmation', completed: false}
    ];
  }

  public onCancel = (): void => {
    this.dialogRef.close();
    if (this.progress.enabled) {
      this.datasetSaved.emit({action: 'CANCEL'});
    }
  }

  checkInfoToChange() {
    if(this.statuses.filter(status => status.step === this.config.steps[this.config.currentStepIdx].slot)[0].completed == false){
      this.checkRequired = true;
    }else{
      this.checkRequired = false;
      this.changeTab(1);
    }
  }

  public changeTab = (delta: number): void => {
    const newSteptStep = this.config.currentStepIdx + delta;
    this.config.steps[newSteptStep].available = true;
    this.config = {...this.config, currentStepIdx: newSteptStep};
    // clear preview data on first tab
    // if (this.config.currentStepIdx === 0) {
    //   this.previewData = undefined;
    // }
  }

  public createUpdateDataset = (): void => {
    this.cancelText = this.translationService.translate("wizard.label.close")
    let onlyEditDataset = true;
    // tdv needs to be created/updated and preview needs to be started
    // 1. create new dataset
    // 2. edit dataset and schema changes
    // 3. edit dataset and upload a new file
    // 4. change the data source
    if (this.file ||
      (this.backupDataset && !isEqual(this.dataset.schema, this.backupDataset.schema)) ||
      !this.datasetWizard.editMode ||
      this.datasetWizard.dataSourceChanged) {
      onlyEditDataset = false;
    }

    this.progress = {
      message1: this.datasetWizard.editMode ? this.translationService.translate("wizard.label.message.updated") : this.translationService.translate("wizard.label.message.created"),
      message2: this.translationService.translate("wizard.label.message2"),
      percentage: 0,
      enabled: !onlyEditDataset
    }

    if (onlyEditDataset) {
      // only save dataset
      const dataset = this.dataset as Dataset;
      const updateDataset: UpdateDataset = {
        name: dataset.name,
        description: dataset.description,
        // schema: dataset.schema,
        // connectionId: dataset?.connection?.id,
        // configuration: dataset.configuration,
        id: dataset.id
      };
      this.catalogService.updateDataset([updateDataset]).subscribe(() => {
        this.dialogRef.close();
        this.datasetSaved.emit({action: 'SAVED', id: updateDataset.id});
      });

    } else {
      const datasetType = this.datasetWizard.dataSourceType.type;
      if (datasetType === 'file' || datasetType === 'xes' || datasetType === 'xlsx') {
        this.datasetService.saveDatasetAndUploadFileAndPreview(this.dataset as CreateDataset, this.progress, this.file)
        .subscribe(resp => {
          const datasetId = resp.id;
          const datasetStatus: DatasetStatus = resp.datasetStatus;
          if (datasetStatus.status === DatasetMetadata.StatusEnum.Ready) {
            // Set the dataset back so we can read the ID
            this.createdDataSetId = datasetId
            this.success = true;
            this.progress.status = 'Success';
            this.progress.percentage = 100;
          } else if (datasetStatus.status === 'Error') {
            this.success = false;
            this.progress.status = DatasetMetadata.StatusEnum.Ready;
            this.errorMsg = datasetStatus.description;
          }
          setTimeout(() => {
            this.progress.enabled = false;
            this.showResult = true;
            this.datasetSaved.emit({action: 'SAVED', id: datasetId});
          }, 2000);
        }, error => {
          this.onCreateError(error);
        });
      }else if (datasetType  === 'tcm' ) {
        this.datasetService.saveTcmDataset(this.dataset as CreateDataset).subscribe(resp => {
          const datasetId = resp;
          this.createdDataSetId = datasetId;
          this.success = true;
          this.progress.status = 'Success';
          this.progress.percentage = 100;
          this.progress.description = 'Your dataset is created. You can close this dialog and come back later to the Datasets table to pause the dataset to generate the snapshot.';

          setTimeout(() => {
            this.progress.enabled = false;
            this.showResult = true;
            this.datasetSaved.emit({action: 'SAVED', id: datasetId});
          }, 2000);
        }, error => {
          this.onCreateError(error);
        })
      }
      else if (datasetType === 'dv') {
        // todo: 
        console.log('save TDV dataset');
      }
    }
  }

  private onCreateError(error) {
    console.warn('save dataset error', error)
    this.progress.status = 'Fail';
    setTimeout(() => {
      let message: string;
      switch (error.from) {
        case 'triggerSnapshotError': {
          message = 'Failed to trigger snapshot after creating dataset'
          break;
        }
        case 'uploadCSVError': {
          message = 'Failed to upload file after creating dataset';
          break;
        }
        default: {
          message = 'Unknown error saving dataset'
        }
      }
      if (error.from === '') {}
      notifyUser('ERROR', message, this.messageService);
      this.datasetSaved.emit({action: 'ERROR'});
      this.dialogRef.close();
    }, 2000);
  }

  private initializeForm(dataset?: Dataset | CreateDataset): void {
    this.datasetWizard = {
      numberRowsForPreview: 15,
      editMode: false,
      connectionAddtionalFields: {}
    } as DatasetWizard;

    if (dataset) {
      this.dataset = dataset;
      this.datasetWizard.editMode = true;
    } else {
      this.dataset = {
        name: '',
        configuration: {
          encoding: 'UTF-8',
          escapeChar: '\\',
          header: true,
          quoteChar: '"',
          delimiter: ','
        },
        schema: []
      } as CreateDataset;
    }

    this.getStepperConfiguration();
  }

  public assembleCsvConfig() {
    const fileConfig = this.dataset.configuration as FileDatasetConfiguration;
    const config = {
      quoteChar: fileConfig.quoteChar,
      escapeChar: fileConfig.escapeChar,
      preview: this.datasetWizard.numberRowsForPreview,
      encoding: fileConfig.encoding,
      comments: fileConfig.commentsChar,
      skipEmptyLines: true,
      download: true,
      delimiter: fileConfig.delimiter
    };
    return config;
  }

  get csvConfig() {
    return this.assembleCsvConfig.bind(this);
  }


  public hide = (element: string): boolean => {
    if (element === 'prev') {
      return this.config.currentStepIdx === 0;
    }
    if (element === 'next') {
      return this.config.currentStepIdx === 4;
    }
    if (element === 'finish') {
      return this.config.currentStepIdx !== 4;
    }
    if (element === 'save') {
      return false;
    }
  }

  public display = (element: string): boolean => {
    if (element === 'dataset-basic-info') {
      return this.config.currentStepIdx === 0
    }
    if (element === 'dataset-datasource') {
      return this.config.currentStepIdx === 1
    }
    if (element === 'dataset-attributes') {
      return this.config.currentStepIdx === 2
    }
    if (element === 'dataset-dates') {
      return this.config.currentStepIdx === 3
    }
    if (element === 'dataset-confirmation') {
      return this.config.currentStepIdx === 4
    }
  }

  public handleStepClick = (step): void => {
    this.config.currentStepIdx = step.detail;
  }

  public handleStatus = ($event: NewAnalysisStepStatus): void => {
    const stepStatus = this.statuses.filter(status => status.step === $event.step)[0];
    stepStatus.completed = $event.completed;

    this.saveEnabled = !this.datasetWizard.editMode || this.file != null || !isEqual(this.dataset, this.backupDataset);
  }

  public handleType(type: string) {
    // If file type is changed, clear previewdata and loaded file
    if(this.datasetWizard.dataSourceType.type != type){
      this.previewData = undefined;
      this.file = undefined;
    }
  }

  public allStepCompleted() {
    return this.statuses.filter(status => !status.completed).length === 0;
  }

  public handleDisableNextButton = (): boolean => {
    return !this.statuses.filter(status => status.step === this.config.steps[this.config.currentStepIdx].slot)[0].completed;
  }

  public handleDisablePrevButton = (): boolean => {
    return false;
  }

  public handleSaveStatus($event: boolean) {
    this.saveEnabled = $event;
  }

  public handlePreviewData = (event): void => {
    if (event) {
      // clear the old data before setting the new data.
      this.resetPreviewData();
      if (event.jsonData) {
        try {
          const data = JSON.parse(event.jsonData);
          this.previewData = data;
        } catch (e) {
          console.error('Parse data from json string failed');
        }
      } else if (event.jsonDataArray) {
        try {
          const data = [];
          for (const jd of event.jsonDataArray) {
            data.push(JSON.parse(jd));
          }
          this.previewData = data;
        } catch (error) {
          console.error('Parse data from json string failed');
        }
      } else if (event.previewData) {
        this.previewData = event.previewData;
      } else if (event.preview) {
        this.previewData = calculateDatasetData(event.columns, event.preview);
      }

      this.datasetWizard.numberRowsForPreview = this.previewData.length;

      let columns = event.columns;
      if (!columns) {
        columns = this.getColumnsFromRowData(this.previewData[0]);
      }
      this.previewColumns = calculateDatasetColumns(columns);

      // todo
      if (this.previewColumns.length !== this.dataset.schema.length) {
        console.warn('The columns parsed from file are not same as the schema in dataset.');
      }

      // todo: if user is allowed to upload a different csv file, then here we need reset schemas
      if (!this.datasetWizard.editMode || this.datasetWizard.dataSourceChanged) {
        this.dataset.schema = this.calculateDatesetColumns(columns);
      }

      // if (event.columnSeparator) {
      //   (this.dataset.configuration as FileDatasetConfiguration).delimiter = event.columnSeparator;
      // }
    } else {
      this.previewData = undefined;
      this.previewColumns = undefined;
      this.dataset.schema = [];
    }
  }

  public onHighlightColumns($event) {
    if (this.previewColumns && $event) {
      this.previewColumns.forEach(col => {
        if ($event.indexOf(col.field) !== -1) {
          col.highlight = true;
        }
      })
    }
  }

  private getColumnsFromRowData(rowData: any): any[];
  private getColumnsFromRowData(rowData: string): any[] {
    try {
      let jsonRowData;
      if (typeof rowData === 'string') {
        jsonRowData = JSON.parse(rowData);
      } else {
        jsonRowData = rowData;
      }
      const cols = [];
      for (const col in jsonRowData) {
        if (col) {
          cols.push(col);
        }
      }
      return cols;
    } catch (error) {
      console.error('The data of csv is invalid JSON string');
      return [];
    }
  }

  private resetPreviewData() {
    this.previewData = null;
  }

  // datesetColumn is about the column name and its attributes type
  private calculateDatesetColumns = (columns: string[]): DatasetSchema[] => {
    return columns.map(column => {
      const newColumn = {
        label: column,
        type: 'string',
        format: 'None'
      } as DatasetSchema;
      return newColumn;
    })
  }

  public uploadFile = (event) => {
    this.file = event;
  }

  public selectConnection(event: ElementReference) {
    if (event) {
      if (this.datasetWizard.editMode) {
        (this.dataset as Dataset).connection = event;
      } else {
        (this.dataset as CreateDataset).connectionId = event.id;
      }

      if (this.datasetWizard.dataSourceType.type === Dataset.TypeEnum.Tcm) {
        this.dataset.configuration = {
          topic: this.datasetWizard.connectionAddtionalFields.topic
        } as TCMDatasetConfiguration
      }

      if (this.datasetWizard.dataSourceType.type === Dataset.TypeEnum.Dv) {
        this.dataset.configuration = {
          view: this.datasetWizard.connectionAddtionalFields.view,
          select: '*'
        } as DVDatasetConfiguration
      }
    }
  }

  public goToDatasets() {
    this.datasetSaved.emit({action: 'GO_DATASET', id: (this.dataset as Dataset).id});
    this.dialogRef.close();
  }

  public goToAnalysis() {
    this.datasetSaved.emit({action: 'CREATE_ANALYSIS', id: (this.dataset as Dataset).id});
    this.dialogRef.close();
    // Directly open the new analysis wizard with the right dataset id
    this.messageService.sendMessage('set-main-tab.topic.message', 'process-analysis');
    this.router.navigate(['/discover/new-analysis'], {queryParams: {initialDatasetId: this.createdDataSetId}});
  }

  goCreateConnection(ev: boolean) {
    if (ev) {
      this.datasetSaved.emit({action: 'CANCEL'});
      this.dialogRef.close();
    }
  }

  connectionSelected(_connS: UIConnection) {
    // todo: MS will provide an api for preview data of connection.
    /*
    this.catalogServiceMS.previewConnection(connS.id).subscribe(preview => {
      // console.log('Preview: ', preview)
      if (preview && preview.length > 0) {
        let cols;
        const dataLines = []
        let lineNumber = -1;
        preview.forEach(line => {
          lineNumber++;
          let addCols = false;
          const dataLine = []
          if (!cols) {
            cols = []
            addCols = true
          }
          for (const [key, value] of Object.entries(line)) {
            if (addCols) {
              cols.push(key)
            }
            dataLine.push(value)
            // console.log(`${key}: ${value}`);
          }
          dataLines.push(dataLine)
        })

        // console.log('Cols: ',  cols)
        // console.log('Data: ', dataLines)

        this.previewColumns = calculateDatasetColumns(cols)
        this.previewData = calculateDatasetData(cols, dataLines)
      }
    })*/
    // Set the data connection selection to done
    this.handleStatus({
      step: 'dataset-datasource',
      completed: true
    })
  }
}
