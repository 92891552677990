import {Component, OnInit} from '@angular/core';
import {
  ConfigurationService,
  GeneralInformationConfiguration,
  LandingPageConfiguration,
  VisualisationServer,
  VisualisationService
} from '@tibco/discover-client-lib';
import {set} from 'lodash-es';
import {MatDialog} from '@angular/material/dialog';
import {WelcomePreviewComponent} from 'src/app/components/admin/welcome-preview/welcome-preview.component';
import {HightlighEditComponent} from 'src/app/components/common/hightligh-edit/hightligh-edit.component';
import {map} from 'rxjs/operators';
import {InternalMessageService} from '../../service/internal-message.service';

@Component({
  selector: 'settings-branding',
  templateUrl: './settings-branding.component.html',
  styleUrls: ['./settings-branding.component.css', '../../components/process-analysis/process-analysis-table/process-analysis-table.component.scss']
})
export class SettingsBrandingComponent implements OnInit {

  public generalInformation: GeneralInformationConfiguration;
  public updatedGeneralInformation: boolean;

  public landingPage: LandingPageConfiguration;
  public updatedLandingPage: boolean;

  public analytics: VisualisationServer;
  public updatedAnalytics: boolean;

  public showAdvanced: boolean;
  public showServer: boolean;

  constructor(
    private configurationMS: ConfigurationService,
    private visusalisationMS: VisualisationService,
    private dialog: MatDialog,
    private messageService: InternalMessageService) {
  }

  ngOnInit(): void {
    this.updatedGeneralInformation = false;
    this.showAdvanced = false;
    this.showServer = false;
    this.handleReset();
  }

  public handleUpdate = (event, object: string, path?: string) => {
    if (object === 'general') {
      this.generalInformation.applicationTitle = event.detail.value;
      this.updatedGeneralInformation = true;
    }

    if (object === 'landingPage') {
      set(this.landingPage, path, event.detail.value);
      this.updatedLandingPage = true;
    }

    if (object === 'advanced') {
      set(this.analytics, path, event.detail.value);
      this.updatedAnalytics = true;
    }

  }

  public handleSave = async (): Promise<void> => {
    const calls = [];
    if (this.updatedGeneralInformation) {
      calls.push(this.configurationMS.postGeneralConfiguration(this.generalInformation).toPromise());
    }
    if (this.updatedLandingPage) {
      calls.push(this.configurationMS.postLandingPagesConfiguration(this.landingPage).toPromise());
    }
    if (this.updatedAnalytics) {
      calls.push(this.visusalisationMS.postVisualisationConfiguration(this.analytics).toPromise());
    }
    await Promise.all(calls);

    this.updatedGeneralInformation = false;
    this.updatedLandingPage = false;
    this.updatedAnalytics = false;

    this.messageService.sendMessage('news-banner.topic.message', 'Settings saved...');
  }

  public handleReset = (): void => {
    this.configurationMS.getGeneralConfiguration().pipe(
      map((result: GeneralInformationConfiguration) => {
        this.generalInformation = result;
        this.updatedGeneralInformation = false;
      })
    ).subscribe();
    this.configurationMS.getLandingPagesConfiguration().pipe(
      map((result: LandingPageConfiguration) => {
        this.landingPage = result;
        this.updatedLandingPage = false;
      })
    ).subscribe();
    this.visusalisationMS.getVisualisationConfiguration().subscribe((result: VisualisationServer) => {
      this.analytics = result;
      this.updatedAnalytics = false;
    });
  }

  public handlePreview = (): void => {
    const dialogRef = this.dialog.open(WelcomePreviewComponent, {
      width: '100%',
      height: '90%',
      data: this.landingPage
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  public handleHighlighEdit = (index) => {
    const dialogRef = this.dialog.open(HightlighEditComponent, {
      width: '60%',
      height: '50%',
      data: {...this.landingPage.highlights[index]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.landingPage.highlights[index] = result;
      }
    });
  }

  public handleUpload = (_file: File): void => {
    // this.configurationService.postLandingPagesUploadConfiguration(file).subscribe(
    //   (response: LandingPageUploadResponse) => {
    //     this.landingPage.backgroundURL = response.path;
    //   },
    //   error => {
    //     console.log('error', error);
    //   }
    // );
  }
}
