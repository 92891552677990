import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Organization, UserInfoResponse } from 'src/app/models_ui/auth';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'admin-nav-bar',
  templateUrl: './admin-nav-bar.component.html',
  styleUrls: ['./admin-nav-bar.component.scss']
})
export class AdminNavBarComponent implements OnInit {

  langChangeSubscription: Subscription;

  userinfo: UserInfoResponse;
  userRole: string;
  userDisplayName: string = '';
  orgInfo: Organization = {} as Organization;
  orgs: Organization[] = [];
  menuObj = { id: 'administration', label: 'administration' };
  stasOrgName: string
  stasOrgDescription:string
  messageTooltip: string;
  public tabs;
  search: string = '';
  orgName: string;
  orgDescription:string;
  orgData:any;
  isLoading: boolean = false;
  selectedOrgId: string;
  searchPlaceholder: string;
  currentLanguage: string;
  supportUrl: string = '';
  constructor(
    private cookieService: CookieService,
    private authService: MsalService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef

  ) {
    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
      if (this.userinfo.firstName && this.userinfo.lastName) {
        this.userDisplayName =
          this.userinfo.firstName +
          ' ' +
          this.userinfo.lastName.substring(0, 1);
      }
    }
    this.tabs = this.generateMenu();
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.translationService.currentLang$.subscribe(() => {
      this.searchPlaceholder = this.translationService.translate('administration.label.search');
      this.cdr.markForCheck(); 
    });
    this.currentLanguage = this.translationService.getCurrentLanguage();
    this.translationService.currentLang$.subscribe(lang => {
      this.currentLanguage = getLanguageCode(lang);
    });
    this.langChangeSubscription = this.translationService.currentLang$.subscribe(() => {
      this.searchPlaceholder = this.translationService.translate('administration.label.search');
      this.cdr.markForCheck(); 
    });
  }
  logout() {
    this.authService.logoutRedirect();
  }
  public generateMenu = (): any[] => {
    let tabs = [];
    return tabs;
  };
}
  
function getLanguageCode(lang) {
  const languagePart = lang.split('-')[0];
    return languagePart.toUpperCase();
}