import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {

  constructor() {
  }

  newItemStorage: { [key: string]: string[] } = {};

  private doShowFirstTime = true

  setNewItems(id: string, newI: string[]) {
    this.newItemStorage[id] = [...newI]
  }

  setDontShowAgain() {
    for (const [newId, _newItems] of Object.entries(this.newItemStorage)) {
      localStorage.setItem('newMessage.'+newId, 'DONE')
      console.log('Storage: ', localStorage.getItem('newMessage.'+newId))
    }
  }

  getShowItems(): string[] {
    // Returns all the new Items to Show
    const re = []
    for (const [newId, newItems] of Object.entries(this.newItemStorage)) {
      if (!(localStorage.getItem('newMessage.' + newId) === 'DONE')) {
        re.push(...newItems);
      }
    }
    return re;
  }

  doShow() {
    // Only show the popup once, when browsing
    const re = this.doShowFirstTime
    this.doShowFirstTime = false
    return re;
  }
}
