<div class="basic-info-container">
  <uxpl-form-field style="margin-top: 5px; margin-bottom: 5px;" id="analysis-name" [label]="placeholderName" type="text" [valid]="isValidN" required="true" [value]="name" float (uxplChanged)="handleUpdate($event, 'name')" [hint]="nameHint"></uxpl-form-field>
  <uxpl-form-field style="margin-top: 5px; margin-bottom: 5px;" id="analysis-description" maxlength="256" [label]="placeholderDescription" type="text" valid required="true" [value]="description" float (uxplChanged)="handleUpdate($event, 'description') "></uxpl-form-field>

  <!-- <uxpl-form-field *ngIf="newFolder" style="margin-top: 5px; margin-bottom: 5px;" id="analysis-folder" maxlength="256" label="Create a new folder" type="text" valid [value]="folder" float (uxplChanged)="handleUpdate($event, 'folder')"></uxpl-form-field> -->
  <!-- <uxpl-select-input *ngIf="folderReady == true && newFolder == false || folderReady == true && newFolder == undefined" style="margin-top: 5px; margin-bottom: 5px;" id="analysis-folder-select" class="flex-field flex-margin" select-caption="" label="Analysis folder" [value]="folder" [options]="folders" (uxplSelection)="handleUpdate($event, 'folder')"></uxpl-select-input> -->
  <!-- <div id="basic-info-switch">
    <span>Existing folder</span>
    <uxpl-switch-input [checked]="newFolder" (uxplChange)="changeFolder($event)"></uxpl-switch-input>
    <span>New folder</span>
  </div> -->
  <div id="analysis-folder">
    <uxpl-button *ngIf="folderReady" type="secondary" style="width: auto;" (click)="openSelectFolderDialog()">{{ 'basic.info.button.changeFolder' | translate }}</uxpl-button>
    <ul>
      <li>
        <span class="material-symbols-rounded folder-icon folder-closed">folder</span>
      </li>
      <li *ngFor="let folder of breadcrumbs">
        {{folder}}
      </li>
    </ul>
  </div>
</div>





