import {ActionDialogComponent} from '../action-dialog/action-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TButton} from '../../../models_ui/buttons';
import {Investigation, InvestigationApplication, InvestigationService} from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../../service/internal-message.service';


export class InvestigationAction {

  constructor(protected dialog: MatDialog,
              protected messageService: InternalMessageService,
              protected investigationMS: InvestigationService) {
  }

  protected _cConfig: InvestigationApplication

  setCConfig(cCon: InvestigationApplication) {
    this._cConfig = cCon;
  }

  // Method for one action
  public oneCommonAction(actionId: string, caseReference: string, formData: string): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ActionDialogComponent, {
        width: '70%',
        height: '70%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'tcs-style-dialog',
        data: {
          actionId,
          caseReference,
          appId: this._cConfig.id,
          formData
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.detail.eventName === 'FORM_SUBMIT') {
            this.messageService.sendMessage('news-banner.topic.message', result.detail.actionName + ' successful...');
          }
          resolve(true)
        }
      });
    })
  }

  public getStateColor(state) {
    const investigationState = this._cConfig.states.filter(el => el.name === state)[0];
    let color = ''
    if (investigationState) {
      color = investigationState.color
    } else {
      console.error('No color found for state: ', state)
    }
    return color ? color : '#f4f4f4';
  }

  public getStateIcon(state) {
    const investigationState = this._cConfig.states.filter(el => el.name === state)[0];
    let icon = ''
    if (investigationState) {
      icon = investigationState.icon
    } else {
      console.error('No Icon found for state: ', state)
    }
    return icon ? icon : 'assets/images/states' + state + '.svg';
  }

  // Function to set the action buttons
  async getActionButtons(investigationDetail: Investigation): Promise<TButton[]> {
    if (investigationDetail?.id && investigationDetail?.data && this._cConfig?.id) {
      // Convert the 'object' to any (so values can be accessed)
      const investigationData = investigationDetail.data as any;
      const actions = await this.investigationMS.getActionsForInvestigationId(this._cConfig.id, investigationDetail.id, investigationData.state).toPromise()
      return actions.map((action) => {
        return {
          id: action.id,
          label: action.label,
          formData: action.formData,
          type: 'ACTION'
        } as TButton;
      });
    }
  }


}
