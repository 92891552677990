<div class="datasource-section">
  <dataset-parse *ngIf="wizard.dataSourceType.type == 'file'" [data]="data" [backupDataset]="backupDataset"
    [wizard]="wizard"
    [previewColumns]="previewColumns" [previewData]="previewData" [file]="file" [fileType]="fileType"
    (uploadedFile)="uploadFile($event)" (highlightColumns)="onHighlightColumns($event)"
    (handlePreviewData)="handlePreviewData.emit($event)" (stopEdit)="stopEdit.emit($event)"
    (csvValid)="onCsvStatus($event)"></dataset-parse>
  <dataset-parse *ngIf="wizard.dataSourceType.type == 'xes'" [data]="data" [backupDataset]="backupDataset"
    [wizard]="wizard"
    [previewColumns]="previewColumns" [previewData]="previewData" [file]="file" [fileType]="fileType"
    (uploadedFile)="uploadFile($event)" (highlightColumns)="onHighlightColumns($event)"
    (handlePreviewData)="handlePreviewData.emit($event)" (stopEdit)="stopEdit.emit($event)"
    (csvValid)="onXesStatus($event)"></dataset-parse>
  <dataset-parse *ngIf="wizard.dataSourceType.type == 'xlsx'" [data]="data" [backupDataset]="backupDataset"
    [wizard]="wizard"
    [previewColumns]="previewColumns" [previewData]="previewData" [file]="file" [fileType]="fileType"
    (uploadedFile)="uploadFile($event)" (highlightColumns)="onHighlightColumns($event)"
    (handlePreviewData)="handlePreviewData.emit($event)" (stopEdit)="stopEdit.emit($event)"
    (csvValid)="onXlsxStatus($event)"></dataset-parse>

  <dataset-connections *ngIf="wizard.dataSourceType.type == 'tcm' || wizard.dataSourceType.type == 'dv'"
                       [dataset]="data" [previewColumns]="previewColumns" [previewData]="previewData" [wizard]="wizard"
                       (connectionValid)="onConnectionStatus($event)"
                       (handlePreviewData)="handlePreviewData.emit($event)"></dataset-connections>
</div>

