<h1 mat-dialog-title>
    {{ "manage.datasets.export.xes.dialog.title" | translate }}
</h1>
<form [formGroup]="mapping" (ngSubmit)="onSubmit()" class="padding-top-15">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div mat-dialog-content class="dialog-content">
      <h3>{{'manage.datasets.export.xes.dialog.title.fields.required' | translate}}</h3>
      <mat-form-field appearance="fill" *ngFor="let field of mappingFieldsRequired">
        <mat-label>{{field.fieldDescription}}</mat-label>
        <mat-select [formControlName]="field.fieldName">
          <ng-container *ngFor="let option of this.data.dataset.schema">
              <mat-option *ngIf="field.type.includes(option.type)" [value]="option.label" [disabled]="isOptionDisabled(option.label)">
                {{ option.label }}
              </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <h3>{{'manage.datasets.export.xes.dialog.title.fields.optional' | translate}}</h3>
      <mat-form-field appearance="fill" *ngFor="let field of mappingFields">
        <mat-label>{{field.fieldDescription}}</mat-label>
        <mat-select [formControlName]="field.fieldName">
          <mat-option>{{'empty' | translate}}</mat-option>
          <ng-container *ngFor="let option of this.data.dataset.schema">
              <mat-option *ngIf="field.type.includes(option.type)" [value]="option.label" [disabled]="isOptionDisabled(option.label)">
                {{ option.label }}
              </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
  </div>
  <div mat-dialog-actions class="justify-content-flex-end">
      <button mat-button mat-dialog-close>{{ "close" | translate }}</button>
      <button mat-button color="primary" type="submit" [disabled]="!mapping.valid">{{ "export" | translate }}</button>
  </div>
</form>
