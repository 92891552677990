import {Injectable} from '@angular/core';
import {CatalogService, Dataset, RepositoryService} from '@tibco/discover-client-lib';

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {

  constructor(private catalogService: CatalogService,
              private repositoryMS: RepositoryService) {
  }

  // Getting the 1 specific dataset that was used to create the process analysis
  public async getDatasetForProcessAnalysisId(processAnalysisId: string): Promise<Dataset> {
    const pa = await this.repositoryMS.getAnalysisDetails(processAnalysisId).toPromise()
    const dataset = await this.catalogService.getDataset(pa.dataset.id).toPromise()
    return dataset
  }

}
