import { Component, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/models_ui/userinfo';
import { OauthService } from 'src/app/service/oauth.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {


  constructor(private oauthService: OauthService) {
  }

  userinfo: UserInfo;

  async ngOnInit(): Promise<void> {
    try {
      this.userinfo = await this.oauthService.getUserInfo().toPromise();
    } catch(error) {
      console.warn('Unable to retrieve userinfo from oauth userinfo endpoint: ', error);
      this.userinfo = {} as UserInfo;
    }
  }

}
