import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss'],
})
export class RemoveUserDialogComponent {
  @Output() userDeleted = new EventEmitter<string>(); // Define event emitter
  roles: string[];
  selectedRole: string;
  userId: string;
  confirmationInput: string = ''; // Variable to store user input
  @ViewChild('confirmationInputField') confirmationInputField: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<RemoveUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { userId: string; orgId: string; superadmin: boolean },
    private userService: UsersService,
    private snackBarService: SnackbarService,
    private translationService: TranslationService,

  ) {
    this.userId = data.userId;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    if (this.data.superadmin) {
      this.userService
        .deleteUserByOrgId(this.userId, this.data.orgId)
        .subscribe(
          () => {
            this.snackBarService.openSnackBar(this.translationService.translate('remove.user.snackBar.deleted'), this.translationService.translate('remove.user.snackBar.close'),'success');
            // Emit event with the ID of the deleted user
            this.userDeleted.emit(this.userId);
          },
          (error) => {
            this.snackBarService.openSnackBar(
              this.translationService.translate('remove.user.snackBar.error'),
              this.translationService.translate('remove.user.snackBar.close'),'error'
            );
            console.error('Error deleting user:', error);
            // Handle error
          }
        );
      this.dialogRef.close();
    } else {
      this.userService.deleteUser(this.userId).subscribe(
        () => {
          this.snackBarService.openSnackBar(this.translationService.translate('remove.user.snackBar.deleted'), 'Close','success');
          // Emit event with the ID of the deleted user
          this.userDeleted.emit(this.userId);
        },
        (error) => {
          this.snackBarService.openSnackBar(
            this.translationService.translate('remove.user.snackBar.error'),
            this.translationService.translate('remove.user.snackBar.close'),'error'
          );
          console.error('Error deleting user:', error);
          // Handle error
        }
      );
      this.dialogRef.close();
    }
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.confirmationInput === 'DELETE') {
        this.onDeleteClick();
      }
    }
  }
}
