<div id="welcome" class="start-page">
  <div id="start-page-wrap" class="width-xs-9 width-sm-5 width-md-3 width-lg-3 width-xl-2">
    <!-- <div id="top-block">
      <h2>Welcome <small>to</small></h2>
    </div> -->
    <mat-card>
      <mat-card-content>
        <pulse-loading name="true" tagLine="true"></pulse-loading>
        <button mat-flat-button color="primary" routerLink="/select-organization" *ngIf="loginDisplay">Start</button>
        <button mat-flat-button color="primary" routerLink="/select-organization" *ngIf="!loginDisplay">Login</button>
      </mat-card-content>
    </mat-card>
    <!-- <div id="bottom-block">
        <p>Pulse Minds is the Process Mining tool, capable of transforming complex data into intuitive visual representations.</p>
        <p>This is a crucial capability in Process Mining, where visualizing process flows is integral for analysis. By presenting data in an easily format, Pulse Minds allows you to understand and communicate the intricacies of your business processes efficiently.</p>
    </div> -->
  </div>
</div>