import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from 'src/app/models_ui/auth';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'org-edit',
  templateUrl: './org-edit.component.html',
  styleUrls: ['./org-edit.component.css'],
  host: {'class': 'flex-item'}
})
export class OrgEditComponent implements OnInit {

  isLoading: boolean = false;
  editOrgFormGroup: FormGroup;
  currentOrgId: string;
  currentOrg: Organization;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentOrgId = this.route.snapshot.paramMap.get('orgId');
    this.editOrgFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
    if(this.currentOrgId){
      this.isLoading = true;
      this.usersService.getOrg(this.currentOrgId).subscribe(
        (response) => {
          this.currentOrg = response;
          this.editOrgFormGroup.patchValue({
            name: this.currentOrg.displayName,
            description: this.currentOrg.description
          });
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.snackBarService.openSnackBar(this.translationService.translate('generic.message.api.error'), this.translationService.translate('close'),'error');
          console.error("GetOrg error: "+error);
        }
      );
    }
  }

  onSubmit(): void {
    this.isLoading = true;
    if (this.editOrgFormGroup.valid) {
      this.usersService.editOrg(this.currentOrg.id, this.editOrgFormGroup.value).subscribe(
        () => {
          this.snackBarService.openSnackBar(this.translationService.translate('modify.organization.snackBar.edited'), this.translationService.translate('modify.organization.snackBar.close'),'success');
          this.isLoading = false;
          this.router.navigate(['/administration/'+this.currentOrg.id+'/statistics']);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.openSnackBar(
            this.translationService.translate('modify.organization.snackBar.error'),
            this.translationService.translate('close'),
            'error'
          );
          console.error('EditOrg error: ', error);
          // Handle error
        }
      );
    }
  }

}
