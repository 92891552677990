<div id="parse-container" class="container-flex-fluid">
    <div class="flex">
        <div class="flex-item width-xs-10">
            <csv-upload [filename]="filename" [filetypes]="fileType" [filesize]="1000" [error]="csvError"
                    (uploadFile)="onUploadFile($event)" size="small"></csv-upload>
        </div>
        <div class="flex-item width-xs-10">
            <parse-csv [datasetConfig]="data.configuration" [numberRowsForPreview]="wizard.numberRowsForPreview" [file]="file"
                (handlePreviewData)="refreshCSVPreview($event)"
                (updateNumberRowsForPreview)="updateNumberRowsForPreview($event)" *ngIf="fileType == 'csv'"></parse-csv>
        </div>
        <div class="flex-item width-xs-10">
            <dataset-data-preview [previewColumns]="previewColumns" [previewData]="previewData" [wizard]="wizard" [dataSource]="file"></dataset-data-preview>
        </div>
    </div>
</div>
