import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CaseField} from '../../../models_ui/configuration';
import _ from 'lodash';
import {Router} from '@angular/router';
import {Investigation, InvestigationMetadata, InvestigationService} from '@tibco/discover-client-lib';
import {InvestigationAction} from '../common/InvestigationAction';
import {MatDialog} from '@angular/material/dialog';
import { InvestigationApplication } from '@tibco/discover-client-lib';
import {FilterMatchMode} from 'primeng/api';
import {InternalMessageService} from '../../../service/internal-message.service';
@Component({
  selector: 'investigation-table',
  templateUrl: './investigation-table.component.html',
  styleUrls: ['../../common/tibco-table.ux.css', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss', './investigation-table.component.scss']
})
export class InvestigationTableComponent extends InvestigationAction implements OnInit, OnChanges {

  constructor(
    private router: Router,
    protected dialog: MatDialog,
    protected messageService: InternalMessageService,
    protected investigationMS: InvestigationService,) {
    super(dialog, messageService, investigationMS)
  }

  @Input() loading: boolean;
  @Input() cConfig: InvestigationApplication;
  @Input() searchTerm: string;
  @Input() investigations: Investigation[];
  @Input() focusIds: string[];

  @Output() refreshEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('investigationTable', {static: true}) investigationTable;

  cols: any[];
  investigationTableData: any[]
  expandedRows = {};
  first = 0;
  showAdditionalSpinner: boolean;
  actions: any = {};

  readonly DETAIL_ID = 'detail'

  private static normalizeField(field: string) {
    let re = ''
    if (field) {
      re = field.replace(':', '_')
    }
    return re
  }

  ngOnInit() {
    this.setCConfig(this.cConfig)
    this.cols = this.cConfig.headerFields.map((el: CaseField) => {
      return {
        field: InvestigationTableComponent.normalizeField(el.field),
        header: el.label,
        format: el.format,
        originalField: el.field
      }
    });
    this.createTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.first = 0;
    if (changes.searchTerm && !changes.searchTerm?.firstChange) {
      this.investigationTable.filterGlobal(this.searchTerm, FilterMatchMode.CONTAINS);
    }
    if (this.searchTerm === '' && this.investigationTable) {
      // A hack to reset the filter, since reset does not seem to do anything
      this.investigationTable.filterGlobal('***$$$', FilterMatchMode.NOT_EQUALS)
    }
    if (changes.investigations) {
      if (this.investigations) {
        this.createTableData()
      }
    }
  }

  public onRowClick(rowData) {
    if (rowData.id) {
      this.openDetails(rowData.id)
    }
  }

  private openDetails(id: string) {
    if (this.cConfig.id && id) {
      this.router.navigate(['/discover/investigation-details/' + this.cConfig.id + '/' + id]);
    }
  }

  private createTableData() {
    if (this.investigations && this.cols) {
      this.investigationTableData = [];
      this.investigations.forEach(inv => {
        const norInv = {id: inv.id};
        this.cols.forEach(col => {
          const data = this.obtainData(inv, col)
          norInv[col.field] = data.value;
          norInv[col.field + '_format'] = data.format
        })
        // Initialize an empty object
        this.actions[inv.id] = {}
        this.investigationTableData.push(norInv)
        if (this.focusIds && this.focusIds.length > 0) {
          this.focusIds.forEach(inF => {
            // move to the top
            const paIx = this.investigationTableData.findIndex(pa => pa.id === inF)
            this.investigationTableData.unshift(this.investigationTableData.splice(paIx, 1)[0]);
            this.expandRowById(inF, true)
          })
        }
      })
    }
  }

  public expandRowById(id: string, value: boolean) {
    const inT = this.investigationTableData.find(v => v.id === id)
    if (inT && inT.id) {
      this.expandedRows[inT.id] = value;
    }
  }

  private obtainData(invDet: Investigation, column: any): { value: string, format: string } {
    let col = column.originalField;
    let value: string;
    if (col.indexOf('META:') === -1) {
      value = _.get(invDet.data, col);
    } else {
      col = col.substring(col.indexOf(':') + 1);
      value = invDet.metadata.filter((el: InvestigationMetadata) => el.name === col)[0].value;
    }
    return {value, format: column.format};
  }

  getInvestigationById(id: string) {
    return this.investigations.find(inv => inv.id === id)
  }

  async handleOptionClick(optionId: string) {
    if (this.actions[optionId]) {
      const investigation = this.getInvestigationById(optionId)
      // Convert the 'object' to any (so values can be accessed)
      const investigationData = investigation.data as any;
      if (investigationData.state) {
        const actions = await this.investigationMS.getActionsForInvestigationId(this.cConfig.id, optionId, investigationData.state).toPromise()
        this.actions[optionId] = {
          options: [{
            id: this.DETAIL_ID,
            invId: optionId,
            label: 'View details'
          },'Divider', ...actions.map(act => {
            return {
              id: act.id,
              label: act.label,
              data: {
                actionId: act.id,
                investigationId: optionId,
                applicationId: this.cConfig.applicationId,
                name: act.label,
                formData: act.formData,
              },
            }
          })]
        }
        console.log('actions ', JSON.stringify(this.actions[optionId]))
      }
    }
  }

  handleInvestigationActionSelect(ev: any) {
    if (ev?.detail) {
      const action = ev.detail;
      if (action.id === this.DETAIL_ID) {
          this.openDetails(action.invId)
      } else {
        if (action.data) {
          const data = action.data
          this.oneCommonAction(data.actionId, data.investigationId, data.formData).then(async (_result) => {
            // Refresh a little later till the case is updated
            setTimeout(() => {
              this.refreshEvent.emit(this.cConfig.id)
            }, 500)
          })
        }
      }
    }
  }
}
