<nav id="nav-administration">
    <ul #primaryNav>
      <li>
        <a id="logo" routerLink="/welcome"><img src="../../../assets/images/png/logo-pulseminds-horizontal-black.png" /></a>
      </li>
      <li *ngFor="let tab of tabs">
        <a (click)="handleClick(tab.id)" [class]="!analysisID && tab.id == 'analytics' ? 'disabled' : ''" [routerLink]="analysisID && tab.id == 'analytics' ? '/discover/' + tab.id + '/' + analysisID : '/discover/' + tab.id"routerLinkActive="active">{{ tab.label }}</a>
      </li>
    </ul>
    <ul #logoutNav>
      <app-language-selector></app-language-selector>
      <li>
      <li>
        <a id="user-action" [matMenuTriggerFor]="userMenu">
          <div>
            <span>{{ userDisplayName }}</span>
            <i *ngIf="orgInfo && orgInfo.displayName">{{orgInfo.displayName}}</i>
          </div>
          <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
        </a>
        <mat-menu #userMenu="matMenu" xPosition="before">
          <button mat-menu-item routerLink="/select-organization">
            <mat-icon class="material-symbols-rounded">corporate_fare</mat-icon>
            <span>{{ 'administration.menu.selectOrg' | translate }}</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon class="material-symbols-rounded">logout</mat-icon>
            <span>{{ 'discover.menu.logout' | translate }}</span>
          </button>
        </mat-menu>
      </li>
    </ul>
  </nav>