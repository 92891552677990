import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UxplLeftNav} from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {Investigation, InvestigationApplication, InvestigationService} from '@tibco/discover-client-lib';

@Component({
  selector: 'app-cases',
  templateUrl: './manage-investigations.component.html',
  styleUrls: ['./manage-investigations.component.css']
})
export class ManageInvestigationsComponent implements OnInit, AfterViewInit {

  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNav>;

  caseConfig: InvestigationApplication[];
  // noDataIconLocation: string;
  hasCaseConfig: boolean;
  leftNavTabs = [];
  hideTable = new Array<boolean>();
  investigations: Investigation[][];

  invFocusApp: string;
  invFocus: { [key: string]: string[] } = {};

  constructor(
    private route: ActivatedRoute,
    private investigationMS: InvestigationService
  ) {
  }

  ngAfterViewInit(): void {
    this.setLeftMenu()
  }

  async ngOnInit(): Promise<void> {
    this.caseConfig = await this.investigationMS.getInvestigationApplications().toPromise();
    this.hasCaseConfig = false;
    if (this.caseConfig && this.caseConfig.length > 0) {
      // Create left menu
      this.hasCaseConfig = true;
      this.leftNavTabs = [];
      for (const conf of this.caseConfig) {
        const lTab = {
          id: conf.id,
          label: conf.customTitle,
          // "icon": conf.iconLocation,
          child: []
        }
        this.hideTable.push(true);
        this.leftNavTabs.push(lTab);
      }
      // Run this on the next cycle so the menu is visible
      setTimeout(() => this.setLeftMenu(), 100)
      this.hideTable[0] = false;
      // Get focus vars
      this.route.queryParams.pipe(take(1)).subscribe(params => {
        if (params.focusApp && params.focus) {
          this.invFocusApp = params.focusApp
          this.invFocus[this.invFocusApp] = params.focus.split(',');
        }
      });

      // Get manage-investigations for each application
      this.investigations = await Promise.all(
        this.caseConfig.map((element: InvestigationApplication) =>
          this.investigationMS.getInvestigations(element.id).toPromise()
        )
      );
    }
  }

  handleClick(event: any) {
    // Show table of selected appId
    if (event.detail) {
      for (let i = 0; i < this.caseConfig.length; i++) {
        if (event.detail.id === this.caseConfig[i].id) {
          this.hideTable[i] = false;
        } else {
          this.hideTable[i] = true;
        }
      }
    }
  }

  private setLeftMenu() {
    if (this.leftNavTabs.length > 0 && this.leftNav) {
      if (this.invFocusApp) {
        this.leftNav.nativeElement.setTab(this.leftNavTabs.find(v => v.id === this.invFocusApp));
      } else {
        this.leftNav.nativeElement.setTab(this.leftNavTabs[0]);
      }
    }
  }

  public refreshEvent = async (applicationId: string, index: number): Promise<void> => {
    // console.log('ApplicationID: ', applicationId, ' Index: ', index);
    this.investigations[index] = await this.investigationMS.getInvestigations(applicationId).toPromise()
  }
}
