<div class="language-selector-wrapper">
  <div class="language-select">
    <a [matMenuTriggerFor]="langMenu" class="language-trigger">
      <img [src]="'../../../assets/png/flags/'+currentLangCode+'.png'"/>
    </a>
    <mat-menu #langMenu="matMenu" xPosition="before">
      <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang.code)">
        <img [src]="'../../../assets/png/flags/'+lang.code+'.png'" style="height: 26px;margin-right: 10px;"/>
        {{lang.name}}
      </button>
    </mat-menu>
  </div>
</div>
