<div class="new-analysis-container container-flex-fluid" *ngIf="config">
  <div class="flex">
    <div class="flex-item width-xs-10">
      <uxpl-object-header [config]="objHeaderConfig" (back)="goProcessAnalsysis()">
        <div *ngIf="!showResult" class="button-container" slot="navigation-content">
          <button mat-stroked-button color="primary" (click)="goProcessAnalsysis()">
            {{cancelText}}
          </button>
          <button mat-stroked-button color="primary" *ngIf="!hide('prev')" (click)="changeTab(-1)">
            {{ 'new.analysis.button.back' | translate }}
          </button>
          <button mat-flat-button color="primary" *ngIf="!hide('next')"
                       [disabled]="handleDisableNextButton()" (click)="changeTab(1)">
            {{ 'new.analysis.button.next' | translate }}
          </button>
          <button mat-flat-button color="primary" *ngIf="!hide('finish')" [disabled]="disableSave"
                       (click)="createAnalysis()">
            {{ 'new.analysis.button.finish' | translate }}
          </button>
          <button mat-flat-button color="primary" *ngIf="!hide('save')" [disabled]="handleSaveButton()"
                       (click)="updateAnalysis(false)">
            {{ 'new.analysis.button.save' | translate }}
          </button>
          <button mat-flat-button color="primary" *ngIf="!hide('save')" [disabled]="handleSaveButton()"
                       (click)="updateAnalysis(true)">
            {{ 'new.analysis.button.saveRerun' | translate }}
          </button>
        </div>
        <div *ngIf="showResult" slot="navigation-content" class="button-container">
          <button mat-stroked-button color="primary" (click)="goToAnalysis()">{{ 'new.analysis.button.goToAnalysis' | translate }}</button>
          <button mat-stroked-button color="primary" *ngIf="newAnalysisId" (click)="goToTemplate()">{{ 'new.analysis.button.goToTemplate' | translate }}</button>
        </div>
      </uxpl-object-header>
    </div>
    <div class="flex-item width-xs-10">
      <uxpl-stepper [config]="config" (stepClickedIdx)="handleStepClick($event)" [hideNumbers]="mode !== 'create'">
        <div *ngIf="display('basic-info')" class="new-analysis-tab-container" slot="basic-info">
          <basic-info [name]="newAnalysis.name" [description]="newAnalysis.description" [folder]="newAnalysis.folder" [folders]="newAnalysis.folders" [analysisId]="analysisId"
                      (changed)="handleData($event)" (status)="handleStatus($event)"></basic-info>
        </div>
        <div *ngIf="display('dataset')" class="new-analysis-tab-container" slot="dataset">
          <!--      <select-dataset [availableDatasets]="['one', 'two']" [dataset]="newAnalysis.datasetId" (selectedDataset)="handleSelectedDataset($event)"-->
          <!--                      (status)="handleStatus($event)"></select-dataset>-->
          <select-dataset [availableDatasets]="availableDatasets$ | async" [dataset]="newAnalysis.datasetId"
                          [preview]="previewD" (selectedDataset)="handleSelectedDataset($event)"
                          [isDataPreviewError]="isDataPreviewError" (status)="handleStatus($event)"></select-dataset>
        </div>
        <div *ngIf="display('map')" class="new-analysis-tab-container" slot="map">
          <map style="overflow: hidden" [preview]="previewD" [selectedDataset]="newAnalysis.datasetId"
               [mapping]="newAnalysis.mappings" [advancedMode]="advancedMode" [doAutoMap]="doAutoMap"
               [hideAdvanced]="mode !== 'create'" (status)="handleStatus($event)" (advance)="toggleAdvanced()"></map>
        </div>
        <div *ngIf="display('dataPreview')" class="new-analysis-tab-container" slot="dataPreview">
          <!-- This get's injected by hiding and showing data-preview -->
        </div>
        <!--
        <div *ngIf="display('scheduling')" class="new-analysis-tab-container" slot="scheduling">
          <scheduler></scheduler>
        </div> -->
        <div *ngIf="display('confirmation')" class="new-analysis-tab-container" slot="confirmation">
          <!-- Test Data: TESTAnalysis | newAnalysis -->
          <confirmation [data]="newAnalysis"></confirmation>
        </div>
      </uxpl-stepper>
      <!-- Test Data: TESTAnalysis | newAnalysis -->
      <data-preview #dataPreview *ngIf="newAnalysis?.datasetId" [hidden]="!display('dataPreview')" class="data-preview"
                    [filters]="newAnalysis.filters" [groups]="newAnalysis.groups" [mapping]="newAnalysis.mappings"
                    [selectedDataset]="newAnalysis.datasetId" [selectedDatasetName]="datasetName"></data-preview>
      <!--<hr/>-->
      <!--  <p><strong>data</strong></p>-->
      <!--  <pre>{{newAnalysis|json}}</pre>-->
      <!-- <pre> Do Automap: {{doAutoMap}}</pre> -->
      <div *ngIf="progress.enabled" class="modal-backdrop">
        <progress-screen class="overlay-container" [message1]="progress.message1" [message2]="progress.message2"
                         [percentage]="progress.percentage" [status]="progress.status"></progress-screen>
      </div>
    
      <div *ngIf="showResult" class="modal-backdrop">
        <div class="overlay-container">
          <div>
            <img *ngIf="success" width="208" height="160" src="assets/images/svg/success-image.svg">
            <img *ngIf="!success" width="211" height="203" src="assets/images/svg/error-image.svg">
          </div>
          <div *ngIf="success">
            <div class="result-text">{{ 'new.analysis.message.success' | translate }}</div>
            <div class="result-msg">{{ 'new.analysis.message.result' | translate }}
            </div>
          </div>
          <div *ngIf="!success">
            <div class="result-text error">{{ 'new.analysis.message.error' | translate }}</div>
            <div class="result-msg">{{errorMsg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
