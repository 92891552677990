import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  doCompare = new EventEmitter();

  // Refresh component
  private refreshComponent = new Subject<void>();
  get refreshObservable() {
    return this.refreshComponent.asObservable();
  }
  triggerRefresh() {
    this.refreshComponent.next();
  }
}
