import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SpotfireDocument, SpotfireViewerComponent} from '@tibcosoftware/spotfire-wrapper';
import {OauthService} from '../../../service/oauth.service';
import {UxplLeftNav} from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import {MenuConfigTemplate} from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../../service/internal-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'template-analytic-preview',
  templateUrl: './template-analytic-preview.component.html',
  styleUrls: ['./template-analytic-preview.component.scss']
})
export class TemplateAnalyticPreviewComponent implements OnInit, OnChanges {

  @Input() location: string;
  @Input() previewParameters: string;
  @Input() previewMenu: MenuConfigTemplate[];
  @Input() isFilterPreview: boolean;

  @Output() pageOptions: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() markingOptions: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() dataOptions: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('analysis', {static: false}) analysisRef: SpotfireViewerComponent;
  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNav>;

  markingOn = '*';
  spotfireServer;
  document: SpotfireDocument;
  analysisParameters: string;

  private defaultSet = false;

  constructor(
    private oService: OauthService,
    private messageService: InternalMessageService
  ) {
    this.messageService.getMessage('preview-dxp.process-analysis-id.message').subscribe(
      (message) => {
        // console.log('Got tab message: ', message)
        if (message.text && message.text !== '' && message.text !== 'init') {
          const previewId = message.text;
          if (this.document) {
            this.document.setDocumentProperty('AnalysisId', previewId);
          }
        }
      })
  }

  ngOnInit(): void {
    this.spotfireServer = environment.spotfireURL;;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.previewParameters && this.previewParameters) {
      this.analysisParameters = this.previewParameters.replace('@@OAUTH_TOKEN@@', this.oService.token);
    }
  }

  setDocument = (event): void => {
    this.document = event;
    this.document.onDocumentReady$().subscribe(_val => {
      // console.log('val: ' , _val);
      if (this.previewMenu && this.previewMenu.length > 0 && !this.defaultSet) {
        this.previewMenu.forEach(mItem => {
          if (mItem.isDefault) {
            this.analysisRef.page = mItem.view;
            this.analysisRef.openPage(mItem.view);
            setTimeout(() => {
              this.leftNav?.nativeElement?.setTab(mItem, true);
            }, 0);
            // Sometime the left tab is not selected, this code ensures it is
            setTimeout(() => {
              this.leftNav?.nativeElement?.setTab(mItem, true);
            }, 100);
          }
        })
        this.defaultSet = true;
      }
    });
    this.document.getPages$().subscribe((pageOptions) => {
      this.pageOptions.emit(pageOptions);
    })
    this.document.getMarking().getMarkingNames$().subscribe((markingOptions) => {
      this.markingOptions.emit(markingOptions);
    });
    this.document.getData().getTables$().subscribe((data) => {
      this.dataOptions.emit(data);
    })
    this.document.getDocumentMetadata$().subscribe(() => {
    })
  }

  handleClick = (event: any): void => {
    if (event?.detail?.view) {
      this.analysisRef.page = event.detail.view;
      this.analysisRef.openPage(event.detail.view);
    }
  };
}
