import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WhatsNewService} from "../../../service/whats-new.service";

@Component({
  selector: 'whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css']
})
export class WhatsNewComponent implements OnInit {

  newItems: string[]
  dontShowAgain = false;

  constructor(private whatsNewService: WhatsNewService,
              private dialogRef: MatDialogRef<WhatsNewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { newItems: string[] }) {
    this.newItems = data.newItems
  }

  ngOnInit(): void {
  }

  okToClose() {
    if(this.dontShowAgain){
      this.whatsNewService.setDontShowAgain()
    }
    this.dialogRef.close();
  }
}
