<div class="page-container container-flex-fluid">
  <div class="flex">
    <div class="flex-item">
      <div class="data-title-container">
        <div class="data-title">
          <div class="page-title">{{ 'manage.templates.title' | translate }}</div>
            <uxpl-form-field class="dataset-search-widget" type="text" consise valid [label]="placeholderSearch" (uxplChanged)="handleSearch($event);">
              <uxpl-icon class="dataset-search-icon" slot="selectionIndicator" icon="pl-icon-search" size="24px"></uxpl-icon>
            </uxpl-form-field>
          </div>
          <div class="data-title">
            <div class="data-title">
              <button mat-stroked-button color="primary" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
                {{ 'manage.templates.button.refresh' | translate }}
              </button>
            </div>
          </div>
      </div>
    </div>
    <div class="flex-item">
      <list-templates [templates]="templates" mode="copy" [search]="search" (newTemplate)="goTemplate()" (selected)="templateAction($event)"></list-templates>
    </div>
  </div>
</div>
