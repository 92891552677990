import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-change-role-dialog',
  templateUrl: './change-role-dialog.component.html',
  styleUrls: ['./change-role-dialog.component.scss'],
})
export class ChangeRoleDialogComponent implements OnInit {
  @Output() roleUpdated = new EventEmitter<string>(); // Define event emitter
  roles: string[];
  currentRole: string;
  selectedRole: string;
  userId: string;
  currentLanguage: string;
  constructor(
    public dialogRef: MatDialogRef<ChangeRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentRole: string;
      roles: string[];
      userId: string;
      orgId?: string;
      superadmin?: boolean;
    },
    private userService: UsersService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,


  ) {
    this.roles = data.roles;
    this.userId = data.userId;
    this.currentRole = data.currentRole;
    console.log(this.currentRole);
    console.log(this.roles);
  }
  ngOnInit(): void {
    this.selectedRole = this.currentRole;
    this.currentLanguage = this.translationService.getCurrentLanguage();
    this.translationService.currentLang$.subscribe(lang => {
      this.currentLanguage = lang;

    });
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    //if i'm a superadmin, do the editUserByOrgId else editUser
    if (this.data.superadmin) {
      this.userService
        .editUserByOrgId(this.userId, this.data.orgId, this.selectedRole)
        .subscribe(
          () => {
            // Emit event with the new role when saved
            this.roleUpdated.emit(this.selectedRole);
            this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.success'), this.translationService.translate('create.org.snackBar.close'),'success');

          },
          (error) => {
            console.error('Error changing role:', error);
            this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error') + error, this.translationService.translate('create.org.snackBar.close'),'error');

            // Handle error
          }
        );
      this.dialogRef.close(this.selectedRole);
    } else {
      this.userService.editUser(this.userId, this.selectedRole).subscribe(
        () => {
          // Emit event with the new role when saved
          this.roleUpdated.emit(this.selectedRole);
          this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.success'), this.translationService.translate('create.org.snackBar.close'),'success');

        },
        (error) => {
          this.snackBarService.openSnackBar(this.translationService.translate('change.role.snackbar.error') + error, this.translationService.translate('create.org.snackBar.close'),'error');

          console.error('Error changing role:', error);
          // Handle error
        }
      );
      this.dialogRef.close(this.selectedRole);
    }
  }
}
