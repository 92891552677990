<div class="table-container">
    <table mat-table
        [dataSource]="dataSource">

        <ng-container matColumnDef="activities">
            <th mat-header-cell
                *matHeaderCellDef>{{'process.analysis.model.upload.step.map.activities.loaded' | translate}}</th>
            <td mat-cell
                *matCellDef="let element"> {{element.activity}} </td>
        </ng-container>

        <ng-container matColumnDef="mapping">
            <th mat-header-cell
                *matHeaderCellDef>{{'process.analysis.model.upload.step.map.activities.available' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <mat-select [(value)]="element.mapLower" (selectionChange)="onSelectionChange(element.index, $event)">
                    <mat-option value="">{{'process.analysis.model.upload.step.map.not.mapped' | translate}}</mat-option>
                    <mat-option *ngFor="let activity of activities" [value]="activity.toLowerCase()">{{activity}}</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>