<process-analysis-moreinfo *ngIf="processAnalysis !== undefined" [display]="moreInfo" (onHide)="handleMoreInfoToggle()"
                           [selectedAnalysis]="processAnalysis"></process-analysis-moreinfo>
<uxpl-detail-pane
                  [topSectionLabel]="label"
                  [bottomSectionLabel]="processAnalysis.metadata['message'] ? undefined : keymetricsLabel"
                  [topSectionAttributes]="attributeDefs"
                  (uxplAttributeCopy)="handleDetailAttributeCopy($event?.detail)"
                  (uxplAttributeAction)="handleDetailAction($event)">
  <div slot="topSectionLabelContent" class="bottom-slot-content-container">
    <div class="slot-button" (click)="handleMoreInfoToggle()">{{ 'process.analysis.details.moreInfo' | translate }}</div>
  </div>

  <div slot="topSectionContent">
    <!--<div class="description-label">DESCRIPTION</div>
    <div class="description-detail">{{processAnalysis.data.description}}</div>-->
    <progress-screen *ngIf="progress" [message1]="progress?.message" [showImageLeft]="true"
                     [percentage]="progress?.percentage" [status]="progress?.status"></progress-screen>
    <div *ngIf="processAnalysis.metadata['message']" class="error-container">
      <img height=75 width=75 src="assets/svg/error-image-2.svg" onerror="this.style.display='none'">
      <div class="error-details-pane">
        <!--div class="error-details">
          <h3>Error Details:</h3>
          <uxpl-icon class="error-icon" height="15px" width="15px" icon="pl-icon-copy"
          matTooltip="copy error to clipboard" matTooltipClass="tTip"
          (click)="copyToClipBoard('Error',processAnalysis.metadata.message, msService)"></uxpl-icon>
        </div-->
        <error-details [errorMessage]="processAnalysis.metadata['message']"></error-details>
      </div>
    </div>
  </div>
  <div slot="bottomSectionLabelContent" class="bottom-slot-content-container">
    <div *ngIf="this.processAnalysis?.metrics"
         [ngClass]="{'slot-button': true, 'disabled': !this.processAnalysis?.metrics}" (click)="handleCompareClick()">
         {{ 'process.analysis.details.compare' | translate }}
    </div>
    <!--
    {{processAnalysis?.metadata?.state}}
    <pre>{{processAnalysis | json}}</pre> -->
  </div>

  <div slot="bottomSectionWidgets">
    <div class="metrics-container"
         *ngIf="(processAnalysis?.metadata?.status === 'Ready' || processAnalysis?.metadata?.status === 'Archived') && processAnalysis?.metrics; else notReady">
      <uxpl-data-widget [label]=quickLabel>
        <div class="data-points" *ngIf="summaryConfig">
          <div class="data-point-row" style="margin-top: 16px;">
            <uxpl-data-point [label]=eventsLabel icon-name="pm-events-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.events | number"></uxpl-data-point>
            <uxpl-data-point [label]=casesLabel icon-name="pm-cases-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.cases | number"></uxpl-data-point>
          </div>
          <div class="data-point-row" style="margin-top: 23px;">
            <uxpl-data-point [label]=variantsLabel icon-name="pm-variants-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.variants | number"></uxpl-data-point>
            <uxpl-data-point [label]=activitiesLabel icon-name="pm-activities-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.activities | number"></uxpl-data-point>
          </div>
          <div class="data-point-row" style="margin-top: 23px;">
            <uxpl-data-point [label]=resourcesLabel icon-name="pm-resources-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.resources | number"></uxpl-data-point>
            <uxpl-data-point [label]=timespanLabel icon-name="pm-timespan-widget" icon-color="#FFFFFF" icon-size="32px"
                             [value]="summaryConfig.timespan" [hint]="summaryConfig.timespanHint"></uxpl-data-point>
          </div>
        </div>
      </uxpl-data-widget>
      <uxpl-data-widget [label]=activitiesCaseLabel>
        <div class="chart-container">
          <div class="chart-container-wrapper" *ngIf="activitiesChartConfig">
            <canvas baseChart
                    [datasets]="activitiesChartConfig.chartData"
                    [labels]="activitiesChartConfig.labels"
                    [options]="activitiesChartConfig.options"
                    [plugins]="activitiesChartConfig.plugins"
                    [legend]="activitiesChartConfig.legend"
                    [type]="activitiesChartConfig.type">
            </canvas>
          </div>
        </div>
      </uxpl-data-widget>
      <uxpl-data-widget [label]=caseDurationLabel>
        <div class="chart-container">
          <div class="chart-container-wrapper" *ngIf="durationChartConfig">
            <canvas baseChart
                    [datasets]="durationChartConfig.chartData"
                    [labels]="durationChartConfig.labels"
                    [options]="durationChartConfig.options"
                    [plugins]="durationChartConfig.plugins"
                    [legend]="durationChartConfig.legend"
                    [type]="durationChartConfig.type">
            </canvas>
          </div>
        </div>
      </uxpl-data-widget>

    </div>
    <ng-template #notReady class="metrics-container">
      <div class="metrics-not-available" *ngIf="!processAnalysis.metadata['message']">
        <div class="no-metrics-content">
          <uxpl-icon icon="pm-metrics-empty-state" width="171px" height="120px"></uxpl-icon>
          {{ 'process.analysis.details.widget.message' | translate }}        </div>
      </div>
    </ng-template>
  </div>
</uxpl-detail-pane>
