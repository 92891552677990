<div class="fixActionRow">
    <h1 mat-dialog-title>Analysis in progress</h1>
    <div mat-dialog-content class="dialog-content">
        <progress-screen *ngIf="!progress.ready" class="overlay-container" [message1]="progress.message1" [message2]="progress.message2"
        [percentage]="progress.percentage"></progress-screen>
        <div class="discover-progress" *ngIf="progress.ready">
            <div class="image">
              <img width="300" src="../../../../assets/images/svg/ready.svg">
            </div>
            <div class="message-top">
              <div *ngIf="progress.message1" class="message">{{progress.message1}}</div>
              <div *ngIf="progress.message2" class="message">{{progress.message2}}</div>
            </div>
            <p-progressBar class="discover-progressbar" [value]="progress.percentage" [showValue]="false" mode="determinate"></p-progressBar>
            <div class="status" *ngIf="progress.percentage">progress: {{progress.percentage}}%</div>
            <div class="status" *ngIf="!progress.percentage">progress: 0%</div>
        </div>
    </div>
    <div>
      <div mat-dialog-actions>
        <uxpl-button type="secondary" *ngIf="!progress.ready" (clicked)="closeDialog()">Close</uxpl-button>
        <uxpl-button type="primary" *ngIf="progress.ready" (clicked)="closeDialog()">Close and retry</uxpl-button>
      </div>
    </div>
  </div>
  