import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UxplLeftNav } from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import { UxplPrimaryNav } from '@tibco/tc-web-components/dist/types/components/uxpl-primary-nav/uxpl-primary-nav';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoResponse, Organization } from 'src/app/models_ui/auth';
import { User } from 'src/app/models_ui/users';
import { Roles, UsersService } from 'src/app/service/users.service';
import { MatSort } from '@angular/material/sort';
import { TranslationService } from 'src/app/service/translate.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { environment } from 'src/environments/environment';
import { IconSidebar } from 'src/app/components/common/icon-sidebar/icon-sidebar.component';
import { MatDialog } from '@angular/material/dialog';
import { OrgInfoComponent } from 'src/app/components/org-info/org-info.component';
import { Subscription } from 'rxjs';
import { SuperadminService } from 'src/app/service/superadmin.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent implements OnInit {
  iconSidebarData: IconSidebar;
  orgSubscription: Subscription;

  currentOrgId: string;
  currentOrg: Organization;

  userinfo: UserInfoResponse;
  userRole: string;
  userDisplayName: string = '';
  orgsInfo: Organization[] = [];
  leftNavTabs: OrgSideMenu[];
  orgs: Organization[] = [];
  menuObj = { id: 'administration', label: 'administration' };
  @ViewChild('primaryNav', { static: false }) nav: ElementRef<UxplPrimaryNav>;
  @ViewChild('leftNav', { static: false }) leftNav: ElementRef<UxplLeftNav>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('orgDetail', { static: true }) orgDetail: ElementRef;
  @ViewChild('statisticsBlock', { static: true }) statisticsBlock: ElementRef;
  @ViewChild('divider', { static: true }) divider: ElementRef;
  @ViewChild('usersBlock', { static: true }) usersBlock: ElementRef;
  tableDisplayedColumns: string[] = ['tableName', 'tableSize'];
  tableDataSource = new MatTableDataSource<any>();
  totalAdmins : number = 0;
  totalAnalysts: number = 0;
  statistics: any
  stasOrgName: string
  stasOrgDescription:string
  messageTooltip: string;
  search: string = '';
  displayedColumns: string[] = [
    'name',
    'email',
    'role',
    'superadmin',
    'actions',
  ];
  dataSource = new MatTableDataSource<any>();
  roles: Roles;
  filteredUsers: Partial<User>[] = [];
  activeOrgId: string;
  orgName: string;
  orgDescription:string;
  orgData:any;
  isLoading: boolean = false;
  selectedOrgId: string;
  searchPlaceholder: string;
  currentLanguage: string;
  supportUrl: string = '';

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,
    private orgInfoDialog: MatDialog,
    private superadminService: SuperadminService,
    private commonService: CommonService
  ) {
    if (this.cookieService.check('userinfo')) {
      this.userinfo = JSON.parse(this.cookieService.get('userinfo'));
      if (this.userinfo.firstName && this.userinfo.lastName) {
        this.userDisplayName =
          this.userinfo.firstName +
          ' ' +
          this.userinfo.lastName.substring(0, 1);
      }
    }
  }

  ngOnInit(): void {
    this.usersService.getRoles().subscribe((res: Roles) => (this.roles = res));
    this.orgSubscription = this.superadminService.currentId.subscribe(id => {
      this.currentOrgId = id;
      if(id){
        this.populareLeftNav();
        this.initIconSidebar();
      }else{
        this.route.firstChild?.paramMap.subscribe(params => {
          this.currentOrgId = params.get('orgId');
          this.populareLeftNav();
          this.initIconSidebar();
        });
        if(!this.currentOrgId){
          this.getFirstOrganization();
        }
      }
    });

    this.currentLanguage = this.translationService.getCurrentLanguage();
    this.translationService.currentLang$.subscribe(lang => {
      this.currentLanguage = getLanguageCode(lang);
    });
    if(environment.supportUrl){
      this.supportUrl = environment.supportUrl;
    }
  }

  ngOnDestroy(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
  }

  triggerChildRefresh() {
    this.commonService.triggerRefresh();
  }

  getFirstOrganization(){
    this.isLoading = true;
    this.usersService.getOrgs().subscribe(
      (response) => {
        if(response.length > 0){
          this.currentOrgId = response[0].id.replace(/-/g, '')
          this.router.navigate(['/administration/'+this.currentOrgId+'/statistics']);
          this.populareLeftNav();
          this.initIconSidebar();
          this.initOrg();
        };
      },
      error => {
        this.isLoading = false;
        console.error('Get Organization error: '+error);
      }
    )
  }

  initOrg(){
    this.currentOrg = this.orgs.find(el => el.id.replace(/-/g, '') == this.currentOrgId);
  }

  populareLeftNav(){
    this.isLoading = true;
    this.usersService.getOrgs().subscribe(
      (orgs) => {
        this.orgs = orgs;
        this.leftNavTabs = this.orgs.map((org) => ({
          label: org?.displayName,
          link: '/administration/'+org?.id?.replace(/-/g, '')+'/statistics',
          id: org.id.replace(/-/g, '')
        }));

        this.orgsInfo = this.orgs;
        this.initOrg();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        console.log("GetOrgs error: ", error);
      }
    );
  }

  initIconSidebar(){
    this.iconSidebarData = {
      "main": [{
        "label": this.translationService.translate('administration.icon.side.menu.statistics'),
        "icon": "analytics",
        "link": "/administration/"+this.currentOrgId+"/statistics",
        "routerLinkActiveExact": true 
      },{
        "label": this.translationService.translate('administration.icon.side.menu.edit.organization'),
        "icon": "edit",
        "link": "/administration/"+this.currentOrgId+"/edit-org",
        "routerLinkActiveExact": true 
      },{
        "label": this.translationService.translate('administration.icon.side.menu.users'),
        "icon": "group",
        "link": "/administration/"+this.currentOrgId+"/users",
        "routerLinkActiveExact": true 
      },{
        "label": this.translationService.translate('administration.icon.side.menu.add.user'),
        "icon": "person_Add",
        "link": "/administration/"+this.currentOrgId+"/add-user",
        "routerLinkActiveExact": true 
      }]
    };
  }

  downloadDXP() {
    this.isLoading = true;
    this.usersService.getDXPDocument(this.currentOrgId).subscribe(
      (response: HttpResponse<Blob>) => {
        this.isLoading = false;
        const blob = new Blob([response.body], { type: response.body.type });

        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${this.currentOrg.displayName}.dxp`;
        document.body.appendChild(downloadLink);

        downloadLink.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(downloadLink);
      },
      (error) => {
        this.isLoading = false;
        console.error('Error:', error);
        this.snackBarService.openSnackBar(this.translationService.translate('admin.component.snackbar.error'), this.translationService.translate('close'),'error');
      }
    );
  }
  getOrgInfo(){
    this.orgInfoDialog.open(OrgInfoComponent, {
      width: '420px',
      data: this.orgsInfo.find(el => el.id.replace(/-/g, '') == this.currentOrgId),
    });
  }
}
export interface OrgSideMenu {
  label: string;
  link: string;
}
function getLanguageCode(lang) {
  const languagePart = lang.split('-')[0];
    return languagePart.toUpperCase();
}

