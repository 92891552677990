<div class="template-basic-info-container">
  <div class="template-basic-info-left-panel">
    <uxpl-select-input *ngIf="isNewTemplate" [label]="title" [value]="baseTemplateId"
                       [options]="templateLabels" (uxplChanged)="handleTemplateSelection($event)"></uxpl-select-input>
    <uxpl-form-field style="margin-top: 5px; margin-bottom: 5px;" label="Template name"
                     type="text" [valid]="!isValid()"
                     required="true" [value]="template.name" [hint]="nameHint"
                     (uxplChanged)="handleUpdate($event, 'name')"></uxpl-form-field>

    <uxpl-textarea class="description-input" [value]="template.description"
                   (uxplChanged)="handleUpdate($event, 'description')">{{ 'template.basic.label.description' | translate }}</uxpl-textarea>
    <div class="custom-icon-label">{{ 'template.basic.label.icon' | translate }}</div>
    <icon-grid [icon]="template.icon" [iconGrid]="ICON_GRID" [iconColor]="'#727272'"
               (selectedIcon)="selectCardIcon($event)"></icon-grid>
  </div>
  <div class="template-basic-info-right-panel">
    <div class="section-title" *ngIf="template.type" >{{ 'template.basic.label.preview' | translate }}</div>
    <div class="template-basic-info-card">
      <template-card *ngIf="template.type"  [cardConfig]="template" mode="select"></template-card>
    </div>
  </div>
</div>
