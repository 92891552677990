import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { InvestigationContext } from '../../../models_ui/investigationContext';
import { Router } from '@angular/router';
import { copyToClipBoard } from 'src/app/functions/details';
import {Investigation, InvestigationApplication, InvestigationMetadata} from '@tibco/discover-client-lib';
import {InternalMessageService} from '../../../service/internal-message.service';

@Component({
  selector: 'investigation-details-table',
  templateUrl: './investigation-details-table.component.html',
  styleUrls: ['./investigation-details-table.component.css']
})
export class InvestigationDetailsTableComponent implements OnInit, OnChanges {

  @Input() cConfig: InvestigationApplication;
  @Input() investigationDetail: Investigation;
  @Input() investigationContext: InvestigationContext;

  protected MAX_FIELD_LENGTH = 35;

  transpose: any[][];
  copyToClipBoard = copyToClipBoard;


  constructor(private router: Router,
              public msService: InternalMessageService) {
  }

  ngOnInit(): void {
    this.transpose = _.cloneDeep(this.cConfig.detailFields[0].map((_var, colIndex) => this.cConfig.detailFields.map(row => row[colIndex])));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cConfig && !changes.cConfig.firstChange) {
      if (!_.isEqual(changes.cConfig.currentValue, changes.cConfig.previousValue)) {
        this.transpose = _.cloneDeep(changes.cConfig.currentValue.detailFields[0].map((_var, colIndex) => changes.cConfig.currentValue.detailFields.map(row => row[colIndex])));
      }
    }
  }

  private getField(fieldV) {
    if (this.investigationDetail) {
      if (fieldV.indexOf('META:') === -1) {
        return _.get(this.investigationDetail.data, fieldV);
      } else {
        fieldV = fieldV.substring(fieldV.indexOf(':') + 1);
        return this.investigationDetail.metadata.filter((el: InvestigationMetadata) => el.name === fieldV)[0].value;
      }
    }
  }

  getFieldValue(fieldV, format?) {
    let re = this.getField(fieldV);
    if (format === 'ARRAY') {
      let newRe = '';
      if (Symbol.iterator in Object(re)) {
        for (const val of re) {
          newRe += val + '<br>';
        }
      }
      re = newRe;
    }
    if (re && re.length > this.MAX_FIELD_LENGTH) {
      re = re.substr(0, this.MAX_FIELD_LENGTH) + '...';
    }
    return re;
  }

  getToolTip(fieldV, format?) {
    let re = this.getField(fieldV);
    if (format === 'ARRAY') {
      let newRe = '';
      if (Symbol.iterator in Object(re)) {
        for (const val of re) {
          newRe += val + '\n';
        }
      }
      if (newRe.length < this.MAX_FIELD_LENGTH) {
        newRe = '';
      }
      re = newRe;
    } else {
      if (re && re.length < this.MAX_FIELD_LENGTH) {
        re = '';
      }
    }
    return re;
  }

  openAnalysis() {
    this.router.navigate(['/discover/process-analysis'], {queryParams: {focus: this.investigationContext.processAnalysis.idNoVersion}});
  }

  openDataset() {
    this.router.navigate(['/discover/dataset'], {queryParams: {focus: this.investigationContext.dataset.id}});

  }
}
