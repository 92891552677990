import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SelectOption} from '@tibco/tc-web-components/dist/types/models/selectInputConfig';
import {Table} from 'primeng/table';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DatasetSchemaType, DatasetWizard} from 'src/app/models_ui/dataset';
import {NewAnalysisStepStatus} from 'src/app/models_ui/discover';
import {DateParseRecord, DateParsingResult} from 'src/app/models_ui/parsing';
import {ConfigurationService} from 'src/app/service/configuration.service';
import {CsvNgpDataSource} from 'src/app/service/csv-ngp-datasource';
import {DatasetService} from 'src/app/service/dataset.service';
import {ParsingService} from 'src/app/service/parsing.service';
import {cloneDeep} from 'lodash-es';
import {CreateDataset, Dataset, DatasetSchema, FileDatasetConfiguration} from '@tibco/discover-client-lib';

@Component({
  selector: 'dataset-date-parser',
  templateUrl: './date-parser.component.html',
  styleUrls: ['./date-parser.component.scss', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss']
})
export class NewDatesetDateParserComponent implements OnChanges, OnInit {

  @Input() data: Dataset | CreateDataset;
  @Input() wizard: DatasetWizard;
  @Input() csvFile: File;
  @Input() previewData: any[];
  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter();
  @Output() handlePreviewData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt', {static: false}) table: Table;

  public validating = false;
  public columns: string[];
  public dateFields: { name: string, index: number }[];
  public possFormats: string[][];
  public dataSource: CsvNgpDataSource;
  public loadedData: any[];
  public rows = 100;
  public formatChanged: Subject<any> = new Subject<any>();
  public parsingResult: DateParsingResult[];
  public bestPartialMatches: DateParseRecord[][];
  public partialFormats: string[][];
  public invalidDateColumnIdxs: number[] = [];
  public invalidDateColumns: string[] = [];
  public schema: DatasetSchema[];

  public validRowsCount = -1;  // -1 means not all column has format and cannot count

  tableScrollHeight = '300px';

  constructor(
    protected parsingService: ParsingService,
    protected datasetService: DatasetService,
    protected configService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.formatChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {
        const myFormat = model.format;
        const dfIndex = model.dfIndex;
        // this.dateOptions[dfIndex] = [myFormat];
        this.getDatesetColumn(dfIndex).format = myFormat;

        this.emitStepStatus();
        this.countValidRows();
      });

    this.initSchema();

    setTimeout(() => {
      this.setTableScrollHeight();
    }, 100);
  }

  private setTableScrollHeight() {
    try {
      const dateparserEle = this.table.el.nativeElement.parentNode.parentNode;
      if (dateparserEle) {
        const height = dateparserEle.offsetHeight;
        if (height) {
          this.tableScrollHeight = (height - 200) + 'px';
        }
      }
    } catch (error) {
    }
  }

  public isBadRow(_rowIndex) {
    // todo
    // if (this.bestPartialMatches) {
    //   const matchRec: DateParseRecord = this.bestPartialMatches.find(rec => {
    //     return rec.format === this.parse.dateTimeFormat;
    //   });
    //   return matchRec.badRows.includes(rowIndex);
    // } else {
    //   return false;
    // }
    return false;
  }

  public clickDateFormat(event: Event) {
    setTimeout(() => {
      // @ts-ignore
      const ul = event.target.shadowRoot.querySelector('.uxpl-options-menu');
      if (ul) {
        ul.style.position = 'fixed';
        ul.style.width = '360px';
      }
    }, 100);

  }

  public calcInvalidColumns() {
    this.invalidDateColumns = [];
    this.invalidDateColumnIdxs = [];
    // todo
    // if (this.bestPartialMatches) {
    //   const matchRec: DateParseRecord = this.bestPartialMatches.find(rec => {
    //     return rec.format === this.parse.dateTimeFormat;
    //   });
    //   if (matchRec) {
    //     this.invalidDateColumnIdxs = matchRec.badColumns;
    //     let idx: number = 0;
    //     this.invalidDateColumnIdxs.forEach(col => {
    //       this.invalidDateColumns.push(this.dateFields[idx]);
    //       idx++;
    //     })
    //   }
    // }
  }

  public isBadColumn(col: string) {
    if (this.invalidDateColumns) {
      return this.invalidDateColumns.includes(col);
    } else {
      return false;
    }

  }

  public isNewFormat(): boolean {
    // let noformat = false;
    for (let i = 0; i < this.dateFields.length; i++) {
      const myFormat = this.getDatesetColumn(i).format;
      if (myFormat && this.parsingResult[i] && this.parsingResult[i].formats) {
        const idx = this.parsingResult[i].formats.findIndex((fmt: DateParseRecord) => {
          return fmt.format === myFormat
        });
        if (idx < 0) {
          return true;
        }
      }
    }
    return false;

  }

  handleDateFormatSelect(event, dfIndex: number) {
    const format = event.detail && event.detail.value && event.detail.value.length > 0 ? event.detail.value : undefined;
    this.handleDateFormatUpdate(format, dfIndex);
  }

  handleDateFormatEdit(event, dfIndex: number) {
    this.handleDateFormatUpdate(event.detail, dfIndex);
  }

  private handleDateFormatUpdate(format: string, dfIndex: number) {
    this.formatChanged.next({
      format,
      dfIndex
    });
  }

  private countValidRows() {
    this.validRowsCount = 0;
    if (this.loadedData && this.loadedData.length > 0 && this.isAllDateFormatSet()) {
      let count = 0;
      for (const dl of this.loadedData) {
        if (dl) {
          let valid = true;
          for (let j = 0; j < this.dateFields.length; j++) {
            const schema: DatasetSchema = this.getDatesetColumn(j);
            if (!schema.format || !this.parsingService.validateSingleDate(dl[schema.label], schema.format)) {
              valid = false;
              break;
            }
          }
          if (valid) {
            count++;
          }
        }
      }
      this.validRowsCount = count;
    }
  }

  private reset() {
    this.bestPartialMatches = [];
    this.partialFormats = [];
    this.parsingResult = [];
    this.loadedData = undefined;
    this.possFormats = undefined;
  }

  public refreshData() {
    this.reset();

    if (this.dataSource) {
      this.dataSource.disconnect();
    }


    if (this.csvFile) {
      // CSV parsing
      // const config = this.getCsvConfig();
      const fileConfig: FileDatasetConfiguration = this.data.configuration as FileDatasetConfiguration;
      const config = {
        quoteChar: fileConfig.quoteChar,
        escapeChar: fileConfig.escapeChar,
        delimiter: fileConfig.delimiter,
        preview: this.wizard.numberRowsForPreview,
        encoding: fileConfig.encoding,
        comments: fileConfig.commentsChar,
        skipEmptyLines: true,
        download: true
      };
      this.dataSource = new CsvNgpDataSource(this.csvFile, this.rows, config);
    }

    if (this.dataSource) {
      this.dataSource.connect().subscribe(
        next => {
          if (next && next.length > 0) {
            this.loadedData = next;
            if (!this.possFormats) {
              const startIdx = 0;
              const rows = next.slice(startIdx - (this.rows + 1), next.length - 1);
              this.possFormats = this.getPossibleDataFormats(rows);
            } else {
              // only need to test the last new rows
              const startIdx = next.length - (this.rows + 1);
              const newRows = next.slice(startIdx, next.length - 1);
              const newFmts = this.getPossibleDataFormats(newRows);

              this.possFormats.forEach((pf, index) => {
                pf = pf.filter(fmt => {
                  return newFmts[index].includes(fmt);
                });
              });
            }

            this.emitStepStatus();
            this.countValidRows();
          }
        }
      )
    } else {
      // edit dataset, no way to get file data source(File instance or file http url) again
      setTimeout(() => {
        if (this.previewData) {
          this.refreshDataWithAvailablePreview();
        } else {
          this.datasetService.pullPreviewData(this.data as Dataset, this.wizard).subscribe(data => {
            this.handlePreviewData.emit({
              previewData: data
            });

            this.initSchema();
          });
        }
      }, 0);

    }
  }

  private refreshDataWithAvailablePreview() {
    if (this.previewData) {
      this.loadedData = cloneDeep(this.previewData);
      this.possFormats = this.getPossibleDataFormats(this.previewData);
      this.emitStepStatus();
      this.countValidRows();
    }
  }

  private isAllDateFormatSet(): boolean {
    let allSet = true;
    for (const df of this.dateFields) {
      if (!this.schema[df.index].format || this.schema[df.index].format === 'None') {
        allSet = false;
        break;
      }
    }
    return allSet;
  }

  private emitStepStatus() {
    const stepStatus = {
      step: 'dataset-dates',
      completed: this.isAllDateFormatSet()
    } as NewAnalysisStepStatus;
    this.status.emit(stepStatus);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    // console.log(changes);
    // if (changes.data.firstChange) {
    //   this.initSchema();
    // }
  }

  initSchema() {
    this.schema = this.data.schema;

    if (this.schema) {

      this.dateFields = [];
      this.schema.forEach((column, index) => {
        if (column.type === DatasetSchemaType.TIMESTAMP) {
          this.dateFields.push({
            name: column.label,
            index
          });
        }
      });

      this.columns = this.schema.map(column => column.label);

      if (this.dateFields) {
        this.validate();
      } else {
        this.refreshData();
      }
    }
  }

  validate() {
    this.validating = true;
    this.refreshData();
  }

  refresh() {
    this.validRowsCount = -1;
    this.dateFields.forEach((_df, dfIndex) => {
      this.getDatesetColumn(dfIndex).format = 'None';
    });
    this.validating = false;

    this.possFormats = this.getPossibleDataFormats(this.previewData);
    this.emitStepStatus();
    this.countValidRows();
  }

  // selectBestMatch(res: DateParseRecord, dfIndex: number) {
  //   this.handleDateFormatUpdate({detail: {value: res.format}}, dfIndex);
  //   this.calcInvalidColumns();
  // }

  public isSelectedBestMatch(_res: DateParseRecord): boolean {
    // todo:
    return true;
  }

  private getPossibleDataFormats(data): string[][] {
    const possibleMatches = [];

    this.dateFields.forEach((df, dfIndex) => {
      const targetData: string[] = [];

      data.forEach(row => {
        // const rowData: string[] = [];
        // rowData.push(row[df.name]);
        targetData.push(row[df.name]);
      });

      // data = targetData;
      this.parsingResult[dfIndex] = this.parsingService.predictDateFormat(targetData);
      const possMatches: string[] = [];
      this.parsingResult[dfIndex].formats.filter(rec => {
        return rec.badRows.length <= 0
      }).forEach(match => {
        possMatches.push(match.format);
      });
      if (possMatches.length < 1) {
        // sort by highest number matches
        const partialMatches = this.parsingResult[dfIndex].formats.sort((a, b) => {
          return b.matches - a.matches;
        });
        this.bestPartialMatches[dfIndex] = partialMatches;
        const partialFormats = [];
        this.bestPartialMatches[dfIndex].forEach(pm => partialFormats.push(pm.format));
        this.partialFormats[dfIndex] = partialFormats;
        // if (this.partialFormats[dfIndex].length > 0) {
        //   this.getDatesetColumn(dfIndex).format = this.partialFormats[dfIndex][0];
        // }
        this.calcInvalidColumns();
      } else {
        if (possMatches.length === 1) {
          // only one format which matches all the date strings
          this.getDatesetColumn(dfIndex).format = possMatches[0];
        }
      }
      possibleMatches[dfIndex] = possMatches;
    });

    return possibleMatches;

  }

  private getDatesetColumn(dfIndex: number): DatasetSchema {
    return this.schema[this.dateFields[dfIndex].index];
  }

  public getOptions(index: number) {
    const options: SelectOption[] = [];
    if (this.possFormats && this.possFormats[index] && this.possFormats[index].length > 0) {
      this.possFormats[index].forEach((fmt: string) => {
        options.push(
          {
            label: fmt,
            value: fmt,
            disabled: false
          }
        );
      })
    } else if (this.partialFormats && this.partialFormats[index] && this.partialFormats[index].length > 0) {
      this.partialFormats[index].forEach((fmt: string) => {
        options.push(
          {
            label: fmt,
            value: fmt,
            disabled: false
          }
        );
      })
    }

    return options;
  }

  public isDateField(col: string) {
    return this.dateFields.filter(f =>
      f.name === col
    ).length > 0;
  }

  private getDatesetColumnByName(col: string): DatasetSchema {
    let index = -1;
    for (const df of this.dateFields) {
      if (df.name === col) {
        index = df.index;
        break;
      }
    }

    if (index !== -1) {
      return this.schema[index];
    }
  }

  public dateClass(date: string, col: string, dfIndex: number): string {
    const column = this.getDatesetColumnByName(col);
    if (column) {
      if (column.format && column.format !== 'None') {
        if (this.parsingService.validateSingleDate(date, column.format)) {
          return 'valid';
        } else {
          return 'invalid';
        }
      } else {
        if ((this.partialFormats[dfIndex] && this.partialFormats[dfIndex].length) ||
          (this.possFormats[dfIndex] && this.possFormats[dfIndex].length)) {
          return 'indeterminate';
        } else {
          // now we use moment-guess to guess the date format, so this case won't happen
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.setTableScrollHeight();
  }
}
