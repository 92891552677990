<div class="cases-container">
  <div class="caseview-top-level">
    <div class="caseview-wrapper" [hidden]="!hasCaseConfig">
      <div class="caseview-left-nav" [hidden]="!hasCaseConfig">
        <uxpl-left-nav-multi #leftNav (selected)="handleClick($event)" [tabs]="leftNavTabs" collapse [hidden]="!hasCaseConfig"></uxpl-left-nav-multi>
      </div>
      <div class="caseview-table" *ngIf="investigations">
        <investigation-list *ngFor="let cCon of caseConfig; let i = index" [cConfig]="cCon" [investigations]="investigations[i]" [focusIds]="invFocus[cCon.applicationId]" [hidden]="hideTable[i]" (refreshEvent)="refreshEvent($event, i)"></investigation-list>
      </div>
    </div>
    <div [hidden]="hasCaseConfig" class="center">
      <div class="img-center">
        <img width="204" src="assets/images/png/no-data.png">
      </div>
      <div class="no-data-available"><br></div>
      <div class="no-data-available" style="font-weight: bold">No investigations configured.<br></div>
      <div class="no-data-available"><br></div>
      <!--
      <div class="no-data-available"> Select <span class="hyperlink" (click)="selectAnalytic()">a process analysis</span>
        to get started.
      </div>-->
    </div>
  </div>
</div>
