import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatStepper}  from '@angular/material/stepper';
import { RepositoryService } from '@tibco/discover-client-lib';
import { convertAnalysisToCreateAnalysis, convertCreateAnalysisToUpdate } from 'src/app/functions/analysis';
import {FolderEntity} from 'src/app/routes/manage-process-analysis/manage-process-analysis.component';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
  
@Component({
  selector: 'analysis-folder',
  templateUrl: './analysis-folder.component.html',
  styleUrls: ['./analysis-folder.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ]
})
  
export class AnalysisFolderComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  creationLoading: boolean = false;
  creationStatus: string = 'ready';
  analyisFolderTitle: string;
  folderName: string = '';
  completeFolderName: string = '';
  analysisArrayPathName: string[] = [];
  folderNamePlaceholder:string;
  message1 = this.translationService.translate('manage.process.analysis.folder.message1');
  message2 = this.translationService.translate('manage.process.analysis.folder.message2');
  message3 = this.translationService.translate('manage.process.analysis.folder.message3');

  validatorArrayMessage: any[] = [{
    code: 0,
    message: '',
    valid: true
  },{
    code: 1,
    message: this.message1,
    valid: false
  },{
    code: 2,
    message: this.message2,
    valid: false
  },{
    code: 3,
    message: this.message3,
    valid: false
  }];
  validationSteps: any[] = [{
    code: 0,
    message: '',
    valid: true
  },{
    code: 0,
    message: '',
    valid: true
  },{
    code: 0,
    message: '',
    valid: true
  }];
  valid1 = new FormControl('');
  valid2 = new FormControl('');
  valid3 = new FormControl('');

  currentFolder2: string;
  currentFolder3: string;
  currentFolderArray2: any[] = [{
    "name": "General",
    "path": "General"
  }];
  currentFolderArray3: any[] = [{
    "name": "General",
    "path": "General"
  }];
  folders: any[] = [];
  foldersLowerCase: any[] = [];
  hierarchicalFolders: FolderEntity[];
  analysisList: any[] = [];
  analysisToAdd: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AnalysisFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private repositoryMS: RepositoryService,
    private translationService: TranslationService,
    private snackBarService: SnackbarService,

  ) {
    dialogRef.disableClose = true;
    this.hierarchicalFolders = data?.hierarchicalFolders;
    this.folders = data?.foldersList;
    this.currentFolder2 = data?.currentFolder;
    this.currentFolder3 = data?.currentFolder;
    this.analysisList = data?.analysisList;
    this.foldersToLowerCase();
  }

  ngOnInit(): void {
      this.analyisFolderTitle = this.data.title;
      
      this.message1= this.translationService.translate('manage.process.analysis.folder.message1');
      this.message2= this.translationService.translate('manage.process.analysis.folder.message2');
      this.message3= this.translationService.translate('manage.process.analysis.folder.message3');
   this.folderNamePlaceholder = this.translationService.translate('manage.process.analysis.label.modal.step1');
  }

  foldersToLowerCase(){
    for(let folder of this.folders){
      this.foldersLowerCase.push(folder.toLowerCase()); 
    }
  }

  public updateFolderName(value?): void {
    if(value){
      this.folderName = value;
    }
    this.completeFolderName = this.currentFolder2+'||'+this.folderName;
    if(this.currentFolder2 == "General"){
      this.completeFolderName = this.folderName;
    }
    if(this.folderName.length > 0){
      this.validateFolderName();
    }else if(!this.folderName){
      this.changeValidatorArray(1, 0);
    }
  }

  validateFolderName(){
    if(this.foldersLowerCase.includes(this.completeFolderName.toLowerCase())){
      this.changeValidatorArray(2, 0);
      this.changeValidatorArray(2, 1);
    }else{
      this.changeValidatorArray(0, 0);
      this.changeValidatorArray(0, 1);
      if(!this.folderName){
        this.changeValidatorArray(1, 0);
      }
    }
  }

  public selectFolder = (folderPath, target): void => {
    let openFolder = document.querySelector('#step-'+target+' .process-analysis-folder-wrapper ul li.open');
    if(openFolder){
      openFolder.classList.remove('open');
    }
    let newFolder = document.querySelector('#step-'+target+' .process-analysis-folder-wrapper ul li.add-folder');
    if(newFolder){
      newFolder.classList.remove('add-folder');
    }
    let selectedFolder = document.querySelector('#step-'+target+' .process-analysis-folder-wrapper ul li a[data-folder-path="'+folderPath+'"]').closest('li');
    if(selectedFolder){
      selectedFolder.classList.add('open');
    }
    if(target == '2'){
      this.currentFolder2 = folderPath;
      localStorage.setItem('folderFocus',folderPath+'||'+this.folderName)
    }else if(target == '3'){
      this.currentFolder3 = folderPath;
    }
    this.updateCurrentFolderArray(target);
    
    if(!this.folderName){
      this.changeValidatorArray(1, 0);
    }else{
      this.updateFolderName(this.folderName);

    }
    
    this.validateFolderName();
  }

  updateCurrentFolderArray(target){
    let aCurrentFolder;
    if(target == '2'){
      aCurrentFolder = this.currentFolder2.split('||');
      this.currentFolderArray2 = [{
        "name": "General",
        "path": "General"
      }]
    }else if(target == '3'){
      aCurrentFolder = this.currentFolder3.split('||');
      this.currentFolderArray3 = [{
        "name": "General",
        "path": "General"
      }]
    }
    for(let [i, folder] of aCurrentFolder.entries()){
      let itemToAdd = undefined;
      if(i == 0 && folder !== 'General'){
        itemToAdd = {
          "name": folder,
          "path": folder
        };
      }else if(i == 1){
        itemToAdd = {
          "name": folder,
          "path": aCurrentFolder[i - 1]+'||'+folder
        };
      }else if(i == 2){
        itemToAdd = {
          "name": folder,
          "path": aCurrentFolder[i - 2]+'||'+aCurrentFolder[i - 1]+'||'+folder
        };
      }
      if(itemToAdd != undefined){
        if(target == '2'){
          this.currentFolderArray2.push(itemToAdd);
        }else if(target == '3'){
          this.currentFolderArray3.push(itemToAdd);
        }
      }
    }
  }

  validateStep2(){
    if(this.analysisToAdd.length > 0){
      this.changeValidatorArray(0, 2);
    }else{
      this.changeValidatorArray(3, 2);
    }
  }

  selectAnalysis(target){
    this.analysisToAdd.push(target);
    this.validateSelectedAnalysis();
  }

  unselectAnalysis(target){
    let indexToRemove = this.analysisToAdd.indexOf(target);
    this.analysisToAdd.splice(indexToRemove, 1);
    this.validateSelectedAnalysis();
  }

  validateSelectedAnalysis(){
    if(this.analysisToAdd.length > 0){
      this.changeValidatorArray(0, 2);
    }else{
      this.changeValidatorArray(3, 2);
    }
  }

  changeValidatorArray(code, step){
    for(let message of this.validatorArrayMessage){
      if(message.code == code){
        this.validationSteps[step] = message;
        break;
      }
    }
  }

  createNewFolder(){
    this.validateFolderName();
    this.validateStep2();

    if(this.isValid()){
      this.creationLoading = true;
      for(let analysisId of this.analysisToAdd){
        this.repositoryMS.getAnalysisDetails(analysisId).subscribe(
          analysis => {
            let newAnalysis = convertAnalysisToCreateAnalysis(analysis);
            let aName = newAnalysis.name.split("||");
            let sName = '';
            sName = aName.pop();
            newAnalysis.name = this.completeFolderName+'||'+sName;
            this.repositoryMS.updateAnalysis([convertCreateAnalysisToUpdate(newAnalysis, analysisId)]).subscribe(
              () => {
                //created successfully
                this.snackBarService.openSnackBar(this.translationService.translate('analysis.folder.snackbar.success'), this.translationService.translate('create.org.snackBar.close'),'success');

              },
              error => {
                this.creationStatus = 'error';
                this.snackBarService.openSnackBar(this.translationService.translate('analysis.folder.snackbar.error') + error, this.translationService.translate('create.org.snackBar.close'),'error');

                console.error('Error updating the analysis.', error);
              }
            );
          }
        )
      }
      if(this.creationStatus != 'error'){
        this.creationStatus = 'success';
      }
      this.creationLoading = false;
    }else{
      this.stepper.selectedIndex = this.getFirstInvalidStep();
    }
  }

  isValid(){
    
    let valid = true;
    for(let step of this.validationSteps){
      if(step.valid == false){
        valid = false;
      }
    }
    return valid;
  }

  getFirstInvalidStep(){
    let invalidStep = 0;
    for(let [i, step] of this.validationSteps.entries()){
      if(step.valid == false){
        invalidStep = i;
        break;
      }
    }
    return invalidStep;
  }

  stepChanged(event){
    console.log(event);
    console.log("this.validationSteps", this.validationSteps);
    if(event.previouslySelectedStep.interacted == true && event.previouslySelectedIndex == 0){
      if(this.folderName.length > 0){
        this.validateFolderName();
      }else if(!this.folderName){
        this.changeValidatorArray(1, 0);
      }
   
    }
    if(event.previouslySelectedStep.interacted == true && event.previouslySelectedIndex == 2){
      this.validateSelectedAnalysis();
    }
    
  }

  retry(){
    this.creationStatus = 'ready';
  }

  public onCancel = (): void => {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
  