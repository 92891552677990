import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pulse-loading',
  templateUrl: './pulse-loading.component.html',
  styleUrls: ['./pulse-loading.component.css']
})
export class PulseLoadingComponent implements OnInit {

  @Input() name: boolean | undefined;
  @Input() tagLine: boolean | undefined;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
