import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  CatalogService,
  CreateDataset,
  Dataset,
  DatasetMetadata,
  TCMDatasetConfiguration
} from '@tibco/discover-client-lib';
import {from, Observable, of} from 'rxjs';
import {catchError, concatMap, delay, filter, map, repeatWhen, take} from 'rxjs/operators';
import {DatasetWizard} from '../models_ui/dataset';
import {CsvService} from '../service/csv.service';
import { endpoints } from 'src/environments/endpoints';

@Injectable({
  providedIn: 'root'
})

export class DatasetService {

  constructor(
    private catalogService: CatalogService,
    private csvService: CsvService,
    private http: HttpClient,
  ) {
  }
  archive(orgData: any): Observable<any> {
    const datasetId = orgData.id;
    const version = orgData.metadata.version;
    return this.http.post<any>(
      endpoints.catalog.dataset +
        `/${datasetId}/${version}/action/archive`,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  unarchive(orgData: any): Observable<any> {
    const datasetId = orgData.id;
    const version = orgData.metadata.version;
    return this.http.post<any>(
      endpoints.catalog.dataset +
        `/${datasetId}/${version}/action/unarchive`,
      null,
      {
        observe: 'response',
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  private uploadCsvFile(id: string, progress: any, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const upload = {
        name: file.name,
        uploaded: 0,
        prevUploaded: 0,
        status: 'queued',
        progress: 0,
      };
      const initPercentage = progress.percentage;
      const finalPercentage = 50;
      progress.description = 'Uploading CSV file';
      this.catalogService.uploadCSV(id, file, 'events', true).subscribe(
        (resp: any) => {
          const response = resp;
          if (response.type === HttpEventType.UploadProgress) {
            upload.uploaded = response.loaded;
            upload.prevUploaded = response.loaded;
            upload.status = 'uploading';
            upload.progress = Math.round(100 * response.loaded / response.total);
            progress.percentage = Math.floor((finalPercentage - initPercentage) * upload.progress / 100) + initPercentage;
            if (upload.progress === 100) {
              upload.status = 'finalizing';
              resolve(file.name + ' Uploaded successfully...')
            }
          }
          if (response.type === HttpEventType.Response && response.ok) {
            upload.status = 'uploaded';
          }
        },
        () => {
          upload.status = 'failed';
          reject()
        }
      );
    });
  }

  // only for tcm dataset
  public saveTcmDataset(createDataset: CreateDataset): Observable<any> {
    return this.catalogService.createDataset(createDataset).pipe(
      concatMap(createDatasetResp => {
        return of(createDatasetResp.id as string);
      })
    ).pipe(
      concatMap(id => {
        return this.catalogService.startDataCollection(id).pipe(
          catchError(error => {
            error.from = 'startDataCollectionError';
            console.error('Error starting data collection for dataset');
            throw error;
          }),
          map((response) => {
            console.log('Data collection response: ', response)
            return id;
          })
        );
      })
    );
  }

  // only for csv file dataset
  public saveDatasetAndUploadFileAndPreview(createDataset: CreateDataset, progress: any, file: File): Observable<any> {
    return this.catalogService.createDataset(createDataset).pipe(
      concatMap(createDatasetResp => {
        return of(createDatasetResp.id as string);
      })
    ).pipe(
      concatMap(id => {
        if (file) {
          return from(this.uploadCsvFile(id, progress, file)).pipe(
            catchError(error => {
              error.from = 'uploadCSVError';
              console.error('Error uploading file for dataset');
              throw error;
            }),
            map(() => {
              return id;
            })
          );
        }
      })
    ).pipe(
      concatMap(id => {
        return this.pollPreviewStatus(id, progress).pipe(
          map(resp => {
            return {
              datasetStatus: resp,
              id
            }
          })
        );
      })
    );
  }

  public pollPreviewStatus(datasetId: string, progress: any): Observable<any> {
    return this.catalogService.reportStatus(datasetId).pipe(
      repeatWhen(obs => obs.pipe(delay(2000))),
      filter(data => {
        // console.log('reportStatus: ', data)
        // the first status data is null, and several status data after it is {progress: 0, ...}
        if (data && data.progress !== 0) {
          progress.percentage = data.progress;
          progress.status = data.status;
          progress.description = data.description;
        }
        return progress.stop === true ||
          (data?.status === DatasetMetadata.StatusEnum.Ready || data?.status === DatasetMetadata.StatusEnum.Error)
      }),
      take(1)
    );
  }

  public refresh(dataset: Dataset, progress: any): Observable<any> {
    return this.catalogService.triggerSnapshot(dataset.id, dataset.metadata.version).pipe(
      concatMap(() => {
        // console.log('triggerSnapshot response 1: ', resp)
        return of({});
      })
    ).pipe(
      concatMap(() => {
        // console.log('triggerSnapshot response: ', resp)
        return this.pollPreviewStatus(dataset.id, progress);
      })
    );
  }

  // public refresh(dataset: Dataset, progress: any): Observable<any> {
  //   return this.catalogService.triggerSnapshot(dataset.id, dataset.metadata.version).pipe(
  //     concatMap(resp => {
  //       console.log('triggerSnapshot response: ',  resp)
  //       return this.pollPreviewStatus(dataset.id, progress);
  //     })
  //   );
  // }

  public pullPreviewData(dataset: Dataset, datasetWizard: DatasetWizard): Observable<any> {
    if (dataset.type === Dataset.TypeEnum.File || dataset.type === Dataset.TypeEnum.Xes || dataset.type === Dataset.TypeEnum.Xlsx) {
      // todo: an api is needed to return csv file data
      return this.catalogService.downloadCSV(dataset.id).pipe(
        concatMap(async (resp: Blob) => {
          const filecontent = await resp.text();
          const parseResult = this.csvService.previewData(filecontent);
          return (parseResult.data as Array<any>).slice(0, datasetWizard.numberRowsForPreview);
        })
      );
    } else if (dataset.type === Dataset.TypeEnum.Tcm) {
      return this.catalogService.getTCMPreview(dataset.id, (dataset.configuration as TCMDatasetConfiguration).topic);
    } else {
      return of({});
    }
  }
}
