<ng-template #mappingT let-mappings="mappings" let-required="required">
  <div class="map-column gap-15">
    <div *ngFor="let map of mappings  let i = index">
      <div *ngIf="!map.isLocked" class="mapping">
        <div class="mapping-label">
          <label>{{map.fieldDescription}}</label>
          <mat-icon class="material-symbols-rounded" *ngIf="map.isAutomapped" matTooltipClass="custom-tooltip" matTooltip="{{getAutoMapToolTip(map.fieldName)}}">lightbulb</mat-icon>
        </div>
        <uxpl-select-input class="map-field" [id]="map.fieldName" allow-clear="true"
                           [yPosition]="getPosition(i)"
                           allow-input [value]="mapping[map.fieldName]"
                           [options]="calculateOption(map.fieldName, map.type)"
                           (uxplSelection)="handleSelection($event, map.fieldName)" required></uxpl-select-input>
      </div>
      <div *ngIf="map.isLocked" class="mapping">
        <div class="mapping-label">{{map.fieldDescription}}</div>
        <div class="locked-mapping" [matTooltip]="getCutToolTip(mapping[map.fieldName])"
             matTooltipClass="tTip">{{getCut(mapping[map.fieldName])}}</div>
        <uxpl-icon icon="pl-icon-password" height="18px" width="18px" class="map-icon"
                   matTooltip="This mapping is locked, see the contextual help for more details..."
                   matTooltipClass="tTip"></uxpl-icon>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="availableColumns" class="map-panel-container">
  <div class="map-section">
    {{ 'map.panel.label.required' | translate }} <span style="color: red;">*</span>
  </div>
  <ng-container
    *ngTemplateOutlet="mappingT,context:{mappings:requiredMappings.mappings, required: false}"></ng-container>
  <div class="map-section margintop">
    {{ 'map.panel.label.optional' | translate }}
  </div>
  <ng-container
    *ngTemplateOutlet="mappingT,context:{mappings:optionalMappings.mappings, required: false}"></ng-container>
  <uxpl-switch-input style='margin-top: 5px' [checked]="mapping.otherAttributes"
                     (uxplChange)="handleOtherAttributes($event)">
    <div class="all-att">{{ 'map.panel.label.required' | translate }}<span>
    <uxpl-icon icon="pl-icon-info" height="18px" width="18px" class="all-att-icon"
               matTooltip="This setting includes all of the attributes from the data in the analysis report. The attributes become available for filtering and other actions. "
               matTooltipClass="tTip"></uxpl-icon></span></div>
  </uxpl-switch-input>
  <!--<pre> {{mapping | json}}</pre>-->
</div>
