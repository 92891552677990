import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {NewAnalysisStepStatus} from '../../../models_ui/discover';
import {calculateColumns, convertColumnNameToLabel} from '../../../functions/analysis';
import {ColumnUI, PreviewUI} from '../../../models_ui/analysis';
import {CatalogService, Mapping} from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  constructor(private catalogServiceMS: CatalogService,
    private translationService: TranslationService,
  ) {
  }

  @Input() mapping: Mapping;
  @Input() selectedDataset: string;
  @Input() advancedMode: boolean;
  @Input() doAutoMap: boolean;
  @Input() hideAdvanced: boolean;
  @Input() preview: PreviewUI;

  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter();
  @Output() advance: EventEmitter<boolean> = new EventEmitter();

  previewColumns: ColumnUI[];

  previewOptions = [
    {label: 'Full data', value: 'full-data'},
    {label: 'Mapped data', value: 'mapped-data'}
  ];
  previewValue: string = this.previewOptions[0].value;
  hasAutoMapped: boolean;
  mappedColumns = []

  ngOnInit(): void {
    this.hasAutoMapped = Object.keys(this.mapping).length === 0 ? true : false;
    this.updatePreviewOptions();
    this.translationService.currentLang$.subscribe(() => {
      this.updatePreviewOptions();
    });
  }
  private updatePreviewOptions(): void {
    const full = this.translationService.translate('select.dataset.fullData')
    const mapped = this.translationService.translate('select.dataset.mappedData')

    this.previewOptions = [
      { label: full, value: 'full-data' },
      { label: mapped, value: 'mapped-data' }
    ];
  }

  public clearMap = (): void => {
    Object.keys(this.mapping).forEach(v => delete this.mapping[v]);
    this.mapping = {} as unknown as Mapping;
    this.calculatePreviewColumns()
  }

  private calculatePreviewColumns() {
    this.previewColumns = calculateColumns(this.previewValue === 'full-data' ? this.preview?.availableColumns : this.filterMappedColumns(), this.mappedColumns);
  }

  public updateStatus = (event): void => {
    if (event) {
      this.hasAutoMapped = event.isAutoMapped;
    }
    this.setMappedColumns();
    this.convertColumnLabels();
    // FIX for "Expression has changed after it was checked" (https://blog.angular-university.io/angular-debugging/)
    // Only send status update in the next JavaScript Cycle
    window.setTimeout(() => {
      this.status.emit(event);
    })
  }

  public toggleAdvanced = (event): void => {
    if (this.advancedMode !== event.detail.checked) {
      this.advance.emit();
    }
  }

  public handlePreviewColumns = (event): void => {
    this.previewValue = event.detail.value;
    this.convertColumnLabels()
  }

  private convertColumnLabels() {
    this.calculatePreviewColumns()
    // Set the header name to the label of the column
    this.previewColumns.map(c => c.headerName = convertColumnNameToLabel(c.field, this.preview))
  }

  private setMappedColumns() {
    this.mappedColumns = [];
    Object.keys(this.mapping).forEach((element) => {
      if (typeof (this.mapping[element]) === 'string') {
        this.mappedColumns.push(this.mapping[element]);
      }
    });
  }

  private filterMappedColumns = (): string[] => {
    // const mappedColumns = []
    const columnsForTable = this.preview?.availableColumns.filter(el => {
      return this.mappedColumns.indexOf(el) >= 0;
    });
    return columnsForTable;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.preview) {
      this.calculatePreviewColumns()
    }
    if (this.selectedDataset) {
      this.catalogServiceMS.getDataset(this.selectedDataset).subscribe(dataset => {
        dataset.schema.forEach(schema => {
          if (this.previewColumns) {
            this.previewColumns.forEach(pvc => {
              if (pvc.field === schema.field && schema.type?.toLowerCase() === 'timestamp') {
                pvc.isDate = true
              }
            })
          }
        })
      })
    }
  }
}
