import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { get } from 'lodash-es';
import { UxplPopup } from '@tibco/tc-web-components/dist/types/components/uxpl-popup/uxpl-popup';
import { Analysis } from '@tibco/discover-client-lib';
import { FilterMatchMode } from 'primeng/api';
import { RepositoryService } from 'src/app/service/repository.service';
import { AnalysisAction, RemoveAnalysisDialogComponent } from '../../remove-analysis-dialog/remove-analysis-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { GenericPopupComponent } from '../../generic-popup/generic-popup.component';
import { TranslationService } from 'src/app/service/translate.service';
import { AnalysisCacheAction, AnalysisCacheStatus, ReportStatusEnum } from 'src/app/models_ui/analysis';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetCacheStatusResponse } from 'src/app/models_ui/repository';
import { ProcessAnalysisReportComponent } from '../process-analysis-report/process-analysis-report.component';
import { ProcessAnalysisDialogCacheAlertComplianceComponent } from '../process-analysis-dialog-cache-alert-compliance/process-analysis-dialog-cache-alert-compliance.component';
import { DictionaryDialogComponent } from '../../common/dictionary-dialog/dictionary-dialog.component';

@Component({
  selector: 'process-analysis-table',
  templateUrl: './process-analysis-table.component.html',
  styleUrls: [
    '../../common/tibco-table.ux.css',
    './process-analysis-table.component.scss',
  ],
})
export class ProcessAnalysisTableComponent implements OnInit, OnChanges {
  constructor(private repositoryService: RepositoryService,
    private removeUserDialog: MatDialog,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    private translationService: TranslationService,

    ) {}

  @Input() processAnalyses: Analysis[];
  @Input() paFocus: string[];
  @Input() searchTerm: string;
  @Input() statusMap: { [key: string]: any };
  @Input() loading;

  @Output() caseActionSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAnalysis: EventEmitter<any> = new EventEmitter<any>();
  @Output() forceRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() analysisId: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('processAnalysisTab', { static: false }) processAnalysisTab;
  @ViewChild('deletePopup', { static: true })
  deletePopup: ElementRef<UxplPopup>;

  readonly NUMBER_OF_ITEMS_BEFORE_PAGINATION = 50;
  readonly MAX_TOOLTIP_LENGTH = 250;

  showPagination = false;
  cols: any[];
  popupX: string;
  popupY: string;
  expandedRows = {};
  showCompare = false;
  selectedAnalysis: Analysis[] = [undefined];
  paToActionName = '';
  paAction: string = undefined;
  paToArchive: any;
  latestMouseEvent: MouseEvent;
  actions = {};
  folders: string[] = [];
  unarchiveTooltip = 'Unarchive analysis';
  deleteTooltip = 'Delete analysis';
  archiveTooltip = 'Archive analysis';
  // Put the data in a data matrix (for optimmization, so no function calls from template)
  processAnalysesTableData: any[] = [];
  currentLanguage: string;
  dropEdit:string;
  dropRerun:string;
  dropAbort:string;
  dropChange:string;
  // private statusInitialSubscription: { [key: string]: Subscription } = {};
  // private statusFinalSubscription: { [key: string]: Subscription } = {};
  private statusCacheSubscription: { [key: string]: Subscription } = {};
  dialogRefActionsInfo: MatDialogRef<DictionaryDialogComponent, any>;

  ngOnInit() {
    this.currentLanguage = this.translationService.getCurrentLanguage();
    this.translationService.currentLang$.subscribe(() => {
      this.updateLabels();
      this.forceRefresh.emit(true);
      this.dropEdit = this.translationService.translate('process.analysis.dropmenu.edit');
      this.dropRerun = this.translationService.translate('process.analysis.dropmenu.rerun');
      this.dropChange = this.translationService.translate('process.analysis.dropmenu.change');
      this.dropAbort = this.translationService.translate('process.analysis.dropmenu.abort');
      const name = this.translationService.translate('process.analysis.table.header.name');
      const modifiedBy = this.translationService.translate('process.analysis.table.header.modifiedBy');
      const modifiedOn = this.translationService.translate('process.analysis.table.header.modifiedOn');
      const state = this.translationService.translate('process.analysis.table.header.status');

      this.cols = [
        {
          field: 'name',
          scrField: 'name',
          header: name,
          class: 'name-header',
          dataClass: ['actionable-data', 'name-data'],
        },
        /*{field: 'description', scrField: 'description', header: 'Description', class: 'description-header'},*/
        {
          field: 'modifiedBy',
          scrField: 'metadata.modifiedBy',
          header: modifiedBy,
          class: 'dates-header',
        },
        {
          field: 'modifiedOn',
          scrField: 'metadata.modifiedOn',
          header: modifiedOn,
          class: 'dates-header',
        },
        {
          field: 'state',
          scrField: 'metadata.status',
          header: state,
          class: 'status-header',
        },
      ];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateLabels();
    if (changes.searchTerm && this.searchTerm && this.processAnalysisTab) {
      this.processAnalysisTab.filterGlobal(
        changes.searchTerm?.currentValue,
        FilterMatchMode.CONTAINS
      );
    }
    if (this.searchTerm === '' && this.processAnalysisTab) {
      // A hack to reset the filter, since reset does not seem to do anything
      this.processAnalysisTab.filterGlobal(
        '***$$$',
        FilterMatchMode.NOT_EQUALS
      );
    }
    if (changes.processAnalyses && this.processAnalyses) {
      this.createTableData();
      // this.calculateActions()
      if (
        this.processAnalyses.length > this.NUMBER_OF_ITEMS_BEFORE_PAGINATION
      ) {
        this.showPagination = true;
      }
    }
  }
  /*
    public canEdit(rowData: any): boolean {
      return (rowData?.state?.toLowerCase() !== 'process mining');
    }

    private archiveCounter = 0

    public canArchive(rowData: any): boolean {
      console.log('Can archive.... ', this.archiveCounter++);
      return ((rowData?.state?.toLowerCase() !== 'process mining') && (rowData?.state?.toLowerCase() !== 'archived'));
    }

    public canDelete(rowData: any): boolean {
      return (rowData?.state?.toLowerCase() === 'archived');
    }

    public canOpenAnalysis(rowData: any): boolean {
      return (rowData?.state?.toLowerCase() === 'ready' || rowData?.state?.toLowerCase() === 'archived');
    }

    public canChangeTemplate(rowData: any): boolean {
      return (rowData?.state?.toLowerCase() === 'ready' || rowData?.state?.toLowerCase() === 'archived');
    }

    public isRunning(rowData: any): boolean {
      return (rowData?.state?.toLowerCase() === 'process mining');
    }*/
    openDialog(): void {
      let msg: string = this.translationService.translate('generic.confirm.message');
      if(this.paAction == 'archive'){
        msg = this.translationService.translate('process.analysis.archive.confirm.message');
      }
      const dialogRef = this.dialog.open(GenericPopupComponent, {
        width: '300px',
        data: {titolo: 'Conferma', 
          message: msg,
          buttonYes: this.translationService.translate('confirm'), 
          buttonNo: this.translationService.translate('cancel')
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          // Confirm
          this.handleActionMenu({ action: this.paAction, analysis: this.paToArchive });
        }
      });
    }
    
   
  public open(rowData: any) {
    this.openAnalysis.emit(rowData);
  }

  public edit(rowData: any) {
    this.caseActionSelect.emit({ analysis: rowData, name: 'edit' });
  }

  public regenerate(rowData: any) {
    this.caseActionSelect.emit({ analysis: rowData, name: 'rerun' });
  }

  public abort(rowData: any) {
    this.caseActionSelect.emit({ analysis: rowData, name: 'abort' });
  }

  public handleOptionSelected(rowData, event) {
    switch (event?.detail?.id) {
      case 'edit': {
        this.edit(rowData);
        break;
      }
      case 'rerun': {
        this.regenerate(rowData);
        break;
      }
      case 'abort': {
        this.abort(rowData);
        break;
      }
      case 'open': {
        this.open(rowData);
        break;
      }
      case 'change-template': {
        this.changeTemplate(rowData);
        break;
      }
    }
  }

  public dataAction(field: string, rowData: any) {
    switch (field) {
      case 'name': {
        this.open(rowData);
      }
    }
  }

  public changeTemplate(rowData: any) {
    this.caseActionSelect.emit({ analysis: rowData, name: 'change-template' });
  }

  // function to put the table data in an object
  private createTableData() {
    this.updateLabels()
    if (this.processAnalyses && this.cols) {
      this.processAnalysesTableData = [];
      this.processAnalyses.forEach((pa) => {
        const norPA: any = {
          id: pa.id,
          templateId: pa.template?.id,
          idNoVersion: pa.id,
          version: pa?.metadata?.version,
        };
        this.cols.forEach((col) => {
          norPA[col.field] = this.obtainTableData(pa, col.scrField);
        });

        // Cache
        let cacheStatus: AnalysisCacheStatus = AnalysisCacheStatus.NONE;
        let cacheAction: AnalysisCacheAction = AnalysisCacheAction.CACHE;
        let cacheLabel: string = this.translationService.translate('process.analysis.cache.label.generate');
        let cacheTooltip: string = this.translationService.translate('process.analysis.cache.tooltip');
        if(!pa.cache || !pa.cache.cacheStatus || pa.cache.cacheStatus == 'None'){
          cacheStatus = AnalysisCacheStatus.NONE;
          if(pa.template && pa.template.name == 'Conformance Checking'){
            cacheAction = AnalysisCacheAction.ALERT_COMPLIANCE;
          }else{
            cacheAction = AnalysisCacheAction.CACHE;
          }
          cacheLabel = this.translationService.translate('process.analysis.cache.label.generate');
          cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip');
        }else if(pa.cache.cacheStatus == "Creating"){
          cacheStatus = AnalysisCacheStatus.CREATING;
          cacheAction = AnalysisCacheAction.REGENERATE;
          cacheLabel = this.translationService.translate('process.analysis.cache.label.regenerate');
          cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.creating');
          if(pa.metadata.status != "Error" && pa.metadata.status != "Archived"){
            this.startGetCacheStatus(pa.id);
          }
        }else if(pa.cache.cacheStatus == "Failed" ){
          cacheStatus = AnalysisCacheStatus.FAILED;
          if(pa.template && pa.template.name == 'Conformance Checking'){
            cacheAction = AnalysisCacheAction.ALERT_COMPLIANCE;
          }else{
            cacheAction = AnalysisCacheAction.CACHE;
          }
          cacheLabel = this.translationService.translate('process.analysis.cache.label.generate');
          cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.failed');
        }else if(pa.cache.cacheStatus == "Completed"){
          cacheStatus = AnalysisCacheStatus.COMPLETED;
          cacheAction = AnalysisCacheAction.UNCACHE;
          cacheLabel = this.translationService.translate('process.analysis.cache.label.uncache');
          cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.ready');
        }
        norPA.cacheStatus = cacheStatus;
        norPA.cacheAction = cacheAction;
        norPA.cacheTooltip = cacheTooltip;
        norPA.cacheLabel = cacheLabel;

        // Report
        norPA.reportFound = false;
        if(pa.report && Array.isArray(pa.report) && pa.report.length > 0){
          let reportFound = pa.report.find((el) => el.status == ReportStatusEnum.COMPLETED);
          if(reportFound){
            norPA.reportFound = true;
          }
        }

        // Adding rules
        norPA.canArchive =
          norPA?.state?.toLowerCase() !== 'process mining' &&
          norPA?.state?.toLowerCase() !== 'archived';
        norPA.canDelete = norPA?.state?.toLowerCase() === 'archived';
        norPA.canOpenAnalysis =
          norPA?.state?.toLowerCase() === 'ready' ||
          norPA?.state?.toLowerCase() === 'archived';
        norPA._analysisOptions = this.calculateRowActions(norPA);

        // Set the class
        norPA.ngClass = this.getNgClass('state', norPA);
        // Set the tooltip
        norPA.toolTip = this.getToolTip('state', norPA);
        // Set the folder
        norPA.folder = pa.folder;
        // Add folder in array
        if (!this.folders.includes(pa.folder)) {
          this.folders.push(pa.folder);
        }

        this.processAnalysesTableData.push(norPA);
      });
      if (this.paFocus && this.paFocus.length > 0) {
        // move element to the top
        this.paFocus.forEach((paF) => {
          const paIx = this.processAnalysesTableData.findIndex(
            (pa) => pa.idNoVersion === paF
          );
          const id = this.processAnalysesTableData[paIx].id;
          this.processAnalysesTableData.unshift(
            this.processAnalysesTableData.splice(paIx, 1)[0]
          );
          this.expandRowById(id, true);
        });
      }
    }
  }
  updateLabels() {
    
     this.dropEdit = this.translationService.translate('process.analysis.dropmenu.edit');
     this.dropRerun = this.translationService.translate('process.analysis.dropmenu.rerun');
     this.dropChange = this.translationService.translate('process.analysis.dropmenu.change');
     this.dropAbort = this.translationService.translate('process.analysis.dropmenu.abort');
  //   this.actions = [
  //     { id: 'edit', label: this.translationService.translate('process.analysis.dropmenu.edit') },
  //     { id: 'rerun', label: this.translationService.translate('process.analysis.dropmenu.rerun') },
  //     { id: 'change-template', label: this.translationService.translate('process.analysis.dropmenu.change') },
  //     { id: 'abort', label: this.translationService.translate('process.analysis.dropmenu.abort') }
  // ];
  }
  calculateRowActions(norPA: any) {
    // analysis options
    this.currentLanguage = this.translationService.getCurrentLanguage();

    const analysisOpts = { options: [] };
    if (norPA?.state?.toLowerCase() !== 'archived') {
      if (norPA?.state?.toLowerCase() !== 'process mining') {
        analysisOpts.options.push({ id: 'edit', label: this.translationService.translate('process.analysis.dropmenu.edit') });
      }
      if (norPA?.state?.toLowerCase() !== 'process mining') {
        analysisOpts.options.push({
          id: 'rerun',
          label: this.dropRerun,
        });
      }
      if (norPA?.state?.toLowerCase() === 'ready') {
        analysisOpts.options.push({
          id: 'change-template',
          label: this.dropChange,
        });
      }
      if (norPA?.state?.toLowerCase() === 'process mining') {
        analysisOpts.options.push({
          id: 'abort',
          label: this.dropAbort,
        });
      }
    }
    return analysisOpts;
  }

  getProcessAnalysisById(id: string) {
    return this.processAnalyses.find((pa) => pa.id === id);
  }

  private obtainTableData(rowData: any, col: any): string {
    // When there is no modified data use the created data
    if (col === 'metadata.modifiedBy' && get(rowData, col) === '') {
      col = 'metadata.createdBy';
    }
    if (col === 'metadata.modifiedOn' && get(rowData, col) === 0) {
      col = 'metadata.createdOn';
    }
    return get(rowData, col);
  }

  private getToolTip(field: any, data: any) {
    const re = '';
    if (field === 'state') {
      const cellData = get(data, field);
      if (cellData.trim() === 'Not ready') {
        // TODO: Get the proper error message for an analysis in error state
        // @ts-ignore
        const processA = this.getProcessAnalysisById(data.id);
        // if (processA.metadata.message) {
        //   re = processA.metadata.message;
        //   if (re.length > this.MAX_TOOLTIP_LENGTH) {
        //     re = re.substr(0, this.MAX_TOOLTIP_LENGTH) + '... (see details for full error message)';
        //   }
        // }
      }
    }
    return re;
  }

  private getNgClass(field, data) {
    let re = null;
    if (field === 'state') {
      const cellData = get(data, field).trim();
      // re = 'neutral';
      if (cellData === 'Not ready' || cellData === 'Error') {
        re = '#F9E1E4';
      }
      if (cellData === 'Ready') {
        re = '#E1F7EB';
      }
      if (
        cellData === 'Archived' ||
        cellData === 'Completed' ||
        cellData === 'Purged'
      ) {
        re = '#F4F4F4';
      }
      if (cellData === 'Added') {
        re = '#E0F0F9';
      }
      if (cellData === 'Created') {
        re = '#E0F0F9';
      }
      if (cellData === 'Process mining') {
        re = '#FEF7EA';
      }
    }
    return re;
  }

  unarchive(rowData: Analysis, event) {
    console.log(rowData, event);
    this.repositoryService.unarchive(rowData).subscribe(
      () => {
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.snackBar.unarchive"), this.translationService.translate("process.analysis.snackBar.close"),'success');

        this.forceRefresh.emit(true);
      },
      (_error) => {
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.snackBar.error.unarchive"), this.translationService.translate("process.analysis.snackBar.close"),'error');
      }
    );
  }
  archive(rowData: Analysis) {
    this.repositoryService.archive(rowData).subscribe(
      () => {
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.snackBar.archive"), this.translationService.translate("process.analysis.snackBar.close"),'success',5000);
        this.forceRefresh.emit(true);
      },
      (_error) => {
        this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.snackBar.error.archive"), this.translationService.translate("process.analysis.snackBar.close"),'error');
      }
    );
  }
  openRemoveAnalysisDialog(rowData:any,action: string) {


    const dialogRef = this.removeUserDialog.open(RemoveAnalysisDialogComponent, {
      width: '250px',
       data: {
        paToArchive: rowData,
        paAction: action,
       },
    });
    dialogRef.componentInstance.analysisDeleted.subscribe(
       (analysisDeleted: AnalysisAction ) => {
        
         // Remove the deleted dataset from the 
         if(analysisDeleted){

          this.caseActionSelect.emit({
            name: analysisDeleted.analysisOnAction,
             analysis: analysisDeleted.analysisId,
          });
         }
       }
    );
    
  }

  action(analysis: Analysis, action: string, event) {
    this.paAction = action;
    this.paToArchive = analysis;
    this.popupX = event.pageX - 340 + 'px';
    this.popupY = event.pageY - 15 + 'px';
    //this.deletePopup.nativeElement.show = true;
    this.openDialog()
  }

  public expandRow(name: string, value: boolean) {
    const paT = this.processAnalyses.find((v) => v.name === name);
    if (paT && paT.id) {
      this.expandedRows[paT.id] = value;
    }
  }

  public expandRowById(id: string, value: boolean) {
    const paT = this.processAnalyses.find((v) => v.id === id);
    if (paT && paT.id) {
      this.expandedRows[paT.id] = value;
    }
  }

  handleActionMenu(event: any) {
    if (event?.action) {
      this.archive(this.paToArchive)
    }
    this.paToArchive = undefined;
    this.paAction = undefined;
  }

  handleOptionClick(mEvent: MouseEvent) {
    this.latestMouseEvent = mEvent;
  }

  handleDoCompare(analysis: Analysis) {
    this.selectedAnalysis = [analysis, undefined];
    this.toggleCompare();
  }

  toggleCompare() {
    this.showCompare = !this.showCompare;
  }

  dragStartAnalysis(analysisId: string) {
    localStorage.setItem('dragStartAnalysisId', analysisId);
  }

  handleCache(analysisId: string, cacheAction: AnalysisCacheAction){
    switch (cacheAction) {
      case AnalysisCacheAction.CACHE:
        this.repositoryService.createCache(analysisId).subscribe(() => {
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheStatus = AnalysisCacheStatus.CREATING;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.REGENERATE;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.creating');
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.regenerate');
          this.startGetCacheStatus(analysisId);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.success"), this.translationService.translate("process.analysis.snackBar.close"),'success');
        }, (error) => {
          console.error(error);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
        });
        break;
      case AnalysisCacheAction.REGENERATE:
        this.repositoryService.createCache(analysisId).subscribe(() => {
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheStatus = AnalysisCacheStatus.CREATING;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.REGENERATE;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.creating');
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.regenerate');
          this.startGetCacheStatus(analysisId);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.success"), this.translationService.translate("process.analysis.snackBar.close"),'success');
        }, (error) => {
          console.error(error);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
        });
        break;
      case AnalysisCacheAction.UNCACHE:
        this.repositoryService.deleteCache(analysisId).subscribe(() => {
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheStatus = AnalysisCacheStatus.NONE;
          const currentAnalysis = this.processAnalyses.find((el) => el.id == analysisId);
          if(currentAnalysis.template && currentAnalysis.template.name == 'Conformance Checking'){
            this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.ALERT_COMPLIANCE;
          }else{
            this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.CACHE;
          }
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip');
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.generate');
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.delete.message.success"), this.translationService.translate("process.analysis.snackBar.close"),'success');
        }, () => {
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.delete.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
        });
        break;
      case AnalysisCacheAction.ALERT_COMPLIANCE:
        this.openCacheAlertComplianceDialog(analysisId);
    }
  }

  startGetCacheStatus(analysisId: string): void {
    if (this.statusCacheSubscription[analysisId]) {
      return;
    }
    this.statusCacheSubscription[analysisId] = interval(5000).pipe(
      switchMap(() => this.repositoryService.getCacheStatus(analysisId))
    ).subscribe((response: GetCacheStatusResponse) => {
      if (response.status === 'Completed') {
        this.stopGetCacheStatus(analysisId, 'completed');
      }else if(response.status === 'Failed'){
        this.stopGetCacheStatus(analysisId, 'failed');
      }
    });
  }

  stopGetCacheStatus(analysisId: string, status: string): void {
    if (this.statusCacheSubscription[analysisId]) {
      this.statusCacheSubscription[analysisId].unsubscribe();
      this.processAnalysesTableData.find((el) => el.id == analysisId).cacheStatus = status;
      if(status == "Failed"){
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.CACHE;
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.failed');
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.generate');
      }else{
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.UNCACHE;
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.ready');
        this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.uncache');
      }
    }
  }

  openActionsInfo(){
    let rows = [{
      "title": this.translationService.translate('process.analysis.actions.info.icon.open.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.open.description'),
      "iconType": "material",
      "icon": "play_circle",
      "iconClass": "material-symbols-rounded primary-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.open.fast.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.open.fast.description'),
      "iconType": "material",
      "icon": "offline_bolt",
      "iconClass": "material-symbols success-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.cache.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.cache.description'),
      "iconType": "material",
      "icon": "speed",
      "iconClass": "material-symbols primary-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.cache.inprogress.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.cache.inprogress.description'),
      "iconType": "material",
      "icon": "speed",
      "iconClass": "material-symbols",
      "loading": true
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.cache.ready.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.cache.ready.description'),
      "iconType": "material",
      "icon": "speed",
      "iconClass": "success-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.cache.failed.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.cache.failed.description'),
      "iconType": "material",
      "icon": "speed",
      "iconClass": "error-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.report.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.report.description'),
      "iconType": "svg",
      "icon": "report-icon",
      "iconClass": "primary-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.report.success.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.report.success.description'),
      "iconType": "svg",
      "icon": "report-icon",
      "iconClass": "success-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.archive.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.archive.description'),
      "iconType": "material",
      "icon": "archive",
      "iconClass": "material-symbols-rounded primary-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.unarchive.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.unarchive.description'),
      "iconType": "material",
      "icon": "unarchive",
      "iconClass": "material-symbols-rounded primary-color",
      "loading": false
    },{
      "title": this.translationService.translate('process.analysis.actions.info.icon.delete.title'),
      "description": this.translationService.translate('process.analysis.actions.info.icon.delete.description'),
      "iconType": "material",
      "icon": "delete",
      "iconClass": "material-symbols-rounded error-color",
      "loading": false
    }];
    this.dialogRefActionsInfo = this.dialog.open(DictionaryDialogComponent, {
      width: '420px',
      height: 'auto',
      data: {
        title: this.translationService.translate('process.analysis.action.info.dialog.title'),
        rows: rows
      },
      hasBackdrop: true,
      backdropClass: 'action-info-backdrop'
    });
    this.dialogRefActionsInfo.afterClosed().subscribe(() => {
      this.dialogRefActionsInfo = undefined;
    });
  }

  openReport(analysisId: string){
    this.openReportsDialog(analysisId);
  }

  openReportsDialog(analysisId: string) {
    const language = localStorage.getItem('language');
    const data: any = {
      "language": language,
      "analysis": this.processAnalyses.find(obj => obj.id === analysisId)
    }
    const dialogRef = this.dialog.open(ProcessAnalysisReportComponent, {
      width: '420px',
      height: 'auto',
      data: data,
      panelClass: 'reports-panel'
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  openCacheAlertComplianceDialog(analysisId: string) {
    const dialogRef = this.dialog.open(ProcessAnalysisDialogCacheAlertComplianceComponent, {
      width: '320px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.repositoryService.createCache(analysisId).subscribe(() => {
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheStatus = AnalysisCacheStatus.CREATING;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheAction = AnalysisCacheAction.REGENERATE;
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheTooltip = this.translationService.translate('process.analysis.cache.tooltip.creating');
          this.processAnalysesTableData.find((el) => el.id == analysisId).cacheLabel = this.translationService.translate('process.analysis.cache.label.regenerate');
          this.startGetCacheStatus(analysisId);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.success"), this.translationService.translate("process.analysis.snackBar.close"),'success');
        }, (error) => {
          console.error(error);
          this.snackBarService.openSnackBar(this.translationService.translate("process.analysis.cache.generate.message.error"), this.translationService.translate("process.analysis.snackBar.close"),'error');
        });
      }
    });
  }
}