<div class="activation-overlay" *ngIf="processing">
  <!-- <uxpl-spinner message="Processing ..."></uxpl-spinner> -->
  <pulse-loading></pulse-loading>
</div>
<div class="fixActionRow">
  <div class="dialog-header-content mat-dialog-title">
    <div class="connection-dialog-title">Edit connection</div>
    <uxpl-icon height="36px" icon="pl-icon-close" size="20px" (click)="close()"></uxpl-icon>
  </div>
  <div mat-dialog-content class="dialog-content dialog-content-borders">
      <uxpl-notification *ngIf="notification" [type]="notification?.type" [message]="notification?.message" (closed)="handleCloseNotification()"></uxpl-notification>
      <connection-parameters-form *ngIf="isConnectionParams" [connection]="connection" (triggerNotification)="notify($event)" (validConnectionParams)="handleParamsSelection($event)" (processing)="handleProcessingChange($event)"></connection-parameters-form>
      <connection-created-form *ngIf="isConnectionSaved" mode="update"></connection-created-form>
  </div>
  <div>
    <div mat-dialog-actions>
      <uxpl-button *ngIf="!isConnectionSaved" type="secondary" (clicked)="close()"><div class="dialog-action-text">Cancel</div></uxpl-button>
      <uxpl-button id="connection-save-button" class="save-button" [ngClass]="{'disabled': !isCreatable}" *ngIf="!isConnectionSaved" [disabled]="!isCreatable" type="primary" (clicked)="save()"><div class="dialog-action-text">Save</div></uxpl-button>
      <uxpl-tooltip position="Left" right="104px" bottom="-15px" class="save-disabled-tooltip" ><div>You need to test the connection before saving</div></uxpl-tooltip>
      <uxpl-button *ngIf="isConnectionSaved" type="secondary" (clicked)="handleConnectionsClick()"><div class="dialog-action-text">Go to connections</div></uxpl-button>
      <uxpl-button *ngIf="isConnectionSaved" type="primary" (clicked)="handleNewDatasetClick()"><div class="dialog-action-text">Create a dataset</div></uxpl-button>
    </div>
  </div>
</div>
