<!--Subheader-->
<div class="page-container container-flex-fluid">
    <div class="flex">
        <div class="flex-item">
            <div class="data-title-container">
                <div class="data-title">
                    <div class="page-title">{{ 'control.panel.users.title' | translate }}</div>
                </div>
                <div class="data-title">
                    <div class="data-title">
                        <button mat-stroked-button color="primary"
                            (click)="refresh()"
                            width="100">
                            <mat-icon class="material-symbols-rounded">refresh</mat-icon>
                            {{ 'user.manager.button.refresh' | translate }}
                        </button>
                        <button mat-flat-button color="primary"
                            routerLink="/discover/control-panel/add-user">
                            <mat-icon class="material-symbols-rounded">person_add</mat-icon>
                            {{ 'user.manager.button.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-item">
            <div id="icon-sidebar-container" class="flex">
                <div id="icon-sidebar-side" class="flex-item">
                    <icon-sidebar [iconSidebarData]="iconSidebarData"></icon-sidebar>
                </div>
                <div id="icon-sidebar-content" class="flex-item">
                    <mat-card>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-card-content>
                            <table mat-table class="width-xs-10"
                                [dataSource]="dataSource">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell
                                        *matHeaderCellDef>{{ 'user.manager.column.name' | translate }}</th>
                                    <td mat-cell
                                        *matCellDef="let user">{{ user.displayName }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="email">
                                    <th mat-header-cell
                                        *matHeaderCellDef>{{ 'user.manager.column.email' | translate }}</th>
                                    <td mat-cell
                                        *matCellDef="let user">{{ user.email }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="role">
                                    <th mat-header-cell
                                        *matHeaderCellDef>{{ 'user.manager.column.role' | translate }}</th>
                                    <td mat-cell
                                        *matCellDef="let user">{{ user.role }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        *matHeaderCellDef>
                                        {{ 'user.manager.column.actions' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let user">
                                        <div class="actions-wrap">
                                            <button mat-stroked-button color="primary"
                                                (click)="openChangeRoleDialog(user.id)" [disabled]="userinfo.id == user.id">
                                                {{ 'user.manager.column.role' | translate }}
                                            </button>
                                            <button mat-stroked-button color="error"
                                                (click)="openRemoveUserDialog(user.id)" [disabled]="userinfo.id == user.id">
                                                <mat-icon class="material-symbols-rounded">delete</mat-icon>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>
                        
                                <tr mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>