import { Component, Input, OnInit } from '@angular/core';

export interface IconSidebar {
  main?: IconSidebarRow[]
  footer?: IconSidebarRow[]
}

export interface IconSidebarRow {
  label: string
  icon: string
  link: string
  routerLinkActiveExact: boolean 
}

@Component({
  selector: 'icon-sidebar',
  templateUrl: './icon-sidebar.component.html',
  styleUrls: ['./icon-sidebar.component.css']
})
export class IconSidebarComponent implements OnInit {

  @Input() iconSidebarData: IconSidebar;

  constructor() { }

  ngOnInit(): void {
  }

}
