import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {take} from "rxjs/operators";

@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.css']
})
export class NoAccessComponent implements OnInit{
  message: string;

  constructor(private route: ActivatedRoute) {
  }

  goSubscription() {
    // TODO: Make sure that this is done for the US when based in the US (this only works for europe)
    window.open('https://eu.account.cloud.tibco.com/manage/subscription', '_blank');
  }

  goContact() {
    window.location.href = encodeURI('mailto:tibcolabs@tibco.com?subject=Request Access to Project Discover&body=Dear TIBCO Labs,')
  }

  ngOnInit(): void {
    console.log('map: ', this.route.snapshot?.paramMap)
    this.message = this.route.snapshot?.paramMap?.get('message')
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.message) {
        this.message = params.message;
      }
    });
  }
}
