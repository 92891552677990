<div id="edit-org" class="card-bg">
    <div class="flex">
        <div class="flex-item width-xs-10 width-sm-8 width-md-6 width-lg-5 width-xl-4">
            <form [formGroup]="editOrgFormGroup" (ngSubmit)="onSubmit()">
                <mat-card>
                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                    <mat-card-title>
                        {{'administration.icon.side.menu.edit.organization' | translate}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="container-flex-fluid">
                            <div class="flex">
                                <div class="flex-item width-xs-10">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'modifyeOrg.name' | translate }}</mat-label>
                                        <input matInput formControlName="name">
                                        <mat-error *ngIf="editOrgFormGroup.get('name').hasError('required')">
                                            {{ 'modifyeOrg.nameRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item width-xs-10">
                                    
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'modifyeOrg.description' | translate }}</mat-label>
                                        <input matInput formControlName="description">
                                        <mat-error *ngIf="editOrgFormGroup.get('description').hasError('required')">
                                            {{ 'modifyeOrg.descriptionRequired' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-flat-button color="primary" type="submit" [disabled]="editOrgFormGroup.invalid">{{"administration.icon.side.menu.edit.organization" | translate}}</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
    </div>
</div>
