import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReleaseNote } from 'src/app/models_ui/users';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { TranslationService } from 'src/app/service/translate.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {
  isLoading: boolean = false;
  ready: boolean = false;
  language: string;
  releaseVersion: string;
  releaseNotes: ReleaseNote;
  releaseDate: string;
  safeHtmlContent: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<ReleaseNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private sanitizer: DomSanitizer,
    private snackBarService: SnackbarService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.usersService.getLastReleaseNote().subscribe(
      (response: any) => {
        this.releaseNotes = response;
        this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.releaseNotes.content)
        this.ready = true;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.openSnackBar(this.translationService.translate('generic.message.api.error'), this.translationService.translate('close'), 'error');
        console.error("getLastReleaseNote error: ", error);
      } 
    );
  }

}
