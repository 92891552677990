<ng-template #tmplNode let-node="node">
  <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id" >
    <div class="menu-item-drag" [@blinking]="animate(node.id) ? 'blink' : 'normal'" >
      <div  class="left-drag-section">
        <div class="drag-icon">
          <uxpl-icon height="24px" width="24px" icon="pl-icon-drag"></uxpl-icon>
        </div>
        <uxpl-icon *ngIf='allowIcons' class="menu-icon" height="24px" width="24px" icon="{{node.icon}}" [ngClass]="{ 'hide-drag-label' : !node.enabled}"></uxpl-icon>
        <div class="drag-label" [ngClass]="{ 'hide-drag-label' : !node.enabled, 'default-label': node.isDefault}">{{node.label}} <span *ngIf="node.isDefault">(Default)</span></div>
      </div>
      <div  class="right-drag-section">
        <!--<uxpl-icon *ngIf="node.isDefault" class="right-drag-button defIcon" height="20px" width="20px" icon="pl-icon-checkmark"
                   ></uxpl-icon>-->
        <uxpl-icon class="right-drag-button" height="20px" width="20px" icon="pl-icon-edit"
                   (click)="toggleEditMenuPopup(node, $event)"  matTooltip="edit" matTooltipClass="tTip"></uxpl-icon>
        <uxpl-icon *ngIf="node.enabled" class="right-drag-button" height="20px" width="20px" icon="pl-icon-show"
                   (click)="toggleVisible(node)" matTooltip="disable" matTooltipClass="tTip"></uxpl-icon>
        <uxpl-icon *ngIf="!node.enabled" class="right-drag-button" height="20px" width="20px" icon="pl-icon-hide"
                   (click)="toggleVisible(node)" matTooltip="enable" matTooltipClass="tTip"></uxpl-icon>
        <uxpl-icon class="right-drag-button" height="20px" width="20px" icon="pl-icon-remove"
                   (click)="deleteMenuItem(node)" matTooltip="delete" matTooltipClass="tTip"></uxpl-icon>
      </div>
    </div>
    <div class="node-children" cdkDropList [cdkDropListData]="node.child" [id]="node.id"
         [cdkDropListConnectedTo]="dropTargetIds"
         (cdkDropListDropped)="drop($event, true)" [cdkDropListSortingDisabled]="true">
      <!--suppress TypeScriptUnresolvedVariable -->
      <div *ngFor="let child of node.child" cdkDrag [cdkDragData]="child.id" (cdkDragMoved)="dragMoved($event, true)">
        <ng-container *ngTemplateOutlet="tmplNode,context:{node:child}"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div cdkDropList *ngIf="menuNodesIN" [cdkDropListData]="menuNodesIN" [id]="'main'" [cdkDropListConnectedTo]="dropTargetIds"
     (cdkDropListDropped)="drop($event, false)" [cdkDropListSortingDisabled]="true" class="drag-drop-container">
  <div *ngFor="let node of menuNodesIN" cdkDrag [cdkDragData]="node.id" (cdkDragMoved)="dragMoved($event, false)">
    <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>
  </div>
</div>

<div id="popup-host" [ngStyle]="{ 'top': popupY , 'left': popupX}" class="popup-wrapper">
  <uxpl-popup #popup id="popup" slot="popup" x-position="after" y-position="below" content-id="my-edit-popup" max-width="350px"
              [maxHeight]="POPUP_HEIGHT + 'px'" >
    <template-edit-menu-popup id="my-edit-popup" [menuNodeItem]="currentEditNode" style="width: 350px;" [analyticTabs]="analyticTabs" [allowIcons]="allowIcons"
                              (menuItem)="updateItem($event)"></template-edit-menu-popup>
  </uxpl-popup>
</div>

<!--
<hr />
<p><strong>current drag drop action:</strong></p>
<pre>{{dropActionTodo|json}}</pre>
<hr />
<p><strong>currentEditNode:</strong></p>
<pre>{{currentEditNode|json}}</pre>
<hr />
<p><strong>allowNesting</strong></p>
<pre>{{allowNesting|json}}</pre>
<hr />
<p><strong>tree data</strong></p>
<pre>{{menuNodesIN|json}}</pre>
<hr/>
<p><strong>UI Menu </strong></p>
<pre>{{menuNodesIN|json}}</pre>
-->
