<div>
  <div id="{{listId}}" cdkDropList class="menu-list"
  (cdkDropListDropped)="tableFieldsDrop($event)" 
  [cdkDropListConnectedTo]="cdkDropListConnectedTo" 
  [cdkDropListData]="fieldList"
  *ngIf="fieldList && fieldList.length>0">
    <div class="menu-item" *ngFor="let tf of fieldList; index as i" cdkDrag (click)="clickMenuItem(i)">
      <div class="name">
        <div style="width: 24px; height: 24px;" class="drag-handler" cdkDragHandle>
          <svg fill="currentColor" viewBox="0 0 24 24" id="pl-icon-drag" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M11 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3-9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
        </div>
        <div class="title">{{tf[labelAttr]}}</div>
      </div>
      <div class="action-btn">
        <div style="display: flex;">
          <uxpl-icon class="right-drag-button" height="20px" width="20px" icon="pl-icon-edit" (click)="editTableField($event, i)"></uxpl-icon>
          <uxpl-icon class="right-drag-button" style="margin-left: 4px;" height="20px" width="20px" icon="pl-icon-remove" (click)="deleteTableField(i)"></uxpl-icon>
        </div>
      </div>
      <div class="placeholder" *cdkDragPlaceholder></div>
      <div class="menu-item preview {{previewClass}}" *cdkDragPreview>
        <div class="name">
          <uxpl-icon height="24px" width="24px" icon="pl-icon-drag" class="drag-handler"></uxpl-icon>
          <div class="title">{{tf[labelAttr]}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
