import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NewAnalysisStepStatus} from 'src/app/models_ui/discover';
import { PreviewUI} from '../../../models_ui/analysis';
import {Router} from '@angular/router';
import { Dataset } from '@tibco/discover-client-lib';
import { FilterMatchMode } from "primeng/api";
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'select-dataset',
  templateUrl: './select-dataset.component.html',
  styleUrls: ['../../common/tibco-table.ux.css', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss', '../../../routes/manage-datasets/manage-datasets.component.css', './select-dataset.component.css', '../../process-analysis/process-analysis-table/process-analysis-table.component.scss']
})
export class SelectDatasetComponent implements OnInit, AfterViewInit{

  @Input() dataset: string;
  @Input() availableDatasets: Dataset[];
  @Input() preview: PreviewUI;
  @Input() isDataPreviewError;

  @Output() status: EventEmitter<NewAnalysisStepStatus> = new EventEmitter<NewAnalysisStepStatus>();
  @Output() selectedDataset: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('datasetsTable', {static: false}) dt;
  @ViewChildren('datasetRow') selectedDtrs: QueryList<ElementRef>;
  cols: any[]; // Define cols as an array
  currentLanguage: string;
  placeholderSearch: string;
  constructor(private router: Router,
    private translationService: TranslationService,

  ) {}
  ngOnInit(): void {
    this.updateTableHeaders();
    this.placeholderSearch = this.translationService.translate('select.dataset.placeholder.search')
    this.translationService.currentLang$.subscribe(() => {
      this.updateTableHeaders();
      this.currentLanguage = this.translationService.getCurrentLanguage();
      this.placeholderSearch = this.translationService.translate('select.dataset.placeholder.search')

    });
    }

  ngAfterViewInit(): void {


    // subscribe to view changes so that if a row was previously selected we can scroll to it
    this.selectedDtrs.changes.subscribe((dtr:QueryList<ElementRef>) => {
      if (this.dataset) {
        const selectedDtr = dtr?.find(dr => {
          return dr?.nativeElement?.id === ('dsr-' + this.dataset);
        })
        selectedDtr?.nativeElement?.scrollIntoView({block: "center"});
      }
    } )
  }

  // cols = [
  //   {field: 'name', header: this.translationService.translate("select.dataset.header.name")},
  //   {field: 'metadata.createdOn', header: this.translationService.translate("select.dataset.header.createOn")},
  //   {field: 'metadata.modifiedOn', header: this.translationService.translate("select.dataset.header.modifiedOn")}
  // ];
  searchTerm: string;

  public handleSelection(dataset: Dataset) {
    this.dataset = dataset.id;
    this.selectedDataset.emit(dataset.id);
    this.updateStatus(true);
  }
  private updateTableHeaders(): void {
    this.cols = [
      { field: 'name', header: this.translationService.translate("select.dataset.header.name") },
      { field: 'metadata.createdOn', header: this.translationService.translate("select.dataset.header.createOn") },
      { field: 'metadata.modifiedOn', header: this.translationService.translate("select.dataset.header.modifiedOn") }
    ];
  }

  handleSearch = ($event): void => {
    this.searchTerm = $event.detail.value;
    this.dt.filterGlobal(this.searchTerm, FilterMatchMode.CONTAINS);
    if (this.searchTerm === '' && this.dt) {
      // A hack to reset the filter, since reset does not seem to do anything
      this.dt.filterGlobal('***$$$', FilterMatchMode.NOT_EQUALS)
    }
  }

  private updateStatus = (status: boolean): void => {
    const stepStatus = {
      step: 'dataset',
      completed: status
    } as NewAnalysisStepStatus;
    this.status.emit(stepStatus);
  }

  isSelected(dataset: Dataset) {
    return this.dataset === dataset.id;
  }

  goDataSet() {
    this.router.navigate(['/discover/dataset']);
  }

}
