<div fxLayout="row" fxLayoutAlign="end center" style="z-index: 200;">
  <div (click)="buttonClicked(button)" *ngFor="let button of primaryButtons"
       class="action-button-base action-button-small">{{button.label}}{{button.addition}}</div>
  <div [hidden]="!showAdditionalButtons">
    <icon-button (click)="openMenu()" [matMenuTriggerFor]="appMenu" [text]="'More'"
                 [iconLocation]="'assets/images/svg/down.svg'"></icon-button>
    <mat-menu #appMenu="matMenu" xPosition="before">
      <div (click)="buttonClicked(aButton)" *ngFor="let aButton of additionalButtons"
           class="action-button-base action-button-small">{{aButton.label}}{{aButton.addition}}</div>
    </mat-menu>
  </div>
  <div [hidden]="!showAdminButtons">
    <icon-button [matMenuTriggerFor]="appAdminMenu" [text]="'Admin'"
                 [iconLocation]="'assets/images/svg/down.svg'"></icon-button>
    <mat-menu #appAdminMenu="matMenu" xPosition="before">
      <div (click)="buttonClicked(aButton)" *ngFor="let aButton of adminButtons"
           class="action-button-base action-button-small">{{aButton.label}}{{aButton.addition}}</div>
    </mat-menu>
  </div>
</div>

