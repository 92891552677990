import {DateTime} from 'luxon';
import {InternalMessageService} from '../service/internal-message.service';

export type Level = 'INFO' | 'WARNING' | 'ERROR';

export function notifyUser(level: Level, message: string, msService: InternalMessageService) {
  message = getShortMessage(message, 58)
  if(level === 'INFO') {
    msService.sendMessage('news-banner.topic.message', message);
  } else {
    const mes = {
      type: level,
      message,
    };
    msService.sendMessage('news-banner.topic.message', 'MESSAGE:' + JSON.stringify(mes));
  }
}

export function getRelativeTime(millisec: number) {
  const diff = new Date().getTime() - new Date(millisec).getTime();
  if (diff < 60000) {
    return 'Less than a minute ago...'
  }
  return millisec ? DateTime.fromMillis(millisec).toRelative() : '';
}


export function getShortMessage(message: string, shortLength = 25) {
  if (message) {
    if (message.length > shortLength) {
      return message.substring(0, shortLength) + '...';
    } else {
      return message;
    }
  }
  return '';
}
