<h2 mat-dialog-title>{{ 'changeRole.title' | translate }}</h2>
<mat-dialog-content>
  <div class="actions-container">
    <mat-radio-group [(ngModel)]="selectedRole">
      <div class="radio-button-container">
        <mat-radio-button 
          color="primary"
          class="radio-button"
          *ngFor="let role of roles"
          [value]="role"
          [checked]="role == currentRole"
        >
          {{ role }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="btn-container">
    <button mat-button (click)="onCancelClick()">{{ 'changeRole.button.cancel' | translate }}</button>
    <button
      mat-button
      (click)="onSaveClick()"
      [disabled]="selectedRole === currentRole"
    >
    {{ 'changeRole.button.save' | translate }}
    </button>
  </div>
</mat-dialog-actions>
