import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NavMenu} from '@tibco/tc-web-components/dist/types/models/leftNav';
import {
  SpotfireDocument,
  SpotfireFilter,
  SpotfireFilterSetting,
  SpotfireViewerComponent
} from '@tibcosoftware/spotfire-wrapper';
import {OauthService} from 'src/app/service/oauth.service';
import {UxplLeftNav} from '@tibco/tc-web-components/dist/types/components/uxpl-left-nav/uxpl-left-nav';
import {
  convertDateFromLocale,
  START_NAME,
  STOP_NAME,
  transformMapping
} from '../../../functions/analysis';
import {StartStop} from 'src/app/models_ui/analysis';
import {CatalogService, VisualisationService, Mapping, TypeValue} from '@tibco/discover-client-lib';
import { TranslationService } from 'src/app/service/translate.service';
import { environment } from 'src/environments/environment';

// TODO: Use these interfaces till nicolas de roche has changed his component
export interface MySpotfireFilter {
  filteringSchemeName: string;
  dataTableName: string;
  dataColumnName: string;
  filterType: string | any;
  filterSettings: SpotfireFilterSetting; // | MySpotfireFilterSetting[];
}

@Component({
  selector: 'data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['./data-preview.component.css']
})
export class DataPreviewComponent implements OnInit {

  @Input() mapping: Mapping;
  @Input() selectedDataset: string;
  @Input() selectedDatasetName: string;
  @Input() filters: TypeValue[];
  @Input() groups: TypeValue[];

  @ViewChild('leftNav', {static: false}) leftNav: ElementRef<UxplLeftNav>;
  @ViewChild('analysis', {static: false}) analysisRef: SpotfireViewerComponent;

  spotfireServer: string;
  availableColumns = [];
  leftNavTabs: NavMenu[];
  page: string;
  private document: SpotfireDocument;

  analysisParameters: string;
  customization = {
    showFilterPanel: true,
    showStatusBar: false,
    showToolBar: false,
    showAuthor: false,
  }

  showMapping = false;
  showTopSection = true;
  previewDXPLocation: string;
  private previewDataTable: string;
  showMenu = true;

  constructor(
    private catalogService: CatalogService,
    /*private configService: ConfigurationService,*/
    private visusalisationMS: VisualisationService,
    private oService: OauthService,
    private translationService: TranslationService

  ) {
    this.leftNavTabs = [
      {id: 'Statistics', label: 'Summary'}/*,
      {id: 'Case Filters', label: 'Case filters'},
      {id: 'Event Filters', label: 'Event filters'},
      /*{ id: 'Groups', label: 'Groups' },/
      {id: 'Start & Stop Activities', label: 'Label Start & Stop'},
      {id: 'Config', label: 'Config'}*/
    ];
    this.page = this.leftNavTabs[0].id;
    this.analysisParameters = 'DatasetId="0";&Token="' + this.oService.token + '";';
    //  console.log('this.analysisParameters: ', this.analysisParameters)
    this.visusalisationMS.getVisualisationConfiguration().subscribe(vizServer => {
      this.previewDXPLocation = vizServer.previewLocation;
      this.previewDataTable = vizServer.previewTableName;
      this.spotfireServer = environment.spotfireURL;
    });
  }


  ngOnInit(): void {

  this.translationService.currentLang$.subscribe(() => {
    this.updateFieldDescriptions();
  });

  this.updateFieldDescriptions();  // Initialize descriptions at component load
    setTimeout(() => {
      this.leftNav?.nativeElement?.setTab(this.leftNavTabs[0], true);
    }, 0);
    // Sometime the left tab is not selected, this code ensures it is
    setTimeout(() => {
      this.leftNav?.nativeElement?.setTab(this.leftNavTabs[0], true);
    }, 100);
    if (this.selectedDataset) {
      this.analysisParameters = 'DatasetId="' + this.selectedDataset + '";&Token="' + this.oService.token + '";';
      this.catalogService.getDataset(this.selectedDataset).subscribe(dataset => {
        this.availableColumns = dataset.schema.map(s => s.field);
      });
    }
  }

  private updateFieldDescriptions() {
    this.leftNavTabs = [
      {id: 'Statistics', label: this.translationService.translate("data.preview.field.summary")}/*,
      {id: 'Case Filters', label: 'Case filters'},
      {id: 'Event Filters', label: 'Event filters'},
      /*{ id: 'Groups', label: 'Groups' },/
      {id: 'Start & Stop Activities', label: 'Label Start & Stop'},
      {id: 'Config', label: 'Config'}*/
    ];
  }

  public updateStatus() {
    // Mapping has been updated in the left panel
    if (this.document) {
      const dataSetId = this.selectedDataset ? this.selectedDataset : '0'
      this.document.setDocumentProperty('DatasetId', dataSetId);
      const mapping = JSON.stringify(transformMapping(this.mapping))
      if (mapping) {
        this.document.setDocumentProperty('ColumnMapping', mapping);
      }
      this.document.setDocumentProperty('Token', this.oService.token);
      if (this.filters && this.filters.length > 0) {
        const ssAct: StartStop = {
          startActivities: this.filters.find(v => v.filterType === 'ActivitiesStart')?.values,
          stopActivities: this.filters.find(v => v.filterType === 'ActivitiesEnd')?.values,
        }
        this.document.setDocumentProperty('StartStopActivities', JSON.stringify(ssAct));
        const filterColumns: SpotfireFilter[] = [];
        this.filters.forEach(f => {
          if (f.name.indexOf(':') > -1) {
            const fType = f.name.split(':')
            if (fType.length > 1) {
              let fSettings: SpotfireFilterSetting;
              if (f.filterType === 'values') {
                fSettings = new SpotfireFilterSetting({
                  includeEmpty: f.includeEmpty,
                  values: f.values
                })
              }
              if (f.filterType === 'range' && f.values.length > 1) {
                fSettings = new SpotfireFilterSetting({
                  includeEmpty: f.includeEmpty,
                  lowValue: f.values[0],
                  highValue: f.values[1]
                })
              }
              filterColumns.push(new SpotfireFilter(
                {
                  filteringSchemeName: fType[0],
                  dataTableName: this.previewDataTable,
                  dataColumnName: fType[1],
                  filterSettings: fSettings
                }))
            }
          }
        })
        this.document.getFiltering().setFilters(filterColumns)
      }
    }
  }

  public handleClick = (event: any): void => {
    this.page = event.detail.id;
  }

  public setDocument = (event): void => {
    this.document = event;
    this.document.getPages$().subscribe(pages => {
      this.leftNavTabs = pages.map(p => {
        return {id: p, label: p.replace('Statistics', 'Summary')}
      })
      this.leftNavTabs = [...this.leftNavTabs]
      this.showMenu = true
    })
    this.updateStatus();
  }

  public async extractSpotfireFilterData(): Promise<boolean> {
    if (this.document) {
      const ssPromise = await new Promise<void>((resolve, reject) => {
        const propSub = this.document.getDocumentProperties$().subscribe(properties => {
          try {
            const startStopProp = properties.find(prop => prop.name === 'StartStopActivities');
            if (startStopProp?.value) {
              const startStop = JSON.parse(startStopProp.value + '') as StartStop;
              if (startStop.startActivities && startStop.startActivities.length > 0) {
                const startFilterObject: TypeValue = {
                  name: START_NAME,
                  description: 'Activities that indicate the start of a variant',
                  filterType: 'ActivitiesStart',
                  values: startStop.startActivities,
                  includeEmpty: false
                }
                this.upsertFilter(startFilterObject);
              }
              if (startStop.stopActivities && startStop.stopActivities.length > 0) {
                const stopFilterObject: TypeValue = {
                  name: STOP_NAME,
                  description: 'Activities that indicate the end of a variant',
                  filterType: 'ActivitiesEnd',
                  values: startStop.stopActivities,
                  includeEmpty: false
                }
                this.upsertFilter(stopFilterObject);
              }
            }
          } catch (e) {
            console.error(e);
            reject(e);
          }
          propSub.unsubscribe();
          resolve();
        })
      });
      const filterPromise = await new Promise<void>((resolve) => {
        const filterSub = this.document.getFiltering().getAllModifiedFilterColumns$().subscribe((filters) => {
          filters.forEach((filter) => {
            // TEMP Fix, till spotifre lib get's updated
            const sfFilter = filter as any as MySpotfireFilter;
            const sfSet = sfFilter.filterSettings;
            const caseFilterObject: TypeValue = {
              name: filter.filteringSchemeName + ':' + sfFilter.dataColumnName,
              description: 'Filter',
              filterType: sfSet.values ? 'values' : 'range',
              values: sfSet.values ? sfSet.values : [sfSet.lowValue, sfSet.highValue],
              includeEmpty: sfSet.includeEmpty
            }
            // Check specifically for range filter
            if (sfFilter.filterType === 'RangeFilter') {
              caseFilterObject.filterType = 'range'
              caseFilterObject.values = [convertDateFromLocale(sfSet.lowValue), convertDateFromLocale(sfSet.highValue)]
            }
            this.upsertFilter(caseFilterObject);
          })
          filterSub.unsubscribe();
          resolve();
        })
      });
      Promise.all([ssPromise, filterPromise]).then(() => {
        return true;
      });
    } else {
      return false;
    }
  }

  private upsertFilter(filterObject: TypeValue) {
    const index = this.filters.findIndex(item => item.name === filterObject.name);
    if (index > -1) {
      this.filters[index] = filterObject;
    } else {
      this.filters.push(filterObject);
    }
  }

  //
  // private convertDateFromLocale = (date: string): string => {
  //   const locale = window.navigator.language;
  //   const newDateLocale = Intl.DateTimeFormat(locale).formatToParts(new Date());
  //   let format = '';
  //   newDateLocale.forEach((element: Intl.DateTimeFormatPart) => {
  //     switch (element.type) {
  //       case 'day':
  //         format = format + 'd';
  //         break;
  //       case 'month':
  //         format = format + 'M';
  //         break;
  //       case 'year':
  //         format = format + (element.value.length === 2 ? 'yy' : 'yyyy');
  //         break;
  //       case 'literal':
  //         format = format + element.value;
  //         break;
  //       default:
  //         break;
  //     }
  //   })
  //   format = format + ' hh:mm:ss';
  //   const newDate = DateTime.fromFormat(date, format, {locale}).toISO();
  //   console.log('OLD DATE: ' + date + ' NEW DATE: ' + newDate + ' Returning: ');
  //   return newDate;
  // }
  //
  // private convertDateToLocale = (date: string): string => {
  //   const currentDate = new Date(date);
  //   const locale = window.navigator.language;
  //   return currentDate.toLocaleDateString(locale) + ' ' + currentDate.toLocaleTimeString(locale)
  // }
}
