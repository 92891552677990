import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CatalogMsService } from 'src/app/service/catalog-ms.service';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss']
})
export class CsvUploadComponent implements OnInit {

  @Input() filename: string;
  @Input() filetypes: string;
  @Input() filesize: number;
  @Input() size: string;
  @Input() error: string;
  @Output() uploadFile: EventEmitter<File> = new EventEmitter();

  allowedTypes: string[];
  maxFileSize;
  loading: boolean = false;

  fileover: boolean;
  currentFileType: string;
  // error: string | null;

  constructor(
    private translationService: TranslationService,
    private CatalogMsService: CatalogMsService
  ) {
  }

  ngOnInit(): void {
    if (this.filetypes) {
      this.allowedTypes = this.filetypes.split(',').map(t => t.toLowerCase());
    }
    if (this.filesize) {
      this.maxFileSize = this.filesize * 1024 * 1024;
    }
  }

  fileBrowseHandler(files) {
    this.selectFile(files);
  }

  // onFileDropped($event) {
  //   console.log();
  // }

  onFileDrop($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = false;
    // @ts-ignore
    this.selectFile($event.dataTransfer.files);
  }

  private selectFile(files) {
    this.loading = true;
    if (files && files.length > 0) {
      const file = files[0];
      // if (this.allowedTypes && this.allowedTypes.indexOf(this.getSubFileType(file.type))) {
      // if (this.allowedTypes && this.allowedTypes.indexOf(file.name)) {
      const fType = file?.name?.substring(file?.name?.lastIndexOf('.') + 1, file?.name?.length);
      let fileOk = false;
      this.allowedTypes.forEach(fileType => {
        if (fType.indexOf(fileType) > -1) {
          this.currentFileType = fileType;
          fileOk = true;
        }
      })
      if (!fileOk) {
        if(this.allowedTypes[0] == 'csv'){
          this.error = this.translationService.translate('basic.file.type.error');
        }else{
          this.error = this.translationService.translate('basic.'+this.allowedTypes[0]+'.type.error');
        }
        this.loading = false;
        return;
      }

      if (this.maxFileSize && file.size >= this.maxFileSize) {
        this.error = this.translationService.translate('basic.file.size.error')+this.filesize+'MB.';
        this.loading = false;
        return;
      }

      this.error = undefined;

      this.filename = files[0].name;
      switch (this.currentFileType) {
        case 'csv':
          this.uploadFile.emit(files[0]);
          this.loading = false;
          break;
        case 'xes':
          this.CatalogMsService.uploadXes(files[0]).subscribe(response => {
            const base64Data = response.body.csv_binary_content;
            this.filename = response.body.fileName;
            const csvFile = this.base64ToFile(base64Data, this.filename);
            this.uploadFile.emit(csvFile);
            this.loading = false;
          });
          break;
        case 'xlsx':
          this.CatalogMsService.uploadXlsx(files[0]).subscribe(response => {
            const base64Data = response.body.csv_binary_content;
            const csvFile = this.base64ToFile(base64Data, this.filename);
            this.uploadFile.emit(csvFile);
            this.loading = false;
          });
          break;
      }
    }
  }

  // private getSubFileType(type: string): string {
  //   let sep = '/';
  //   if (navigator.appVersion.indexOf('Win') !== -1) sep = '\\';
  //   const pos = type.indexOf(sep);
  //   if (pos !== -1) {
  //     type = type.substr(pos + 1);
  //   }
  //   return type.toLowerCase();
  // }

  onDragover($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = true;
  }

  onDragleave($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fileover = false;
  }

  base64ToFile(base64: string, filename: string): File {
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'text/csv' });
    return new File([blob], filename, { type: 'text/csv' });
  }
}
