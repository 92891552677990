<div fxLayout="row" style="margin-top: 10px;">
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="investigationDetail">
      <uxpl-tag icon="{{getStateIcon(investigationDetail?.data?.state)}}" class="state"
                color="{{getStateColor(investigationDetail?.data?.state)}}">{{investigationDetail?.data?.state | translate}}</uxpl-tag>
    </div>
  <div class="divider"></div>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center">
      <action-button-bar [buttons]="actionButtons"
                         (actionButtonClicked)="handleButtonClicked($event)"></action-button-bar>
    </div>
</div>
