<mat-card class="analytic-card-outer" [ngClass]="{ 'selected-card': isSelected}">
  <mat-card-content>
    <mat-card-title>
      <div class="icon-container" *ngIf="cardConfig?.icon && !cardConfig.icon.startsWith('CUSTOM-ICON-CODE:')"><img height=52 width=52 src="{{cardConfig?.icon}}" onerror="this.style.display='none'"></div>
      <div class="icon-container" *ngIf="cardConfig?.icon && cardConfig.icon.startsWith('CUSTOM-ICON-CODE:')"><div class="icon-outer"><uxpl-icon class="card-icon" icon="{{cardConfig?.icon.substring(17)}}" width="30px" height="30px"></uxpl-icon></div></div>
      {{ cardConfig?.name }}
      <!-- <uxpl-options-menu *ngIf="!isSelectionMode" default-color='#2B94EB' x-position="before" y-position="below" icon-width="14px" icon-height="14px" [config]="templateOptions" (selected)="handleOptionSelected($event)"></uxpl-options-menu> -->
    </mat-card-title>
    <mat-card-subtitle>{{cardConfig?.type?.replace(' ', '-') | uppercase }} TEMPLATE</mat-card-subtitle>
    <img mat-card-image *ngIf="cardConfig?.splash" src="{{cardConfig?.splash}}">
    <div class="template-description" [innerHTML]="cardConfig?.description | highlight: highLight : 'Multi-Match'"></div>
  </mat-card-content>
  <mat-card-action *ngIf="isSelectionMode">
    <button mat-stroked-button color="primary" *ngIf="!isSelected" (click)="isSelectionMode ? optionClicked('select', $event) : undefined">
      {{ 'select' | translate }}
    </button>
    <button mat-button mat-flat-button color="primary" *ngIf="isSelected">
      <mat-icon>check</mat-icon>
      {{ 'selected' | translate }}
    </button>
  </mat-card-action>
</mat-card>
