import { Injectable } from '@angular/core';
import { AuthKeys, TokenResponse } from '../models_ui/oauth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models_ui/userinfo';
import {OAUTH_LOCAL_STORAGE_KEY} from '../app.settings';
import {Subscription} from '../models_ui/subscriptionDetails';
// import {MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})

export class OauthService {

  // private CLIENTID = environment.authConfig.clientId;
  // private SCOPE = 'securitycontext+internal.refresh-session+offline+openid+profile+email+openid+BPM+nimbus+spotfire+tsc+offline_access';
  // private SCOPE = 'securitycontext+internal.refresh-session+offline+openid+profile+email+openid+BPM+spotfire+offline_access';
  // private VERIFIER;
  // private MAX_TOKEN_EXPIRY_SECONDS = 1080;

  private _AUTHKEYS: AuthKeys;
  private _MODE: string = environment.local ? 'dev' : 'cloud';
  private _REGION: string = environment.region;
  private regionSuffix: string = (environment.region.toLowerCase() === 'us' ? '' : environment.region.toLowerCase() + '.');


  constructor(
      private http: HttpClient,
      // @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ac = urlParams.get('accountChange');
    if (ac === 'true') {
      sessionStorage.removeItem('TSC_DISCOVERUI_KEY');
    } else {
      // const auth = this.getKey();
      // this.VERIFIER = auth?.verifier;
    }
  }

  // private tokenExpiresIn(authKeys: AuthKeys): number {
  //   const nowEpoch: number = Math.floor(new Date().getTime() / 1000);
  //   return authKeys.expiry - nowEpoch;
  // }

  public async initialize() {
    // new HttpHeaders()
    //   .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
    //   .set('Pragma', 'no-cache')
    //   .set('Expires', '0')
    //   if (!this.CLIENTID) {
    //       console.error('No client_id');
    //       exit();
    //     }
    //     /*
    //     * Check for ac params. If have one get new token
    //     * Check for token/refresh token, check expiry - if on cloud we have to get new one (TBC)
    //     * If token and it will expire soon, refresh -> done
    //     * If token and it is valid for > MAX -> done
    //     * If no token, or error call auth endpoint
    //     *           if cookies will redirect with ac
    //     *           if no cookies will redirect to login
    //     */
    //     const queryString = window.location.search;
    //     const urlParams = new URLSearchParams(queryString);
    //     const ac = urlParams.get('code');

    //     if (ac) {
    //       // get new token - save and continue
    //       return this.getToken(ac).toPromise().then(
    //         () => {
    //           return
    //         },
    //         error => {
    //           if (error instanceof HttpErrorResponse && error.status === 403 && error.error.startsWith('Forbidden: Token is not authorized to access scope')) {
    //             // org doesnt have sufficient Tibco Cloud capabilities
    //             console.error('Required Tibco Cloud capabilities are not provisioned for this organization', error);
    //             //exit;
    //           } else {
    //             console.error('Unable to retrieve token using access code. Redirect to login', error);
    //             return this.redirectLogin().then(() => exit());;
    //           }
    //         }
    //       );
    //     } else {
    //       // get existing token
    //       const authKeys = this.getKey();
    //       if (authKeys?.token) {
    //         if (this.isCloud()) {
    //           // if we are on cloud we could have a different oauth key so best to get a new one
    //           // TODO: JS confirm if there is a better way to do this by detecting if we are on same sub/userId
    //           return this.getTscHash().toPromise().then(
    //             tscHash => {
    //               if (tscHash === authKeys.tscHash) {
    //                 // same org so we can keep using this token
    //                 if (this.tokenExpiresIn(authKeys) < this.MAX_TOKEN_EXPIRY_SECONDS) {
    //                   // get a new token
    //                   return this.getToken(authKeys.refreshToken).toPromise().then(
    //                     () => { return },
    //                     error => {
    //                       console.error('Unable to refresh token. Redirect to login', error);
    //                       return this.redirectLogin().then(() => exit());;
    //                     }
    //                   );
    //                 } else {
    //                   // just continue and use existing token
    //                   return;
    //                 }
    //               } else {
    //                 // org switch
    //                 console.warn('token is for a different org - getting new token');
    //                 return this.redirectLogin().then(() => exit());
    //               }
    //             }
    //           ).catch(
    //             error => {
    //               console.error(error);
    //               console.error('Error validating cookie - will need to reauthenticate');
    //               return;
    //             }
    //           )
    //         } else {
    //           // dev mode
    //           // is token still valid (and expiry > MAX_TOKEN_EXPIRY_SECONDS)
    //           if (this.tokenExpiresIn(authKeys) < this.MAX_TOKEN_EXPIRY_SECONDS) {
    //             // get a new token
    //             return this.getToken(authKeys.refreshToken).toPromise().then(
    //               () => { return },
    //               error => {
    //                 console.error('Unable to refresh token. Redirect to login', error);
    //                 return this.redirectLogin().then(() => exit());;
    //               }
    //             );
    //           } else {
    //             // just continue and use existing token
    //             return;
    //           }
    //         }
    //       } else {
    //         // no token try and get one. Will redirect to login if no cookies
    //         return this.redirectLogin().then(() => exit());;
    //       }
    //     }

  }

  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public async redirectLogin() {
    const codeVerifier = this.strRandom(32);
    // const verifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    // const codeChallenge =  verifierHash
    //   .replace(/=/g, '')
    //   .replace(/\+/g, '-')
    //   .replace(/\//g, '_');
    this.setKey({verifier: codeVerifier});
    // const codeChallenge = 'vVx8gU7hfb18z4v_fischYYz8aaeJP6GAio2a_WzE14';
    // window.location.href = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/idm/v1/oauth2/auth?response_type=code&scope='
    //   + this.SCOPE + '&redirect_uri='
    //   + environment.authConfig.redirect_url + '&client_id='
    //   + this.CLIENTID
    //   + '&code_challenge=' + codeChallenge + '&code_challenge_method=S256';
  }

  public getUserInfo(): Observable<UserInfo> {
    const userInfoUrl: string = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/public/v1/oauth2/userinfo';
    return this.http.get(userInfoUrl).pipe(
      map(userinfo => {
        return userinfo as UserInfo
      })
    );
  }

  public getSubscriptionDetails(): Observable<Subscription> {
    const subscriptionDetailsUrl: string = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/tsc-ws/v1/subscription-details';
    return this.http.get(subscriptionDetailsUrl).pipe(
      map(subD => {
        return subD as Subscription
      })
    );
  }



  getTscHash(tokenOverride?: string): Observable<string> {
    let url = '/tsc-ws/v2/whoami';
    if (!environment?.local) {
      url = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/tsc-ws/v2/whoami';
    }
    let headers: HttpHeaders;
    if (tokenOverride) {
      // add auth header with bearer token
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenOverride);
    }
    return this.http.get(url, {headers: headers ? headers : undefined, withCredentials: tokenOverride ? undefined : true }).pipe(
      map((res: any) => {
        return res.hid + '-' + res.hacct + '-' + res.regn;
      })
    )
  }

  getTscWhoami(): Observable<any> {
    let url = '/tsc-ws/v2/whoami';
    if (!environment?.local) {
      url = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/tsc-ws/v2/whoami';
    }
    // const url = `https://${this._REGION === 'us' ? '' : (this._REGION + '.')}account.cloud.tibco.com/tsc-ws/v2/whoami`;
    return this.http.get(url);
  }

  public getToken(code: string): Observable<TokenResponse> {
    console.log(code);
    // let url = '/idm/v1/oauth2/token';
    // if (!environment?.local) {
    //   url = 'https://' + this.regionSuffix + 'account.' + environment.tcHost + '/idm/v1/oauth2/token';
    // }
    // const isAc = code.startsWith('ac.');
    // const body = new HttpParams()
    //   .set('code', isAc ? code : undefined)
    //   .set('refresh_token', isAc ? undefined : code)
    //   .set('redirect_uri', environment.authConfig.redirect_url)
    //   .set('client_id', this.CLIENTID)
    //   .set('code_verifier', this.VERIFIER)
    //   .set('grant_type', code.startsWith('ac.') ? 'authorization_code' : 'refresh_token');

    // const headers = new HttpHeaders()
    //   .set('Content-Type', 'application/x-www-form-urlencoded');

    // return this.http.post(url, body.toString(), { headers }).pipe(
    //   concatMap(
    //     (res: TokenResponse) => {
    //       // use just retrieved token to get the tsc info (only dev mode) - cloud will have tsc cookie
    //       return this.getTscHash(res.access_token).pipe(
    //         map(
    //           hash => {
    //             // save token and calculate expiry
    //             const expiresIn: number = res.expires_in;
    //             const expiryEpoch = Math.floor(new Date().getTime() / 1000) + expiresIn;
    //             const authKeys: AuthKeys = {
    //               token: res.access_token,
    //               refreshToken: res.refresh_token,
    //               expiry: expiryEpoch,
    //               verifier: this.VERIFIER,
    //               tscHash: hash
    //             }
    //             this.setKey(authKeys);
    //             return res;
    //           }
    //         )
    //       )
    //     }
    //   )
    // )
    let response = new Observable<TokenResponse>(observer => {
      let value = {
        access_token: "",
        refresh_token: "",
        scope: "",
        token_type: "",
        expires_in: 0,
        id_token: ""
      }
      let accountKey = localStorage.getItem('msal.account.keys');
      let tokenObject: any;
      if(accountKey){
        tokenObject = JSON.parse(localStorage.getItem("msal.token.keys."+accountKey));
      }
      if(tokenObject.accessToken && tokenObject.accessToken.length > 0 && tokenObject.idToken && tokenObject.idToken.length > 0 && tokenObject.refreshToken && tokenObject.refreshToken.length > 0){
        value.access_token = tokenObject.accessToken[0];
        value.id_token = tokenObject.idToken[0];
        value.refresh_token = tokenObject.refreshToken[0];
      }
      observer.next(value);
    });
    console.log("getToken", response);
    return response;
  }

  public setMode(mode) {
    this._MODE = mode;
  }

  public isCloud(): boolean {
    if (this._MODE) {
      if (this._MODE.toLowerCase() === 'cloud') {
        return true;
      } else {
        return false;
      }
    } else {
      return undefined;
    }
  }

  get region(): string {
    return this._REGION;
  }

  get authkeys(): AuthKeys {
    return this._AUTHKEYS;
  }

  get mode(): string {
    return this._MODE;
  }

  get token(): string {
    return this._AUTHKEYS?.token;
  }

  getKey(): AuthKeys {
    if (this._AUTHKEYS) {
      return this._AUTHKEYS;
    } else {
      const oauthKey = sessionStorage.getItem(OAUTH_LOCAL_STORAGE_KEY);
      this._AUTHKEYS = JSON.parse(oauthKey);
      if (!this._AUTHKEYS) {
        console.warn('No auth key in local storage');
      }
      return this._AUTHKEYS;
    }
  }

  createCookie(keys: AuthKeys) {
    // only used in dev mode with nginx proxy (to ensure TCLA calls to static resources work)
    // create a secure cookie with oauth key so a proxy can convert this to oauth token when accessing static resources
    let expires = '';
    const date = new Date();
    date.setTime(date.getTime() + (180 * 24 * 60 * 60 * 1000));
    expires = ';expires=' + date.toUTCString();
    const cookie = 'TCSTKSESSION' + '=' + (keys.token || '') + expires + ';path=/;secure;';
    document.cookie = cookie;
  }

  removeCookie() {
    document.cookie = 'TCSTKSESSION' + '=' + '; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure;';
  }

  setKey(keys: AuthKeys) {
    this._AUTHKEYS = keys;
    if (keys) {
      sessionStorage.setItem(OAUTH_LOCAL_STORAGE_KEY, JSON.stringify(keys));
      if (this.mode === 'dev') {
        this.createCookie(keys);
      }
    } else {
      sessionStorage.removeItem(OAUTH_LOCAL_STORAGE_KEY);
      if (this.mode === 'dev') {
        this.removeCookie();
      }
    }
  }
}
