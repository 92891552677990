<div class="box">
  <div class="rowC header">
    <uxpl-primary-nav style="display: none" #primaryNav [tabs]="tabs" (selected)="handleClick($event)"></uxpl-primary-nav>
    <admin-nav-bar></admin-nav-bar>
    <div class="new-user-container container-flex-fluid">
      <div class="flex">
        <div class="flex-item width-xs-10">
          <uxpl-object-header [config]="objHeaderConfig" (back)="goBack()">
            <div class="buttons-container" slot="navigation-content">
              <button mat-stroked-button color="primary" (click)="goBack()">
                {{ 'createOrg.back' | translate }}
              </button>
              <button mat-flat-button color="primary" [disabled]="newOrgForm.invalid" type="submit" (click)="onSubmit()">
                {{ 'createOrg.save' | translate }}
              </button>
            </div>
          </uxpl-object-header>
        </div>
        <div class="flex-item width-xs-10 width-sm-6 width-md-5 width-lg-4">
          <mat-card>
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            <mat-card-content>
              <form [formGroup]="newOrgForm" class="form-sub-container" (ngSubmit)="onSubmit()">
                <div class="form-field-container">
                  <!-- <div class="form-field-sub-container-up"> -->
                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'createOrg.name' | translate }}</mat-label>
                    <input matInput formControlName="name" placeholder="Enter org name"/>
                    <mat-error *ngIf="newOrgForm.get('name').hasError('required')">
                      {{ 'createOrg.nameRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="newOrgForm.get('name').hasError('alphanumspace')">
                      {{ 'generic.message.form.field.alphanumerica.space.validation.error' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'createOrg.description' | translate }}</mat-label>
                    <input matInput formControlName="description" placeholder="Enter description" />
                    <mat-error *ngIf="newOrgForm.get('description').hasError('required')">
                      {{ 'createOrg.descriptionRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="newOrgForm.get('description').hasError('alphanumspace')">
                      {{ 'generic.message.form.field.alphanumerica.space.validation.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>