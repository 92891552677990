<div id="parse-container" class="flex">
  <div class="flex-item width-xs-10 width-md-333 gap-15 justify-content-flex-end">
    <div>{{ 'parse.csv.label' | translate }}</div>
    <uxpl-radio-group [value]="columnSeparator" (uxplChanged)="onSetColumnSeparator($event)">
      <uxpl-radio value=";">{{ 'parse.csv.radio.semicolon' | translate }}</uxpl-radio>
      <uxpl-radio value=",">{{ 'parse.csv.radio.comma' | translate }}</uxpl-radio>
      <uxpl-radio value="\t">{{ 'parse.csv.radio.tab' | translate }}</uxpl-radio>
      <uxpl-radio value="other">
        <uxpl-form-field slot="content" [label]="radioOther" type="text" valid
                        [value]="customColumnSeparator"></uxpl-form-field>
      </uxpl-radio>
    </uxpl-radio-group>
  </div>
  <div class="flex-item width-xs-10 width-md-333 gap-15">
    <uxpl-select-input  [placeholder]="placeholderEncoding" [label]="labelEncoding" [value]="datasetConfig?.encoding" [options]="encodingOptions" (uxplSelection)="handleEncodingSelection($event)"></uxpl-select-input>
    <uxpl-form-field type="number" valid [value]="numberRowsForPreview" 
    [label]="labelNumberRow" 
    min="1" max="100" (uxplChanged)="updateNumForRowsPreview($event)"></uxpl-form-field>
    <uxpl-form-field [label]="labelQuote" type="text" valid [value]="datasetConfig?.quoteChar"
                    (uxplChanged)="handleUpdate($event, 'quoteChar')"></uxpl-form-field>
  </div>
  <div class="flex-item width-xs-10 width-md-333 gap-15 justify-content-flex-end">
    <uxpl-form-field class="field-margin" [label]="labelEscape" type="text" valid
                    [value]="datasetConfig?.escapeChar"
                    (uxplChanged)="handleUpdate($event, 'escapeChar')"></uxpl-form-field>
    <uxpl-button class="padding-0" type="secondary" icon="assets/images/svg/pl-icon-refresh.svg" (clicked)="clickedRefresh()" width="100">
      {{ 'parse.csv.button.refresh' | translate }}
    </uxpl-button>
  </div>
  <div class="mask" *ngIf="!file || datasetConfig?.filename"></div>
</div>
