import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import {compareTemplates} from '../../functions/templates';

import { Analysis, RepositoryService, Template, VisualisationService } from '@tibco/discover-client-lib';
import {getShortMessage} from '../../functions/message';
import {ObjectHeaderConfig} from '@tibco/tc-web-components/dist/types/models/objectHeaderConfig';
import {RecursivePartial} from '../../models_ui/discover';
import { TranslationService } from 'src/app/service/translate.service';

@Component({
  selector: 'template-select',
  templateUrl: './template-select.component.html',
  styleUrls: ['./template-select.component.css']
})
export class TemplateSelectComponent implements OnInit {

  templates: Template[];
  objHeaderConfig: RecursivePartial<ObjectHeaderConfig>;
  analysis: Analysis;
  showListTemplates = false;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private repositoryMS: RepositoryService,
    private visualisationMS: VisualisationService,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  public goProcessAnalysis = (): void => {
    this.router.navigate(['/discover/process-analysis']);
  }

  public refresh = (): void => {
    this.visualisationMS.getTemplates().subscribe( (aTemplates) => {
      this.templates = aTemplates.sort(compareTemplates);
      const template$ = this.templates;
      const analysis$ = this.repositoryMS.getAnalysisDetails(this.route.snapshot.paramMap.get('name'));
      forkJoin([template$, analysis$]).subscribe(async results => {
        this.analysis = results[1];
        const formatDate = new Date(this.analysis.metadata.createdOn).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
        let tempText = 'No template selected';
        if(this.analysis?.template?.id){
          try {
            const templateDetails = await this.visualisationMS.getTemplate(this.analysis.template.id).toPromise();
            tempText = 'Template: ' + templateDetails.name
          } catch (e) {
            // TODO: Specifically check for a 404 when the backend is updated
            console.error('TEMPLATE ERROR ', e);
          }
        }
        let sName = this.analysis.name;
        let aName = this.analysis.name.split('||');
        if(aName.length > 1){
          sName = aName[1];
        }
        this.objHeaderConfig = {
          title: {
            value: sName,
          },
          details: [
            {
              value: this.translationService.translate('template.select.created.by') + this.analysis.metadata.createdBy
            },
            {
              value: this.translationService.translate('template.select.created.on') + formatDate,
            },
            {
              value: getShortMessage(this.analysis.description, 50),
            },
            {isEdit: true, editing: false, value: tempText, eventOnEdit: true, id: 'template'}
          ]
        };
        this.showListTemplates = true;
      });
    })
  }

  public handleSelectTemplate(event) {
    const template = event.template;
    this.repositoryMS.setTemplateForAnalysis(this.analysis.id, this.analysis.metadata.version, template.id).subscribe(
      () => {
        window.setTimeout(() => {
          this.router.navigate(['/discover/analytics/' + this.analysis.id] ,{ queryParams: { forceTemplate: template.id }});
          })
      }
    );
  }

  public goTemplate() {
    // Go back to templates
    this.location.back();
  }
}
