<div class="box">
  <!--Header-->
  <div class="rowC">
    <admin-nav-bar></admin-nav-bar>
    <!--End Header-->
    <!--Subheader-->
    <div class="page-container container-flex-fluid">
      <div class="flex">
        <div class="flex-item">
          <div class="data-title-container">
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            <div class="data-title">
              <div class="page-title">{{ 'administration.title' | translate }}</div>
            </div>
            <div class="data-title">
              <div class="data-title">
                <button mat-stroked-button
                  color="primary"
                  (click)="getOrgInfo()"
                  *ngIf="orgs.length > 0"
                  [matTooltip]="'administration.org.info.tooltip' | translate">
                  <mat-icon class="material-symbols-rounded">info</mat-icon>
                </button>
                <button mat-stroked-button
                  color="primary"
                  (click)="downloadDXP()"
                  *ngIf="orgs.length > 0">
                  <mat-icon class="material-symbols-rounded">download</mat-icon>
                  {{ 'administration.button.download' | translate }}
                </button>
                <button mat-stroked-button
                  color="primary"
                  [routerLink]="'/administration/'+currentOrgId+'/edit-org'"
                  *ngIf="orgs.length > 0">
                  <mat-icon class="material-symbols-rounded">edit</mat-icon>
                  {{ 'administration.button.edit' | translate }}
                </button>
                <button mat-stroked-button
                  color="primary"
                  (click)="triggerChildRefresh()">
                  <mat-icon class="material-symbols-rounded">refresh</mat-icon>
                  {{ 'refresh' | translate }}
                </button>
                <button mat-flat-button
                  color="primary"
                  [routerLink]="'/administration/'+currentOrgId+'/add-user'"
                  *ngIf="orgs.length > 0">
                  <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                  {{ 'administration.button.add' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--end progress bar-->
        <div class="flex-item content-wrapper">
          <!-- Left Sidebar -->
          <div class="flex" style="height: calc(100vh - 142px);">
            <div class="flex-item sidebar-left-nav">
              <div class="sidebar-wrapper-container">
                <div class="sidebar-tabs-container resizable-panel"
                  [style.width.px]="250"
                  appResizable
                  resizableMinWidth="200"
                  resizableMaxWidth="300">
                  
                  <ul>
                    <li *ngFor="let tab of leftNavTabs">
                      <a [routerLink]="tab.link" [class]="tab.id == currentOrgId ? 'active' : ''">
                        <span class="org-name">{{ tab.label }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="sidebar-footer">
                <button mat-flat-button color="primary"
                  class="secondary add-folder-button"
                  type="secondary"
                  routerLink="/administration/create-org"
                  style="width: 100%;">
                  <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                  {{ 'administration.button.addOrg' | translate }}
                </button>
              </div>
            </div>
            <div id="sidebar-content" class="flex-item">
              <div id="icon-sidebar-container" class="flex">
                <div id="icon-sidebar-side" class="flex-item">
                  <icon-sidebar [iconSidebarData]="iconSidebarData"></icon-sidebar>
                </div>
                <div id="icon-sidebar-content" class="flex-item">
                  <div class="flex icon-sidebar-scroll">
                    <router-outlet></router-outlet>
                  </div>
                </div>
              </div>
            </div>
            <div id="no-orgs" *ngIf="orgs.length == 0 && !isLoading">
              <img src="../../../assets/images/png/no-data.png">
            </div>
          </div>
      </div>
    </div>
  </div>
</div>