<div class="box">
  <div class="rowC header">
    <uxpl-primary-nav
      style="display: none"
      #primaryNav
      [tabs]="tabs"
      (selected)="handleClick($event)"
    ></uxpl-primary-nav>
    <nav>
      <ul #primaryNav>
        <li>
          <a id="logo" routerLink="/welcome"><img src="../../../assets/images/png/logo-pulseminds-horizontal-black.png" /></a>
        </li>
        <li *ngFor="let tab of tabs">
          <a (click)="handleClick(tab.id)" [class]="!analysisID && tab.id == 'analytics' ? 'disabled' : ''" [routerLink]=" analysisID && tab.id == 'analytics' ? '/discover/' + tab.id + '/' + analysisID : '/discover/' + tab.id " routerLinkActive="active" >{{ tab.label }}</a>
        </li>
      </ul>
      <ul #logoutNav>
        <app-language-selector></app-language-selector>
        <li>
          <a id="user-action" [matMenuTriggerFor]="userMenu">
            <div>
              <span>{{ userDisplayName }}</span>
              <i *ngIf="orgInfo && orgInfo.displayName">{{ orgInfo.displayName }}</i>
            </div>
            <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
          </a>
          <mat-menu #userMenu="matMenu" xPosition="before">
            
            <button *ngIf="userRole === 'Admin' || userRole === 'Superadmin'" mat-menu-item routerLink="/discover/control-panel">
              <mat-icon class="material-symbols-rounded">dashboard</mat-icon>
              <span>{{ 'discover.menu.control.panel' | translate }}</span>
            </button>
            <button *ngIf="superadmin" mat-menu-item routerLink="/administration">
              <mat-icon class="material-symbols-rounded">admin_panel_settings</mat-icon>
              <span>{{ 'discover.menu.administration' | translate }}</span>
            </button>
            <button mat-menu-item routerLink="/select-organization">
              <mat-icon class="material-symbols-rounded">corporate_fare</mat-icon>
              <span>{{ 'discover.menu.changeOrg' | translate }}</span>
            </button>
            <a *ngIf="docsUrl != ''" mat-menu-item [href]="docsUrl" target="_blank">
              <mat-icon class="material-symbols-rounded">book</mat-icon>
              <span>{{ 'administration.menu.documentation' | translate }}</span>
            </a>
            <a *ngIf="supportUrl != ''" mat-menu-item [href]="supportUrl" target="_blank">
              <mat-icon class="material-symbols-rounded">support</mat-icon>
              <span>{{ 'administration.menu.support' | translate }}</span>
            </a>
            <button mat-menu-item (click)="handleReleaseNotes()">
              <mat-icon class="material-symbols-rounded">flag</mat-icon>
              <span>{{'release.notes.menu.item' | translate}} {{ releaseVersion }}</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon class="material-symbols-rounded">logout</mat-icon>
              <span>{{ 'discover.menu.logout' | translate }}</span>
            </button>
          </mat-menu>
        </li>
      </ul>
    </nav>
    <!-- <news-banner></news-banner> -->
  </div>
  <div class="rowC content discover-container">
    <div [hidden]="hideAnalytics">
      <analytics-dashboard
        [analysis]="analysisID"
        class="analytics-dashboard"
      ></analytics-dashboard>
    </div>
    <div [hidden]="!hideAnalytics" style="height: 100%; overflow: hidden">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
