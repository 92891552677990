<div>
    <resizable-draggable class="filter-panel" #sfFilterContainer
        [width]="width" [height]="height" [top]="top" [left]="left"
        [minWidth]="minFilterPanelSize.x" [minHeight]="minFilterPanelSize.y"
        (enableSFPointerEvents)="enableSpotfirePointerEvents($event)"
        (resizeToSmallSize)="resizeToSmallSize()"
        (resize)="resize($event)"
        >
        <div class="resizble-draggable-content filter-panel-content">
            <div class="top-header">
                <div class="header-left">
                    <mat-icon class="material-symbols-rounded">filter_alt</mat-icon>
                    <div class="header-title">filter</div>
                </div>
                <div class="collapse-handler" >
                    <mat-icon class="material-symbols-rounded" (click)="closeFilterPanel()">close</mat-icon>
                </div>
            </div>
            <div class="bottom-content new-filters">
                <div class="left-navi hidden">
                    <uxpl-left-nav-multi #leftNav [tabs]="leftNavTabs" (selected)="handleMenuClick($event)" collapse></uxpl-left-nav-multi>
                </div>
                <div id="filter-tabs" class="tabs">
                    <div class="tabs-wrap">
                        <ul>
                            <li *ngFor="let nav of leftNavTabs">
                                <span [class.active]="nav.id == activeTab">
                                    <span class="name" (click)="handleMenuClick2(nav.id)"><span class="name-text">{{nav.label}}</span></span>
                                    <label class="filter-switch">
                                        <!-- <uxpl-switch-input [checked]="!filterByTab[nav.id] ? false : filterByTab[nav.id].enabled" (uxplChange)="enableFilter(nav.id, $event)"></uxpl-switch-input> -->
                                        <mat-slide-toggle [checked]="!filterByTab[nav.id] ? false : filterByTab[nav.id].enabled" (change)="enableFilter(nav.id, $event)"></mat-slide-toggle>
                                        <!-- <uxpl-switch-input></uxpl-switch-input> -->
                                        <!-- <input type="checkbox"> -->
                                    </label>
                                </span>
                            </li>
                        </ul>
                        <uxpl-button type="primary" (click)="resetAllFilters()">Reset all filters</uxpl-button>
                    </div>
                </div>
                <div class="spotfire-view" #sfView>
                    <div id='FILTERDIV' class="sf-iframe-div"></div>
                    <div id="filter-by-time" class="custom-filter-page hidden">
                        <div class="container-flex-fluid">
                            <div class="flex">
                                <div class="flex-item width-xs-1 align-items-center">
                                    <mat-calendar #timeCalendar [selected]="selectedRangeValue" [startAt]="startAt" startView="multi-year" [minDate]="minDate" [maxDate]="maxDate" (selectedChange)="selectedChange($event)"></mat-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="filter-attributes" class="custom-filter-page hidden">
                        <div class="container-flex-fluid" *ngIf="!attributes">
                            <div class="flex-row">
                                <div class="flex-item flex-xs-10 justify-content-center">
                                    <span class="material-symbols-rounded loader" style="margin: 0 auto;">progress_activity</span>
                                </div>
                            </div>
                        </div>
                        <div class="container-flex-fluid" *ngIf="attributes && attributes.length > 0">
                            <div class="flex-row">
                                <div class="flex-item flex-xs-333" *ngFor="let attribute of attributes">
                                    <div class="card card-table" *ngIf="attribute.filterType != 'RangeFilter'">
                                        <div class="card-content">
                                            <header>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <uxpl-checkbox [attr.id]="attribute.name" [value]="attribute.name" (click)="checkboxClicked()" (clicked)="checkAllAttribute($event)"></uxpl-checkbox>
                                                            </th>
                                                            <th>{{attribute.name}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let value of attribute.values">
                                                            <td>
                                                                <uxpl-checkbox></uxpl-checkbox>
                                                            </td>
                                                            <td>{{value.value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </header>
                                            <main>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <uxpl-checkbox></uxpl-checkbox>
                                                            </th>
                                                            <th>{{attribute.name}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let value of attribute.values">
                                                            <td>
                                                                <uxpl-checkbox [checked]="value.checked" [value]="value.value" (clicked)="checkAttribute(attribute.name, $event)"></uxpl-checkbox>
                                                            </td>
                                                            <td>{{value.value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </main>
                                        </div>
                                    </div>
                                    <div class="card" *ngIf="attribute.filterType == 'RangeFilter'">
                                        <div class="card-content">
                                            <header>
                                                <label>{{attribute.name}}</label>
                                            </header>
                                            <main>
                                                <uxpl-form-field 
                                                    type="number" 
                                                    [name]="attribute.name" 
                                                    [attr.valid]="attribute.currentLowValue >= attribute.lowValue && attribute.currentLowValue <= attribute.highValue && attribute.currentLowValue <= attribute.currentHighValue" 
                                                    [value]="attribute.currentLowValue" 
                                                    label="From" 
                                                    [hint]="'Minimum value '+attribute.lowValue"
                                                    (uxplChanged)="updateAttributeLowValue($event)"
                                                ></uxpl-form-field>
                                                <uxpl-form-field 
                                                    type="number" 
                                                    [name]="attribute.name" 
                                                    [attr.valid]="attribute.currentHighValue <= attribute.highValue && attribute.currentHighValue >= attribute.lowValue && attribute.currentHighValue >= attribute.currentLowValue" 
                                                    [value]="attribute.currentHighValue" 
                                                    label="To" 
                                                    [hint]="'Maximum value '+attribute.highValue"
                                                    (uxplChanged)="updateAttributeHighValue($event)"
                                                ></uxpl-form-field>
                                            </main>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container-flex-fluid" *ngIf="attributes && attributes.length == 0">
                            <div class="flex-row">
                                <div class="flex-item flex-xs-10 justify-content-center">
                                    <label>There are no attributes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="filter-actions">
                        <uxpl-button (click)="resetTabFilter(activeTab)" type="secondary">Reset</uxpl-button>
                        <uxpl-button (click)="applyFilter(activeTab)" type="primary">Apply</uxpl-button>
                    </div>
                </div>
            </div>
        </div>
    </resizable-draggable>
</div>
