<div class="side-panel-container">
  <div *ngFor="let button of buttons" class="button-holder" [ngClass]="button.class" class="isolate">
    <div class="tooltip-trigger button-inner" (click)="buttonClicked(button, $event)">
      <div *ngIf="button.label === 'Filters' && filterCount > 0" class="numberCircle">{{filterCount}}</div>
      <uxpl-icon class="button-icon" [icon]="button.icon" width="24px" height="24px"></uxpl-icon>
      <div class="button-label">{{ 'manage.process.analysis.filter.filters' | translate }}</div>
    </div>
    <uxpl-tooltip position="Left" right="-5px" top="-42px" class="my-tooltip">
      <div class="tooltip-inner">{{button.hoverText}}</div>
    </uxpl-tooltip>
  </div>
  <div class="isolate">
    <div class="displaying-holder" *ngIf="results.length > 0">
      <div class="displaying-label">{{ 'manage.process.analysis.filter.filtering' | translate }}</div>
    </div>
    <div *ngFor="let result of results" class="result-holder">
      <div class="tooltip-trigger result-inner">
        <div class="result-line">{{result.line}}</div>
        <div class="result-label">{{result.label}}</div>
      </div>
      <uxpl-tooltip position="Left" right="-5px" top="-42px" class="my-tooltip">
        <div class="tooltip-inner">{{result.hoverText}}</div>
      </uxpl-tooltip>
    </div>
  </div>
  <div class="isolate">
    <div class="displaying-holder" *ngIf="markings.length > 0">
      <div class="displaying-label">{{ 'manage.process.analysis.filter.selection' | translate }}</div>
    </div>
    <div *ngFor="let marking of markings" class="result-holder">
      <div class="tooltip-trigger result-inner">
        <div class="result-line">{{marking.line}}</div>
        <div class="result-label">{{marking.label}}</div>
      </div>
      <uxpl-tooltip position="Left" right="-5px" top="-42px" class="my-tooltip">
        <div class="tooltip-inner">{{marking.hoverText}}</div>
      </uxpl-tooltip>
    </div>
    <div *ngIf="markings.length > 0">
      <button id="reset-selection" mat-flat-button color="primary" (click)="clearMarking()">{{ 'manage.process.analysis.filter.reset.selection' | translate }}</button>
    </div>
  </div>
</div>
