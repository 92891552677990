import {Component,
  Inject, OnInit, 
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})

export class AnalyticsDictionaryComponent implements OnInit {
  panelOpenState = false;
  constructor(
    public dialogRef: MatDialogRef<AnalyticsDictionaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  public onCancel = (): void => {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
