<div class="icon-grid-container">
  <div class="icon-table">
    <table>
      <tr class="icon-row" *ngFor="let row of iconGrid">
        <td *ngFor="let iconI of row">
          <div class="icon-cell" (click)="iconSelected(iconI)" [ngStyle]="{'color':iconColor}" [ngClass]="{'disabled' : iconI == NO_ICON, 'selected' : iconI == icon}">
            <uxpl-icon *ngIf="!(iconI.indexOf('.svg') > 0)" height="20px" width="20px" icon="{{iconI}}"
                       ></uxpl-icon>
            <img *ngIf="iconI.indexOf('.svg') > 0" height=20 width=20 src="{{iconI}}" >
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
